import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import BasicTextArea from '../../../SharedComponents/BasicTextArea/BasicTextArea';
import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-time-picker/assets/index.css';
import './Editdealmemo.scss';
import { withUserContext } from '../../../../contexts/UserContext';
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import DateField from '../../../SharedComponents/DateField/DateField';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';

export default withUserContext(class Editdealmemo extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
           
            statusOptions: [],
            isFetchingEpisodeDetails: false,
            postInitiated: false,
            isPosting: false,
            selectedPerformerValues: [],
            ProjectofficerReportDetails:[],

        }
    }


    render()
    {
        let isLoading = this.state.isFetchingEpisodeDetails && this.state.isFetchingEpisodeTypes;
        return(
            <>
              {isLoading ? (
                <div className="ContentLoader">
                    <CircularProgress />
                </div>
               ) : (
            
                    <MDBContainer className="EditdealmemoContainer" id='rolesDropDown'>
                        <MDBRow>
                            <MDBCol md={3} className="Datefield">
                                <BasicLabel text={"Memo Date"} />
                                    <DateField
                                        id={"roles-select"}
                                         multiple={true}
                                                      value={this.state.selectedPerformerValues}
                                                    options={this.state.performerOptions} width={300}
                                                          // onChange={(e, value) => this.onChangeCallBack('selectedPerformerValues', value)}
                                    />
                            
                            </MDBCol>
                            <MDBCol md={7} className="searchSelectcontent">
                                <BasicTextField
                                    label={"Document Name"}
                                    placeholder={" [Document Name]"}
                                    value={this.state.selectedPerformerValues}  
                               />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={4} className="searchSelect" id='rolesDropDown'> 
                                <BasicLabel text={"Project"} />
                        <SearchSelectField
                            id={"roles-select"}
                            multiple={true}
                            placeholder={" -Selecet-"} 
                            value={this.state.selectedPerformerValues}
                            options={this.state.performerOptions} width={260}
                            // onChange={(e, value) => this.onChangeCallBack('selectedPerformerValues', value)}
                        />
                        </MDBCol>
                        <MDBCol md={8} className="searchSelectcontent">
                                <BasicTextField
                                    label={"Assigned Material"}
                                    placeholder={" [Assigned Material]"}
                                    value={this.state.selectedPerformerValues}  
                               />
                            </MDBCol>
                        </MDBRow>
                        
                        <MDBRow>
                                <BasicTextArea
                                    value={this.state.selectedPerformerValues}
                                    label={"Deal notes"}
                                    placeholder="[Deal Notes]"
                                    className={"textArea"}
                                    rows={3}
                                    //limit={sizeLimits.notesCharacterLimit}
                                    //limitWarning={messages.exceed200CharacterWarning || ""}
                                    // onChange={(e) => {
                                    //     this.handleGeneralEdit('notes', e.target.value.length > 0 ? e.target.value : null)
                                    // }}
                                />
                            
                        </MDBRow>

                        <MDBRow className="p-2">
                            <MDBCol md={8} >
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    className="btnwidth"
                                    type="inline"                                  
                                    variant="contained"
                                    text={"Save"}
                                    onClick={this.Editdeatils}
                                    disabled={false}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                            <BasicButton                                  
                                    variant="outlined"
                                    type="inline"
                                    text= "Cancel"
                                    disabled={false}
                                    onClick={this.props?.onClose}
                                />
                            </MDBCol>
                        </MDBRow>
                   </MDBContainer>
            )}
        </>
        )
    }
}
);