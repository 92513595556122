const modalStyles = {
    position: 'absolute',
    // overflowY: 'auto',
    // overflowY: 'hidden',
    overflowX: 'hidden',
    border: '2px solid grey',
    borderRadius: '8px',

}

export const useStyles = theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        ...modalStyles,
        boxShadow: theme.shadows[5],
        backgroundMDBColor: theme.palette.background.paper,
        width: '60%',
        height: '80%',
    },
    // statusDate: {
    //     maxWidth: '440px !important',
    // },
    AddProjectContainer: {
        maxWidth: '70% !important'
    },
    WGAFormContainer: {
        maxWidth: '70% !important'
    },
    label: {
        fontSize: '0.75rem'
    }
});

