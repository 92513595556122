import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../SharedComponents/SelectField/SelectField';
import PhoneComponent from '../../../Common/Phone/PhoneComponent';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import AddressComponent from '../../../Common/AddressComponent/AddressComponent';
import EmailComponent from '../../../Common/Email/EmailComponent';
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './Representative.scss';
import { emailConfig, phoneConfig, addressConfig } from './Config';
import { validateCharNumOnlyWithSpace, validatePlusCharacter, validateCharctersOnly } from '../../../Common/Helper';
import messages from '../../../Common/Messages.json';
import sizeLimits from '../../../Common/SizeLimits.json';
import { findAllByDisplayValue } from '@testing-library/dom';
import { withUserContext } from '../../../contexts/UserContext';
import ScripterService from '../../../services/service';
import * as Constants from '../../../constants/constants';

class RepresentativeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            representativeDetails: [],
            navObj: {},
            mode: "new"
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.representativeDetails !== state.representativeDetails || props.mode !== state.mode || (JSON.stringify(props.navObj) !== JSON.stringify(state.navObj)) && props.navObj) {
            return {
                mode: props.mode,
                navObj: props.navObj || {},
                representativeDetails: props.representativeDetails
            }
        }
        return null
    }

    componentDidMount() {
        this.getStaticData('prefixes', 'lu_prefix');
        this.getStaticData('suffixes', 'lu_suffix');
    }

    getStaticData = (listType, staticTableName) => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/staticData?staticTable=${staticTableName}`, this.props.userContext?.active_tenant?.tenant_id)
        .then(response => {
            let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
            this.setState({ [listType]: formattedList });
        },
        (err) => {
            console.log("Error in fetching Email Types:", err)
        })
    }

    onChangeCallback = (field, value) => {
        let newVal;
        if (field === "prefix") {
            newVal = this.state.prefixes?.find(item => item.value === value)?.label;
        } else {
            newVal = this.state.suffixes?.find(item => item.value === value)?.label;
        }
        this.props?.handleGeneralEdit(field, newVal);
    }

    render() {
        let isLoading = this.props.isFetchingRepresentativeDetails || this.props.isFetchingRepresentativeTypes;
        return (
            <div>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBContainer className="RepresentativeDetailsContainer">
                            <MDBRow className='g-0 detailsHeading'>
                                <BasicLabel
                                    type={"subHeading"}
                                    text={"Details"}
                                />
                            </MDBRow>
                            <input className="hiddenButton" type="button" ref={this.props.submitButtonRef} onClick={() => this.props.handleSubmit()}></input>
                            <MDBRow className='g-0'>
                                <MDBCol md="1" id='prefixCol'>
                                    <SelectField
                                        id={"rep-prefix"}
                                        className={"prefix"}
                                        // value={this.state.representativeDetails?.prefix || ""}
                                        value={this.state.prefixes?.find(item => item.label === this.state.representativeDetails?.prefix)?.value || null}
                                        label={"Prefix"}
                                        defaultMenuText={"-"}
                                        options={this.state.prefixes}
                                        onChange={(e) => this.onChangeCallback('prefix', e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={3} className="col-content">
                                    <BasicTextField id="representativeFirstName"
                                        isMandatory={true}
                                        label={"First Name"}
                                        limit={sizeLimits.nameCharacterLimit}
                                        showMandatory={this.props.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        limitWarning={messages.exceed50CharacterWarning || ""}
                                        value={this.state.representativeDetails?.first_name || ""}
                                        onChange={(e) => this.props.handleGeneralEdit('first_name', e.target.value)} 
                                    />
                                </MDBCol>
                                <MDBCol md={3} xs={2} className="col-content rep-mi">
                                    <BasicTextField 
                                        id="representativeMI" 
                                        label={"Middle Name"} 
                                        value={this.state.representativeDetails?.m_i || ""}
                                        limit={sizeLimits.miCharacterLimit}
                                        limitWarning={messages.exceed50CharacterWarning || ""}
                                        onChange={(e) => this.props.handleGeneralEdit('m_i', e.target.value.length > 0 ? e.target.value : null)}
                                    />
                                </MDBCol>
                                <MDBCol md={3} xs={8} className="col-content repLastName">
                                    <BasicTextField id="representativeLastName" label={"Last Name"}
                                        value={this.state.representativeDetails?.last_name || ""}
                                        limit={sizeLimits.nameCharacterLimit}
                                        // showMandatory={this.props.postInitiated}
                                        // mandatoryWarning={messages.mandatoryWarning}
                                        limitWarning={messages.exceed50CharacterWarning || ""}
                                        onChange={(e) => this.props.handleGeneralEdit('last_name', e.target.value)} 
                                    />
                                </MDBCol>
                                <MDBCol md={1} xs={4} className="col-content suffixCol">
                                    <SelectField
                                        id={"rep-suffix"}
                                        className={"suffix"}
                                        // value={this.state.representativeDetails?.suffix || ""}
                                        value={this.state.suffixes?.find(item => item.label === this.state.representativeDetails?.suffix)?.value || null}
                                        label={"Suffix"}
                                        defaultMenuText={"-"}
                                        options={this.state.suffixes}
                                        onChange={(e) => this.onChangeCallback('suffix', e.target.value)}
                                    />
                                    {/* <BasicTextField id="representativeSuffix" label={"Suffix"}
                                        value={this.state.representativeDetails?.suffix || ""}
                                        limit={sizeLimits.suffixCharacterLimit}
                                        limitWarning={messages.exceed10CharactersWarning || ""}
                                        onChange={(e) => this.props.handleGeneralEdit('suffix', e.target.value.length > 0 ? e.target.value : null)} 
                                    /> */}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='g-0'>
                                <MDBCol md={4} className="col-content repType">
                                    <SelectField id={"representativeType"}
                                        label={"Representative Type"} isMandatory={true}
                                        showMandatory={this.props.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        options={this.props.representativeTypes || []}
                                        value={this.state.representativeDetails?.type_id || ""}
                                        onChange={(e) => this.props.handleGeneralEdit('type_id', e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={4} className="col-content" id="agencyDropDown">

                                    {
                                        this.state.representativeDetails?.agencies?.map((agencies, index) => (
                                            <MDBRow>
                                                {index === 0 ? <BasicLabel text={"Company"}
                                                    isMandatory={true} /> : null}
                                                <Autocomplete size="medium"
                                                    id={"representativeAgencies"}
                                                    className="tags-standard"
                                                    options={this.props.agencyTypes ? this.props.agencyTypes : []}
                                                    getOptionLabel={(option) => (option.label)}
                                                    value={(agencies?.agency_selected ?
                                                        agencies?.agency_selected : null)}
                                                    fullWidth={true}
                                                    onKeyPress={(event, newValue) => {
                                                        //this.expKeypress(event, newValue)
                                                    }}
                                                    onChange={(event, newValue) => {
                                                        this.props.formatAgencies('agencies', newValue)
                                                    }}
                                                    style={{ width: '100%' }}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            id={"representativeAgenciesText"}
                                                            placeholder="- Search or Select -"
                                                            margin="normal"
                                                            onChange={ev => {
                                                                // dont fire API if the user delete or not entered anything
                                                                if ((ev.target.value !== "" || ev.target.value !== null)) {
                                                                    validateCharNumOnlyWithSpace(ev.target.value) &&
                                                                        this.props.getAgencies(ev.target.value)
                                                                }
                                                            }}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                disableUnderline: true,
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {this.props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                )
                                                            }}
                                                        />
                                                    )
                                                    }
                                                />
                                                <div className="errorText">{this.props.postInitiated && this.props.agencySelected ? messages.mandatoryWarning : ""}</div>
                                            </MDBRow>
                                        ))
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='g-0 ContactHeading'>
                                <BasicLabel
                                    type={"subHeading"}
                                    text={"Contact"}
                                />
                            </MDBRow>
                            <MDBRow className='g-0'>
                                <MDBCol md={6} className="col-content email-tab">

                                    {
                                        this.state.representativeDetails?.email_list?.map((email, index) => (
                                            <MDBRow>
                                                <EmailComponent
                                                    limit={sizeLimits.emailCharacterLimit}
                                                    limitWarning={messages.exceed50CharacterWarning || ""}
                                                    editCallback={this.props.handleListEdit}
                                                    inValidInput={messages.invalidInput || ""}
                                                    keyName={index + "-representativeEmail"}
                                                    list={email}
                                                    emailConfig={emailConfig}
                                                    typeEndpoint={'lu_person_email_type'}
                                                    addCallback={() => this.props.addAdditionalField('email_list')}
                                                    removeCallback={() => this.props.removeField('email_list', email)}
                                                    showRemove={email.representative_email_id || index === 0 ? false : true}
                                                    showAdd={(this.state.representativeDetails?.email_list?.length - 1) === index
                                                        && this.state.representativeDetails?.email_list[index]?.email_type
                                                        && this.state.representativeDetails?.email_list[index]?.email ? true : false}
                                                    label={index === 0 ? "Email List" : ""}
                                                    disabled={email.representative_email_id || false}
                                                    checkedMultiPrimary={(this.state.representativeDetails?.email_list?.length - 1) === index ? this.props.checkedMultiPrimary.email_list : false}
                                                />
                                            </MDBRow>
                                        ))
                                    }
                                </MDBCol>
                                <MDBCol md={6} className="col-content phone-tab">
                                    {
                                        this.state.representativeDetails?.phone_list?.map((phone, index) => (
                                            <MDBRow>
                                                <PhoneComponent label={index === 0 ? "Phone List" : ""}
                                                    keyName={index + "-representativePhone"}
                                                    editCallback={this.props.handleListEdit}
                                                    typeEndpoint={'lu_person_phone_type'}
                                                    limit={validatePlusCharacter((phone.phone.trim()).charAt(0)) ? sizeLimits.phoneLimitIntl : sizeLimits.phoneLimitUS}
                                                    limitWarning={validatePlusCharacter((phone.phone.trim()).charAt(0)) ? messages.phoneLimitIntl : messages.phoneLimitUs}
                                                    list={phone}
                                                    inValidInput={messages.invalidInput || ""}
                                                    phoneConfig={phoneConfig}
                                                    addCallback={() => this.props.addAdditionalField('phone_list')}
                                                    removeCallback={() => this.props.removeField('phone_list', phone)}
                                                    showAdd={((this.state.representativeDetails?.phone_list?.length - 1) === index) && this.state.representativeDetails?.phone_list[index]?.phone_type
                                                        && this.state.representativeDetails?.phone_list[index]?.phone ? true : false}
                                                    showRemove={phone.representative_phone_id || index === 0 ? false : true}
                                                    disabled={phone.representative_phone_id || false}
                                                    checkedMultiPrimary={(this.state.representativeDetails?.phone_list?.length - 1) === index ? this.props.checkedMultiPrimary.phone_list : false} />

                                            </MDBRow>
                                        ))
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='g-0 Address'>
                                <MDBCol md={12} className="col-content">
                                    {
                                        this.state.representativeDetails?.address_list?.map((address, index) => (
                                            <MDBRow>
                                                <AddressComponent
                                                    editCallback={this.props.handleListEdit}
                                                    keyName={index + "-representativeAddress"}
                                                    typeEndpoint={'lu_person_address_type'}
                                                    list={address}
                                                    addressConfig={addressConfig}
                                                    postInitiated={this.props.postInitiated}
                                                    checkMandatory={(address?.address_1 !== null || address?.city !== null || address?.state !== null || address?.zip !== null) &&
                                                        !address?.representative_address_id}
                                                    showAdd={(this.state.representativeDetails?.address_list.length - 1) === index && this.state.representativeDetails.address_list[index]?.address_type
                                                        && this.state.representativeDetails.address_list[index]?.zip && this.state.representativeDetails?.address_list[index]?.city
                                                        && this.state.representativeDetails.address_list[index]?.address_1
                                                        && (this.state.representativeDetails.address_list[index]?.state_id || this.state.representativeDetails?.address_list[index]?.state) ? true : false}
                                                    showRemove={address.representative_address_id || index === 0 ? false : true}
                                                    addCallback={() => this.props.addAdditionalField('address_list')}
                                                    removeCallback={() => this.props.removeField('address_list', address)}
                                                    disabled={address.representative_address_id || false}
                                                    checkedMultiPrimary={(this.state.representativeDetails?.address_list?.length - 1) === index ? this.props.checkedMultiPrimary.address_list : false}
                                                />
                                            </MDBRow>
                                        ))
                                    }
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    )}
            </div>
        );
    }
}

export default withUserContext(RepresentativeDetails);