import React from 'react';
import MiscFilesComponent from '../../../MiscFiles/MiscFiles';
import { MiscFilesList } from './Mock';

class WeekendReadFiles extends React.Component {
    render () {
        return (
            <MiscFilesComponent list={MiscFilesList} />
        )
    }
}

export default WeekendReadFiles
