import React from "react";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import CancelIcon from "@material-ui/icons/Cancel";
import { projectDetails, participatingWriterList, talentPrefixes, talentSuffixes } from "./config";
import TabsComponent from "../../../../SharedComponents/Tabs/Tabs";
import BasicTextField from "../../../../SharedComponents/BasicTextField/BasicTextField";
import DateField from "../../../../SharedComponents/DateField/DateField";
import BasicTextArea from "../../../../SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../../SharedComponents/SelectField/SelectField";
import BasicCheckbox from "../../../../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicButton from "../../../../SharedComponents/BasicButton/BasicButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { InputAdornment } from "@material-ui/core";
import ScripterService from "../../../../../services/service";
import * as Constants from "../../../../../constants/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import BasicLabel from "../../../../SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    validateCharNumOnlyWithSpace,
    validateCharNumWithHyphenSlash,
    validatePlusCharacter,
    validateUSNumber,
    validatePhoneNumber,
    validateIntlNumber,
    getFormattedDate,
    formatPhoneNumbers
} from "../../../../../Common/Helper";
import messages from "../../../../../Common/Messages.json";
import sizeLimits from "../../../../../Common/SizeLimits.json";
import MessageModal from "../../../../SharedComponents/MessageModal";
import { withUserContext } from "../../../../../contexts/UserContext";
import SearchSelectField from "../../../../SharedComponents/SearchSelectField";
import SearchFieldWithAddValue from "../../../../SharedComponents/SearchFieldWithAddValue";
import SubAttachedFiles from "../../../Submissions/Dialogs/SubAttachedFiles";
import "./WGAContainer.scss";
import moment from "moment";
import { FormatColorReset, IndeterminateCheckBox } from "@material-ui/icons";
import { EmergencyRecordingOutlined } from "@mui/icons-material";
import Writer from './Writer';
import { WGATabList } from "../../../../../constants/TabList";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

class WGAContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            projectDetails: null,
            isSubmit: false,
            formEdited: false,
            postInitiated: false,
            participatingWritersIndex: 2,
            writerOptions: [],
            currentRowIndex: 0,
            wgaFormTypeOptions: [],
            loading: false,
            arrowClickedIndex: -1,
            existingWriters: {},
            isExistData: false,
            isRepLoading: false,
            isAddressLoading: false,
            isOpen: false,
            selectedWGAType: {},
            phoneValid: true,
            canSubmit:false,
            tabValue:1,
            isFetchingReport:false,
            submit:false,
            isNWTC:false,
            isnotify:false,
            isnotifyFail:false,
            project_wga_id:null,
            isWriterFlag :false
        };
    }
    getWGATypeData() {
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/staticData?staticTable=lu_wga_type`, this.props.userContext.active_tenant.tenant_id)
            .then((response) => {
                let wgaList = response.data?.map((item) => ({
                    text: item.name,
                    value: item.id,
                }));
                this.setState({
                    wgaFormTypeOptions: wgaList,
                });
            });
    }

    componentDidMount = () => {
        this.getWGATypeData();
        if (this.props.mode === "New") {
            let projectDetailsData = this.formatNewWGAFormDetailsResponse(
                JSON.parse(JSON.stringify({ ...projectDetails }))
            )
            projectDetailsData.project_id = this.props?.projectId;
            projectDetailsData.title = this.props?.projectName;
            projectDetailsData.wga_type_selected = { value: 3,text: "Features" }
            projectDetailsData.wga_type_id = 3
            projectDetailsData.wga_type_id_selected = "Features"
            this.setState({
                projectDetails: projectDetailsData,
                isExistData: true,
            });
            this.getWGANameData();
        } else if (this.props.mode === "Edit") {
            this.getWGAFormData();
        }
        // this.getStaticData('repPrefixes', 'lu_prefix');
        // this.getStaticData('repSuffixes', 'lu_suffix');
    };
    componentDidUpdate(prevProps){
        if(prevProps?.wgaId !== this.props.wgaId && this.props.wgaId != null){
            this.setState({tabValue:1});
            this.getWGAFormData();
        }
        else if (this.props.mode === "New" && prevProps?.mode !== this.props.mode ){            //&& this.props.wgaId != prevProps?.wgaId
         this.setState({tabValue:1, existingWriters:{}});
        this.getWGATypeData();
        let projectDetailsData = this.formatNewWGAFormDetailsResponse(
                JSON.parse(JSON.stringify({ ...projectDetails }))
            )
            projectDetailsData.project_id = this.props?.projectId;
            projectDetailsData.title = this.props?.projectName;
            projectDetailsData.wga_type_selected = { value: 3,text: "Features" };
            projectDetailsData.wga_type_id = 3;
            projectDetailsData.wga_type_id_selected = "Features";
            this.setState({
                projectDetails: projectDetailsData,
                isExistData: true,
            });
            this.getWGANameData();
        }
    }
    getStaticData = (listType, staticTableName) => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/staticData?staticTable=${staticTableName}`,
            this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                this.setState({ [listType]: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Email Types:", err)
                })
    }

    getWGANameData = (searchString) => {
        ScripterService.getData(Constants.scripterServiceBaseUrl +
            `/entitySearch?entity=WGA_NAME&searchString=null&projectId=${this.props?.projectId}`, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let wgaData = response.data[0];
                this.setState(prevState => ({
                    projectDetails: {
                        ...prevState.projectDetails,
                        wga_name: wgaData?.wga_name,
                        wga_address: wgaData?.address,
                        wga_phone: wgaData?.phone
                    },
                }));
            })
    }

    getWGAFormData() {
        this.setState({ isLoading: true });
        ScripterService.getData(
            Constants.scripterServiceBaseUrl + `/wga?projectWgaId=${this.props?.wgaId || this.state.project_wga_id}`,
            this.props.userContext.active_tenant.tenant_id,
            this.props?.divisionId
        ).then((response) => {
            let wgaDetails = this.formatNewWGAFormDetailsResponse(
                JSON.parse(JSON.stringify({ ...response.data[0] }))
            );
            wgaDetails.wga_type_id_selected = wgaDetails.wga_type_name;
            if (!(/\([\d]+\)/.test(wgaDetails.wga_phone))) {
                wgaDetails.wga_phone = formatPhoneNumbers(wgaDetails?.wga_phone?.replace(/-/g, ""))
            }
            this.props?.wgaNameChanged(wgaDetails.wga_type_name);
            let editWriterRowIndex = wgaDetails?.writers[wgaDetails?.writers.length - 1].row_no;
            let existWriterData = JSON.parse(JSON.stringify({ ...wgaDetails }));
            let newWriterArray = this.formatNewWGAFormDetailsResponse(
                JSON.parse(JSON.stringify({ ...projectDetails }))
            );
            newWriterArray.writers[0].row_no = editWriterRowIndex + 1;
            wgaDetails.writers = [...newWriterArray.writers];
            this.setState({
                projectDetails: wgaDetails,
                existingWriters: existWriterData,
                isLoading: false,
                isExistData: false,
                project_wga_id: this.props?.wgaId
            });
            },
            (err) => {
                this.setState({
                    projectDetails: this.formatNewWGAFormDetailsResponse(
                        JSON.parse(JSON.stringify({ ...projectDetails }))
                    ),
                    isLoading: false,
                });
            }
        )
    }
    /**This method is used to push writers object to projectDetails writers array */
    formatNewWGAFormDetailsResponse = (response) => {
        response['wga_type_selected'] = {
            'value': response.wga_type_id, 'text': response.wga_type_name
        }
        if (response?.writers?.length === 0) {
            response?.writers?.push(
                JSON.parse(JSON.stringify({ ...participatingWriterList }))
            );
        }
        return response;
    };
    showMessage = () => {
        this.setState({ isOpen: true });
    }
    handleGeneralEdit = (field, value) => {
        let projectDetails = JSON.parse(
            JSON.stringify({ ...this.state.projectDetails })
        );
        this.setState({ selectedWGAType: value, submit: true })
        if (field === "wga_type_id") {
            if (projectDetails.wga_type_selected?.value !== null && this.state.isOpen === false) {
                this.showMessage();
            } else if (projectDetails.wga_type_selected?.value !== null && this.state.isOpen === true) {
                projectDetails[field] = value?.value;
                projectDetails[field + "_selected"] = value?.text;
                projectDetails["wga_type_selected"] = value;
                this.props?.wgaNameChanged(value?.text);
                this.setState({ projectDetails: projectDetails, formEdited: true, isOpen: false });
            } else if (projectDetails.wga_type_selected?.value === null) {
                projectDetails[field] = value?.value;
                projectDetails[field + "_selected"] = value?.text;
                projectDetails["wga_type_selected"] = value;
                this.props?.wgaNameChanged(value?.text);
                this.setState({ projectDetails: projectDetails, formEdited: true });
            }
        } else if (field === "notice_date") {
            this.setState((prevState) => ({
                projectDetails: {
                    ...prevState.projectDetails,
                    [field]: value,
                    "wga_date": getFormattedDate(value, false) || null
                },
            }));
        } else {
            this.setState((prevState) => ({
                projectDetails: {
                    ...prevState.projectDetails,
                    [field]: value,
                },
                formEdited: true,
            }));
        }
        this.props.fieldChanged(this.state.formEdited, "change");
    };

    handleSubmit = () => {
        let wgaProjectDetails = JSON.parse(
            JSON.stringify({ ...this.state.projectDetails })
        );
        let writersArray = []
        this.setState({ postInitiated: true }, () => {
            if (this.validateWGAFormJson(wgaProjectDetails)) {
                this.postWGAFormDetails(wgaProjectDetails);
            }
        });
    };
    postWGAFormDetails = (wgaFormDetails) => {
        this.setState({ isSubmit: true ,isLoading:true});
        this.props.setPostFlag(true);
        ScripterService.postDataParams(
            Constants.scripterServiceBaseUrl + "/wga",
            wgaFormDetails,
            this.props.userContext.active_tenant.tenant_id,
            this.props?.divisionId
        ).then((response) => {
            if (response.data.error) {
                this.setState({ isSubmit: false, submit: false });
                this.props.setPostFlag(false);
                this.props.fieldChanged(true, "fail");
            } else {
                this.setState({
                    postInitiated: false,
                    formEdited: false,
                    isSubmit: false,
                    submit: false,
                    isnotify: true,
                    isNWTC: true,
                    project_wga_id:response.data[0].project_wga_id,
                    isLoading:false
                });
                    this.props.toggleRefreshPage('wga');
                    this.props.setPostFlag(false);
                    this.props.fieldChanged(true, "success");
                    this.props?.wgaIdChanged(response.data[0].project_wga_id);
                    this.getWGAFormData();

                }
            }, (err) => {
                this.setState({ isSubmit: false, submit: false ,isnotifyFail: true });
                this.props.fieldChanged(true, "fail");
                this.props.setPostFlag(false);
                console.log("Post WGA project details error: " + err);
            }
        );
    };
    checkFieldLength = (field, limit) => {
        return field ? field.length <= limit : true
    }
    validatePhone(field) {
        return (field ? (validatePlusCharacter((field.trim()).charAt(0)) ?
                validateIntlNumber(field) :
                (validateUSNumber(field) || validatePhoneNumber(field))) : true)
    }
   
    validateWGAFormJson = (projectDetailsWGAJson) => {
        let canSubmit = false;
        if (projectDetailsWGAJson.wga_type_id_selected === "HBSVOD") {
            canSubmit =
                (this.checkFieldLength(projectDetailsWGAJson.title, sizeLimits.nameCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.episode_title, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.series_title, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.production_company, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.executive_producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.associate_producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.co_producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.director, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.other_production_staff, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.on_screen, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.source_material, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.revised_final_script, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.final_date, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.company, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.wga_name, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.wga_address, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.continuing_source_material, sizeLimits.notesCharacterLimit) &&
                  this.checkFieldLength(projectDetailsWGAJson.notes, sizeLimits.largeNotes) &&
                this.validatePhone(projectDetailsWGAJson.wga_phone))//&&
            //projectDetailsWGAJson?.writers?.length > 0  && this.validateWriters(projectDetailsWGAJson?.writers))               
            return canSubmit;
        } else if (projectDetailsWGAJson.wga_type_id_selected === "Animation") {
            canSubmit = this.checkFieldLength(projectDetailsWGAJson.title, sizeLimits.nameCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.executive_producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.associate_producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.co_producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.director, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.other_production_staff, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.on_screen, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.on_screen_source_credit, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.on_screen_production_credit, sizeLimits.wgaformCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.source_material, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.final_date, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.company, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.wga_name, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.wga_address, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.wga_date, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.notes, sizeLimits.largeNotes) &&
                this.validatePhone(projectDetailsWGAJson.wga_phone) //&&
            //projectDetailsWGAJson?.writers?.length > 0  && this.validateWriters(projectDetailsWGAJson?.writers)                       
            return canSubmit;
        } else if (projectDetailsWGAJson.wga_type_id_selected === "New Media") {
            canSubmit = this.checkFieldLength(projectDetailsWGAJson.title, sizeLimits.nameCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.production, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.series_title, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.original_exhibition_site, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.production_company, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.executive_producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.associate_producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.co_producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.director, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.other_production_staff, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.episode_writing_credits, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.on_screen_source_credit, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.source_material, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.continuing_source_material, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.final_date, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.company, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.wga_name, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.wga_address, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.notes, sizeLimits.largeNotes) &&
                this.validatePhone(projectDetailsWGAJson.wga_phone) //&&
            //projectDetailsWGAJson?.writers?.length > 0  && this.validateWriters(projectDetailsWGAJson?.writers)     
            return canSubmit;
        } else if (projectDetailsWGAJson.wga_type_id_selected === "Television") {
            canSubmit = this.checkFieldLength(projectDetailsWGAJson.episode_title, sizeLimits.nameCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.production, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.series_title, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.production_company, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.executive_producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.associate_producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.director, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.other_production_staff, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.on_screen, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.source_material, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.continuing_source_material, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.revised_final_script, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.final_date, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.company, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.wga_name, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.wga_address, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.notes, sizeLimits.largeNotes) &&
                this.validatePhone(projectDetailsWGAJson.wga_phone) //&&
            //projectDetailsWGAJson?.writers?.length > 0  && this.validateWriters(projectDetailsWGAJson?.writers) 

            return canSubmit;
        } else if (projectDetailsWGAJson.wga_type_id_selected === "Features") {
            canSubmit = this.checkFieldLength(projectDetailsWGAJson.title, sizeLimits.nameCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.executive_producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.producer, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.director, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.other_production_staff, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.on_screen, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.on_screen_source_credit, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.on_screen_production_credit, sizeLimits.wgaformCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.source_material, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.final_date, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.company, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.wga_name, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.wga_address, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.wga_date, sizeLimits.notesCharacterLimit) &&
                this.checkFieldLength(projectDetailsWGAJson.notes, sizeLimits.largeNotes) &&
                this.validatePhone(projectDetailsWGAJson.wga_phone)//&&
            //projectDetailsWGAJson?.writers?.length > 0  && this.validateWriters(projectDetailsWGAJson?.writers)                 
            return canSubmit;
        }
    };

    validatePhoneValues = (value) => {
        this.setState({
            phoneValid:
                value.length > 0 ?
                    (validatePlusCharacter((value.trim()).charAt(0)) ?
                        validateIntlNumber(value) : validateUSNumber(value) || validatePhoneNumber(value)) : true
        });
        this.setState(prevState => ({
            projectDetails: {
                ...prevState.projectDetails,
                wga_phone: value || null,
            },
        }))
    }
    getReportName = (type) => {
        switch (type) {
            case 'Television': return 'rptWgaTelevisionNotice';
            case 'New Media': return 'rptWgaNewMedia';
            case 'Animation': return 'rptWgaAnimationForm';
            case 'HBSVOD': return 'rptWgaHbsvodCreditTemplate';
            case 'Features': return 'rptWgaTheatricalNoticeReport';
            default: return null;
        }
    }
    handleWgaPdfDownload = (wga) => {
        this.setState({ isFetchingReport: true, mode: 'pdf' })
        let type = this.getReportName(wga?.wga_type_name);
        if (type) {
            let json = { "project_id": wga?.project_id, "project_wga_id": wga?.project_wga_id };
            let load = {
                "payload": 'generateReport',
                "reportName": type,
                "searchJson": btoa(JSON.stringify(json)),
                "showHtml": 0,
                "isStaticReport": 0,
                "encryptionType": 1,
                "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
                "divisionId": this.props?.divisionId
            }
            ScripterService.getDataWS(load)
                .then(webSocketConn => {
                    webSocketConn.onmessage = (e) => {
                        let response = JSON.parse(e.data)
                        if (response?.statusCode == 200) {
                            this.setState({ isFetchingReport: false, mode: null })
                            ScripterService.downloadFile(response.body).then(res => {
                                const url = window.URL.createObjectURL(res.data);
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', type);
                                document.body.appendChild(link);
                                link.click();
                            }, (err) => {
                                console.log("File Download Failed:" + err);
                                this.setState({ isFetchingReport: false, mode: null })
                            });
                        } else {
                            this.setState({ isFetchingReport: false, mode: null })
                        }
                    }
                })
        } else {
            this.setState({ isFetchingReport: false, mode: null })
        }
    }
    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue
        })
    }

    render() {

        return (
            <div>
                {this.state.isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <div fluid className="ModalContainer WGAContainer">
                            <MessageModal
                                open={this.state.isOpen}
                                title={"Change Form Type"}
                                message={"Would you like to change the Form Type?"}
                                primaryButtonText={"Ok"}
                                secondaryButtonText={"Cancel"}
                                onConfirm={() => this.handleGeneralEdit("wga_type_id", this.state.selectedWGAType)}
                                handleClose={(e) => this.setState({ isOpen: false })} />
                            <MessageModal
                                open={this.state.showDeleteConfirmationModal}
                                title={"Delete record"}
                                message={"Would you like to delete saved record?"}
                                primaryButtonText={"Delete"}
                                secondaryButtonText={"Cancel"}
                                onConfirm={() => this.setState({ showDeleteConfirmationModal: false }, () => {
                                    this.deleteRow(this.state.deletedCallbackItem, this.state.type)
                                })}
                                handleClose={(e) => this.setState({ showDeleteConfirmationModal: false })}
                            />
                            <MessageModal
                                open={this.state.submit && this.state.tabValue==2}
                                title={"Unsaved changes"}
                                message={"The unsaved changes will be gone .Would you like to move to next tab?"}
                                primaryButtonText={"Ok"}
                                secondaryButtonText={"Cancel"}
                                onConfirm={() => this.setState({ tabValue:2,submit:false },()=>{this.getWGAFormData()})}
                                handleClose={(e) => this.setState({ tabValue:1 })}
                            />
                            <MDBRow className="ModelContent" id="ProductionDetailsContainer">
                                <div style={{ "display": "flex" }}>

                                    <TabsComponent
                                        tabList={WGATabList}
                                        value={this.state.tabValue}
                                        badgeContent={0}
                                        onChange={(e, v) => this.handleTabChange(v)}
                                        disabled={this.props.mode==='New'? true:false}

                                    />
                                    <BasicButton className="mt-3 me-3"
                                        variant="outlined"
                                        type="inline"
                                        color="primary"
                                        text={"Generate PDF"}
                                        text={this.state.isFetchingReport ? <CircularProgress color="inherit" size={20} /> : "Generate"}
                                        onClick={() => this.handleWgaPdfDownload(this.state.projectDetails)}
                                        disabled={this.props.mode === 'New' ? true :  !this.state.project_wga_id ? true:false}
                                    />

                                </div>
                                {this.state.tabValue == 1 &&
                                    <MDBCol>
                                        <MDBRow className="ModalBody p-2">
                                            <MDBCol md={12} className="Content">
                                                <MDBRow>
                                                    <MDBCol md={3} className="d-inline-block">
                                                        <SearchSelectField
                                                            width={"100%"}
                                                            id={"wga_type_id"}
                                                            label={"Form Type"}
                                                            isMandatory={true}
                                                            showMandatory={this.state.postInitiated}
                                                            mandatoryWarning={messages.mandatoryWarning}
                                                            options={this.state.wgaFormTypeOptions || []}
                                                            //defaultValue={"Feacture"}
                                                            value={this.state.projectDetails?.wga_type_selected || {}}
                                                            onChange={(e, newValue) =>
                                                                this.handleGeneralEdit("wga_type_id", newValue)
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="mb-3">
                                                    <MDBCol md={3} className="d-inline-block">
                                                        <DateField
                                                            id={"noticeDate"}
                                                            label={"Notice Date"}
                                                            value={this.state.projectDetails?.notice_date ?
                                                                moment(
                                                                    getFormattedDate(this.state.projectDetails?.notice_date, false)
                                                                ).format("YYYY-MM-DD") : null}
                                                            onChange={(e) =>
                                                                this.handleGeneralEdit(
                                                                    "notice_date",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </MDBCol>
                                                    {this.state.projectDetails?.wga_type_id_selected ===
                                                        "HBSVOD" ? (
                                                            <MDBCol md={4}>
                                                                <BasicTextField
                                                                    label="Title"
                                                                    limit={sizeLimits.nameCharacterLimit}
                                                                    limitWarning={messages.exceed50CharacterWarning || ""}
                                                                    value={this.state.projectDetails?.title || ""}
                                                                    onChange={(e) =>
                                                                        this.handleGeneralEdit("title", e.target.value)
                                                                    }
                                                                />
                                                            </MDBCol>
                                                        ) : null}
                                                </MDBRow>

                                                <MDBRow>
                                                    {this.state.projectDetails?.wga_type_id_selected ===
                                                        "Features" ||
                                                        this.state.projectDetails?.wga_type_id_selected ===
                                                        "Animation" ? (
                                                            <MDBCol md={4}>
                                                                <BasicTextField
                                                                    label="Title"
                                                                    limit={sizeLimits.nameCharacterLimit}
                                                                    limitWarning={messages.exceed50CharacterWarning || ""}
                                                                    value={this.state.projectDetails?.title || ""}
                                                                    onChange={(e) => {
                                                                        this.handleGeneralEdit("title", e.target.value);
                                                                    }}
                                                                />
                                                            </MDBCol>
                                                        ) : null}
                                                    {this.state.projectDetails?.wga_type_id_selected ===
                                                        "Television" ||
                                                        this.state.projectDetails?.wga_type_id_selected ===
                                                        "New Media" ||
                                                        this.state.projectDetails?.wga_type_id_selected ===
                                                        "HBSVOD" ? (
                                                            <MDBCol md={4}>
                                                                <BasicTextField
                                                                    label="Title of Episode"
                                                                    limit={sizeLimits.notesCharacterLimit}
                                                                    limitWarning={messages.exceed200CharacterWarning || ""}
                                                                    value={
                                                                        this.state.projectDetails?.episode_title || ""
                                                                    }
                                                                    onChange={(e) => {
                                                                        this.handleGeneralEdit(
                                                                            "episode_title",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                />
                                                            </MDBCol>
                                                        ) : null}
                                                    {this.state.projectDetails?.wga_type_id_selected ===
                                                        "Television" ||
                                                        this.state.projectDetails?.wga_type_id_selected ===
                                                        "New Media" ? (
                                                            <MDBCol md={2}>
                                                                <BasicTextField
                                                                    label="Production #"
                                                                    limit={sizeLimits.notesCharacterLimit}
                                                                    limitWarning={
                                                                        messages.exceed200CharacterWarning || ""
                                                                    }
                                                                    onChange={(e) => {
                                                                        this.handleGeneralEdit(
                                                                            "production",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    value={this.state.projectDetails?.production || ""}
                                                                />
                                                            </MDBCol>
                                                        ) : null}
                                                </MDBRow>
                                                {this.state.projectDetails?.wga_type_id_selected ===
                                                    "Television" ||
                                                    this.state.projectDetails?.wga_type_id_selected ===
                                                    "HBSVOD" ||
                                                    this.state.projectDetails?.wga_type_id_selected ===
                                                    "New Media" ? (
                                                        <MDBRow>
                                                            <MDBCol md={5}>
                                                                <BasicTextField
                                                                    label="Series Title"
                                                                    limit={sizeLimits.notesCharacterLimit}
                                                                    limitWarning={
                                                                        messages.exceed200CharacterWarning || ""
                                                                    }
                                                                    value={
                                                                        this.state.projectDetails?.series_title || ""
                                                                    }
                                                                    onChange={(e) => {
                                                                        this.handleGeneralEdit(
                                                                            "series_title",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                />
                                                            </MDBCol>
                                                            {this.state.projectDetails?.wga_type_id_selected ===
                                                                "New Media" ? (
                                                                    <MDBCol md={5}>
                                                                        <BasicTextField
                                                                            label="Original Exhibition Site"
                                                                            limit={sizeLimits.notesCharacterLimit}
                                                                            limitWarning={
                                                                                messages.exceed200CharacterWarning || ""
                                                                            }
                                                                            value={
                                                                                this.state.projectDetails
                                                                                    ?.original_exhibition_site || ""
                                                                            }
                                                                            onChange={(e) => {
                                                                                this.handleGeneralEdit(
                                                                                    "original_exhibition_site",
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                        />
                                                                    </MDBCol>
                                                                ) : null}
                                                            <MDBCol md={5}>
                                                                <BasicTextField
                                                                    label="Producing Company"
                                                                    limit={sizeLimits.notesCharacterLimit}
                                                                    limitWarning={
                                                                        messages.exceed200CharacterWarning || ""
                                                                    }
                                                                    value={
                                                                        this.state.projectDetails?.production_company ||
                                                                        ""
                                                                    }
                                                                    onChange={(e) => {
                                                                        this.handleGeneralEdit(
                                                                            "production_company",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                />
                                                            </MDBCol>
                                                        </MDBRow>
                                                    ) : null}

                                                <MDBRow>
                                                    <MDBCol md={12}>
                                                        <BasicTextField
                                                            label="Executive Producer"
                                                            limit={sizeLimits.notesCharacterLimit}
                                                            limitWarning={
                                                                messages.exceed200CharacterWarning || ""
                                                            }
                                                            onChange={(e) =>
                                                                this.handleGeneralEdit(
                                                                    "executive_producer",
                                                                    e.target.value
                                                                )
                                                            }
                                                            value={
                                                                this.state.projectDetails?.executive_producer || ""
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md={12}>
                                                        <BasicTextField
                                                            label="Producer"
                                                            limit={sizeLimits.notesCharacterLimit}
                                                            limitWarning={
                                                                messages.exceed200CharacterWarning || ""
                                                            }
                                                            onChange={(e) =>
                                                                this.handleGeneralEdit("producer", e.target.value)
                                                            }
                                                            value={this.state.projectDetails?.producer || ""}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md={12}>
                                                        <BasicTextField
                                                            label="Associate Producer"
                                                            limit={sizeLimits.notesCharacterLimit}
                                                            limitWarning={
                                                                messages.exceed200CharacterWarning || ""
                                                            }
                                                            onChange={(e) => {
                                                                this.handleGeneralEdit(
                                                                    "associate_producer",
                                                                    e.target.value
                                                                );
                                                            }}
                                                            value={
                                                                this.state.projectDetails?.associate_producer ||
                                                                ""
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                {this.state.projectDetails?.wga_type_id_selected ===
                                                    "Animation" ||
                                                    this.state.projectDetails?.wga_type_id_selected ===
                                                    "HBSVOD" ||
                                                    this.state.projectDetails?.wga_type_id_selected ===
                                                    "New Media" ||
                                                    this.state.projectDetails?.wga_type_id_selected ===
                                                    "Features" ? (
                                                        <MDBRow>
                                                            <MDBCol md={12}>
                                                                <BasicTextField
                                                                    label="Co-Producer"
                                                                    limit={sizeLimits.notesCharacterLimit}
                                                                    limitWarning={
                                                                        messages.exceed200CharacterWarning || ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.handleGeneralEdit(
                                                                            "co_producer",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    value={this.state.projectDetails?.co_producer || ""}
                                                                />
                                                            </MDBCol>
                                                        </MDBRow>
                                                    ) : null}
                                                <MDBRow>
                                                    <MDBCol md={12}>
                                                        <BasicTextField
                                                            label="Director"
                                                            limit={sizeLimits.notesCharacterLimit}
                                                            limitWarning={
                                                                messages.exceed200CharacterWarning || ""
                                                            }
                                                            onChange={(e) =>
                                                                this.handleGeneralEdit("director", e.target.value)
                                                            }
                                                            value={this.state.projectDetails?.director || ""}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md={12}>
                                                        <BasicTextField
                                                            label="Other Production Executive(s),and their title(s),if participating writer(s)"

                                                            limit={sizeLimits.notesCharacterLimit}
                                                            limitWarning={
                                                                messages.exceed200CharacterWarning || ""
                                                            }
                                                            onChange={(e) =>
                                                                this.handleGeneralEdit(
                                                                    "other_production_staff",
                                                                    e.target.value
                                                                )
                                                            }
                                                            value={
                                                                this.state.projectDetails?.other_production_staff ||
                                                                ""
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                {this.state.projectDetails?.wga_type_id_selected !=
                                                    "New Media" ? (
                                                        <MDBRow>
                                                            <MDBCol md={12}>
                                                                <BasicTextField
                                                                    label="On Screen Writing Credit"
                                                                    limit={sizeLimits.notesCharacterLimit}
                                                                    limitWarning={
                                                                        messages.exceed200CharacterWarning || ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.handleGeneralEdit(
                                                                            "on_screen",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    value={this.state.projectDetails?.on_screen || ""}
                                                                />
                                                            </MDBCol>
                                                        </MDBRow>
                                                    ) : null}
                                                {this.state.projectDetails?.wga_type_id_selected ===
                                                    "Features" ||
                                                    this.state.projectDetails?.wga_type_id_selected ===
                                                    "Animation" ? (
                                                        <>
                                                            <MDBRow>
                                                                <MDBCol md={12}>
                                                                    <BasicTextField
                                                                        label="On Screen Source Material Credit, If Any"
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "on_screen_source_credit",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails
                                                                                ?.on_screen_source_credit || ""
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={12}>
                                                                    <BasicTextArea
                                                                        label="On Screen And/Or Advertising, presentation and production credit, if any"
                                                                        className={"textArea"}
                                                                        isWGA={true}
                                                                        limit={sizeLimits.wgaformCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed1000characterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "on_screen_production_credit",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails
                                                                                ?.on_screen_production_credit || ""
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={12}>
                                                                    <BasicTextField
                                                                        label="Source Material, upon which the motion picture is based, if any"
                                                                        // isMandatory={true}
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) => {
                                                                            this.handleGeneralEdit(
                                                                                "source_material",
                                                                                e.target.value.length > 0
                                                                                    ? e.target.value
                                                                                    : null
                                                                            );
                                                                        }}
                                                                        value={
                                                                            this.state.projectDetails?.source_material || ""
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>

                                                            <MDBRow>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={"Final Date"}
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "final_date",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails?.final_date || null
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={"Company"}
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "company",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={this.state.projectDetails?.company || ""}
                                                                    />
                                                                </MDBCol>
                                                                <MDBCol md={4}></MDBCol>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={"Name"}
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "wga_name",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={this.state.projectDetails?.wga_name || ""}
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={8}></MDBCol>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={"Address"}
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "wga_address",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails?.wga_address || ""
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={"Date"}
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "wga_date",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={this.state.projectDetails?.wga_date || null}
                                                                    />
                                                                </MDBCol>
                                                                <MDBCol md={4}></MDBCol>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label="Telephone"
                                                                        placeholder="(___) ___-____"
                                                                        value={this.state.projectDetails?.wga_phone || ""}
                                                                        fieldValid={this.state.phoneValid === false ? true : false}
                                                                        inValidInput={messages.invalidInput}
                                                                        limit={validatePlusCharacter((this.state.projectDetails?.wga_phone?.trim())?.charAt(0)) ? sizeLimits.phoneLimitIntl : sizeLimits.phoneLimitUS}
                                                                        limitWarning={validatePlusCharacter((this.state.projectDetails?.wga_phone?.trim())?.charAt(0)) ? messages.phoneLimitIntl : messages.phoneLimitUs}
                                                                        onChange={(e) => this.validatePhoneValues(e.target.value)}
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </>
                                                    ) : null}

                                                {this.state.projectDetails?.wga_type_id_selected === 'Television' ?
                                                    <MDBRow>
                                                        <MDBCol md={12}>
                                                            <BasicTextField
                                                                label="If on screen credit is accorded on this episode, it shall appear as follows"

                                                                limit={sizeLimits.notesCharacterLimit}

                                                                limitWarning={
                                                                    messages.exceed200CharacterWarning || ""
                                                                }
                                                                onChange={(e) =>
                                                                    this.handleGeneralEdit(
                                                                        "on_screen_source_credit",
                                                                        e.target.value
                                                                    )
                                                                }
                                                                value={
                                                                    this.state.projectDetails
                                                                        ?.on_screen_source_credit || ""
                                                                }
                                                            />
                                                        </MDBCol>
                                                    </MDBRow> : null
                                                }

                                                {this.state.projectDetails?.wga_type_id_selected ===
                                                    "Television" ||
                                                    this.state.projectDetails?.wga_type_id_selected ===
                                                    "HBSVOD" ? (
                                                        <>
                                                            <MDBRow>
                                                                <MDBCol md={12}>
                                                                    <BasicTextField
                                                                        label="Source Material Credit on this episode(on separate card, unless otherwise indicated) if any"

                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "source_material",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails
                                                                                ?.source_material || ""
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={12}>
                                                                    <BasicTextField
                                                                        label="Continuing Source Material or Created By credit Appearing on all episodes of series(on separate card)"

                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "continuing_source_material",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails
                                                                                ?.continuing_source_material || ""
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={
                                                                            "Revised final script was sent to participating writers on"
                                                                        }
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "revised_final_script",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails
                                                                                ?.revised_final_script || ""
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                                <MDBCol md={4}></MDBCol>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={"Company"}
                                                                        limit={sizeLimits.notesCharacterLimit}

                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "company",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={this.state.projectDetails?.company || ""}
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={"Final Date"}
                                                                        limit={sizeLimits.notesCharacterLimit}

                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "final_date",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails?.final_date || null
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                                <MDBCol md={4}></MDBCol>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={"Name"}
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "wga_name",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={this.state.projectDetails?.wga_name || ""}
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={8}></MDBCol>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={"Address"}
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "wga_address",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails?.wga_address || ""
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={4}></MDBCol>
                                                                <MDBCol md={4}></MDBCol>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label="Telephone"
                                                                        placeholder="(___) ___-____"
                                                                        value={this.state.projectDetails?.wga_phone || ""}
                                                                        fieldValid={this.state.phoneValid === false ? true : false}
                                                                        inValidInput={messages.invalidInput}
                                                                        limit={validatePlusCharacter((this.state.projectDetails?.wga_phone?.trim())?.charAt(0)) ? sizeLimits.phoneLimitIntl : sizeLimits.phoneLimitUS}
                                                                        limitWarning={validatePlusCharacter((this.state.projectDetails?.wga_phone?.trim())?.charAt(0)) ? messages.phoneLimitIntl : messages.phoneLimitUs}
                                                                        onChange={(e) => this.validatePhoneValues(e.target.value)}
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </>
                                                    ) : null}

                                                {this.state.projectDetails?.wga_type_id_selected ===
                                                    "New Media" ? (
                                                        <>
                                                            <MDBRow>
                                                                <MDBCol md={12}>
                                                                    <BasicTextField
                                                                        label="Writing Credits on this episode are tentatively Determined as follows"

                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "episode_writing_credits",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails
                                                                                ?.episode_writing_credits || ""
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={12}>
                                                                    <BasicTextField
                                                                        label="If on screen credit is accorded on this episode, it shall appear as follows"

                                                                        limit={sizeLimits.notesCharacterLimit}

                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "on_screen_source_credit",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails
                                                                                ?.on_screen_source_credit || ""
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={12}>
                                                                    <BasicTextField
                                                                        label="Source Material ON THIS EPISODE, if any"

                                                                        limit={sizeLimits.notesCharacterLimit}

                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "source_material",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails
                                                                                ?.source_material || ""
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={12}>
                                                                    <BasicTextField
                                                                        label="Continuing source material or Created By Credit, if any"

                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "continuing_source_material",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails
                                                                                ?.continuing_source_material || ""
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={"Final Date"}
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "final_date",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails?.final_date || null
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                                <MDBCol md={4}></MDBCol>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={"Company"}
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "company",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={this.state.projectDetails?.company || ""}
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={4}></MDBCol>
                                                                <MDBCol md={4}></MDBCol>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={"Name"}
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "wga_name",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={this.state.projectDetails?.wga_name || ""}
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={8}></MDBCol>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label={"Address"}
                                                                        limit={sizeLimits.notesCharacterLimit}
                                                                        limitWarning={
                                                                            messages.exceed200CharacterWarning || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleGeneralEdit(
                                                                                "wga_address",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this.state.projectDetails?.wga_address || ""
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={4}></MDBCol>
                                                                <MDBCol md={4}></MDBCol>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label="Telephone"
                                                                        placeholder="(___) ___-____"
                                                                        value={this.state.projectDetails?.wga_phone || ""}
                                                                        fieldValid={this.state.phoneValid === false ? true : false}
                                                                        inValidInput={messages.invalidInput}
                                                                        limit={validatePlusCharacter((this.state.projectDetails?.wga_phone?.trim())?.charAt(0)) ? sizeLimits.phoneLimitIntl : sizeLimits.phoneLimitUS}
                                                                        limitWarning={validatePlusCharacter((this.state.projectDetails?.wga_phone?.trim())?.charAt(0)) ? messages.phoneLimitIntl : messages.phoneLimitUs}
                                                                        onChange={(e) => this.validatePhoneValues(e.target.value)}
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </>
                                                    ) : null}

                                                <MDBRow>
                                                    <MDBCol md={12}>
                                                        <BasicTextArea
                                                            value={this.state.projectDetails?.notes || null}
                                                            label={"Notes (will not print on form)"}
                                                            className={"textArea"}
                                                            rows={2}
                                                            limit={sizeLimits.largeNotes}
                                                            limitWarning={
                                                                messages.exceed1000CharacterWarning || ""
                                                            }
                                                            onChange={(e) =>
                                                                this.handleGeneralEdit(
                                                                    "notes",
                                                                    e.target.value.length > 0 ? e.target.value : null
                                                                )
                                                            }
                                                            typedCharCount={this.state.projectDetails?.notes?.length || 0}
                                                            showCharCount={true}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>

                                                <MDBRow className="">
                                                    <MDBCol md={9}></MDBCol>
                                                    <MDBCol md={3}>
                                                        <MDBRow className="mt-1">
                                                            <MDBCol>
                                                                <BasicButton
                                                                    type="inline"
                                                                    variant="outlined"
                                                                    text={"Cancel"}
                                                                    onClick={() => this.props.onClose()}
                                                                    disabled={false}
                                                                />
                                                            </MDBCol>
                                                            <MDBCol>
                                                                <BasicButton
                                                                    className="btnwidth"
                                                                    variant="contained"
                                                                    type="inline"
                                                                    text={
                                                                        this.state.isSubmit ? (
                                                                            <CircularProgress color="inherit" size={20} />
                                                                        ) : (
                                                                                this.props.primaryButtonText
                                                                            )
                                                                    }
                                                                    onClick={this.handleSubmit}
                                                                    disabled={!this.state.submit ? true : false}
                                                                />

                                                                <Snackbar
                                                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                                    open={this.state.isnotify || this.state.isnotifyFail}
                                                                    onClose={() => this.setState({ isnotify: false })}
                                                                    autoHideDuration={6000}
                                                                >
                                                                    <Alert onClose={() => this.setState({ isnotify: false, isnotifyFail: false })} severity={!this.state.isnotifyFail ? "success" : "warning"} sx={{ width: '100%' }}>{!this.state.isnotifyFail ? 'Saved Successfully!' : 'saved Failed !'}</Alert>
                                                                </Snackbar>

                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                }
                            </MDBRow>

                            {this.state.tabValue == 2 &&
                                <Writer existingWriters={this.state.existingWriters}
                                    projectDetails={this.state.projectDetails}
                                    onClose={this.props.onClose}
                                    fieldChanged={this.props.fieldChanged}
                                    setPostFlag={this.props.setPostFlag}
                                    divisionId={this.props.divisionId}
                                    mode={this.props.mode}
                                    project_wga_id={this.state.project_wga_id}
                                />

                            }

                        </div>
                    )}
            </div>
        );
    }
}

export default withUserContext(WGAContainer);