import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import BasicCheckbox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-time-picker/assets/index.css';
import ScripterService from '../../services/service';
import * as Constants from '../../constants/constants';
import SearchSelectList from '../../components/Views/Submissions/Dialogs/SearchSelectList';
import './DealInformationReport.scss';
import { withUserContext } from '../../contexts/UserContext';

export default withUserContext(class DealInformationReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            projectTitleField: false,
            writerField: false,
            projectList: [],
            userList: [],
            selectedProject: null,
            selectedUser: null
        }
    }
    handleCheck = (e) => {
        let isChecked = e.target.checked;
        if (isChecked) {
            if (e.target.id == "project" ) {
                this.setState({ projectTitleField: true })
            }
            else {
                this.setState({ writerField: true })
            }
            
        }
        else {
            if (e.target.id == "project" ) {
                this.setState({ projectTitleField: false })
            }
            else {
                this.setState({ writerField: false })
            }
        }
    }

    componentDidMount () {
        this.getEntity('projectList', 'PROJECT', '');
        this.getEntity('userList', 'USER', '');
    }

    getEntity = (entity, entitytype, searchString) => {
        let loading = (entitytype === 'PROJECT') ? 'isProjectListLoading' : (entitytype === 'USER') ? 'isUserListLoading' : 'loading';
        this.setState({ [loading]: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`,
            this.props.userContext.active_tenant.tenant_id, this.props.userContext.active_tenant.division_id)
            .then(response => {
                let formattedList = [];
                console.log("Project Response: ",response);
                if (entitytype === 'PROJECT') {
                    formattedList = response?.data?.map(item => ({ value: item.project_id, label: item.project_title }));
                } else if (entitytype === 'USER') {
                    formattedList = response?.data?.map(item => ({ value: item.user_id, label: item.user_name }));
                }
                this.setState({[entity]: formattedList, [loading]: false });
                console.log("Entity: ",this.state.userList);
            },
                (err) => {
                    this.setState({ [loading]: false });
                    console.log("Error in fetching entities:", err)
                })
    }

    render() {

        let isLoading = this.state.isFetchingDealInformationReportDetails && this.state.isFetchingDealInformationReportTypes;
        return (
            <>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBContainer className="DealInformationReportContainer">
                            <MDBRow>
                                <BasicLabel type={"fieldHeading"} text={"Search By"} />
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={1} className="basicCheck">
                                    <BasicCheckbox
                                        className="UserCheckBox"
                                        id={"project"}
                                        //label="Custom"
                                        checked={this.state.projectTitleField}
                                        onChange={(e) => this.handleCheck(e)}
                                    />

                                </MDBCol>
                                <MDBCol md={11} className="searchSelectcontent">
                                    <SelectField
                                        id={"project"}
                                        className={"Project"}
                                        placeHolderText={"-Select Project Title-"}
                                        disabled={!this.state.projectTitleField}
                                        options={this.state.projectList}
                                        value={this.state.selectedProject || ""}
                                        onChange={ (e) => this.setState({selectedProject: e.target.value}) || ""}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={1} className="basicCheck">
                                    <BasicCheckbox
                                        className="UserCheckBox"
                                        id={"writer"}
                                        //label="Custom"
                                        checked={this.state.writerField}
                                        onChange={(e) => this.handleCheck(e)}
                                    />
                                </MDBCol>
                                <MDBCol md={11}>
                                    {console.log("User List:", this.state.userList)}
                                    <SelectField
                                        id={"project"}
                                        className={"Project"}
                                        placeHolderText={"- Select Writer -"}
                                        disabled={!this.state.writerField}
                                        options={this.state.userList}
                                        value={this.state.selectedUser || ""}
                                        onChange={ (e) => this.setState({selectedUser: e.target.value}) || ""}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <BasicCheckbox
                                    id="Stunt_Only"
                                    label="Include Deal Notes"
                                // checked={this.state.is_ssn === 1}
                                // align="start"
                                // onChange={e => this.handleCheck(e)}
                                />
                            </MDBRow>
                            <MDBRow>
                                <BasicCheckbox
                                    id="Stunt_Only"
                                    label="Include Deal Step Notes"
                                // checked={this.state.is_ssn === 1}
                                // align="start"
                                // onChange={e => this.handleCheck(e)}
                                />
                            </MDBRow>
                            <MDBRow className="p-2">
                                <MDBCol md={8} >
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        type="inline"
                                        variant="outlined"
                                        text={"Cancel"}
                                        onClick={this.props?.onClose}
                                        disabled={false}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        className="btnwidth"
                                        variant="contained"
                                        type="inline"
                                        text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate"}
                                        disabled={false}
                                        onClick={this.GenerateReportDetails}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    )}
            </>
        )
    }
}
);