import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import './AsynchronousSearch.scss';
import SearchIcon from "@material-ui/icons/Search";
import { selectOptionsFeature } from "./Asynchronous";
import SearchResultsLayout from './SearchResultsLayout';
import { withUserContext } from '../../../contexts/UserContext';
import * as Constants from '../../../constants/constants';
import ScripterService from '../../../services/service';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { BrowserRouter as Router, Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link'
import ModalComponent from '../../../Common/DefaultModalLayout/Modal/Modal';
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';
import { talentNavList, agentNavList, representativeNavList } from '../../../constants/NavList';

class AsynchronousSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            options: [],
            loading: false,
            isOpen: false,
            parentDropDownSelection: 2,
            selectOptionsFeature: JSON.parse(JSON.stringify([...selectOptionsFeature])),
            childDropDownSelection: [],
            list: [],
            defautSelection: [],
            searchKeyWord: '',
            showTalentPopup: false,
            showAgencyPopup: false,
            showSubmissionModal: false,
            showRepresentativePopup: false,
            talentId: null,
            agencyId: null,
            repId: null,
            submissionId: null,
            totalCount: 0,
            searchCancelToken: null,
            tenant_id: null,
            modifyMenuOptions: () => this.modifyMenuOptions()
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props?.userContext?.active_tenant?.tenant_id !== state.tenant_id) {
            state.modifyMenuOptions();
            return {
                tenant_id: props?.userContext?.active_tenant?.tenant_id
            }
        }
        return null;
    }

    modifyMenuOptions = () => {
        this.setState({  tenant_id: this.props?.userContext?.active_tenant?.tenant_id }, () => {
            let selectOptions = JSON.parse(JSON.stringify([...this.state.selectOptionsFeature]))
            let permissions = this.props?.userContext?.active_tenant?.permissions;
            if (!permissions?.canViewNonConfidentialSubmissions) {
                selectOptions = selectOptions.filter(item => item.value !== 2)
            }
            if (!permissions?.canViewProjects) {
                selectOptions = selectOptions.filter(item => item.value !== 3)
            }
            if (!permissions?.canViewDeal) {
                selectOptions = selectOptions.filter(item => item.value !== 4)
            }
            if (!permissions?.canViewWeekendRead) {
                selectOptions = selectOptions.filter(item => item.value !== 5)
            }
            if (!permissions?.canViewTalentRepAgencies) {
                selectOptions = selectOptions.filter(item => item.value !== 6)
            }
            this.setState({ selectOptionsFeature: selectOptions, parentDropDownSelection: selectOptions[0]?.value || null })
        })
    }

    onChangeHandle = (searchString) => {
        let searchDetails = this.state.selectOptionsFeature?.find(item => item.value === this.state.parentDropDownSelection);
        if (searchString !== "") {
            this.setState({loading:true});
            if (this.state.searchCancelToken != null){
            this.state.searchCancelToken.cancel("Operation canceled due to new request.");}
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
            ScripterService.getDataWithCancel(Constants.scripterServiceBaseUrl +
                `/entitySearch?entity=${searchDetails?.searchType}&searchString=${searchString}`,
                cancelToken, this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    let formattedList = [];
                    if (searchDetails?.searchType === 'TALENT') {
                        formattedList = response?.data?.map(item => (
                        {
                            value: item[searchDetails.id],
                            name: item[searchDetails.name],
                            date_created: item[searchDetails.date],
                            type: !item?.writer_team ? searchDetails?.searchType : 'talent_group',
                            resultlabel:!item?.writer_team ? this.capitalizeFirstLetter(searchDetails?.searchType?.toLowerCase()) : this.capitalizeFirstLetter('talent_group'.toLowerCase()),
                            writerTeam: item?.writer_team
                        }));
                    } else {
                        formattedList = response?.data?.map(item => (
                        {
                            value: item[searchDetails.id],
                            name: searchDetails?.searchType === 'PROJECT' ?
                                item.aka != null ? item[searchDetails.name] + " + " + item.aka : item[searchDetails.name] : 
                                item[searchDetails.name],
                            date_created: searchDetails?.searchType === 'PROJECT' ?"":item[searchDetails.date],
                            type: searchDetails?.searchType,
                            resultlabel:searchDetails?.searchType === 'SUBMISSION'? item[searchDetails.coverageType]: searchDetails?.searchType === 'PROJECT'? item[searchDetails.projectStatus]:
                            searchDetails?.searchType === 'DEAL_MEMO'?item[searchDetails.projectTitle]:this.capitalizeFirstLetter(searchDetails?.searchType?.toLowerCase())
                        }));
                    }
                    this.setState({ options: formattedList, loading: false, searchKeyWord: searchString, totalCount: response?.data?.length, list: response?.data });
                },
                    (err) => {
                        this.setState({ options: [], loading: false, totalCount: 0 })
                    })
        } else {
            this.setState({ options: [], loading: false, searchKeyWord: "", totalCount: 0 });
        }
    }

    capitalizeFirstLetter = (str) => {
        if (str) {
            var i, frags = str?.split('_');
            for (i = 0; i < frags?.length; i++) {
                frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
            }
            return frags?.join(' ');
        }
        return '';
    }

    filterOptions = createFilterOptions({
        stringify: (option) => option.name
    });

    handleParentSearchChange = (event) => {
        this.setState({ parentDropDownSelection: event.target.value, options: [], searchKeyWord: "", totalCount: 0 })
    };

    handleSearchOption = (obj) => {
        if (obj) {
            if (obj.type == 'DEAL_MEMO') {
                window.open("/dealDetails/" + obj.value);
            } else if (obj.type == 'WEEKEND_READ') {
                window.open("/weekendReadDetails/" + obj.value);
            } else if (obj.type == 'SUBMISSION') {
                window.open("/submissionDetails/" + obj.value)
            } else if (obj.type == 'PROJECT') {
                window.open("/projectDetails/" + obj.value);
            } else if (obj.type == 'TALENT' || obj.type == 'talent_group') {
                this.functions.handleShowTalentPopup(obj.value, obj.writerTeam)
            }
        }
    }

    functions = {
        handleShowTalentPopup: (id = null, writerTeam = 0) => {
            if (writerTeam) {
                this.setState({ talentGroupId: id })
            } else {
                this.setState({ talentId: id });
            }
            this.setState({ showTalentPopup: true, talentWriterTeam: writerTeam });
        },
        handleHideTalentPopup: () => {
            this.setState({ showTalentPopup: false });
        },

        handleShowAgencyPopup: (agencyId = null) => {
            this.setState({ showAgencyPopup: true, agencyId: agencyId });
        },
        handleHideAgencyPopup: () => {
            this.setState({ showAgencyPopup: false });
        },

        handleShowRepresentativePopup: (repId = null) => {
            this.setState({ showRepresentativePopup: true, repId: repId });
        },
        handleHideRepresentativePopup: () => {
            this.setState({ showRepresentativePopup: false });
        },
        handleShowSubmissionPopup: (subId = null) => {
            this.setState({ showSubmissionModal: true, submissionId: subId })
        },
        handleHideSubmissionPopup: () => {
            this.setState({ showSubmissionModal: false })
        }
    }

    render() {
        return (
            <>
                <div class='row PLSearch'>
                    <div class='col-1 ptPt4'>
                        <Link component={RouterLink} className="action-icon" to={'/advancedSearch'}>
                            <AutoFixHighIcon htmlColor="white" size={20} />
                        </Link>
                    </div>
                    <div class='col-3' id='txtAllSelect'>
                        <Select
                            variant="outlined"
                            displayEmpty className='selectStyle'
                            defaultValue={this.state.parentDropDownSelection}
                            value={this.state.parentDropDownSelection}
                            onChange={this.handleParentSearchChange}
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                }
                            }}
                        >
                            {
                                this.state.selectOptionsFeature?.map((item, index) => {
                                    return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                })
                            }
                        </Select>

                    </div>
                    <div class='col-8 ptPt4' id='txtSearch'>
                        <Autocomplete value={this.state.defautSelection}
                            id="asynchronous-demo" className='autoCompleteStyle'
                            open={this.state.open}
                            onOpen={() => {
                                this.setState({ open: true })
                            }}
                            onClose={() => {
                                this.setState({ open: false })
                            }}
                            renderOption={(props, option) => (
                                <div className='w-100'>
                                    <li {...props}>
                                        <SearchResultsLayout searchResult={props}></SearchResultsLayout>
                                    </li>
                                </div>
                            )}
                            onChange={(event, newValue) => {
                                if (newValue && newValue.result_type != 'recordCount') {
                                    this.handleSearchOption(newValue)
                                } else if (newValue && newValue.result_type == 'recordCount') {
                                    this.setState({ defautSelection: [] })
                                    this.props.props.history.push('/advancedSearch',
                                        {
                                            type: this.state.selectOptionsFeature.find(item => item.value === this.state.parentDropDownSelection)?.searchGroup,
                                            subGroup: this.state.selectOptionsFeature.find(item => item.value === this.state.parentDropDownSelection)?.searchSubGroup,
                                            searchParam: this.state.selectOptionsFeature.find(item => item.value === this.state.parentDropDownSelection)?.searchParam,
                                            searchKey: this.state.searchKeyWord
                                        })
                                } else {
                                    this.setState({ defautSelection: [] })
                                }
                                this.setState({ options: [], totalCount: 0 })
                            }}

                            filterOptions={(options, params) => {
                                options = this.filterOptions(options, params)
                                if (this.state.totalCount > 0) {
                                    let type = this.state.totalCount === 1 ? 'result' : 'results'
                                    options.unshift({
                                        sub_group: `${this.state.totalCount} search ${type}`
                                    })
                                    // if (this.state.totalCount > 1) {
                                    //     options.push({
                                    //         name: `See all ${this.state.totalCount} results`,
                                    //         result_type: 'recordCount'
                                    //     });
                                    // }
                                } else {
                                    options = []
                                }
                                return options;
                            }}
                            getOptionLabel={(option) => option.name}
                            options={this.state.options}
                            loading={this.state.loading}
                            renderTags={() => null}
                            noOptionsText="No labels"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Search..."
                                    variant="outlined"
                                    onChange={ev => {
                                        if (ev.target.value !== "" || ev.target.value !== null) {
                                            this.setState({ loading: true }, () => { this.onChangeHandle(ev.target.value) })
                                        } else { this.setState({ loading: true, options: [], totalCount: 0 }) }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {!this.state.loading ? <SearchIcon className='MRN3'/> : <CircularProgress className='MRN3' color="inherit" size={20} />}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
                {
                    this.state.showSubmissionModal &&
                    <MainViewModalComponent
                        open={this.state.showSubmissionModal}
                        handleClose={() => this.setState({ showSubmissionModal: false, submissionId: null })}
                        headerText={"Submission"}
                        mode={this.state.submissionId ? "Edit" : "New"}
                        modalName={"Submission"}
                        submissionId={this.state.submissionId}
                    />
                }
                {
                    this.state.showAgencyPopup ?
                        <ModalComponent
                            navFuns={this.functions}
                            open={this.state.showAgencyPopup}
                            handleClose={this.functions.handleHideAgencyPopup}
                            tabList={agentNavList}
                            bodyText={"Search for an Agency to view its Data"}
                            searchPlaceholder={"Search by Agency name or type"}
                            addButtonText={"New Agency"}
                            agencyId={this.state.agencyId}
                            objectType={'AGENCY'}
                            headerText={"Agency"}
                            dataUrl='agency' /> : null

                }
                {
                    this.state.showRepresentativePopup ?
                        <ModalComponent
                            navFuns={this.functions}
                            open={this.state.showRepresentativePopup}
                            handleClose={this.functions.handleHideRepresentativePopup}
                            tabList={representativeNavList}
                            bodyText={"Search for a Representative to view its Data"}
                            searchPlaceholder={"Search by Representative name or Agency name"}
                            addButtonText={"New Representative"}
                            repId={this.state.repId}
                            objectType={'REPRESENTATIVE'}
                            headerText={"Representatives"}
                            dataUrl='representative' /> : null
                }
                {
                    this.state.showTalentPopup ?
                        <ModalComponent
                            navFuns={this.functions}
                            talentId={this.state.talentId}
                            talentGroupId={this.state.talentGroupId}
                            talentWriterTeam={this.state.talentWriterTeam}
                            open={this.state.showTalentPopup}
                            handleClose={this.functions.handleHideTalentPopup}
                            tabList={talentNavList}
                            bodyText={"Search for Talent to view their Data, or create a new Talent"}
                            addButtonText={"New Talent"}
                            searchPlaceholder={"Search Talent by name or SSN"}
                            objectType={'TALENT'}
                            headerText={"Talent"}
                            dataUrl='talent' /> : null

                }
            </>
        );
    }

}
export default withRouter(withUserContext(AsynchronousSearch));