import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import TabsComponent from '../../../SharedComponents/Tabs/Tabs';
import { submissionAssignedAnalystList } from '../../../../constants/TabList';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import AssignedAnalystDetails from './AssignedAnalystDetails';
import SelectField from '../../../SharedComponents/SelectField/SelectField';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import BasicTextArea from '../../../SharedComponents/BasicTextArea/BasicTextArea';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import MessageModal from '../../../SharedComponents/MessageModal';
import MenuField from '../../../SharedComponents/MenuField/MenuField';
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import RichTextEditor from '../../../SharedComponents/RichTextEditor/RichTextEditor';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import messages from '../../../../Common/Messages.json';
import { validateCharNumOnlyWithSpace, getFormattedDate } from '../../../../Common/Helper';
import sizeLimit from '../../../../Common/SizeLimits.json';
import { coverageMenuOptions, submissionWorkflowMockData, filePayload } from './Config';
import { withUserContext } from '../../../../contexts/UserContext';
import dompurify from 'dompurify';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#e6e6e6",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 380,
        border: "1px solid #dadde9",
        marginTop: "-0.2rem",
        whiteSpace: "pre-line"
    }
}))(Tooltip);
class NewAssignedAnalyt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            coverageDetails: props?.coverage,
            expanded: false,
            mode: 'default',
            tabValue: null,
            assignedAnalystDetails: {},
            genres: [],
            recommendations: [],
            writerRecommendations: [],
            isFetchingGenres: false,
            isFetchingRecommendations: false,
            isFetchingWriterRecommendations: false,
            isFetchingCoverage: false,
            postInitiated: false,
            isPosting: false,
            anchorEl: null,
            showCancelCoverageModal: false,
            isCoveragePdfPosting: false,
            showRecordCannotbeDeleted: false,
            tabList: JSON.parse(JSON.stringify(submissionAssignedAnalystList))
        }
    }

    componentDidMount() {
        this.getGenres();
        this.getRecommendations();
        this.getWriterRecommendations();
        if (window.location.href.indexOf("coverage") > -1) {
            this.handleScrollToCoverage()
        } else {
            this.props?.onChangeAnalystExpansion(0, true)
        }
        this.filterTabList()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.checkIfCoverageModalOpen != this.props?.checkIfCoverageModalOpen) {
            if (this.props?.checkIfCoverageModalOpen) {
                this.props?.onChangeAnalystExpansion(false)
            }
        }
        if (prevProps?.coverage != this.props?.coverage) {
            this.setState({ coverageDetails: this.props?.coverage })
        }
    }

    filterTabList = () => {
        let tabList = [...this.state.tabList]
        switch (this.state.coverageDetails.coverage_type_name) {
            case "Amended Writing Credit Determination":
            case "Extended Coverage":
            // case "Memo / Report":
            case "Project":
            case "Quality of Writing":
            case "Regular":
            case "Project Regular":
                // case "Chapter Breakdown":
                tabList = tabList.filter(item => item.value !== 2)
                break;
            case "Capsule Synopsis Only":
            // case "Character Analysis":
            case "Chapter Breakdown":
            case "Memo / Report":
                tabList = tabList.filter(item => item.value === 1)
                break;
            case "Legal":
            case "Legal Amendment":
            case "Legal Addendum":
                tabList = tabList.filter(item => item.value != 2)
                let synopsisTabIndex = tabList.findIndex((obj => obj.value == 1));
                tabList[synopsisTabIndex].label = "Analysis"
                break;
            case "Character Analysis":
                tabList = tabList.filter(item => item.value === 1)
                let firstTabIndex = tabList.findIndex((obj => obj.value == 1));
                tabList[firstTabIndex].label = "Character Analysis"
                break;
            case "Scene Breakdown":
                tabList = tabList.filter(item => item.value != 2)
                let tabIndex = tabList.findIndex((obj => obj.value == 1));
                tabList[tabIndex].label = "Scene Breakdown"
                break;
            case "Second Opinion":
            case "Second Opinion Project":
                tabList = tabList.filter(item => item.value === 3)
                let objIndexTab = tabList.findIndex((obj => obj.value === 3));
                tabList[objIndexTab].label = "Second Opinion"
                break;
            case "Third Opinion":
                tabList = tabList.filter(item => item.value === 3)
                let indObj = tabList.findIndex((obj => obj.value === 3));
                tabList[indObj].label = "Third Opinion"
                break;
            case "Suggested Cuts":
                tabList = tabList.filter(item => item.value != 2)
                let indexObj = tabList.findIndex((obj => obj.value === 1));
                tabList[indexObj].label = "Suggested Cuts"
                break;
            case "Title Search Suggestions":
                tabList = tabList.filter(item => item.value != 2)
                let tabObjIndex = tabList.findIndex((obj => obj.value === 1));
                tabList[tabObjIndex].label = "Title Search Suggestions"
                break;
            case "Top Sheet Comparison":
                tabList = tabList.filter(item => item.value !== 1)
                break;
            case "Writing Credit Determination":
                tabList = tabList.filter(item => item.value !== 2)
                break;
        }
        this.setState({ tabList: tabList, tabValue: tabList[0]?.value })
    }

    handleScrollToCoverage = () => {
        let id = window.location.href?.split('=')[1];
        let coverageIndex = this.props?.findCoverageIndex(parseInt(id))
        document.getElementById('coverage-' + id)?.scrollIntoView();
        this.props?.onChangeAnalystExpansion(coverageIndex, true)
    }

    getCoverage = () => {
        this.setState({ isFetchingCoverage: true })
        let coverageId = this.props?.coverage?.submission_coverage_id || this.state.coverageId;
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/submissionCoverages?submissionCoveragesId=${coverageId}`,
            this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(response => {
                if (response.data.error) {
                    console.log("response error", response)
                } else {
                    let coverageDetails = response?.data?.[0];
                    coverageDetails.is_cancel_coverage = this.props?.coverage?.is_cancel_coverage;
                    coverageDetails.is_confirm_coverage = this.props?.coverage?.is_confirm_coverage;
                    coverageDetails.is_delete = this.props?.coverage?.is_delete;
                    coverageDetails.is_edit_coverage = this.props?.coverage?.coverage.is_edit_coverage;
                    coverageDetails.is_revise_coverage = this.props?.coverage?.is_revise_coverage;
                    coverageDetails.is_submit_coverage = this.props?.coverage?.is_submit_coverage;
                    // coverageDetails.is_delete = this.props?.coverage.is_delete;
                    // coverageDetails.is_edit_coverage = this.props?.coverage.is_edit_coverage;
                    // coverageDetails.is_cancel_coverage = this.props?.coverage.is_cancel_coverage;
                    // coverageDetails.is_revise_confirm_coverage = this.props?.coverage.is_revise_confirm_coverage;
                    // coverageDetails.is_submit_coverage = this.props?.coverage.is_submit_coverage;
                    this.setState({ isFetchingCoverage: false, coverageDetails: coverageDetails });
                    this.props?.updateCoverageDetails(coverageId, coverageDetails)
                }
            },
                (err) => {
                    this.setState({ isFetchingCoverage: false })
                })
    }

    getGenres = () => {
        this.setState({ isFetchingGenres: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_genre',
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, text: item.name }));
                this.setState({ genres: formattedList, isFetchingGenres: false });
            },
                (err) => {
                    this.setState({ isFetchingGenres: false });
                    console.log("Error in fetching coverage types:", err)
                })
    }

    getRecommendations = () => {
        this.setState({ isFetchingRecommendations: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_recommendation',
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ recommendations: formattedList, isFetchingRecommendations: false });
            },
                (err) => {
                    this.setState({ isFetchingRecommendations: false });
                    console.log("Error in fetching coverage types:", err)
                })
    }

    getWriterRecommendations = () => {
        this.setState({ isFetchingWriterRecommendations: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_writer_recommendation',
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ writerRecommendations: formattedList, isFetchingWriterRecommendations: false });
            },
                (err) => {
                    this.setState({ isFetchingWriterRecommendations: false });
                    console.log("Error in fetching coverage types:", err)
                })
    }

    handleChange = (coverageIndex, isExpand) => {
        this.setState({ mode: 'default', coverageDetails: this.props?.coverage })
        this.props?.onChangeAnalystExpansion(coverageIndex, isExpand)
    }

    handlePageRefresh = (page) => {
        if (page === 'coverage') {
            this.getCoverage();
        } else {
            this.props?.handlePageRefresh(page)
        }
    }

    onChangeCallback = (field, value) => {
        if (field === "genre_list") {
            let newValue = value.filter((val, index, arr) =>
                index === arr.findIndex((item) => (
                    item.value === val.value))
            )
            let genreValue = newValue?.map(item => ({
                submission_coverage_genre_id: null,
                genre_id: item.value,
                genre_name: item.text,
            }))
            this.setState(prevState => ({
                coverageDetails: {
                    ...prevState.coverageDetails,
                    [field]: genreValue || null,
                },
                postInitiated: false
            }))
        } else if (field === "recommendation") {
            let recommendationName = this.state.recommendations?.find(type => type.value === value)?.label;
            this.setState(prevState => ({
                coverageDetails: {
                    ...prevState.coverageDetails,
                    [field + '_id']: value || null,
                    [field + '_name']: recommendationName || null,
                },
                postInitiated: false
            }))
        } else if (field === "writer_recommendation") {
            let writerRecommendationName = this.state.writerRecommendations?.find(type => type.value === value)?.label;
            this.setState(prevState => ({
                coverageDetails: {
                    ...prevState.coverageDetails,
                    [field + '_id']: value || null,
                    [field + '_name']: writerRecommendationName || null,
                },
                postInitiated: false
            }))
        } else {
            this.setState(prevState => ({
                coverageDetails: {
                    ...prevState.coverageDetails,
                    [field]: value || null,
                },
                postInitiated: false
            }))
        }
    }

    handleLoglineOnChange = (value, field) => {
        this.onChangeTextEditor(value, field)
    }

    onChangeTextEditor = (value, field) => {
        let fieldSelected = null;
        if (this.state.tabValue === 1) {
            fieldSelected = 'synopsis'
        } else if (this.state.tabValue === 2) {
            fieldSelected = 'comparison'
        } else {
            fieldSelected = 'comments'
        }
        if (field === 'logline') {
            fieldSelected = field;
        }
        this.setState(prevState => ({
            coverageDetails: {
                ...prevState.coverageDetails,
                [fieldSelected]: value,
            },
            postInitiated: false
        }))
    }

    handleEdit = () => {
        this.setState({ mode: "edit" })
    }

    canSubmit = () => {
        let { genre_list, locale, time, logline, recommendation_summary, writer_summary,
            recommendation_id, writer_recommendation_id, synopsis, comparison, comments, coverage_type_name
        } = this.state.coverageDetails;
        let canSubmit = false;
        if (coverage_type_name === "Amended Writing Credit Determination" || coverage_type_name === "Legal Addendum" || coverage_type_name === "Legal Amendment" || coverage_type_name === "Legal" || coverage_type_name === "Memo / Report" || coverage_type_name === "Title Search Suggestions" || coverage_type_name === "Writing Credit Determination") {
            canSubmit = (logline && (logline?.length <= sizeLimit.largeNotes) &&
                (recommendation_summary ? recommendation_summary.length <= sizeLimit.summaryLimit : true) &&
                (writer_summary ? writer_summary.length <= sizeLimit.summaryLimit : true) &&
                recommendation_id && writer_recommendation_id);
        } else {
            canSubmit = ((genre_list?.length > 0) && locale && (locale?.length <= sizeLimit.summaryLimit) &&
                time && (time?.length <= sizeLimit.timeLimit) &&
                logline && (logline?.length <= sizeLimit.largeNotes) &&
                (recommendation_summary ? recommendation_summary.length <= sizeLimit.summaryLimit : true) &&
                (writer_summary ? writer_summary.length <= sizeLimit.summaryLimit : true) &&
                recommendation_id && writer_recommendation_id);
        }
        return canSubmit;
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true })
        // if (this.canSubmit()) {
        this.setState({ isPosting: true })
        this.postSubmissionCoverageDetails();
        // } else {
        //     console.log('Coverage Submission Failed');
        // }
    }

    handleCancel = () => {
        this.props?.onChangeAnalystExpansion(this.props?.index, true)
        this.setState({ mode: "default", coverageDetails: this.props?.coverage })
    }

    postSubmissionCoverageDetails = () => {
        let postJson = { ...this.state.coverageDetails };
        delete postJson.isExpanded;
        this.props?.onChangeAnalystExpansion(this.props?.index, true)
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/submissionCoverages', postJson,
            this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isPosting: false, mode: "default" })
                    this.props?.manageMessageModal('Fail', true)
                } else {
                    this.setState({
                        postInitiated: false,
                        isPosting: false,
                        mode: "default",
                        coverageId: response.data?.[0]?.submission_coverage_id
                    }, () => {
                        this.getCoverage()
                        let coverageStatus = {
                            'Sent to Story Analyst': 'ASSIGN COVERAGE',
                            'Return to Story Department': 'RETURN TO STORY DEPARTMENT',
                            'Return to Executive': 'APPROVE COVERAGE',
                            'Return For Revision (Coverage)': 'REVISE COVERAGE'
                        }
                        let workFlowJson = {}
                        workFlowJson.submission_id = this.props?.submissionId;
                        workFlowJson.submission_coverage_id = this.state.coverageId;
                        workFlowJson.action_label = coverageStatus[this.props?.coverage?.coverage_status];
                        workFlowJson.created_at = new Date().toLocaleString('en-US', { hour12: false });
                        workFlowJson['is_edit_coverage'] = 1;
                        // if (this.props?.coverage?.coverage_status === 'Return to Executive' && this.state.mode === 'edit') {
                        this.postWorkflowSubmission(workFlowJson, '', false, coverageStatus[this.props?.coverage?.coverage_status])
                        // }
                    });
                    this.props?.manageMessageModal('Success', true)
                }
            },
                (err) => {
                    console.log("Error in posting coverage:", err)
                    this.setState({ postInitiated: false, isPosting: false, mode: "default" });
                    this.props?.manageMessageModal('Fail', true)
                })
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue
        })
    }

    handleCoveragePopUp = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    onClickCallback = (val) => {
        this.setState({ showCancelCoverageModal: true })
    }

    cancelCoverage = () => {
        this.setState({ isPosting: true })
        let postJson = this.getPostJsonData("CANCEL COVERAGE");
        this.postWorkflowSubmission(postJson, 'showCancelCoverageModal', true, 'cancel_coverage');
    }

    getPostJsonData = (actionLabel) => {
        let postJson = { ...submissionWorkflowMockData };
        postJson.submission_id = this.props?.submissionId;
        postJson.submission_coverage_id = this.props?.coverage?.submission_coverage_id || this.state.coverageId;
        postJson.action_label = actionLabel;
        postJson.created_at = new Date().toLocaleString('en-US', { hour12: false });

        return postJson
    }

    postWorkflowSubmission = (postJson, modal, refreshSubmissionPage = false, actionLabel) => {
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/submissionCoverageWorkflow', postJson,
            this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isPosting: false, [modal]: false })
                    this.props?.manageMessageModal('Fail', true)
                } else {
                    if (actionLabel == 'cancel_coverage') {
                        if (response.data && response.data.length > 0) {
                            this.setState({ isPosting: false, [modal]: false, showRecordCannotbeDeleted: true });
                        } else {
                            this.setState({ isPosting: false, [modal]: false });
                            this.props?.manageMessageModal('Success', true, refreshSubmissionPage, actionLabel)
                        }
                    } else {
                        this.setState({ isPosting: false, [modal]: false });
                        this.props?.manageMessageModal('Success', true, refreshSubmissionPage, actionLabel)
                    }

                }
            })
            .catch(err => {
                console.log("Error in confirming submission:", err)
                this.setState({ isPosting: false, showCancelCoverageModal: false });
                this.props?.manageMessageModal('Fail', true)
            })
    }

    formatCoverageType = () => {
        let formattedCoverageType = this.props?.coverage?.coverage_type_name.replace(/\w+/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1);
        }).replace(/\s/g, '');

        return formattedCoverageType
    }

    getFiles = () => {
        let reportName = null;
        if (this.props?.coverage?.coverage_type_name === "Memo / Report") {
            reportName = "MemoReport";
        } else {
            reportName = this.formatCoverageType();
        }
        let payload = { ...filePayload };
        payload.tenantName = this.props?.userContext?.active_tenant?.tenant_name;
        payload.submissionId = this.props?.submissionId;
        payload.module = "submission_coverage";
        payload.submissionCoverageId = this.props?.coverage?.submission_coverage_id || this.state.coverageId;
        ScripterService.getDataParams(Constants.scripterServiceBaseUrl + `/getFiles`, payload,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({ showLoader: false })
                if (response.data?.error) {
                    this.setState({ isCoveragePdfPosting: false })
                    console.log("Error from fetch file", response.data?.error)
                    return;
                }
                this.setState({ isCoveragePdfPosting: false })
                if (this.props?.coverage?.default_extension === 'PDF') {
                    let url = response.data?.Contents[0]?.url;
                    let finalReportName = `${reportName}Report`
                    this.downloadUrl(url, finalReportName)
                } else {
                    window.open((response.data?.Contents[0]?.url)?.replaceAll('"', ''), '_blank');
                    this.props.history.push('/submissionDetails/' + `${this.props?.submissionId}`);
                }
            }, err => {
                console.log('from misc files', err)
                this.setState({ showLoader: false, isCoveragePdfPosting: false })
            })
    }

    handleCoveragePdf = () => {
        if (this.props?.coverage?.coverage_number) {
            if (this.props?.userContext?.user_profile?.group_name === 'Feature Story Admin' || 
                this.props?.coverage?.default_extension === 'PDF'
            ) {
                this.setState({ isCoveragePdfPosting: true })
                this.getFiles();
            } else {
                this.setState({ showCoverageDownloadAlert: true })
            }
        } else {
            this.setState({ isCoveragePdfPosting: true })
            let reportName = null;
            if (this.props?.coverage?.coverage_type_name === "Memo / Report") {
                reportName = "MemoReport";
            } else {
                reportName = this.formatCoverageType();
            }
            let json = {
                "submission_id": this.props?.submissionId,
                "submission_coverage_id": this.props?.coverage?.submission_coverage_id || this.state.coverageId
            };
            let load = {
                "payload": 'generateReport',
                "reportName": `rptSc${reportName}`,
                "searchJson": btoa(JSON.stringify(json)),
                "showHtml": 0,
                "isStaticReport": 0,
                "encryptionType": 1,
                "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
                "divisionId": this.props?.divisionId
            }
            console.log("load ws", load, this.props?.coverage?.coverage_type_name);
            if (this.props?.coverage?.coverage_type_name != 'Not Covered' && this.props?.coverage?.coverage_type_name != 'N/A'
                && this.props?.coverage?.coverage_type_name != 'Microfiche - Regular' && this.props?.coverage?.coverage_type_name != 'Other') {
                ScripterService.getDataWS(load)
                    .then(webSocketConn => {
                        webSocketConn.onmessage = (e) => {
                            console.log("wssss", e);
                            let response = JSON.parse(e.data)
                            if (response?.statusCode == 200) {
                                this.setState({ isCoveragePdfPosting: false })
                                let url = response.body;
                                let finalReportName = `${reportName}Report`
                                this.downloadUrl(url, finalReportName)
                            } else {
                                this.setState({ isCoveragePdfPosting: false })
                            }
                        }

                    })
            } else {
                this.setState({ isCoveragePdfPosting: false })
            }
        }
    }

    downloadUrl = (url, reportName) => {
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', reportName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        }
    }

    formatSubmissionCompareTo = (compareTo) => {
        let formattedCompareToList = [];
        compareTo?.map(item => {
            if (item?.compare_to) {
                formattedCompareToList?.push(item?.compare_to)
            }
        })
        return formattedCompareToList?.join(', ');
    }

    handleCompareToTooltipDetails = (compareTo) => {
        let formattedCompareToList = [];
        compareTo?.map(item => {
            if (item?.compare_to) {
                formattedCompareToList?.push(item?.compare_to)
            }
        })
        return formattedCompareToList?.join('\n');
    }

    trimHtml = (value) => {
        let html = value;
        let divElement = document.createElement("div");
        divElement.innerHTML = html;
        return (divElement.innerText?.length || 0)
    }

    render() {
        const sanitizer = dompurify.sanitize;
        return (
            <div id="assignedAnalyst">
                <Accordion
                    id={`coverage-${this.props?.coverage?.submission_coverage_id || this.state.coverageId}`}
                    key={this.props?.index}
                    expanded={this.props?.coverage?.isExpanded}
                >
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        expandIcon={
                            <>
                                {this.props?.coverage?.isExpanded ?
                                    <MDBIcon
                                        onClick={() => this.handleChange(this.props?.index, false)}
                                        icon="minus-circle" size='sm'
                                    /> :
                                    <MDBIcon
                                        onClick={() => this.handleChange(this.props?.index, true)}
                                        icon="plus-circle" size='sm'
                                    />
                                }
                                <div className="d-flex">
                                    <div className='header-title'>{this.state.coverageDetails?.analyst}</div>
                                    {this.props?.coverage?.isExpanded && this.props?.coverage?.is_cancel_coverage ?
                                        <div className='ml-1'>
                                            <MDBIcon
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={this.handleCoveragePopUp}
                                                icon={"ellipsis-h"}
                                            />
                                        </div> : ""
                                    }
                                    <div className="submissionMenu">
                                        <MenuField
                                            id={"type"}
                                            anchorEl={this.state.anchorEl}
                                            open={this.state.anchorEl}
                                            options={coverageMenuOptions || []}
                                            handleClose={() => this.setState({ anchorEl: null })}
                                            onClick={this.onClickCallback}
                                        />
                                    </div>
                                    {this.props?.coverage?.isExpanded ?
                                        <div className="ml-3">
                                            <BasicButton
                                                className="coverage-pdf-btn"
                                                variant="outlined"
                                                text={this.state.isCoveragePdfPosting ? <CircularProgress color="inherit" size={20} /> : "Coverage"}
                                                type="inline"
                                                onClick={this.handleCoveragePdf}
                                                icon={"file-pdf"}
                                            />
                                        </div>
                                        : ""}
                                </div>
                            </>
                        }
                    >
                        <div className='header-section'>
                            <div className="coverage-status-section">
                                <div className="coverage-type d-flex">
                                    {this.props?.coverage?.isExpanded && (
                                        <>
                                            <Tooltip placement="bottom" title={this.formatSubmissionCompareTo(this.props?.coverage?.submission_coverage_compare_to) || ""}>
                                                <div><MDBIcon icon="eye" size='sm' /></div>
                                            </Tooltip>
                                        </>
                                    )}
                                    <div className="coverage-type-name">
                                        <span>{this.state.coverageDetails?.coverage_type_name}</span>
                                    </div>
                                </div>
                                <span className="status">{this.state.coverageDetails?.coverage_status}</span>
                                <div className="p-0 edit-button flex">
                                    {(this.props?.coverage?.isExpanded &&
                                        this.props?.coverage?.is_edit_coverage && this.state.mode === 'default'
                                    ) ?
                                        <BasicButton
                                            type="inline"
                                            variant="outlined"
                                            text={"Edit"}
                                            onClick={this.handleEdit}
                                            icon={"pen"}
                                            disabled={false}
                                        /> : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {this.state.mode === 'default' ? (
                            <AssignedAnalystDetails
                                confidential={this.props?.confidential}
                                submissionId={this.props?.submissionId}
                                coverageDetails={this.state.coverageDetails}
                                coverageId={this.state.coverageId}
                                handleEdit={this.handleEdit}
                                sanitizer={sanitizer}
                                manageMessageModal={this.props?.manageMessageModal}
                                handlePageRefresh={this.handlePageRefresh}
                                divisionId={this.props?.divisionId}
                            />
                        ) : (
                                <MDBContainer fluid className="assignedAnalystForm p-0">
                                    <MDBRow className="details-section">
                                        <MDBCol md={1} className="date-sent">
                                            <BasicLabel text={"Sent"} />
                                            <p>{getFormattedDate(this.state.coverageDetails?.coverage_created, false)}</p>
                                        </MDBCol>
                                        <MDBCol md={1} className="p-0 due-date">
                                            <BasicLabel text={"Due"} />
                                            <p>{getFormattedDate(this.state.coverageDetails?.due_date, false)}</p>
                                        </MDBCol>
                                        {/* <MDBCol md={1} className="p-0 due-date">
                                            <BasicLabel text={"Return Date"} />
                                            <p>{getFormattedDate(this.state.coverageDetails?.return_date, false)}</p>
                                        </MDBCol> */}
                                        <MDBCol md={8} className="p-0 assigned-notes">
                                            <BasicLabel text={"Assigned Message"} />
                                            <p>{this.state.coverageDetails?.notes}</p>
                                        </MDBCol>
                                        <MDBCol className="p-0 cancel-coverage-button">
                                            <BasicButton
                                                variant="outlined"
                                                type="inline"
                                                text={"Cancel"}
                                                onClick={this.handleCancel}
                                            />
                                        </MDBCol>
                                        <MDBCol className="p-0 save-button">
                                            <BasicButton
                                                variant="contained"
                                                type="inline"
                                                text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"}
                                                icon={"save"}
                                                onClick={this.handleSubmit}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        {this.state.coverageDetails?.coverage_type_name != "Amended Writing Credit Determination" &&
                                            this.state.coverageDetails?.coverage_type_name != "Legal Addendum" &&
                                            this.state.coverageDetails?.coverage_type_name != "Legal Amendment" &&
                                            this.state.coverageDetails?.coverage_type_name != "Legal" &&
                                            this.state.coverageDetails?.coverage_type_name != "Memo / Report" &&
                                            this.state.coverageDetails?.coverage_type_name != "Writing Credit Determination" ?
                                            <MDBCol md={3}>
                                                <MDBRow>
                                                    <MDBCol
                                                        md={12}
                                                        className={`${this.state?.coverageDetails?.genre_list?.length === 0 ?
                                                            "searchSelectHeight coverageSearchSelect" :
                                                            "coverageSearchSelect"
                                                            }`}
                                                        id='coverageGenreDropDown'
                                                    >
                                                        <BasicLabel
                                                            // isMandatory={true} 
                                                            text={"Genre"}
                                                        />
                                                        <SearchSelectField
                                                            id={"genre-select"}
                                                            multiple={true}
                                                            width={"100%"}
                                                            limitTags={1000}
                                                            mandatoryWarning={messages.mandatoryWarning}
                                                            // showMandatory={this.state.postInitiated || false}
                                                            value={this.state?.coverageDetails?.genre_list?.map(item => ({ value: item.genre_id, text: item.genre_name })) || []}
                                                            options={this.state?.genres || []}
                                                            onChange={(e, value) => this.onChangeCallback('genre_list', value)}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <div className="text-area">
                                                    <BasicTextArea
                                                        label="Time"
                                                        id={"time"}
                                                        rows={2}
                                                        // isMandatory={true}
                                                        mandatoryWarning={messages.mandatoryWarning}
                                                        // showMandatory={this.state.postInitiated || false}
                                                        limit={sizeLimit.timeLimit}
                                                        limitWarning={messages.exceed300CharacterWarning || ""}
                                                        value={this.state.coverageDetails?.time || ""}
                                                        onChange={(e) => this.onChangeCallback('time', e.target.value)}
                                                        typedCharCount={this.state.coverageDetails?.time?.length || 0}
                                                        showCharCount={true}
                                                    />
                                                </div>
                                                <div className="text-area">
                                                    <BasicTextArea
                                                        label="Locale"
                                                        id={"locale"}
                                                        rows={2}
                                                        // isMandatory={true}
                                                        mandatoryWarning={messages.mandatoryWarning}
                                                        // showMandatory={this.state.postInitiated || false}
                                                        limit={sizeLimit.summaryLimit}
                                                        limitWarning={messages.exceed300CharacterWarning || ""}
                                                        value={this.state.coverageDetails?.locale || ""}
                                                        onChange={(e) => this.onChangeCallback('locale', e.target.value)}
                                                        typedCharCount={this.state.coverageDetails?.locale?.length || 0}
                                                        showCharCount={true}
                                                    />
                                                </div>
                                            </MDBCol> : null
                                        }
                                        <MDBCol className="logline text-area">
                                            <BasicLabel
                                                text={this.state.coverageDetails?.coverage_type_name === "Amended Writing Credit Determination" ||
                                                    this.state.coverageDetails?.coverage_type_name === "Legal Addendum" ||
                                                    this.state.coverageDetails?.coverage_type_name === "Legal Amendment" ||
                                                    this.state.coverageDetails?.coverage_type_name === "Legal" ||
                                                    this.state.coverageDetails?.coverage_type_name === "Memo / Report" ||
                                                    this.state.coverageDetails?.coverage_type_name === "Writing Credit Determination" ? "Assignment" : "Logline"}
                                                // isMandatory={this.state.assignCoverageDetails?.coverage_type_name === "Amended Writing Credit Determination" || this.state.assignCoverageDetails?.coverage_type_name === "Full Project Comparison" || this.state.assignCoverageDetails?.coverage_type_name === "Legal Addendum" || this.state.assignCoverageDetails?.coverage_type_name === "Legal Amendment" || this.state.assignCoverageDetails?.coverage_type_name === "Second Opinion" || this.state.assignCoverageDetails?.coverage_type_name === "Second Opinion Project" || this.state.assignCoverageDetails?.coverage_type_name === "Third Opinion" || this.state.assignCoverageDetails?.coverage_type_name === "Top Sheet Comparison" || this.state.assignCoverageDetails?.coverage_type_name === "Extended Comparison" ? true : false}
                                                typedCharCount={this.trimHtml(this.state.coverageDetails?.logline)}
                                                showCharCount={true}
                                                charLimit={sizeLimit.largeNotes}
                                            />
                                            <RichTextEditor id={`logline${this.props?.index}`}
                                                value={this.state.coverageDetails?.logline || ""}
                                                onChange={(value) => this.handleLoglineOnChange(value, 'logline')}
                                                charCount={this.state?.loglineCharCount}
                                                getCharCount={(c) => this.setState({ loglineCharCount: c })}
                                                limit={sizeLimit.largeNotes}
                                                limitWarning={messages.exceed1000CharacterWarning || ""}
                                            >
                                            </RichTextEditor>
                                        </MDBCol>
                                        {(this.state.coverageDetails?.coverage_type_name === 'Extended Coverage' ||
                                            this.state.coverageDetails?.coverage_type_name === 'Quality of Writing' ||
                                            this.state.coverageDetails?.coverage_type_name === 'Project Regular' ||
                                            this.state.coverageDetails?.coverage_type_name === 'Regular' ||
                                            this.state.coverageDetails?.coverage_type_name === 'Second Opinion' ||
                                            this.state.coverageDetails?.coverage_type_name === 'Third Opinion' ||
                                            this.state.coverageDetails?.coverage_type_name === 'Top Sheet Comparison') ?
                                            <MDBCol md={3} className="summary-section text-area">
                                                <SelectField
                                                    id={"recommendation"}
                                                    className={"recommendation"}
                                                    // isMandatory={true}
                                                    mandatoryWarning={messages.mandatoryWarning}
                                                    // showMandatory={this.state.postInitiated || false}
                                                    value={this.state.coverageDetails?.recommendation_id || ""}
                                                    label="Recommendation Summary"
                                                    defaultMenuText={"- Select -"}
                                                    options={this.state.recommendations}
                                                    onChange={(e) => this.onChangeCallback('recommendation', e.target.value)}
                                                    limit={sizeLimit.summaryLimit}
                                                    typedCharCount={this.state.coverageDetails?.recommendation_summary?.length || 0}
                                                    showCharCount={true}
                                                />
                                                <BasicTextArea
                                                    // label="Recommendation Summary"
                                                    id={"recc-summary"}
                                                    rows={2}
                                                    // isMandatory={true}
                                                    mandatoryWarning={messages.mandatoryWarning}
                                                    // showMandatory={this.state.postInitiated || false}
                                                    limit={sizeLimit.summaryLimit}
                                                    limitWarning={messages.exceed300CharacterWarning || ""}
                                                    value={this.state.coverageDetails?.recommendation_summary || ""}
                                                    onChange={(e) => this.onChangeCallback('recommendation_summary', e.target.value)}
                                                />
                                                <SelectField
                                                    id={"writer_recommendation"}
                                                    className={"writer_recommendation"}
                                                    // isMandatory={true}
                                                    mandatoryWarning={messages.mandatoryWarning}
                                                    // showMandatory={this.state.postInitiated || false}
                                                    value={this.state.coverageDetails?.writer_recommendation_id || ""}
                                                    label={"Writer Summary"}
                                                    defaultMenuText={"- Select -"}
                                                    options={this.state.writerRecommendations}
                                                    onChange={(e) => this.onChangeCallback('writer_recommendation', e.target.value)}
                                                    limit={sizeLimit.summaryLimit}
                                                    typedCharCount={this.state.coverageDetails?.writer_summary?.length || 0}
                                                    showCharCount={true}
                                                />
                                                <BasicTextArea
                                                    // label="Recommendation Summary"
                                                    id={"writer-summary"}
                                                    rows={2}
                                                    // isMandatory={true}
                                                    mandatoryWarning={messages.mandatoryWarning}
                                                    // showMandatory={this.state.postInitiated || false}
                                                    limit={sizeLimit.summaryLimit}
                                                    limitWarning={messages.exceed300CharacterWarning || ""}
                                                    value={this.state.coverageDetails?.writer_summary || ""}
                                                    onChange={(e) => this.onChangeCallback('writer_summary', e.target.value)}
                                                />
                                            </MDBCol> : null
                                        }
                                    </MDBRow>
                                    <MDBRow className="tabs-section">
                                        <MDBCol className="tab-content">
                                            <TabsComponent
                                                tabList={this.state.tabList}
                                                value={this.state.tabValue}
                                                onChange={(e, v) => { this.handleTabChange(v) }}
                                            />
                                        </MDBCol>
                                        {this.state.tabValue === 1 && (
                                            <>
                                                <MDBRow className="tab-content-width">
                                                    <MDBCol className="p-0">
                                                        <RichTextEditor id={`synopsis${this.props?.index}`}
                                                            value={this.state.coverageDetails?.synopsis || ""}
                                                            onChange={this.onChangeTextEditor}
                                                        >
                                                        </RichTextEditor>
                                                    </MDBCol>
                                                </MDBRow>
                                            </>
                                        )}
                                        {this.state.tabValue === 2 && (
                                            <>
                                                <MDBRow className="tab-content-width">
                                                    <MDBCol className="p-0">
                                                        <RichTextEditor id={`comparision${this.props?.index}`}
                                                            value={this.state.coverageDetails?.comparison || ""}
                                                            onChange={this.onChangeTextEditor}
                                                        >
                                                        </RichTextEditor>
                                                    </MDBCol>
                                                </MDBRow>
                                            </>
                                        )}
                                        {this.state.tabValue === 3 && (
                                            <>
                                                <MDBRow className="tab-content-width">
                                                    <MDBCol className="p-0">
                                                        <RichTextEditor id={`comments${this.props?.index}`}
                                                            value={this.state.coverageDetails?.comments || ""}
                                                            onChange={this.onChangeTextEditor}
                                                        >
                                                        </RichTextEditor>
                                                    </MDBCol>
                                                </MDBRow>
                                            </>
                                        )}
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md={9}></MDBCol>
                                        {this.props?.coverage?.is_submit_coverage ?
                                            < MDBCol md={3} className="return-editor">
                                                <BasicButton
                                                    variant="contained"
                                                    type="inline"
                                                    text={"Return to Story Dept"}
                                                    disabled={true}
                                                    icon={"share"}
                                                // onClick={this.handleReturnToStoryDept}
                                                />
                                            </MDBCol> : ""
                                        }
                                    </MDBRow>
                                </MDBContainer>
                            )}
                    </AccordionDetails>
                </Accordion>
                {this.state.showCancelCoverageModal &&
                    <MessageModal
                        className="cancelMessageModalButton"
                        open={this.state.showCancelCoverageModal}
                        title={"Cancel Coverage"}
                        message={"Are you sure you would like to cancel this coverage? The assigned Story Analyst will be notified. This action cannot be undone."}
                        primaryButtonText={"No"}
                        secondaryButtonText={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Yes"}
                        onConfirm={this.cancelCoverage}
                        handleClose={(e) => this.setState({ showCancelCoverageModal: false })}
                    />
                }
                {this.state.showRecordCannotbeDeleted &&
                    <MessageModal
                        open={this.state.showRecordCannotbeDeleted}
                        title={"Cancel Coverage"}
                        message={"Coverage Can not be Cancelled"}
                        primaryButtonText={"Ok"}
                        secondaryButtonText={"Close"}
                        onConfirm={(e) => this.setState({ showRecordCannotbeDeleted: false })}
                        handleClose={(e) => this.setState({ showRecordCannotbeDeleted: false })}
                    />
                }
                {this.state.showCoverageDownloadAlert &&
                    <MessageModal
                        open={this.state.showCoverageDownloadAlert}
                        title={"Download Coverage"}
                        message={"Please Contact Feature Story Administration for this older coverage.  Thank you."}
                        primaryButtonText={"Ok"}
                        hideCancel={true}
                        onConfirm={(e) => this.setState({ showCoverageDownloadAlert: false })}
                    />
                }
            </div>
        )
    }
}

export default withUserContext(NewAssignedAnalyt)