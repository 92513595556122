import React, { Component } from 'react';
import { MDBContainer, MDBRow } from "mdbreact";
import { talentProjectsList } from './MockData';
import TableComponent from '../../SharedComponents/Table';
import MessageModal from '../../SharedComponents/MessageModal';
import TablePagination from '@material-ui/core/TablePagination';
import { talentProjectsConfig } from './Config';
import { getFormattedDate } from "../../../Common/Helper";
import { withUserContext } from "../../../contexts/UserContext"
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';
import ScripterService from '../../../services/service';
import * as Constants from '../../../constants/constants';

class TalentProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 10,
            page: 0,
            renderList: [],
            projects: [],
            isLoading: false,
            mode: "new",
            rowsPerPage: 10,
            page: 0,
            config: talentProjectsConfig,
            isFetchingProjectsList: false,
            isSortByAsc: true,
            sortCount: 0,
            sortBy: "Project",
            getSortNode: this.getSortNode.bind(this),
            showMessageModal: false,
            showError: false,
            unsavedMessage: "Are you sure you want to discard your changes?"
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.mode === "edit" &&
            (this.props.talentId != nextProps.talentId || this.props?.talentGroupId != nextProps.talentGroupId)
        ) {
            this.getTalentProjectsList();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.talentId != this.props?.talentId || prevProps?.talentGroupId != this.props?.talentGroupId) {
            this.getTalentProjectsList()
        }
    }

    componentDidMount() {
        if (this.props.mode === "new") {
            this.setState({ projects: [] });
        } else if (this.props.mode === "edit") {
            this.getTalentProjectsList()
        }
        this.getTalentProjectsList();
    }

    getTalentProjectsList() {
        this.setState({ isFetchingProjectsList: true });
        let url;
        if (this.props?.talentId) {
            url = `/talent?searchKey=id&searchString=${this.props.talentId}`
        } else {
            url = `/talent?searchKey=id&searchString=${this.props.talentGroupId}&writerTeam=1`
        }
        ScripterService.getData(Constants.scripterServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, null)
            .then(response => {
                console.log("prjresponse", response.data)
                if (response.data.length > 0) {
                    let formattedList = response.data[0]?.project_list.map(obj => {
                        return {
                            "project": obj?.project_title,
                            "log_line": obj?.log_line,
                            "project_id": obj?.project_id,
                            "studio_division": obj?.studio_division
                        }
                    });
                    let list = tableSortList("Name", this.getSortNode(this.state.sortBy), formattedList, this.state.isSortByAsc)
                    this.setState({ renderList: list, isFetchingProjectsList: false, projects: list });
                } else {
                    this.setState({ isFetchingProjectsList: false })
                }
            },
                (err) => {
                    this.setState({ isFetchingProjectsList: false });
                    console.log("Error in fetching Project List:", err)
                })
    }


    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage, renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.projects) });
    }

    navigationCallback = (obj, node) => {
        if (node.includes("project")) {
            if (this.props.talentUpdated == true) {
                this.setState({ showMessageModal: true, showError: true, projectId: obj.project_id });
            } else {
                this.onHandleCloseTalentModal(obj);
            }
        }
    }

    onHandleCloseTalentModal = (obj) => {
        let projectId = obj?.project_id || this.state.projectId;
        this.props.navFuns.handleHideTalentPopup();
        window.open(`/projectDetails/${projectId}`)
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Project": return "project";
            case "Logline": return "logline";
            case "Studio Division": return "studio_division";
        }
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                renderList: tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
            });
        })
    }

    render() {
        return (
            <MDBContainer fluid className="TalentProjects">
                <MDBRow className='g-0'>
                    <TableComponent
                        list={this.state.renderList || []}
                        config={this.state.config}
                        isLoading={this.state.isFetchingProjectsList || false}
                        hyperLinkNavigationCallback={this.navigationCallback}
                        sortCallback={this.handleTableColumnClick}
                    />
                </MDBRow>
                <MDBRow className="Pagination">
                    <TablePagination
                        component="div"
                        count={this.state.projects ? this.state.projects?.length : 0}
                        rowsPerPageOptions={[]}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                    />
                </MDBRow>
                {
                    this.state.showMessageModal &&
                    <MessageModal
                        open={this.state.showError || false}
                        title={"Unsaved Changes"}
                        message={this.state.unsavedMessage}
                        hideCancel={false}
                        primaryButtonText={"OK"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={this.onHandleCloseTalentModal}
                        handleClose={(e) => this.setState({ showError: false })} />
                }
            </MDBContainer>
        );
    }
}

export default withUserContext(TalentProjects);