import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../../SharedComponents/BasicButton/BasicButton";
import SelectField from "../../../../SharedComponents/SelectField/SelectField";
import BasicLabel from "../../../../SharedComponents/BasicLabel/BasicLabel";
import SearchSelectField from "../../../../SharedComponents/SearchSelectField";
import SearchFieldWithAddValue from "../../../../SharedComponents/SearchFieldWithAddValue"
import CircularProgress from "@material-ui/core/CircularProgress";
import "rc-time-picker/assets/index.css";
import { initialTalent, talentPrefixes, talentSuffixes } from "./config";
import "./Talent.scss";
import { withUserContext } from "../../../../../contexts/UserContext";
import ScripterService from "../../../../../services/service";
import * as Constants from "../../../../../constants/constants";
import axios from "axios";
import messages from "../../../../../Common/Messages.json";
export default withUserContext(
    class AddTalent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                talentDetails: {},
                postInitiated: false,
                representiveOptions: [],
                roleOptions: [],
                isPosting: false,
                searchCancelToken: null,
                writerTeam: 0
            };
        }

        componentDidMount = () => {
            if (this.props.mode === "Add") {
                let talentDetails = { ...initialTalent };
                talentDetails.project_id = this.props.projectId;
                this.setState({ talentDetails: talentDetails });
            } else {
                {
                    this.setState({ talentDetails: JSON.parse(JSON.stringify({ ...this.props?.talentDetails })) });
                    if (this.props?.talentDetails?.talent_id) {
                        this.getTalentInfo(this.props?.talentDetails?.talent_id);
                    } else {
                        this.setState({ writerTeam: 1 }, () => {
                            this.getTalentInfo(this.props?.talentDetails?.talent_group_id, this.state.writerTeam);
                        })
                    }
                }
            }
            this.getStaticData('repPrefixes', 'lu_prefix');
            this.getStaticData('repSuffixes', 'lu_suffix');
        };

        getStaticData = (listType, staticTableName) => {
            ScripterService.getData(Constants.scripterServiceBaseUrl + `/staticData?staticTable=${staticTableName}`, 
                this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                this.setState({ [listType]: formattedList });
            },
            (err) => {
                console.log("Error in fetching Email Types:", err)
            })
        }

        handleSubmit = () => {
            let talentDetailsData = this.state.talentDetails;
            this.setState({ postInitiated: true }, () => {
                if (this.validateTalentDetails(talentDetailsData)) {
                    this.submitTalentDetails(talentDetailsData);
                }
            });
        };

        validateTalentDetails = (talentDetailsData) => {
            if (!this.state.writerTeam) {
                let canSubmit =
                    talentDetailsData.talent_id &&
                    talentDetailsData.roles.length > 0;
                    // && talentDetailsData.representative_id;
                return canSubmit;
            } else {
                let canSubmit =
                    talentDetailsData.talent_group_id &&
                    talentDetailsData.roles.length > 0;
                    // && talentDetailsData.representative_id;
                return canSubmit;
            }
        };

        submitTalentDetails = (talentDetailsData) => {
            let talentValues = { ...talentDetailsData };
            this.setState({ isPosting: true });
            this.props.setPostFlag(true);
            ScripterService.postDataParams(
                Constants.scripterServiceBaseUrl + "/projectTalents",
                talentValues,
                this.props.userContext.active_tenant.tenant_id,
                this.props?.divisionId
            ).then(
                (response) => {
                    if (response.data.error) {
                        this.setState({ postInitiated: false, formEdited: false, isPosting: false })
                        this.props.setPostFlag(false);
                        this.props.fieldChanged(true, "fail");
                    } else {
                        this.setState({ postInitiated: false, formEdited: false, isPosting: false })
                        this.props.toggleRefreshPage("talent");
                        this.props.setPostFlag(false);
                        this.props.fieldChanged(true, "success");
                    }
                },
                (err) => {
                    console.log("error", err);
                }
            );
        };

        getTalentDetails = (event) => {
            if (event.target.value !== "" || event.target.value !== null) {
                ScripterService.getData(
                    Constants.scripterServiceBaseUrl + `/entitySearch?entity=TALENT&searchString=${event.target.value}`,
                    this.props.userContext?.active_tenant?.tenant_id
                ).then(
                    (response) => {
                        let formattedList = response?.data?.map((item) => ({
                            value: item.talent_id,
                            text: item.talent_name,
                            writerTeam: item.writer_team,
                            type:'data'
                        }));
                        this.setState({ fieldOptions: formattedList, loading: false });
                    },
                    (err) => {

                        console.log("Error in fetching Talent Details:", err);
                    }
                );
            }
        };

        getTalentInfo = (talentId, writerTeam) => {
            let url;
            if (!writerTeam) {
                url = `/talent?searchString=${talentId}&searchKey=id`
            } else {
                url = `/talent?searchString=${talentId}&searchKey=id&writerTeam=${writerTeam}`
            }
            ScripterService.getData(
                Constants.scripterServiceBaseUrl + url,
                this.props.userContext?.active_tenant?.tenant_id, null
            ).then(
                (response) => {
                    let representiveList = response?.data[0]?.representation_list?.map(
                        (item) => ({
                            value: item.representative_id,
                            text: item.representative_name,
                            type:"rep"
                        })
                    );
                    let roleList = response?.data[0]?.role_list?.map((roleItem) => ({
                        value: roleItem.role_id,
                        text: roleItem.role,
                    }));
                    this.setState({
                        representiveOptions: representiveList,
                        roleOptions: roleList,
                    }, () => {
                        let populateRoles = this.state.roleOptions?.map(item => ({
                            talent_role_id: item?.value || null,
                            role_name: item?.text || null,
                            project_talent_role_id: null
                        }))
                        this.handleSelectedValue("roles", populateRoles);
                    });
                },
                (err) => {
                    this.setState({ representiveOptions: [], roleOptions: [] });
                    console.log("Error in fetching Talent Details:", err);
                }
            );
        };

        handleSelectedValue = (field, value) => {
            this.setState((prevState) => ({
                talentDetails: {
                    ...prevState.talentDetails,
                    [field]: value,
                },
            }));
        };
        handleNewTalentDetails = (talentDetails) => {
            console.log("checking the talent details", talentDetails);
            // let newValue = {
            //     value: talentDetails?.talent_id || talentDetails?.talent_group_id,
            //     text: talentDetails?.talent_id ? 
            //         talentDetails?.first_name + ' ' + talentDetails?.last_name : 
            //         this.getFormatTalentGroupName(talentDetails?.talent_group_members_list),
            //     type: talentDetails?.talent_id ? 'talent' : 'talent_group',
            //     company: talentDetails?.company
            // }
            // this.props?.handleListEdit('submitted_by_id', newValue, this.state.talentObj, 'submitted_by')
            this.handleSelectedValue("talent_id",  talentDetails?.talent_id || null);
                this.handleSelectedValue("talent_group_id", talentDetails?.talent_group_id || null);
                this.handleSelectedValue("talent", talentDetails?.talent_id ? 
                // talentDetails?.first_name + ' ' + talentDetails?.last_name : 
                ((talentDetails?.prefix ? talentDetails?.prefix + ' ' : '') + talentDetails?.first_name + ' ' + (talentDetails?.m_i ? talentDetails?.m_i + ' ' : '') + (talentDetails?.last_name ? talentDetails?.last_name : '') + (talentDetails?.suffix ? ' ' + talentDetails?.suffix : '')) :
                this.getFormatTalentGroupName(talentDetails?.talent_group_members_list) || null)
                this.setState({ writerTeam: talentDetails?.writer_team }, () => {
                    this.getTalentInfo(talentDetails?.talent_id || talentDetails?.talent_group_id , talentDetails?.writer_team);
                })
        }
    
        getFormatTalentGroupName = (talentTeamList) => {
            let talentGroupName = [];
            talentTeamList?.map(item => {
                talentGroupName.push(item?.talent_name)
            })
            return talentGroupName?.join(' & ');
        }
        handleSelectedTalent = (e, newValue) => {
            if(newValue) {
                if(!newValue?.hasOwnProperty('type')){
                    let talentDetails = {}
                    let prefixDetails = this.getOnFlyName("prefix", newValue?.value);
                    if (prefixDetails) {
                        talentDetails['prefix_id'] = prefixDetails?.value;
                        talentDetails['prefix'] = prefixDetails?.label;
                    }
                    talentDetails['first_name'] = this.getOnFlyName("first_name", newValue?.value);
                    talentDetails['middle_name'] = this.getOnFlyName("middle_name", newValue?.value) || null;
                    talentDetails['last_name'] = this.getOnFlyName("last_name", newValue?.value) || null;
                    let suffixDetails = this.getOnFlyName("suffix", newValue?.value);
                    if (suffixDetails) {
                        talentDetails['suffix_id'] = suffixDetails?.value;
                        talentDetails['suffix'] = suffixDetails?.label;
                    }
                    this.props?.handleShowTalentPopup(null, 0, "new",this.handleNewTalentDetails, talentDetails)
                } else  {
                    this.handleSelectedValue("talent_id", !newValue?.writerTeam ? newValue?.value : null || null);
                    this.handleSelectedValue("talent_group_id", newValue?.writerTeam ? newValue?.value : null || null);
                    this.handleSelectedValue("talent", newValue?.text || null)
                    this.getTalentInfo(newValue?.value, newValue?.writerTeam);
                    this.setState({ writerTeam: newValue?.writerTeam })
                }
            }
            else  {
                this.setState({talentDetails: {},writerTeam: 0})
            }
          
        };
        getOnFlyName = (field, value, type = null) => {
            let prefixes = type === 'rep' ? [ ...this.state.repPrefixes ] : [ ...talentPrefixes ];
            let suffixes = type === 'rep' ? [ ...this.state.repSuffixes ] : [ ...talentSuffixes ];
            let n = value?.split(" ");
            if (field === "first_name" || field === "middle_name" || field === "last_name") {
                let prefixExist = prefixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[0]?.toLowerCase()));
                if (prefixExist) {
                    n.splice(0, 1);
                }
                let suffixExist = suffixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[n?.length - 1]?.toLowerCase()));
                if (suffixExist) {
                    n.pop();
                }
            }
            if (field === "first_name") {
                if (n?.length > 0) {
                    return n[0];
                }
            } else if (field === "middle_name") {
                if (n?.length > 2) {
                    return n[1];
                }
            } else if (field === "last_name") {
                if (value.split(" ").length > 1) {
                    let lastName = [];
                    n?.map((item, index) => {
                        if (n?.length <= 2 && index !== 0) {
                            lastName.push(item)
                        } else if (n?.length > 2 && index !== 0 && index !== 1) {
                            lastName.push(item)
                        }
                    })
                    return lastName.join(" ");
                }
            } else if (field === "prefix") {
                let prefix = prefixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[0]?.toLowerCase()));
                return prefix;
            } else if (field === "suffix") {
                let suffix = suffixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[n?.length -1]?.toLowerCase()));
                return suffix;
            }
        }
        handleNewRepDetails(details){
            let newValue = [{
                value: details?.representative_id,
                // text:  details?.first_name + ' ' + (details?.last_name ? details?.last_name : '') 
                text: ((details?.prefix ? details?.prefix + ' ' : '') + details?.first_name + ' ' + (details?.m_i ? details?.m_i + ' ' : '') + (details?.last_name ? details?.last_name : '') + (details?.suffix ? ' ' + details?.suffix : ''))         
            }]
            let repList = this.state?.representiveOptions?.length > 0 ? 
                [...this.state?.representiveOptions, ...newValue] : [...newValue]
            this.setState({representiveOptions:repList});
            this.handleSelectedValue("representative_id",  details?.representative_id || null);
            this.handleSelectedValue("representative", newValue?.[0]?.text || null)
                // details?.first_name + ' ' + (details?.last_name ? details?.last_name : '') || null)
        }
        handleSelectedRepresentation = (e, newValue) => {
            
                // this.props?.handleShowTalentPopup(null, 0, "new",this.handleNewTalentDetails)
                if(newValue && !newValue?.hasOwnProperty("type")){
                    // let talentId = this.state?.projectDetails?.writers?.[index]?.talent_id ||  this.state?.projectDetails?.writers?.[index]?.talent_group_id || null;
                    let talentId= null;
                    let repDetails = {}
                    let prefixDetails = this.getOnFlyName("prefix", newValue?.value, 'rep');
                    if (prefixDetails) {
                        repDetails['prefix_id'] = prefixDetails?.value;
                        repDetails['prefix'] = prefixDetails?.label;
                    }
                    repDetails['first_name'] = this.getOnFlyName("first_name", newValue?.value, 'rep');
                    repDetails['middle_name'] = this.getOnFlyName("middle_name", newValue?.value, 'rep') || null;
                    repDetails['last_name'] = this.getOnFlyName("last_name", newValue?.value, 'rep') || null;
                    let suffixDetails = this.getOnFlyName("suffix", newValue?.value, 'rep');
                    if (suffixDetails) {
                        repDetails['suffix_id'] = suffixDetails?.value;
                        repDetails['suffix'] = suffixDetails?.label;
                    }
                    this.props?.navFuns?.handleShowRepresentativePopup(null,"new", talentId,this.handleNewRepDetails.bind(this), repDetails)
                } else {
                this.handleSelectedValue("representative_id", newValue?.value || null);
                this.handleSelectedValue("representative", newValue?.text || null)
            }
        };

        onChangeRolesCallBack = (field, val) => {
            let formattedRoles = val.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.value === value?.value && t.text === value?.text
                ))
            )
            let roles = formattedRoles?.map(item => ({
                talent_role_id: item?.value || null,
                role_name: item?.text || null,
                project_talent_role_id: null
            }))
            this.handleSelectedValue("roles", roles);
        }

        render() {
            return (
                <MDBContainer className="AddTalentContainer">
                    <MDBRow>
                        <MDBCol md={12}>
                            {(this.state.talentDetails?.project_talent_id &&
                                (this.state.talentDetails?.talent_id || this.state.talentDetails?.talent_group_id)) ?
                                <div className="talentName">
                                    <BasicLabel text={'Talent'} />
                                    <span className="basic-text">
                                        {this.state.talentDetails?.talent || "-"}
                                    </span>
                                </div> :
                                <>
                                <BasicLabel text={"Talent"} isMandatory={true}/>
                               
                                <SearchFieldWithAddValue
                                    id={"talent-select1"}
                                    textFieldId={"talent-search"}
                                    label={"Talent"}
                                    options={this.state.fieldOptions}
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated}
                                    mandatoryWarning={messages.mandatoryWarning}
                                    width={"100%"}
                                    multiple={false}
                                    detail_selected={(this.state.talentDetails?.talent_id || this.state.talentDetails?.talent_group_id) && this.state.talentDetails?.talent ? {
                                        value: (this.state.talentDetails?.talent_id || this.state.talentDetails?.talent_group_id),
                                        text: this.state.talentDetails?.talent
                                    } : null}
                                    valueSelected={this.handleSelectedTalent}
                                    searchText={this.getTalentDetails}
                                    searchSelect={true}
                                    loading={this.state.loading}
                                />
                                </>
                            }
                        </MDBCol>
                    </MDBRow>
                    {this.props.mode === "Edit" ||
                        (this.state.talentDetails?.talent_id || this.state.talentDetails?.talent_group_id) ? (
                            <>
                                <MDBRow>
                                    <MDBCol md={12}
                                        className={`${this.state?.talentDetails?.roles?.length === 0 ? "searchSelectHeight talentRoleSearchSelect" : "talentRoleSearchSelect"}`}
                                        id='rolesDropDown'>
                                        <SearchSelectField
                                            id={"roles-select"}
                                            label={"Role"}
                                            multiple={true}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            value={this.state?.talentDetails?.roles?.map(item => ({ value: item.talent_role_id, text: item?.role_name || item?.talent_role_name })) || []}
                                            options={this.state?.roleOptions || []}
                                            width={"100%"}
                                            onChange={(e, value) => this.onChangeRolesCallBack('roles', value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={12}>
                                        <BasicLabel 
                                            text={"Primary Representative"} 
                                            // isMandatory={true}
                                        />
                                        <SearchFieldWithAddValue
                                            id={"Primary Representative-select"}
                                            textFieldId={"Primary Representativet-search"}
                                            label={"Primary Representative"}
                                            // isMandatory={true}
                                            // showMandatory={this.state.postInitiated}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            options={this.state.representiveOptions}
                                            width={"100%"}
                                            multiple={false}
                                            detail_selected={this.state.talentDetails?.representative_id && this.state.talentDetails?.representative ? {
                                                value: this.state.talentDetails?.representative_id,
                                                text: this.state.talentDetails?.representative
                                            } : null}
                                            valueSelected={this.handleSelectedRepresentation}
                                            searchSelect={true}
                                            loading={this.state.loading}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </>
                        ) : null}

                    <MDBRow className="p-2">
                        <MDBCol md={8}></MDBCol>
                        <MDBCol md={2}>
                            <BasicButton
                                className="btnwidth"
                                variant="contained"
                                type="inline"
                                text={
                                    this.state.isPosting ?
                                        <CircularProgress color="inherit" size={20} /> :
                                        this.props?.primaryButtonText
                                }
                                disabled={false}
                                onClick={this.handleSubmit}
                            />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicButton
                                type="inline"
                                variant="outlined"
                                text={this.props?.secondaryButtonText}
                                onClick={() => this.props?.onClose()}
                                disabled={false}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            );
        }
    }
);
