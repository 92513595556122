export const intialScriptDeliveryNotice = {
    "generate_script_delivery_notice_report_id": null,
    "deal_memo_id": null,
    "deal_memo_step_id": null,
    "to": null,
    "from": null,
    "date": null,
    "subject": null,
    "cc": null,
    "title": null,
    "writers": null,
    "delivery_date": null,
    "script_status": null,
    "reading_period": null,
    "next_step": null,
    "additional_comments": null
  }