import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-time-picker/assets/index.css';
import './ProjectOfficerReports.scss';
import { withUserContext } from '../../../../contexts/UserContext';
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import SelectField from '../../../SharedComponents/SelectField/SelectField';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';


export default withUserContext(class GeneralReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

            statusOptions: [],
            isFetchingAuditionStatus: false,
            postInitiated: false,
            isPosting: false,
            selectedPerformerValues: null,
            ProjectofficerReportDetails: [],

        }
    }
    componentDidMount () {
        this.getEntity('userList', 'USER', '');
    }
    getEntity = (entity, entitytype, searchString) => {
        let loading = (entitytype === 'USER') ? 'isUserListLoading' : 'loading';
        this.setState({ [loading]: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`,
            this.props.userContext.active_tenant.tenant_id, this.props.userContext.active_tenant.division_id)
            .then(response => {
                let formattedList = [];
                console.log("Project Response: ",response);
                if (entitytype === 'USER') {
                    formattedList = response?.data?.map(item => ({ value: item.user_id, label: item.user_name }));
                }
                this.setState({[entity]: formattedList, [loading]: false });
                console.log("Entity: ",this.state.userList);
            },
                (err) => {
                    this.setState({ [loading]: false });
                    console.log("Error in fetching entities:", err)
                })
    }

    render() {

        let isLoading = this.state.isFetchingEpisodeDetails && this.state.isFetchingEpisodeTypes;
        return (
            <>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBContainer className="NewProjectOfficerReportContainer">
                            <MDBRow className="searchSelect">
                                <MDBCol md={12} className="searchSelectcontent" id='rolesDropDown'>
                                    <BasicLabel text={"Officer"} />
                                    <SelectField
                                        id={"roles-select"}
                                        multiple={true}
                                        value={this.state.selectedPerformerValues}
                                        options={this.state.userList} width={300}
                                        placeholder={"- Select Executive -"}
                                        onChange={ (e) => this.setState({selectedPerformerValues: e.target.value}) || ""}
                                    />
                                </MDBCol>

                            </MDBRow>
                            <MDBRow className="p-2">
                                <MDBCol md={8} >
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        type="inline"
                                        variant="outlined"
                                        text={"Cancel"}
                                        onClick={this.props?.onClose}
                                        disabled={false}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        className="btnwidth"
                                        variant="contained"
                                        type="inline"
                                        text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate"}
                                        disabled={false}
                                        onClick={this.ProjectofficerReportDetails}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    )}
            </>
        )
    }
}
);