const selectOptions = [
    {
        label: "Cognizant",
        value: 1
    },
    {
        label: "Infosys",
        value: 2
    },
    {
        label: "CapGemini",
        value: 3
    },
]

const selectOptionsNew = [
    {
        label: "Cognizant123",
        value: 1
    },
    {
        label: "Infosys123",
        value: 2
    },
    {
        label: "CapGemini123",
        value: 3
    },
]

const selectOptionsAuto = [
    {
        label: "Cognizant123",
        value: 1
    },
    {
        label: "Infosys123",
        value: 2
    },
    {
        label: "CapGemini123",
        value: 3
    },
]

const configObjectSubmissions = {
    iscolumnFilterRequired: true,
    headings: [{
        headingLabel: "Title + AKAs",
        icon: "",
        width: "100%",
        inputType: "text",
        isColumn: true,
        dataNode: "title",
        searchJson: "title_aka_search"
    },
    {
        headingLabel: "Genre",
        icon: "",
        width: "100%",
        inputType: "dropdown",
        selectOptions: "statusSubmissionOptions",
        isColumn: true,
        dataNode: "genre_name",
        searchJson: "genre",
        // tableName: "lu_submission_status"
    },
    {
        headingLabel: "Logline",
        icon: "",
        width: "100%",
        inputType: "dropdown",
        selectOptions: "statusSubmissionOptions",
        isColumn: true,
        dataNode: "logline",
        searchJson: "logline",
        // tableName: "lu_submission_status"
    },
    {
        headingLabel: "Status",
        icon: "",
        width: "100%",
        inputType: "dropdown",
        selectOptions: "statusSubmissionOptions",
        isColumn: true,
        dataNode: "status",
        searchJson: "status_ids",
        tableName: "lu_submission_status"
    },
    {
        headingLabel: "Authors",
        icon: "",
        width: "0%",
        inputType: "autocomplete",
        selectOptions: "authorsOptions",
        searchJson: "author_search_ids",
        isColumn: false,
        dataNode: "authors",
        entityType: "TALENT"
    },
    {
        headingLabel: "Format",
        icon: "",
        width: "0%",
        inputType: "dropdown", 
        isColumn: false,
        dataNode: "format",
        selectOptions: "formatOptions",
        tableName: "lu_format",
        searchJson: "format_search_ids"
    },
    {
        headingLabel: "Submitted To",
        icon: "",
        width: "0%",
        inputType: "autocomplete",
        selectOptions: "submittedByOptions",
        isColumn: false,
        dataNode: "submitted_to",
        entityType: "USER",
        searchJson: "submitted_to_ids",
    },
    {
        headingLabel: "Draft Date",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "draft_date",
        searchJson: "min_draft_date"
    },
    {
        headingLabel: "Received Date",
        icon: "",
        width: "0%",
        inputType: "text", 
        isColumn: false,
        dataNode: "received_date",
        searchJson: "min_received_date"
    },
    {
        headingLabel: "Analyst",
        icon: "",
        width: "0%",
        inputType: "autocomplete",
        selectOptions: "analystOptions",
        isColumn: false,
        dataNode: "analyst", // ????????
        entityType: "USER",
        searchJson: "analyst_search_ids"
    },
    {
        headingLabel: "Coverage Due Date",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "due_date", // ??????
        searchJson: "min_coverage_due_date"
    },
    {
        headingLabel: "Coverage Type",
        icon: "",
        width: "0%",
        inputType: "dropdown",
        selectOptions: "coverageTypeOptions",
        isColumn: false,
        dataNode: "coverage_type",
        tableName: "lu_coverage_types",
        searchJson: "coverage_type_ids"
    },
    
    {
        headingLabel: "Project",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "project_title",
        searchJson: "project_search_text"
    },
    {
        headingLabel: "Deal Step",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "deal_step",
        searchJson: "deal_step_search_text"
    },
    {
        headingLabel: "Term Deal Company",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "term_deal_company",
        searchJson: "term_deal_company_search_text"
    },
    {
        headingLabel: "Company",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "company",
        searchJson: "company_search_text"
    },
    {
        headingLabel: "Unofficial/Interim",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "un_official_interim",
        searchJson: "unofficial_interim_dd",
    },
    {
        headingLabel: "Submission Notes",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "submission_notes",
        searchJson: "submission_notes_search_text"
    },
    {
        headingLabel: "Draft Version",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "draft_version",
        searchJson: "draft_version_search_text"
    },
    {
        headingLabel: "Page Count",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "pgs",
        searchJson: "min_pgs"
    },
    {
        headingLabel: "WB Page Count",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "wb_pgs",
        searchJson: "min_wb_pgs"
    },
    {
        headingLabel: "BOX #",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "box",
        searchJson: "box_search_text"
    },
    {
        headingLabel: "SKU #",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "sku",
        searchJson: "sku_search_text"
    },
    {
        headingLabel: "Confidentiality",
        icon: "",
        width: "0%",
        inputType: "dropdown",
        isColumn: false,
        dataNode: "is_confidential",
        searchJson: "confidential_flag", // ????
        selectOptions: "confidentialOptions", //(1 or 0)
        tableName: "none"

    },
    {
        headingLabel: "Elements",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "elements",
        searchJson: "elements_search_text"
    },
    {
        headingLabel: "Studio Division",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "division_name",
        searchJson: "studio_division_search_text"
    }],
    dataNodes: ["title", "genre_name", "logline", "status", "authors", "box", "company", "coverage_type", "draft_date", "draft_version", "due_date", "elements", "format", "is_confidential", "pgs", "project_title", "received_date", "sku", "status", "submission_id", "submission_notes", "submitted_to", "term_deal_company", "title", "un_official_interim", "wb_pgs", "analyst", "deal_step", "division_name"],
    primaryKey: "submission_id",
    // actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    filtersLanding: false,
    hyperlinks: ["title"]
};


const configObjectProjects = {
    iscolumnFilterRequired: true,
    headings: [{
        headingLabel: "Title + AKAs",
        icon: "",
        width: "100%",
        inputType: "text",
        isColumn: true,
        dataNode: "title",
        searchJson: "title_aka_search",
        isDisabled: true
    },
    {
        headingLabel: "Genre",
        icon: "",
        width: "100%",
        inputType: "dropdown",
        isColumn: true,
        dataNode: "genre",
        searchJson: "genre_search_ids"
    },
    {
        headingLabel: "Logline",
        icon: "",
        width: "100%",
        inputType: "text",
        isColumn: true,
        dataNode: "log_line",
        searchJson: "logline_search_text"
    },
    {
        headingLabel: "Status",
        icon: "",
        width: "100%",
        inputType: "dropdown",
        isColumn: true,
        dataNode: "status",
        searchJson: "status_ids"
    },
    {
        headingLabel: "Studio Division",
        icon: "",
        width: "0%",
        inputType: "dropdown",
        isColumn: false,
        dataNode: "division_name",
        searchJson: "division_search_ids"
    },
    {
        headingLabel: "Project Type",
        icon: "",
        width: "0%",
        inputType: "dropdown",
        isColumn: false,
        dataNode: "project_type_name",
        searchJson: "project_type_search_ids"
    },
    {
        headingLabel: "Creative Executives",
        icon: "",
        width: "0%",
        inputType: "autocomplete",
        isColumn: false,
        dataNode: "creative_executives",
        searchJson: "creative_executive_search_ids",
        selectOptions: selectOptionsAuto
    },
    {
        headingLabel: "Material Location",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "material_location",
        searchJson: "material_location_search_text"
    },
    {
        headingLabel: "Vault Information",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "vault_information",
        searchJson: "vault_information_search_text"
    },
    {
        headingLabel: "Authors",
        icon: "",
        width: "0%",
        inputType: "autocomplete",
        isColumn: false,
        dataNode: "authors",
        selectOptions: selectOptionsAuto
    },
    {
        headingLabel: "Confidentiality",
        icon: "",
        width: "0%",
        inputType: "dropdown",
        isColumn: false,
        dataNode: "is_confidential"
    },
    {
        headingLabel: "Project Notes",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "project_notes"
    },
    {
        headingLabel: "SAP (Dev)",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "SAP_dev"
    },
    {
        headingLabel: "SAP (Prod)",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "SAP_prod"
    },
    {
        headingLabel: "Wrap Date",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "wrap_date"
    },
    {
        headingLabel: "Production Start Date",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "prod_start_date"
    },
    {
        headingLabel: "Release Date",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "release_date"
    },
    {
        headingLabel: "Rights Lapse Date",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "rights_lapse_date"
    },
    {
        headingLabel: "Distribution Company",
        icon: "",
        width: "0%",
        inputType: "dropdown",
        isColumn: false,
        dataNode: "distribution_company_name"
    },
    ],
    dataNodes: ["project_id", "title", "aka", "genre", "log_line", "creative_executives", "status", "division_name"
        , "material_location", "vault_information", "project_type_name", "authors", "is_confidential", "project_notes", "SAP_dev", "SAP_prod"
        , "wrap_date", "prod_start_date", "release_date", "rights_lapse_date", "distribution_company_name"],
    primaryKey: "project_id",
    // actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    filtersLanding: false,
    hyperlinks: ["title"]
};

export const configObjectWGA = {
    iscolumnFilterRequired: true,
    headings: [
        {
            headingLabel: "Type",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "wga_type_name",
            searchJson: "wga_type_search_text",
        },
        {
            headingLabel: "Notice Date",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "notice_date",
            searchJson: "notice_date_search_text",
        },
        {
            headingLabel: "Title",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "episode_title",
            searchJson: "episode_title_search_text",
        },
        {
            headingLabel: "Participating Writers",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "writer_name",
            searchJson: "adjusted_talent_name_search_text",
        },
    ],
    dataNodes: ["wga_type_name", "notice_date", "episode_title", "writer_name"],
    primaryKey: "wga_type_id",
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    filtersLanding: false,
    // hyperlinks: ["document_name"]
}


const configObjectDeals = {
    iscolumnFilterRequired: true,
    headings: [
        {
            headingLabel: "Project Title + AKAs",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "project_title",
            searchJson: "project_search_text"
        },
        {
            headingLabel: "Document Name",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "document_name",
            searchJson: "document_name_search_text",
        },
        {
            headingLabel: "Deal Date",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "memo_date",
            searchJson: "min_deal_date"
        },
        {
            headingLabel: "Amendments",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "ammendments",
            searchJson: "min_ammendment_date",
        },
        {
            headingLabel: "Business Affair Executive",
            icon: "",
            width: "100%",
            inputType: "autocomplete",
            isColumn: true,
            dataNode: "ba_executive",
            selectOptions: "businessAffairsOptions",
            entityType: "USER", //pass grp name along with user
            searchJson: "ba_executive_search_ids"
        },
        {
            headingLabel: "Feature Legal Executive",
            icon: "",
            width: "0%",
            inputType: "autocomplete",
            isColumn: false,
            dataNode: "legal_executive",
            selectOptions: "featureLegalExecOptions",
            entityType: "USER", //pass grp name 
            searchJson: "legal_executive_search_ids"
        },
        {
            headingLabel: "Deal Notes",
            icon: "",
            width: "0%",
            inputType: "text",
            isColumn: false,
            dataNode: "deal_notes",
            searchJson: "deal_notes_search_text"
        },
    ],
    dataNodes: ["project_id", "project_title", "memo_date", "legal_executive", "document_name", "deal_notes", "ba_executive", "ammendments"],
    primaryKey: "deal_memo_id",
    // actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    filtersLanding: false,
    hyperlinks: ["document_name"]
};



const configObjectWeekendRead = {
    iscolumnFilterRequired: true,
    headings: [
        {
            headingLabel: "Title",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "title",
            searchJson: "title_search_text"
        },
        {
            headingLabel: "WER Date Range",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "weekend_read_date",
            searchJson: "min_wer_date"
        },
        {
            headingLabel: "Per/Advocate",
            icon: "",
            width: "100%",
            inputType: "text",
            // inputType: "autocomplete",
            isColumn: true,
            dataNode: "advocate",
            // selectOptions: "advocateOptions",
            // entityType: "USER", //???? all users
            searchJson: "per_advocate_search_text"
        },
        {
            headingLabel: "Assigned To / Also Reading",
            icon: "",
            width: "100%",
            inputType: "autocomplete",
            isColumn: true,
            dataNode: "assigned_to",
            selectOptions: "assignedToOptions",
            entityType: "USER", //???? 
            searchJson: "assigned_to_search_ids"

        },
        {
            headingLabel: "Attached Coverages",
            icon: "",
            width: "0%",
            inputType: "autocomplete",
            isColumn: false,
            dataNode: "attached_coverages",
            selectOptions: "authorOptions",
            entityType: "SUBMISSIOn",
            searchJson: "author_search_ids"
        },
        {
            headingLabel: "Weekend Read Notes",
            icon: "",
            width: "0%",
            inputType: "text",
            isColumn: false,
            dataNode: "notes", //??? 
            searchJson: "weekend_read_notes_search_text"

        },
        {
            headingLabel: "Execs Who've Read",
            icon: "",
            width: "0%",
            inputType: "autocomplete",
            isColumn: false,
            dataNode: "exec_read",
            searchJson: "exec_read_search_ids",
            entityType: "USER" //????? user
        },
        {
            headingLabel: "Additional Material",
            icon: "",
            width: "0%",
            inputType: "text",
            isColumn: false,
            dataNode: "additional_material",
            searchJson: "advanced_material_search_text"
        },
        {
            headingLabel: "Submitted/Produced By",
            icon: "",
            width: "0%",
            inputType: "autocomplete",
            isColumn: false,
            dataNode: "submitted_by",
            selectOptions: "submittedByOptions",
            entityType: "USER", //???? all users
            searchJson: "submitted_by_search_ids"

        }],
    dataNodes: ["weekend_read_id", "weekend_read_date", "additional_material", "advocate", "assigned_to", "attached_coverages", "exec_read", "notes", "submitted_by", "title"],
    primaryKey: "weekend_read_id",
    // actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    filtersLanding: false,
    hyperlinks: ["title"]
};


const configObjectTalent = {
    iscolumnFilterRequired: true,
    headings: [
        {
            headingLabel: "Talent Name + AKAs",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "talent_name"
        },
        {
            headingLabel: "Role",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "role"
        },
        {
            headingLabel: "Loanout Company",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "loanout_company"
        },
        {
            headingLabel: "Performer Notes",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "performer_notes"
        },
        {
            headingLabel: "Status",
            icon: "",
            width: "0%",
            inputType: "text",
            isColumn: false,
            dataNode: "is_active"
        },
    ],
    dataNodes: ["talent_name", "prefix", "first_name", "last_name", "suffix", "m_i", "talent_group_members_name", "loanout_company", "is_active", "performer_notes"],
    primaryKey: "talent_id",
    // actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    hyperlinks: ["talent_name"],
    filtersLanding: false
};

export const configObjectRepresentative = {
    iscolumnFilterRequired: true,
    headings: [
        {
            headingLabel: "Name",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "rep_name",
            searchJson: "rep_name_search_text",
        },
        {
            headingLabel: "Company",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "company",
            searchJson: "agency_search_text",
        },
        {
            headingLabel: "Rep. Type",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "representative_type_name",
            searchJson: "rep_type_search_text",
        },
        {
            headingLabel: "Address",
            icon: "",
            width: "0%",
            inputType: "text",
            isColumn: false,
            dataNode: "address",
            searchJson: "address_type_search_text",
        },
    ],
    dataNodes: ["rep_name", "company", "representative_type_name", "address"],
    primaryKey: "wga_type_id",
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    filtersLanding: false,
    // hyperlinks: ["document_name"]
}

export const configObjectCompany = {
    iscolumnFilterRequired: true,
    headings: [
        {
            headingLabel: "Name",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "agency_name",
            searchJson: "agency_name_search_text",
        },
        {
            headingLabel: "Type",
            icon: "",
            width: "100%",
            inputType: "text",
            isColumn: true,
            dataNode: "agency_type_name",
            searchJson: "agency_type_search_text",
        },
        {
            headingLabel: "Address",
            icon: "",
            width: "0%",
            inputType: "text",
            isColumn: false,
            dataNode: "address",
            searchJson: "agency_address_search_text",
        },
        {
            headingLabel: "Company Notes",
            icon: "",
            width: "0%",
            inputType: "text",
            isColumn: false,
            dataNode: "agency_notes",
            searchJson: "agency_notes_search_text",
        },
    ],
    dataNodes: ["agency_name", "agency_type_name", "adress_1", "agency_notes"],
    primaryKey: "wga_type_id",
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    filtersLanding: false,
    // hyperlinks: ["document_name"]
}

const tabList = [
    { label: "Submissions", index: 0, name: "allusers" },
    { label: "Projects", index: 1, name: "groups" },
    { label: "Deals", index: 2, name: "groups" },
    { label: "Weekend Read", index: 3, name: "groups" },
    { label: "My Queue", index: 4, name: "groups" },
]

const reportObjectList = [
    { label: "Submissions", value: "Submissions", name: "Submissions" },
    { label: "Projects", value: "Projects", name: "Projects" },
    { label: "Deals", value: "Deals", name: "Deals" },
    { label: "WGAs", value: "WGAs", name: "WGAs" },
    { label: "Weekend Read", value: "Weekend_Reads", name: "Weekend Read" },
    { label: "Talent", value: "Talent", name: "Talent" },
    { label: "Representatives", value: "Representatives", name: "Representatives" },
    { label: "Companies", value: "Companies", name: "Companies" },
]

export const filterTypeNumberOptions = [
    { value: '=', text: 'Equals to' },
    { value: '>', text: 'Greater than' },
    { value: '<', text: 'Less than' },
    { value: '>=', text: 'Greater than equals to' },
    { value: '<=', text: 'Less than equals to' },
]

export const filterTypeDateOptions = [
    { value: 'between', text: 'Between' },
    { value: '>', text: 'Greater than' },
    { value: '<', text: 'Less than' },
    { value: 'anyValueEntered', text: 'Any Value Entered' },
    { value: 'noValueEntered', text: 'No Value Entered' },
]

export const filterTypeRangeOptions = [
    { value: 'between', text: 'Between' },
]

export const filterTypeSelectOptions = [
    { value: '=', text: 'Equals to' },
]

export const filterTypePgsOptions = [
    { value: 'pgs', text: 'Page Count' },
    { value: 'wb_pgs', text: 'WB Page Count' }
]

export const filterTypeTextOptions = [
    { value: 'contains', text: 'Contains' },
    { value: '=', text: 'Equals to' },
    { value: 'startsWith', text: 'Starts with' },
    { value: 'anyValueEntered', text: 'Any Value Entered' },
    { value: 'noValueEntered', text: 'No Value Entered' },
]

export const filterTypeLargeTextOptions = [
    { value: 'contains', text: 'Contains' },
    // { value: "<", text: 'Less Than' },
    { value: '=', text: 'Equal To' },
    { value: 'anyValueEntered', text: 'Any Value Entered' },
    { value: 'noValueEntered', text: 'No Value Entered' },
]

export const newFilterRecord = {
    "groupField": null,
    "groupFieldSelected": [],
    "filterField": null,
    "filterFieldSelected": [],
    "value1": null,
    "value2": null,
    "option1": [],
    "option2": [],
    "option3": [],
    "type": null,
    "range": false,
    "filterType": null,
    "filterTypeSelected": [],
    "filterTypeOptions": [], 
    "index": 1
}

export const saveSearchJson = { 
    "advance_search_query_id": null, 
    "name": null, 
    "user_id": null,  
    "query": null,
    "entity": null
} 


const searchGroups = [
    {
        type: "Projects", value: "Projects", filters: [
            {
                value: "Project", text: "Projects", field: 'projects', isExpand: true, filterCategories: [
                    { value: "project_id", text: "Project Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    { value: "project_status_id", text: "Status", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "projectStatus", tableName: "lu_project_status", optionName: "projectStatusDetails" },
                    { value: "log_line", text: "Logline", isCoreField: true, type: "Large Text", fieldName: "projectLogline" },
                    { value: "genre_id", text: "Genre", isCoreField: true, type: "Multi Select", fieldName: "projectGenre", tableName: "lu_genre", optionName: "genreDetails" },
                    { value: "project_type_id", text: "Project Type", isCoreField: true, type: "Multi Select", fieldName: "projectType", tableName: "lu_project_type", optionName: "projectTypeDetails" },
                    { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                ]
            },
            {
                value: "Submissions", text: "Submissions", field: 'submissions', filterCategories: [
                    { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                    { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                    { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                    { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                    { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                    { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                    { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                    { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                ]
            },
            {
                value: "Wga", text: "WGA", field: 'wga', filterCategories: [
                    { value: "wga_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "wgaType", tableName: "lu_wga_type", optionName: "wgaTypeDetails" },
                    { value: "notice_date", text: "Notice Date", isCoreField: true, type: "Date", fieldName: "wgaNoticeDate" },
                    { value: "episode_title", text: "Title", isCoreField: true, type: "Text", fieldName: "wgaTitle" },
                    { value: "writer_name", text:"Participating Writers", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaWriters", tableName: "TALENT", optionName: "writerDetails" },
                    { value: "additional_writer_team_info", text: "Additional Writer Team Info", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaAdditionalWriters", tableName: "TALENT", optionName: "writerDetails" },
                    { value: "adjusted_representative_name", text: "Representation", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaRepresentation", tableName: "REPRESENTATIVE", optionName: "writerDetails" },
                    { value: "Writing Credits on this production are tentatively determined as follows", text: "Writing Credits on this production are tentatively determined as follows", isCoreField: true, type: "Text", fieldName: "wgaWritingCredits" },
                    { value: "on_screen_production_credit", text: "On screen and/or advertising, presentation and production credit, if any", type: "Text", isCoreField: true, fieldName: "wgaScreenAdvertisement" },
                ]
            },
            {
                value: "Deal", text: "Deal", field: 'deals', filterCategories: [
                    { value: "document_name", text: "Document Name", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "dealDocumentName" },
                    { value: "project_id", text: "Project Title + AKAs", isCoreField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    { value: "writer_id,talent_group_id", text: "Writer(s)", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "dealWriter", tableName: "TALENT", optionName: "writersDetails" },
                    { value: "memo_date", text: "Memo Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "dealMemoDate" },
                    { value: "ammendment_date", text: "Amendment Date", isCoreField: true, type: "Date", fieldName: "dealAmendmentDate" },
                    { value: "business_executive_id", text: "Business Affairs Executive(s)", isCoreField: true, type: "Search", fieldName: "businessAffairsExecutive", tableName: "USER", optionName: "businessAffairExecutiveDetails" },
                    { value: "feature_executive_id", text: "Feature Legal Executive(s)", isCoreField: true, type: "Search", fieldName: "featureLegalExecutive", tableName: "USER", optionName: "featureLegalExecutiveDetails" },
                ]
            },
            {
                value: "Talent", text: "Talent", field: 'talent', filterCategories: [
                    { value: "talent_id, talent_group_id", text: "Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                    { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
                    { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
                    { value: "performer_notes", text: "Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
                ]
            }
        ]
    },
    {
        type: "Submissions", value: "Submissions", filters: [
            {
                value: "Submissions", text: "Submissions", field: 'submissions', isExpand: true, filterCategories: [
                    { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                    { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                    { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                    { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                    { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                    { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                    { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                    { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                ]
            },
            {
                value: "Coverages", text: "Coverages", field: 'coverages', filterCategories: [
                    { value: "analyst_id", text: "Analyst", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "coveragesAnalyst", tableName: "USER", optionName: "analystDetails" },
                    { value: "coverage_type_id", text: "Coverage Type", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "coverageType", tableName: "lu_coverage_types", optionName: "coveragesDetails" },
                    { value: "logline", text: "Logline", isCoreField: true, type: "Large Text", fieldName: "coverageLogline" },
                    { value: "genre_id", text: "Genre", isCoreField: true, type: "Multi Select", fieldName: "coverageGenre", tableName: "lu_genre", optionName: "genreDetails" },
                    { value: "locale", text: "Locale", isCoreField: true, type: "Text", fieldName: "coverageLocale" },
                    { value: "time", text: "Time", isCoreField: true, type: "Text", fieldName: "coverageTime" },
                    { value: "recommendation", text: "Recommendation", isCoreField: true, type: "Select", fieldName: "recommendation", tableName: "lu_recommendation", optionName: "recommendationDetails" },
                    { value: "writer_recommendation", text: "Writer Recommendation", isCoreField: true, type: "Select", fieldName: "writerRecommendation", tableName: "lu_writer_recommendation", optionName: "writerRecommendationDetails" },
                ]
            },
            {
                value: "Project", text: "Projects", field: 'projects', filterCategories: [
                    { value: "project_id", text: "Project Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    { value: "project_status_id", text: "Status", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "projectStatus", tableName: "lu_project_status", optionName: "projectStatusDetails" },
                    { value: "log_line", text: "Logline", isCoreField: true, type: "Large Text", fieldName: "projectLogline" },
                    { value: "genre_id", text: "Genre", isCoreField: true, type: "Multi Select", fieldName: "projectGenre", tableName: "lu_genre", optionName: "genreDetails" },
                    { value: "project_type_id", text: "Project Type", isCoreField: true, type: "Multi Select", fieldName: "projectType", tableName: "lu_project_type", optionName: "projectTypeDetails" },
                    { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", fieldName: "studioDivision", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                ]
            },
            {
                value: "Deal", text: "Deal", field: 'deals', filterCategories: [
                    { value: "document_name", text: "Document Name", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "dealDocumentName" },
                    { value: "project_id", text: "Project Title + AKAs", isCoreField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    { value: "writer_id,talent_group_id", text: "Writer(s)", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "dealWriter", tableName: "TALENT", optionName: "writersDetails" },
                    // { value: "writer_id", text: "Writer(s)", isCoreField: true, type: "Search", fieldName: "dealWriter", tableName: "TALENT", optionName: "writersDetails" },
                    { value: "memo_date", text: "Memo Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "dealMemoDate" },
                    { value: "ammendment_date", text: "Amendment Date", isCoreField: true, type: "Date", fieldName: "dealAmendmentDate" },
                    { value: "business_executive_id", text: "Business Affairs Executive(s)", isCoreField: true, type: "Search", fieldName: "businessAffairsExecutive", tableName: "USER", optionName: "businessAffairExecutiveDetails" },
                    { value: "feature_executive_id", text: "Feature Legal Executive(s)", isCoreField: true, type: "Search", fieldName: "featureLegalExecutive", tableName: "USER", optionName: "featureLegalExecutiveDetails" },
                ]
            },
            {
                value: "WeekendRead", text: "Weekend Read", field: 'weekend_reads', filterCategories: [
                    { value: "title", text: "Title", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "weekendReadTitle", tableName: "", optionName: "" },
                    { value: "weekend_read_date", text: "WER Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "weekendReadDate" },
                    { value: "advocate_id", text: "Per/Advocate", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "weekendReadAdvocate", tableName: "USER", optionName: "advocateDetails" },
                    { value: "assigned_to_id", text: "Assigned To / Also Reading", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "weekendReadAssignedTo", tableName: "USER", optionName: "assignedToDetails" },
                ]
            },
            {
                value: "Talent", text: "Talent", field: 'talent', filterCategories: [
                    { value: "talent_id, talent_group_id", text: "Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                    { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
                    { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
                    { value: "performer_notes", text: "Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
                ]
            },
            {
                value: "Representatives", text: "Representative", field: 'representative', filterCategories: [
                    { value: "representative_id", text: "Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeName", tableName: "REPRESENTATIVE", optionName: "repDetails" },
                    { value: "agency_id", text: "Company", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeCompany", tableName: "AGENCY", optionName: "agencyDetails" },
                    { value: "representative_type_id", text: "Representative Type", isCoreField: true, type: "Multi Select", fieldName: "representativeType", tableName: "lu_representative_type", optionName: "representativeTypeDetails" },
                    { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "representativeAddress", tableName: "lu_person_address_type", optionName: "addressTypeDetails" },
                ]
            },
            {
                value: "Agency", text: "Company", field: 'agency', filterCategories: [
                    { value: "agency_id", text: "Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "agencyName", tableName: "AGENCY", optionName: "agencyDetails" },
                    { value: "agency_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "agencyType", tableName: "lu_agency_types", optionName: "agencyTypeDetails" },
                    { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "agencyAddress" },
                    { value: "agency_notes", text: "Company Notes", isCoreField: true, type: "Large Text", fieldName: "agencyNotes" },
                ]
            },
        ]
    },
    {
        type: "Deals", value: "Deals", filters: [
            {
                value: "Deal", text: "Deal", field: 'deals', isExpand: true, filterCategories: [
                    { value: "document_name", text: "Document Name", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "dealDocumentName" },
                    { value: "project_id", text: "Project Title + AKAs", isCoreField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    { value: "writer_id,talent_group_id", text: "Writer(s)", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "dealWriter", tableName: "TALENT", optionName: "writersDetails" },
                    { value: "memo_date", text: "Memo Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "dealMemoDate" },
                    { value: "ammendment_date", text: "Amendment Date", isCoreField: true, type: "Date", fieldName: "dealAmendmentDate" },
                    { value: "business_executive_id", text: "Business Affairs Executive(s)", isCoreField: true, type: "Search", fieldName: "businessAffairsExecutive", tableName: "USER", optionName: "businessAffairExecutiveDetails" },
                    { value: "feature_executive_id", text: "Feature Legal Executive(s)", isCoreField: true, type: "Search", fieldName: "featureLegalExecutive", tableName: "USER", optionName: "featureLegalExecutiveDetails" },
                ]
            },
            {
                value: "Submissions", text: "Submissions", field: 'submissions', filterCategories: [
                    { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                    { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                    { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                    { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                    { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                    { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                    { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                    { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                ]
            },
            {
                value: "Project", text: "Projects", field: 'projects', filterCategories: [
                    { value: "project_id", text: "Project Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    { value: "project_status_id", text: "Status", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "projectStatus", tableName: "lu_project_status", optionName: "projectStatusDetails" },
                    { value: "log_line", text: "Logline", isCoreField: true, type: "Large Text", fieldName: "projectLogline" },
                    { value: "genre_id", text: "Genre", isCoreField: true, type: "Multi Select", fieldName: "projectGenre", tableName: "lu_genre", optionName: "genreDetails" },
                    { value: "project_type_id", text: "Project Type", isCoreField: true, type: "Multi Select", fieldName: "projectType", tableName: "lu_project_type", optionName: "projectTypeDetails" },
                    { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", fieldName: "studioDivision", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                ]
            },
            {
                value: "Talent", text: "Talent", field: 'talent', filterCategories: [
                    { value: "talent_id, talent_group_id", text: "Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                    { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
                    { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
                    { value: "performer_notes", text: "Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
                ]
            }
        ]
    },
    {
        type: "WGAs", value: "WGAs", filters: [
            {
                value: "Wga", text: "WGA", field: 'wga', isExpand: true, filterCategories: [
                    { value: "wga_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "wgaType", tableName: "lu_wga_type", optionName: "wgaTypeDetails" },
                    { value: "notice_date", text: "Notice Date", isCoreField: true, type: "Date", fieldName: "wgaNoticeDate" },
                    { value: "episode_title", text: "Title", isCoreField: true, type: "Text", fieldName: "wgaTitle" },
                    { value: "writer_name", text:"Participating Writers", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaWriters", tableName: "TALENT", optionName: "writerDetails" },
                    { value: "additional_writer_team_info", text: "Additional Writer Team Info", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaAdditionalWriters", tableName: "TALENT", optionName: "writerDetails" },
                    { value: "adjusted_representative_name", text: "Representation", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaRepresentation", tableName: "REPRESENTATIVE", optionName: "writerDetails" },
                    { value: "Writing Credits on this production are tentatively determined as follows", text: "Writing Credits on this production are tentatively determined as follows", isCoreField: true, type: "Text", fieldName: "wgaWritingCredits" },
                    { value: "on_screen_production_credit", text: "On screen and/or advertising, presentation and production credit, if any", type: "Text", isCoreField: true, fieldName: "wgaScreenAdvertisement" },
                ]
            },
            {
                value: "Project", text: "Projects", field: 'projects', filterCategories: [
                    { value: "project_id", text: "Project Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    { value: "project_status_id", text: "Status", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "projectStatus", tableName: "lu_project_status", optionName: "projectStatusDetails" },
                    { value: "log_line", text: "Logline", isCoreField: true, type: "Large Text", fieldName: "projectLogline" },
                    { value: "genre_id", text: "Genre", isCoreField: true, type: "Multi Select", fieldName: "projectGenre", tableName: "lu_genre", optionName: "genreDetails" },
                    { value: "project_type_id", text: "Project Type", isCoreField: true, type: "Multi Select", fieldName: "projectType", tableName: "lu_project_type", optionName: "projectTypeDetails" },
                    { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", fieldName: "studioDivision", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                ]
            },
            // {
            //     value: "Talent", text: "Talent", field: 'talent', filterCategories: [
            //         { value: "talent_id, talent_group_id", text: "Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
            //         { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
            //         { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
            //         { value: "performer_notes", text: "Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
            //     ]
            // },
            // {
            //     value: "Representative", text: "Representative", field: 'representative', filterCategories: [
            //         { value: "representative_id", text: "Representative Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeName", tableName: "REPRESENTATIVE", optionName: "repDetails" },
            //         { value: "agency_id", text: "Company", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeCompany", tableName: "AGENCY", optionName: "agencyDetails" },
            //         { value: "representative_type_id", text: "Representative Type", isCoreField: true, type: "Multi Select", fieldName: "representativeType", tableName: "lu_representative_type", optionName: "representativeTypeDetails" },
            //         { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "representativeAddress", tableName: "lu_person_address_type", optionName: "addressTypeDetails" },
            //     ]
            // },
        ]
    },
    {
        type: "Weekend Read", value: "Weekend_Reads", filters: [
            {
                value: "WeekendRead", text: "Weekend Read", field: 'weekend_reads', isExpand: true, filterCategories: [
                    { value: "title", text: "Title", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "weekendReadTitle", tableName: "", optionName: "" },
                    { value: "weekend_read_date", text: "WER Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "weekendReadDate" },
                    { value: "advocate_id", text: "Per/Advocate", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "weekendReadAdvocate", tableName: "USER", optionName: "advocateDetails" },
                    { value: "assigned_to_id", text: "Assigned To / Also Reading", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "weekendReadAssignedTo", tableName: "USER", optionName: "assignedToDetails" },
                ]
            },
            {
                value: "Submissions", text: "Submissions", field: 'submissions', filterCategories: [
                    { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                    { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                    { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                    { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                    { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                    { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                    { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                    { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                ]
            }

        ]
    },
    {
        type: "Talent", value: "Talent", filters: [
            {
                value: "Talent", text: "Talent", field: 'talent', isExpand: true, filterCategories: [
                    { value: "talent_id, talent_group_id", text: "Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                    { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
                    { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
                    { value: "performer_notes", text: "Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
                ]
            },
            {
                value: "Representative", text: "Representative", field: 'representative', filterCategories: [
                    { value: "representative_id", text: "Representative Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeName", tableName: "REPRESENTATIVE", optionName: "repDetails" },
                    { value: "agency_id", text: "Company", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeCompany", tableName: "AGENCY", optionName: "agencyDetails" },
                    { value: "representative_type_id", text: "Representative Type", isCoreField: true, type: "Multi Select", fieldName: "representativeType", tableName: "lu_representative_type", optionName: "representativeTypeDetails" },
                    { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "representativeAddress", tableName: "lu_person_address_type", optionName: "addressTypeDetails" },
                ]
            },
            {
                value: "Agency", text: "Company", field: 'agency', filterCategories: [
                    { value: "agency_id", text: "Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "agencyName", tableName: "AGENCY", optionName: "agencyDetails" },
                    { value: "agency_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "agencyType", tableName: "lu_agency_types", optionName: "agencyTypeDetails" },
                    { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "agencyAddress" },
                    { value: "agency_notes", text: "Company Notes", isCoreField: true, type: "Large Text", fieldName: "agencyNotes" },
                ]
            },
            {
                value: "Submissions", text: "Submissions", field: 'submissions', filterCategories: [
                    { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                    { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                    { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                    { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                    { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                    { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                    { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                    { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                ]
            },
            {
                value: "Project", text: "Projects", field: 'projects', filterCategories: [
                    { value: "project_id", text: "Project Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    { value: "project_status_id", text: "Status", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "projectStatus", tableName: "lu_project_status", optionName: "projectStatusDetails" },
                    { value: "log_line", text: "Logline", isCoreField: true, type: "Large Text", fieldName: "projectLogline" },
                    { value: "genre_id", text: "Genre", isCoreField: true, type: "Multi Select", fieldName: "projectGenre", tableName: "lu_genre", optionName: "genreDetails" },
                    { value: "project_type_id", text: "Project Type", isCoreField: true, type: "Multi Select", fieldName: "projectType", tableName: "lu_project_type", optionName: "projectTypeDetails" },
                    { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", fieldName: "studioDivision", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                ]
            },
            // {
            //     value: "Wga", text: "WGA", field: 'wga', filterCategories: [
            //         { value: "wga_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "wgaType", tableName: "lu_wga_type", optionName: "wgaTypeDetails" },
            //         { value: "notice_date", text: "Notice Date", isCoreField: true, type: "Date", fieldName: "wgaNoticeDate" },
            //         { value: "episode_title", text: "Title", isCoreField: true, type: "Text", fieldName: "wgaTitle" },
            //         { value: "adjusted_talent_name", text: "Participating Writers", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaWriters", tableName: "TALENT", optionName: "writerDetails" },
            //         { value: "Writing Credits on this production are tentatively determined as follows", text: "Writing Credits on this production are tentatively determined as follows", isCoreField: true, type: "Text", fieldName: "wgaWritingCredits" },
            //         { value: "on_screen_production_credit", text: "On screen and/or advertising, presentation and production credit, if any", type: "Text", isCoreField: true, fieldName: "wgaScreenAdvertisement" },
            //     ]
            // },
            {
                value: "Deal", text: "Deal", field: 'deals', filterCategories: [
                    { value: "document_name", text: "Document Name", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "dealDocumentName" },
                    { value: "project_id", text: "Project Title + AKAs", isCoreField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    { value: "writer_id,talent_group_id", text: "Writer(s)", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "dealWriter", tableName: "TALENT", optionName: "writersDetails" },
                    { value: "memo_date", text: "Memo Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "dealMemoDate" },
                    { value: "ammendment_date", text: "Amendment Date", isCoreField: true, type: "Date", fieldName: "dealAmendmentDate" },
                    { value: "business_executive_id", text: "Business Affairs Executive(s)", isCoreField: true, type: "Search", fieldName: "businessAffairsExecutive", tableName: "USER", optionName: "businessAffairExecutiveDetails" },
                    { value: "feature_executive_id", text: "Feature Legal Executive(s)", isCoreField: true, type: "Search", fieldName: "featureLegalExecutive", tableName: "USER", optionName: "featureLegalExecutiveDetails" },
                ]
            },
        ]
    },
    {
        type: "Representatives", value: "Representatives", filters: [
            {
                value: "Representative", text: "Representative", field: 'representative', isExpand: true, filterCategories: [
                    { value: "representative_id", text: "Representative Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeName", tableName: "REPRESENTATIVE", optionName: "representativeDetails" },
                    { value: "agency_id", text: "Company", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeCompany", tableName: "AGENCY", optionName: "agencyDetails" },
                    { value: "representative_type_id", text: "Representative Type", isCoreField: true, type: "Multi Select", fieldName: "representativeType", tableName: "lu_representative_type", optionName: "representativeTypeDetails" },
                    { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "representativeAddress", tableName: "lu_person_address_type", optionName: "addressTypeDetails" },
                ]
            },
            {
                value: "Agency", text: "Company", field: 'agency', filterCategories: [
                    { value: "agency_id", text: "Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "agencyName", tableName: "AGENCY", optionName: "agencyDetails" },
                    { value: "agency_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "agencyType", tableName: "lu_agency_types", optionName: "agencyTypeDetails" },
                    { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "agencyAddress" },
                    { value: "agency_notes", text: "Company Notes", isCoreField: true, type: "Large Text", fieldName: "agencyNotes" },
                ]
            },
            {
                value: "Talent", text: "Talent", field: 'talent', filterCategories: [
                    { value: "talent_id, talent_group_id", text: "Talent Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                    { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
                    { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
                    { value: "performer_notes", text: "Performer Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
                ]
            },
            {
                value: "Submissions", text: "Submissions", field: 'submissions', filterCategories: [
                    { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                    { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                    { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                    { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                    { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                    { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                    { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                    { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                ]
            },
            // {
            //     value: "Wga", text: "WGA", field: 'wga', filterCategories: [
            //         { value: "wga_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "wgaType", tableName: "lu_wga_type", optionName: "wgaTypeDetails" },
            //         { value: "notice_date", text: "Notice Date", isCoreField: true, type: "Date", fieldName: "wgaNoticeDate" },
            //         { value: "episode_title", text: "Title", isCoreField: true, type: "Text", fieldName: "wgaTitle" },
            //         { value: "adjusted_talent_name", text: "Participating Writers", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaWriters", tableName: "TALENT", optionName: "writerDetails" },
            //         { value: "Writing Credits on this production are tentatively determined as follows", text: "Writing Credits on this production are tentatively determined as follows", isCoreField: true, type: "Text", fieldName: "wgaWritingCredits" },
            //         { value: "on_screen_production_credit", text: "On screen and/or advertising, presentation and production credit, if any", type: "Text", isCoreField: true, fieldName: "wgaScreenAdvertisement" },
            //     ]
            // },
        ]
    },
    {
        type: "Companies", value: "Companies", filters: [
            {
                value: "Agency", text: "Company", field: 'agency', isExpand: true, filterCategories: [
                    { value: "agency_id", text: "Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "agencyName", tableName: "AGENCY", optionName: "agencyDetails" },
                    { value: "agency_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "agencyType", tableName: "lu_agency_types", optionName: "agencyTypeDetails" },
                    { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "agencyAddress" },
                    { value: "agency_notes", text: "Company Notes", isCoreField: true, type: "Large Text", fieldName: "agencyNotes" },
                ]
            },
            {
                value: "Representative", text: "Representative", field: 'representative', filterCategories: [
                    { value: "representative_id", text: "Representative Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeName", tableName: "REPRESENTATIVE", optionName: "representativeDetails" },
                    { value: "agency_id", text: "Company", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeCompany", tableName: "AGENCY", optionName: "agencyDetails" },
                    { value: "representative_type_id", text: "Representative Type", isCoreField: true, type: "Multi Select", fieldName: "representativeType", tableName: "lu_representative_type", optionName: "representativeTypeDetails" },
                    { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "representativeAddress", tableName: "lu_person_address_type", optionName: "addressTypeDetails" },
                ]
            },
            {
                value: "Talent", text: "Talent", field: 'talent', filterCategories: [
                    { value: "talent_id, talent_group_id", text: "Talent Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                    { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
                    { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
                    { value: "performer_notes", text: "Performer Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
                ]
            },
            {
                value: "Submissions", text: "Submissions", field: 'submissions', filterCategories: [
                    { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                    { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                    { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                    { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                    { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                    { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                    { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                    { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                ]
            }
        ]
    },
]

export const allCriteriaConfig = [
    {
        type: "Submissions", value: "Submissions", filters: [
            {
                value: "Submissions", text: "Submissions", field: 'submissions', isExpand: true, filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                            { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                            { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                            { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                            { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                            { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                        ]
                    },
                    {
                        type: "Material Details", filterCategories: [
                            { value: "pgs", text: "Page Count/WB Page Count", type: "Radio Number Range", fieldName: "submissionPageCount" },
                            { value: "draft_version", text: "Draft Version", type: "Text", fieldName: "submissionDraftVersion" },
                            { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "deal_memo_step_id", text: "Deal Step", type: 'Search', fieldName: "dealStep", tableName: 'DEAL_STEPS', optionaName: 'dealStepDetails' },
                            { value: "is_term_deal_producer", text: "Term Deal Producer", type: "Boolean", fieldName: "submissionTermDeal", tableName: "none", optionName: "genericDetails" },
                            { value: "term_deal_company", text: "Term Deal Company", type: "Text", fieldName: "submissionTermDealCompany", tableName: "lu_project_status", optionName: "companyDetails" },
                            { value: "box", text: "Box #", type: "Text", fieldName: "submissionBox" },
                            { value: "sku", text: "SKU #", type: "Text", fieldName: "submissionSKU" },
                            { value: "submission_notes", text: "Submission Notes", type: "Large Text", fieldName: "submissionNotes" },
                        ]
                    },
                    {
                        type: "Source Material", filterCategories: [
                            { value: "title", text: "Source Material Title", type: "Text", fieldName: "submissionMaterialTitle" },
                            { value: "material_type_id", text: "Source Material Type", type: "Select", fieldName: "submissionMaterialType", tableName: "lu_material_type", optionName: "materialTypeDetails" },
                            { value: "author", text: "Source Material Author", type: "Text", fieldName: "submissionMaterialAuthor" },
                            { value: "publisher", text: "Source Material Publisher", type: "Text", fieldName: "submissionMaterialPublisher" },
                            { value: "source_material_date", text: "Source Material Date", type: "Date", fieldName: "submissionMaterialDate" },
                            { value: "copy_right_date", text: "Source Material Copyright Date", type: "Date", fieldName: "submissionCopyrightDate" },
                        ]
                    },
                    {
                        type: "Security", filterCategories: [
                            { value: "is_confidential", text: "Confidential", type: "Boolean", fieldName: "submissionConfidential", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_author", text: "Hide Author", type: "Boolean", fieldName: "submissionHideAuthor", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_title", text: "Hide Title", type: "Boolean", fieldName: "submissionHideTitle", tableName: "none", optionName: "genericDetails" },
                            { value: "is_un_official,is_interim", text: "Unofficial / Interim", type: "Boolean", fieldName: "submissionUnofficial", tableName: "submission", optionName: "submissionDetails" },
                        ]
                    },
                    {
                        type: "Elements", filterCategories: [
                            { value: "talent_id,talent_group_id", text: "Element's Name", type: "Search", tableName: "TALENT", optionName: "submissionElementNameDetails", fieldName: "submissionElementName" },
                            { value: "role_id", text: "Element's Role", type: "Select", fieldName: "submissionElementRole", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "element_status_id", text: "Element's Status", type: "Select", fieldName: "submissionElementStatus", tableName: "lu_element_status", optionName: "elementDetails" }
                        ]
                    },
                    // {
                    //     type: "Project/Deal Link", filterCategories: [
                    //         { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    //         { value: "deal_step_id", text: "Deal Step", type: 'Multi Select', fieldName: "dealStep", tableName: 'DEAL_STEP', optionaName: 'dealStepDetails' },
                    //     ]
                    // }
                ]
            },
            {
                value: "Coverages", text: "Coverages", field: 'coverages', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "analyst_id", text: "Analyst", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "coveragesAnalyst", tableName: "USER", optionName: "analystDetails" },
                            { value: "coverage_type_id", text: "Coverage Type", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "coverageType", tableName: "lu_coverage_types", optionName: "coveragesDetails" },
                            { value: "logline", text: "Logline", isCoreField: true, type: "Large Text", fieldName: "coverageLogline" },
                            { value: "genre_id", text: "Genre", isCoreField: true, type: "Multi Select", fieldName: "coverageGenre", tableName: "lu_genre", optionName: "genreDetails" },
                            { value: "locale", text: "Locale", isCoreField: true, type: "Text", fieldName: "coverageLocale" },
                            { value: "time", text: "Time", isCoreField: true, type: "Text", fieldName: "coverageTime" },
                            { value: "recommendation", text: "Recommendation", isCoreField: true, type: "Select", fieldName: "recommendation", tableName: "lu_recommendation", optionName: "recommendationDetails" },
                            { value: "writer_recommendation", text: "Writer Recommendation", isCoreField: true, type: "Select", fieldName: "writerRecommendation", tableName: "lu_writer_recommendation", optionName: "writerRecommendationDetails" },
                        ]
                    },
                    {
                        type: "All Criteria", filterCategories: [
                            { value: "synopsis", text: "Synopsis", type: "Large Text", fieldName: "coveragesSynopsis" },
                            { value: "comparison", text: "Comparison", type: "Large Text", fieldName: "projectSourceCopyDate" },
                            { value: "comments", text: "Comments", type: "Large Text", fieldName: "projectSourceCopyDate" },
                            { value: "recommendation_summary", text: "Recommendation Summary", type: "Large Text", fieldName: "projectSourceCopyDate" },
                            { value: "writer_summary", text: "Writer Summary", type: "Large Text", fieldName: "projectSourceCopyDate" },
                            { value: "status_id,created_at", text: "Date Sent", type: "Date", fieldName: "coveragesDateSent", range: true },
                            { value: "due_date", text: "Due Date", type: "Date", fieldName: "coveragesDueDate", range: true },
                            { value: "return_date", text: "Date Returned", type: "Date", fieldName: "coveragesDateReturned", range: true },
                            { value: "compare_to_id", text: "Compared To Submission", type: "Search", fieldName: "compareToSubmission", tableName: "SUBMISSION", optionName: "compareToSubmissionDetails" },
                            { value: "status_id", text: "Status", type: "Multi Select", fieldName: "coveragesStatus", tableName: "lu_submission_status", optionName: "coverageStatusDetails" },
                        ]
                    }
                ]
            },
            {
                value: "Project", text: "Projects", field: 'projects', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "project_id", text: "Project Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "project_status_id", text: "Status", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "projectStatus", tableName: "lu_project_status", optionName: "projectStatusDetails" },
                            { value: "log_line", text: "Logline", isCoreField: true, type: "Large Text", fieldName: "projectLogline" },
                            { value: "genre_id", text: "Genre", isCoreField: true, type: "Multi Select", fieldName: "projectGenre", tableName: "lu_genre", optionName: "genreDetails" },
                            { value: "project_type_id", text: "Project Type", isCoreField: true, type: "Multi Select", fieldName: "projectType", tableName: "lu_project_type", optionName: "projectTypeDetails" },
                            { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", fieldName: "studioDivision", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                        ]
                    },
                    {
                        type: "Project Dates", filterCategories: [
                            { value: "prod_start_date", text: "Prod. Start Date", type: "Date", fieldName: "projectStartDate" },
                            { value: "prod_start_date_tbd", text: "Prod. Start Date TBD", type: "Boolean", fieldName: "projectStartDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "wrap_date", text: "Wrap Date", type: "Date", fieldName: "projectWrapDate" },
                            { value: "wrap_date_tbd", text: "Wrap Date TBD", type: "Boolean", fieldName: "projectWrapDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "release_date", text: "Release Date", type: "Date", fieldName: "projectReleaseDate" },
                            { value: "release_date_tb", text: "Release Date TBD", type: "Boolean", fieldName: "projectReleaseDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "rights_lapse_date", text: "Rights Lapse Date", type: "Date", fieldName: "projectRightsLapseDate" },
                        ]
                    },
                    {
                        type: "Project Details", filterCategories: [
                            { value: "project_notes", text: "Project Notes", type: "Large Text", fieldName: "projectNotes" },
                            { value: "distribution_company_id", text: "Distribution Company", type: "Multi Select", fieldName: "projectDistributionCompany", tableName: "lu_distribution_company", optionName: "distributionDetails" },
                            { value: "is_confidential", text: "Confidential", type: "Boolean", fieldName: "projectConfidential", tableName: "none", optionName: "genericDetails" },
                            { value: "material_location", text: "Material location", type: "Text", fieldName: "projectMaterialLocation" },
                            { value: "vault_information", text: "Vault Information", type: "Large Text", fieldName: "projectVaultLocation" },
                        ]
                    },
                    {
                        type: "Source Material", filterCategories: [
                            { value: "title", text: "Source Material Title", type: "Text", fieldName: "projectSourceTitle" },
                            { value: "material_type_id", text: "Source Material Type", type: "Select", fieldName: "projectSourceType", tableName: "lu_material_type", optionName: "materialTypeDetails" },
                            { value: "author", text: "Source Material Author", type: "Text", fieldName: "submissionMaterialAuthor" },
                            { value: "publisher", text: "Source Material Publisher", type: "Text", fieldName: "projectSourcePublisher" },
                            { value: "published_date", text: "Source Material Date", type: "Date", fieldName: "projectSourceDate" },
                            { value: "copyright_date", text: "Source Material Copyright Date", type: "Date", fieldName: "projectSourceCopyDate" }
                        ]
                    },
                    {
                        type: "Executives", filterCategories: [
                            { value: "executive_id", text: "Executives", configText: 'Name', type: "Search", fieldName: "projectExecutives", tableName: "USER", optionName: "executiveDetails" },
                            { value: "department", text: "Executive's Department", configText: "Department", type: "Select", fieldName: "projectExecDepartment", tableName: "lu_element_status", optionName: "departmentDetails" },
                            { value: "title", text: "Executive's Title", configText: "Title", type: "Text", fieldName: "projectExecTitle" },
                            { value: "start_date", text: "Executive's Project Date", configText: "From Date to Date", type: "Date", fieldName: "executiveFromDateToDate" },
                            { value: "executive_is_active", text: "Executive is Active", type: "Select", fieldName: "executiveIsActive" }
                        ]
                    },
                    // {
                    //     type: "Linked Talent, Deals & Drafts", filterCategories: [
                    //         { value: "talent_id, talent_group_id", text: "Talent Name + AKAs", isCoreField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                    //         { value: "submission_id", text: "Linked Draft (Submission) Title + AKAs", isCoreField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                    //         { value: "document_name", text: "Deal Document Name", isCoreField: true, type: "Text", fieldName: "dealDocumentName" },
                    //     ]
                    // },
                ]
            },
            {
                value: "Deal", text: "Deal", field: 'deals', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "document_name", text: "Document Name", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "dealDocumentName" },
                            { value: "project_id", text: "Project Title + AKAs", isCoreField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "writer_id,talent_group_id", text: "Writer(s)", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "dealWriter", tableName: "TALENT", optionName: "writersDetails" },
                            { value: "memo_date", text: "Memo Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "dealMemoDate" },
                            { value: "ammendment_date", text: "Amendment Date", isCoreField: true, type: "Date", fieldName: "dealAmendmentDate" },
                            { value: "business_executive_id", text: "Business Affairs Executive(s)", isCoreField: true, type: "Search", fieldName: "businessAffairsExecutive", tableName: "USER", optionName: "businessAffairExecutiveDetails" },
                            { value: "feature_executive_id", text: "Feature Legal Executive(s)", isCoreField: true, type: "Search", fieldName: "featureLegalExecutive", tableName: "USER", optionName: "featureLegalExecutiveDetails" },
                        ]
                    },
                    {
                        type: "Deal Details", filterCategories: [
                            { value: "assigned_material", text: "Assigned Material", type: "Text", fieldName: "dealAssignedMaterial" },
                            { value: "deal_notes", text: "Deal Notes", type: "Large Text", fieldName: "dealNotes" },
                            { value: "ammendment_notes", text: "Amendment Notes", type: "Large Text", fieldName: "dealAmendmentNotes" },
                        ]
                    },
                    {
                        type: "Deal Steps", filterCategories: [
                            { value: "deal_step_id", text: "Deal Step", type: 'Multi Select', fieldName: "dealStep", tableName: 'DEAL_STEPS', optionaName: 'dealStepDetails' },
                            { value: "deal_notes", text: "Deal Step Notes", type: "Large Text", fieldName: "dealStepNotes" },
                            { value: "writing_start", text: "Writing Start Date", type: "Date", fieldName: "dealWritingStartDate", range: true },
                            // { value: "writing_due", text: "Writing End Date", type: "Date", fieldName: "dealWritingEndDate", range: true },
                            { value: "delivery_date", text: "Delivery Date", type: "Date", fieldName: "dealDelivery" },
                            { value: "reading_end", text: "Reading End Date", type: "Date", fieldName: "dealReadingEnd" },
                            { value: "writing_due", text: "Writing Due Range", type: "Date", fieldName: "dealWritingDue", range: true },
                            { value: "currency_type_id", text: "Currency Type", type: "Multi Select", fieldName: "currencyType", tableName: "lu_currency_type", optionName: "currencyDetails" },
                            { value: "payment", text: "Payment", type: "Radio Number Range", fieldName: "dealPayment" },
                            // { value: "title", text: "Linked Draft (Submission) Title + AKAs", isCoreField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                        ]
                    },
                ]
            },
            {
                value: "WeekendRead", text: "Weekend Read", field: 'weekend_reads', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "title", text: "Title", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "weekendReadTitle", tableName: "", optionName: "" },
                            { value: "weekend_read_date", text: "WER Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "weekendReadDate" },
                            { value: "advocate_id", text: "Per/Advocate", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "weekendReadAdvocate", tableName: "USER", optionName: "advocateDetails" },
                            { value: "assigned_to_id", text: "Assigned To / Also Reading", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "weekendReadAssignedTo", tableName: "USER", optionName: "assignedToDetails" },
                        ]
                    },
                    {
                        type: "Notes + Remarks", filterCategories: [
                            { value: "internal_office_notes", text: "Internal Office Notes", type: "Large Text", fieldName: "weekendReadInternalOfficeNotes" },
                            { value: "memo_remarks", text: "Memo Remarks/Question", type: "Large Text", fieldName: "weekendReadMemoRemarks" },
                            { value: "notes", text: "Agenda Meeting Notes", type: "Large Text", fieldName: "weekendReadAgendaMeetingNotes" }
                        ]
                    },
                    {
                        type: "Details", filterCategories: [
                            { value: "already_distributed", text: "Already Distributed", type: "Boolean", fieldName: "alreadyDistributed", tableName: "none", optionName: "genericDetails" },
                            { value: "coverage", text: "Coverage", type: "Boolean", fieldName: "coverage", tableName: "none", optionName: "genericDetails" },
                            { value: "coverage_tbd", text: "Coverage TBD", type: "Boolean", fieldName: "coverageTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "coverage_only", text: "Coverage Only", type: "Boolean", fieldName: "coverageOnly", tableName: "none", optionName: "genericDetails" },
                            { value: "development", text: "Deevelopment", type: "Boolean", fieldName: "development", tableName: "none", optionName: "genericDetails" },
                            { value: "discussion", text: "Discussion", type: "Boolean", fieldName: "discussion", tableName: "none", optionName: "genericDetails" },
                            { value: "list_attached", text: "List Attached", type: "Boolean", fieldName: "listAttached", tableName: "none", optionName: "genericDetails" },
                            { value: "memo_attached", text: "Memo Attached", type: "Boolean", fieldName: "memoAttached", tableName: "none", optionName: "genericDetails" },
                            { value: "no_material", text: "No Material", type: "Boolean", fieldName: "no_material", tableName: "none", optionName: "genericDetails" },
                            { value: "old_or_new_cov_attached", text: "Old Cov. Attached/New Cov. TBD", type: "Boolean", fieldName: "oldCovAttached", tableName: "none", optionName: "genericDetails" },
                            { value: "packaging", text: "Packaging", type: "Boolean", fieldName: "packaging", tableName: "none", optionName: "genericDetails" },
                            { value: "priority_development", text: "Priority Development", type: "Boolean", fieldName: "priorityDevelopment", tableName: "none", optionName: "genericDetails" },
                            { value: "production", text: "Production", type: "Boolean", fieldName: "production", tableName: "none", optionName: "genericDetails" },
                            { value: "security_disclaimer", text: "Security Disclaimer", type: "Boolean", fieldName: "securityDisclaimer", tableName: "none", optionName: "genericDetails" },
                            { value: "separate_page", text: "Separate Page", type: "Boolean", fieldName: "separate_page", tableName: "none", optionName: "genericDetails" },
                            { value: "produced_submitted_by", text: "Produced/Submitted By", isMandatoryField: true, type: "Boolean", fieldName: "producedBy", tableName: "none", optionName: "genericDetails" },
                            { value: "synopsis", text: "Synopsis", type: "Boolean", fieldName: "synopsis", tableName: "none", optionName: "genericDetails" },
                            { value: "talent", text: "Talent", type: "Boolean", fieldName: "talent", tableName: "none", optionName: "genericDetails" },
                        ]
                    },
                    {
                        type: "Attached Material", filterCategories: [
                            { value: "exec_read_id", text: "Execs Who've Read", type: "Search", fieldName: "weekendReadExecWhoRead", tableName: "USER", optionName: "execDetails" },
                            { value: "attached_coverage_id", text: "Attached Coverages", type: "Search", fieldName: "weekendReadAttachedCoverages", tableName: "SUBMISSION", optionName: "coverageDetails" },
                            { value: "additional_material", text: "Additional Material", type: "Text", fieldName: "weekendReadAdditionalMaterial" },
                        ]
                    }
                ]
            },
            {
                value: "Talent", text: "Talent", field: 'talent', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "talent_id, talent_group_id", text: "Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
                            { value: "performer_notes", text: "Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
                        ]
                    },
                    {
                        type: "All Criteria", filterCategories: [
                            { value: "company", text: "Company", isCoreField: true, type: "Text", fieldName: "talentCompany" },
                            { value: "deceased", text: "Deceased", type: "Boolean", fieldName: "talentDeceased", tableName: "none", optionName: "genericDetails" },
                            { value: "project_id", text: "Linked Projects", isCoreField: true, type: "Search", fieldName: "linkedProject", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "title", text: "Linked Submissions", isCoreField: true, type: "Search", fieldName: "linkedSubmission", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "wga_type", text: "Linked WGAs", isCoreField: true, type: "Search", fieldName: "linkedWGA", tableName: "SUBMISSION", optionName: "submissionDetails" },
                        ]
                    }
                ]
            },
            {
                value: "Representative", text: "Representative", field: 'representative', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "representative_id", text: "Representative Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeName", tableName: "REPRESENTATIVE", optionName: "representativeDetails" },
                            { value: "agency_id", text: "Company", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeCompany", tableName: "AGENCY", optionName: "agencyDetails" },
                            { value: "representative_type_id", text: "Representative Type", isCoreField: true, type: "Multi Select", fieldName: "representativeType", tableName: "lu_representative_type", optionName: "representativeTypeDetails" },
                            { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "representativeAddress", tableName: "lu_person_address_type", optionName: "addressTypeDetails" },
                        ]
                    }
                ]
            },
            {
                value: "Agency", text: "Company", field: 'agency', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "agency_id", text: "Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "agencyName", tableName: "AGENCY", optionName: "agencyDetails" },
                            { value: "agency_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "agencyType", tableName: "lu_agency_types", optionName: "agencyTypeDetails" },
                            { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "agencyAddress" },
                            { value: "agency_notes", text: "Company Notes", isCoreField: true, type: "Large Text", fieldName: "agencyNotes" },
                        ]
                    }
                ]
            }
        ]
    },
    {
        type: "Projects", value: "Projects", filters: [
            {
                value: "Project", text: "Projects", field: 'projects', isExpand: true, filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "project_id", text: "Project Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "project_status_id", text: "Status", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "projectStatus", tableName: "lu_project_status", optionName: "projectStatusDetails" },
                            { value: "log_line", text: "Logline", isCoreField: true, type: "Large Text", fieldName: "projectLogline" },
                            { value: "genre_id", text: "Genre", isCoreField: true, type: "Multi Select", fieldName: "projectGenre", tableName: "lu_genre", optionName: "genreDetails" },
                            { value: "project_type_id", text: "Project Type", isCoreField: true, type: "Multi Select", fieldName: "projectType", tableName: "lu_project_type", optionName: "projectTypeDetails" },
                            { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", fieldName: "studioDivision", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                        ]
                    },
                    {
                        type: "Project Dates", filterCategories: [
                            { value: "prod_start_date", text: "Prod. Start Date", type: "Date", fieldName: "projectStartDate" },
                            { value: "prod_start_date_tbd", text: "Prod. Start Date TBD", type: "Boolean", fieldName: "projectStartDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "wrap_date", text: "Wrap Date", type: "Date", fieldName: "projectWrapDate" },
                            { value: "wrap_date_tbd", text: "Wrap Date TBD", type: "Boolean", fieldName: "projectWrapDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "release_date", text: "Release Date", type: "Date", fieldName: "projectReleaseDate" },
                            { value: "release_date_tb", text: "Release Date TBD", type: "Boolean", fieldName: "projectReleaseDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "rights_lapse_date", text: "Rights Lapse Date", type: "Date", fieldName: "projectRightsLapseDate" },
                        ]
                    },
                    {
                        type: "Project Details", filterCategories: [
                            { value: "project_notes", text: "Project Notes", type: "Large Text", fieldName: "projectNotes" },
                            { value: "distribution_company_id", text: "Distribution Company", type: "Multi Select", fieldName: "projectDistributionCompany", tableName: "lu_distribution_company", optionName: "distributionDetails" },
                            { value: "is_confidential", text: "Confidential", type: "Boolean", fieldName: "projectConfidential", tableName: "none", optionName: "genericDetails" },
                            { value: "material_location", text: "Material location", type: "Text", fieldName: "projectMaterialLocation" },
                            { value: "vault_information", text: "Vault Information", type: "Large Text", fieldName: "projectVaultLocation" },
                        ]
                    },
                    {
                        type: "Source Material", filterCategories: [
                            { value: "title", text: "Source Material Title", type: "Text", fieldName: "projectSourceTitle" },
                            { value: "material_type_id", text: "Source Material Type", type: "Select", fieldName: "projectSourceType", tableName: "lu_material_type", optionName: "materialTypeDetails" },
                            { value: "author", text: "Source Material Author", type: "Text", fieldName: "submissionMaterialAuthor" },
                            { value: "publisher", text: "Source Material Publisher", type: "Text", fieldName: "projectSourcePublisher" },
                            { value: "published_date", text: "Source Material Date", type: "Date", fieldName: "projectSourceDate" },
                            { value: "copyright_date", text: "Source Material Copyright Date", type: "Date", fieldName: "projectSourceCopyDate" }
                        ]
                    },
                    {
                        type: "Executives", filterCategories: [
                            { value: "executive_id", text: "Executives", configText: 'Name', type: "Search", fieldName: "projectExecutives", tableName: "USER", optionName: "executiveDetails" },
                            { value: "department", text: "Executive's Department", configText: "Department", type: "Select", fieldName: "projectExecDepartment", tableName: "lu_element_status", optionName: "departmentDetails" },
                            { value: "title", text: "Executive's Title", configText: "Title", type: "Text", fieldName: "projectExecTitle" },
                            { value: "start_date", text: "Executive's Project Date", configText: "From Date to Date", type: "Date", fieldName: "executiveFromDateToDate" },
                            { value: "executive_is_active", text: "Executive is Active", type: "Select", fieldName: "executiveIsActive" }
                        ]
                    },
                    // {
                    //     type: "Linked Talent, Deals & Drafts", filterCategories: [
                    //         { value: "talent_id, talent_group_id", text: "Talent Name + AKAs", isCoreField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                    //         { value: "title", text: "Linked Draft (Submission) Title + AKAs", isCoreField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                    //         { value: "document_name", text: "Document Name", isCoreField: true, type: "Text", fieldName: "dealDocumentName" },
                    //     ]
                    // },
                ]
            },
            {
                value: "Submissions", text: "Submissions", field: 'submissions', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                            { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                            { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                            { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                            { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                            { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                        ]
                    },
                    {
                        type: "Material Details", filterCategories: [
                            { value: "pgs", text: "Page Count/WB Page Count", type: "Radio Number Range", fieldName: "submissionPageCount" },
                            { value: "draft_version", text: "Draft Version", type: "Text", fieldName: "submissionDraftVersion" },
                            { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "deal_memo_step_id", text: "Deal Step", type: 'Search', fieldName: "dealStep", tableName: 'DEAL_STEPS', optionaName: 'dealStepDetails' },
                            { value: "is_term_deal_producer", text: "Term Deal Producer", type: "Boolean", fieldName: "submissionTermDeal", tableName: "none", optionName: "genericDetails" },
                            { value: "term_deal_company", text: "Term Deal Company", type: "Text", fieldName: "submissionTermDealCompany", tableName: "lu_project_status", optionName: "companyDetails" },
                            { value: "box", text: "Box #", type: "Text", fieldName: "submissionBox" },
                            { value: "sku", text: "SKU #", type: "Text", fieldName: "submissionSKU" },
                            { value: "submission_notes", text: "Submission Notes", type: "Large Text", fieldName: "submissionNotes" },
                        ]
                    },
                    {
                        type: "Source Material", filterCategories: [
                            { value: "title", text: "Title", type: "Text", fieldName: "submissionMaterialTitle" },
                            { value: "material_type_id", text: "Type", type: "Select", fieldName: "submissionMaterialType", tableName: "lu_material_type", optionName: "materialTypeDetails" },
                            { value: "author", text: "Author", type: "Text", fieldName: "submissionMaterialAuthor" },
                            { value: "publisher", text: "Publisher", type: "Text", fieldName: "submissionMaterialPublisher" },
                            { value: "source_material_date", text: "Publisher Date", type: "Date", fieldName: "submissionMaterialDate" },
                            { value: "copy_right_date", text: "Copyright Date", type: "Date", fieldName: "submissionCopyrightDate" },
                        ]
                    },
                    {
                        type: "Security", filterCategories: [
                            { value: "is_confidential", text: "Confidential", type: "Boolean", fieldName: "submissionConfidential", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_author", text: "Hide Author", type: "Boolean", fieldName: "submissionHideAuthor", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_title", text: "Hide Title", type: "Boolean", fieldName: "submissionHideTitle", tableName: "none", optionName: "genericDetails" },
                            { value: "is_un_official,is_interim", text: "Unofficial / Interim", type: "Boolean", fieldName: "submissionUnofficial", tableName: "submission", optionName: "submissionDetails" },
                        ]
                    },
                    {
                        type: "Elements", filterCategories: [
                            { value: "talent_id,talent_group_id", text: "Name", type: "Search", tableName: "TALENT", optionName: "submissionElementNameDetails", fieldName: "submissionElementName" },
                            { value: "role_id", text: "Role", type: "Select", fieldName: "submissionElementRole", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "element_status_id", text: "Status", type: "Select", fieldName: "submissionElementStatus", tableName: "lu_element_status", optionName: "elementDetails" }
                        ]
                    },
                    // {
                    //     type: "Project/Deal Link", filterCategories: [
                    //         { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    //         { value: "deal_step_id", text: "Deal Step", type: 'Multi Select', fieldName: "dealStep", tableName: 'DEAL_STEP', optionaName: 'dealStepDetails' },
                    //     ]
                    // }
                ]
            },
            {
                value: "WGA", text: "WGA", field: 'wga', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "wga_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "wgaType", tableName: "lu_wga_type", optionName: "wgaTypeDetails" },
                            { value: "notice_date", text: "Notice Date", isCoreField: true, type: "Date", fieldName: "wgaNoticeDate" },
                            { value: "episode_title", text: "Title", isCoreField: true, type: "Text", fieldName: "wgaTitle" },
                            { value: "writer_name", text:"Participating Writers", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaWriters", tableName: "TALENT", optionName: "writerDetails" },
                            { value: "additional_writer_team_info", text: "Additional Writer Team Info", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaAdditionalWriters", tableName: "TALENT", optionName: "writerDetails" },
                            { value: "adjusted_representative_name", text: "Representation", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaRepresentation", tableName: "REPRESENTATIVE", optionName: "writerDetails" },
                            { value: "Writing Credits on this production are tentatively determined as follows", text: "Writing Credits on this production are tentatively determined as follows", isCoreField: true, type: "Text", fieldName: "wgaWritingCredits" },
                            { value: "on_screen_production_credit", text: "On screen and/or advertising, presentation and production credit, if any", type: "Text", isCoreField: true, fieldName: "wgaScreenAdvertisement" },
                        ]
                    },
                    {
                        type: "Details", filterCategories: [
                            //{ value: "adjusted_representative_name", text: "Representation", isMandatoryField: true, type: "Search", fieldName: "wgaRepresentation", tableName: "REPRESENTATIVE", optionName: "representationDetails" },
                            { value: "adjusted_representative_address", text: "Address", isMandatoryField: true, type: "Large Text", fieldName: "wgaAddress" },
                            { value: "executive_producer", text: "Executive Producer(s)", type: "Large Text", fieldName: "wgaExecProducers" },
                            { value: "producer", text: "Producer(s)", type: "Large Text", fieldName: "wgaProducers" },
                            { value: "co_producer", text: "Co-Producer(s)", type: "Large Text", fieldName: "wgaCoProducers" },
                            { value: "associate_producer", text: "Assoc. Producer(s)", type: "Large Text", fieldName: "wgaAssocProducers" },
                            { value: "director", text: "Director(s)", type: "Large Text", fieldName: "wgaDirectors" },
                            { value: "other_production_staff", text: "Other Production Executive(s), and their title(s), if participating writer(s)", type: "Large Text", fieldName: "wgaOthers" },
                            { value: "source_material", text: "Source Material upon which the motion picture is based, if any", type: "Large Text", fieldName: "wgaSourceMaterial" },
                            { value: "on_screen_source_credit", text: "On Screen Source Material Credit, if any", type: "Large Text", fieldName: "wgaScreenCredit" },
                        ]
                    },
                ]
            },
            {
                value: "Deal", text: "Deal", field: 'deals', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "document_name", text: "Document Name", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "dealDocumentName" },
                            { value: "project_id", text: "Project Title + AKAs", isCoreField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "writer_id,talent_group_id", text: "Writer(s)", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "dealWriter", tableName: "TALENT", optionName: "writersDetails" },
                            { value: "memo_date", text: "Memo Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "dealMemoDate" },
                            { value: "ammendment_date", text: "Amendment Date", isCoreField: true, type: "Date", fieldName: "dealAmendmentDate" },
                            { value: "business_executive_id", text: "Business Affairs Executive(s)", isCoreField: true, type: "Search", fieldName: "businessAffairsExecutive", tableName: "USER", optionName: "businessAffairExecutiveDetails" },
                            { value: "feature_executive_id", text: "Feature Legal Executive(s)", isCoreField: true, type: "Search", fieldName: "featureLegalExecutive", tableName: "USER", optionName: "featureLegalExecutiveDetails" },
                        ]
                    },
                    {
                        type: "Deal Details", filterCategories: [
                            { value: "assigned_material", text: "Assigned Material", type: "Text", fieldName: "dealAssignedMaterial" },
                            { value: "deal_notes", text: "Deal Notes", type: "Large Text", fieldName: "dealNotes" },
                            { value: "ammendment_notes", text: "Amendment Notes", type: "Large Text", fieldName: "dealAmendmentNotes" },
                        ]
                    },
                    {
                        type: "Deal Steps", filterCategories: [
                            { value: "deal_step_id", text: "Deal Step", type: 'Multi Select', fieldName: "dealStep", tableName: 'DEAL_STEPS', optionaName: 'dealStepDetails' },
                            { value: "deal_notes", text: "Deal Step Notes", type: "Large Text", fieldName: "dealStepNotes" },
                            { value: "writing_start", text: "Writing Start Date", type: "Date", fieldName: "dealWritingStartDate", range: true },
                            // { value: "writing_end", text: "Writing End Date", type: "Date", fieldName: "dealWritingEndDate", range: true },
                            { value: "delivery_date", text: "Delivery Date", type: "Date", fieldName: "dealDelivery" },
                            { value: "reading_end", text: "Reading End Date", type: "Date", fieldName: "dealReadingEnd" },
                            { value: "writing_due", text: "Writing Due Range", type: "Date", fieldName: "dealWritingDue", range: true },
                            { value: "currency_type_id", text: "Currency Type", type: "Multi Select", fieldName: "currencyType", tableName: "lu_currency_type", optionName: "currencyDetails" },
                            { value: "payment", text: "Payment", type: "Radio Number Range", fieldName: "dealPayment" },
                            // { value: "title", text: "Linked Draft (Submission) Title + AKAs", isCoreField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                        ]
                    },
                ]
            },
            {
                value: "Talent", text: "Talent", field: 'talent', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "talent_id, talent_group_id", text: "Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
                            { value: "performer_notes", text: "Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
                        ]
                    },
                    {
                        type: "All Criteria", filterCategories: [
                            { value: "company", text: "Company", isCoreField: true, type: "Text", fieldName: "talentCompany" },
                            { value: "deceased", text: "Deceased", type: "Boolean", fieldName: "talentDeceased", tableName: "none", optionName: "genericDetails" },
                            { value: "project_id", text: "Linked Projects", isCoreField: true, type: "Search", fieldName: "linkedProject", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "title", text: "Linked Submissions", isCoreField: true, type: "Search", fieldName: "linkedSubmission", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "wga_type", text: "Linked WGAs", isCoreField: true, type: "Search", fieldName: "linkedWGA", tableName: "SUBMISSION", optionName: "submissionDetails" },
                        ]
                    }
                ]
            },
        ]
    },
    {
        type: "WGAs", value: "WGAs", filters: [
            {
                value: "WGA", text: "WGA", field: 'wga', isExpand: true, filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "wga_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "wgaType", tableName: "lu_wga_type", optionName: "wgaTypeDetails" },
                            { value: "notice_date", text: "Notice Date", isCoreField: true, type: "Date", fieldName: "wgaNoticeDate" },
                            { value: "episode_title", text: "Title", isCoreField: true, type: "Text", fieldName: "wgaTitle" },
                            { value: "writer_name", text: "Participating Writers", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaWriters", tableName: "TALENT", optionName: "writerDetails" },
                            { value: "additional_writer_team_info", text: "Additional Writer Team Info", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaAdditionalWriters", tableName: "TALENT", optionName: "writerDetails" },
                            { value: "adjusted_representative_name", text: "Representation", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaRepresentation", tableName: "REPRESENTATIVE", optionName: "representationDetails" },
                            { value: "Writing Credits on this production are tentatively determined as follows", text: "Writing Credits on this production are tentatively determined as follows", isCoreField: true, type: "Text", fieldName: "wgaWritingCredits" },
                            { value: "on_screen_production_credit", text: "On screen and/or advertising, presentation and production credit, if any", type: "Text", isCoreField: true, fieldName: "wgaScreenAdvertisement" },
                        ]
                    },
                    {
                        type: "Details", filterCategories: [
                            //{ value: "adjusted_representative_name", text: "Representation", isMandatoryField: true, type: "Search", fieldName: "wgaRepresentation", tableName: "REPRESENTATIVE", optionName: "representationDetails" },
                            { value: "adjusted_representative_address", text: "Address", isMandatoryField: true, type: "Large Text", fieldName: "wgaAddress" },
                            { value: "executive_producer", text: "Executive Producer(s)", type: "Large Text", fieldName: "wgaExecProducers" },
                            { value: "producer", text: "Producer(s)", type: "Large Text", fieldName: "wgaProducers" },
                            { value: "co_producer", text: "Co-Producer(s)", type: "Large Text", fieldName: "wgaCoProducers" },
                            { value: "associate_producer", text: "Assoc. Producer(s)", type: "Large Text", fieldName: "wgaAssocProducers" },
                            { value: "director", text: "Director(s)", type: "Large Text", fieldName: "wgaDirectors" },
                            { value: "other_production_staff", text: "Other Production Executive(s), and their title(s), if participating writer(s)", type: "Large Text", fieldName: "wgaOthers" },
                            { value: "source_material", text: "Source Material upon which the motion picture is based, if any", type: "Large Text", fieldName: "wgaSourceMaterial" },
                            { value: "on_screen_source_credit", text: "On Screen Source Material Credit, if any", type: "Large Text", fieldName: "wgaScreenCredit" },
                        ]
                    },
                ]
            },
            {
                value: "Project", text: "Projects", field: 'projects', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "project_id", text: "Project Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "project_status_id", text: "Status", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "projectStatus", tableName: "lu_project_status", optionName: "projectStatusDetails" },
                            { value: "log_line", text: "Logline", isCoreField: true, type: "Large Text", fieldName: "projectLogline" },
                            { value: "genre_id", text: "Genre", isCoreField: true, type: "Multi Select", fieldName: "projectGenre", tableName: "lu_genre", optionName: "genreDetails" },
                            { value: "project_type_id", text: "Project Type", isCoreField: true, type: "Multi Select", fieldName: "projectType", tableName: "lu_project_type", optionName: "projectTypeDetails" },
                            { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", fieldName: "studioDivision", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                        ]
                    },
                    {
                        type: "Project Dates", filterCategories: [
                            { value: "prod_start_date", text: "Prod. Start Date", type: "Date", fieldName: "projectStartDate" },
                            { value: "prod_start_date_tbd", text: "Prod. Start Date TBD", type: "Boolean", fieldName: "projectStartDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "wrap_date", text: "Wrap Date", type: "Date", fieldName: "projectWrapDate" },
                            { value: "wrap_date_tbd", text: "Wrap Date TBD", type: "Boolean", fieldName: "projectWrapDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "release_date", text: "Release Date", type: "Date", fieldName: "projectReleaseDate" },
                            { value: "release_date_tb", text: "Release Date TBD", type: "Boolean", fieldName: "projectReleaseDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "rights_lapse_date", text: "Rights Lapse Date", type: "Date", fieldName: "projectRightsLapseDate" },
                        ]
                    },
                    {
                        type: "Project Details", filterCategories: [
                            { value: "project_notes", text: "Project Notes", type: "Large Text", fieldName: "projectNotes" },
                            { value: "distribution_company_id", text: "Distribution Company", type: "Multi Select", fieldName: "projectDistributionCompany", tableName: "lu_distribution_company", optionName: "distributionDetails" },
                            { value: "is_confidential", text: "Confidential", type: "Boolean", fieldName: "projectConfidential", tableName: "none", optionName: "genericDetails" },
                            { value: "material_location", text: "Material location", type: "Text", fieldName: "projectMaterialLocation" },
                            { value: "vault_information", text: "Vault Information", type: "Large Text", fieldName: "projectVaultLocation" },
                        ]
                    },
                    {
                        type: "Source Material", filterCategories: [
                            { value: "title", text: "Source Material Title", type: "Text", fieldName: "projectSourceTitle" },
                            { value: "material_type_id", text: "Source Material Type", type: "Select", fieldName: "projectSourceType", tableName: "lu_material_type", optionName: "materialTypeDetails" },
                            { value: "author", text: "Source Material Author", type: "Text", fieldName: "submissionMaterialAuthor" },
                            { value: "publisher", text: "Source Material Publisher", type: "Text", fieldName: "projectSourcePublisher" },
                            { value: "published_date", text: "Source Material Date", type: "Date", fieldName: "projectSourceDate" },
                            { value: "copyright_date", text: "Source Material Copyright Date", type: "Date", fieldName: "projectSourceCopyDate" }
                        ]
                    },
                    {
                        type: "Executives", filterCategories: [
                            { value: "executive_id", text: "Executives", configText: 'Name', type: "Search", fieldName: "projectExecutives", tableName: "USER", optionName: "executiveDetails" },
                            { value: "department", text: "Executive's Department", configText: "Department", type: "Select", fieldName: "projectExecDepartment", tableName: "lu_element_status", optionName: "departmentDetails" },
                            { value: "title", text: "Executive's Title", configText: "Title", type: "Text", fieldName: "projectExecTitle" },
                            { value: "start_date", text: "Executive's Project Date", configText: "From Date to Date", type: "Date", fieldName: "executiveFromDateToDate" },
                            { value: "executive_is_active", text: "Executive is Active", type: "Select", fieldName: "executiveIsActive" }
                        ]
                    },
                    // {
                    //     type: "Linked Talent, Deals & Drafts", filterCategories: [
                    //         { value: "talent_id, talent_group_id", text: "Talent Name + AKAs", isCoreField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                    //         { value: "title", text: "Linked Draft (Submission) Title + AKAs", isCoreField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                    //         { value: "document_name", text: "Document Name", isCoreField: true, type: "Text", fieldName: "dealDocumentName" },
                    //     ]
                    // },
                ]
            },
            {
                value: "Talent", text: "Talent", field: 'talent', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "talent_id, talent_group_id", text: "Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
                            { value: "performer_notes", text: "Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
                        ]
                    },
                    {
                        type: "All Criteria", filterCategories: [
                            { value: "company", text: "Company", isCoreField: true, type: "Text", fieldName: "talentCompany" },
                            { value: "deceased", text: "Deceased", type: "Boolean", fieldName: "talentDeceased", tableName: "none", optionName: "genericDetails" },
                            { value: "project_id", text: "Linked Projects", isCoreField: true, type: "Search", fieldName: "linkedProject", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "title", text: "Linked Submissions", isCoreField: true, type: "Search", fieldName: "linkedSubmission", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "wga_type", text: "Linked WGAs", isCoreField: true, type: "Search", fieldName: "linkedWGA", tableName: "SUBMISSION", optionName: "submissionDetails" },
                        ]
                    }
                ]
            },
            {
                value: "Representative", text: "Representative", field: 'representative', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "representative_id", text: "Representative Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeName", tableName: "REPRESENTATIVE", optionName: "representativeDetails" },
                            { value: "agency_id", text: "Company", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeCompany", tableName: "AGENCY", optionName: "agencyDetails" },
                            { value: "representative_type_id", text: "Representative Type", isCoreField: true, type: "Multi Select", fieldName: "representativeType", tableName: "lu_representative_type", optionName: "representativeTypeDetails" },
                            { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "representativeAddress", tableName: "lu_person_address_type", optionName: "addressTypeDetails" },
                        ]
                    }
                ]
            },
        ]
    },
    {
        type: "Deals", value: "Deals", filters: [
            {
                value: "Deal", text: "Deal", field: 'deals', isExpand: true, filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "document_name", text: "Document Name", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "dealDocumentName" },
                            { value: "project_id", text: "Project Title + AKAs", isCoreField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "writer_id,talent_group_id", text: "Writer(s)", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "dealWriter", tableName: "TALENT", optionName: "writersDetails" },
                            { value: "memo_date", text: "Memo Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "dealMemoDate" },
                            { value: "ammendment_date", text: "Amendment Date", isCoreField: true, type: "Date", fieldName: "dealAmendmentDate" },
                            { value: "business_executive_id", text: "Business Affairs Executive(s)", isCoreField: true, type: "Search", fieldName: "businessAffairsExecutive", tableName: "USER", optionName: "businessAffairExecutiveDetails" },
                            { value: "feature_executive_id", text: "Feature Legal Executive(s)", isCoreField: true, type: "Search", fieldName: "featureLegalExecutive", tableName: "USER", optionName: "featureLegalExecutiveDetails" },
                        ]
                    },
                    {
                        type: "Deal Details", filterCategories: [
                            { value: "assigned_material", text: "Assigned Material", type: "Text", fieldName: "dealAssignedMaterial" },
                            { value: "deal_notes", text: "Deal Notes", type: "Large Text", fieldName: "dealNotes" },
                            { value: "ammendment_notes", text: "Amendment Notes", type: "Large Text", fieldName: "dealAmendmentNotes" },
                        ]
                    },
                    {
                        type: "Deal Steps", filterCategories: [
                            { value: "deal_step_id", text: "Deal Step", type: 'Multi Select', fieldName: "dealStep", tableName: 'DEAL_STEPS', optionaName: 'dealStepDetails' },
                            { value: "deal_notes", text: "Deal Step Notes", type: "Large Text", fieldName: "dealStepNotes" },
                            { value: "writing_start", text: "Writing Start Date", type: "Date", fieldName: "dealWritingStartDate", range: true },
                            // { value: "writing_end", text: "Writing End Date", type: "Date", fieldName: "dealWritingEndDate", range: true },
                            { value: "delivery_date", text: "Delivery Date", type: "Date", fieldName: "dealDelivery" },
                            { value: "reading_end", text: "Reading End Date", type: "Date", fieldName: "dealReadingEnd" },
                            { value: "writing_due", text: "Writing Due Range", type: "Date", fieldName: "dealWritingDue", range: true },
                            { value: "currency_type_id", text: "Currency Type", type: "Multi Select", fieldName: "currencyType", tableName: "lu_currency_type", optionName: "currencyDetails" },
                            { value: "payment", text: "Payment", type: "Radio Number Range", fieldName: "dealPayment" },
                            // { value: "title", text: "Linked Draft (Submission) Title + AKAs", isCoreField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                        ]
                    },
                ]
            },
            {
                value: "Submissions", text: "Submissions", field: 'submissions', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                            { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                            { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                            { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                            { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                            { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                        ]
                    },
                    {
                        type: "Material Details", filterCategories: [
                            { value: "pgs", text: "Page Count/WB Page Count", type: "Radio Number Range", fieldName: "submissionPageCount" },
                            { value: "draft_version", text: "Draft Version", type: "Text", fieldName: "submissionDraftVersion" },
                            { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "deal_memo_step_id", text: "Deal Step", type: 'Search', fieldName: "dealStep", tableName: 'DEAL_STEPS', optionaName: 'dealStepDetails' },
                            { value: "is_term_deal_producer", text: "Term Deal Producer", type: "Boolean", fieldName: "submissionTermDeal", tableName: "none", optionName: "genericDetails" },
                            { value: "term_deal_company", text: "Term Deal Company", type: "Text", fieldName: "submissionTermDealCompany", tableName: "lu_project_status", optionName: "companyDetails" },
                            { value: "box", text: "Box #", type: "Text", fieldName: "submissionBox" },
                            { value: "sku", text: "SKU #", type: "Text", fieldName: "submissionSKU" },
                            { value: "submission_notes", text: "Submission Notes", type: "Large Text", fieldName: "submissionNotes" },
                        ]
                    },
                    {
                        type: "Source Material", filterCategories: [
                            { value: "title", text: "Title", type: "Text", fieldName: "submissionMaterialTitle" },
                            { value: "material_type_id", text: "Type", type: "Select", fieldName: "submissionMaterialType", tableName: "lu_material_type", optionName: "materialTypeDetails" },
                            { value: "author", text: "Author", type: "Text", fieldName: "submissionMaterialAuthor" },
                            { value: "publisher", text: "Publisher", type: "Text", fieldName: "submissionMaterialPublisher" },
                            { value: "source_material_date", text: "Publisher Date", type: "Date", fieldName: "submissionMaterialDate" },
                            { value: "copy_right_date", text: "Copyright Date", type: "Date", fieldName: "submissionCopyrightDate" },
                        ]
                    },
                    {
                        type: "Security", filterCategories: [
                            { value: "is_confidential", text: "Confidential", type: "Boolean", fieldName: "submissionConfidential", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_author", text: "Hide Author", type: "Boolean", fieldName: "submissionHideAuthor", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_title", text: "Hide Title", type: "Boolean", fieldName: "submissionHideTitle", tableName: "none", optionName: "genericDetails" },
                            { value: "is_un_official,is_interim", text: "Unofficial / Interim", type: "Boolean", fieldName: "submissionUnofficial", tableName: "submission", optionName: "submissionDetails" },
                        ]
                    },
                    {
                        type: "Elements", filterCategories: [
                            { value: "talent_id,talent_group_id", text: "Name", type: "Search", tableName: "TALENT", optionName: "submissionElementNameDetails", fieldName: "submissionElementName" },
                            { value: "role_id", text: "Role", type: "Select", fieldName: "submissionElementRole", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "element_status_id", text: "Status", type: "Select", fieldName: "submissionElementStatus", tableName: "lu_element_status", optionName: "elementDetails" }
                        ]
                    },
                    // {
                    //     type: "Project/Deal Link", filterCategories: [
                    //         { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    //         { value: "deal_step_id", text: "Deal Step", type: 'Multi Select', fieldName: "dealStep", tableName: 'DEAL_STEP', optionaName: 'dealStepDetails' },
                    //     ]
                    // }
                ]
            },
            {
                value: "Project", text: "Projects", field: 'projects', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "project_id", text: "Project Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "project_status_id", text: "Status", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "projectStatus", tableName: "lu_project_status", optionName: "projectStatusDetails" },
                            { value: "log_line", text: "Logline", isCoreField: true, type: "Large Text", fieldName: "projectLogline" },
                            { value: "genre_id", text: "Genre", isCoreField: true, type: "Multi Select", fieldName: "projectGenre", tableName: "lu_genre", optionName: "genreDetails" },
                            { value: "project_type_id", text: "Project Type", isCoreField: true, type: "Multi Select", fieldName: "projectType", tableName: "lu_project_type", optionName: "projectTypeDetails" },
                            { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", fieldName: "studioDivision", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                        ]
                    },
                    {
                        type: "Project Dates", filterCategories: [
                            { value: "prod_start_date", text: "Prod. Start Date", type: "Date", fieldName: "projectStartDate" },
                            { value: "prod_start_date_tbd", text: "Prod. Start Date TBD", type: "Boolean", fieldName: "projectStartDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "wrap_date", text: "Wrap Date", type: "Date", fieldName: "projectWrapDate" },
                            { value: "wrap_date_tbd", text: "Wrap Date TBD", type: "Boolean", fieldName: "projectWrapDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "release_date", text: "Release Date", type: "Date", fieldName: "projectReleaseDate" },
                            { value: "release_date_tb", text: "Release Date TBD", type: "Boolean", fieldName: "projectReleaseDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "rights_lapse_date", text: "Rights Lapse Date", type: "Date", fieldName: "projectRightsLapseDate" },
                        ]
                    },
                    {
                        type: "Project Details", filterCategories: [
                            { value: "project_notes", text: "Project Notes", type: "Large Text", fieldName: "projectNotes" },
                            { value: "distribution_company_id", text: "Distribution Company", type: "Multi Select", fieldName: "projectDistributionCompany", tableName: "lu_distribution_company", optionName: "distributionDetails" },
                            { value: "is_confidential", text: "Confidential", type: "Boolean", fieldName: "projectConfidential", tableName: "none", optionName: "genericDetails" },
                            { value: "material_location", text: "Material location", type: "Text", fieldName: "projectMaterialLocation" },
                            { value: "vault_information", text: "Vault Information", type: "Large Text", fieldName: "projectVaultLocation" },
                        ]
                    },
                    {
                        type: "Source Material", filterCategories: [
                            { value: "title", text: "Source Material Title", type: "Text", fieldName: "projectSourceTitle" },
                            { value: "material_type_id", text: "Source Material Type", type: "Select", fieldName: "projectSourceType", tableName: "lu_material_type", optionName: "materialTypeDetails" },
                            { value: "author", text: "Source Material Author", type: "Text", fieldName: "submissionMaterialAuthor" },
                            { value: "publisher", text: "Source Material Publisher", type: "Text", fieldName: "projectSourcePublisher" },
                            { value: "published_date", text: "Source Material Date", type: "Date", fieldName: "projectSourceDate" },
                            { value: "copyright_date", text: "Source Material Copyright Date", type: "Date", fieldName: "projectSourceCopyDate" }
                        ]
                    },
                    {
                        type: "Executives", filterCategories: [
                            { value: "executive_id", text: "Executives", configText: 'Name', type: "Search", fieldName: "projectExecutives", tableName: "USER", optionName: "executiveDetails" },
                            { value: "department", text: "Executive's Department", configText: "Department", type: "Select", fieldName: "projectExecDepartment", tableName: "lu_element_status", optionName: "departmentDetails" },
                            { value: "title", text: "Executive's Title", configText: "Title", type: "Text", fieldName: "projectExecTitle" },
                            { value: "start_date", text: "Executive's Project Date", configText: "From Date to Date", type: "Date", fieldName: "executiveFromDateToDate" },
                            { value: "executive_is_active", text: "Executive is Active", type: "Select", fieldName: "executiveIsActive" }
                        ]
                    },
                    // {
                    //     type: "Linked Talent, Deals & Drafts", filterCategories: [
                    //         { value: "talent_id, talent_group_id", text: "Talent Name + AKAs", isCoreField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                    //         { value: "title", text: "Linked Draft (Submission) Title + AKAs", isCoreField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                    //         { value: "document_name", text: "Document Name", isCoreField: true, type: "Text", fieldName: "dealDocumentName" },
                    //     ]
                    // },
                ]
            },
            {
                value: "Talent", text: "Talent", field: 'talent', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "talent_id, talent_group_id", text: "Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
                            { value: "performer_notes", text: "Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
                        ]
                    },
                    {
                        type: "All Criteria", filterCategories: [
                            { value: "company", text: "Company", isCoreField: true, type: "Text", fieldName: "talentCompany" },
                            { value: "deceased", text: "Deceased", type: "Boolean", fieldName: "talentDeceased", tableName: "none", optionName: "genericDetails" },
                            { value: "project_id", text: "Linked Projects", isCoreField: true, type: "Search", fieldName: "linkedProject", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "title", text: "Linked Submissions", isCoreField: true, type: "Search", fieldName: "linkedSubmission", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "wga_type", text: "Linked WGAs", isCoreField: true, type: "Search", fieldName: "linkedWGA", tableName: "SUBMISSION", optionName: "submissionDetails" },
                        ]
                    }
                ]
            },
        ]
    },
    {
        type: "Weekend Read", value: "Weekend_Reads", filters: [
            {
                value: "WeekendRead", text: "Weekend Read", field: 'weekend_reads', isExpand: true, filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "title", text: "Title", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "weekendReadTitle", tableName: "", optionName: "" },
                            { value: "weekend_read_date", text: "WER Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "weekendReadDate" },
                            { value: "advocate_id", text: "Per/Advocate", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "weekendReadAdvocate", tableName: "USER", optionName: "advocateDetails" },
                            { value: "assigned_to_id", text: "Assigned To / Also Reading", isMandatoryField: true, isCoreField: true, type: "Search", fieldName: "weekendReadAssignedTo", tableName: "USER", optionName: "assignedToDetails" },
                        ]
                    },
                    {
                        type: "Notes + Remarks", filterCategories: [
                            { value: "internal_office_notes", text: "Internal Office Notes", type: "Large Text", fieldName: "weekendReadInternalOfficeNotes" },
                            { value: "memo_remarks", text: "Memo Remarks/Question", type: "Large Text", fieldName: "weekendReadMemoRemarks" },
                            { value: "notes", text: "Agenda Meeting Notes", type: "Large Text", fieldName: "weekendReadAgendaMeetingNotes" }
                        ]
                    },
                    {
                        type: "Details", filterCategories: [
                            { value: "already_distributed", text: "Already Distributed", type: "Boolean", fieldName: "alreadyDistributed", tableName: "none", optionName: "genericDetails" },
                            { value: "coverage", text: "Coverage", type: "Boolean", fieldName: "coverage", tableName: "none", optionName: "genericDetails" },
                            { value: "coverage_tbd", text: "Coverage TBD", type: "Boolean", fieldName: "coverageTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "coverage_only", text: "Coverage Only", type: "Boolean", fieldName: "coverageOnly", tableName: "none", optionName: "genericDetails" },
                            { value: "development", text: "Deevelopment", type: "Boolean", fieldName: "development", tableName: "none", optionName: "genericDetails" },
                            { value: "discussion", text: "Discussion", type: "Boolean", fieldName: "discussion", tableName: "none", optionName: "genericDetails" },
                            { value: "list_attached", text: "List Attached", type: "Boolean", fieldName: "listAttached", tableName: "none", optionName: "genericDetails" },
                            { value: "memo_attached", text: "Memo Attached", type: "Boolean", fieldName: "memoAttached", tableName: "none", optionName: "genericDetails" },
                            { value: "no_material", text: "No Material", type: "Boolean", fieldName: "no_material", tableName: "none", optionName: "genericDetails" },
                            { value: "old_or_new_cov_attached", text: "Old Cov. Attached/New Cov. TBD", type: "Boolean", fieldName: "oldCovAttached", tableName: "none", optionName: "genericDetails" },
                            { value: "packaging", text: "Packaging", type: "Boolean", fieldName: "packaging", tableName: "none", optionName: "genericDetails" },
                            { value: "priority_development", text: "Priority Development", type: "Boolean", fieldName: "priorityDevelopment", tableName: "none", optionName: "genericDetails" },
                            { value: "production", text: "Production", type: "Boolean", fieldName: "production", tableName: "none", optionName: "genericDetails" },
                            { value: "security_disclaimer", text: "Security Disclaimer", type: "Boolean", fieldName: "securityDisclaimer", tableName: "none", optionName: "genericDetails" },
                            { value: "separate_page", text: "Separate Page", type: "Boolean", fieldName: "separate_page", tableName: "none", optionName: "genericDetails" },
                            { value: "produced_submitted_by", text: "Produced/Submitted By", isMandatoryField: true, type: "Boolean", fieldName: "producedBy", tableName: "none", optionName: "genericDetails" },
                            { value: "synopsis", text: "Synopsis", type: "Boolean", fieldName: "synopsis", tableName: "none", optionName: "genericDetails" },
                            { value: "talent", text: "Talent", type: "Boolean", fieldName: "talent", tableName: "none", optionName: "genericDetails" },
                        ]
                    },
                    {
                        type: "Attached Material", filterCategories: [
                            { value: "exec_read_id", text: "Execs Who've Read", type: "Search", fieldName: "weekendReadExecWhoRead", tableName: "USER", optionName: "execDetails" },
                            { value: "attached_coverage_id", text: "Attached Coverages", type: "Search", fieldName: "weekendReadAttachedCoverages", tableName: "SUBMISSION", optionName: "coverageDetails" },
                            { value: "additional_material", text: "Additional Material", type: "Text", fieldName: "weekendReadAdditionalMaterial" },
                        ]
                    }
                ]
            },
            {
                value: "Submissions", text: "Submissions", field: 'submissions', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                            { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                            { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                            { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                            { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                            { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                        ]
                    },
                    {
                        type: "Material Details", filterCategories: [
                            { value: "pgs", text: "Page Count/WB Page Count", type: "Radio Number Range", fieldName: "submissionPageCount" },
                            { value: "draft_version", text: "Draft Version", type: "Text", fieldName: "submissionDraftVersion" },
                            { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "deal_memo_step_id", text: "Deal Step", type: 'Search', fieldName: "dealStep", tableName: 'DEAL_STEPS', optionaName: 'dealStepDetails' },
                            { value: "is_term_deal_producer", text: "Term Deal Producer", type: "Boolean", fieldName: "submissionTermDeal", tableName: "none", optionName: "genericDetails" },
                            { value: "term_deal_company", text: "Term Deal Company", type: "Text", fieldName: "submissionTermDealCompany", tableName: "lu_project_status", optionName: "companyDetails" },
                            { value: "box", text: "Box #", type: "Text", fieldName: "submissionBox" },
                            { value: "sku", text: "SKU #", type: "Text", fieldName: "submissionSKU" },
                            { value: "submission_notes", text: "Submission Notes", type: "Large Text", fieldName: "submissionNotes" },
                        ]
                    },
                    {
                        type: "Source Material", filterCategories: [
                            { value: "title", text: "Title", type: "Text", fieldName: "submissionMaterialTitle" },
                            { value: "material_type_id", text: "Type", type: "Select", fieldName: "submissionMaterialType", tableName: "lu_material_type", optionName: "materialTypeDetails" },
                            { value: "author", text: "Author", type: "Text", fieldName: "submissionMaterialAuthor" },
                            { value: "publisher", text: "Publisher", type: "Text", fieldName: "submissionMaterialPublisher" },
                            { value: "source_material_date", text: "Publisher Date", type: "Date", fieldName: "submissionMaterialDate" },
                            { value: "copy_right_date", text: "Copyright Date", type: "Date", fieldName: "submissionCopyrightDate" },
                        ]
                    },
                    {
                        type: "Security", filterCategories: [
                            { value: "is_confidential", text: "Confidential", type: "Boolean", fieldName: "submissionConfidential", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_author", text: "Hide Author", type: "Boolean", fieldName: "submissionHideAuthor", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_title", text: "Hide Title", type: "Boolean", fieldName: "submissionHideTitle", tableName: "none", optionName: "genericDetails" },
                            { value: "is_un_official,is_interim", text: "Unofficial / Interim", type: "Boolean", fieldName: "submissionUnofficial", tableName: "submission", optionName: "submissionDetails" },
                        ]
                    },
                    {
                        type: "Elements", filterCategories: [
                            { value: "talent_id,talent_group_id", text: "Name", type: "Search", tableName: "TALENT", optionName: "submissionElementNameDetails", fieldName: "submissionElementName" },
                            { value: "role_id", text: "Role", type: "Select", fieldName: "submissionElementRole", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "element_status_id", text: "Status", type: "Select", fieldName: "submissionElementStatus", tableName: "lu_element_status", optionName: "elementDetails" }
                        ]
                    },
                    // {
                    //     type: "Project/Deal Link", filterCategories: [
                    //         { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    //         { value: "deal_step_id", text: "Deal Step", type: 'Multi Select', fieldName: "dealStep", tableName: 'DEAL_STEP', optionaName: 'dealStepDetails' },
                    //     ]
                    // }
                ]
            },
        ]
    },
    {
        type: "Talent", value: "Talent", filters: [
            {
                value: "Talent", text: "Talent", field: 'talent', isExpand: true, filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "talent_id, talent_group_id", text: "Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
                            { value: "performer_notes", text: "Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
                        ]
                    },
                    {
                        type: "All Criteria", filterCategories: [
                            { value: "company", text: "Company", isCoreField: true, type: "Text", fieldName: "talentCompany" },
                            { value: "deceased", text: "Deceased", type: "Boolean", fieldName: "talentDeceased", tableName: "none", optionName: "genericDetails" },
                            { value: "project_id", text: "Linked Projects", isCoreField: true, type: "Search", fieldName: "linkedProject", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "title", text: "Linked Submissions", isCoreField: true, type: "Search", fieldName: "linkedSubmission", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "wga_type", text: "Linked WGAs", isCoreField: true, type: "Search", fieldName: "linkedWGA", tableName: "SUBMISSION", optionName: "submissionDetails" },
                        ]
                    }
                ]
            },
            {
                value: "Representatives", text: "Representative", field: 'representative', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "representative_id", text: "Representative Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeName", tableName: "REPRESENTATIVE", optionName: "repDetails" },
                            { value: "loanout_company", text: "Company", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeCompany", tableName: "AGENCY", optionName: "agencyDetails" },
                            { value: "representative_type_id", text: "Representative Type", isCoreField: true, type: "Multi Select", fieldName: "representativeType", tableName: "lu_representative_type", optionName: "representativeTypeDetails" },
                            { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "representativeAddress", tableName: "lu_person_address_type", optionName: "addressTypeDetails" },
                        ]
                    }
                ]
            },
            {
                value: "Agency", text: "Company", field: 'agency', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "agency_id", text: "Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "agencyName", tableName: "AGENCY", optionName: "agencyDetails" },
                            { value: "agency_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "agencyType", tableName: "lu_agency_types", optionName: "agencyTypeDetails" },
                            { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "agencyAddress" },
                            { value: "agency_notes", text: "Company Notes", isCoreField: true, type: "Large Text", fieldName: "agencyNotes" },
                        ]
                    }
                ]
            },
            {
                value: "Submissions", text: "Submissions", field: 'submissions', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                            { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                            { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                            { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                            { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                            { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                        ]
                    },
                    {
                        type: "Material Details", filterCategories: [
                            { value: "pgs", text: "Page Count/WB Page Count", type: "Radio Number Range", fieldName: "submissionPageCount" },
                            { value: "draft_version", text: "Draft Version", type: "Text", fieldName: "submissionDraftVersion" },
                            { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "deal_memo_step_id", text: "Deal Step", type: 'Search', fieldName: "dealStep", tableName: 'DEAL_STEPS', optionaName: 'dealStepDetails' },
                            { value: "is_term_deal_producer", text: "Term Deal Producer", type: "Boolean", fieldName: "submissionTermDeal", tableName: "none", optionName: "genericDetails" },
                            { value: "term_deal_company", text: "Term Deal Company", type: "Text", fieldName: "submissionTermDealCompany", tableName: "lu_project_status", optionName: "companyDetails" },
                            { value: "box", text: "Box #", type: "Text", fieldName: "submissionBox" },
                            { value: "sku", text: "SKU #", type: "Text", fieldName: "submissionSKU" },
                            { value: "submission_notes", text: "Submission Notes", type: "Large Text", fieldName: "submissionNotes" },
                        ]
                    },
                    {
                        type: "Source Material", filterCategories: [
                            { value: "title", text: "Title", type: "Text", fieldName: "submissionMaterialTitle" },
                            { value: "material_type_id", text: "Type", type: "Select", fieldName: "submissionMaterialType", tableName: "lu_material_type", optionName: "materialTypeDetails" },
                            { value: "author", text: "Author", type: "Text", fieldName: "submissionMaterialAuthor" },
                            { value: "publisher", text: "Publisher", type: "Text", fieldName: "submissionMaterialPublisher" },
                            { value: "source_material_date", text: "Publisher Date", type: "Date", fieldName: "submissionMaterialDate" },
                            { value: "copy_right_date", text: "Copyright Date", type: "Date", fieldName: "submissionCopyrightDate" },
                        ]
                    },
                    {
                        type: "Security", filterCategories: [
                            { value: "is_confidential", text: "Confidential", type: "Boolean", fieldName: "submissionConfidential", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_author", text: "Hide Author", type: "Boolean", fieldName: "submissionHideAuthor", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_title", text: "Hide Title", type: "Boolean", fieldName: "submissionHideTitle", tableName: "none", optionName: "genericDetails" },
                            { value: "is_un_official,is_interim", text: "Unofficial / Interim", type: "Boolean", fieldName: "submissionUnofficial", tableName: "submission", optionName: "submissionDetails" },
                        ]
                    },
                    {
                        type: "Elements", filterCategories: [
                            { value: "talent_id,talent_group_id", text: "Name", type: "Search", tableName: "TALENT", optionName: "submissionElementNameDetails", fieldName: "submissionElementName" },
                            { value: "role_id", text: "Role", type: "Select", fieldName: "submissionElementRole", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "element_status_id", text: "Status", type: "Select", fieldName: "submissionElementStatus", tableName: "lu_element_status", optionName: "elementDetails" }
                        ]
                    },
                    // {
                    //     type: "Project/Deal Link", filterCategories: [
                    //         { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    //         { value: "deal_step_id", text: "Deal Step", type: 'Multi Select', fieldName: "dealStep", tableName: 'DEAL_STEP', optionaName: 'dealStepDetails' },
                    //     ]
                    // }
                ]
            },
            {
                value: "Project", text: "Projects", field: 'projects', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "project_id", text: "Project Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "project_status_id", text: "Status", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "projectStatus", tableName: "lu_project_status", optionName: "projectStatusDetails" },
                            { value: "log_line", text: "Logline", isCoreField: true, type: "Large Text", fieldName: "projectLogline" },
                            { value: "genre_id", text: "Genre", isCoreField: true, type: "Multi Select", fieldName: "projectGenre", tableName: "lu_genre", optionName: "genreDetails" },
                            { value: "project_type_id", text: "Project Type", isCoreField: true, type: "Multi Select", fieldName: "projectType", tableName: "lu_project_type", optionName: "projectTypeDetails" },
                            { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", fieldName: "studioDivision", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                        ]
                    },
                    {
                        type: "Project Dates", filterCategories: [
                            { value: "prod_start_date", text: "Prod. Start Date", type: "Date", fieldName: "projectStartDate" },
                            { value: "prod_start_date_tbd", text: "Prod. Start Date TBD", type: "Boolean", fieldName: "projectStartDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "wrap_date", text: "Wrap Date", type: "Date", fieldName: "projectWrapDate" },
                            { value: "wrap_date_tbd", text: "Wrap Date TBD", type: "Boolean", fieldName: "projectWrapDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "release_date", text: "Release Date", type: "Date", fieldName: "projectReleaseDate" },
                            { value: "release_date_tb", text: "Release Date TBD", type: "Boolean", fieldName: "projectReleaseDateTBD", tableName: "none", optionName: "genericDetails" },
                            { value: "rights_lapse_date", text: "Rights Lapse Date", type: "Date", fieldName: "projectRightsLapseDate" },
                        ]
                    },
                    {
                        type: "Project Details", filterCategories: [
                            { value: "project_notes", text: "Project Notes", type: "Large Text", fieldName: "projectNotes" },
                            { value: "distribution_company_id", text: "Distribution Company", type: "Multi Select", fieldName: "projectDistributionCompany", tableName: "lu_distribution_company", optionName: "distributionDetails" },
                            { value: "is_confidential", text: "Confidential", type: "Boolean", fieldName: "projectConfidential", tableName: "none", optionName: "genericDetails" },
                            { value: "material_location", text: "Material location", type: "Text", fieldName: "projectMaterialLocation" },
                            { value: "vault_information", text: "Vault Information", type: "Large Text", fieldName: "projectVaultLocation" },
                        ]
                    },
                    {
                        type: "Source Material", filterCategories: [
                            { value: "title", text: "Source Material Title", type: "Text", fieldName: "projectSourceTitle" },
                            { value: "material_type_id", text: "Source Material Type", type: "Select", fieldName: "projectSourceType", tableName: "lu_material_type", optionName: "materialTypeDetails" },
                            { value: "author", text: "Source Material Author", type: "Text", fieldName: "submissionMaterialAuthor" },
                            { value: "publisher", text: "Source Material Publisher", type: "Text", fieldName: "projectSourcePublisher" },
                            { value: "published_date", text: "Source Material Date", type: "Date", fieldName: "projectSourceDate" },
                            { value: "copyright_date", text: "Source Material Copyright Date", type: "Date", fieldName: "projectSourceCopyDate" }
                        ]
                    },
                    {
                        type: "Executives", filterCategories: [
                            { value: "executive_id", text: "Executives", configText: 'Name', type: "Search", fieldName: "projectExecutives", tableName: "USER", optionName: "executiveDetails" },
                            { value: "department", text: "Executive's Department", configText: "Department", type: "Select", fieldName: "projectExecDepartment", tableName: "lu_element_status", optionName: "departmentDetails" },
                            { value: "title", text: "Executive's Title", configText: "Title", type: "Text", fieldName: "projectExecTitle" },
                            { value: "start_date", text: "Executive's Project Date", configText: "From Date to Date", type: "Date", fieldName: "executiveFromDateToDate" },
                            { value: "executive_is_active", text: "Executive is Active", type: "Select", fieldName: "executiveIsActive" }
                        ]
                    },
                    // {
                    //     type: "Linked Talent, Deals & Drafts", filterCategories: [
                    //         { value: "talent_id, talent_group_id", text: "Talent Name + AKAs", isCoreField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                    //         { value: "title", text: "Linked Draft (Submission) Title + AKAs", isCoreField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                    //         { value: "document_name", text: "Document Name", isCoreField: true, type: "Text", fieldName: "dealDocumentName" },
                    //     ]
                    // },
                ]
            },
            {
                value: "WGA", text: "WGA", field: 'wga', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "wga_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "wgaType", tableName: "lu_wga_type", optionName: "wgaTypeDetails" },
                            { value: "notice_date", text: "Notice Date", isCoreField: true, type: "Date", fieldName: "wgaNoticeDate" },
                            { value: "episode_title", text: "Title", isCoreField: true, type: "Text", fieldName: "wgaTitle" },
                            { value: "writer_name", text: "Participating Writers", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaWriters", tableName: "TALENT", optionName: "writerDetails" },
                            { value: "additional_writer_team_info", text: "Additional Writer Team Info", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaAdditionalWriters", tableName: "TALENT", optionName: "writerDetails" },
                            { value: "adjusted_representative_name", text: "Representation",isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaRepresentation", tableName: "REPRESENTATIVE", optionName: "writerDetails" },
                            { value: "Writing Credits on this production are tentatively determined as follows", text: "Writing Credits on this production are tentatively determined as follows", isCoreField: true, type: "Text", fieldName: "wgaWritingCredits" },
                            { value: "on_screen_production_credit", text: "On screen and/or advertising, presentation and production credit, if any", type: "Text", isCoreField: true, fieldName: "wgaScreenAdvertisement" },
                        ]
                    },
                    {
                        type: "Details", filterCategories: [
                           // { value: "adjusted_representative_name", text: "Representation", isMandatoryField: true, type: "Text", fieldName: "wgaRepresentation", tableName: "REPRESENTATIVE", optionName: "representationDetails" },
                            { value: "adjusted_representative_address", text: "Address", isMandatoryField: true, type: "Large Text", fieldName: "wgaAddress" },
                            { value: "executive_producer", text: "Executive Producer(s)", type: "Large Text", fieldName: "wgaExecProducers" },
                            { value: "producer", text: "Producer(s)", type: "Large Text", fieldName: "wgaProducers" },
                            { value: "co_producer", text: "Co-Producer(s)", type: "Large Text", fieldName: "wgaCoProducers" },
                            { value: "associate_producer", text: "Assoc. Producer(s)", type: "Large Text", fieldName: "wgaAssocProducers" },
                            { value: "director", text: "Director(s)", type: "Large Text", fieldName: "wgaDirectors" },
                            { value: "other_production_staff", text: "Other Production Executive(s), and their title(s), if participating writer(s)", type: "Large Text", fieldName: "wgaOthers" },
                            { value: "source_material", text: "Source Material upon which the motion picture is based, if any", type: "Large Text", fieldName: "wgaSourceMaterial" },
                            { value: "on_screen_source_credit", text: "On Screen Source Material Credit, if any", type: "Large Text", fieldName: "wgaScreenCredit" },
                        ]
                    },
                ]
            },
            {
                value: "Deal", text: "Deal", field: 'deals', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "document_name", text: "Document Name", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "dealDocumentName" },
                            { value: "project_id", text: "Project Title + AKAs", isCoreField: true, type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "writer_id,talent_group_id", text: "Writer(s)", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "dealWriter", tableName: "TALENT", optionName: "writersDetails" },
                            { value: "memo_date", text: "Memo Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "dealMemoDate" },
                            { value: "ammendment_date", text: "Amendment Date", isCoreField: true, type: "Date", fieldName: "dealAmendmentDate" },
                            { value: "business_executive_id", text: "Business Affairs Executive(s)", isCoreField: true, type: "Search", fieldName: "businessAffairsExecutive", tableName: "USER", optionName: "businessAffairExecutiveDetails" },
                            { value: "feature_executive_id", text: "Feature Legal Executive(s)", isCoreField: true, type: "Search", fieldName: "featureLegalExecutive", tableName: "USER", optionName: "featureLegalExecutiveDetails" },
                        ]
                    },
                    {
                        type: "Deal Details", filterCategories: [
                            { value: "assigned_material", text: "Assigned Material", type: "Text", fieldName: "dealAssignedMaterial" },
                            { value: "deal_notes", text: "Deal Notes", type: "Large Text", fieldName: "dealNotes" },
                            { value: "ammendment_notes", text: "Amendment Notes", type: "Large Text", fieldName: "dealAmendmentNotes" },
                        ]
                    },
                    {
                        type: "Deal Steps", filterCategories: [
                            { value: "deal_step_id", text: "Deal Step", type: 'Multi Select', fieldName: "dealStep", tableName: 'DEAL_STEPS', optionaName: 'dealStepDetails' },
                            { value: "deal_notes", text: "Deal Step Notes", type: "Large Text", fieldName: "dealStepNotes" },
                            { value: "writing_start", text: "Writing Start Date", type: "Date", fieldName: "dealWritingStartDate", range: true },
                            // { value: "writing_end", text: "Writing End Date", type: "Date", fieldName: "dealWritingEndDate", range: true },
                            { value: "delivery_date", text: "Delivery Date", type: "Date", fieldName: "dealDelivery" },
                            { value: "reading_end", text: "Reading End Date", type: "Date", fieldName: "dealReadingEnd" },
                            { value: "writing_due", text: "Writing Due Range", type: "Date", fieldName: "dealWritingDue", range: true },
                            { value: "currency_type_id", text: "Currency Type", type: "Multi Select", fieldName: "currencyType", tableName: "lu_currency_type", optionName: "currencyDetails" },
                            { value: "payment", text: "Payment", type: "Radio Number Range", fieldName: "dealPayment" },
                            // { value: "title", text: "Linked Draft (Submission) Title + AKAs", isCoreField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                        ]
                    },
                ]
            },
        ]
    },
    {
        type: "Representatives", value: "Representatives", filters: [
            {
                value: "Representatives", text: "Representative", field: 'representative', isExpand: true, filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "representative_id", text: "Representative Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeName", tableName: "REPRESENTATIVE", optionName: "repDetails" },
                            { value: "agency_id", text: "Company", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeCompany", tableName: "AGENCY", optionName: "agencyDetails" },
                            { value: "representative_type_id", text: "Representative Type", isCoreField: true, type: "Multi Select", fieldName: "representativeType", tableName: "lu_representative_type", optionName: "representativeTypeDetails" },
                            { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "representativeAddress", tableName: "lu_person_address_type", optionName: "addressTypeDetails" },
                        ]
                    }
                ]
            },
            {
                value: "Agency", text: "Company", field: 'agency', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "agency_id", text: "Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "agencyName", tableName: "AGENCY", optionName: "agencyDetails" },
                            { value: "agency_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "agencyType", tableName: "lu_agency_types", optionName: "agencyTypeDetails" },
                            { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "agencyAddress" },
                            { value: "agency_notes", text: "Company Notes", isCoreField: true, type: "Large Text", fieldName: "agencyNotes" },
                        ]
                    }
                ]
            },
            {
                value: "Talent", text: "Talent", field: 'talent', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "talent_id, talent_group_id", text: "Talent Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
                            { value: "performer_notes", text: "Performer Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
                        ]
                    },
                    {
                        type: "All Criteria", filterCategories: [
                            { value: "company", text: "Company", isCoreField: true, type: "Text", fieldName: "talentCompany" },
                            { value: "deceased", text: "Deceased", type: "Boolean", fieldName: "talentDeceased", tableName: "none", optionName: "genericDetails" },
                            { value: "project_id", text: "Linked Projects", isCoreField: true, type: "Search", fieldName: "linkedProject", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "title", text: "Linked Submissions", isCoreField: true, type: "Search", fieldName: "linkedSubmission", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "wga_type", text: "Linked WGAs", isCoreField: true, type: "Search", fieldName: "linkedWGA", tableName: "SUBMISSION", optionName: "submissionDetails" },
                        ]
                    }
                ]
            },
            {
                value: "Submissions", text: "Submissions", field: 'submissions', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                            { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                            { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                            { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                            { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                            { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                        ]
                    },
                    {
                        type: "Material Details", filterCategories: [
                            { value: "pgs", text: "Page Count/WB Page Count", type: "Radio Number Range", fieldName: "submissionPageCount" },
                            { value: "draft_version", text: "Draft Version", type: "Text", fieldName: "submissionDraftVersion" },
                            { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "deal_memo_step_id", text: "Deal Step", type: 'Search', fieldName: "dealStep", tableName: 'DEAL_STEPS', optionaName: 'dealStepDetails' },
                            { value: "is_term_deal_producer", text: "Term Deal Producer", type: "Boolean", fieldName: "submissionTermDeal", tableName: "none", optionName: "genericDetails" },
                            { value: "term_deal_company", text: "Term Deal Company", type: "Text", fieldName: "submissionTermDealCompany", tableName: "lu_project_status", optionName: "companyDetails" },
                            { value: "box", text: "Box #", type: "Text", fieldName: "submissionBox" },
                            { value: "sku", text: "SKU #", type: "Text", fieldName: "submissionSKU" },
                            { value: "submission_notes", text: "Submission Notes", type: "Large Text", fieldName: "submissionNotes" },
                        ]
                    },
                    {
                        type: "Source Material", filterCategories: [
                            { value: "title", text: "Title", type: "Text", fieldName: "submissionMaterialTitle" },
                            { value: "material_type_id", text: "Type", type: "Select", fieldName: "submissionMaterialType", tableName: "lu_material_type", optionName: "materialTypeDetails" },
                            { value: "author", text: "Author", type: "Text", fieldName: "submissionMaterialAuthor" },
                            { value: "publisher", text: "Publisher", type: "Text", fieldName: "submissionMaterialPublisher" },
                            { value: "source_material_date", text: "Publisher Date", type: "Date", fieldName: "submissionMaterialDate" },
                            { value: "copy_right_date", text: "Copyright Date", type: "Date", fieldName: "submissionCopyrightDate" },
                        ]
                    },
                    {
                        type: "Security", filterCategories: [
                            { value: "is_confidential", text: "Confidential", type: "Boolean", fieldName: "submissionConfidential", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_author", text: "Hide Author", type: "Boolean", fieldName: "submissionHideAuthor", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_title", text: "Hide Title", type: "Boolean", fieldName: "submissionHideTitle", tableName: "none", optionName: "genericDetails" },
                            { value: "is_un_official,is_interim", text: "Unofficial / Interim", type: "Boolean", fieldName: "submissionUnofficial", tableName: "submission", optionName: "submissionDetails" },
                        ]
                    },
                    {
                        type: "Elements", filterCategories: [
                            { value: "talent_id,talent_group_id", text: "Name", type: "Search", tableName: "TALENT", optionName: "submissionElementNameDetails", fieldName: "submissionElementName" },
                            { value: "role_id", text: "Role", type: "Select", fieldName: "submissionElementRole", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "element_status_id", text: "Status", type: "Select", fieldName: "submissionElementStatus", tableName: "lu_element_status", optionName: "elementDetails" }
                        ]
                    },
                    // {
                    //     type: "Project/Deal Link", filterCategories: [
                    //         { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    //         { value: "deal_step_id", text: "Deal Step", type: 'Multi Select', fieldName: "dealStep", tableName: 'DEAL_STEP', optionaName: 'dealStepDetails' },
                    //     ]
                    // }
                ]
            },
            {
                value: "WGA", text: "WGA", field: 'wga', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "wga_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "wgaType", tableName: "lu_wga_type", optionName: "wgaTypeDetails" },
                            { value: "notice_date", text: "Notice Date", isCoreField: true, type: "Date", fieldName: "wgaNoticeDate" },
                            { value: "episode_title", text: "Title", isCoreField: true, type: "Text", fieldName: "wgaTitle" },
                            { value: "writer_name", text:"Participating Writers", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaWriters", tableName: "TALENT", optionName: "writerDetails" },
                            { value: "additional_writer_team_info", text: "Additional Writer Team Info", isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaAdditionalWriters", tableName: "TALENT", optionName: "writerDetails" },
                            { value: "adjusted_representative_name", text: "Representation",isCoreField: true, isMandatoryField: true, type: "Text", fieldName: "wgaRepresentation", tableName: "REPRESENTATIVE", optionName: "writerDetails" },
                            { value: "Writing Credits on this production are tentatively determined as follows", text: "Writing Credits on this production are tentatively determined as follows", isCoreField: true, type: "Text", fieldName: "wgaWritingCredits" },
                            { value: "on_screen_production_credit", text: "On screen and/or advertising, presentation and production credit, if any", type: "Text", isCoreField: true, fieldName: "wgaScreenAdvertisement" },
                        ]
                    },
                    {
                        type: "Details", filterCategories: [
                            //{ value: "adjusted_representative_name", text: "Representation", isMandatoryField: true, type: "Text", fieldName: "wgaRepresentation", tableName: "REPRESENTATIVE", optionName: "representationDetails" },
                            { value: "adjusted_representative_address", text: "Address", isMandatoryField: true, type: "Large Text", fieldName: "wgaAddress" },
                            { value: "executive_producer", text: "Executive Producer(s)", type: "Large Text", fieldName: "wgaExecProducers" },
                            { value: "producer", text: "Producer(s)", type: "Large Text", fieldName: "wgaProducers" },
                            { value: "co_producer", text: "Co-Producer(s)", type: "Large Text", fieldName: "wgaCoProducers" },
                            { value: "associate_producer", text: "Assoc. Producer(s)", type: "Large Text", fieldName: "wgaAssocProducers" },
                            { value: "director", text: "Director(s)", type: "Large Text", fieldName: "wgaDirectors" },
                            { value: "other_production_staff", text: "Other Production Executive(s), and their title(s), if participating writer(s)", type: "Large Text", fieldName: "wgaOthers" },
                            { value: "source_material", text: "Source Material upon which the motion picture is based, if any", type: "Large Text", fieldName: "wgaSourceMaterial" },
                            { value: "on_screen_source_credit", text: "On Screen Source Material Credit, if any", type: "Large Text", fieldName: "wgaScreenCredit" },
                        ]
                    },
                ]
            },
        ]
    },
    {
        type: "Companies", value: "Companies", filters: [
            {
                value: "Agency", text: "Company", field: 'agency', isExpand: true, filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "agency_id", text: "Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "agencyName", tableName: "AGENCY", optionName: "agencyDetails" },
                            { value: "agency_type_id", text: "Type", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "agencyType", tableName: "lu_agency_types", optionName: "agencyTypeDetails" },
                            { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "agencyAddress" },
                            { value: "agency_notes", text: "Company Notes", isCoreField: true, type: "Large Text", fieldName: "agencyNotes" },
                        ]
                    }
                ]
            },
            {
                value: "Representatives", text: "Representative", field: 'representative', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "representative_id", text: "Representative Name", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeName", tableName: "REPRESENTATIVE", optionName: "repDetails" },
                            { value: "agency_id", text: "Company", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "representativeCompany", tableName: "AGENCY", optionName: "agencyDetails" },
                            { value: "representative_type_id", text: "Representative Type", isCoreField: true, type: "Multi Select", fieldName: "representativeType", tableName: "lu_representative_type", optionName: "representativeTypeDetails" },
                            { value: "address_1", text: "Address", isCoreField: true, type: "Large Text", fieldName: "representativeAddress", tableName: "lu_person_address_type", optionName: "addressTypeDetails" },
                        ]
                    }
                ]
            },
            {
                value: "Talent", text: "Talent", field: 'talent', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "talent_id, talent_group_id", text: "Talent Name + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "talent", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "role_id", text: "Role", isCoreField: true, isMandatoryField: true, type: "Select", fieldName: "talentRoles", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "company", text: "Loanout Company", isCoreField: true, type: "Text", fieldName: "talentLoanoutCompany" },
                            { value: "performer_notes", text: "Performer Notes", isCoreField: true, type: "Large Text", fieldName: "talentPerformerNotes" },
                        ]
                    },
                    {
                        type: "All Criteria", filterCategories: [
                            { value: "company", text: "Company", isCoreField: true, type: "Text", fieldName: "talentCompany" },
                            { value: "deceased", text: "Deceased", type: "Boolean", fieldName: "talentDeceased", tableName: "none", optionName: "genericDetails" },
                            { value: "project_id", text: "Linked Projects", isCoreField: true, type: "Search", fieldName: "linkedProject", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "title", text: "Linked Submissions", isCoreField: true, type: "Search", fieldName: "linkedSubmission", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "wga_type", text: "Linked WGAs", isCoreField: true, type: "Search", fieldName: "linkedWGA", tableName: "SUBMISSION", optionName: "submissionDetails" },
                        ]
                    }
                ]
            },
            {
                value: "Submissions", text: "Submissions", field: 'submissions', filterCategoryTypes: [
                    {
                        type: "Core", filterCategories: [
                            { value: "submission_id", text: "Title + AKAs", isCoreField: true, isMandatoryField: true, type: "Search", fieldName: "submissionTitle", tableName: "SUBMISSION", optionName: "submissionDetails" },
                            { value: "author_id,talent_group_id", text: "Authors", isCoreField: true, type: "Search", fieldName: "submissionAuthors", tableName: "TALENT", optionName: "authorDetails" },
                            { value: "format_id", text: "Format", isCoreField: true, isMandatoryField: true, type: "Multi Select", fieldName: "submissionFormat", tableName: "lu_format", optionName: "materialTypeDetails" },
                            { value: "submitted_to", text: "Submitted To", isCoreField: true, type: "Search", fieldName: "submittedTo", tableName: "USER", optionName: "submittedToDetails" },
                            { value: "talent_id,talent_group_id,representative_id", text: "Submitted By", isCoreField: true, type: "Search", fieldName: "submittedBy", tableName: "TALENT_REP", optionName: "submittedByDetails" },
                            { value: "draft_date", text: "Draft Date", isCoreField: true, type: "Date", fieldName: "submissionDraftDate" },
                            { value: "received_date", text: "Received Date", isCoreField: true, isMandatoryField: true, type: "Date", fieldName: "submissionReceivedDate" },
                            { value: "division_id", text: "Studio Division", isCoreField: true, type: "Select", tableName: "USER_DIVISIONS", optionName: "studioDivisionDetails" },
                        ]
                    },
                    {
                        type: "Material Details", filterCategories: [
                            { value: "pgs", text: "Page Count/WB Page Count", type: "Radio Number Range", fieldName: "submissionPageCount" },
                            { value: "draft_version", text: "Draft Version", type: "Text", fieldName: "submissionDraftVersion" },
                            { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                            { value: "deal_memo_step_id", text: "Deal Step", type: 'Search', fieldName: "dealStep", tableName: 'DEAL_STEPS', optionaName: 'dealStepDetails' },
                            { value: "is_term_deal_producer", text: "Term Deal Producer", type: "Boolean", fieldName: "submissionTermDeal", tableName: "none", optionName: "genericDetails" },
                            { value: "term_deal_company", text: "Term Deal Company", type: "Text", fieldName: "submissionTermDealCompany", tableName: "lu_project_status", optionName: "companyDetails" },
                            { value: "box", text: "Box #", type: "Text", fieldName: "submissionBox" },
                            { value: "sku", text: "SKU #", type: "Text", fieldName: "submissionSKU" },
                            { value: "submission_notes", text: "Submission Notes", type: "Large Text", fieldName: "submissionNotes" },
                        ]
                    },
                    {
                        type: "Source Material", filterCategories: [
                            { value: "title", text: "Title", type: "Text", fieldName: "submissionMaterialTitle" },
                            { value: "material_type_id", text: "Type", type: "Select", fieldName: "submissionMaterialType", tableName: "lu_material_type", optionName: "materialTypeDetails" },
                            { value: "author", text: "Author", type: "Text", fieldName: "submissionMaterialAuthor" },
                            { value: "publisher", text: "Publisher", type: "Text", fieldName: "submissionMaterialPublisher" },
                            { value: "source_material_date", text: "Publisher Date", type: "Date", fieldName: "submissionMaterialDate" },
                            { value: "copy_right_date", text: "Copyright Date", type: "Date", fieldName: "submissionCopyrightDate" },
                        ]
                    },
                    {
                        type: "Security", filterCategories: [
                            { value: "is_confidential", text: "Confidential", type: "Boolean", fieldName: "submissionConfidential", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_author", text: "Hide Author", type: "Boolean", fieldName: "submissionHideAuthor", tableName: "none", optionName: "genericDetails" },
                            { value: "is_hide_title", text: "Hide Title", type: "Boolean", fieldName: "submissionHideTitle", tableName: "none", optionName: "genericDetails" },
                            { value: "is_un_official,is_interim", text: "Unofficial / Interim", type: "Boolean", fieldName: "submissionUnofficial", tableName: "submission", optionName: "submissionDetails" },
                        ]
                    },
                    {
                        type: "Elements", filterCategories: [
                            { value: "talent_id,talent_group_id", text: "Name", type: "Search", tableName: "TALENT", optionName: "submissionElementNameDetails", fieldName: "submissionElementName" },
                            { value: "role_id", text: "Role", type: "Select", fieldName: "submissionElementRole", tableName: "lu_talent_roles", optionName: "roleDetails" },
                            { value: "element_status_id", text: "Status", type: "Select", fieldName: "submissionElementStatus", tableName: "lu_element_status", optionName: "elementDetails" }
                        ]
                    },
                    // {
                    //     type: "Project/Deal Link", filterCategories: [
                    //         { value: "project_id", text: "Project Title + AKAs", type: "Search", fieldName: "projectTitle", tableName: "PROJECT", optionName: "projectDetails" },
                    //         { value: "deal_step_id", text: "Deal Step", type: 'Multi Select', fieldName: "dealStep", tableName: 'DEAL_STEP', optionaName: 'dealStepDetails' },
                    //     ]
                    // }
                ]
            },
        ]
    },
];

export const selectCannedReports = [
    {
        label: "Deal Information Report",
        value: "Deal Information Report",
        type: "Deals",
        field: [
            { group: 'Deal', parameter: 'project_id' },
            { group: 'Deal', parameter: 'writer_id,talent_group_id' }
        ],
        isExcel: false,
        reportname: 'rptCnDealWithProject',
        reportname1: 'rptCnDealWithProject',
        reportname2: 'rptCnDealWithWriterOnly',
        reportname3: 'rptCnDealWithProjectAndDealNotes',
        reportname4: 'rptCnDealWithWriterAndDealNotes'
    },
    {
        label: "Project Officer Report",
        value: "Project Officer Report",
        type: "Projects",
        field: [{
            group: 'Project', parameter: 'executive_id'
        }],
        isExcel: false,
        reportname: 'rptCnProjectOfficer'
    },
    {
        label: "Writing Due Report",
        value: "Writing Due Report",
        type: "Deals",
        field: [{
            group: 'Deal', parameter: 'writing_start'
        }],
        isExcel: false,
        reportname: 'rptCnWritingDueBetweenSpecified',
        reportname1: 'rptCnWritingDueBetweenSpecified',
        reportname2: 'rptCnWritingDueAndOutstanding',
        reportname3: 'rptCnWritingDueAndDelivered'
    },
    {
        label: "Project Detail Report",
        value: "Project Detail Report",
        type: "Projects",
        field: [{
            group: 'Project', parameter: 'project_id'
        }],
        isExcel: true,
        reportname: 'rptCnProjectDetail'
    },
    {
        label: "Logline Report",
        value: "Logline Report",
        type: "Projects",
        field: [{
            group: 'Project', parameter: 'log_line'
        }],
        isExcel: true,
        reportname: 'rptCnLogline'
    },
    {
        label: "Vault Storage Report",
        value: "Vault Storage Report",
        type: "Projects",
        field: [
            { group: 'Project', parameter: 'project_id' },
            { group: 'Submissions', parameter: 'box' }
        ],
        isExcel: false,
        reportname: 'rptCnVaultStorageByProjectTitle',
        reportname1: 'rptCnVaultStorageByProjectTitle',
        reportname2: 'rptCnVaultStorageByBoxNumber'
    },
    {
        label: "Detailed Submission Report",
        value: "Detailed Submission Report",
        type: "Submissions",
        field: [{
            group: 'Submissions', parameter: 'submission_id'
        }],
        isExcel: true,
        reportname: 'rptCnDetailedSubmission'
    },
    // {
    //     label: "Project Report",
    //     value: "Project Report",
    //     type: "Projects",
    //     field: [{
    //         group: 'Project', parameter: 'project_id'
    //     }],
    //     isExcel: false,
    //     reportname: 'rptCnProjectSingleTitle'
    // },
]

export const cannedReportConfig = [
    {
        reportName: "rptCnProjectOfficer",
        type: "Projects",
        reportType: "Project Officer Report"
    },
    {
        reportName: "rptCnProjectDetail",
        type: "Projects",
        reportType: "Project Detail Report"
    },
    {
        reportName: "rptCnLogline",
        type: "Projects",
        reportType: "Logline Report"
    },
    {
        reportName: "rptCnVaultStorageByProjectTitle",
        type: "Projects",
        searchType: "title",
        reportType: "Vault Storage Report"
    },
    {
        reportName: "rptCnVaultStorageByBoxNumber",
        type: "Projects",
        searchType: "box",
        reportType: "Vault Storage Report"
    },
    {
        reportName: "",
        type: "Projects",
        reportType: "Project Report"
    },
    {
        reportName: "",
        type: "Deals",
        reportType: "Writing Due Report"
    },
    {
        reportName: "",
        type: "Submissions",
        reportType: "Detailed Submission Report"
    },
    {
        reportName: "",
        type: "Deals",
        reportType: "Deal Information Report"
    }
];

export { searchGroups, reportObjectList, selectOptions, configObjectSubmissions, configObjectProjects, configObjectDeals, configObjectWeekendRead, configObjectTalent, tabList }