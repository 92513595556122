export const executiveDetails = {
    "addexecutive_id": null,
    "talent_id": null,
    "user_name": null,
    "group_name": null,
    "department": null,
    "title": null,
    "start_date": null,
    "end_date": null,
    "selectExecutiveValues": null,
    "project_executive_id": null,
    "project_id": null,
    "user_id": null,
    "executive_id": null,
    "selected_executive": { value: null, text: null }
}