import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import InlineSelectField from '../../components/SharedComponents/InlineSelectField/InlineSelectField';
import ActionButtons from '../../components/SharedComponents/ActionButtons/BasicActionButtons';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import scripterService from '../../services/service';
import { validateEmail } from '../Helper';
import * as Constants from '../../constants/constants';
import './EmailComponent.scss';
import { withUserContext } from '../../contexts/UserContext';
class EmailComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emailTypes: [],
            mandatoryWarning: "Please complete this mandatory field"
        }
    }

    componentDidMount() {
        this.getEmailTypes();
    }

    getEmailTypes = () => {
        if (this.props.typeEndpoint) {
            scripterService.getData(Constants.scripterServiceBaseUrl + `/staticData?staticTable=${this.props.typeEndpoint}`, this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                    this.setState({ emailTypes: formattedList });
                },
                    (err) => {
                        console.log("Error in fetching Email Types:", err)
                    })
        }
    }

    onChangeCallback = (field, value) => {
        if (field === this.props.emailConfig?.email) {
            this.setState({ fieldValid: validateEmail(value) });
        }
        this.props.editCallback(field, value, this.props.list, 'email_list');
        if (field === this.props.emailConfig?.emailTypeId) {
            this.props.editCallback(this.props.emailConfig?.emailType,
                this.state.emailTypes?.find(type => type.value === value)?.label,
                this.props.list, 'email_list');
        }
    }

    render() {
        const { emailConfig } = this.props;
        let hideActions = this.props.hideActions ? true : false;
        let error = (this.props.showMandatory && ( this.props?.list.email_type_id == null ? true : (this.props?.list.email == null ? true : false)) ) || this.props.fieldValid || false;
        return (
            <MDBContainer className="EmailContainer">
                <MDBRow>
                    {this.props?.label && <BasicLabel text={this.props?.label || ""}
                        isMandatory={this.props?.isMandatory || false} />}
                </MDBRow>
                <MDBRow className='g-0'>
                    <MDBCol md={8} className="EmailField">
                        <InlineSelectField
                            textFieldLength={7}
                            selectFieldLength={5}
                            textFieldposition="end"
                            limit={this.props.limit}
                            disabled={this.props.disabled || false}
                            showAsLabel={this.props.disabled || false}
                            inValidInput={this.props.inValidInput}
                            placeholder="Enter new email:"
                            limit={this.props.limit}
                            limitWarning={this.props.limitWarning}
                            fieldValid={this.state.fieldValid}
                            onSelectChange={(e) => this.onChangeCallback(emailConfig?.emailTypeId, e.target.value)}
                            onFieldChange={(e) => this.onChangeCallback(emailConfig?.email, e.target.value)}
                            selectValue={(this.props.list && emailConfig?.emailTypeId) ? this.props.list[emailConfig?.emailTypeId] : ""}
                            fieldValue={(this.props.list && emailConfig?.email) ? this.props.list[emailConfig?.email] : ""}
                            options={this.state.emailTypes || []} />
                    </MDBCol>
                    {
                        !hideActions ? (
                            <MDBCol md={4}>
                                <ActionButtons
                                    keyName={this.props.keyName}
                                    primaryOnChange={(e) => this.onChangeCallback(emailConfig?.isPrimary, e.target.checked)}
                                    isPrimary={(this.props.list && emailConfig?.isPrimary) ? this.props.list[emailConfig?.isPrimary] : false}
                                    addCallback={this.props.addCallback}
                                    removeCallback={this.props.removeCallback}
                                    showAdd={this.props.showAdd || false}
                                    showRemove={this.props.showRemove || false} />
                            </MDBCol>
                        ) : null
                    }
                </MDBRow>
                {
                    error && <MDBRow className="error">{(this.props.showMandatory && !this.props?.value) ? this.state.mandatoryWarning :
                        (this.props.fieldValid ? this.props.inValidInput : (this.props.disableSignatory ? this.state.selectUnion : ""))}</MDBRow>
                }
                {
                    this.props.checkedMultiPrimary ? (
                        <MDBRow>
                            <div className="error">{"Only one email address can be a primary. Multiple selection is not allowed"}</div>
                        </MDBRow>
                    ) : null
                }
            </MDBContainer>
        );
    }
}

export default withUserContext(EmailComponent);