export const weekendRead={
    "weekend_read_id": null,
    "weekend_read_date": null,
    "title": null,
    "submitted_by_name": null,
    "additional_material": null,
    "is_production": 0,
    "is_packaging": 0,
    "is_developement": 0,
    "is_prior_developement": 0,
    "is_talent": 0,
    "is_discussion": 0,
    "is_coverage_tbd": 0,
    "is_cov_old_new": 0,
    "is_coverage_only": 0,
    "is_no_material": 0,
    "is_seperate_page": 0,
    "is_memo_attached": 0,
    "is_list_attached": 0,
    "is_synopsis": 0,
    "is_already_distributed":0,
    "is_security_disclaimer": 0,
    "notes": null,
    "meeting_remarks": null,
    "weekend_assigned": [],
    "attached_coverage": [],
    "exec_read": [],
    "exec_reading": [],
    "advocate": [],
    "submitted_by": [{
      "weekend_read_submitted_by_id": null,
      "talent_id": null,
      "talent_group_id": null,
      "representative_id": null,
      "loanout_company": null,
      "agency_id": null,
      "is_company_change": 0,
      "index": 1,
      "is_delete": 0
    }],
  }

  export const WeekendReadQueueInitialJson = {
    "is_deal_queue": null,
    "is_project_queue": null,
    "is_submission_queue": null,
    "is_weekend_read_queue": 1,
    "user_id": null,
    "user_queue_deal_id": null,
    "deal_memo_id": null,
    "user_queue_project_id": null,
    "project_id": null,
    "user_queue_submission_id": null,
    "submission_id": null,
    "user_queue_weekend_read_id": null,
    "weekend_read_id": null,
    "is_delete": 0
}

export const submittedBy = {
	"weekend_read_submitted_by_id": null,
	"talent_id": null,
	"talent_group_id": null,
	"representative_id": null,
	"loanout_company": null,
	"agency_id": null,
	"is_company_change": 0,
	"index": 1,
	"is_delete": 0
}

//   "weekend_assigned": [
//     {
//       "weekend_assigned_to_id": null,
//       "assigned_to_id": 0,
//       "assigned_to": "pradeep"
//     }
//   ],
//   "attached_coverage": [
//     {
//       "weekend_attached_coverage_id": null,
//       "attached_coverage_id": 60,
//       "attached_coverage": "sadasd"
//     }
//   ],
//   "exec_read": [
//     {
//       "weekend_exec_read_id": 0,
//       "exec_read_id": 0,
//       "exec_read": "pradeep"
//     }
//   ],
//   "exec_reading": [
//     {
//       "weekend_exec_reading_id": 0,
//       "exec_reading_id": 0,
//       "exec_reading": "pradeep"
//     }
//   ]