import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import './AdvancedSearch.scss';

class ExportPdf extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file_name: null
        }
    }

    componentDidMount() {
        if (this.props?.reportName) {
            this.setState({ file_name: this.props?.reportName })
        }
    }

    onChangeCallBack = (field, value) => {
        this.setState({ [field]: value })
    }

    handleSubmit = () => {
        let postJson = {
            file_name: this.state.file_name
        }
        this.props?.handleGeneratePdf(postJson)
    }

    render() {
        return (
            <MDBContainer fluid className="ExportPdfContainer">
                {this.props?.reportName &&
                    <MDBRow className="revise-text">
                        <BasicLabel
                            text={`${this.props?.reportName} will be generated based on filters applied on the Advanced Search screen.`}
                        />
                    </MDBRow>
                }
                <MDBRow>
                    <MDBCol>
                        <BasicTextField
                            label={"File Name"}
                            id={"file-name"}
                            value={this.state.file_name || ""}
                            onChange={(e) => this.onChangeCallBack('file_name', e.target.value)
                            }
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="pdf-action-btn">
                    <MDBCol md={8} >
                    </MDBCol>
                    <MDBCol md={2} className="pl-0">
                        <BasicButton
                            className="btnwidth"
                            variant="contained"
                            type="inline"
                            text={this.props?.isGeneratingPdf ? <CircularProgress color="inherit" size={20} /> : "Export"}
                            onClick={this.handleSubmit}
                        />
                    </MDBCol>
                    <MDBCol md={2} className="pl-0">
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            onClick={this.props?.handleClose}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default ExportPdf;