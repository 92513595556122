export const selectOptions = [
    {
        label: "Active",
        value: 1
    },
    {
        label: "In-Active",
        value: 2
    },
]


export const selectHomePage = [
    {
        label: "Submissions",
        value: "Submissions"
    },
    {
        label: "Projects",
        value: "Projects"
    },
    {
        label: "Deals",
        value: "Deals"
    },
    {
        label: "Weekend Read",
        value: "Weekend Read"
    },
    {
        label: "My Queue",
        value: "My Queue"
    },
]

export const projectAccessConfig = {
    "user_project_id": "user_project_id",
    "project_id": "project_id"
}

export const submissionsConfig = {
    "user_submission_id": "user_submission_id",
    "submission_id": "submission_id"
}

export const tenant = {
    "user_tenant_id": "user_tenant_id",
    "tenant_id": "tenant_id",
    "tenant_name": "tenant_name"
}

export const userInitialDetails = {
    "divisions": [],
    "projects": [],
    "submissions": [],
    "user_assistants": [],
}

export const subDivisions = {
    "user_division_id": null,
    "division_id": 0,
    "division_name": null
} 

export const subProjects = {
    "index": 1,
    "user_project_id": null,
    "project_id": null,
    "project_title": null
}

export const subSubmissions = {
    "index": 1,
    "user_susbmission_id": null,
    "submission_id": null,
    "title": null
}

export const initialAssistantConfig = {
    "index": 1,
    "user_assistant_id": null,
    "user_id": null,
    "assistant_id": null,
    "assistant_name": null,
    "users_count": null
}

export const userLimit = {
    "fullName": 50,
    "department": 50,
    "title": 50,
    "email":50,
    "phone": 10,
    "justification": 100
}