import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicButton from '../../../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../../../SharedComponents/BasicLabel/BasicLabel';
import SearchSelectField from '../../../../SharedComponents/SearchSelectField';
import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-time-picker/assets/index.css';
import { initialSelectWGA } from "./config"
import './WGAType.scss';
import { withUserContext } from '../../../../../contexts/UserContext';

export default withUserContext(class WGAType extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            wgaDetails: initialSelectWGA,
            selectedwgaValues: [],
            wgaOptions: [],
            statusOptions: [],
            isFetchingWgaStatus: false,
            postInitiated: false,
            isPosting: false
        }
    }
    

    render() {
        let isLoading = this.state.isFetchingWgaDetails && this.state.isFetchingWgaTypes;

        return (
            <>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBContainer className="NewAuditionContainer">
                            <MDBRow className="searchSelect" id='rolesDropDown'>
                                <BasicLabel text={" "} />
                                <SearchSelectField
                                    id={"executive-select"}
                                    multiple={true}
                                    value={this.state.selectedwgaValues}
                                    options={this.state.performerOptions} width={'80%'}
                                />
                            </MDBRow>
                            <MDBRow className="p-2">
                                <MDBCol md={8} >
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        className="btnwidth"
                                        variant="contained"
                                        type="inline"
                                        text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Cancel"}
                                        disabled={false}
                                        onClick={this.submitAuditionDetails}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        type="inline"
                                        variant="outlined"
                                        text={"Generate"}
                                        onClick={this.props?.onClose}
                                        disabled={false}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    )}
            </>
        )
    }
}
);