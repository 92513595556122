import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import CircularProgress from "@material-ui/core/CircularProgress";

class ProjectDetailsTabContent extends React.Component {

    getCommaSeparatedValues = (field, list) => {
        let formattedValue = "";
        if (typeof list === 'string') {
            formattedValue = list;
        } else {
            list?.map(item => item[field] ? (formattedValue = formattedValue + (formattedValue ? ", " : " ") + item[field]) : null);
        }
        return formattedValue;
    }

    render() {
        const { projectDetails } = this.props;
        return (
            <MDBContainer fluid className="ProjectDetailsContainer p-0">
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardText>
                            {this.props?.isLoading ? (
                                <div className="ContentLoader">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div className="projectDetailsSection">
                                    <div className="sectionWidth">
                                        <MDBRow>
                                            <MDBCol>
                                                <BasicLabel text={"AKAs"} />
                                                <p>{this.getCommaSeparatedValues('aka', projectDetails?.akas) || '-'}</p>
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicLabel text={"Studio Division"} />
                                                <p>{projectDetails?.division_name || '-'}</p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={6}>
                                                <BasicLabel text={"Type"} />
                                                <p>{projectDetails?.project_type_name || '-'}</p>
                                            </MDBCol>
                                            <MDBCol md={6}>
                                                <BasicLabel text={"Genres"} />
                                                <p>{this.getCommaSeparatedValues('genre_name', projectDetails?.genres) || '-'}</p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={12}>
                                                <BasicLabel text={"Logline"} />
                                                <p>{projectDetails?.log_line || '-'}</p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={12}>
                                                <BasicLabel text={"Notes"} />
                                                <p>{projectDetails?.project_notes || '-'}</p>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                    <div className="sectionWidth">
                                        <MDBRow>
                                            <MDBCol md={5}>
                                                <BasicLabel text={"Distribution Company"} />
                                                <p>{projectDetails?.distribution_company_name || '-'}</p>
                                            </MDBCol>
                                            <MDBCol md={2}>
                                                <BasicLabel text={"SAP (Dev)"} />
                                                <p>{projectDetails?.sap_dev || '-'}</p>
                                            </MDBCol>
                                            <MDBCol md={2}>
                                                <BasicLabel text={"SAP (Prod)"} />
                                                <p>{projectDetails?.sap_prod || '-'}</p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={3}>
                                                <BasicLabel text={"Prod. Start Date"} />
                                                <p>{projectDetails?.production_start_date || '-'}</p>
                                            </MDBCol>
                                            <MDBCol md={2} className="p-0">
                                                <BasicLabel text={"Wrap Date"} />
                                                <p>{projectDetails?.wrap_date || '-'}</p>
                                            </MDBCol>
                                            <MDBCol md={2}>
                                                <BasicLabel text={"Release Date"} />
                                                <p>{projectDetails?.release_date || '-'}</p>
                                            </MDBCol>
                                            <MDBCol md={3}>
                                                <BasicLabel text={"Rights Lapse Date"} />
                                                <p>{projectDetails?.rights_lapse_date || '-'}</p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol>
                                                <BasicLabel text={"Source Material"} />
                                                {
                                                    projectDetails?.source_material?.map(source => (
                                                        <MDBRow>
                                                            <MDBCol md={4}><p>{`${source?.title || '-'} (${source?.type_name || '-'})`}</p></MDBCol>
                                                            <MDBCol md={5}><p>{`Pub: ${source?.publisher || '-'} (${source?.date || '-'})`}</p></MDBCol>
                                                            <MDBCol md={3}><p>{`Copyright: ${source?.copyright_date || '-'}`}</p></MDBCol>
                                                        </MDBRow>
                                                    ))
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={4}>
                                                <BasicLabel text={"MPM#"} />
                                                <p>{projectDetails?.mpm_no || '-'}</p>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </div>
                            )}
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
        )
    }
}

export default ProjectDetailsTabContent