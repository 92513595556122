import React from 'react';
import TableComponent from '../../../SharedComponents/Table';
import { withRouter } from 'react-router';
import { configObjectDealSteps, dealStepsList } from './config';
import './DealsDetails.scss';

class DealSteps extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            listdeal: [],
            config: configObjectDealSteps,
        }
    }
    navigationCallback = (obj, node) => {
        console.log(obj);
        if (node.includes("draft")) {
            // redirect to  submission
            let path = `/submissionDetails/${obj?.submission_id}`;
            this.props.history.push(path);
        }
        else if(node.includes("writers")){
            // console.log(obj);            
            this.props?.showModals("talent", null, obj?.id);
        }
    }

    handleFileCallback(id, dataItem, index) {
        let indexToPass = this.props?.listData.map(e => e.id).indexOf(dataItem.id);
        this.props?.showModals("generateScript", indexToPass);
    }

    render() {
        return (
            <div id="DealStepsTable">
                <TableComponent
                    list={this.props?.listData ? this.props?.listData : ""}
                    config={this.state.config}
                    hyperLinkNavigationCallback={this.navigationCallback}
                    handleScrollCallback={this.handleFileCallback.bind(this)}
                />
             </div>
        )
    }
}
export default withRouter(DealSteps)