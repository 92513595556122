export const talentProjectsList = [
    { 
        Project: "Project 1",
        Logline: "[West world, season2]",
        StudioDivision: "Studio Division 1",

    },
    { 
        Project: "Project 2",
        Logline: "[West world, season2]",
        StudioDivision: "Studio Division 2",

    },
]

export const talentSubmissionsList = [
    {
        Submission: "Submission 1",
        Authors: "[Author 1, Author 2]", 
        Format: "Format 1",
        Status: "Active"
    },
    {
        Submission: "Submission 2",
        Authors: "[Author 3, Author 4]", 
        Format: "Format 2",
        Status: "Pending"
    }
]