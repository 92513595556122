const ActiveOrInActive = [
    {
        value: 0,
        label: "Inactive"
    },
    {
        value: 1,
        label: "Active"
    }
]

const configObjectAllUsers = {
    iscolumnFilterRequired: true,
    headings: [{
        headingLabel: "Name",
        icon: "",
        width: "10%",
        inputType: "select",
        isColumn: true,
        dataNode: "name"
    },
    {
        headingLabel: "Studio",
        icon: "",
        width: "12%",
        inputType: "searchselect",
        className: "search-select",
        isColumn: true,
        dataNode: "studio",
        tableName: 'lu_division',
        selectOptions: "studioOptions"
    },
    {
        headingLabel: "Group",
        icon: "",
        width: "12%",
        inputType: "searchselect",
        className: "search-select",
        isColumn: true,
        dataNode: "group",
        tableName: 'group',
        selectOptions: "groupOptions"
    },
    {
        headingLabel: "Department",
        icon: "",
        width: "10%",
        inputType: "select",
        isColumn: true,
        dataNode: "department"
    },
    {
        headingLabel: "Title",
        icon: "",
        width: "10%",
        inputType: "select",
        isColumn: true,
        dataNode: "title"
    },
    {
        headingLabel: "Email",
        icon: "",
        width: "15%",
        inputType: "select",
        isColumn: true,
        dataNode: "email"
    },
    {
        headingLabel: "Phone",
        icon: "",
        width: "8%",
        inputType: "select",
        isColumn: true,
        dataNode: "phone"
    },
    {
        headingLabel: "Status",
        icon: "",
        width: "5%",
        inputType: "dropdown",
        isColumn: true,
        dataNode: "status",
        selectOptions: "statusOptions",
        tableName: "none"

    }],
    dataNodes: ["name", "studio", "group", "department", "title", "email","phone","status"],
    primaryKey: "id",
    primaryKey: "user_id",
    hyperlinks: ["name"],
    //actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    filtersLanding: true
};

const allUsersList = [
    {
        id: 1,
        name: "User Name",
        studio: "Studio",
        group: "Group Name",
        department: "Department",
        title: "Title",
        email: "Email",
        phone: "Phone Number",
        status: "Active"
    },
    {
        id: 2,
        name: "User Name",
        studio: "Studio",
        group: "Group Name",
        department: "Department",
        title: "Title",
        email: "Email",
        phone: "Phone Number",
        status: "Active"
    },
    {
        id: 3,
        name: "User Name",
        studio: "Studio",
        group: "Group Name",
        department: "Department",
        title: "Title",
        email: "Email",
        phone: "Phone Number",
        status: "Active"
    },
    {
        id: 4,
        name: "User Name",
        studio: "Studio",
        group: "Group Name",
        department: "Department",
        title: "Title",
        email: "Email",
        phone: "Phone Number",
        status: "Active"
    },
    {
        id: 5,
        name: "User Name",
        studio: "Studio",
        group: "Group Name",
        department: "Department",
        title: "Title",
        email: "Email",
        phone: "Phone Number",
        status: "Active"
    },
    {
        id: 6,
        name: "User Name",
        studio: "Studio",
        group: "Group Name",
        department: "Department",
        title: "Title",
        email: "Email",
        phone: "Phone Number",
        status: "Active"
    },
]

const configObjectAllUsersModal = {
    dataNode: ["user_name"],
    primaryKey: "id",
    actions: ["view", "trash-alt"],
};




export {ActiveOrInActive, allUsersList, configObjectAllUsers, configObjectAllUsersModal }