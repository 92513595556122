import React from 'react';
import Popover from '@material-ui/core/Popover';

class PopOver extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }
        }
    }

    render() {
        return (
            <div className="popOverContainer">
                <Popover
                    id={this.props?.id}
                    open={this.props?.open}
                    anchorEl={this.props?.anchorEl}
                    onClose={this.props?.handleClose}
                    anchorOrigin={this.props?.anchorOrigin || this.state.anchorOrigin}
                    transformOrigin={this.props?.transformOrigin || this.state.transformOrigin}
                    // BackdropProps={{ invisible: false }}
                >
                    {this.props?.content != '-' && this.props?.content?.map(content => (
                        <p>{content}</p>
                    ))}
                </Popover>
            </div>
        )
    }
}

export default PopOver;