const configObjectDealSteps = {
    iscolumnFilterRequired: false,
    headings: [{
        headingLabel: "No.",
        width: "3%",
        isColumn: true,
    },{
        headingLabel: "Step Name",
        width: "10%",
        isColumn: true,
    },
    {
        headingLabel: "Writers",
        width: "13%",
        isColumn: true,
    },

    {
        headingLabel: "Duration",
        width: "7%",
        isColumn: true,
    },
    {
        headingLabel: "Writing Start",
        width: "9%",
        isColumn: true,
    },
    {
        headingLabel: "Writing Due",
        width: "7%",
        isColumn: true,
    },
    {
        headingLabel: "Delivery",
        width: "7%",
        isColumn: true,

    },
    {
        headingLabel: "Reading Period",
        width: "9%",
        isColumn: true,
    },
    {
        headingLabel: "Reading End",
        width: "8%",
        isColumn: true,

    },
    {
        headingLabel: "Payment",
        width: "6%",
        isColumn: true,

    },
    {
        headingLabel: "Draft",
        width: "4%",
        isColumn: true,


    },
    {
        headingLabel: "General Notes ",
        width: "8%",
        isColumn: true,

    },
    {
        headingLabel: "Dellivery Notice",
        width: "9%",
        isColumn: true,

    },



    ],
    dataNodes: ["no","name", "writers", "duration", "writingstart", "writingdue", "delivery", "readingperiod", "readingend", "payment", "draft", "genaralnotes"],
    primaryKey: "id",
    actions: ["scroll"],
    // hyperlinks: ["writers"],
    shareIcon: [
        {
            column: "draft",
            icon: "share",
            shareId:"submission_id"
            
        },
        {
            column: "genaralnotes",
            icon: "file",
            isTooltip: true
        },
    ],
    // columnWithIcon: [
       
    //     {
    //         column: "writers",
    //         icon: "eye"
    //     },
    // ],
    hyperlinks: ["writers"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    // filtersLanding: true
   
};

const dealStepsList = [
    {
        id: 1,
        name: "[step name]",
        writers: "[comma-sep list of writers name]",
        duration: "1week",
        writingstart: "MM/DD/YYY",
        writingdue: "MM/DD/YYY",
        delivery: "MM/DD/YYY",
        readingperiod: "2days",
        readingend: "MM/DD/YYY",
        payment: "$3000",
        shareIcon: "pen",
        genaralnotes: "[general notes]"
    },
    {
        id: 2,
        name: "[step name]",
        writers: "[comma-sep list of writers name]",
        duration: "1week",
        writingstart: "MM/DD/YYY",
        writingdue: "MM/DD/YYY",
        delivery: "MM/DD/YYY",
        readingperiod: "2days",
        readingend: "MM/DD/YYY",
        payment: "$3000",
        draft: "",
        genaralnotes: "[general notes]"
    },
    {
        id: 3,
        name: "[step name]",
        writers: "[comma-sep list of writers name]",
        duration: "1week",
        writingstart: "MM/DD/YYY",
        writingdue: "MM/DD/YYY",
        delivery: "MM/DD/YYY",
        readingperiod: "2days",
        readingend: "MM/DD/YYY",
        payment: "$3000",
        draft: "",
        genaralnotes: "[general notes]"
    },
    {
        id: 4,
        name: "[step name]",
        writers: "[comma-sep list of writers name]",
        duration: "1week",
        writingstart: "MM/DD/YYY",
        writingdue: "MM/DD/YYY",
        delivery: "MM/DD/YYY",
        readingperiod: "2days",
        readingend: "MM/DD/YYY",
        payment: "$3000",
        draft: "",
        genaralnotes: "[general notes]"
    },
    {
        id: 5,
        name: "[step name]",
        writers: "[comma-sep list of writers name]",
        duration: "1week",
        writingstart: "MM/DD/YYY",
        writingdue: "MM/DD/YYY",
        delivery: "MM/DD/YYY",
        readingperiod: "2days",
        readingend: "MM/DD/YYY",
        payment: "$3000",
        draft: "",
        genaralnotes: "[general notes]"
    },
    {
        id: 6,
        name: "[step name]",
        writers: "[comma-sep list of writers name]",
        duration: "1week",
        writingstart: "MM/DD/YYY",
        writingdue: "MM/DD/YYY",
        delivery: "MM/DD/YYY",
        readingperiod: "2days",
        readingend: "MM/DD/YYY",
        payment: "$3000",
        draft: "",
        genaralnotes: "[general notes]"
    },
]




export { configObjectDealSteps, dealStepsList }