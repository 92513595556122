import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import TableComponent from '../../SharedComponents/Table';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import { representedTalentListConfig } from './Config';
import TablePagination from '@material-ui/core/TablePagination';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';
import MessageModal from '../../SharedComponents/MessageModal';
import './Representative.scss';

class RepresentatedTalent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 10,
            page: 0,
            renderList: [],
            representativeList: [],
            config: representedTalentListConfig,
            isSortByAsc: true,
            sortCount: 0,
            sortBy: "Talent Name",
            getSortNode: this.getSortNode.bind(this),
            showMessageModal: false,
            showError: false,
            unsavedMessage: "Are you sure you want to discard your changes?"
        }
    }

    componentDidMount() {
        let list = tableSortList("Name", this.getSortNode(this.state.sortBy), this.state.representativeList, this.state.isSortByAsc)
        this.setState({ renderList: handlePaginationList(this.state.page, this.state.rowsPerPage, list), representativeList: list });
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage, renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.representativeList) });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.representativeList !== state.representativeList) {
            let list = tableSortList("Name", state.getSortNode(state.sortBy), props.representativeList, state.isSortByAsc)
            return {
                representativeList: props.representativeList,
                renderList: handlePaginationList(state.page, state.rowsPerPage, list)
            }
        }
        return null
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })

    }

    getSortNode = (col) => {
        switch (col) {
            case "Talent Name": return "talent_name";
            case "Datetime Added": return "created_at";
        }
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                renderList: tableSortList("Name", this.getSortNode(colName), this.state.representativeList, this.state.isSortByAsc)
            });
        })
    }

    navigationCallback = (obj, node) => {
        if (node.includes("talent_name")) {
            this.props.navFuns.handleHideRepresentativePopup();
            if (obj.talent_id) {
                this.props.navFuns.handleShowTalentPopup(obj.talent_id);
            } else {
                this.props.navFuns.handleShowTalentPopup(obj.talent_group_id, 1);
            }
        }
    }

    handleAddTalent = () => {
        if (this.props.repUpdated == true) {
            this.setState({ showMessageModal: true, showError: true });
        }
        else {
            this.onHandleRepClose();
        }
    }

    onHandleRepClose = () => {
        this.props.navFuns.handleHideRepresentativePopup();
        this.props.navFuns.handleShowTalentPopup();
    }

    render() {
        return (
            <MDBContainer fluid className="ModalTableContainer">
                <input className="hiddenButton" type="button" ref={this.props.submitButtonRef} onClick={() => this.props.handleSubmit()}></input>
                <MDBRow className='g-0'>
                    <MDBCol md={10}></MDBCol>
                    <MDBCol md={2} className="new-talent-btn">
                        <BasicButton
                            variant="contained"
                            text="New Talent"
                            type="inline"
                            onClick={this.handleAddTalent}
                            icon={"plus-circle"}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className='g-0'>
                    <TableComponent
                        list={this.state.renderList || []}
                        config={representedTalentListConfig}
                        isLoading={this.props.isLoading || false}
                        sortCallback={this.handleTableColumnClick}
                        className={"agentList"}
                        hyperLinkNavigationCallback={this.navigationCallback}
                    />
                </MDBRow>
                <MDBRow className="Pagination">
                    <TablePagination
                        component="div"
                        count={this.state.representativeList ? this.state.representativeList?.length : 0}
                        rowsPerPageOptions={[]}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                    />
                </MDBRow>
                {
                    this.state.showMessageModal &&
                    <MessageModal
                        open={this.state.showError || false}
                        title={"Unsaved Changes"}
                        message={this.state.unsavedMessage}
                        hideCancel={this.state.showFail || this.state.showReportFail || this.state.showSuccess || this.state.showReportNotFound || false}
                        primaryButtonText={"OK"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={this.onHandleRepClose}
                        handleClose={(e) => this.setState({ showError: false })} />
                }
            </MDBContainer>
        );
    }
}

export default RepresentatedTalent;