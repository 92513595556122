import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from '@material-ui/core/Chip';
import { withUserContext } from '../../../contexts/UserContext';
import SearchSelectField from '../../SharedComponents/SearchSelectField';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import SelectField from '../../SharedComponents/SelectField/SelectField';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import MiniField from '../../SharedComponents/MiniField/MiniField';
import ListField from '../../SharedComponents/ListField/ListField';
import { akaConfig, emailConfig, talentPersonalLimit, prefixes, suffixes, talentEmail, talentAka } from './Config';
import EmailComponent from '../../../Common/Email/EmailComponent';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import BasicCheckBox from '../../SharedComponents/BasicCheckbox/BasicCheckbox';
import MessageModal from '../../SharedComponents/MessageModal';
import {
    validateAlpVarCharNumOnlyWithSpace,
    validateCharctersOnly,
    validateCharNumOnlyWithSpace
} from '../../../Common/Helper';
import messages from '../../../Common/Messages.json';
import ScripterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import axios from 'axios';

class TalentPersonal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            talentDetails: [],
            prefixes: prefixes,
            suffixes: suffixes,
            selectedRoleValues: [],
            roleOptions: [],
            searchCancelToken: null
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.talentDetails !== state.talentDetails) {
            return {
                talentDetails: props.talentDetails
            }
        }
        return null
    }

    componentDidMount() {
        this.getRoleType();
    }

    getRoleType = () => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_talent_roles', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log("roleResponse", response)
                let filteredList = response.data?.map(item => ({
                    value: item.id,
                    text: item.name,
                    is_active: item.is_active
                }));
                let removeDuplicates = filteredList.filter((value, index, arr) =>
                    index === arr.findIndex((item) => (
                        item.value === value.value))
                )
                this.setState({
                    roleOptions: removeDuplicates,
                })

            })
    }

    getWriterTalents = (entity, entitytype, searchString) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        ScripterService.getDataWithCancel(Constants.scripterServiceBaseUrl + `/entitySearch?entity=${entitytype}&searchString=${searchString}`,
            cancelToken,
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = [];
                let talentDetails = response?.data?.map(item => ({ value: item.talent_id, text: item.talent_name, roles: item.roles, writer_team: item.writer_team }));
                formattedList = talentDetails?.filter(item => item?.roles?.includes("Writer") && item.writer_team === 0);
                this.setState({ [entity]: formattedList });
            },
                (err) => {
                    console.log("Error in fetching entities:", err)
                })
    }

    onChangeCallBack = (field, val) => {
        if (field === "role_list") {
            let newValue = val.filter((value, index, arr) =>
                index === arr.findIndex((item) => (
                    item.value === value.value)
                )
            )
            let roleValue = newValue?.map(item => ({ role_id: item.value, role: item.text, talent_role_id: null }))
            this.props.handleGeneralEdit(field, roleValue);
        } else if (field === "talent_group_members_list") {
            let teamMembersList = this.props?.talentDetails?.talent_group_members_list ?
                this.props?.talentDetails?.talent_group_members_list : [];
            let newValue = {
                talent_id: val?.value,
                talent_group_member_id: null,
                talent_name: val?.text
            }
            teamMembersList?.push(newValue);
            this.props.handleGeneralEdit(field, teamMembersList);
        }
    }

    handleOnChangeWriterTeam = (field, value) => {
        if (this.state?.talentDetails?.talent_id && value) {
            this.updateExistingTalentToGroup(field, value);
        } else if (!this.state.talentDetails?.talent_id && value) {
            this.setNewTalentToTeam(field, value);
            // this.props?.handleGeneralEdit(field, value);
            // this.setWriterAsDefaultRole();
        } else if (!this.state.talentDetails?.talent_id && !value) {
            this.props?.handleGeneralEdit(field, value);
            this.props?.handleGeneralEdit("role_list", []);
        } else {
            this.setGroupToPrevState(field, value);
        }
    }

    setWriterAsDefaultRole = () => {
        let formattedWriter = {};
        let existingRole = this.props?.talentDetails?.role_list?.find(item => item.role === 'Writer' && item.talent_role_id);
        if (existingRole) {
            formattedWriter = existingRole
        } else {
            let writerRole = this.state?.roleOptions?.find(item => item.text === "Writer");
            formattedWriter = {
                role_id: writerRole?.value,
                role: writerRole?.text,
                talent_role_id: null
            }
        }
        let role_list = [];
        role_list?.push(formattedWriter);
        this.props?.handleGeneralEdit('role_list', role_list);
    }

    setNewTalentToTeam = (field, value) => {
        let akas = [];
        let email_list = [];
        akas?.push(JSON.parse(JSON.stringify({ ...talentAka })));
        email_list?.push(JSON.parse(JSON.stringify({ ...talentEmail })));
        this.props?.handleGeneralEdit("akas", akas);
        this.props?.handleGeneralEdit("email_list", email_list);
        this.props?.handleGeneralEdit("role_list", []);
        this.props?.handleGeneralEdit("company", null);
        this.props?.handleGeneralEdit('prefix_id', null);
        this.props?.handleGeneralEdit('prefix', null);
        this.props?.handleGeneralEdit('suffix_id', null);
        this.props?.handleGeneralEdit('suffix', null);
        this.props?.handleGeneralEdit('m_i', null);
        this.props?.handleGeneralEdit('first_name', null);
        this.props?.handleGeneralEdit('last_name', null);
        this.props?.handleGeneralEdit('performer_notes', null);
        this.props?.handleGeneralEdit('representation_list', []);
        this.props?.handleGeneralEdit('project_list', []);
        this.props?.handleGeneralEdit('submission_list', []);
        this.props?.handleGeneralEdit(field, value);
        this.setWriterAsDefaultRole();
    }

    updateExistingTalentToGroup = (field, value) => {
        let akas = [];
        let email_list = [];
        akas?.push(JSON.parse(JSON.stringify({ ...talentAka })));
        email_list?.push(JSON.parse(JSON.stringify({ ...talentEmail })));
        this.props?.handleGeneralEdit("akas", akas);
        this.props?.handleGeneralEdit("email_list", email_list);
        this.props?.handleGeneralEdit("role_list", []);
        this.props?.handleGeneralEdit("company", null);
        this.props?.handleGeneralEdit('prefix_id', null);
        this.props?.handleGeneralEdit('prefix', null);
        this.props?.handleGeneralEdit('suffix_id', null);
        this.props?.handleGeneralEdit('suffix', null);
        this.props?.handleGeneralEdit('m_i', null);
        this.props?.handleGeneralEdit('first_name', null);
        this.props?.handleGeneralEdit('last_name', null);
        this.props?.handleGeneralEdit('performer_notes', null);
        this.props?.handleGeneralEdit('representation_list', []);
        this.props?.handleGeneralEdit('project_list', []);
        this.props?.handleGeneralEdit('submission_list', []);
        this.props?.handleGeneralEdit(field, value);
        // let existingTalent = this.state.writerTalents?.find(o => o.value === this.state.talentDetails?.talent_id);
        // if (existingTalent) {
            let talent_group_members = [];
            let groupItem = {
                talent_group_member_id: null,
                talent_id: this.state.talentDetails?.talent_id,
                talent_name: this.state.talentDetails?.last_name === null ? this.state.talentDetails?.first_name : this.state.talentDetails?.first_name + ' ' + this.state.talentDetails?.last_name
            }
            talent_group_members?.push(groupItem);
            this.props?.handleGeneralEdit("talent_group_id", null);
            this.props?.handleGeneralEdit("talent_group_members_list", talent_group_members);
        // }
        this.setWriterAsDefaultRole();
    }

    setGroupToPrevState = (field, value) => {
        this.props?.handleGeneralEdit(field, value);
        let talentDetails = { ...this.props?.initialTalentDetails }
        this.props?.handleGeneralEdit('first_name', talentDetails?.first_name);
        this.props?.handleGeneralEdit('last_name', talentDetails?.last_name);
        if (talentDetails.akas?.length === 0) {
            talentDetails?.akas?.push(JSON.parse(JSON.stringify({ ...talentAka })));
        }
        if (talentDetails?.email_list?.length === 0) {
            talentDetails?.email_list?.push(JSON.parse(JSON.stringify({ ...talentEmail })));
        }
        this.props?.handleGeneralEdit("akas", talentDetails?.akas);
        this.props?.handleGeneralEdit("email_list", talentDetails?.email_list);
        this.props?.handleGeneralEdit("role_list", talentDetails?.role_list);
        this.props?.handleGeneralEdit("company", talentDetails?.company);
        this.props?.handleGeneralEdit('prefix_id',
            this.state.prefixes?.find(prefixItem => prefixItem.label === talentDetails?.prefix)?.value
        );
        this.props?.handleGeneralEdit('prefix', talentDetails?.prefix);
        this.props?.handleGeneralEdit('suffix_id',
            this.state.suffixes?.find(suffixItem => suffixItem.label === talentDetails?.suffix)?.value
        );
        this.props?.handleGeneralEdit('suffix', talentDetails?.suffix);
        this.props?.handleGeneralEdit('m_i', talentDetails?.m_i);
        this.props?.handleGeneralEdit('performer_notes', talentDetails?.performer_notes);
        this.props?.handleGeneralEdit('representation_list', talentDetails?.representation_list);
        this.props?.handleGeneralEdit('project_list', talentDetails?.project_list);
        this.props?.handleGeneralEdit('submission_list', talentDetails?.submission_list);
        this.props?.handleGeneralEdit("talent_group_members_list", []);
    }

    handlePerfixChange = (prefix) => {
        this.props?.handleGeneralEdit('prefix_id', prefix);
        this.props?.handleGeneralEdit('prefix',
            this.state.prefixes?.find(prefixItem => prefixItem.value === prefix)?.label);
    }

    handleSuffixChange = (suffix) => {
        this.props?.handleGeneralEdit('suffix_id', suffix);
        this.props?.handleGeneralEdit('suffix',
            this.state.suffixes?.find(suffixItem => suffixItem.value === suffix)?.label);
    }

    handleDeleteSelectedTalent = (item) => {
        let teamMembersList = this.props?.talentDetails?.talent_group_members_list;
        teamMembersList = teamMembersList.filter(function (o) {
            return o.talent_id !== item?.talent_id
        })
        this.props?.handleGeneralEdit("talent_group_members_list", teamMembersList)
    }

    handleDeleteConfirmation = (listName, callbackItem) => {
        if (callbackItem?.talent_aka_id) {
            this.setState({ 
                showDeleteConfirmationModal: true,
                listName: listName,
                callbackItem: callbackItem
            })
        } else {
            this.props?.removeCallback(listName, callbackItem)
        }
    }

    render() {
        return (
          
            <div>
                {this.props?.isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <div className="TalentPersonal">
                            <MDBContainer>
                                <MessageModal
                                    open={this.state.showDeleteConfirmationModal}
                                    title={"Delete record"}
                                    message={"Would you like to delete saved record?"}
                                    primaryButtonText={"Delete"}
                                    secondaryButtonText={"Cancel"}
                                    onConfirm={() => this.setState({ showDeleteConfirmationModal: false }, () => {
                                        this.props?.removeCallback(this.state.listName, this.state.callbackItem)
                                    })}
                                    handleClose={(e) => this.setState({ showDeleteConfirmationModal: false })} 
                                />
                                <MDBRow className="detailsHeading">
                                    <BasicLabel type={"fieldHeading"} text={"Details"} />
                                </MDBRow>
                                {(this.props?.talentDetails?.talent_group_members_name && !this.props?.talentDetails?.writer_team) ? 
                                    <MDBRow className="note-text">
                                        <MDBCol>
                                            <span>{`Note: Talent is a part of a Team with 
                                                ${!this.props?.talentDetails?.talent_group_members_name?.includes('\n') ? 
                                                    this.props?.talentDetails?.talent_group_members_name : ''
                                                }`}
                                            </span>
                                            {this.props?.talentDetails?.talent_group_members_name?.includes('\n') ?
                                                <div className="pre-line-wrap">
                                                    {this.props?.talentDetails?.talent_group_members_name}
                                                </div> : ""
                                            }
                                        </MDBCol>
                                    </MDBRow> : null
                                }
                                {!this.props?.talentDetails?.writer_team ?
                                    <MDBRow>
                                        <MDBCol md="1" id='prefixCol'>
                                            <SelectField
                                                id={"talent-prefix"}
                                                className={"prefix"}
                                                value={this.state.talentDetails?.prefix || ""}
                                                label={"Prefix"}
                                                defaultMenuText={" "}
                                                options={this.state.prefixes}
                                                onChange={(e) => this.props.handleGeneralEdit('prefix', e.target.value)}
                                            />,
                                        </MDBCol>
                    
                                        <MDBCol md="3" className="talentFirstName">
                                            <BasicTextField
                                                label="First Name"
                                                limit={talentPersonalLimit.firstName}
                                                showMandatory={this.props.postInitiated || false}
                                                limitWarning={messages.exceed50CharacterWarning || ""}
                                                isMandatory={true}
                                                value={this.state.talentDetails?.first_name || ""}
                                                onChange={(e) => validateAlpVarCharNumOnlyWithSpace(e.target.value) ?
                                                    this.props.handleGeneralEdit('first_name', e.target.value) : null}
                                                isAlphabetVar={true}
                                                handleAlphabetVariantSelect={(value) => this.props?.handleGeneralEdit('first_name', value)}
                                            />
                                        </MDBCol>
                                        <MDBCol md="3" className="talentMI">
                                            <BasicTextField
                                                limit={talentPersonalLimit.mi}
                                                limitWarning={messages.exceed50CharacterWarning || ""}
                                                maxLength={10}
                                                label="Middle Name"
                                                value={this.state.talentDetails?.m_i || ""}
                                                onChange={(e) => validateAlpVarCharNumOnlyWithSpace(e.target.value) ?
                                                    this.props?.handleGeneralEdit('m_i', e.target.value.length > 0 ? e.target.value : null) : null}
                                                isAlphabetVar={true}
                                                handleAlphabetVariantSelect={(value) => this.props?.handleGeneralEdit('m_i', value)} />
                                        </MDBCol>
                                        <MDBCol md="3" className="talentLastName">
                                            <BasicTextField
                                                label="Last Name"
                                                // showMandatory={this.props.postInitiated || false}
                                                // isMandatory={true}
                                                value={this.state.talentDetails?.last_name || ""}
                                                limit={talentPersonalLimit.lastName}
                                                limitWarning={messages.exceed50CharacterWarning || ""}
                                                onChange={(e) => validateAlpVarCharNumOnlyWithSpace(e.target.value) ?
                                                    this.props.handleGeneralEdit('last_name', e.target.value) : null}
                                                isAlphabetVar={true}
                                                handleAlphabetVariantSelect={(value) => this.props?.handleGeneralEdit('last_name', value)} />
                                        </MDBCol>
                                        <MDBCol md="1" id="suffixCol">
                                            <SelectField
                                                id={"talent-suffix"}
                                                className={"suffix"}
                                                value={this.state.talentDetails?.suffix || ""}
                                                label={"Suffix"}
                                                defaultMenuText={" "}
                                                options={this.state.suffixes}
                                                onChange={(e) => this.props.handleGeneralEdit("suffix", e.target.value)}
                                            /></MDBCol>
                                    </MDBRow> :
                                    <MDBRow>
                                        <MDBCol
                                            md={11}
                                            className={`${(!this.props?.talentDetails?.talent_group_members_list ||
                                                this.props?.talentDetails?.talent_group_members_list?.length === 0) ?
                                                "searchSelectHeight talentPersonalSearchSelect" :
                                                "talentPersonalSearchSelect"
                                                }`
                                            }
                                            id='rolesDropDown'
                                        >
                                            <BasicLabel text={"Team Members"} isMandatory={true} />
                                            { this.props?.talentDetails?.talent_group_members_list?.find((item)=>item.talent_name === "null null" || item.talent_name === null) ? '' :
                                                <div className={`${this.props?.talentDetails?.talent_group_members_list?.length > 0 ? "talent-chips" : ""}`}>
                                                    {this.props?.talentDetails?.talent_group_members_list?.map((item, index) => (
                                                        <Chip
                                                            label={`${(item?.first_name && item?.last_name) ? (item?.first_name + ' ' + item?.last_name) : item?.talent_name}`}
                                                            onDelete={() => this.handleDeleteSelectedTalent(item)}
                                                            color="primary"
                                                        />
                                                    ))}
                                                </div>
                                            }
                                            <SearchSelectField
                                                id={"team-select"}
                                                textFieldId={"team-select"}
                                                width={"800px"}
                                                placeholder={'- Search -'}
                                                showMandatory={
                                                    (this.props?.postInitiated && 
                                                        (!this.props?.talentDetails?.talent_group_members_list || 
                                                            this.props?.talentDetails?.talent_group_members_list?.length === 0
                                                        )
                                                    ) || false
                                                }
                                                options={this.state?.writerTalents || []}
                                                valueSelected={(e, value) => this.onChangeCallBack('talent_group_members_list', value)}
                                                searchText={(e) => this.getWriterTalents('writerTalents', 'TALENT', e.target.value)}
                                                searchSelect={true}
                                                multiple={false}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                }
                                <MDBRow>
                                    <MDBCol md={3}>
                                        <BasicCheckBox
                                            label="Writer Team"
                                            id={"writer-team"}
                                            disabled={(this.props?.talentDetails?.talent_group_id || 
                                                (this.props?.talentDetails?.talent_id && 
                                                    !this.props?.talentDetails?.role_list?.find(item => item.role === 'Writer' && item.talent_role_id))) ? 
                                                        true : false
                                            }
                                            checked={this.props?.talentDetails?.writer_team}
                                            onChange={(e) => this.handleOnChangeWriterTeam("writer_team", e.target.checked)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6} className="talentAka">
                                        <MDBRow> <BasicLabel text={"AKA's"} type={"text"} /></MDBRow>
                                        {
                                            this.props.talentDetails?.akas?.map((aka, index) => (
                                                <ListField id={"aka" + index}
                                                    value={aka?.aka}
                                                    list={aka}
                                                    width={280}
                                                    limit={talentPersonalLimit.aka}
                                                    limitWarning={messages.exceed50CharacterWarning || ""}
                                                    showAdd={(((this.props?.talentDetails?.akas?.length - 1) === index)
                                                        && (Object.values(aka).some(x => x && x !== aka.index && x !== true))) ? true : false}
                                                    showRemove={(aka.aka_id || index === 0) ? false : true}
                                                    config={akaConfig}
                                                    hidePrimary={true}
                                                    className="representativeAgencies"
                                                    addCallback={() => this.props.addCallback('akas')}
                                                    // removeCallback={() => this.props.removeCallback('akas', aka)}
                                                    removeCallback={() => this.handleDeleteConfirmation('akas', aka)}
                                                    onChange={(e) => this.props.handleListEdit("aka", e.target.value.length > 0 ? e.target.value : null, aka, "akas")}
                                                />
                                            ))
                                        }
                                    </MDBCol>
                                    <MDBCol
                                        md={6}
                                        className={`${this.state?.talentDetails?.role_list?.length === 0 ? "searchSelectHeight talentPersonalSearchSelect" : "talentPersonalSearchSelect"}`}
                                        id='rolesDropDown'
                                    >
                                        <BasicLabel text={"Role"} isMandatory={true} />
                                        <SearchSelectField
                                            id={"roles-select"}
                                            multiple={true}
                                            showMandatory={this.props?.postInitiated}
                                            value={this.state?.talentDetails?.role_list?.map(item => ({ value: item.role_id, text: item.role })) || []}
                                            options={this.state?.roleOptions || []}
                                            width={350}
                                            onChange={(e, value) => this.onChangeCallBack('role_list', value, e.target.value)}
                                            disableTag={this.props?.talentDetails?.writer_team ? ["Writer"] : []}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6} className="col-content email-tab">
                                        {
                                            this.props?.talentDetails?.email_list?.map((email, index) => (
                                                <MDBRow className="talentEmail-Section">
                                                    <EmailComponent
                                                        editCallback={this.props.handleListEdit}
                                                        keyName={index + "- talentEmail"}
                                                        list={email}
                                                        disabled={email.talent_email_id || false}
                                                        limit={talentPersonalLimit.email}
                                                        limitWarning={messages.exceed50CharacterWarning || ""}
                                                        inValidInput={messages.invalidInput || ""}
                                                        checkedMultiPrimary={((this.props.talentDetails?.email_list?.length - 1) === index) &&
                                                            this.props.checkMultiplePrimary(this.props.talentDetails?.email_list)}
                                                        emailConfig={emailConfig}
                                                        typeEndpoint={'lu_person_email_type'}
                                                        addCallback={() => this.props.addCallback('email_list')}
                                                        removeCallback={() => this.props.removeCallback('email_list', email)}
                                                        showRemove={(email.talent_email_id || index === 0) ? false : true}
                                                        showAdd={(this.props?.talentDetails?.email_list?.length - 1) === index
                                                            && this.props?.talentDetails?.email_list[index]?.email_type
                                                            && this.props?.talentDetails?.email_list[index]?.email ? true : false}
                                                        label={index === 0 ? "Email List" : ""} />
                                                </MDBRow>
                                            ))
                                        }
                                    </MDBCol>
                                    <MDBCol md="5">
                                        <MDBRow>
                                            <MDBCol>
                                                <BasicTextField
                                                    label="Loanout Company"
                                                    // showMandatory={this.props.postInitiated || false}
                                                    value={this.state.talentDetails?.company || ""}
                                                    onChange={(e) => validateAlpVarCharNumOnlyWithSpace(e.target.value) ?
                                                        this.props.handleGeneralEdit('company', e.target.value) : null}
                                                    isAlphabetVar={true}
                                                    handleAlphabetVariantSelect={(value) => this.props?.handleGeneralEdit('first_name', value)}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol className="talentPerformerNotes">
                                                <BasicTextArea id={"textarea-performer"}
                                                    limit={talentPersonalLimit.performerNotes}
                                                    limitWarning={messages.exceed100CharacterWarning || ""}
                                                    value={this.state.talentDetails?.performer_notes || ""}
                                                    onChange={(e) => this.props.handleGeneralEdit('performer_notes', e.target.value.length > 0 ? e.target.value : null)}
                                                    label={"Performer Notes"} 
                                                    typedCharCount={this.state.talentDetails?.performer_notes?.length || 0}
                                                    showCharCount={true}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </div>
                    )}
            </div>
        )
    }
}

export default withUserContext(TalentPersonal)