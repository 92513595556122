export const initialTalent = {
    "project_talent_id": null,
    "project_id": null,
    "talent_id": null,
    "talent_group_id": null,
    "talent": null,
    "representative_id": null,
    "representative": null,
    "roles": []
}

export const talentPrefixes = [
    { value: "Dr", label: "Dr" },
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" }
]

export const talentSuffixes = [
    { value: "Esq.", label: "Esq." },
    { value: "I", label: "I" },
    { value: "II", label: "II" },
    { value: "III", label: "III" },
    { value: "IV", label: "IV" },
    { value: "V", label: "V" },
    { value: "Sr.", label: "Sr." },
    { value: "Jr.", label: "Jr." }
]