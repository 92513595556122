import React from 'react';
class SearchResultsLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResult: props.searchResult ? props.searchResult : null
        }
    }
    componentDidMount() {
    }

    static getDerivedStateFromProps(props, state) {
        if (props.searchResult !== state.searchResult) {
            return {
                searchResult: props.searchResult
            }
        }
        return null
    }

    render() {
        switch (this.props.headerText) {
            case 'Companies':
                return (<>
                    <div class='row'>
                        <div class='col-12'>
                            {this.state.searchResult?.name}
                        </div>
                    </div>
                </>
                );
                break;
            case 'Representatives':
                return (<>
                    <div class='row'>
                        <div class='col-12'>
                            {this.state.searchResult?.name}
                        </div>
                    </div>
                </>
                );
                break;
            case 'Talent':
                return (<>
                    <div class='row'>
                        <div class='col-12'>
                            {this.state.searchResult?.name}
                        </div>
                    </div>
                </>
                );
                break;
            case 'Crew':
                return (<>
                    <div class='row'>
                        <div class='col-12'>
                            {this.state.searchResult?.first_name} - {this.state.searchResult?.last_name}
                        </div>
                    </div>
                </>
                );
                break;
            case 'Production Companies':
                return (<>
                    <div class='row'>
                        <div class='col-12'>
                            {this.state.searchResult?.name}
                        </div>
                    </div>
                </>
                );
            case 'User Management Group':
                return (<>
                    <div class='row'>
                        <div class='col-12'>
                            {this.state.searchResult?.name}
                        </div>
                    </div>
                </>
                );
            default:
                return (<>
                    <div class='row'>
                        <div class='col-12'>
                        </div>
                    </div>
                </>
                );
                break;
        }
    }
}
export default SearchResultsLayout;
