export const uploadProfileParams = {
    "signedURLType": "download",
    "tenantName": null,
    "submissionId": null,
    "projectId": null,
    "dealId": null,
    "talentId": null,
    "weekendReadId": null,
    "module": null,
    "subModule": null,
    "fileName": null
  }