export const talentNavList = [
    {
        label: "Personal",
        index: 0
    },
    {
        label: "Representation",
        index: 1
    },
    {
        label: "Projects",
        index: 2
    },
    {
        label: "Submissions",
        index: 3
    },
]

export const agentNavList = [
    {
        label: "Details",
        index: 0
    },
    {
        label: "Personnel List",
        index: 1
    },
    {
        label: "Covered Talent",
        index: 2
    }
]

export const representativeNavList = [
    {
        label: "Details",
        index: 0
    },
    {
        label: "Represented Talent",
        index: 1
    }
]