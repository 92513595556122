import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import { withRouter } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Chip from '@material-ui/core/Chip'
import Popover from '@mui/material/Popover';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import BasicCheckbox from '../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import DateField from '../../SharedComponents/DateField/DateField';
import SearchSelectField from '../../SharedComponents/SearchSelectField';
import SelectField from '../../SharedComponents/SelectField/SelectField';
import TableComponent from '../../SharedComponents/Table';
import MessageModal from '../../SharedComponents/MessageModal';
import { withUserContext } from '../../../contexts/UserContext';
// import { allCriteriaConfig } from './AllCriteriaConfig';
import {
    reportObjectList,
    selectCannedReports,
    searchGroups,
    allCriteriaConfig,
    newFilterRecord,
    filterTypePgsOptions,
    filterTypeSelectOptions,
    filterTypeNumberOptions,
    filterTypeDateOptions,
    filterTypeRangeOptions,
    filterTypeTextOptions,
    filterTypeLargeTextOptions,
    configObjectProjects,
    configObjectSubmissions,
    configObjectDeals,
    configObjectTalent,
    configObjectWeekendRead,
    configObjectWGA,
    configObjectRepresentative,
    configObjectCompany,
    saveSearchJson
} from './config';
import ScripterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { getFormattedDate } from '../../../Common/Helper'
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';
import "./AdvancedSearch.scss";

class AdvancedSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            list: [],
            page: 0,
            rowsPerPage: 10,
            count: 0,
            counter:0,
            fieldIndex: 2,
            config: JSON.parse(JSON.stringify({ ...configObjectProjects })),
            entity: "Submissions",
            entityReport: "No Report Selected",
            isExpanded: false,
            filterCriteria: [],
            filterCriteriaValues: [],
            multiSelectOptions: [],
            savedSearch: null,
            openAllCriteria: false,
            showCols: false,
            showMaxCols: false,
            showMinCols: false,
            maxColErrorMessage: 'Max ten columns are allowed for selection',
            minColErrorMessage: 'Min two columns should be selected',
            showMaxCols: 'Max Columns Alert',
            showMinColsAlert: 'Min Columns Alert',
            query: {
                "projects": 1, "project_genres": 1, "project_executives": 1,
                "project_source_material": 1, "project_talent": 1, "projects_ba_executive": 1,
                "projects_le_executive": 1, "wga": 0, "authors": 0, "submissions": 0, "submission_author": 0,
                "submission_elements": 0, "submission_format": 0, "submission_source_material": 0,
                "submission_submitted": 0, "submission_submitted_by": 0, "submission_compare_to": 0, "coverages": 0, "coverages_analyst": 0, "coverages_workflow": 0,
                "weekend_reads": 0, "weekend_read_assigned_to": 0,"weekend_per_advocate_to": 0,"weekend_read_exec_read": 0, "agency": 0,
                "representative": 0, "deals": 0, "deal_ammendments": 0, "deal_steps": 0, "talent": 0, "talent_email": 0,
                "talent_role": 0, "drafts": 0
            },
            queryString: '',
            isSortByAsc: true,
            sortCount: 0,
            sortBy: "Title",
            // getSortNode: this.getSortNode.bind(this),
            searchCancelToken: null,
            submittedToGroupName: null,
            getGlobalResults: (data) => this.getGlobalResults(data)
        }
    }

    componentDidMount() {
        this.getDatabaseMapping(this.props?.history?.location?.state);
        this.getSearchFilters();
        this.getSearchParams();
        // this.getSavedSearches();
        this.handleEntityPermissions();
        // this.getSortNode(this.state.sortBy)
        this.getGroups();
    }

    static getDerivedStateFromProps(props, state) {
        if (props?.history?.location?.state) {
            if (props?.history?.location?.state?.type != state?.globalState?.type || props?.history?.location?.state?.searchKey != state?.globalState?.searchKey) {
                state.getGlobalResults(props?.history?.location?.state)
                return {
                    globalState: props?.history?.location?.state
                }
            }
        }
    }

    getSavedSearches = (entity) => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/saveAdvanceSearch`,
            this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedSavedSearches = [];
                formattedSavedSearches = response?.data?.filter(item => item?.entity === entity);
                formattedSavedSearches = formattedSavedSearches?.map(item => ({ value: item?.advance_search_query_id, label: item?.name }));

                this.setState({
                    searchOptions: formattedSavedSearches,
                    savedSearches: response?.data
                })
            },
                (err) => {
                    console.log(err, "error in fetching saved searches");
                })
    }

    handleEntityPermissions = () => {
        let entities = JSON.parse(JSON.stringify(reportObjectList));
        let permissions = this.props?.userContext?.active_tenant?.permissions;
        if (!permissions?.canViewNonConfidentialSubmissions) {
            entities = entities.filter(item => item.value !== 'Submissions')
        }
        if (!permissions?.canViewProjects) {
            entities = entities.filter(item => item.value !== 'Projects')
        }
        if (!permissions?.canViewDeal) {
            entities = entities.filter(item => item.value !== 'Deals')
        }
        if (!permissions?.canViewWeekendRead) {
            entities = entities.filter(item => item.value !== 'Weekend_Reads')
        }
        if (!permissions?.canViewTalentRepAgencies) {
            entities = entities.filter(item =>
                item.value !== 'Talent' && item.value !== 'Representatives' && item.value !== 'Companies'
            )
        }
        if (!permissions.canViewOrModifyProjectWga) {
            entities = entities.filter(item => item.value !== 'WGAs')
        }
        this.setState({ entities: entities, entity: entities[0]?.value }, () => {
            this.getSearchFilters();
            this.getSavedSearches(this.state.entity)
        })
    }

    onChangeSavedSearch = async (field, value) => {
        let selectedSavedSearch = this.state.savedSearches?.find(item => item?.advance_search_query_id === value);
        await this.onChangeHeaderOptionsCallBack('entity', selectedSavedSearch?.entity)
        this.setState({
            [field]: value,
            selectedSavedSearch: selectedSavedSearch,
            entity: selectedSavedSearch?.entity,
            filterCriteria: JSON.parse(selectedSavedSearch?.query)?.saved_search_filter || [],
            queryString: JSON.parse(selectedSavedSearch?.query)?.where_query
        }, () => {
            this.getSearchResults(null, true);
        })
    }

    getGlobalResults = (props) => {
        this.setState({ globalState: props }, () => {
            this.getFilterDetails(reportObjectList.find(detail => detail?.value === props?.type), props);
        })
    }

    getDatabaseMapping = (props = {}) => {
        let entityType = this.state.entity === 'Companies' ? 'Company' :
            this.state.entity === 'WGAs' ? 'WGA' : this.state.entity;
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/entitySearch?entity=ADVANCE_SEARCH&searchString=${props?.type ? props?.type?.toUpperCase() : entityType?.toUpperCase()}`,
            this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (props?.type) {
                    this.setState({
                        filterConfiguration: response?.data, state: props, selectedType: props.type, searchResult: true,
                        selectedGroup: reportObjectList.find(detail => detail.value === props.type).label,
                        reportSelected: reportObjectList.find(detail => detail.value === props.type).value
                    }, () => {
                        this.handleColumnChange();
                        let temp = this.state.selectedFilter.find(values => values?.value === props.subGroup);
                        let parameterKey = props.subGroup === 'Project' ? 'project_id' : props.subGroup === 'Submissions' ? 'submission_id' : props.subGroup === 'Deal' ?
                            'document_name' : props.subGroup === 'WeekendRead' ? 'title' : props.subGroup === 'Talent' ? 'first_name,last_name, talent_group_members_name' : ''
                        let parameter = temp?.filterCategories?.find(detail => detail.value === parameterKey);
                        let filterType = filterTypeTextOptions?.find(detail => detail.value === 'contains')
                        let globalSearchFilters = {
                            filterType: filterType,
                            filterValueKey: 'value1',
                            searchKey: props?.searchKey
                        }
                        this.getSearchFilters();
                        console.log("check the filter details from global search", temp)
                        // this.handleFilter(temp, parameter, null, globalSearchFilters);
                    })
                } else {
                    this.setState({ filterConfiguration: response?.data })
                    this.handleColumnChange();
                }

            },
                (err) => {
                })
    }

    handleColumnChange = () => {
        let value = this.state.entity;
        let configUpdate = (value === 'Submissions' ? configObjectSubmissions : value === 'Projects' ? configObjectProjects :
            value === 'Deals' ? configObjectDeals : value === 'Weekend_Reads' ? configObjectWeekendRead : value === 'Talent' ? configObjectTalent :
                value === 'WGAs' ? configObjectWGA : value === 'Representatives' ? configObjectRepresentative :
                    value === 'Companies' ? configObjectCompany : null)
        this.setState({
            page: 0,
            rowsPerPage: 10,
            config: configUpdate
        });
    }

    prepareQuery = (colName = null) => {
        this.setState({ inValidFilters: false, page: 0, rowsPerPage: 10 })
        if (this.verifyFilters(this.state.filterCriteria)) {
            // this.getQuery();
            this.getQueryGroup(colName);
        } else {
            this.setState({ inValidFilters: true })
        }
    }

    verifyFilters = () => {
        let canSubmit = true;
        let newFilteredCriteria = [...this.state.filterCriteria];
        newFilteredCriteria = newFilteredCriteria?.filter(item => item?.filters[0]?.value1 || item?.filters[0]?.value2);
        this.setState({ filterCriteria: newFilteredCriteria }, () => {
            for (let i = 0; i < newFilteredCriteria.length; i++) {
                canSubmit = newFilteredCriteria[i]?.filters?.every(item => {
                    if (item?.range) {
                        return item?.groupField && item?.filterField && item?.value1 && item?.value2 && item?.filterType && item?.value2 > item?.value1
                    }
                    else {
                        if (item?.type === 'Date') {
                            if (item?.filterType === '>') {
                                return item?.groupField && item?.filterField && item?.value1 && item?.filterType
                            } else if (item?.filterType === '<') {
                                return item?.groupField && item?.filterField && item?.value2 && item?.filterType
                            } else if (item?.filterType === 'between') {
                                return item?.groupField && item?.filterField && item?.value1 && item?.value2 && item?.filterType
                            }
                        }
                        return item?.groupField && item?.filterField && item?.value1 && item?.filterType
                    }
                })
                if (!canSubmit) {
                    break
                }
            }
        })
        return canSubmit
    }

    // getQuery = () => {
    //     let queryDisplay = "";
    //     this.state.filterCriteria.map((item, index, mainArray) => {
    //         item?.filters?.map((ele, i, innerArray) => {
    //             queryDisplay = queryDisplay + ele?.filterFieldSelected?.text + ':' + (ele?.type === 'Select' || ele?.type === 'Search' ? '"' + (ele?.value1selected?.text || ele?.value1selected?.label) + '"' : '"' + ele.value1 + '"')
    //             queryDisplay = (innerArray.length > 1 && i !== innerArray.length - 1) ? queryDisplay + ' OR ' : queryDisplay
    //         })
    //         queryDisplay = (mainArray.length > 1 && index !== mainArray.length - 1) ? queryDisplay + " AND  " : queryDisplay
    //     })
    //     this.setState({ queryDisplay: queryDisplay })
    // }

    getQueryGroup = (colName = null) => {
        let query = { ...this.state.query }
        let filterConfigurations = [...this.state.filterConfiguration]
        Object.keys(query).forEach(function (index) {
            query[index] = 0;
        });
        this.state.filterCriteria.map((item, index, mainArray) => {
            item?.filters?.map((ele, i, innerArray) => {
                query[ele.groupFieldSelected.field] = 1
                let filteredConfig = filterConfigurations?.find(fc =>
                    fc.query_build_field_name === ele.filterField &&
                    fc.query_build_group_name === ele.groupField &&
                    fc.group_field === ele.filterFieldSelected.text
                );
                if (query.hasOwnProperty(filteredConfig?.query_build_alias_name)) {
                    query[filteredConfig?.query_build_alias_name] = 1;
                }
                if (filteredConfig?.query_build_alias_name === 'user' && filteredConfig?.query_build_group_name === 'Project') {
                    query['project_executives'] = 1
                }
                if ((filteredConfig?.query_build_alias_name === 'user' || filteredConfig?.query_build_alias_name === 'project_executives') &&
                    filteredConfig?.query_build_group_name === 'Project' &&
                    filteredConfig?.query_build_entity === 'DEALS'
                ) {
                    query['projects_ba_executive'] = 1
                    query['projects_le_executive'] = 1
                }
                if ((filteredConfig?.query_build_alias_name === 'deal_memo_steps' || filteredConfig?.query_build_alias_name === 'deal_memo_step_writers')
                    && filteredConfig?.query_build_group_name === 'Deal'
                ) {
                    query['deal_ammendments'] = 1;
                    query['deal_steps'] = 1;
                }
                if (filteredConfig?.query_build_alias_name === 'submission_submitted_to' &&
                    filteredConfig?.query_build_group_name === 'Submissions' &&
                    (filteredConfig?.group_field === 'Submitted To')
                ) {
                    query['submission_submitted'] = 1
                }
                if (filteredConfig?.query_build_alias_name === 'submission_submitted_by' &&
                    filteredConfig?.query_build_group_name === 'Submissions' &&
                    (filteredConfig?.group_field === 'Submitted By')
                ) {
                    query['submission_submitted_by'] = 1
                }
                if (filteredConfig?.query_build_alias_name === 'submission_compare_to' &&
                    filteredConfig?.query_build_group_name === 'Submissions' &&
                    (filteredConfig?.group_field === 'Compare To')
                ) {
                    query['submission_compare_to'] = 1
                }
                if (filteredConfig?.query_build_field_name === 'analyst_id' && filteredConfig?.query_build_group_name === 'Coverages') {
                    query['coverages_analyst'] = 1;
                }
                if (filteredConfig?.query_build_alias_name === 'submission_workflow' && filteredConfig?.query_build_group_name === 'Coverages') {
                    query['coverages_workflow'] = 1;
                }
                if (filteredConfig?.query_build_alias_name === 'talent_email, talent_group_email') {
                    query['talent_email'] = 1;
                }
                if (filteredConfig?.query_build_alias_name === 'talent_roles, talent_group_roles') {
                    query['talent_role'] = 1;
                }
                if (this.state.entity === 'Submissions' && (ele?.groupField === 'Representatives' || ele?.groupField === 'Agency')) {
                    query['submission_submitted_by'] = 1;
                }
                if (this.state.entity === 'Talent' && ele?.groupField === 'Wga') {
                    query['projects'] = 1;
                }
            });
        })
        if (this.state.entity === 'Projects') {
            query['projects'] = 1;
            query['project_genres'] = 1;
            query['project_executives'] = 1;
            query['project_source_material'] = 1;
            query['project_talent'] = 1;
            // query['talent'] = 1;
            this.setState({ selectedReport: 'rptCnProjectDetail' });
        } else if (this.state.entity === 'Submissions') {
            // query['talent'] = 1;
            query['submissions'] = 1;
            query['deals'] = 1;
            query['coverages'] = 1;
            query['submission_author'] = 1,
                query['submission_elements'] = 1,
                query['submission_format'] = 1,
                query['submission_submitted'] = 1,
                query['coverages_analyst'] = 1,
                this.setState({ selectedReport: 'rptCnDetailedSubmission' });
        } else if (this.state.entity === 'Deals') {
            query['deals'] = 1;
            query['projects'] = 1;
            query['projects_ba_executive'] = 1
            query['projects_le_executive'] = 1
            this.setState({ selectedReport: 'rptCnDealWithProject' });
        } else if (this.state.entity === 'Weekend Read') {
            query['weekend_reads'] = 1;
            query['coverages'] = 1;
            query['submissions'] = 1;
            query['weekend_read_assigned_to'] = 1;
            query['weekend_per_advocate_to'] = 1;
            query['weekend_read_exec_read'] = 1;
        } else if (this.state.entity === 'Talent') {
            query['talent'] = 1;
            query['talent_role'] = 1;
        } else if (this.state.entity === 'WGAs') {
            query['talent'] = 1;
        } else if (this.state.entity === 'Representatives') {
            query['agency'] = 1;
        }
        this.setState({ query: query }, () => {
            this.getQueryString(false, colName);
        });
    }

    getQueryString = (isGeneratePdf = false, colName = null) => {
        let queryString = "(";
        this.state.filterCriteria?.map((item, index, mainArray) => {
            item?.filters?.map((ele, i, innerArray) => {
                let postElement = { ...ele }
                let entity = this.state.entity === 'Companies' ? 'Company' :
                    this.state.entity === 'WGAs' ? 'WGA' : this.state.entity;
                let fieldConfiguration = this.state.filterConfiguration?.find(findField =>
                    findField?.group_field === postElement.filterFieldSelected.text && findField?.query_build_entity === entity?.toUpperCase() &&
                    findField?.query_build_group_name === postElement?.groupField && findField?.query_build_field_name === postElement?.filterField
                )
                let comparisionValue = ''
                if (postElement?.filterField === 'is_confidential' || postElement?.filterField === 'prod_start_date_tbd' || postElement?.filterField === 'wrap_date_tbd' ||
                    postElement?.filterField === 'release_date_tb' || postElement?.filterField === 'is_term_deal_producer') {
                    postElement.value1 = postElement?.value1 === 'Yes' ? 1 : 0
                } else if (postElement?.filterField === 'is_un_official,is_interim') {
                    postElement.filterField = postElement?.value1 === 'is_interim' ? 'is_interim' : 'is_un_official';
                }
                switch (postElement?.filterType) {
                    case 'between':
                    case 'pgs':
                    case 'wb_pgs':
                        comparisionValue = ` BETWEEN '${postElement?.value1}' AND '${postElement?.value2}'`; break;
                    case '<': comparisionValue = ` < '${postElement?.value2}'`; break;
                    case '>': comparisionValue = ` > '${postElement?.value1}'`; break;
                    case 'contains': comparisionValue = ` LIKE '%${postElement?.value1}%'`; break;
                    case 'startsWith': comparisionValue = ` LIKE '${postElement?.value1}%'`; break;
                    case 'endsWith': comparisionValue = ` LIKE '%${postElement?.value1}'`; break;
                    case 'anyValueEntered': comparisionValue = `${postElement?.value1}`; break;
                    case 'noValueEntered': comparisionValue = `${postElement?.value1}`; break;
                    default: comparisionValue = postElement?.type === 'Select' || postElement?.type === 'Search' ? `${postElement?.filterType} ${postElement?.value1}` :
                        fieldConfiguration?.query_build_field_name === 'reading_end' || fieldConfiguration?.query_build_field_name === 'copy_right_date' ||
                            fieldConfiguration?.query_build_field_name === 'status_id,created_at' || fieldConfiguration?.query_build_field_name === 'draft_date' ||
                            fieldConfiguration?.query_build_field_name === 'received_date' || fieldConfiguration?.query_build_field_name === 'reading_period' ?
                            ` ${postElement?.filterType}'${postElement?.value1}'` : fieldConfiguration?.query_build_field_name === 'genre_id' && fieldConfiguration?.query_build_group_name=== 'Coverages'
                            ?  '' : postElement?.type === 'Boolean' ? `= ${postElement?.value1}` :  `${postElement?.filterType}'${postElement?.value1}'`
                }
                if (postElement?.filterField === 'talent_id, talent_group_id') {
                    let talentFilters = innerArray?.filter(item1 => item1?.value1selected?.writer_team == 0)
                    let talentValues = [];
                    talentFilters?.map(filter => {
                        talentValues?.push(filter.value1)
                    })
                    let writerFilters = innerArray?.filter(item1 => item1?.value1selected?.writer_team == 1)
                    let writerValues = [];
                    writerFilters?.map(filter => {
                        writerValues?.push(filter.value1)
                    })
                    if (postElement?.value1selected?.writer_team) {
                        queryString = queryString + `${fieldConfiguration?.query_build_alias_name.split(',')[1]}.talent_group_id ${postElement?.filterType} ${postElement?.value1}`
                    } else {
                        queryString = queryString + `${fieldConfiguration?.query_build_alias_name.split(',')[0]}.talent_id ${postElement?.filterType} ${postElement?.value1}`
                    }
                    // if (talentFilters?.length == 1 && writerFilters?.length == 0) {
                    //     queryString = '(' + `${fieldConfiguration?.query_build_alias_name.split(',')[0]}.talent_id ${talentFilters[0]?.filterType} ${talentFilters[0]?.value1}`
                    // }
                    // if (talentFilters?.length == 0 && writerFilters?.length == 1) {
                    //     queryString = '(' + `${fieldConfiguration?.query_build_alias_name.split(',')[1]}.talent_group_id ${writerFilters[0]?.filterType} ${writerFilters[0]?.value1}`
                    // }
                    // if (talentFilters?.length > 0 && writerFilters?.length > 0) {
                    //     queryString = '(' + `${fieldConfiguration?.query_build_alias_name.split(',')[0]}.talent_id in (${talentValues?.toString()}) OR ${fieldConfiguration?.query_build_alias_name.split(',')[1]}.talent_group_id in (${writerValues?.toString()})`
                    // }
                }
                if(postElement?.filterField ==='genre_id' && postElement?.groupField ==='Coverages'){
                    queryString =  queryString +'FIND_IN_SET('+ "'"+`${postElement?.value1}`+ "'"+ ',scg.genre_id)'
                }
                else if (postElement?.filterField === 'pgs' && postElement?.filterType === 'pgs') {
                    queryString = queryString + `submission.pgs${comparisionValue}`
                } else if (postElement?.filterField === 'pgs' && postElement?.filterType === 'wb_pgs') {
                    queryString = queryString + `submission.wb_pgs${comparisionValue}`
                } else if (postElement?.filterField === 'business_executive_id' || postElement?.filterField === 'feature_executive_id') {
                    queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.executive_id ${comparisionValue}`
                } else if (postElement?.filterField === 'talent_id,talent_group_id,representative_id') {
                    if (postElement?.filterType === 'anyValueEntered') {
                        queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.talent_id ${comparisionValue} OR ${fieldConfiguration?.query_build_alias_name}.talent_group_id ${comparisionValue} OR ${fieldConfiguration?.query_build_alias_name}.representative_id ${comparisionValue}`
                    } else if (postElement?.filterType === 'noValueEntered') {
                        queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.talent_id ${comparisionValue} AND ${fieldConfiguration?.query_build_alias_name}.talent_group_id ${comparisionValue} AND ${fieldConfiguration?.query_build_alias_name}.representative_id ${comparisionValue}`
                    } else {
                        queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.talent_id ${postElement?.filterType} ${postElement?.value1} OR ${fieldConfiguration?.query_build_alias_name}.talent_group_id ${postElement?.filterType} ${postElement?.value1} OR ${fieldConfiguration?.query_build_alias_name}.representative_id ${postElement?.filterType} ${postElement?.value1}`
                    }
                }
                else if (postElement?.filterField === 'talent_id,talent_group_id') {
                    // queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.talent_id ${postElement?.filterType} ${postElement?.value1} OR ${fieldConfiguration?.query_build_alias_name}.talent_group_id ${postElement?.filterType} ${postElement?.value1}`
                    if ((postElement?.filterType !== 'anyValueEntered' && postElement?.filterType !== 'noValueEntered')) {
                        if (postElement?.value1selected?.writer_team) {
                            queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.talent_group_id ${comparisionValue}`
                        } else {
                            queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.talent_id ${comparisionValue}`
                        }
                    } else {
                        if (postElement?.filterType === 'anyValueEntered') {
                            queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.talent_id ${comparisionValue} OR ${fieldConfiguration?.query_build_alias_name}.talent_group_id ${comparisionValue}`
                        } else if (postElement?.filterType === 'noValueEntered') {
                            queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.talent_id ${comparisionValue} AND ${fieldConfiguration?.query_build_alias_name}.talent_group_id ${comparisionValue}`
                        }
                    }
                }
                else if (postElement?.filterField === 'writer_id,talent_group_id') {
                    // queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.writer_id ${postElement?.filterType} ${postElement?.value1} OR ${fieldConfiguration?.query_build_alias_name}.talent_group_id ${postElement?.filterType} ${postElement?.value1}`
                    if ((postElement?.filterType !== 'anyValueEntered' && postElement?.filterType !== 'noValueEntered')) {
                        if (postElement?.value1selected?.writer_team) {
                            queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.talent_group_id ${postElement?.filterType} ${postElement?.value1}`
                        } else {
                            queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.writer_id ${postElement?.filterType} ${postElement?.value1}`
                        }
                    } else {
                        if (postElement?.filterType === 'anyValueEntered') {
                            queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.writer_id ${comparisionValue} OR ${fieldConfiguration?.query_build_alias_name}.talent_group_id ${comparisionValue}`
                        } else if (postElement?.filterType === 'noValueEntered') {
                            queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.writer_id ${comparisionValue} AND ${fieldConfiguration?.query_build_alias_name}.talent_group_id ${comparisionValue}`
                        }
                    }
                    if (this.state.selectedCannedReport === 'Deal Information Report') {
                        console.log("this is called while downloading reports", this.state.dealNotes)
                        this.setState({
                            selectedReport: `${fieldConfiguration?.query_build_field_name === 'writer_id,talent_group_id'}` ?
                                (this.state.dealNotes ? this.state.selectedCannedReportValue.reportname4 : this.state.selectedCannedReportValue.reportname2) :
                                this.state.dealNotes ? this.state.selectedCannedReportValue.reportname3 : this.state.selectedCannedReportValue.reportname1
                        });
                    }
                }
                else if (postElement?.filterField === 'author_id,talent_group_id') {
                    if ((postElement?.filterType !== 'anyValueEntered' && postElement?.filterType !== 'noValueEntered')) {
                        if (postElement?.value1selected?.writer_team) {
                            queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.talent_group_id ${comparisionValue}`
                        } else {
                            queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.author_id ${comparisionValue}`
                        }
                    } else {
                        if (postElement?.filterType === 'anyValueEntered') {
                            queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.author_id ${comparisionValue} OR ${fieldConfiguration?.query_build_alias_name}.talent_group_id ${comparisionValue}`
                        } else if (postElement?.filterType === 'noValueEntered') {
                            queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.author_id ${comparisionValue} AND ${fieldConfiguration?.query_build_alias_name}.talent_group_id ${comparisionValue}`
                        }
                    }
                }
                else if (postElement?.filterField === 'status_id,created_at') {
                    queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.status_id ${postElement?.filterTypeOptions.find(item => item.value === '=')?.value}${fieldConfiguration?.group_field === 'Date Sent' ? this.state.submissionStatus.find(item => item.text === 'Sent to Story Analyst')?.value : this.state.submissionStatus.find(item => item.text === 'Return to Executive')?.value}  AND date(${fieldConfiguration?.query_build_alias_name}.created_at)  ${comparisionValue}`
                } else if (postElement?.filterField === 'email' && fieldConfiguration?.query_build_group_name === 'Talent') {
                    let fieldConfigAliasName = fieldConfiguration?.query_build_alias_name?.split(',');
                    queryString = queryString + `${fieldConfigAliasName[0]}.email ${comparisionValue} OR ${fieldConfigAliasName[1]}.email ${comparisionValue}`
                } else if (postElement?.filterField === 'company' && fieldConfiguration?.query_build_group_name === 'Talent') {
                    let fieldConfigAliasName = fieldConfiguration?.query_build_alias_name?.split(',');
                    queryString = queryString + `${fieldConfigAliasName[0]}.company ${comparisionValue} OR ${fieldConfigAliasName[1]}.company ${comparisionValue}`
                } else if (postElement?.filterField === 'performer_notes') {
                    let fieldConfigAliasName = fieldConfiguration?.query_build_alias_name?.split(',');
                    queryString = queryString + `${fieldConfigAliasName[0]}.performer_notes ${comparisionValue} OR ${fieldConfigAliasName[1]}.performer_notes ${comparisionValue}`
                } else if (postElement?.filterField === 'role_id' && fieldConfiguration?.query_build_group_name === 'Talent') {
                    let fieldConfigAliasName = fieldConfiguration?.query_build_alias_name?.split(',');
                    queryString = queryString + `${fieldConfigAliasName[0]}.role_id ${comparisionValue} OR ${fieldConfigAliasName[1]}.role_id ${comparisionValue}`
                } else {
                    if (postElement?.filterType === 'anyValueEntered' || postElement?.filterType === 'noValueEntered') {
                        queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.${fieldConfiguration?.query_build_field_name} ${comparisionValue}`
                    }else {
                        queryString = queryString + `${fieldConfiguration?.query_build_alias_name}.${fieldConfiguration?.query_build_field_name === 'project_id' &&
                            this.state.selectedCannedReport === 'Project Report' ? 'project_id' :
                            (fieldConfiguration?.query_build_field_name === 'is_un_official,is_interim' ? postElement.filterField :
                                fieldConfiguration?.query_build_field_name)}${comparisionValue}`
                    }
                    if (this.state.selectedCannedReport === 'Deal Information Report') {
                        let reportName = fieldConfiguration?.query_build_field_name === 'writer_id,talent_group_id' ?
                            (this.state.dealNotes ? this.state.selectedCannedReportValue.reportname4 : this.state.selectedCannedReportValue.reportname2) :
                            this.state.dealNotes ? this.state.selectedCannedReportValue.reportname3 : this.state.selectedCannedReportValue.reportname1
                        this.setState({
                            selectedReport: reportName
                        });
                    }
                    if (this.state.selectedCannedReport === 'Vault Storage Report') {
                        this.setState({
                            selectedReport: (this.state.filterCriteria?.length == 1 && this.state.filterCriteria[0]?.filters[0]?.filterField === 'project_id') ?
                                this.state.selectedCannedReportValue.reportname1 : this.state.selectedCannedReportValue.reportname2
                        });
                    }
                    if (this.state.selectedCannedReport === 'Project Officer Report' || this.state.selectedCannedReport === 'Project Detail Report' ||
                        this.state.selectedCannedReport === 'Logline Report' || this.state.selectedCannedReport === 'Detailed Submission Report' ||
                        this.state.selectedCannedReport === 'Project Report'
                    ) {
                        this.setState({
                            selectedReport: this.state.selectedCannedReportValue?.reportname
                        });
                    }
                }
                if (this.state.selectedCannedReport === 'Writing Due Report') {
                    if (this.state.writingDrafts === 'Outstanding') {
                        queryString = queryString + ` AND (deal_memo_steps.delivery_date is null or deal_memo_steps.delivery_date = '')`
                    } else if (this.state.writingDrafts === 'Delivered') {
                        queryString = queryString + ` AND (deal_memo_steps.delivery_date is not null and deal_memo_steps.delivery_date != '')`
                    }
                    this.setState({
                        selectedReport: this.state.writingDrafts === 'Outstanding' ? this.state.selectedCannedReportValue.reportname2 :
                            this.state.writingDrafts === 'Delivered' ? this.state.selectedCannedReportValue.reportname3 : this.state.selectedCannedReportValue.reportname1
                    });
                }                
                queryString = (innerArray.length > 1 && i !== innerArray.length - 1) ? queryString + ' OR ' : queryString
            })
            queryString = (mainArray.length > 1 && index !== mainArray.length - 1) ? queryString + " ) AND ( " : queryString + ' ) '
        })
        if (!isGeneratePdf) {
            this.setState({ isLoadingSets: true, queryString: queryString }, () => {
                this.getSearchResults(queryString, false, colName);
            })
        } else {
            this.setState({ queryString: queryString })
        }
    }

    getSearchResults = (queryString = null, isSavedSearch = false, colName = null) => {
        let json = {
            "where_query": queryString,
            "from_query": this.state.query,
            "page_no": this.state.page + 1,
            "page_count": this.state.rowsPerPage,
        }
        let columnNames = {
            'Submissions': 'title',
            'Deals': 'document_name',
            'Weekend_Reads': 'title',
            'WGAs': 'notice_date',
            'Companies': 'agency_name'
        }
        if (colName) {
            json["order_by_clause"] = 'ORDER BY ' + colName + ' ' + (this.state.isSortByAsc === true ? 'ASC' : 'DESC')
        } else {
            json["order_by_clause"] = 'ORDER BY ' + (columnNames[this.state.entity] ?? 'project_title') + ' ' + 'ASC'
        }
        
        this.setState(
            (prevState) => ({counter: prevState.counter+1}),
            () => { 
        
        if (isSavedSearch) {
            this.setState({ isLoadingSets: true })
        }
        json["counter"]=this.state.counter;
        let load = {
            "payload": 'advanceSearch',
            "input": isSavedSearch ? btoa(this.state.selectedSavedSearch?.query) : btoa(JSON.stringify(json)),
            "dataType": `${this.state.entity === 'Representatives' ?
                'Representative' : this.state.entity === 'Companies' ? 'Company' :
                    this.state.entity === 'WGAs' ? 'WGA' :
                        this.state.entity
                }`,
            "exportResults": 0,
            "encryptionType": "1",
            "tenantId": `${this.props?.userContext?.active_tenant?.tenant_id}`,
        }
        
        ScripterService.getDataWS(load)
            .then(webSocketConn => {
                webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data)
                    if (response?.statusCode == 200 && JSON.parse(response?.body)?.counter == this.state.counter) {
                        console.log("search result output", response, JSON.parse(response?.body)?.data)
                        let result = JSON.parse(response?.body)?.data
                        switch (this.state.entity) {
                            case 'Projects':
                                // result?.sort(function (a, b) {
                                //     return (a.project_title > b.project_title) ?
                                //         1 : ((b.project_title > a.project_title) ? -1 : 0);
                                // });
                                result?.map(item => {
                                    item.prod_start_date = getFormattedDate(item.prod_start_date);
                                    item.wrap_date = getFormattedDate(item.wrap_date);
                                    item.release_date = getFormattedDate(item.release_date);
                                    item.rights_lapse_date = getFormattedDate(item.rights_lapse_date);
                                    item.is_confidential = item.is_confidential === 1 ? 'Yes' : 'No';
                                    item.title = item.project_title + ' ' + (!item.aka ? '' : `+ ${item.aka}`);
                                    item.title = (item?.title && item?.title?.length > 32) ?
                                        (item?.title?.slice(0, 32) + "...") : item?.title
                                    item.log_line = (item?.log_line && item?.log_line?.length > 32) ?
                                        (item?.log_line?.slice(0, 32) + "...") : item?.log_line
                                    item.project_notes = (item?.project_notes && item?.project_notes?.length > 30) ?
                                        (item?.project_notes?.slice(0, 30) + "...") : item?.project_notes
                                    return item;
                                });
                                break;
                            case 'Submissions':
                                // result?.sort(function (a, b) {
                                //     return (a.title > b.title) ?
                                //         1 : ((b.title > a.title) ? -1 : 0);
                                // });
                                result?.map(item => {
                                    item.is_confidential = item.is_confidential === 1 ? 'Yes' : 'No';
                                    item.title = item.title + ' ' + (!item.aka ? '' : `+ ${item.aka}`);
                                    item.title = (item?.title && item?.title?.length > 32) ?
                                        (item?.title?.slice(0, 32) + "...") : item?.title
                                    item.submission_notes = (item?.submission_notes && item?.submission_notes?.length > 32) ?
                                        (item?.submission_notes?.slice(0, 32) + "...") : item?.submission_notes
                                    item.un_official_interim = (item?.is_interim && item?.is_un_official) ? 'Interim & UnOfficial' : null;
                                    let logline = item?.logline ? item?.logline?.replace(/<\/?[^>]+(>|$)/g, "") : null;
                                    item.logline = (logline && logline?.length >= 35) ?
                                        (logline?.slice(0, 35) + "...") :
                                        logline ? logline : null;
                                    return item;
                                });
                                break;
                            // case 'Deals':
                            //     result?.sort(function (a, b) {
                            //         return (a.document_name > b.document_name) ?
                            //             1 : ((b.document_name > a.document_name) ? -1 : 0);
                            //     });
                            //     result?.map(item => {
                            //         return item;
                            //     });
                            //     break;
                            case 'WGAs':
                                // result?.sort(function (a, b) {
                                //     return new Date(b.notice_date) - new Date(a.notice_date);
                                // });
                                result?.map(item => {
                                    item.notice_date = item?.notice_date ? getFormattedDate(item?.notice_date, false) : null
                                    return item;
                                });
                                break;
                            case 'Weekend_Reads':
                                // result?.sort(function (a, b) {
                                //     return (a.title > b.title) ?
                                //         1 : ((b.title > a.title) ? -1 : 0);
                                // });
                                result?.map(item => {
                                    item.notes = !item.notes ? item.notes : item.notes.replace(/(<([^>]+)>)/ig, '');
                                    return item;
                                });
                                break;
                            case 'Talent':
                                result?.map(item => {
                                    item.is_active = item.is_active === 1 ? 'Yes' : 'No';
                                    item.talent_name = item?.talent_group_id ? item?.talent_group_members_name : item?.talent_name
                                    return item;
                                });
                                result?.sort(function (a, b) {
                                    return (a.talent_name > b.talent_name) ?
                                        1 : ((b.talent_name > a.talent_name) ? -1 : 0);
                                });
                                break;
                            case 'Representatives':
                                result?.map(item => {
                                    item['rep_name'] = `${item?.prefix ? (item?.prefix + ' ') : ''} ${item?.first_name} ${item?.middle_name ? (' ' + item?.middle_name) : ''} ${item?.last_name ? (' ' + item?.last_name) : ''} ${item?.suffix ? (' ' + item?.suffix) : ''}`
                                    return item;
                                });
                                result?.sort(function (a, b) {
                                    return (a.rep_name > b.rep_name) ?
                                        1 : ((b.rep_name > a.rep_name) ? -1 : 0);
                                });
                                break;
                            // case 'Companies':
                            //     result?.sort(function (a, b) {
                            //         return (a.agency_name > b.agency_name) ?
                            //             1 : ((b.agency_name > a.agency_name) ? -1 : 0);
                            //     });
                            //     break;
                        }

                        let count = JSON.parse(response?.body)?.count;
                        this.setState({
                            list: result,
                            count: count,
                            searchResult: true,
                            sortBy: colName
                        }, () => {
                            this.setState({ isLoadingSets: false })
                            // this.getSortNode(this.state.sortBy)
                        })
                        this.props.history.push('/advancedSearch');
                    } 
                        this.setState({
                            count: this.state.count,
                            //isLoadingSets: false,
                        })
                    
                }
                
            })
        });
    }

    getFilterDetails = (searchType, props = {}) => {
        this.setState({
            writingDrafts: null,
            entity: searchType?.value,
            selectedGroup: searchType?.label,
            reportSelected: searchType?.value,
            // filterCriteria: [], 
            selectedCannedReport: "",
            selectedCannedReportValue: []
        }, () => {
            this.getSearchFilters();
            this.getDatabaseMapping(props);
            // this.getCannedReports();
            this.getSearchParams();

        })
    }

    getSearchFilters = () => {
        let entityConfig = !this.state.openAllCriteria ?
            JSON.parse(JSON.stringify(searchGroups)) : JSON.parse(JSON.stringify(allCriteriaConfig));
        this.setState({
            selectedFilter: entityConfig?.find(item =>
                item?.value === this.state.entity)?.filters
        })
    }

    onChangeHeaderOptionsCallBack = (field, value) => {
        this.setState({
            [field]: value,
            filterCriteria: [],
            list: [],
            queryString: "",
            dealNotes: false
        },
            () => {
                if (field === 'entity') {
                    this.getSearchFilters();
                    this.getSavedSearches(this.state.entity)
                    let value = this.state.entity;
                    let entityItem = reportObjectList?.find(item => item?.value === value);
                    this.getFilterDetails(entityItem)
                    let configUpdate = (value === 'Submissions' ? configObjectSubmissions : value === 'Projects' ? configObjectProjects :
                        value === 'Deals' ? configObjectDeals : value === 'Weekend_Reads' ? configObjectWeekendRead : value === 'Talent' ? configObjectTalent : null)
                    this.setState({
                        page: 0,
                        rowsPerPage: 10,
                        count: 0,
                        config: configUpdate
                    });
                }
            })
    }

    onCannedReportSelection = (value) => {
        let newValue = selectCannedReports?.find(item => item?.type === this.state.entity && item?.value === value);
        this.getSearchFilters();
        this.setState({
            writingDrafts: null,
            selectedCannedReportValue: !newValue ? [] : newValue,
            selectedCannedReport: newValue?.value ? newValue.value : '',
            selectedReport: newValue?.reportname ? newValue.reportname : '',
        });
    }

    handleFieldExpansion = (selectedGroup, selectedType, filterCategoryType = null) => {
        let filterCriteria = [...this.state.filterCriteria];
        let criteria = this.state.filterCriteria?.find(item =>
            item?.filterGroup === selectedGroup && item?.filterType === this.state.selectedType &&
            item?.filterCategoryType === filterCategoryType?.type
        );
        let filterCriteriaIndex = this.state.filterCriteria?.indexOf(criteria);
        filterCriteria[filterCriteriaIndex]?.filters?.map(item => {
            if (item?.groupField === selectedGroup && item?.filterField === selectedType) {
                item.isExpand = false;
            }
            return item;
        })
        this.setState({ filterCriteria: filterCriteria })
    }

    handleFilterTypeAndValue = (filterValue, subFilterValue, globalSearchFilters) => {
        let newFilterCriteria = [...this.state.filterCriteria];
        newFilterCriteria[0].filters[0].filterType = globalSearchFilters?.filterType?.value;
        newFilterCriteria[0].filters[0][globalSearchFilters.filterValueKey] = globalSearchFilters?.searchKey;
        this.setState({ filterCriteria: newFilterCriteria }, () => {
            this.prepareQuery();
        })
    }

    handleFilter = (filterValue, subFilterValue, filterCategoryType = null, globalSearchFilters = null) => {
        let newCriteria = [...this.state.filterCriteria]
        let mainObject = {
            filterGroup: filterValue?.value,
            filterType: subFilterValue?.value,
            filterCategoryType: filterCategoryType?.type,
            // isExpand: true,
            filters: [{ ...newFilterRecord }]
        }
        if (!newCriteria?.find(item =>
            item?.filterType === subFilterValue?.value && item?.filterGroup === filterValue?.value &&
            item?.filterCategoryType === filterCategoryType?.type)
        ) {
            newCriteria?.push({ ...mainObject });
        }
        if (filterValue?.value === 'Talent' && this.state.entity && this.props.history.location.state) {
            filterValue.text = 'Talent'
        }
        let filteredNewCriteria = newCriteria?.find(item => item?.filterGroup === filterValue?.value && item?.filterType === subFilterValue?.value && item?.filterCategoryType === filterCategoryType?.type);
        let filterIndex = newCriteria?.indexOf(filteredNewCriteria);
        if (newCriteria[filterIndex]?.filters?.length == 0) {
            newCriteria[filterIndex]?.filters?.push({ ...newFilterRecord })
        }
        if (newCriteria[filterIndex]?.filters?.length === 1 && newCriteria[filterIndex]?.filters[0]?.groupField === null) {
            newCriteria[filterIndex].filters[0].groupField = filterValue?.value;
            newCriteria[filterIndex].filters[0].groupFieldSelected = filterValue;
            newCriteria[filterIndex].filters[0].option1 = this.state.selectedFilter?.find(item => item?.value === filterValue?.value)?.filterCategories;
            newCriteria[filterIndex].filters[0].filterField = subFilterValue?.value;
            newCriteria[filterIndex].filters[0].filterFieldSelected = subFilterValue;
            newCriteria[filterIndex].filters[0].filterType = null;
            if (subFilterValue?.type === 'Select' || subFilterValue?.type === 'Multi Select') {
                newCriteria[filterIndex].filters[0].option2 = this.state[subFilterValue?.optionName];
                newCriteria[filterIndex].filters[0].filterTypeOptions = filterTypeSelectOptions
                newCriteria[filterIndex].filters[0].filterType = filterTypeSelectOptions[0].value;
                newCriteria[filterIndex].filters[0].filterTypeSelected = filterTypeSelectOptions[0];
            }
            if (subFilterValue?.type === 'Search') {
                newCriteria[filterIndex].filters[0].filterTypeOptions = filterTypeTextOptions?.filter(item =>
                    item?.value !== 'contains' && item?.value !== 'startsWith');
                newCriteria[filterIndex].filters[0].filterType = filterTypeSelectOptions[0].value;
                newCriteria[filterIndex].filters[0].filterTypeSelected = filterTypeSelectOptions[0];
            }
            if (subFilterValue?.type === 'Date' || subFilterValue?.type === 'Radio Number Range') {
                newCriteria[filterIndex].filters[0].filterTypeOptions = filterTypeDateOptions;
                newCriteria[filterIndex].filters[0].filterType = filterTypeDateOptions[0].value;
                newCriteria[filterIndex].filters[0].filterTypeSelected = filterTypeDateOptions[0];
            }
            if (subFilterValue?.type === 'Text') {
                newCriteria[filterIndex].filters[0].filterTypeOptions = subFilterValue.value === 'adjusted_representative_address' ?
                    filterTypeTextOptions.filter(item => item.value != '=') :
                    subFilterValue.value === 'synopsis' || subFilterValue.value === 'comparison' || subFilterValue.value === 'comments' ?
                        filterTypeTextOptions.filter(item => item.value === 'contains') : filterTypeTextOptions;
                newCriteria[filterIndex].filters[0].filterType = newCriteria[filterIndex].filters[0].filterTypeOptions[0].value;
                newCriteria[filterIndex].filters[0].filterTypeSelected = newCriteria[filterIndex].filters[0].filterTypeOptions[0];
            }
            if (subFilterValue?.type === 'Large Text') {
                newCriteria[filterIndex].filters[0].filterTypeOptions = filterTypeLargeTextOptions;
                newCriteria[filterIndex].filters[0].filterType = newCriteria[filterIndex].filters[0].filterTypeOptions[0].value;
                newCriteria[filterIndex].filters[0].filterTypeSelected = newCriteria[filterIndex].filters[0].filterTypeOptions[0];
            }
            if (subFilterValue?.range) {
                newCriteria[filterIndex].filters[0].filterTypeOptions = filterTypeRangeOptions;
                newCriteria[filterIndex].filters[0].filterType = filterTypeRangeOptions[0].value;
                newCriteria[filterIndex].filters[0].filterTypeSelected = filterTypeRangeOptions[0];
            }
            if (subFilterValue?.text === 'Page Count/WB Page Count') {
                newCriteria[filterIndex].filters[0].filterTypeOptions = filterTypePgsOptions;
                newCriteria[filterIndex].filters[0].filterType = filterTypePgsOptions[0].value;
                newCriteria[filterIndex].filters[0].filterTypeSelected = filterTypePgsOptions[0];
            }
            if (subFilterValue?.isMandatoryField) {
                newCriteria[filterIndex].filters[0].filterTypeOptions = newCriteria[filterIndex].filters[0].filterTypeOptions?.filter(item =>
                    item?.value !== 'anyValueEntered' && item?.value !== 'noValueEntered'
                )
            }
            newCriteria[filterIndex].filters[0].range = subFilterValue?.range
            newCriteria[filterIndex].filters[0].type = subFilterValue?.type
            newCriteria[filterIndex].filters[0].value1 = null
            newCriteria[filterIndex].filters[0].value1selected = null
            newCriteria[filterIndex].filters[0].value2 = null
            newCriteria[filterIndex].filters[0]['isExpand'] = true
            if (subFilterValue?.type === 'Multi Select' && subFilterValue?.tableName !== 'USER' &&
                subFilterValue?.tableName !== 'USER_DIVISIONS' && subFilterValue?.tableName !== 'DEAL_STEPS'
            ) {
                this.getStaticData(subFilterValue?.tableName, subFilterValue?.optionName);
            }
            if ((subFilterValue?.type === 'Select' ||
                subFilterValue?.type === 'Multi Select') && (subFilterValue?.tableName === 'USER' ||
                    subFilterValue?.tableName === 'USER_DIVISIONS' || subFilterValue?.tableName === 'DEAL_STEPS')
            ) {
                this.fetchSearchDetails(subFilterValue, '');
            }
        }
        // newCriteria[filterIndex].filters = newCriteria[filterIndex].filters?.map(item => {
        //     if (item?.filterField === subFilterValue?.value && item?.groupField == filterValue?.value) {
        //         item.isExpand = true
        //     }
        //     return item;
        // })
        newCriteria?.map((item, index) => {
            item?.filters?.map(item1 => {
                if (index === filterIndex) {
                    item1.isExpand = true
                } else {
                    item1.isExpand = false
                }
                return item;
            })
        })
        this.setState({ filterCriteria: newCriteria }, () => {
            if (globalSearchFilters) {
                this.handleFilterTypeAndValue(globalSearchFilters)
            }
            let filterCriteriaValues = [...this.state.filterCriteriaValues];
            this.state.filterCriteria?.map(item => {
                item?.filters?.map((item1, index) => {
                    filterCriteriaValues?.push(item1)
                })
            })
            const uniqueFilterCriteria = [...new Set(filterCriteriaValues.map(item => item))];
            this.setState({ filterCriteriaValues: uniqueFilterCriteria })
        })
    }

    handleFilterType = (filterCriteria, filterValue, criteriaIndex) => {
        let newFilterCriteria = [... this.state.filterCriteria]
        newFilterCriteria[criteriaIndex].filters = newFilterCriteria[criteriaIndex]?.filters?.map(item => {
            item.filterType = filterValue;
            item.filterTypeSelected = item?.filterTypeOptions?.find(item => item?.value === filterValue);
            if (filterCriteria?.type === 'Date' || filterCriteria?.type === 'Radio Number Range') {
                if (item.filterType === '<') {
                    item['isDisableField1'] = true;
                    item['isDisableField2'] = false;
                    item['isDisableField'] = false;
                } else if (item.filterType === '>') {
                    item['isDisableField1'] = false;
                    item['isDisableField2'] = true;
                    item['isDisableField'] = false;
                } else if (item.filterType === 'anyValueEntered' || item.filterType == 'noValueEntered') {
                    item['isDisableField1'] = false;
                    item['isDisableField2'] = false;
                    item['isDisableField'] = true;
                    item.value1 = item.filterType === 'anyValueEntered' ? 'is not null' : 'is null';
                    this.prepareQuery()
                } else {
                    item.isDisableField = false;
                }
            } else {
                if (item.filterType === 'anyValueEntered' || item.filterType == 'noValueEntered') {
                    item['isDisableField'] = true;
                    item['isDisableField1'] = false;
                    item['isDisableField2'] = false;
                    item.value1 = item.filterType === 'anyValueEntered' ? 'is not null' : 'is null';
                    this.prepareQuery()
                } else {
                    item['isDisableField'] = false;
                    item['isDisableField1'] = false;
                    item['isDisableField2'] = false;
                }
            }
            return item;
        })
        this.setState({ filterCriteria: newFilterCriteria })
    }

    handleFilterValue = (filterField, value, selectedFilterCriteria, selectedFilterCriteriaIndex, range = false, criteriaIndex) => {
        let newFilterCriteria = [... this.state.filterCriteria]

        newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex][filterField]
        if (newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex].type === 'Search') {
            newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex][filterField] = value?.value || null;
            newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex][filterField + 'selected'] = value
        } else if (newFilterCriteria[criteriaIndex]?.filters[selectedFilterCriteriaIndex]?.type === 'Select') {
            let selectedValue = this.state[selectedFilterCriteria?.filterFieldSelected?.optionName]?.find(item => item?.value === value);
            newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex][filterField] = value || null;
            newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex][filterField + 'selected'] = selectedValue
        } else {
            newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex][filterField] = value
        }
        if (filterField === 'value1' && !range) {
            newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex].value2 = null
        }
        this.setState({ filterCriteria: newFilterCriteria }, () => {
            if (selectedFilterCriteria?.type === 'Date') {
                if (selectedFilterCriteria?.filterType === 'between' &&
                    newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex]?.value1 && newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex]?.value2
                ) {
                    this.prepareQuery()
                } else if (selectedFilterCriteria?.filterType === '>' && newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex]?.value1) {
                    this.prepareQuery()
                } else if (selectedFilterCriteria?.filterType === '<' && newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex]?.value2) {
                    this.prepareQuery()
                } else {
                    return;
                }
            } else if (selectedFilterCriteria?.type === 'Radio Number Range') {
                if (newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex]?.value1 &&
                    newFilterCriteria[criteriaIndex].filters[selectedFilterCriteriaIndex]?.value2) {
                    this.prepareQuery()

                } else {
                    return;
                }
            } else if (selectedFilterCriteria?.type === 'Large Text' || selectedFilterCriteria?.type === 'Text') {
                return;
            } else {
                this.prepareQuery()
            }
        })
    }

    getMultiSelectArrayDiff = (array1, array2) => {
        return array1.filter(object1 => {
            return !array2.some(object2 => {
                return object1.value === object2.value;
            });
        });
    }

    onChangeMultiSelectCallBack = (filterField, value, selectedFilterCriteria, criteriaIndex) => {
        let newValue = value.filter((value, index, arr) =>
            index === arr.findIndex((item) => (
                item.value === value.value)
            )
        )
        let newFilterCriteria = [...this.state.filterCriteria];
        if (newValue?.length === 1 && newFilterCriteria[criteriaIndex]?.filters[0].value1selected == null) {
            newFilterCriteria[criteriaIndex].filters[0][filterField] = newValue[0]?.value || null;
            newFilterCriteria[criteriaIndex].filters[0][filterField + 'selected'] = newValue;
        } else if (newValue?.length < newFilterCriteria[criteriaIndex]?.filters[0].value1selected?.length) {
            const filteredRemovedItem = [
                ...this.getMultiSelectArrayDiff(selectedFilterCriteria?.value1selected, newValue),
                ...this.getMultiSelectArrayDiff(newValue, selectedFilterCriteria?.value1selected),
            ];
            newFilterCriteria[criteriaIndex].filters = newFilterCriteria[criteriaIndex]?.filters?.filter(item => item?.value1 !== filteredRemovedItem[0]?.value);
            newFilterCriteria[criteriaIndex]?.filters?.map(item => {
                item.value1selected = newValue;
                return item;
            })
        } else {
            let filterCriteria = { ...selectedFilterCriteria };
            newValue?.map((item, itemIndex) => {
                if (itemIndex > 0 &&
                    !newFilterCriteria[criteriaIndex]?.filters?.find(item1 =>
                        item1?.groupField === selectedFilterCriteria?.groupField &&
                        item1?.filterField === selectedFilterCriteria?.filterField &&
                        item1?.type === 'Multi Select' &&
                        item1?.value1 === item?.value)
                ) {
                    filterCriteria[filterField] = item?.value || null;
                    filterCriteria[filterField + 'selected'] = value;
                    newFilterCriteria[criteriaIndex]?.filters?.map(filter => {
                        filter[filterField + 'selected'] = value;
                        return filter;
                    })
                    newFilterCriteria[criteriaIndex]?.filters?.push(filterCriteria);
                }
            })
        }
        this.setState({ filterCriteria: newFilterCriteria }, () => {
            if ((this.state.filterCriteria?.length == 1 && !this.state.filterCriteria?.[0]?.filters?.[0]?.value1) || newValue?.length === 0) {
                this.setState({ list: [], isLoadingSets: false, queryString: "", count: 0, filterCriteria: [] })
            } else {
                this.prepareQuery();
            }
        })
    }

    addActionCallback = (fieldType, filterItem, selectedFieldIndex, criteriaIndex) => {
        let initialFilterDetails = { ...filterItem };

        let filterCriteria = [...this.state.filterCriteria];
        initialFilterDetails.value1 = null;
        if (filterItem?.type === 'Date') {
            initialFilterDetails.value2 = null;
        }
        initialFilterDetails.value1selected = null;
        initialFilterDetails.index = this.state.fieldIndex;
        initialFilterDetails.canRemove = true;
        filterCriteria[criteriaIndex]?.filters?.push(initialFilterDetails);
        if (filterItem?.type === 'Large Text' || filterItem?.type === 'Text') {
            filterCriteria[criteriaIndex].filters[selectedFieldIndex]['valueAdded'] = true;
        }

        this.setState({ fieldIndex: this.state.fieldIndex + 1, filterCriteria: filterCriteria }, () => {
            if (filterItem?.type === 'Large Text' || filterItem?.type === 'Text') {
                this.prepareQuery();
            }
        })
    }

    removeActionCallback = (fieldType, filterItem, selectedFieldIndex, criteriaIndex) => {
        let filterCriteria = [...this.state.filterCriteria];
        filterCriteria[criteriaIndex].filters = filterCriteria[criteriaIndex]?.filters?.filter(item =>
            item?.groupField === filterItem?.groupField && item?.filterField === filterItem?.filterField &&
            item?.index !== filterItem?.index && item?.type === filterItem?.type
        )
        if (filterCriteria[criteriaIndex].filters?.length == 0) {
            filterCriteria[criteriaIndex]?.filters?.push({ ...newFilterRecord })
        }
        this.setState({ filterCriteria: filterCriteria }, () => {
            if (this.state.filterCriteria?.length == 1 && !this.state.filterCriteria[0]?.filters[0]?.value1) {
                this.setState({ list: [], isLoadingSets: false, queryString: "", count: 0, filterCriteria: [] })
            } else {
                this.prepareQuery();
            }
        })
    }

    getFilterValues = (selectedGroup, filterCategoryType = null) => {
        let criteria = this.state.filterCriteria?.find(item =>
            item?.filterGroup === selectedGroup && item?.filterType === this.state.selectedType
        );
        let filterCriteriaIndex = this.state.filterCriteria?.indexOf(criteria);

        let filteredCriteria = this.state.filterCriteria[filterCriteriaIndex]?.filters?.find(item => item?.groupField === selectedGroup && item?.filterField === this.state.selectedType)
        let searchFilterCriteria = this.state.filterCriteria[filterCriteriaIndex]?.filters?.filter?.(item =>
            item?.groupField === selectedGroup && item?.filterField === this.state.selectedType && item?.type === filteredCriteria?.type);

        switch (filteredCriteria?.type) {
            case 'Boolean': return <>
                {searchFilterCriteria?.map((item, index) => (
                    <MDBRow className={`${this.state.openAllCriteria ? 'filter-field-padding' : ''}`}>
                        <MDBCol md={6}>
                            <RadioGroup
                                aria-label="Classification"
                                value={item?.value1}
                                onChange={(e) => this.handleFilterValue('value1', e.target.value, item, index, false, filterCriteriaIndex)}
                                name="radio-buttons-group"
                                class={'alignRadioButtons'}>
                                <FormControlLabel value="1" control={<Radio size="small" />} label="Yes" />
                                <FormControlLabel value="0" control={<Radio size="small" />} label="No" />
                            </RadioGroup>
                        </MDBCol>
                        <MDBCol md={2} className="actionButons d-inline-block">
                            <MDBRow>
                                <MDBCol md={1} className="removeActionIcon p-0">
                                    {
                                        (item?.value1?.length > 0) ?
                                            <MDBIcon
                                                icon="trash-alt"
                                                className="float-right"
                                                onClick={() => this.removeActionCallback("Boolean", item, index, filterCriteriaIndex)}
                                            />
                                            : null
                                    }
                                </MDBCol>
                                <MDBCol md={1} className={`${this.state.openAllCriteria ? 'addIcon' : ''} addIconMargin p-0`}>
                                    {
                                        (item?.value1?.length > 0 && index === (searchFilterCriteria?.length - 1)) ?
                                            <AddCircleIcon
                                                onClick={(e) => this.addActionCallback("Boolean", item, index, filterCriteriaIndex)}
                                            /> : null
                                    }
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                ))}
            </>
            case 'Text': return <>
                <div className={`${this.state.openAllCriteria ? 'filter-field-padding' : ''}`}>
                    <div className="radio-options">
                        <RadioGroup
                            value={filteredCriteria?.filterTypeSelected?.value}
                            onChange={(e) => this.handleFilterType(filteredCriteria, e.target.value, filterCriteriaIndex)}
                        >
                            {filteredCriteria?.filterTypeOptions?.map(option =>
                                <FormControlLabel value={option?.value} control={<Radio size="small" />} label={option?.text} />
                            )}
                        </RadioGroup>
                    </div>
                    {searchFilterCriteria?.map((item, index) => (
                        <MDBRow className="field-margin text-field">
                            <MDBCol md={10}>
                                {(item?.valueAdded && item?.value1) ?
                                    <BasicLabel text={item?.value1} type="text" /> :
                                    <BasicTextField
                                        label=""
                                        value={item?.value1}
                                        placeholder={"Enter text to search"}
                                        onChange={(e) => this.handleFilterValue('value1', e.target.value, item, index, false, filterCriteriaIndex)}
                                    />
                                }
                            </MDBCol>
                            <MDBCol md={2} className="actionButons d-inline-block">
                                <MDBRow>
                                    <MDBCol md={1} className="removeActionIcon text-delete-icon p-0">
                                        {
                                            (item?.valueAdded && item?.value1?.length > 0) ?
                                                <MDBIcon
                                                    icon="trash-alt"
                                                    className="float-right"
                                                    onClick={() => this.removeActionCallback("Text", item, index, filterCriteriaIndex)}
                                                />
                                                : null
                                        }
                                    </MDBCol>
                                    <MDBCol md={1} className={`${this.state.openAllCriteria ? 'addIcon' : ''} addIconMargin p-0`}>
                                        {
                                            (!item?.valueAdded && item?.value1?.length > 0) ?
                                                <AddCircleIcon
                                                    onClick={(e) => this.addActionCallback("Text", item, index, filterCriteriaIndex)}
                                                /> : null
                                        }
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    ))}
                </div>
            </>
            case 'Radio Number Range': return <>
                <div className={`${this.state.openAllCriteria ? 'filter-field-padding' : ''}`}>
                    <div className="radio-options">
                        <RadioGroup
                            value={filteredCriteria?.filterTypeSelected?.value}
                            onChange={(e) => this.handleFilterType(filteredCriteria, e.target.value, filterCriteriaIndex)}
                        >
                            {filteredCriteria?.filterTypeOptions?.map(option =>
                                <FormControlLabel value={option?.value} control={<Radio size="small" />} label={option?.text} />
                            )}
                        </RadioGroup>
                    </div>
                    {searchFilterCriteria?.map((item, index) => (
                        <MDBRow className="field-margin">
                            <MDBCol md={10}>
                                <MDBRow>
                                    <MDBCol md={6} className="pr-0 min-range">
                                        <BasicTextField
                                            label=""
                                            value={item?.value1}
                                            placeholder={"Min"}
                                            disabled={(item?.isDisableField1 || item?.isDisableField) || false}
                                            onChange={(e) => this.handleFilterValue('value1', e.target.value, item, index, false, filterCriteriaIndex)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6} className="pl-0 max-range">
                                        <BasicTextField
                                            label=""
                                            value={item?.value2}
                                            placeholder={"Max"}
                                            disabled={(item?.isDisableField2 || item?.isDisableField) || false}
                                            onChange={(e) => this.handleFilterValue('value2', e.target.value, item, index, false, filterCriteriaIndex)}
                                        />
                                    </MDBCol>
                                </MDBRow>

                            </MDBCol>
                            <MDBCol md={2} className="actionButons d-inline-block">
                                <MDBRow>
                                    <MDBCol md={1} className="removeActionIcon p-0">
                                        {
                                            (item?.value1?.length > 0) ?
                                                <MDBIcon
                                                    icon="trash-alt"
                                                    className="float-right"
                                                    onClick={() => this.removeActionCallback("Radio Number Range", item, index, filterCriteriaIndex)}
                                                />
                                                : null
                                        }
                                    </MDBCol>
                                    <MDBCol md={1} className={`${this.state.openAllCriteria ? 'addIcon' : ''} addIconMargin p-0`}>
                                        {
                                            (item?.value1?.length > 0 && index === (searchFilterCriteria?.length - 1)) ?
                                                <AddCircleIcon
                                                    onClick={(e) => this.addActionCallback("Radio Number Range", item, index, filterCriteriaIndex)}
                                                /> : null
                                        }
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    ))}
                </div>
            </>
            case 'Date': return <>
                <div className={`${this.state.openAllCriteria ? 'filter-field-padding' : ''}`}>
                    <div className="radio-options">
                        <RadioGroup
                            value={filteredCriteria?.filterTypeSelected?.value}
                            onChange={(e) => this.handleFilterType(filteredCriteria, e.target.value, filterCriteriaIndex)}
                        >
                            {filteredCriteria?.filterTypeOptions?.map(option =>
                                <FormControlLabel value={option?.value} control={<Radio size="small" />} label={option?.text} />
                            )}
                        </RadioGroup>
                    </div>
                    {searchFilterCriteria?.map((item, index) => (
                        <MDBRow className="field-margin">
                            <MDBCol md={10}>
                                <MDBRow>
                                    <MDBCol md={6} className="pr-0 start-date-field">
                                        <DateField
                                            value={item?.value1}
                                            disabled={(item?.isDisableField1 || item?.isDisableField) || false}
                                            onChange={(e) => this.handleFilterValue('value1', e.target.value, item, index, false, filterCriteriaIndex)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6} className="pl-0 end-date-field">
                                        <DateField
                                            value={item?.value2}
                                            disabled={(item?.isDisableField2 || item?.isDisableField) || false}
                                            onChange={(e) => this.handleFilterValue('value2', e.target.value, item, index, false, filterCriteriaIndex)} />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={2} className="actionButons d-inline-block">
                                <MDBRow>
                                    <MDBCol md={1} className="removeActionIcon p-0">
                                        {
                                            (item?.value1?.length > 0 || item?.value2?.length > 0) ?
                                                <MDBIcon
                                                    icon="trash-alt"
                                                    className="float-right"
                                                    onClick={() => this.removeActionCallback("Date", item, index, filterCriteriaIndex)}
                                                />
                                                : null
                                        }
                                    </MDBCol>
                                    <MDBCol md={1} className={`${this.state.openAllCriteria ? 'addIcon' : ''} addIconMargin p-0`}>
                                        {
                                            ((item?.value1?.length > 0 || item?.value2?.length > 0) && index === (searchFilterCriteria?.length - 1)) ?
                                                <AddCircleIcon
                                                    onClick={(e) => this.addActionCallback("Date", item, index, filterCriteriaIndex)}
                                                /> : null
                                        }
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    ))}
                </div>
            </>
            case 'Select': return <>
                <div className={`${this.state.openAllCriteria ? 'filter-field-padding' : ''}`}>
                    {searchFilterCriteria?.map((item, index) => (
                        <MDBRow className="field-margin">
                            <MDBCol md={10} className="selectField">
                                <SelectField
                                    placeholder={"- Select -"}
                                    value={item?.value1}
                                    options={this.state[item.filterFieldSelected.optionName]}
                                    onChange={(e, newValue) => this.handleFilterValue('value1', e.target.value, item, index, false, filterCriteriaIndex)}
                                    multiple={false}
                                    width={'100%'}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="actionButons d-inline-block">
                                <MDBRow>
                                    <MDBCol md={1} className="removeActionIcon p-0">
                                        {
                                            (item?.value1selected?.label?.length > 0) ?
                                                <MDBIcon
                                                    icon="trash-alt"
                                                    className="float-right"
                                                    onClick={() => this.removeActionCallback("Select", item, index, filterCriteriaIndex)}
                                                />
                                                : null
                                        }
                                    </MDBCol>
                                    <MDBCol md={1} className={`${this.state.openAllCriteria ? 'addIcon' : ''} addIconMargin p-0`}>
                                        {
                                            (item?.value1selected?.label?.length > 0 && index === (searchFilterCriteria?.length - 1)) ?
                                                <AddCircleIcon
                                                    onClick={(e) => this.addActionCallback("Select", item, index, filterCriteriaIndex)}
                                                /> : null
                                        }
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    ))}
                </div>
            </>
            case 'Search': return <>
                <div className={`${this.state.openAllCriteria ? 'filter-field-padding' : ''}`}>
                    <div className="radio-options">
                        <RadioGroup
                            value={filteredCriteria?.filterTypeSelected?.value}
                            onChange={(e) => this.handleFilterType(filteredCriteria, e.target.value, filterCriteriaIndex)}
                        >
                            {filteredCriteria?.filterTypeOptions?.map(option =>
                                <FormControlLabel value={option?.value} control={<Radio size="small" />} label={option?.text} />
                            )}
                        </RadioGroup>
                    </div>
                    {searchFilterCriteria?.map((item, index) => (
                        <MDBRow className="field-margin">
                            <MDBCol md={10} className="noRangeSelectField borderRadiusRight">
                                <SearchSelectField
                                    placeholder={"- Enter text to search -"}
                                    detail_selected={item?.value1selected}
                                    searchSelect={true}
                                    options={this.state[item.filterFieldSelected.optionName]}
                                    searchText={(e) => this.fetchSearchDetails(item, e.target.value)}
                                    valueSelected={(e, newValue) => this.handleFilterValue('value1', newValue, item, index, false, filterCriteriaIndex)}
                                    multiple={false} width={'100%'}
                                    disabled={item?.isDisableField || false}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="actionButons d-inline-block">
                                <MDBRow>
                                    <MDBCol md={1} className="removeActionIcon p-0">
                                        {
                                            (item?.value1selected?.text?.length > 0) ?
                                                <MDBIcon
                                                    icon="trash-alt"
                                                    className="float-right"
                                                    onClick={() => this.removeActionCallback("Search", item, index, filterCriteriaIndex)}
                                                />
                                                : null
                                        }
                                    </MDBCol>
                                    <MDBCol md={1} className={`${this.state.openAllCriteria ? 'addIcon' : ''} addIconMargin p-0`}>
                                        {
                                            (item?.value1selected?.text?.length > 0 && index === (searchFilterCriteria?.length - 1)) ?
                                                <AddCircleIcon
                                                    onClick={(e) => this.addActionCallback("Search", item, index, filterCriteriaIndex)}
                                                /> : null
                                        }
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    ))}
                </div>
            </>
            case 'Large Text': return <>
                <div className={`${this.state.openAllCriteria ? 'filter-field-padding' : ''}`}>
                    <div className="radio-options option-width">
                        <RadioGroup
                            value={filteredCriteria?.filterTypeSelected?.value}
                            onChange={(e) => this.handleFilterType(filteredCriteria, e.target.value, filterCriteriaIndex)}
                        >
                            {filteredCriteria?.filterTypeOptions?.map(option =>
                                <FormControlLabel value={option?.value} control={<Radio size="small" />} label={option?.text} />
                            )}
                        </RadioGroup>
                    </div>
                    {searchFilterCriteria?.map((item, index) => (
                        <MDBRow className="field-margin text-field">
                            <MDBCol md={10}>
                                {(item?.valueAdded && item?.value1) ?
                                    <BasicLabel text={item?.value1} type="text" /> :
                                    <BasicTextArea
                                        label=""
                                        rows={2}
                                        value={item?.value1}
                                        placeholder={"Enter text to search"}
                                        onChange={(e) => this.handleFilterValue('value1', e.target.value, item, index, false, filterCriteriaIndex)}
                                        disabled={item?.isDisableField || false}
                                    />
                                }
                            </MDBCol>
                            <MDBCol md={2} className="actionButons d-inline-block">
                                <MDBRow>
                                    <MDBCol md={1} className="removeActionIcon text-delete-icon p-0">
                                        {
                                            (item?.valueAdded && item?.value1?.length > 0) ?
                                                <MDBIcon
                                                    icon="trash-alt"
                                                    className="float-right"
                                                    onClick={() => this.removeActionCallback("Large Text", item, index, filterCriteriaIndex)}
                                                />
                                                : null
                                        }
                                    </MDBCol>
                                    <MDBCol md={1} className={`${this.state.openAllCriteria ? 'addIcon' : ''} addIconMargin p-0`}>
                                        {
                                            (!item?.valueAdded && item?.value1?.length > 0) ?
                                                <AddCircleIcon
                                                    onClick={(e) => this.addActionCallback("Large Text", item, index, filterCriteriaIndex)}
                                                /> : null
                                        }
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    ))}
                </div>
            </>
            case 'Multi Select': return <>
                <div className={`${this.state.openAllCriteria ? 'filter-field-padding' : ''}`}>
                    <div className="radio-options">
                        <RadioGroup
                            value={filteredCriteria?.filterTypeSelected?.value}
                            onChange={(e) => this.handleFilterType(filteredCriteria, e.target.value, filterCriteriaIndex)}
                        >
                            {filteredCriteria?.filterTypeOptions?.map(option =>
                                <FormControlLabel value={option?.value} control={<Radio size="small" />} label={option?.text} />
                            )}
                        </RadioGroup>
                    </div>
                    <div
                        md={6}
                        className={`${filteredCriteria?.value1selected?.length === 0 ? "searchSelectHeight multiSelectSearchSelect" : "multiSelectSearchSelect"}`}
                        id='multiSelectDropDown'
                    >
                        <SearchSelectField
                            multiple={true}
                            value={filteredCriteria?.value1selected || []}
                            options={this.state[filteredCriteria.filterFieldSelected.optionName]}
                            width={"100%"}
                            onChange={(e, value) => this.onChangeMultiSelectCallBack('value1', value, filteredCriteria, filterCriteriaIndex)}
                        />
                    </div>
                </div>
            </>
            default: return <MDBCol>Not a Valid Type</MDBCol>
        }
        // }
    }

    fetchSearchDetails = (fieldDetails, value) => {
        let tableName = fieldDetails?.filterFieldSelected?.tableName || fieldDetails?.tableName;
        let groupName = this.state.submittedToGroupName;
        let url = fieldDetails?.filterFieldSelected?.fieldName ==='submittedTo' ? `/entitySearch?entity=${tableName}&groupName=${groupName}&isinacUser=1&searchString=${value}` : fieldDetails?.filterFieldSelected?.fieldName === 'coveragesAnalyst' ? `/entitySearch?entity=${tableName}&permissionName="Assignable (and view submission)/Submit Coverages"&isinacUser=1&searchString=${value}` :`/entitySearch?entity=${tableName}&searchString=${value}` ;
        ScripterService.getData(Constants.scripterServiceBaseUrl +
            url,
            this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (tableName === "TALENT_REP") {
                    let formattedList = response?.data?.map(item => ({ value: item['id'], text: item['name'] + ' - ' + item['type'] }));
                    this.setState({ [fieldDetails.filterFieldSelected.optionName]: formattedList });

                } else if (tableName === "USER_DIVISIONS") {
                    let formattedList = response?.data?.map(item => ({
                        value: item?.id,
                        label: item?.name
                    }));
                    this.setState({ [fieldDetails?.filterFieldSelected?.optionName || fieldDetails?.optionName]: formattedList });
                } else if (tableName === "DEAL_STEPS") {
                    let formattedList = response?.data?.map(item => ({
                        value: item?.deal_memo_id,
                        text: item?.step_name
                    }));
                    this.setState({ [fieldDetails?.filterFieldSelected?.optionName || fieldDetails?.optionName]: formattedList });
                } else if (tableName === 'TALENT') {
                    let formattedList = response?.data?.map(item => ({
                        value: item[tableName?.toLowerCase() + '_id'],
                        text: item[tableName.toLowerCase() + '_name'],
                        writer_team: item?.writer_team
                    }));
                    this.setState({ [fieldDetails?.filterFieldSelected?.optionName || fieldDetails?.optionName]: formattedList });
                } else if (this.state.entity === 'Projects' && tableName === 'PROJECT') {
                    let formattedList = response?.data?.map(item => ({
                        value: item[tableName?.toLowerCase() + '_id'],
                        text: item[tableName.toLowerCase() + (tableName.toLowerCase() === 'project' ? '_title' : '_name')] + (item?.aka ? ` + ${item?.aka}` : '')
                    }));
                    this.setState({ [fieldDetails?.filterFieldSelected?.optionName || fieldDetails?.optionName]: formattedList });
                } else {
                    let formattedList = response?.data?.map(item => ({
                        value: item[tableName?.toLowerCase() + '_id'],
                        text: item[tableName.toLowerCase() === 'submission' ? 'title' : tableName.toLowerCase() + (tableName.toLowerCase() === 'project' ? '_title' : '_name')]
                    }));
                    this.setState({ [fieldDetails?.filterFieldSelected?.optionName || fieldDetails?.optionName]: formattedList });

                }

            },
                (err) => {

                })
    }

    getSearchParams = () => {
        if (this.state.openAllCriteria) {
            allCriteriaConfig?.map(item => {
                let temp = {};
                item?.filters?.map(item1 => {
                    item1?.filterCategoryTypes?.map(item2 => {
                        item2?.filterCategories?.map(field => {
                            temp[field.fieldName] = null;
                        })
                    })
                    temp['page_count'] = 10;
                    temp['page_no'] = 1;
                    this.setState({ searchGroupParams: item1?.filterCategoryTypes, searchGroupJson: temp }, () => {
                        this.getAPIDetails(this.state.searchGroupParams);
                    });
                })
            })
        } else {
            searchGroups.map(item => {
                if (item.type === this.state.entity) {
                    let temp = {};
                    item.filters.map(data => {
                        data.filterCategories?.map(field => {
                            temp[field.fieldName] = null;
                        });
                    });
                    temp['page_count'] = 10;
                    temp['page_no'] = 1;
                    console.log(temp);
                    this.setState({ searchGroupParams: item.filters, searchGroupJson: temp }, () => {
                        this.getAPIDetails(this.state.searchGroupParams);
                    });
                }
            })
        }
    }

    getStaticData = (tableName, optionName) => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=' + tableName, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, text: item.name, is_active: item.is_active }));
                this.setState({ [optionName]: formattedList });
            },
                (err) => {

                })
    }

    getAPIDetails = (details) => {
        details.map(fields => {
            fields.filterCategories.map(data => {
                if (data.type === "Select") {
                    if (data.tableName != 'none') {
                        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=' + data.tableName, this.props.userContext?.active_tenant?.tenant_id)
                            .then(response => {
                                let formattedList = response.data?.map(item => ({ value: item.id, text: item.name, is_active: item.is_active }));
                                if (data.tableName === 'lu_wga_type' || data.tableName === 'lu_talent_roles' || data.tableName === 'lu_recommendation' ||
                                    data.tableName === 'lu_writer_recommendation' || data.tableName === 'lu_material_type' || data.tableName === 'lu_element_status' ||
                                    data.tableName === 'lu_format'
                                ) {
                                    formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                                }
                                this.setState({ [data.optionName]: formattedList });
                            },
                                (err) => {

                                })
                    } else if (data.tableName === 'none') {
                        this.setState({ [data.optionName]: this.state?.genericDetails });
                    } else if (data.tableName === 'submission') {
                        this.setState({ [data.optionName]: this.state?.submissionDetails });
                    }
                }
            })
        })
    }


    handleColumns = (event) => {
        this.setState({
            setColumns: event.currentTarget
        })
    };

    handleCloseColumns = () => {
        this.setState({
            setColumns: null
        })
    };

    divideArrayToChunks = (chunksize, array) => {
        return array?.map((e, i) => {
            return i % chunksize === 0 ? array?.slice(i, i + chunksize) : null;
        })?.filter(e => { return e; });
    }

    onColumnCheck = (element, index, checked) => {
        let config = { ...this.state.config };
        let count = config.headings?.filter(item => item.isColumn === true).length;
        if (count > 9 && checked) {
            this.setState({ showCols: true })
        } else if (count <= 2 && !checked) {
            this.setState({ showMinCols: true })
        }
        else {
            //Headings filter will changes here
            config.headings[index].isColumn = checked;
            let filteredColumns = config.headings.filter(item => item.width === "100%");
            if (checked && filteredColumns?.length < 10) {
                config.headings[filteredColumns?.length].width = "100%";
            }
            this.setState({ config: config })
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage, isLoadingSets: true
        }, () => {
            this.getSearchResults(this.state.queryString);
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10), page: 0, isLoadingSets: true
        }, () => {
            this.getSearchResults(this.state.queryString);
        });
    }

    handleFilterLabel = (filter) => {
        console.log("check the filter over here", filter);
        let filterLabel = null;
        let fieldName = filter?.filterFieldSelected?.fieldName ?
            filter?.filterFieldSelected?.fieldName?.replace(/([A-Z])/g, " $1") :
            filter?.filterFieldSelected?.text;
        fieldName = fieldName?.charAt(0)?.toUpperCase() + fieldName?.slice(1);

        if (filter?.type === 'Search' || filter?.type === 'Select') {
            filterLabel = fieldName + ': ' + (filter?.value1selected?.text || filter?.value1selected?.label)
        } else if (filter?.type === 'Multi Select') {
            let filterValue = filter?.value1selected?.find(item => item?.value === filter?.value1);
            filterLabel = fieldName + ': ' + filterValue?.text;
        } else if (filter?.type === 'Date') {
            if (filter?.filterType === '>') {
                filterLabel = fieldName + ': ' + '> ' + filter?.value1;
            } else if (filter?.filterType === '<') {
                filterLabel = fieldName + ': ' + '< ' + filter?.value2;
            } else if (filter?.filterType === 'between') {
                filterLabel = fieldName + ': ' + 'between ' + filter?.value1 + ' & ' + filter?.value2;
            }
        } else if (filter?.type === 'Large Text') {
            filterLabel = fieldName + ': ' + (filter?.value1?.length >= 30 ? filter?.value1.slice(0, 30) + "..." : filter?.value1);
        } else {
            filterLabel = fieldName + ': ' + filter?.value1;
        }
        if (filter?.filterType === 'anyValueEntered' || filter?.filterType === 'noValueEntered') {
            filterLabel = fieldName + ': ' + filter?.value1;
        }
        return filterLabel;
    }

    handleFilterDelete = (filter, criteriaIndex) => {
        let filterCriteria = [...this.state.filterCriteria];
        let index = filterCriteria[criteriaIndex]?.filters?.indexOf(filter);
        if (index > -1) {
            filterCriteria[criteriaIndex]?.filters?.splice(index, 1);
        }
        this.setState({ filterCriteria: filterCriteria }, () => {
            if (this.state.filterCriteria[criteriaIndex]?.filters?.length > 0) {
                this.prepareQuery();
            } else {
                if (this.state.filterCriteria?.length == 1) {
                    this.setState({ list: [], isLoadingSets: false, count: 0, queryString: "" })
                } else {
                    let newFilteredCriteria = [...this.state.filterCriteria];
                    newFilteredCriteria = newFilteredCriteria?.filter(item => item?.filters?.length > 0);
                    this.setState({ filterCriteria: newFilteredCriteria }, () => {
                        this.prepareQuery();
                    })
                }
            }
        })
    }

    handleSaveSearchCriteria = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleDeleteSearchCriteria = () => {
        this.setState({ isDeletingSearch: true })
        let searchItem = this.state.savedSearches?.find(item => item?.advance_search_query_id === this.state.savedSearch);
        let postJson = { ...searchItem };
        postJson['is_delete'] = 1;
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/saveAdvanceSearch', postJson,
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isDeletingSearch: false })
                } else {
                    this.getSavedSearches(this.state.entity);
                    this.setState({
                        isDeletingSearch: false,
                        savedSearch: null,
                        list: [],
                        filterCriteria: [],
                        count: 0
                    })
                }
                (err) => {
                    console.log("Error in saving search:", err)
                    this.setState({ isDeletingSearch: false })
                }
            })
    }

    handleCloseSaveSearch = () => {
        this.setState({ anchorEl: null });
    };

    handleSaveSearchSubmit = () => {
        this.setState({ isSavingSearch: true })
        let postJson = { ...saveSearchJson };
        let json = {
            "where_query": this.state.queryString,
            "from_query": this.state.query,
            "page_no": this.state.page + 1,
            "page_count": this.state.rowsPerPage,
            "saved_search_filter": [...this.state.filterCriteria]
        }
        postJson.name = this.state.searchCriteriaName;
        postJson.user_id = this.props?.userContext?.user_profile?.user_id;
        postJson.query = JSON.stringify(json);
        postJson.entity = this.state.entity;
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/saveAdvanceSearch', postJson,
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isSavingSearch: false })
                } else {
                    this.getSavedSearches(this.state.entity);
                    this.setState({ isSavingSearch: true, anchorEl: null, searchCriteriaName: null })
                }
                (err) => {
                    console.log("Error in saving search:", err)
                    this.setState({ isSavingSearch: false })
                }
            })
    }

    handleOpenCategoryType = (categoryType, categoryIndex) => {
        let selectedFilter = [...this.state.selectedFilter];
        selectedFilter?.map(item => {
            if (item?.value === this.state.selectedGroupType) {
                item.filterCategoryTypes[categoryIndex]['isExpand'] = true
            }
            return item;

        })
        this.setState({
            selectedFilter: selectedFilter,
            selectedFilterCategoryType: categoryType?.type,
            expandCategory: true
        })
    }

    handleCloseCategoryType = (categoryType, categoryIndex) => {
        let selectedFilter = [...this.state.selectedFilter];
        selectedFilter?.map(item => {
            if (item?.value === this.state.selectedGroupType) {
                item.filterCategoryTypes[categoryIndex]['isExpand'] = false
            }
            return item;

        })
        this.setState({ selectedFilter: selectedFilter })
    }

    handleGeneratePdf = async (pdfDetails) => {
        await this.getQueryString('generatePdf');
        // if (!this.validateCannedReportFilters()) {
        //     return;
        // }
        this.setState({ pdfGenerate: true })
        let load = null
        let query = { ...this.state.query };
        if (this.state.selectedReport === 'rptCnVaultStorageByBoxNumber') {
            query['projects'] = 1;
            query['submissions'] = 1;
            query['coverages'] = 1;
            query['submission_format'] = 1;
            query['submission_coverage'] = 1;
            query['submission_author'] = 1;
        } else if (this.state.selectedReport === 'rptCnVaultStorageByProjectTitle') {
            query['submissions'] = 1;
            query['coverages'] = 1;
            query['submission_author'] = 1;
            query['submission_format'] = 1;
            query['submission_coverage'] = 1;
        } else if (this.state.selectedReport === 'rptCnWritingDueAndOutstanding' ||
            this.state.selectedReport === 'rptCnWritingDueBetweenSpecified' ||
            this.state.selectedReport === 'rptCnWritingDueAndDelivered') {
                query['projects'] = 1;
                query['project_executives'] = 1;
                query['deal_steps'] = 1;
        } else if (this.state.selectedReport === 'rptCnDetailedSubmission') {
            query['submission_source_material'] = 1;
            query['submission_submitted_by'] = 1;
        } else if (this.state.selectedReport === 'rptCnDealWithProject' ||
            this.state.selectedReport === 'rptCnDealWithWriterOnly' ||
            this.state.selectedReport === 'rptCnDealWithProjectAndDealNotes' || 
            this.state.selectedReport === 'rptCnDealWithWriterAndDealNotes') {
            query['projects'] = 1;
            query['submissions'] = 1;
        } else if (this.state.selectedReport === 'rptCnProjectSingleTitle') {
            query['submissions'] = 1;
            query['submission_author'] = 1;
            query['submission_source_material'] = 1;
            query['coverages'] = 1;
            query['deals'] = 1;
            query['deal_steps'] = 1;
        }
        let columnNames = {
            'Detailed Submission Report': 'title',
            'Deal Information Report': 'STR_TO_DATE(deal_date,"%m/%d/%Y")',
            'Writing Due Report': 'document_name'
        }
        let json = {
            "where_query": this.state.queryString,
            "from_query": query,
            "order_by_clause": 'ORDER BY ' + (columnNames[this.state.selectedCannedReport] ?? 'project_title') + ' ' + 'ASC',
            "file_name": pdfDetails?.file_name ?
                (pdfDetails?.file_name.replace(this.state.selectedCannedReport, "").trim()) : ''
        };
        if (this.state.selectedReport === 'rptCnProjectSingleTitle' || this.state.selectedReport === 'rptCnProjectDetail') {
            let projectFilters = this.state.filterCriteria?.find(item => item?.filterGroup === 'Project' && item?.filterType === 'project_id')?.filters;
            let projectFilterIds = [];
            projectFilters?.map(item => {
                projectFilterIds?.push(item?.value1);
            })
            json['project_id'] = projectFilterIds?.join(', ') || null;
        }
        if (this.state.selectedReport === 'rptCnLogline' || this.state.selectedReport === 'rptCnProjectOfficer') {
            let projectGenres = this.state.filterCriteria?.find(item => 
                item?.filterGroup === 'Project' && item?.filterType === 'genre_id')?.filters?.filter(item1 => item1.value1 !== null)
            let projectStatuses = this.state.filterCriteria?.find(item => 
                item?.filterGroup === 'Project' && item?.filterType === 'project_status_id')?.filters?.filter(item1 => item1.value1 !== null)
            
            json["is_genre_selected"] = projectGenres?.length > 0 ? 1 : 0,
            json["is_status_selected"] = projectStatuses?.length > 0 ? 1 : 0
        }
        if (this.state.selectedReport === 'rptCnProjectOfficer') {
            let projectExecutives = this.state.filterCriteria?.find(item => 
                item?.filterGroup === 'Project' && item?.filterType === 'executive_id')?.filters?.filter(item1 => item1.value1 !== null)
                
            json["is_executives_selected"] = projectExecutives?.length > 0 ? 1 : 0;
        }
        if (this.state.selectedCannedReport.length != 0) {
            load = {
                "payload": 'generateReport',
                "reportName": this.state.selectedReport,
                "searchJson": btoa(JSON.stringify(json)),
                "showHtml": 0,
                "isStaticReport": 0,
                "encryptionType": 1,
                "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
                "divisionId": this.props?.userContext?.active_tenant?.division_id
            }
        } else {
            load = {
                "payload": 'exportAdvanceSearchPdf',
                "reportName": 'advanceSearchPdf',
                "dataType": this.state.entity,
                "searchJson": btoa(JSON.stringify(json)),
                "showHtml": 0,
                "isStaticReport": 0,
                "encryptionType": 1,
                "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
            }
        }
        ScripterService.getDataWS(load)
            .then(webSocketConn => {
                webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data)
                    if (response?.statusCode == 200) {
                        ScripterService.downloadFile(response.body).then(res => {
                            const url = window.URL.createObjectURL(res.data);
                            const link = document.createElement('a');
                            link.href = url;
                            // link.setAttribute('download', this.state.selectedCannedReport ? pdfDetails?.file_name : this.state.selectedGroup);
                            link.setAttribute('download', pdfDetails?.file_name ? pdfDetails?.file_name :
                                (this.state.selectedCannedReport ? this.state.selectedCannedReport : this.state.selectedGroup))
                            document.body.appendChild(link);
                            link.click();
                            this.setState({ pdfGenerate: false, showExportReportToPdfModal: false })
                        }, (err) => {
                            console.log("File Download Failed:" + err);
                            this.setState({ pdfGenerate: false })
                        });
                    } else {
                        this.setState({ pdfGenerate: false })
                    }
                }
            })
    }

    handleGenerateExcel = (excelDetails) => {
        this.setState({ excelGenerate: true })
        let entity = this.state.entity === 'WGAs' ? 'WGA' : this.state.entity;
        let json = {
            "where_query": this.state.queryString,
            "from_query": this.state.query,
            "file_name": excelDetails?.file_name,
            "page_no": 1,
            "page_count": this.state.count,
        }
        let load = {
            "payload": 'advanceSearch',
            "input": btoa(JSON.stringify(json)),
            "dataType": `${entity}`,
            "exportResults": 1,
            "encryptionType": "1",
            "tenantId": `${this.props?.userContext?.active_tenant?.tenant_id}`,
            "divisionId": `${this.props?.userContext?.active_tenant?.division_id}`,
        }

        ScripterService.getDataWS(load)
            .then(webSocketConn => {
                webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data)
                    console.log('total response after websocket call', e)
                    if (response?.statusCode == 200) {
                        window.open((response?.body)?.replaceAll('"', ''), '_blank');
                        this.setState({ excelGenerate: false, showExportReportToExcelModal: false });
                        this.props.history.push('/advancedSearch');
                    } else {
                        this.setState({ excelGenerate: false })
                    }
                }
            })
    }

    handleNavigation = (item, node) => {
        if (this.state.entity === "Projects") {
            this.props.history.push("/projectDetails/" + item.project_id);
        } else if (this.state.entity === "Deals") {
            this.props.history.push("/dealDetails/" + item.deal_memo_id);
        } else if (this.state.entity === "Submissions") {
            this.props.history.push("/submissionDetails/" + item.submission_id);
        } else if (this.state.entity === "Weekend_Reads") {
            this.props.history.push("/weekendReadDetails/" + item.weekend_read_id);
        } else if (this.state.entity === "Talent") {
            if (item?.talent_group_id) {
                this.props?.handleShowTalentPopup(item.talent_group_id, 1)
            } else {
                this.props?.handleShowTalentPopup(item.talent_id)
            }
        }
    }

    handleAccordionExpansion = () => {
        let selectedFilter = this.state.openAllCriteria ?
            [...this.state.selectedFilter] : [...this.state.selectedFilter];
        selectedFilter[0].isExpand = !selectedFilter[0].isExpand;
        this.setState({ selectedFilter: selectedFilter })
    }

    handleSortColumn = (column, dataNode) => {
        if (dataNode === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(dataNode);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(dataNode);
        })
    }

    handleTableSort = (dataNode) => {
        let isSortByAsc = this.state.isSortByAsc;
        isSortByAsc = this.state.sortCount % 2 === 0 ? true : false,
            this.setState({
                isSortByAsc,
                config: updateTableSortConfigObject(this.state.config, isSortByAsc, this.state.sortBy, dataNode)
            }, () => { this.prepareQuery(dataNode) })
    }

    validateCannedReportFilters = () => {
        let filtersApplied = [];
        let filterCriteria = [...this.state.filterCriteria];
        let selectedCannedReport = selectCannedReports?.find(item => item?.value === this.state.selectedCannedReport);
        filterCriteria?.map(item => {
            item?.filters?.map(item1 => {
                let fieldObject = {}
                fieldObject['group'] = item1?.groupField;
                fieldObject['parameter'] = item1?.filterField
                filtersApplied?.push(fieldObject)
            })
        });
        const filteredItems = [
            ...this.getFiltersArrayDiff(selectedCannedReport?.field, filtersApplied),
            ...this.getFiltersArrayDiff(filtersApplied, selectedCannedReport?.field),
        ];
        if (filteredItems?.length > 0) {
            // this.setState({ showCannedReportError: true })
            return true;
        } else {
            return false;
        }
    }

    getFiltersArrayDiff = (array1, array2) => {
        return array1.filter(object1 => {
            return !array2.some(object2 => {
                return object1.group === object2.group && object1.parameter === object2.parameter;
            });
        });
    }

    validateCannedReport = (group, type, categoryType = null) => {
        let selectedCannedReport = selectCannedReports?.find(item => item?.value === this.state.selectedCannedReport);
        let filteredItem = selectedCannedReport?.field?.filter(item => item?.group === group?.value && item?.parameter === type?.value);
        if (filteredItem?.length > 0) {
            if (this.state.filterCriteria?.find(item =>
                item?.filterGroup === this.state.selectedGroupType && item?.filterType === this.state.selectedType)?.filters?.find(item1 =>
                    item1?.groupField === group?.value && item1?.filterField === type?.value)?.isExpand) {
                this.handleFieldExpansion(group?.value, type?.value)
            } else {
                if (this.state.openAllCriteria) {
                    this.setState({
                        selectedType: type?.value,
                        expandField: true
                    })
                    this.handleFilter(group, type, categoryType)
                } else {
                    this.setState({ selectedType: type?.value });
                    this.handleFilter(group, type, null, null)
                }
            }
        } else {
            this.setState({ showCannedReportError: true })
        }
    }

    getFilterNames = () => {
        if (this.state.selectedCannedReport === 'Detailed Submission Report') {
            return 'Submission Title + AKAs'
        } else if (this.state.selectedCannedReport === 'Deal Information Report') {
            return 'Project Title + AKAs, Writer(s)'
        } else if (this.state.selectedCannedReport === 'Project Officer Report') {
            return 'Executives';
        } else if (this.state.selectedCannedReport === 'Writing Due Report') {
            return 'Writing Start';
        } else if (this.state.selectedCannedReport === 'Project Detail Report' || this.state.selectedCannedReport === 'Project Report') {
            return 'Project Title + AKAs'
        } else if (this.state.selectedCannedReport === 'Logline Report') {
            return 'Logline'
        } else if (this.state.selectedCannedReport === 'Vault Storage Report') {
            return 'Project Title + AKAs, Box# in Submission group'
        }
    }
    getGroups = () => {
        let response = ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=group', null)
            .then(response => {
                let filteredSubmittedToGroups = response.data?.map(item => {
                    if (item?.name !== "Story Analyst" && item?.name !== "User Provisioner") {
                        return item?.name
                    }
                }).filter(item => item !== undefined);
                this.setState({
                    submittedToGroupName: '"' + filteredSubmittedToGroups.join('","') + '"'
                })
            },
                (err) => {
                    console.log("Error in fetching Group Details:", err)
                })
        return response
    }
    render() {
        let open = Boolean(this.state.setPopover);
        let openColumn = Boolean(this.state.setColumns);
        let openSaveSearch = Boolean(this.state.anchorEl);
        let id = open ? 'simple-popover' : undefined;
        let idColumn = openColumn ? 'simple-popover' : undefined;
        let idSaveSearch = openSaveSearch ? 'simple-popover' : undefined;
        let message = this.state.showCols ? this.state.maxColErrorMessage : this.state.showMinCols ? this.state.minColErrorMessage : "";
        let confirmModalTitle = this.state.showCols ? this.state.showMaxCols : this.state.showMinCols ? this.state.showMinColsAlert : "";
        return (
            <MDBContainer fluid className="AdvancedSearchContainer p-0">
                <MessageModal
                    open={this.state.showCannedReportError}
                    title={"Download Report Failure"}
                    message={`Apply valid filters to generate Canned report. Apply the following filters:
                    ${this.getFilterNames()}`}
                    hideCancel={true}
                    primaryButtonText={"OK"}
                    onConfirm={(e) => this.setState({ showCannedReportError: false })}
                />
                {
                    this.state.showExportReportToPdfModal &&
                    <MainViewModalComponent
                        open={this.state.showExportReportToPdfModal}
                        handleClose={() => this.setState({ showExportReportToPdfModal: false })}
                        headerText={"Export Report to PDF"}
                        mode={""}
                        modalName={"Export Report to PDF"}
                        handleGeneratePdf={this.handleGeneratePdf}
                        reportName={this.state.selectedCannedReport}
                        isGeneratingPdf={this.state.pdfGenerate}
                    />
                }
                {
                    this.state.showExportReportToExcelModal &&
                    <MainViewModalComponent
                        open={this.state.showExportReportToExcelModal}
                        handleClose={() => this.setState({ showExportReportToExcelModal: false })}
                        headerText={"Export Report to Excel"}
                        mode={""}
                        modalName={"Export Report to Excel"}
                        handleGenerateExcel={this.handleGenerateExcel}
                        reportName={this.state.selectedCannedReport}
                        isGeneratingExcel={this.state.excelGenerate}
                    />
                }
                <MDBRow>
                    <MDBCol className="header p-0">
                        <BasicLabel type="headerText" text={"Advanced Search"} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="object-selection">
                    <MDBCol md={3}>
                        <MDBRow>
                            <MDBCol md={6} className="entity-selection p-0">
                                <SelectField
                                    id={"entity"}
                                    label={"Select an object to search"}
                                    defaultMenuText={"-Select-"}
                                    options={this.state.entities || []}
                                    value={this.state.entity || ""}
                                    onChange={(e, value) => this.onChangeHeaderOptionsCallBack('entity', e.target.value)}
                                />
                            </MDBCol>
                            {(this.state.entity !== "Weekend_Reads" && this.state.entity !== "Talent") ?
                                <MDBCol md={6} className="report-selection p-0">
                                    <div className="d-flex">
                                        <SelectField
                                            id={"report"}
                                            label={"Select a report to generate (Optional)"}
                                            defaultMenuText={"-Select-"}
                                            options={selectCannedReports?.filter(item => item?.type === this.state.entity) || []}
                                            value={this.state.selectedCannedReport || ""}
                                            onChange={(e, value) => this.onCannedReportSelection(e.target.value)}
                                        />
                                        {this.state.selectedCannedReport ?
                                            <MDBIcon icon="times" onClick={() => this.setState({
                                                selectedCannedReport: '',
                                                filterCriteria: [],
                                                list: [],
                                                count: 0
                                            })} /> : null
                                        }
                                    </div>
                                    {this.state.selectedCannedReport ?
                                        <div className="report-filters">{`Apply ${this.getFilterNames()} filter(s)`}</div> : null
                                    }
                                </MDBCol> : <MDBCol className="report-selection p-0"></MDBCol>
                            }
                        </MDBRow>
                    </MDBCol>
                    <MDBCol className="deal-report-filters">
                        <>
                            {this.state.selectedCannedReport === 'Deal Information Report' &&
                                <BasicCheckbox
                                    label="Include Deal Notes"
                                    id={'dealNotes'}
                                    checked={this.state.dealNotes}
                                    onChange={(e) => this.setState({ dealNotes: e.target.checked })}
                                    align="end"
                                />
                            }
                            {this.state.selectedCannedReport === 'Writing Due Report' &&
                                <RadioGroup
                                    aria-label="Classification" value={this.state.writingDrafts}
                                    onChange={(e) => this.setState({ writingDrafts: e.target.value })}
                                    name="radio-buttons-group"
                                    class={'alignRadioButtons'}>
                                    <FormControlLabel value="All Drafts" control={<Radio size="small" />} label="All Drafts" />
                                    <FormControlLabel value="Outstanding" control={<Radio size="small" />} label="Only Outstanding Drafts" />
                                    <FormControlLabel value="Delivered" control={<Radio size="small" />} label="Only Delivered Drafts" />
                                </RadioGroup>
                            }
                        </>
                    </MDBCol>
                    <MDBCol md={1} className="export-pdf-btn p-0">
                        {this.state.selectedCannedReport ?
                            <BasicButton
                                type="inline"
                                variant="outlined"
                                icon="file-pdf"
                                text={"Export to PDF"}
                                disabled={!this.state.queryString || false}
                                // disabled={this.validateCannedReportFilters || false}
                                onClick={(e) => this.setState({ showExportReportToPdfModal: true })}
                            /> : null}
                    </MDBCol>
                    <MDBCol md={1} className="p-0 export-excel-btn">
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            icon="file-excel"
                            text={"Export as Excel"}
                            disabled={!this.state.queryString || false}
                            onClick={(e) => this.setState({ showExportReportToExcelModal: true })}
                        />
                    </MDBCol>
                </MDBRow>
                {!this.state.openAllCriteria ?
                    <MDBRow>
                        <MDBCol md={3} className="p-0">
                            <MDBCard className="filter-card">
                                <MDBCardBody>
                                    <MDBCardText>
                                        <MDBRow className="filter-section">
                                            <MDBCol md={9} className="criteria-heading p-0">
                                                <BasicLabel type="headerText" text={"Criteria"} />
                                            </MDBCol>
                                            <MDBCol className="all-criteria-btn p-0">
                                                <BasicButton
                                                    variant="outlined"
                                                    text="All Criteria"
                                                    type="inline"
                                                    onClick={() => {
                                                        let entityItem = reportObjectList?.find(item => item?.value === this.state.entity);
                                                        this.getFilterDetails(entityItem)
                                                        this.onCannedReportSelection(this.state.selectedCannedReport)
                                                        this.setState({ openAllCriteria: true })
                                                    }}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            {this.state.selectedFilter?.map((item, filterIndex) => (
                                                <Accordion
                                                    key={'filter' + '-' + filterIndex}
                                                    onClick={() => {
                                                        this.setState({ selectedGroupType: item?.value, filterIndex: filterIndex })
                                                    }}
                                                    expanded={item?.isExpand}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        onClick={() => filterIndex === 0 ? this.handleAccordionExpansion() : ''}
                                                    >
                                                        <Typography>{item?.text}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {item?.filterCategories?.map((subFilterItem, subFilterIndex) => (
                                                            <div>
                                                                {subFilterItem?.isCoreField ?
                                                                    <div
                                                                        className="d-flex core-field"
                                                                        onClick={() => {
                                                                            // if (this.state.selectedCannedReport) {
                                                                            //     this.validateCannedReport(item, subFilterItem);
                                                                            // } else {
                                                                            if (this.state.filterCriteria?.find(item =>
                                                                                item?.filterGroup === this.state.selectedGroupType && item?.filterType === this.state.selectedType)?.filters?.find(item1 =>
                                                                                    item1?.groupField === item?.value && item1?.filterField === subFilterItem?.value)?.isExpand) {
                                                                                this.handleFieldExpansion(item?.value, subFilterItem?.value)
                                                                            } else {
                                                                                this.setState({ selectedType: subFilterItem?.value, subFilterIndex: subFilterIndex });
                                                                                this.handleFilter(item, subFilterItem, null, null)
                                                                            }
                                                                            // }
                                                                        }}
                                                                    >
                                                                        {subFilterItem?.text}
                                                                        {this.state.filterCriteria?.find(item =>
                                                                            item?.filterGroup === this.state.selectedGroupType && item?.filterType === this.state.selectedType)?.filters?.find(item1 =>
                                                                                item1?.groupField === item?.value && item1?.filterField === subFilterItem?.value)?.isExpand ?
                                                                            <MDBIcon icon="minus" /> :
                                                                            <MDBIcon icon="plus" />
                                                                        }
                                                                    </div> : null
                                                                }
                                                                <>
                                                                    {this.state.filterCriteria?.find(item =>
                                                                        item?.filterGroup === this.state.selectedGroupType && item?.filterType === this.state.selectedType)?.filters?.find(item1 =>
                                                                            item1?.groupField === item?.value && item1?.filterField === subFilterItem?.value)?.isExpand &&
                                                                        this.getFilterValues(item?.value)
                                                                    }
                                                                </>
                                                            </div>
                                                        ))}
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </MDBRow>
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol>
                            <MDBCard>
                                <MDBCardBody>
                                    <MDBCardText>
                                        <MDBRow>
                                            <MDBCol md={6} className="saved-search-filter">
                                                <SelectField
                                                    id={"saved-search"}
                                                    // defaultMenuText={"- Select -"}
                                                    placeHolderText="Saved Searches"
                                                    options={this.state.searchOptions || []}
                                                    value={this.state.savedSearch || ""}
                                                    onChange={(e, value) => this.onChangeSavedSearch('savedSearch', e.target.value)}
                                                />
                                            </MDBCol>
                                            <MDBCol md={3} className="p-0 historical-data">
                                                {/* <BasicCheckbox
                                                    id={'historical-data'}
                                                    label="Include Historical Data (past 4yrs)"
                                                    checked={this.state.historicalData}
                                                    onChange={(e) => this.setState({ historicalData: e.target.checked })}
                                                /> */}
                                            </MDBCol>
                                            <MDBCol md={1} className="column-filter p-0">
                                                <>
                                                    <BasicButton
                                                        className="columnsFilter"
                                                        type="inline"
                                                        variant="outlined"
                                                        text={"Columns"}
                                                        icon={"chevron-right"}
                                                        iconRight={true}
                                                        onClick={this.handleColumns}
                                                    />
                                                    <Popover
                                                        id={idColumn}
                                                        // className="column-popover"
                                                        className={`${(this.state.entity !== 'WGAs' && this.state.entity !== 'Deals'
                                                            && this.state.entity !== 'Talent' && this.state.entity !== 'Representatives' &&
                                                            this.state.entity !== 'Companies') ?
                                                            'column-popover' : ''}`
                                                        }
                                                        open={openColumn}
                                                        anchorEl={this.state.setColumns}
                                                        onClose={this.handleCloseColumns}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <div class='row'>
                                                            {this.divideArrayToChunks(6, this.state?.config?.headings)?.map((element, parentIndex) => {
                                                                return (<div class='col-3'>
                                                                    {element.map((column, i) => {
                                                                        return (<span><BasicCheckbox
                                                                            id={i + (parentIndex * 6)} key={i + (parentIndex * 6)}
                                                                            label={column.headingLabel} checked={column.isColumn}
                                                                            disabled={column.isDisabled ? column.isDisabled : false}
                                                                            onChange={(e) => this.onColumnCheck(column, (i + (parentIndex * 6)), e.target.checked)} /></span>)
                                                                    })}
                                                                </div>)
                                                            })
                                                            }
                                                        </div>
                                                    </Popover>
                                                </>
                                            </MDBCol>
                                            {/* <MDBCol md={2} className="sort-filter p-0">
                                                <BasicButton
                                                    type="inline"
                                                    variant="outlined"
                                                    text={"Sort By A-Z"}
                                                    icon={"chevron-right"}
                                                    iconRight={true}
                                                    onClick={this.handleSortColumn}
                                                />
                                            </MDBCol> */}
                                        </MDBRow>
                                        <MDBRow>
                                            <div className="filter-chips d-flex">
                                                <div className="filter-width">
                                                    {this.state.filterCriteria?.map((criteria, criteriaIndex) => (
                                                        <>
                                                            {criteria?.filters?.map((filter, index) => (
                                                                <>
                                                                    {((filter?.type === 'Large Text' || filter?.type === 'Text') ?
                                                                        (filter?.value1 && filter?.valueAdded) : (filter?.value1 !== null || filter?.value2 !== null)) ?
                                                                        <Chip
                                                                            label={this.handleFilterLabel(filter)}
                                                                            onDelete={() => this.handleFilterDelete(filter, criteriaIndex)}
                                                                        /> : null
                                                                    }
                                                                </>
                                                            ))}
                                                        </>
                                                    ))}
                                                </div>
                                                {(this.state.filterCriteria[0]?.filters?.length > 0 &&
                                                    ((this.state.filterCriteria[0]?.filters[0]?.type === 'Large Text' || this.state.filterCriteria[0]?.filters[0]?.type === 'Text') ?
                                                        (this.state.filterCriteria[0]?.filters[0]?.value1 && this.state.filterCriteria[0]?.filters[0]?.valueAdded) :
                                                        (this.state.filterCriteria[0]?.filters[0]?.value1 !== null || this.state.filterCriteria[0]?.filters[0]?.value2 !== null))) ?
                                                    <div className="d-flex">
                                                        <div className="clear-criteria-btn p-0">
                                                            <BasicButton
                                                                variant="outlined"
                                                                text="Clear Criteria"
                                                                type="inline"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        filterCriteria: [],
                                                                        list: [],
                                                                        queryString: "",
                                                                        savedSearch: null,
                                                                        count: 0
                                                                    }, () => {
                                                                        this.getSearchFilters();
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="save-search-btn p-0">
                                                            {!this.state.savedSearch ?
                                                                <>
                                                                    <BasicButton
                                                                        variant="outlined"
                                                                        text="Save Search Criteria"
                                                                        type="inline"
                                                                        onClick={this.handleSaveSearchCriteria}
                                                                    />
                                                                    <Popover
                                                                        id={idSaveSearch}
                                                                        className="save-search-popover"
                                                                        open={openSaveSearch}
                                                                        anchorEl={this.state.anchorEl}
                                                                        onClose={this.handleCloseSaveSearch}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                    >
                                                                        <MDBRow className="save-search-modal-content">
                                                                            <BasicTextField
                                                                                label="Enter name to save search criteria as"
                                                                                value={this.state.searchCriteriaName || null}
                                                                                onChange={(e) => this.setState({ searchCriteriaName: e.target.value })}
                                                                            />
                                                                        </MDBRow>
                                                                        <MDBRow className="modal-btn">
                                                                            <MDBCol md={8}></MDBCol>
                                                                            <MDBCol md={1} className="p-0">
                                                                                <BasicButton
                                                                                    className={"modal-btn-save"}
                                                                                    variant="contained"
                                                                                    text=" "
                                                                                    // text={this.state.isSavingSearch ? <CircularProgress color="inherit" size={20} /> : " "}
                                                                                    icon={"save"}
                                                                                    onClick={this.handleSaveSearchSubmit}
                                                                                />
                                                                            </MDBCol>
                                                                            <MDBCol>
                                                                                <BasicButton
                                                                                    className={"modal-cancel-btn"}
                                                                                    variant="outlined"
                                                                                    text=" "
                                                                                    icon={"times"}
                                                                                    onClick={this.handleCloseSaveSearch}
                                                                                />
                                                                            </MDBCol>
                                                                        </MDBRow>
                                                                    </Popover>
                                                                </> :
                                                                <BasicButton
                                                                    variant="outlined"
                                                                    text="Delete Search Criteria"
                                                                    type="inline"
                                                                    onClick={this.handleDeleteSearchCriteria}
                                                                />
                                                            }
                                                        </div>
                                                    </div> : null
                                                }
                                            </div>
                                        </MDBRow>
                                        <MDBRow>
                                            <div className={"AdvancedSearchTable"} id={"AdvancedSearch"}>
                                                {this.state.isLoadingSets ? <CircularProgress className={'loaderPosition'} /> : null}
                                                <TableComponent
                                                    list={this.state.list ? this.state.list : ""}
                                                    config={this.state.config}
                                                    IsLandingPage={true}
                                                    options={this.state}
                                                    tabValue={this.state.tabValue}
                                                    sortCallback={this.handleSortColumn}
                                                    hyperLinkNavigationCallback={(item, node) => this.handleNavigation(item, node)}
                                                />
                                                <TablePagination
                                                    component="div"
                                                    count={this.state.count}
                                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                                    page={this.state.page}
                                                    onChangePage={this.handleChangePage}
                                                    rowsPerPage={this.state.rowsPerPage}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                />
                                                <MDBRow className="results-count">
                                                    <MDBCol>
                                                        <BasicLabel text={`${this.state.count} results`} />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MessageModal
                                                    open={this.state.showCols || this.state.showMinCols || false}
                                                    title={confirmModalTitle}
                                                    message={message}
                                                    hideCancel={this.state.showCols || this.state.showMinCols || false}
                                                    primaryButtonText={"OK"}
                                                    secondaryButtonText={"Cancel"}
                                                    onConfirm={() => this.setState({ showCols: false, showMinCols: false })}
                                                    handleClose={(e) => this.setState({ showCols: false, showMinCols: false })}
                                                />
                                            </div>

                                        </MDBRow>
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow> :
                    // <MDBRow>
                    <MDBCard>
                        <MDBCardBody>
                            <MDBCardText>
                                <MDBRow className="filter-section">
                                    <MDBRow>
                                        <MDBCol md={1} className="criteria-heading p-0">
                                            <BasicLabel type="headerText" text={"All Criteria"} />
                                        </MDBCol>
                                        <MDBCol md={1} className="back-to-table p-0">
                                            <BasicButton
                                                variant="outlined"
                                                text="Back to Table"
                                                type="inline"
                                                onClick={() => this.setState({ openAllCriteria: false }, () => {
                                                    this.getSearchFilters();
                                                })}
                                            />
                                        </MDBCol>
                                        <MDBCol md={4} className="saved-search-filter">
                                            <SelectField
                                                id={"saved-search"}
                                                // defaultMenuText={"-Select-"}
                                                placeHolderText="Saved Searches"
                                                options={this.state.searchOptions || []}
                                                value={this.state.savedSearch || ""}
                                                onChange={(e, value) => this.onChangeSavedSearch('savedSearch', e.target.value)}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <div className="filter-chips d-flex">
                                            <div className="filter-width">
                                                {this.state.filterCriteria?.map((criteria, criteriaIndex) => (
                                                    <>
                                                        {criteria?.filters?.map((filter, index) => (
                                                            <>
                                                                {((filter?.type === 'Large Text' || filter?.type === 'Text') ?
                                                                    (filter?.value1 && filter?.valueAdded) : (filter?.value1 !== null || filter?.value2 !== null)) ?
                                                                    <Chip
                                                                        label={this.handleFilterLabel(filter)}
                                                                        onDelete={() => this.handleFilterDelete(filter, criteriaIndex)}
                                                                    /> : null
                                                                }
                                                            </>
                                                        ))}
                                                    </>
                                                ))}
                                            </div>
                                            {(this.state.filterCriteria[0]?.filters?.length > 0 &&
                                                ((this.state.filterCriteria[0]?.filters[0]?.type === 'Large Text' || this.state.filterCriteria[0]?.filters[0]?.type === 'Text') ?
                                                    (this.state.filterCriteria[0]?.filters[0]?.value1 && this.state.filterCriteria[0]?.filters[0]?.valueAdded) :
                                                    (this.state.filterCriteria[0]?.filters[0]?.value1 !== null || this.state.filterCriteria[0]?.filters[0]?.value2 !== null))) ?
                                                <div className="d-flex">
                                                    <div className="clear-criteria-btn p-0">
                                                        <BasicButton
                                                            variant="outlined"
                                                            text="Clear Criteria"
                                                            type="inline"
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterCriteria: [],
                                                                    list: [],
                                                                    isLoadingSets: false,
                                                                    queryString: "",
                                                                    savedSearch: null,
                                                                    count: 0
                                                                }, () => {
                                                                    this.getSearchFilters();
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="save-search-btn p-0">
                                                        <BasicButton
                                                            variant="outlined"
                                                            text="Save Search Criteria"
                                                            type="inline"
                                                            onClick={this.handleSaveSearchCriteria}
                                                        />
                                                        <Popover
                                                            id={idSaveSearch}
                                                            open={openSaveSearch}
                                                            anchorEl={this.state.anchorEl}
                                                            onClose={this.handleCloseSaveSearch}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >
                                                            <MDBRow className="save-search-modal-content">
                                                                <BasicTextField
                                                                    label="Enter name to save search criteria as"
                                                                    value={this.state.searchCriteriaName || null}
                                                                    onChange={(e) => this.setState({ searchCriteriaName: e.target.value })}
                                                                />
                                                            </MDBRow>
                                                            <MDBRow className="modal-btn">
                                                                <MDBCol md={8}></MDBCol>
                                                                <MDBCol md={1} className="p-0">
                                                                    <BasicButton
                                                                        className={"modal-btn-save"}
                                                                        variant="contained"
                                                                        text=" "
                                                                        icon={"save"}
                                                                        onClick={this.handleSaveSearchSubmit}
                                                                    />
                                                                </MDBCol>
                                                                <MDBCol>
                                                                    <BasicButton
                                                                        className={"modal-cancel-btn"}
                                                                        variant="outlined"
                                                                        text=" "
                                                                        icon={"times"}
                                                                        onClick={this.handleCloseSaveSearch}
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </Popover>
                                                    </div>
                                                </div> : null
                                            }
                                        </div>
                                    </MDBRow>
                                </MDBRow>

                                <div className="d-flex">
                                    {this.state.selectedFilter?.map((criteriaFilter, criteriaIndex) => (
                                        <>
                                            {criteriaIndex < 4 &&
                                                <div className="all-criteria-details">
                                                    <Accordion
                                                        key={'criteria-filter' + '-' + criteriaIndex}
                                                        onClick={() => {
                                                            this.setState({ selectedGroupType: criteriaFilter?.value, filterIndex: criteriaIndex })
                                                        }}
                                                        expanded={criteriaFilter?.isExpand}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            onClick={() => criteriaIndex === 0 ? this.handleAccordionExpansion() : ''}
                                                        >
                                                            <Typography>{criteriaFilter?.text}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div className="full-width">
                                                                {criteriaFilter?.filterCategoryTypes?.map((categoryType, categoryIndex) => (
                                                                    <>
                                                                        <div className="core-field">
                                                                            {categoryType?.type}
                                                                            {categoryType?.isExpand ?
                                                                                <MDBIcon
                                                                                    icon="minus"
                                                                                    onClick={() => this.handleCloseCategoryType(categoryType, categoryIndex)}
                                                                                /> :
                                                                                <MDBIcon
                                                                                    icon="plus"
                                                                                    onClick={() => { this.handleOpenCategoryType(categoryType, categoryIndex) }}
                                                                                />
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {categoryType?.filterCategories?.map((filterCategory, filterCategoryIndex) => (
                                                                                <div className="core-field">
                                                                                    {categoryType?.isExpand ?
                                                                                        <div className="filters-margin">
                                                                                            {filterCategory?.configText || filterCategory?.text}
                                                                                            {/* {this.state.filterCriteria?.find(item => item?.filterField === filterCategory?.value)?.isExpand ? */}
                                                                                            {this.state.filterCriteria?.find(item =>
                                                                                                item?.filterGroup === this.state.selectedGroupType && item?.filterType === this.state.selectedType
                                                                                                && item?.filterCategoryType === categoryType?.type)?.filters?.find(item1 =>
                                                                                                    item1?.groupField === criteriaFilter?.value && item1?.filterField === filterCategory?.value)?.isExpand ?
                                                                                                <MDBIcon
                                                                                                    icon="minus"
                                                                                                    onClick={() => this.handleFieldExpansion(criteriaFilter?.value, filterCategory?.value, categoryType)}
                                                                                                /> :
                                                                                                <MDBIcon
                                                                                                    icon="plus"
                                                                                                    onClick={() => {
                                                                                                        // if (this.state.selectedCannedReport) {
                                                                                                        //     this.validateCannedReport(criteriaFilter, filterCategory, categoryType)
                                                                                                        // } else {
                                                                                                        this.setState({
                                                                                                            selectedType: filterCategory?.value,
                                                                                                            subFilterIndex: filterCategoryIndex,
                                                                                                            expandField: true
                                                                                                        })
                                                                                                        this.handleFilter(criteriaFilter, filterCategory, categoryType)
                                                                                                        // }
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        </div> : null
                                                                                    }
                                                                                    <>
                                                                                        {this.state.expandCategory && this.state.expandField && this.state.filterCriteria?.find(item =>
                                                                                            item?.filterGroup === this.state.selectedGroupType && item?.filterType === this.state.selectedType
                                                                                            && item?.filterCategoryType === categoryType?.type)?.filters?.find(item1 =>
                                                                                                item1?.groupField === criteriaFilter?.value && item1?.filterField === filterCategory?.value)?.isExpand &&
                                                                                            this.getFilterValues(criteriaFilter?.value, categoryType)
                                                                                        }
                                                                                    </>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            }
                                        </>
                                    ))}
                                </div>
                                <div className="d-flex">
                                    {this.state.selectedFilter?.map((criteriaFilter, criteriaIndex) => (
                                        <>
                                            {criteriaIndex > 3 &&
                                                <div className="all-criteria-details">
                                                    <Accordion
                                                        key={'criteria-filter' + '-' + criteriaIndex}
                                                        onClick={() => this.setState({ selectedGroupType: criteriaFilter?.value, filterIndex: criteriaIndex })}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            onClick={() => criteriaIndex === 0 ? this.handleAccordionExpansion() : ''}
                                                        >
                                                            <Typography>{criteriaFilter?.text}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div className="full-width">
                                                                {criteriaFilter?.filterCategoryTypes?.map((categoryType, categoryIndex) => (
                                                                    <>
                                                                        <div className="core-field">
                                                                            {categoryType?.type}
                                                                            {categoryType?.isExpand ?
                                                                                <MDBIcon
                                                                                    icon="minus"
                                                                                    onClick={() => this.handleCloseCategoryType(categoryType, categoryIndex)}
                                                                                /> :
                                                                                <MDBIcon
                                                                                    icon="plus"
                                                                                    onClick={() => { this.handleOpenCategoryType(categoryType, categoryIndex) }}
                                                                                />
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {categoryType?.filterCategories?.map((filterCategory, filterCategoryIndex) => (
                                                                                <div className="core-field">
                                                                                    {categoryType?.isExpand ?
                                                                                        <div className="filters-margin">
                                                                                            {filterCategory?.text}
                                                                                            {/* {this.state.filterCriteria?.find(item => item?.filterField === filterCategory?.value)?.isExpand ? */}
                                                                                            {this.state.filterCriteria[this.state.filterCriteria?.indexOf(this.state.filterCriteria?.find(item =>
                                                                                                item?.filterGroup === this.state.selectedGroupType && item?.filterType === this.state.selectedType))]?.filters?.find(item1 =>
                                                                                                    item1?.groupField === criteriaFilter?.value && item1?.filterField === filterCategory?.value)?.isExpand ?
                                                                                                <MDBIcon
                                                                                                    icon="minus"
                                                                                                    onClick={() => this.handleFieldExpansion(criteriaFilter?.value, filterCategory?.value)}
                                                                                                /> :
                                                                                                <MDBIcon
                                                                                                    icon="plus"
                                                                                                    onClick={() => {
                                                                                                        // if (this.state.selectedCannedReport) {
                                                                                                        //     this.validateCannedReport(criteriaFilter, filterCategory, categoryType)
                                                                                                        // } else {
                                                                                                        this.setState({
                                                                                                            selectedType: filterCategory?.value,
                                                                                                            subFilterIndex: filterCategoryIndex,
                                                                                                            expandField: true
                                                                                                        })
                                                                                                        this.handleFilter(criteriaFilter, filterCategory, categoryType)
                                                                                                        // }
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        </div> : null
                                                                                    }
                                                                                    <>
                                                                                        {this.state.filterCriteria[this.state.filterCriteria?.indexOf(this.state.filterCriteria?.find(item =>
                                                                                            item?.filterGroup === this.state.selectedGroupType && item?.filterType === this.state.selectedType))]?.filters?.find(item1 =>
                                                                                                item1?.groupField === criteriaFilter?.value && item1?.filterField === filterCategory?.value)?.isExpand &&
                                                                                            this.getFilterValues(criteriaFilter?.value)
                                                                                        }
                                                                                    </>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            }
                                        </>
                                    ))}
                                </div>
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                    // </MDBRow>
                }
            </MDBContainer>
        )
    }
}

export default withRouter(withUserContext(AdvancedSearch));