import axios from 'axios';
import authService from "../home/app.config";
import * as Constants from '../constants/constants';

class ScripterService {

    constructor() {
    }

    async getData(httpPath, tenantId, divisionId, encrypted = false) {
        let token = await authService.getAccessToken();

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantid': tenantId,
            'divisionId': divisionId,
            ...(encrypted) && { 'encryptionType': 1 }
        };
        return axios.get(httpPath, { headers });
    }

    async getDataWS(load) {
        let token = await authService.getAccessToken();
        var webSocketConn = new WebSocket(Constants.scripterServiceWSBaseUrl,
            ["access_token", token]
        );
        webSocketConn.onopen = (e) => {
            webSocketConn.send(JSON.stringify(load));
        }
        return webSocketConn;
    }

    async getDataParams(httpPath, params, tenantId, divisionId, inputKey = "?input=") {
        let token = await authService.getAccessToken();
        // let tenantId = localStorage.getItem("active_tenant_id");
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'encryptionType': 1,
            'tenantid': tenantId,
            'divisionId': divisionId
        };
        return axios.get(httpPath + inputKey + btoa(JSON.stringify(params)), { headers });
    }
    async getAdvancedSearch(httpPath,entity,params, tenantId, divisionId, encrypted = false, report) {
        let token = await authService.getAccessToken();
        // let tenantId = localStorage.getItem("active_tenant_id");
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'encryptionType': 1,
            'tenantid': tenantId,
            'divisionId': divisionId
        };
        return axios.get(httpPath + (report ? "?reportName="+entity:"?entity="+entity.toUpperCase())+  (report ? "&searchJson=" : "&input=") + btoa((JSON.stringify(params))), { headers });
    }
    async getLandingDataParams(httpPath, params, tenantId, divisionId) {
        let token = await authService.getAccessToken();
        // let tenantId = localStorage.getItem("active_tenant_id");
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'encryptionType': 1,
            'tenantid': tenantId,
            'divisionId': divisionId
        };
        return axios.get(httpPath + "?searchJson=" + btoa(JSON.stringify(params)), { headers });
    }
    async getLandingDataParamsWithoutQuestionMark(httpPath, params, tenantId, divisionId) {
        let token = await authService.getAccessToken();
        // let tenantId = localStorage.getItem("active_tenant_id");
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'encryptionType': 1,
            'tenantid': tenantId,
            'divisionId': divisionId
        };
        return axios.get(httpPath + "searchJson=" + btoa(JSON.stringify(params)), { headers });
    }
    async getReportDataParamsWithAmpersand(httpPath, params, tenantId, divisionId) {
        let token = await authService.getAccessToken();
        // let tenantId = localStorage.getItem("active_tenant_id");
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'encryptionType': 1,
            'tenantid': tenantId,
            'divisionId': divisionId
        };
        return axios.get(httpPath + "&searchJson=" + btoa(JSON.stringify(params)), { headers });
    }
    async getDataWithCancel(httpPath, cancelToken, tenantId, divisionId, encryptionType = 1, aesKey = '') {
        let token = await authService.getAccessToken();
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantid': tenantId,
            'divisionId': divisionId
        };
        if (encryptionType == 1) {
            return axios.get(httpPath,
                {
                    headers: headers,
                    cancelToken: cancelToken.token
                }
            );
        } else {
            return this.getEncryptedData(httpPath, tenantId, aesKey, cancelToken.token);
        }
    }

    async getDataWithCancelWithTenant(httpPath, cancelToken, tenantIdNew, divisionId, encryptionType = 1) {
        let token = await authService.getAccessToken();
        let tenantId = tenantIdNew;
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantid': tenantId,
            'divisionId': divisionId
        };
        if (encryptionType == 1) {
            return axios.get(httpPath,
                {
                    headers: headers,
                    cancelToken: cancelToken.token
                }
            );
        } else {
            return this.getEncryptedData(httpPath, headers, cancelToken.token);
        }
    }

    async postDataParams(httpPath, body, tenantId, divisionId, encryptionType = 1) {
        let token = await authService.getAccessToken();
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'encryptionType': encryptionType,
            'tenantid': tenantId,
            'divisionId': divisionId
        };
        if (encryptionType == 1) {
            // return axios.post(httpPath, btoa(JSON.stringify(body)), { headers });
            return axios.post(httpPath, btoa(unescape(encodeURIComponent(JSON.stringify(body)))), { headers })
        } else if (encryptionType == 2) {
            //Retrieve Secret Key and use for decryption
            return this.getData(Constants.scripterServiceBaseUrl + `/aesSecretKey`)
                .then(response => {
                    let secretKey = response?.data;
                    var CryptoJS = require("crypto-js");
                    // Decrypt AES Key First
                    var aesDecrypted = JSON.parse(atob(secretKey)).secret_key
                    // Decrypt
                    var bytes = CryptoJS.AES.encrypt(JSON.stringify(body), aesDecrypted);
                    return axios.post(httpPath, bytes.toString(), { headers });
                },
                    (err) => {
                        console.log("Error in post:", err)
                    })
        }
        //return null;

    }

    async deleteDataParams(httpPath, params, tenantId, divisionId) {
        let token = await authService.getAccessToken();
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'encryptionType': 1,
            'tenantid': tenantId,
            'divisionId': divisionId
        };
        return axios.delete(httpPath + "?input=" + btoa(JSON.stringify(params)), { headers });
    }
    async deleteParam(httpPath, tenantId, divisionId) {
        let token = await authService.getAccessToken();
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantid': tenantId,
            'divisionId': divisionId
        };
        return axios.delete(httpPath, { headers });
    }

    async fetchData(httpPath, divisionId) {
        let token = await authService.getAccessToken();
        let tenantId = localStorage.getItem("active_tenant_id");
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantid': tenantId,
            'divisionId': divisionId
        };

        let options = {
            headers: new Headers(headers)
        };

        return fetch(httpPath, options);
    }

    async fetchFile(httpPath) {
        return fetch(httpPath);
    }
    async postData(httpPath, body, tenantId, divisionId) {
        let token = await authService.getAccessToken();
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantid': tenantId,
            'divisionId': divisionId
        };
        return axios.post(httpPath, JSON.stringify(body), { headers });
    }

    async uploadFile(httpPath, formData) {
        //
        let token = await authService.getAccessToken();

        // let headers = {
        //     'Content-Type': 'multipart/form-data',
        //     'Authorization': `Bearer ${token}`
        // };
        //Base64.extendString();
        //var axios = axios.create();
        //axios.defaults.headers.common = {};
        var client = axios.create({
            transformRequest: [
                (data, headers) => {
                    //delete headers.common["Content-Type"];
                    delete headers.put["Content-Type"];
                    headers.put["Content-Type"] = '';
                    return data;
                },
            ]
        });
        //delete client.defaults.headers.common["Content-Type"];
        return client.put(httpPath, // encodeGetParams(formData)
            // btoa(encodeURIComponent(formData).replace(/%([0-9A-F]{2})/g,
            // function toSolidBytes(match, p1) {
            //     return String.fromCharCode('0x' + p1);
            // }))
            //Base64.encode(formData)
            //formData.toBase64()
            //Base64.btoa(formData)
            formData);
    }

    async downloadFile(httpPath) {
        //
        //let token = await authService.getAccessToken();
        var client = axios.create({
            transformRequest: [
                (data, headers) => {
                    //delete headers.common["Content-Type"];
                    // delete headers.delete["Content-Type"];
                    // headers.delete["Content-Type"] = '';
                    return data;
                },
            ]
        });

        return client.get(httpPath, { responseType: 'blob' });
    }

    encodeGetParams(params) {
        // var result = Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&");        
        // return btoa(result);
        return btoa(encodeURIComponent(params).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
            }));
    }


    async getEncryptedData(httpPath, tenantId, divisionId, aesKey = '', cancelToken = null) {
        //, isEncrypted = 0, encryptionType = 2
        //Marking Encryption Type as 2 for AES encryption type
        //No other encryption techinques as of now
        let data = null;
        let token = await authService.getAccessToken();
        // let tenantId = localStorage.getItem("active_tenant_id");
        let headerSection = {};
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantid': tenantId,
            'divisionId': divisionId
        };
        headerSection.headers = headers;
        if (cancelToken) {
            headerSection.cancelToken = cancelToken;
        }
        if (aesKey != '') {
            return axios.get(httpPath, headerSection).then(res => {
                if (res && res.data) {
                    var CryptoJS = require("crypto-js");
                    // Decrypt AES Key First
                    var aesDecrypted = JSON.parse(atob(aesKey)).secret_key
                    var bytes = CryptoJS.AES.decrypt(res.data, aesDecrypted);
                    data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                    return data;
                }
            }, (err) => { })
        }
        //Retrieve Secret Key and use for decryption
        // return this.getData(Constants.scripterServiceBaseUrl + `/aesSecretKey`)
        //     .then(response => {
        //         let secretKey = response?.data?.secret_key;
        //         return axios.get(httpPath, headerSection).then(res => {
        //             if (res && res.data) {
        //                 var CryptoJS = require("crypto-js");
        //                 // Decrypt
        //                 var bytes = CryptoJS.AES.decrypt(res.data, secretKey);
        //                 data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        //                 return data;
        //             }
        //         }, (err) => { })
        //     },
        //         (err) => {
        //             console.log("Error in fetching Address Types:", err)
        //         })
    }

}


export default new ScripterService();