import React from 'react';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.scss';
import EditorToolbar, { formats } from "./EditOrToolbar";
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';

Quill.register(Quill.import("attributors/style/align"), true);
class RichTextEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            limitExceeded:false,
            charCount:0
        }
        this.reactQuillRef = React.createRef(null);
    }

    returnCharCounter(){
        const editor = this.reactQuillRef?.current?.getEditor();
        const unprivilegedEditor = this.reactQuillRef?.current?.makeUnprivilegedEditor(editor);
        let chars = unprivilegedEditor?.getLength();
        
       return chars;
    }
    checkCharacterCount(event){
       
        // let text = unprivilegedEditor.getText()
        
        let chars = this.returnCharCounter();         
        
        this.props?.getCharCount(event.key !== 'Backspace' ? chars:chars-1)  
        if (chars > this.props?.limit && event.key !== 'Backspace'){
            event.preventDefault();
        }
       
        // else{
        //     const editor = this.reactQuillRef.current.getEditor();
        //     const unprivilegedEditor = this.reactQuillRef.current.makeUnprivilegedEditor(editor);
        //     let val = unprivilegedEditor.getHTML();            
        //     this.props?.onChange(val);
        // }
        
        
        
    }
    
    onChangeHandler(val){  
        let chars = this.returnCharCounter();  
        this.props?.onChange(val);       
        // this.setState({charCount:chars}) 
    }
    render() {
        // modules.toolbar.container = `#toolbar${this.props?.id}`        
        let error = this.props?.charCount > this.props.limit || (this.props.showMandatory && !this.props?.value) || false;
        let mandatoryWarning = "Please complete this mandatory field";
        // console.log(error);
        return (
            <div className="text-editor">
                <EditorToolbar id={this.props?.id} />
                <ReactQuill
                    ref={ this.reactQuillRef }
                    {...(this.props?.value ? { value: this.props?.value } : {})}
                    {...(this.props?.defaultValue ? { defaultValue: this.props?.defaultValue } : {})}
                    {...(this.props?.onChange ? { onChange:  this.onChangeHandler.bind(this)} : {})}
                    {...(this.props?.limit ? {onKeyDown:this.checkCharacterCount.bind(this)}:{})}
                    theme="snow"
                    formats={formats}
                    modules={{
                        toolbar: {
                            container: `#toolbar${this.props?.id}`
                        }
                    }}
                />
                 {error && <div className="errorText">{((this.props?.showMandatory && !this.props?.value) ? mandatoryWarning : this.props.limitWarning) || ""}</div>}
            </div>
        )
    }
}

export default RichTextEditor;