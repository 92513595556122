export const intialDeals = {
    "deals_id": null,
    "talent_id": null,
    "steps_name": null,
    "writers": null,
    "writers_list": [],
    "deal_list":[],
    "writing_start": null,
    "duration": null,
    "writing_due": null,
    "draft": null,
    "delivery": null,
    "reading_period": null,
    "reading_end": null,
    "payment": null,
    "notes":null
}
export const writers = {
    "writers_id": null,
    "writer": null,
    "index": 1
}
export const initialSteps = {
    
        "deal_memo_step_id": null,
        "deal_memo_id": null,
        "deal_ammendment_id": null,
        "step_number":null,
        "step_name_id": null,
        "draft_submission_id": null,
        "writing_start": null,
        "duration": null,
        "duration_period": null,
        "delivery_date": null,
        "reading": null,
        "reading_period": null,
        "currency": null,
        "payment": null,
        "deal_notes": null,
        "writing_due":null,
        "reading_end":null,
        "writers": [
            {
                
                "deal_step_writer_id": 12345,
                "deal_memo_step_id": null,
                "writer_id": null,
                "writerOptions":[]
            }
        ]
    
}
export const writerConfig={
    "deal_step_writer_id": null,
    "deal_memo_step_id": null,
    "writer_id": null,
    "talent_group_id": null  
}
export const intialDealConfig={  
    "division_id": null,   
    "deal_memo_id": null,
    "is_deal_memo": 1, // new deal
    "is_deal_ammendment": 0, //new amendment
    "project_id": null,
    "document_name": null,
    "memo_date": null,
    "assigned_material": null,
    "deal_notes": null,
    "deal_ammendment_id": null,
    "ammendment_date": null,
    "ammendment_notes": null,
    "steps": [],
    "is_delete": 0
}

export const DealQueueInitialJson = {
    "is_deal_queue": 1,
    "is_project_queue": null,
    "user_id": null,
    "user_queue_deal_id": null,
    "deal_memo_id": null,
    "user_queue_project_id": null,
    "project_id": null,
    "is_delete": 0

}

export const talentPrefixes = [
    { value: "Dr", label: "Dr" },
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" }
]

export const talentSuffixes = [
    { value: "Esq.", label: "Esq." },
    { value: "I", label: "I" },
    { value: "II", label: "II" },
    { value: "III", label: "III" },
    { value: "IV", label: "IV" },
    { value: "V", label: "V" },
    { value: "Sr.", label: "Sr." },
    { value: "Jr.", label: "Jr." }
]