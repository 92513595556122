export const lookupType = [
    {value:"lu_agency_types",label:"Company Type"},
    {value:"lu_country",label:"Country"},
    {value:"lu_state",label:"State"},
    {value:"lu_coverage_types",label:"Coverage Type"},
    {value:"lu_currency",label:"Currency"},
    {value:"lu_distribution_company",label:"Distribution Company Type"},
    {value:"lu_division",label:"Division"},
    {value:"lu_duration",label:"Duration"},
    {value:"lu_element_status",label:"Element Status"},
    {value:"lu_format",label:"Format"},
    {value:"lu_genre",label:"Genre"},
    {value:"lu_material_type",label:"Material Type"},
    {value:"lu_person_email_type",label:"Email Type (Person)"},
    {value:"lu_person_phone_type",label:"Phone Type (Person)"},
    {value:"lu_prefix",label:"Prefix"},
    {value:"lu_project_status",label:"Project Status"},
    {value:"lu_project_type",label:"Project Type"},
    {value:"lu_recommendation",label:"Recommendation"},
    {value:"lu_representative_type",label:"Representative Type"},
    {value:"lu_step_names",label:"Step Name"},
    {value:"lu_submission_status",label:"Submission Status"},
    {value:"lu_suffix",label:"Suffix"},
    {value:"lu_talent_roles",label:"Talent Roles"},
    {value:"lu_wga_type",label:"WGA Types"},
    {value:"lu_writer_recommendation",label:"Writer Recommendation"},
    {value:"lu_person_address_type",label:"Address Type (Person)"},
    {value:"lu_company_email_type",label:"Email Type (Company)"},
    {value:"lu_company_phone_type",label:"Phone Type (Company)"},
    {value:"lu_company_address_type",label:"Address Type (Company)"},
]
export const lookupConfig = {
    headings: [
        {
            headingLabel: "Value",
            icon: "sort-alpha-up",
            width: '92%'
        },
        {
            headingLabel: "Active",
            icon: "",
            width: '8%'
        }
    ],
    dataNodes: ["label","check"],
    primaryKey: "value"
}