import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

class MenuField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleItemClick = (selectedItem) => {
        this.props?.onClick(selectedItem)
        this.props?.handleClose()
    }

    render() {
        let selectOptions = this.props?.options || [];
        return (
            <div className="MenuFieldContainer">
                <Menu
                    id={this.props?.id}
                    anchorEl={this.props?.anchorEl}
                    keepMounted
                    open={this.props?.open}
                    onClose={this.props?.handleClose}
                >
                    {
                        selectOptions?.map((item, index) => {
                            return (
                                <MenuItem key={index} value={item.value} onClick={() => this.handleItemClick(item)}>
                                    {item.label}
                                </MenuItem>
                            )
                        })
                    }
                </Menu>
            </div>
        )
    }
}

export default MenuField;