import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import ActionButtons from '../../components/SharedComponents/ActionButtons/BasicActionButtons';
import scripterService from '../../services/service';
import * as Constants from '../../constants/constants';
import './AddressComponent.scss';
import { validateNumbersOnly } from '../../Common/Helper';
import messages from '../../Common/Messages.json';
import sizeLimits from '../../Common/SizeLimits.json';
import { withUserContext } from '../../contexts/UserContext';

class AddressComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addressTypes: [],
            isIntlChecked: false,
            selectedCountryValue: 0,
            selectedStateValue: '',
            intlCountryStatus: false,
            intlStateStatus: false,
            address: props?.address
        }
    }

    componentDidMount() {
        this.getaddressTypes();
        this.getCountryList();
        this.getStateList();
    }

    getaddressTypes = () => {
        scripterService.getData(Constants.scripterServiceBaseUrl  + `/staticData?staticTable=${this.props.typeEndpoint}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                this.setState({ addressTypes: formattedList },
                    () => {
                        if (this.props.list[this.props.addressConfig?.addressTypeId] === null) {
                            this.props.editCallback('address_type_id', this.state.addressTypes[0].value, this.props.list, 'address_list', 'Check');
                            this.props.editCallback('address_type', this.state.addressTypes[0].label, this.props.list, 'address_list', 'Check');
                        }
                    });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    getCountryList = () => {
        scripterService.getData(Constants.scripterServiceBaseUrl + `/staticData?staticTable=lu_country`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active}));
                let defaultCountry = formattedList.filter(item => {
                    return item.label === "United States"
                })

                this.setState({
                    countryList: formattedList,
                    selectedCountryValue: defaultCountry[0].value
                });

                if (!this.props?.list?.country_id) {
                    this.props.editCallback('country_id', defaultCountry[0].value, this.props.list, 'address_list', 'Check');
                }
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }
    getStateList = () => {
        scripterService.getData(Constants.scripterServiceBaseUrl + `/staticData?staticTable=lu_state`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                this.setState({ stateList: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    onChangeCallback = (field, value) => {
        this.props.editCallback(field, value, this.props.list, 'address_list');

        if (field === 'address_type_id') {
            this.props.editCallback(this.props.addressConfig?.addressType,
                this.state.addressTypes?.find(type => type.value === value)?.label,
                this.props.list, 'address_list');
        }
        if (field === 'country_id') {
            this.setState({ intlCountryStatus: true, selectedCountryValue: value, selectedStateValue: '' });

            if (this.state.countryList?.find(item => item?.value == value)?.label == 'United States') {
                this.setState({ selectedCountryValue: this.state.countryList[0].value }, () => {
                    this.props.editCallback('country_id', this.state.selectedCountryValue, this.props.list, 'address_list');
                    this.props.editCallback('country', 'United States', this.props.list, 'address_list');
                    this.props.editCallback('is_international', 0, this.props.list, 'address_list');
                    this.props.editCallback(this.props.addressConfig?.stateId, null, this.props.list, 'address_list');
                    this.props.editCallback(this.props.addressConfig?.state, null, this.props.list, 'address_list');
                })
            } else {
                this.setState({
                    isIntlChecked: true, selectedCountryValue: value, selectedStateValue: '', intlStateStatus: false, intlCountryStatus: false
                }, () => {
                    this.props.editCallback('country_id', this.state.selectedCountryValue, this.props.list, 'address_list');
                    this.props.editCallback('is_international', 1, this.props.list, 'address_list');
                    this.props.editCallback(this.props.addressConfig?.stateId, null, this.props.list, 'address_list');
                    this.props.editCallback(this.props.addressConfig?.state, null, this.props.list, 'address_list');
                });
            }
        }
        if (field === 'state_id' || field === 'state') {
            this.setState({ intlStateStatus: true, selectedStateValue: value });
            if (field === 'state_id') {
                let stateValue = this.state.stateList.find(type => type.value === value)?.label
                this.props.editCallback(this.props.addressConfig?.state, stateValue, this.props.list, 'address_list');
            }
        }
    }

    render() {
        const { addressConfig } = this.props;

        return (
            <MDBContainer className="AddressComponentContainer">
                <MDBRow>
                    <MDBCol md={1}>
                        <SelectField id={"type"}
                            label={"Type"}
                            disabled={this.props.disabled || false}
                            value={(this.props.list && addressConfig?.addressTypeId) ? this.props.list[addressConfig?.addressTypeId] : ""}
                            options={this.state.addressTypes || []}
                            onChange={(e) => this.onChangeCallback(addressConfig?.addressTypeId, e.target.value)}
                            showAsLabel={this.props.disabled || false}
                            className="left-pdng" />
                    </MDBCol>
                    <MDBCol md={3}>
                        <BasicTextField
                            id="address_line1"
                            label={"Address Line"}
                            placeholder={"Address Line1"}
                            limit={sizeLimits.addressCharacterLimit}
                            limitWarning={messages.exceed100CharacterWarning || ""}
                            value={(this.props.list && addressConfig?.address1) ? this.props.list[addressConfig?.address1] : ""}
                            isMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                            showMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                            showAsLabel={this.props.disabled || false}
                            onChange={(e) => this.onChangeCallback(addressConfig?.address1, e.target.value.length > 0 ? e.target.value : null)}
                        />
                        <div className="addressLineMargin">
                            <BasicTextField
                                id="address_line2"
                                placeholder={"Address Line2"}
                                limit={sizeLimits.addressCharacterLimit}
                                limitWarning={messages.exceed100CharacterWarning || ""}
                                value={(this.props.list && addressConfig?.address2) ? this.props.list[addressConfig?.address2] : ""}
                                showAsLabel={this.props.disabled || false}
                                onChange={(e) => this.onChangeCallback(addressConfig?.address2, e.target.value.length > 0 ? e.target.value : null)}
                            />
                        </div>
                        <div className="addressLineMargin">
                            <BasicTextField
                                id="address_line3"
                                placeholder={"Address Line3"}
                                limit={sizeLimits.addressCharacterLimit}
                                limitWarning={messages.exceed100CharacterWarning || ""}
                                value={(this.props.list && addressConfig?.address3) ? this.props.list[addressConfig?.address3] : ""}
                                showAsLabel={this.props.disabled || false}
                                onChange={(e) => this.onChangeCallback(addressConfig?.address3, e.target.value.length > 0 ? e.target.value : null)}
                            />
                        </div>
                    </MDBCol>
                    <MDBCol md={3}>
                        <BasicTextField id="city"
                            value={(this.props.list && addressConfig?.city) ? this.props.list[addressConfig?.city] : ""}
                            onChange={(e) => this.onChangeCallback(addressConfig?.city, e.target.value.length > 0 ? e.target.value : null)}
                            label="City"
                            disabled={this.props.disabled || false}
                            limit={sizeLimits.cityCharacterLimit}
                            showAsLabel={this.props.disabled || false}
                            isMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                            showMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                            inValidInput={messages.invalidInput || ""}
                            limitWarning={messages.exceed20CharacterWarning || ""} />
                        <SelectField id={"country"}
                            label={"Country"}
                            disabled={this.props.disabled || false}
                            value={this.props.list[addressConfig?.countryId]}
                            options={this.state.countryList || []}
                            onChange={(e) => this.onChangeCallback(addressConfig?.countryId, e.target.value)}
                            showAsLabel={this.props.disabled || false}
                        />
                    </MDBCol>
                    <MDBCol md={3} className="state">
                        {
                            this.state.countryList?.find(item => item?.value == this.state.selectedCountryValue)?.label == 'United States' ?
                                <SelectField
                                    id={"state"}
                                    label={"State/Province"}
                                    disabled={this.props.disabled || false}
                                    value={this.props.disabled ? this.props.list[addressConfig?.state] : this.props.list[addressConfig?.stateId]}
                                    isMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                                    showMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                                    options={this.state.stateList || []}
                                    onChange={(e) => this.onChangeCallback(addressConfig?.stateId, e.target.value)}
                                    showAsLabel={this.props.disabled || false}
                                    stateLabel={this.props.disabled ? true : false}
                                /> :
                                <BasicTextField id="state"
                                    limit={sizeLimits.stateCharacterLimit}
                                    disabled={this.props.disabled || false}
                                    inValidInput={messages.invalidInput || ""}
                                    limitWarning={messages.exceed20CharacterWarning || ""}
                                    label={"State/Province"}
                                    value={this.props.list[addressConfig?.state]}
                                    isMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                                    showMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                                    onChange={(e) => this.onChangeCallback(addressConfig?.state, e.target.value.length > 0 ? e.target.value : null)}
                                    disabled={this.props.list[addressConfig?.countryId] ? false : true}
                                    showAsLabel={this.props.disabled || false} />
                        }
                        <BasicTextField id="zipCode"
                            disabled={this.props.disabled || false}
                            limit={this.props.list && (this.props.list[addressConfig?.isInternational] === 1) ?
                                sizeLimits.intlZipCharacterLimit : sizeLimits.usZipCharacterLimit}
                            limitWarning={this.props.list && (this.props.list[addressConfig?.isInternational] === 1) ?
                                messages.addressLimitIntl : messages.addressLimitUs}
                            value={(this.props.list && addressConfig?.zipcode) ? this.props.list[addressConfig?.zipcode] : ""}
                            isMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                            showMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                            onChange={(e) => validateNumbersOnly(e.target.value) ?
                                this.onChangeCallback(addressConfig?.zipcode, e.target.value.length > 0 ? e.target.value : null) : null}
                            label="Zip Code"
                            showAsLabel={this.props.disabled || false} />
                    </MDBCol>
                    <MDBCol md={2} className="actions check-primary">
                        <ActionButtons
                            keyName={this.props.keyName}
                            showRemove={this.props.showRemove || false}
                            showAdd={this.props.showAdd || false}
                            primaryOnChange={(e) => this.onChangeCallback(addressConfig?.isPrimary, e.target.checked)}
                            isPrimary={(this.props.list && addressConfig?.isPrimary) ? this.props.list[addressConfig?.isPrimary] : false}
                            addCallback={this.props.addCallback}
                            removeCallback={this.props.removeCallback}
                            showAsLabel={this.props.disabled || false}
                        />
                    </MDBCol>
                </MDBRow>
                {
                    this.props.checkedMultiPrimary ? (
                        <MDBRow>
                            <div className="error">{"Only one address can be a primary. Multiple selection is not allowed"}</div>
                        </MDBRow>
                    ) : null
                }
            </MDBContainer>
        );
    }
}

export default withUserContext(AddressComponent);