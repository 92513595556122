import React, { Component } from 'react';
import RepresentativeDetails from './RepresentativeDetails';
import RepresentedTalentList from './RepresentedTalent';
import { getFormattedDate, getDateAndTime } from '../../../Common/Helper';
import { withUserContext } from '../../../contexts/UserContext';
import './Representative.scss';
import {
    initialRepresentativePhone,
    initialRepresentativeEmail,
    initialRepresentativeAddress,
    addressConfig,
    newRepresentativeDetails,
    initialRepresentativeAgencies
} from './Config';
import {
    validateEmail,
    validateUSNumber,
    validateIntlNumber,
    validatePhoneNumber,
    getAddress,
    validatePlusCharacter
} from '../../../Common/Helper';
import messages from '../../../Common/Messages.json';
import sizeLimits from '../../../Common/SizeLimits.json';
import ScripterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
class RepresentativeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            mode: "new",
            representativeList: [],
            isFetchingRepresentativeList: false,
            representative_id: null,
            phoneIndex: 2,
            emailIndex: 2,
            addressIndex: 2,
            representativeDetails: [],
            representativeTypes: [],
            isFetchingRepresentativeDetails: false,
            isFetchingRepresentativeTypes: false,
            isFetchingRepresentativeAgencies: false,
            postInitiated: false,
            formEdited: false,
            loading: false,
            agencySelected: null,
            checkedMultiPrimary: {
                email_list: false,
                phone_list: false,
                address_list: false
            },
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.representative_id != nextProps?.navObj?.representative_id) {
            if (nextProps.mode === "new") {
                this.setState({ representativeList: [], representative_id: nextProps?.navObj?.representative_id });
            } else if (nextProps.mode === "edit") {
                if (!(nextProps?.navObj) || !(Object.keys(nextProps?.navObj).length)) {
                    this.setState({ representativeList: [], representative_id: nextProps?.navObj?.representative_id });
                } else {
                    this.setState({ representative_id: nextProps?.navObj?.representative_id });
                    this.getRepresentativeList(nextProps?.navObj?.representative_id);
                }

            }
        }
        if (nextProps.tabValue !== this.state.tabValue || nextProps.mode !== this.state.mode) {
            this.setState({ tabValue: nextProps.tabValue, mode: nextProps.mode });
        }
    }

    componentDidMount() {
        this.checkMode(this.props);
        this.getRepresentativeTypes();
    }

    componentDidUpdate(prevProps) {
        if (this.props.navObj.representative_id != prevProps.navObj.representative_id || this.props.mode !== prevProps.mode) {
            this.checkMode(this.props);
        }
    }

    checkMode = (props) => {
        if (props.mode === "new") {
            this.setState({
                representativeDetails: this.formatRepresentativeDetailsResponse(
                    JSON.parse(JSON.stringify({ ...newRepresentativeDetails }))
                )
            }, () => {
                this.props.setModalList(this.state.representativeDetails);
            })
        } else if (props.mode === "edit") {
            if (!(Object.keys(props.navObj).length)) {
                this.setState({
                    representativeDetails: this.formatRepresentativeDetailsResponse(
                        JSON.parse(JSON.stringify({ ...newRepresentativeDetails }))
                    )
                }, () => {
                    this.props.setModalList(this.state.representativeDetails);
                })
            } else {
                if (!props.navObj.first_name) {
                    this.getRepresentativeDetails(props.navObj?.representative_id || props.repId);
                } else {
                    this.setState({ representativeDetails: this.formatRepresentativeDetailsResponse(props.navObj) }, () => {
                        this.props.setModalList(this.state.representativeDetails);
                        this.state.representativeDetails?.address_list?.map((item) => {
                            item['address'] = ((!item.address_1) ? '' : item.address_1) + ((!item.address_2) ? '' : ' ' + item.address_2)
                                + ((!item.address_3) ? '' : ' ' + item.address_3)
                        });
                    })
                }
            }
        }
    }

    getRepresentativeDetails = (representative_id) => {
        this.setState({ isFetchingRepresentativeDetails: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/representative?searchString=${representative_id}&searchKey=id`, this.props.userContext?.active_tenant?.tenant_id, null)
            .then(response => {
                this.setState({ representativeDetails: this.formatRepresentativeDetailsResponse(response.data[0]), isFetchingRepresentativeDetails: false }, () => {
                    this.props.setModalList(this.state.representativeDetails);
                    this.state.representativeDetails?.address_list?.map((item) => {
                        item['address'] = ((!item.address_1) ? '' : item.address_1) + ((!item.address_2) ? '' : ' ' + item.address_2)
                            + ((!item.address_3) ? '' : ' ' + item.address_3)
                    });
                })
            },
                (err) => {
                    this.setState({
                        isFetchingRepresentativeDetails: false,
                        representativeDetails: this.formatRepresentativeDetailsResponse(
                            JSON.parse(JSON.stringify({ ...newRepresentativeDetails })))
                    })
                    this.props.setModalList(newRepresentativeDetails);
                    console.log("Error in fetching Representative Details:", err)
                })
    }

    getRepresentativeTypes = () => {
        this.setState({ isFetchingRepresentativeTypes: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_representative_type', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                console.log(formattedList);
                this.setState({ representativeTypes: formattedList, isFetchingRepresentativeTypes: false });
            },
                (err) => {
                    this.setState({ isFetchingRepresentativeTypes: false });
                    console.log("Error in fetching Representative Types:", err)
                })
    }

    getAgencies = (agency_id) => {
        this.setState({ loading: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/agency?searchString=${agency_id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item =>
                    ({ value: item.agency_id, label: item.agency_name, agency_type: item.agency_type, agency_type_id: item.agency_type_id }));
                console.log(formattedList);
                this.setState({ agencyTypes: formattedList, loading: false });
            },
                (err) => {
                    this.setState({ loading: false });
                    console.log("Error in fetching Agencies:", err)
                })
    }

    validateErrorFlags = (representativeDetailsPostJson) => {
        this.setState({ agencySelected: !representativeDetailsPostJson.agencies[0].agency_id ? true : false })
        let canSubmit = ((
            (representativeDetailsPostJson.first_name 
                && representativeDetailsPostJson.type_id && representativeDetailsPostJson.agencies[0].agency_id) &&
            (representativeDetailsPostJson?.first_name?.length <= sizeLimits.nameCharacterLimit) &&
            (representativeDetailsPostJson?.last_name ? representativeDetailsPostJson?.last_name?.length <= sizeLimits.nameCharacterLimit: true) &&
            (representativeDetailsPostJson?.m_i ? representativeDetailsPostJson?.m_i?.length <= sizeLimits.miCharacterLimit : true) &&
            representativeDetailsPostJson.email_list.every(email =>
                !(email.email) ? true :
                    (email.email.length <= sizeLimits.emailCharacterLimit) && validateEmail(email.email)) &&
            representativeDetailsPostJson.phone_list.every(phone =>
                !(phone.phone) ? true :
                    !phone.representative_phone_id ? (validatePlusCharacter((phone.phone.trim()).charAt(0)) ?
                        validateIntlNumber(phone.phone) && phone.phone.length <= sizeLimits.phoneLimitIntl
                        :
                        (validateUSNumber(phone.phone) || validatePhoneNumber(phone.phone)) && phone.phone.length <= sizeLimits.phoneLimitUS) : true)
            &&

            (representativeDetailsPostJson.address_list?.every(address =>
                (address?.representative_address_id ? true : (address?.address_1 && address?.city && address?.state && address?.zip))
            )) &&
            representativeDetailsPostJson.address_list.every(address =>
                !(address.address_1) ? true :
                    (address.address_1.length <= sizeLimits.addressCharacterLimit)) &&

            representativeDetailsPostJson.address_list.every(address =>
                !(address.address_2) ? true :
                    (address.address_2.length <= sizeLimits.addressCharacterLimit)) &&

            representativeDetailsPostJson.address_list.every(address =>
                !(address.address_3) ? true :
                    (address.address_3.length <= sizeLimits.addressCharacterLimit)) &&

            representativeDetailsPostJson.address_list.every(address =>
                !(address.city) ? true :
                    (address.city.length <= sizeLimits.cityCharacterLimit)) &&

            representativeDetailsPostJson.address_list.every(address =>
                !(address.state) ? true : (address.state.length <= sizeLimits.stateCharacterLimit)) &&

            representativeDetailsPostJson.address_list.every(address =>
                !(address.zip) ? true : (address.zip.length <= (address.is_international === 1 ?
                    sizeLimits.intlZipCharacterLimit : sizeLimits.usZipCharacterLimit))
            ) &&

            (!this.state.checkedMultiPrimary.email_list) && (!this.state.checkedMultiPrimary.phone_list) &&
            (!this.state.checkedMultiPrimary.address_list)

        )) ? true : false;

        return canSubmit;
    }

    handleGeneralEdit = (field, value) => {
        this.setState(prevState => ({
            representativeDetails: {
                ...prevState.representativeDetails,
                [field]: value
            },
            postInitiated: false,
            formEdited: true
        }),
            () => {
                this.props.setModalList(this.state.representativeDetails);
                this.props.fieldChanged(this.state.formEdited, "change");
                this.setState({ repUpdated: true });
            }
        )

        if (field === "type_id") {
            this.setState(prevState => ({
                representativeDetails: {
                    ...prevState.representativeDetails,
                    type_name: this.state.representativeTypes?.find(type => type.value === value)?.label
                },
                postInitiated: false,
                formEdited: true
            })
                ,
                () => {
                    this.props.fieldChanged(this.state.formEdited, "change");
                })
        } else if (field === "agencies") {
            this.setState(prevState => ({
                representativeDetails: {
                    ...prevState.representativeDetails,
                    agency_type_name: value
                },
                postInitiated: false,
                formEdited: true
            }),
                () => {
                    this.props.fieldChanged(this.state.formEdited, "change");
                })
        }
    }

    formatRepresentativeDetailsResponse = (response) => {
        response?.email_list.push(JSON.parse(JSON.stringify({ ...initialRepresentativeEmail })));
        response?.phone_list.push(JSON.parse(JSON.stringify({ ...initialRepresentativePhone })));
        response?.address_list.push(JSON.parse(JSON.stringify({ ...initialRepresentativeAddress })));
        if (response?.agencies?.length === 0) {
            response?.agencies.push(JSON.parse(JSON.stringify({ ...initialRepresentativeAgencies })));
        } else {
            response?.agencies?.map((agency) => {
                let r = { 'value': agency.agency_id, 'label': agency.agency_name }
                agency['agency_selected'] = r;
            });
        }
        if (this.props?.repDetails !== undefined && Object.entries(this.props?.repDetails).length != 0) {
            response.prefix_id = this.props?.repDetails?.prefix_id || null;
            response.prefix = this.props?.repDetails?.prefix || null;
            response.first_name = this.props?.repDetails?.first_name;
            response.m_i = this.props?.repDetails?.middle_name || null;
            response.last_name = this.props?.repDetails?.last_name;
            response.suffix_id = this.props?.repDetails?.suffix_id || null;
            response.suffix = this.props?.repDetails?.suffix || null;
        }
        return response;
    }

    initializeCountry = (field, value) => {
        this.setState(prevState => ({
            representativeDetails: {
                ...prevState.representativeDetails,
                [field]: value
            },
            postInitiated: false
        }), () => {
            this.props.setModalList(this.state.representativeDetails);
        })
    }

    handleListEdit = (field, value, obj, listName, check = '') => {
        let representativeDetails = { ...this.state.representativeDetails };
        let id = (listName === "email_list") ? "representative_email_id" :
            (listName === "phone_list") ? "representative_phone_id" :
                (listName === "address_list") ? "representative_address_id" : "";
        let list = representativeDetails[listName]?.map(item => {
            let currentVal = item;
            if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                if (field === "is_primary" || field === "is_international") {
                    currentVal[field] = value ? 1 : 0;
                } else if (field === "address") {
                    currentVal = getAddress(field, currentVal, value, addressConfig);
                } else {
                    currentVal[field] = value;
                }
                return currentVal;
            } return currentVal;
        })
        this.setState(prevState => ({
            checkedMultiPrimary: {
                ...prevState.checkedMultiPrimary,
                [listName]: this.checkMultiplePrimary(listName, list)
            }
        }))
        if (check === 'Check') {
            this.initializeCountry(listName, list);
        } else {
            this.handleGeneralEdit(listName, list);
        }
    }

    checkMultiplePrimary = (listName, list) => {
        let multiplePrimary = list?.filter(item => item.is_primary === 1);
        return multiplePrimary.length > 1
    }

    setRepresentativeDetailsUniqueEntries = () => {
        let representativeDetails = { ...this.state.representativeDetails };
        representativeDetails.email_list = [...representativeDetails.email_list].filter((tag, index, array) =>
            (array.findIndex(t => t.email == tag.email && t.email_type == tag.email_type) == index) &&
            tag.email && tag.email_type);
        representativeDetails.phone_list = [...representativeDetails.phone_list].filter((tag, index, array) =>
            (array.findIndex(t => t.phone == tag.phone && t.phone_type == tag.phone_type) == index) &&
            tag.phone && tag.phone_type);
        representativeDetails.address_list = [...representativeDetails.address_list].filter((tag, index, array) =>
            (array.findIndex(t => t.address_1 == tag.address_1 && t.address_2 == tag.address_2 &&
                t.address_3 == tag.address_3 && t.address_type == tag.address_type) == index) && (tag.address_1 ||
                    tag.address_2 || tag.address_3 || tag.city || tag.state || tag.zip));
        return representativeDetails;
    }

    formatAgencies = (field, value) => {
        let representativeDetails = { ...this.state.representativeDetails };
        representativeDetails[field].map(item => {
            item['agency_id'] = !value ? null : value?.value;
            item['agency_name'] = !value ? null : value?.label;
            item['agency_type'] = !value ? null : value?.agency_type;
            item['agency_type_id'] = !value ? null : value?.agency_type_id;
            item['agency_selected'] = !value ? null : value;
        })
        this.setState({
            representativeDetails: representativeDetails, postInitiated: false,
            formEdited: true, agencyTypes: [], agencySelected: !value ? true : false
        })
        this.props.fieldChanged(this.state.formEdited, "change");
    }

    addAdditionalField = (listName) => {
        let representativeDetails = { ...this.state.representativeDetails };
        switch (listName) {
            case 'email_list':
                let initialEmailArray = JSON.parse(JSON.stringify({ ...initialRepresentativeEmail }));
                let email_list = [...representativeDetails.email_list];
                initialEmailArray.index = this.state.emailIndex;
                initialEmailArray.canRemove = true;
                email_list.push(initialEmailArray)
                representativeDetails.email_list = email_list;
                this.setState({ emailIndex: this.state.emailIndex + 1, representativeDetails: representativeDetails });
                break;
            case 'phone_list':
                let initialPhoneArray = JSON.parse(JSON.stringify({ ...initialRepresentativePhone }));
                let phone_list = [...representativeDetails.phone_list];
                initialPhoneArray.index = this.state.phoneIndex;
                initialPhoneArray.canRemove = true;
                phone_list.push(initialPhoneArray)
                representativeDetails.phone_list = phone_list;
                this.setState({ phoneIndex: this.state.phoneIndex + 1, representativeDetails: representativeDetails });
                break;
            case 'address_list':
                let initialAddressArray = JSON.parse(JSON.stringify({ ...initialRepresentativeAddress }));
                let address_list = [...representativeDetails.address_list];
                initialAddressArray.index = this.state.addressIndex;
                initialAddressArray.canRemove = true;
                address_list.push(initialAddressArray)
                representativeDetails.address_list = address_list;
                this.setState({ addressIndex: this.state.addressIndex + 1, representativeDetails: representativeDetails });
                break;
        }
    }

    removeField = (listName, callbackItem) => {
        let representativeDetails = { ...this.state.representativeDetails };
        if (!callbackItem.canRemove) {
            let index = representativeDetails[listName].indexOf(callbackItem);
            switch (listName) {
                case 'email_list':
                    representativeDetails[listName][index] = JSON.parse(JSON.stringify({ ...initialRepresentativeEmail }));
                    break;
                case 'phone_list':
                    representativeDetails[listName][index] = JSON.parse(JSON.stringify({ ...initialRepresentativePhone }));
                    break;
                case 'address_list':
                    representativeDetails[listName][index] = JSON.parse(JSON.stringify({ ...initialRepresentativeAddress }));
                    break;
            }
        } else {
            let list = representativeDetails[listName]?.filter(item => item.index !== callbackItem.index);
            representativeDetails[listName] = list;
        }
        this.setState({ representativeDetails: representativeDetails });
    }

    checkIfPrimaryIsChecked = (representativeDetails) => {
        if (representativeDetails.email_list.length !== 0 && !representativeDetails.email_list.some(item => item.is_primary == 1)) {
            representativeDetails.email_list[0].is_primary = 1;
        }
        if (representativeDetails.phone_list.length !== 0 && !representativeDetails.phone_list.some(item => item.is_primary == 1)) {
            representativeDetails.phone_list[0].is_primary = 1;
        }
        if (representativeDetails.address_list.length !== 0 && !representativeDetails.address_list.some(item => item.is_primary == 1)) {
            representativeDetails.address_list[0].is_primary = 1;
        }
        return representativeDetails;
    }

    postRepresentativeDetails = (representativeDetailsPostJson) => {
        this.props.setPostFlag(true);
        if(this.props?.talentId){
            representativeDetailsPostJson.talent_id = this.props?.talentId;
        }
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/representative', representativeDetailsPostJson, this.props.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                if (response.data.error) {
                    this.props.setPostFlag(false);
                    this.props.fieldChanged(true, "fail");
                } else {
                    if (this.props.isProfileChanged) {
                        this.props.uploadProfile(response.data[0].representative_id);
                    }
                  
                    this.setState({ postInitiated: false, formEdited: false });
                    this.props.setPostFlag(false);
                    this.props.fieldChanged(true, "success");
                    representativeDetailsPostJson.representative_id = response?.data[0]?.representative_id;
                    this.setState({
                        representativeDetails: this.formatRepresentativeDetailsResponse(
                            JSON.parse(JSON.stringify({ ...newRepresentativeDetails })))
                    },()=>{
                        if (this.props?.updateDetailsToParent) {
                            this.props?.updateDetailsToParent(representativeDetailsPostJson)
                        }
                    });
                }
            },
                (err) => {
                    this.props.fieldChanged(true, "fail");
                    this.props.setPostFlag(false);
                    console.log("Post representative details error: " + err);
                });
    }

    handleSubmit = () => {
        let representativeDetailsPostJson = this.checkIfPrimaryIsChecked(this.setRepresentativeDetailsUniqueEntries());
        this.setState({ postInitiated: true }, () => {
            if (this.validateErrorFlags(representativeDetailsPostJson)) {
                this.editPhoneNumbers(representativeDetailsPostJson);
                this.postRepresentativeDetails(representativeDetailsPostJson);
            } else {

            }
        })
    }

    editPhoneNumbers = (representativeDetailsPostJson) => {
        representativeDetailsPostJson.phone_list.map(phone => {
            if (phone.phone.length === 10) {
                phone.phone = '(' + phone.phone.substr(0, 3) + ')' + ' ' + phone.phone.substr(3, 3) + '-' + phone.phone.substr(6, 4);
            }
        }
        )
    }

    getRepresentativeList = (rep_id) => {
        this.setState({ isFetchingRepresentativeList: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/representativeTalentAgency?representativeId=${rep_id}`, 
        this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => {
                    item.talent_name = item.akas !== null ? item.talent_name + "(" + item.akas + ")" : item.talent_name;
                    item.created_at = getDateAndTime(item.created_at);
                    return item;
                });
                this.setState({ representativeList: formattedList, isFetchingRepresentativeList: false });
                this.props.setModalList(this.props.navObj);
            },
                (err) => {
                    this.setState({ isFetchingRepresentativeList: false });
                    console.log("Error in fetching Representative List:", err)
                })
    }

    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 0: return <RepresentativeDetails
                setModalList={this.props.setModalList}
                setPostFlag={this.props.setPostFlag}
                navObj={this.props.navObj || {}}
                mode={this.props.mode || "new"}
                repId={this.props.repId}
                submitButtonRef={this.props.submitButtonRef}
                fieldChanged={this.props.fieldChanged}
                isProfileChanged={this.props?.isProfileChanged}
                uploadProfile={this.props?.uploadProfile}
                isFetchingRepresentativeDetails={this.state.isFetchingRepresentativeDetails}
                isFetchingRepresentativeTypes={this.state.isFetchingRepresentativeTypes}
                representativeTypes={this.state.representativeTypes}
                representativeDetails={this.state.representativeDetails}
                handleSubmit={this.handleSubmit}
                postInitiated={this.state.postInitiated}
                agencyTypes={this.state.agencyTypes}
                handleGeneralEdit={this.handleGeneralEdit}
                formatAgencies={this.formatAgencies}
                getAgencies={this.getAgencies}
                loading={this.state.loading}
                agencySelected={this.state.agencySelected}
                handleListEdit={this.handleListEdit}
                addAdditionalField={this.addAdditionalField}
                removeField={this.removeField}
                checkedMultiPrimary={this.state.checkedMultiPrimary}
            />;
            case 1: return <RepresentedTalentList
                representativeList={this.state.representativeList}
                isLoading={this.state.isFetchingRepresentativeList}
                handleSubmit={this.handleSubmit}
                repUpdated={this.state.repUpdated}
                submitButtonRef={this.props.submitButtonRef}
                navFuns={this.props.navFuns} />;
        }
    }

    render() {
        return (
            <div className="RepresentativeContainer">
                <input className="hiddenButton" type="button" ref={this.props.submitButtonRef} onClick={() => this.handleSubmit()}></input>
                {this.getTabComponent()}
            </div>
        );
    }
}

export default withUserContext(RepresentativeContainer);