import React from 'react';
import { withUserContext } from '../../../contexts/UserContext';
import TalentPersonal from './TalentPersonal';
import TalentRepresentation from './TalentRepresentation';
import TalentProjects from './TalentProjects';
import TalentSubmissions from './TalentSubmissions';
import './Talent.scss';
import {
    talentInitialDetails, talentAddress, talentPhone,
    talentEmail, talentAka, talentRepresentation, talentPersonalLimit
} from './Config';
import ScripterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { validateEmail } from '../../../Common/Helper';

class TalentContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            mode: "new",
            navObj: {},
            postInitiated: false,
            talentDetails: {},
            projectList: [],
            submissionList: [],
            emailIndex: 2,
            phoneIndex: 2,
            addressIndex: 2,
            akaIndex: 2,
            repIndex: 2,
            isFetchingTalentDetails: false,
            isFetchingProjectsList: false,
            isFetchingSubmissionList: false,
            checkedMultiPrimary: {
                email_list: false,
                representation_list: false,
            },
            aesKey: '',
            listProdFiles: {
                "signedURLType": "",
                "fileCategory": "",
                "tenantName": props?.userContext?.active_tenant?.tenant_name,
                "objectType": "",
                "fileName": "",
                "uploadLocation": "",
                "showName": "",
                "seasonName": "",
                "showId": null,
                "seasonId": null,
                "episodeId": null,
                "episodeName": "",
                "performerName": null,
                "performerId": null,
                "talentId": null
            },
            performerFiles: [],

        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.tabValue !== state.tabValue || props.mode !== state.mode || (JSON.stringify(props.navObj) !== JSON.stringify(state.navObj) && props.navObj)) {
            return {
                tabValue: props.tabValue,
                mode: props.mode,
                navObj: props.navObj || {}
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.mode !== prevState.mode || this.state.navObj !== prevState.navObj || 
            (prevProps?.talentDetailsSaved != this.props?.talentDetailsSaved && this.state.mode != "edit")
        ) {
            if (this.state.mode === "new" && (this.state.talentDetails.talent_id || this.state.talentDetails?.talent_group_id)) {
                this.setState({ 
                    talentDetails: this.formatAgencyDetailsResponse(JSON.parse(JSON.stringify({ ...talentInitialDetails }))), 
                    performerFiles: [] 
                }, () => {
                    this.props.setModalList(this.state.talentDetails);
                })
            } else if (this.state.mode === "edit") {
                // this.getAes();
                this.getTalentDetails();
                //this.loadPerformerFilesData();
            }
        }
    }


    componentDidMount() {
        if (this.props.mode === "new") {
            if (Object.entries(this.state.talentDetails).length === 0) {
                this.setState({ talentDetails: this.formatAgencyDetailsResponse(JSON.parse(JSON.stringify({ ...talentInitialDetails }))) }, () => {
                    this.props.setModalList(this.state.talentDetails);
                })
            }
        }
        else if (this.props.mode === "edit") {
            this.getTalentDetails();
            // this.filterTalentRoles();
        }
    }
    
    getTalentDetails = (details = null) => {
        this.setState({ isFetchingTalentDetails: true });
        let url;
        let talent_id = details?.talent_id || this.state.navObj.talent_id;
        let talent_group_id = details?.talent_group_id || this.state.navObj.talent_group_id;
        if (!talent_group_id) {
            url = `/talent?searchString=${talent_id}&searchKey=id`;
        } else {
            url = `/talent?searchString=${talent_group_id}&searchKey=id&writerTeam=1`
        }
        ScripterService.getData(Constants.scripterServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, null)
            .then(response => {
                console.log("talentResp", response);
                let formattedResponse = this.formatAgencyDetailsResponse(response?.data[0]);
                if (formattedResponse?.talent_group_id) {
                    formattedResponse['writer_team'] = 1;
                }
                this.setState({ talentDetails: formattedResponse, isFetchingTalentDetails: false }, this.loadPerformerFilesData);
                this.props.setModalList(formattedResponse)
                this.state.talentDetails?.address_list?.map((item) => {
                    item['address'] = ((!item.address_1) ? '' : item.address_1) + ((!item.address_2) ? '' : ' ' + item.address_2)
                        + ((!item.address_3) ? '' : ' ' + item.address_3)
                });
            },
                (err) => {
                    this.setState({ isFetchingTalentDetails: false, talentDetails: this.formatAgencyDetailsResponse(JSON.parse(JSON.stringify({ ...talentInitialDetails }))) })
                    console.log("Error in fetching Talent Details:", err)
                })
    }
    handleTalentSubmit = () => {
        let talentDetailsPostJson = this.checkIfPrimaryIsChecked(this.setTalentDetailsUniqueEntries());
        this.setState({ postInitiated: true }, () => {
            if (this.validateErrorFlags(talentDetailsPostJson)) {
                this.postTalentDetails(talentDetailsPostJson)
            } else {
                console.log("Submit Fail")
            }
        })
        console.log("talentjson", talentDetailsPostJson)
    }

    
    checkMultiplePrimary = (list) => {
        let multiplePrimary = list?.filter(item => item.is_primary === 1);
        return multiplePrimary.length > 1
    }
    postTalentDetails = (talentDetails) => {
        this.props.setPostFlag(true);
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/talent', talentDetails, this.props.userContext?.active_tenant?.tenant_id, null)
            .then((response) => {
                if (response?.data?.error) {
                    //Add your error message to alert box from response.data.error
                    this.props.fieldChanged(true, "fail");
                    this.props.setPostFlag(false);
                    this.setState({ postInitiated: false })
                } else {
                    // let id = response?.data[0]?.talent_id || response?.data[0]?.talent_group_id;
                    if (this.props.isProfileChanged) {
                        let id = response?.data[0]?.talent_id || response?.data[0]?.talent_group_id;
                        this.props.uploadProfile(id);
                    }
                    this.props.fieldChanged(false, "error");
                    this.props.fieldChanged(true, "success");
                    this.props.setPostFlag(false);
                    talentDetails.talent_id = response?.data[0]?.talent_id || null;
                    talentDetails.talent_group_id = response?.data[0]?.talent_group_id || null;
                    this.setState({ 
                        postInitiated: false, 
                        talentDetails: this.formatAgencyDetailsResponse(JSON.parse(JSON.stringify({ ...talentDetails }))) 
                    }, () => {
                        if (this.props?.updateDetailsToParent) {
                            this.props?.updateDetailsToParent(talentDetails)
                        }
                    })
                    if(this.props?.talentDetailsSaved) {
                        this.getTalentDetails(talentDetails);
                    }
                }
            },
                (err) => {
                    this.props.fieldChanged(true, "fail");
                    this.props.setPostFlag(false);
                    this.setState({ postInitiated: false })
                    console.log("Post talent details error: " + err);
                });
    }
    formatAgencyDetailsResponse = (response) => {
        response?.email_list?.push(JSON.parse(JSON.stringify({ ...talentEmail })));
        if (response?.akas?.length === 0) {
            response?.akas?.push(JSON.parse(JSON.stringify({ ...talentAka })));
        }
        if (this.props?.talentDetails) {
            response.prefix_id = this.props?.talentDetails?.prefix_id || null;
            response.prefix = this.props?.talentDetails?.prefix || null;
            response.first_name = this.props?.talentDetails?.first_name;
            response.m_i = this.props?.talentDetails?.middle_name || null;
            response.last_name = this.props?.talentDetails?.last_name || null;
            response.suffix_id = this.props?.talentDetails?.suffix_id || null;
            response.suffix = this.props?.talentDetails?.suffix || null;
        }
       return response;
    }
    loadPerformerFilesData = () => {

        var listProdFilesOther = { ...this.state.listProdFiles };
        listProdFilesOther["objectType"] = "Performer";
        // listProdFilesOther["fileCategory"] = FileCategoryJSON["FilesPerformer"];;
        listProdFilesOther["uploadLocation"] = "Performer";
        listProdFilesOther["tenantName"] = this.props.userContext?.active_tenant?.tenant_name;
        listProdFilesOther["talentId"] = this.state?.navObj.talent_id;
        listProdFilesOther["performerName"] = this.state?.talentDetails?.first_name + " " + this.state?.talentDetails?.last_name;
    }

    addAdditionalField = (listName) => {
        let talentDetails = { ...this.state.talentDetails };
        switch (listName) {
            case 'email_list':
                let initialEmailArray = { ...talentEmail };
                let email_list = [...talentDetails.email_list];
                initialEmailArray.index = this.state.emailIndex;
                initialEmailArray.canRemove = true;
                email_list.push(initialEmailArray)
                talentDetails.email_list = email_list;
                this.setState({ emailIndex: this.state.emailIndex + 1, talentDetails: talentDetails });
                break;
            case 'akas':
                let initialAkaArray = { ...talentAka };
                let akas = [...talentDetails.akas];
                initialAkaArray.index = this.state.akaIndex;
                initialAkaArray.canRemove = true;
                akas.push(initialAkaArray)
                talentDetails.akas = akas;
                this.setState({ akaIndex: this.state.akaIndex + 1, talentDetails: talentDetails });
                break;
        }
    }

    checkMultiplePrimary = (list) => {
        let multiplePrimary = list?.filter(item => item.is_primary === 1);
        return multiplePrimary.length > 1
    }

    removeField = (listName, callbackItem) => {
        let talentDetails = { ...this.state.talentDetails };
        if (!callbackItem.canRemove) {
            let index = talentDetails[listName].indexOf(callbackItem);
            if (index > -1) {
                talentDetails[listName].splice(index, 1);
            }
        } else {
            let list = talentDetails[listName]?.filter(item => item.index !== callbackItem.index);
            talentDetails[listName] = list;
        }
        this.setState({ talentDetails: talentDetails });
    }

    handleGeneralEdit = (field, value) => {
        let newValue = value;
        if (typeof value === "boolean") {
            newValue = value ? 1 : 0
        }
        if (field === "company") {
            newValue = value === "" ? null : value
        }
        this.setState(prevState => ({
            talentDetails: {
                ...prevState.talentDetails,
                [field]: newValue
            },
            postInitiated: false
        }), () => {
            this.props.setModalList(this.state.talentDetails);
            this.props.fieldChanged(true, "change");
            this.setState({ talentUpdated: true });
        })
    }

    handleListEdit = (field, value, obj, listName, check = '') => {
        let talentDetails = { ...this.state.talentDetails };
        let id = (listName === "email_list") ? "talent_email_id" :
            (listName === "akas") ? "talent_aka_id" :
                (listName === "representation_list") ? "talent_representative_agency_mtrx_id" : "";
        let list = talentDetails[listName]?.map(item => {
            let currentVal = item;
            if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                if (field === "is_primary") {
                    currentVal[field] = value ? 1 : 0;
                } else {
                    currentVal[field] = value;
                }
                return currentVal;
            } return currentVal;
        })
        this.setState(prevState => ({
            checkedMultiPrimary: {
                ...prevState.checkedMultiPrimary,
                [listName]: this.checkMultiplePrimary(list)
            }
        }))
        if (check === 'Check') {
            this.initializeCountry(listName, list);
        } else {
            this.handleGeneralEdit(listName, list);
        }
    }

    handleRepListChange = (list, obj, listName) => {
        let talentDetails = { ...this.state.talentDetails };
        let id = "talent_representative_agency_mtrx_id";
        let newList = []
        if (listName === "agency") {
            newList = talentDetails.representation_list?.map(item => {
                if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                    item.agency_id = list?.value || null;
                    item.phone = list?.phone || null;
                    item.agency_email = list?.email || null;
                    item.agency_email_id = list?.emailType || null;
                    item.agency_phone_id = list?.phoneType || null;
                    item.agency_name = list?.text || null;
                    if (!list) {
                        item.address_1 = null;
                        item.address_2 = null;
                        item.address_3 = null;
                        item.city = null;
                        item.country = null;
                        item.country_id = null;
                        item.representative_address_id = null;
                        item.representative_email = null;
                        item.representative_email_id = null;
                        item.representative_id = null;
                        item.representative_name = null;
                        item.representative_phone = null;
                        item.representative_phone_id = null;
                        item.representative_type = null;
                        item.representative_type_id = null;
                        item.state = null;
                        item.state_id = null;
                        item.zip = null;
                        item.is_primary = list?.is_primary || 0;
                    }
                    return item;
                } return item;
            });
        } else if (listName === "representative") {
            newList = talentDetails.representation_list?.map(item => {
                if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                    item.address_1 = list?.address_1 || null;
                    item.address_2 = list?.address_2 || null;
                    item.address_3 = list?.address_3 || null;
                    item.city = list?.city || null;
                    item.country = list?.country || null;
                    item.country_id = list?.country_id || null;
                    item.representative_address_id = list?.address_id || null;
                    item.representative_email = list?.email || null;
                    item.representative_email_id = list?.email_id || null;
                    item.representative_id = list?.representative_id || null;
                    item.representative_name = list?.representative_name || null;
                    item.representative_phone = list?.phone || null;
                    item.representative_phone_id = list?.phone_id || null;
                    item.representative_type = list?.type_name || null;
                    item.representative_type_id = list?.type_id || null;
                    item.state = list?.state || null;
                    item.state_id = list?.state_id || null;
                    item.zip = list?.zip || null;
                    item.is_primary = list?.is_primary || 0;
                    return item;
                } return item;
            });
        }
        this.handleGeneralEdit("representation_list", newList);
    }

    addRepresentationList = () => {
        let talentDetails = { ...this.state.talentDetails };
        let initialRepList = { ...talentRepresentation };
        let representation_list = [...talentDetails.representation_list];
        initialRepList.index = this.state.repIndex;
        initialRepList.canRemove = true;
        representation_list.push(initialRepList)
        talentDetails.representation_list = representation_list;
        this.setState({ repIndex: this.state.repIndex + 1, talentDetails: talentDetails });
    }

    getTabComponent = () => {
        switch (this.props.tabValue) {
            case 0: return <TalentPersonal
                talentDetails={this.state.talentDetails}
                postInitiated={this.state.postInitiated}
                checkMultiplePrimary={this.checkMultiplePrimary}
                addCallback={this.addAdditionalField}
                removeCallback={this.removeField}
                handleListEdit={this.handleListEdit}
                isLoading={this.state.isFetchingTalentDetails}
                handleGeneralEdit={this.handleGeneralEdit} 
                initialTalentDetails={this.state.talentDetails}
                // initialTalentDetails={this.props?.navObj}
            />;
            case 1: return <TalentRepresentation
                representativeDetails={this.state.talentDetails.representation_list}
                removeCallback={this.removeField}
                handleListEdit={this.handleListEdit}
                navFuns={this.props.navFuns}
                postInitiated={this.state.postInitiated}
                handleRepListChange={this.handleRepListChange}
                checkMultiplePrimary={this.checkMultiplePrimary}
                isLoading={this.state.isFetchingTalentDetails}
                addRepresentationList={this.addRepresentationList} 
                talentUpdated={this.state.talentUpdated}
            />;
            case 2: return <TalentProjects
                representativeList={this.state.projectList}
                isLoading={this.state.isFetchingProjectsList}
                handleSubmit={this.handleTalentSubmit}
                submitButtonRef={this.props.submitButtonRef}
                navFuns={this.props.navFuns}
                talentId={this.state.navObj.talent_id}
                talentGroupId={this.state.navObj.talent_group_id}
                talentUpdated={this.state.talentUpdated}
                // mode={this.props.mode}
                // navFuns={this.props.navFuns}
            />;
            case 3: return <TalentSubmissions
                submissionList={this.state.submissionList}
                isLoading={this.state.isFetchingSubmissionList}
                submitButtonRef={this.props.submitButtonRef}
                handleSubmit={this.handleTalentSubmit}
                talentId={this.state.navObj.talent_id}
                talentGroupId={this.state.navObj.talent_group_id}
                mode={this.props.mode}
                navFuns={this.props.navFuns} 
                talentUpdated={this.state.talentUpdated}
            />;
        }
    }
    validateErrorFlags = (talentDetails) => {
        let canSubmit = false;
        if (!talentDetails?.writer_team) {
            canSubmit = (talentDetails?.first_name &&
                (talentDetails?.first_name?.length <= talentPersonalLimit.firstName) &&
                (talentDetails?.last_name ? talentDetails?.last_name?.length <= talentPersonalLimit.lastName : true) &&
                (talentDetails?.m_i ? talentDetails?.m_i?.length <= talentPersonalLimit.mi : true) &&
                // (talentDetails?.prefix ? talentDetails?.prefix <= talentPersonalLimit.prefix : true) &&
                // (talentDetails?.suffix ? talentDetails?.suffix <= talentPersonalLimit.suffix : true) &&
                (talentDetails?.role_list?.length > 0) &&
                (talentDetails?.akas?.length !== 0 ? talentDetails?.akas?.every(aka => (aka?.aka && aka?.aka?.length <= talentPersonalLimit.aka)) : true) &&
                (talentDetails?.email_list?.length !== 0 ? (talentDetails?.email_list?.every(email => (email?.email) ? true : false)) : true) &&
                (!this.checkMultiplePrimary(talentDetails.email_list)) &&
                (!this.checkMultiplePrimary(talentDetails.representation_list)) &&
                (!talentDetails.representation_list?.some(rep => !rep.representative_id || !rep.agency_id)) &&
                (talentDetails?.performer_notes ? talentDetails?.performer_notes?.length <= talentPersonalLimit.performerNotes : true)
            )
        } else {
            canSubmit = ((talentDetails?.akas?.length !== 0 ? talentDetails?.akas?.every(aka => (aka?.aka && aka?.aka?.length <= talentPersonalLimit.aka)) : true) &&
                (talentDetails?.email_list?.length !== 0 ? (talentDetails?.email_list?.every(email => (email?.email) ? true : false)) : true) &&
                (talentDetails?.talent_group_members_list?.length > 0) &&
                (talentDetails?.talent_group_members_list?.length > 0 ? (talentDetails?.talent_group_members_list?.every(team => (team?.talent_id) ? true : false)) : true) &&
                (talentDetails?.role_list?.length > 0) &&
                (!this.checkMultiplePrimary(talentDetails.email_list)) &&
                (!this.checkMultiplePrimary(talentDetails.representation_list)) &&
                (!talentDetails.representation_list?.some(rep => !rep.representative_id || !rep.agency_id)) &&
                (talentDetails?.performer_notes ? talentDetails?.performer_notes?.length <= talentPersonalLimit.performerNotes : true)
            );
        }
        return canSubmit;
    }

    checkIfPrimaryIsChecked = (talentDetails) => {
        if (talentDetails?.email_list?.length !== 0 && !talentDetails?.email_list?.some(item => item.is_primary == 1)) {
            talentDetails.email_list[0].is_primary = 1;
        }
        if (talentDetails?.representation_list?.length !== 0 && !talentDetails?.representation_list?.some(item => item.is_primary == 1)) {
            talentDetails.representation_list[0].is_primary = 1;
        }
        return talentDetails;
    }
    setTalentDetailsUniqueEntries = () => {
        let talentDetails = { ...this.state.talentDetails };
        console.log("talentuni", this.state.talentDetails)
        talentDetails.email_list = [...talentDetails.email_list].filter((tag, index, array) =>
            (array.findIndex(t => t.email == tag.email && t.email_type == tag.email_type) == index) &&
            tag.email && tag.email_type);
        talentDetails.akas = [...talentDetails.akas].filter((tag, index, array) =>
            (array.findIndex(t => t.aka == tag.aka) == index) && tag.aka);
        return talentDetails;
    }

    render() {
        return (
            <div className="TalentContainer">
                <input className="hiddenButton" type="button" ref={this.props.submitButtonRef} onClick={() => this.handleTalentSubmit()}></input>
                {this.getTabComponent()}
            </div>
        );
    }
}

export default withUserContext(TalentContainer);