import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SearchResultsLayout from './SearchResultsLayout';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import './AutoCompleteSearch.scss';
import SearchIcon from "@material-ui/icons/Search";
import scripterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import axios from 'axios';
import { MDBIcon } from 'mdbreact';
import { withUserContext } from '../../../contexts/UserContext';

export default withUserContext(class AutoCompleteSearchField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            options: [],
            loading: false,
            isOpen: false,
            parentDropDownSelection: 1,
            childDropDownSelection: [],
            defautSelection: [],
            searchKeyWord: '',
            searchCancelToken: null,
            aesKey: ''
        }
    }
    componentDidMount() {
        if (this.props.encryptionType == 2) {
            this.getAes();
        }
    }
    filterOptions = createFilterOptions({
        stringify: (option) => option.name
    });
    sleep = (delay = 0) => {
        return new Promise((resolve) => {
            setTimeout(resolve, delay);
        });
    }
    onChangeHandle = async value => {
        let option = {
            "searchString": value,
        }
        let active = true;
        this.setState({ searchKeyWord: value })
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request.");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });

        if (!this.state.loading) {
            return undefined;
        }
        if (value == '') {
            this.setState({ loading: false, options: [] })
            return undefined;
        }
        let path = '';
        if (this.props.objectType) {
            let objectType = this.props.objectType;
            path = Constants.scripterServiceBaseUrl + '/entitySearch' + '?entity='+objectType+'&searchString='+value
        } else {
            path = Constants.scripterServiceBaseUrl + '/' + this.props.dataUrl + '?searchString='+value
        }
        // let path = '';
        // if (this.props.headerText == 'Talent') {
        //     path = Constants.scripterServiceBaseUrl + '/' + this.props.dataUrl + `?entity=TALENT&searchString=${value}`
        // } else {
        //     path = Constants.scripterServiceBaseUrl + '/' + this.props.dataUrl + `?entity=` + this.props.objectType`&searchString=${value}`
        // }
        (async () => {
            scripterService.getDataWithCancel(path,
                cancelToken, this.props.userContext?.active_tenant?.tenant_id, null, (this.props.encryptionType || 1), this.state.aesKey)
                .then(response => {
                    if (active) {
                        if (this.props.headerText === "User Management Group") {
                            let formattedList = response.data?.map(item => ({ name: item.name, id: item.production_company_id }));
                            this.setState({ options: formattedList, loading: false });
                        } if (this.props.headerText === "Talent") {
                            let formattedList = response.data?.map(item => ({ name: item.talent_name, id: item.talent_id, writerTeam: item.writer_team }));
                            this.setState({ options: formattedList || [], loading: false });
                            console.log("talentFormatted", formattedList);
                        }
                        if (this.props.headerText === "Representatives") {
                            let formattedList = response.data?.map(item => ({ name: item.representative_name, id: item.representative_id }));
                            this.setState({ options: formattedList || [], loading: false });
                        }
                        if (this.props.headerText === "Companies") {
                            let formattedList = response.data?.map(item => ({ name: item.agency_name, id: item.agency_id }));
                            this.setState({ options: formattedList || [], loading: false });
                        }
                        // else {
                        //     this.setState({ options: response.data, loading: false });
                        // }
                    }
                },
                    (err) => {
                        console.log("Error in fetching Address Types:", err)
                    })
        })();
        return () => {
            active = false;
        };
    };
    filterOptions = createFilterOptions({
        stringify: (option) => option.name
    });
    handleParentSearchChange = (event) => {
        this.setState({ parentDropDownSelection: event.target.value, defautSelection: [] })
    };
    getAes = () => {
        scripterService.getData(Constants.scripterServiceBaseUrl + `/aesSecretKey`)
            .then(response => {
                let secretKey = response?.data;
                this.setState({ aesKey: secretKey })
            },
                (err) => {
                    console.log("Error in AES:", err)
                })
    }
    render() {
        console.log("options", this.state.options);
        return (
            <div id='searchAutoComplete'>
                <Autocomplete
                    id="asynchronous-demo" className='searchautoCompleteStyle'
                    open={this.state.open}
                    onOpen={() => {
                        this.setState({ open: true })
                    }}
                    onClose={() => {
                        this.setState({ open: false })
                    }}
                    renderOption={(props, option) => (
                        <>
                            <li {...props}>
                                <SearchResultsLayout searchResult={props}
                                    headerText={this.props.headerText}></SearchResultsLayout>
                            </li>
                        </>
                    )}
                    onChange={(event, newValue) => {
                        { console.log("newValue", newValue) }
                        if (newValue) {
                            let path = ''
                            if (this.props.objectType == 'AGENCY' || this.props.objectType == 'REPRESENTATIVE' ||
                                this.props.objectType == 'TALENT') {
                                if (this.props.objectType == 'TALENT') {
                                    path = Constants.scripterServiceBaseUrl + `/talent?searchKey=id&searchString=${newValue.id}&writerTeam=${newValue.writerTeam}`
                                } else {
                                    path = Constants.scripterServiceBaseUrl + '/' + this.props.dataUrl + `?searchKey=id&searchString=${newValue.id}`
                                }
                                var cancelToken = axios.CancelToken.source();
                                // CasterService.getEncryptedData(path, this.props.userContext?.active_tenant?.tenant_id, this.state.aesKey)
                                scripterService.getDataWithCancel(path, cancelToken, this.props.userContext?.active_tenant?.tenant_id, null, (this.props.encryptionType || 1), this.state.aesKey)
                                    .then(response => {
                                        console.log("repres", response);
                                        // console.log("objectType", this.props.objectType);
                                        this.props.handleSearchSelect(this.props.objectType == 'TALENT' ? response?.data[0] : response?.data[0]);
                                        // console.log("response", response[0]);
                                    },
                                        (err) => {
                                        })
                            }
                            else {
                                this.props.handleSearchSelect(newValue);
                            }
                        }
                    }}
                    getOptionLabel={(option) => (this.props.headerText === "Companies" ?
                        option?.name : (this.props.headerText === "User Management Group" ? option?.name :
                            (this.props.headerText === "Production Companies" ? option?.name :
                                (this.props.headerText === "Talent" ? option?.name :
                                    (this.props.headerText === "Representatives" ? option?.name : option?.name)))))}
                    options={this.state.options}
                    loading={this.state.loading}
                    renderTags={() => null}
                    noOptionsText="No labels"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={this.props?.placeholder}
                            variant="outlined"
                            onChange={ev => {
                                if (ev.target.value !== "" || ev.target.value !== null) {
                                    this.setState({ loading: true }, () => { this.onChangeHandle(ev.target.value) })
                                } else { this.setState({ loading: true, options: [] }) }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {this.state.loading ?
                                            <CircularProgress color="inherit" size={20} /> : null}
                                        {this.props.headerText === "User Management Group" ? <MDBIcon icon="chevron-right" /> : <SearchIcon />}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        );
    }
}
);