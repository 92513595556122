export const agentListConfig = {
    headings: [
        {
            headingLabel: "Rep. Type",
            icon: "",
            isColumn: true
        },
        {
            headingLabel: "Rep. Name",
            icon: "",
            isColumn: true
        },
        {
            headingLabel: "Covered Talent",
            icon: "",
            isColumn: true
        },
        {
            headingLabel: "Datetime Added",
            icon: "",
            isColumn: true
        }],
    dataNodes: ["representative_type", "representative_name", "talent_name", "representative_created_at"],
    primaryKey: "talent_representative_agency_mtrx_id",
    hyperlinks: ["representative_name", "talent_name"]
};

export const coveredTalentConfig = {
    headings: [
        {
            headingLabel: "Talent Name",
            icon: "",
            isColumn: true
        },
        {
            headingLabel: "Rep. Type",
            icon: "",
            isColumn: true
        },
        {
            headingLabel: "Rep. Name",
            icon: "",
            isColumn: true
        },
        {
            headingLabel: "Datetime Added",
            icon: "",
            isColumn: true
        }],
    dataNodes: ["talent_name", , "representative_type", "representative_name", "talent_created_at"],
    primaryKey: "talent_representative_agency_mtrx_id",
    hyperlinks: ["talent_name", "representative_name"]
};

export const initialAgencyPhone = {
    "agency_phone_id": null,
    "phone_type_id": null,
    "phone_type": "",
    "is_international": 0,
    "phone": "",
    "is_primary": 0,
    "index": 1
}

export const phoneConfig = {
    "phoneTypeId": "phone_type_id",
    "phoneType": "phone_type",
    "phone": "phone",
    "isPrimary": "is_primary",
    "isInternational": "is_international"
}

export const initialAgencyEmail = {
    "agency_email_id": null,
    "email_type_id": null,
    "email_type": null,
    "email": null,
    "is_primary": 0,
    "index": 1
}

export const emailConfig = {
    "emailTypeId": "email_type_id",
    "emailType": "email_type",
    "email": "email",
    "isPrimary": "is_primary"
}

export const initialAgencyAddress = {
    "agency_address_id": null,
    "address_type_id": null,
    "address_type": null,
    "address_1": null,
    "address_2": null,
    "address_3": null,
    "address": null,
    "city": null,
    "state_id": "",
    "state": null,
    "country_id": 0,
    "country": null,
    "zip": null,
    "is_primary": 0,
    "is_international": 0,
    "index": 1
}

export const addressConfig = {
    "addressTypeId": "address_type_id",
    "addressType": "address_type",
    "address1": "address_1",
    "address2": "address_2",
    "address3": "address_3",
    "address": "address",
    "country": "country",
    "countryId": "country_id",
    "state": "state",
    "stateId": "state_id",
    "city": "city",
    "zipcode": "zip",
    "isPrimary": "is_primary",
    "isInternational": "is_international"
}

export const newAgencyDetails = {
    "agency_id": null,
    "is_active": 1,
    "agency_notes": null,
    "email_list": [],
    "phone_list": [],
    "address_list": []
}

