export const ProjectTabList = [
    {
        label: "Details",
        value: 1,
    },
    {
        label: "Executives",
        value: 2,
    },
    {
        label: "Talent",
        value: 3,
    },
    {
        label: "Deals",
        value: 4,
    },
    {
        label: "WGA",
        value: 5,
    },
    {
        label: "Attached Files",
        value: 6,
    }
]

export const weekendReadTabList = [
    {
        label: "Details",
        value: 1,
    },
    {
        label: "Files",
        value: 2,
    }
    
]

export const weekendReadTabListSub = [
    {
        label: "Meeting Remarks",
        value: 1,
    },
    {
        label: "Notes",
        value: 2,
    }
    
]
export const submissionTabList = [
    {
        label: "Details",
        value: 1,
    },
    {
        label: "Elements",
        value: 2,
    },
    {
        label: "Attached Files",
        value: 3,
    }
]

export const submissionDetailsTabList = [
    {
        label: "Coverages",
        value: 1
    }
]

export const DealsTabList =[
    {
        label: 'Details',
        value: 1,
    }
]

export const submissionAssignedAnalystList = [
    {
        label: "Synopsis",
        value: 1
    },
    {
        label: "Comparison",
        value: 2
    },
    {
        label: "Comments",
        value: 3
    }
]

export const draftsTabList =[
    {
        label: 'Drafts',
        value: 1,
    }
]
export const WGATabList =[
    {
        label: 'NTWC',
        value: 1,
    },
    {
        label: 'Participating Writers',
        value: 2,
    }
]