import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import DateField from '../../../SharedComponents/DateField/DateField';
import BasicCheckbox from '../../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel'
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from '../../../SharedComponents/BasicTextArea/BasicTextArea';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchSelect from '../../../SharedComponents/SearchSelectField'
import MessageModal from '../../../SharedComponents/MessageModal';
import "./WeekendRead.scss";
import { weekendRead,submittedBy } from './config';
import scripterService from "../../../../services/service";
import * as Constants from '../../../../constants/constants';
import sizeLimits from '../../../../Common/SizeLimits.json'
import messages from '../../../../Common/Messages.json'
import ListSearchSelectField from '../../../SharedComponents/ListSearchSelectField'
import { withUserContext } from "../../../../contexts/UserContext"
import { RepeatRounded } from '@material-ui/icons';
import moment from "moment";
import axios from 'axios';
import RichTextEditor from '../../../SharedComponents/RichTextEditor/RichTextEditor';
import { getFormattedDate } from '../../../../Common/Helper';
import SearchFieldWithAddValue from '../../../SharedComponents/SearchFieldWithAddValue';
import { talentPrefixes, talentSuffixes } from '../../Submissions/Dialogs/Config';
class NewWeekendRead extends Component {
    constructor() {
        super();
        this.state = {
            weekendReadDetails: weekendRead,
            postInitiated: false,
            showLoader: false,
            advocateSelectOptions: [],
            advocate_selected:null,
            advocate_removed:[],
            submittedBySelectOptions: [],
            submitted_by_removed:[],
            weekend_assigned_options: [],
            weekend_assigned_removed:[],
            attached_coverage_options: [],
            attached_coverage_removed:[],
            exec_read_options: [],
            exec_read_removed:[],
            exec_reading_options: [],
            exec_reading_removed:[],
            noteCharCount:0,
            weekend_assigned_selected: null,
            attached_coverage_selected: null,
            exec_read_selected: null,
            exec_reading_selected: null,
            searchCancelToken: null,
            submittedByIndex:2,
            isFetchingWeekendReadData:false,
            mandatoryWarning: "Please complete this mandatory field"

        }
    }
    componentDidMount() {
        // this.addSelect("weekend_assigned");
        // this.addSelect("attached_coverage")
        // this.addSelect("exec_read")
        // this.addSelect("exec_reading")
        if (this.props?.mode === "Edit") {
            this.getData()
        }
        this.getStaticData('repPrefixes', 'lu_prefix');
        this.getStaticData('repSuffixes', 'lu_suffix');
    }
    handleChange = (field, value) => {
        this.setState(prevState => ({
            weekendReadDetails: {
                ...prevState.weekendReadDetails,
                [field]: value
            }
        }))
    }
    selectTypeReturnKey(key, type) {
        let returnKey = "";
        if (key === "weekend_assigned") {
            returnKey = type === 'main' ? "weekend_assigned_to_id" : type === "subid" ? "assigned_to_id" : type === "name" ? "assigned_to" : '';
        }
        else if (key === "attached_coverage") {
            returnKey = type === 'main' ? "weekend_attached_coverage_id" : type === "subid" ? "attached_coverage_id" : type === "name" ? "attached_coverage" : '';
        }
        else if (key === "exec_read") {
            returnKey = type === 'main' ? "weekend_exec_read_id" : type === "subid" ? "exec_read_id" : type === "name" ? "exec_read" : '';
        }
        else if (key === "exec_reading") {
            returnKey = type === 'main' ? "weekend_exec_reading_id" : type === "subid" ? "exec_reading_id" : type === "name" ? "exec_reading" : '';
        }else if(key === "advocate"){
            returnKey = type === 'main' ? "weekend_advocate_id" : type === "subid" ? "advocate_id" : type === "name" ? "advocate" : '';
        }else if(key === "submitted_by"){
            returnKey = type === 'main' ? "weekend_read_submitted_by_id" : "";
        }
        return returnKey;
    }

    getStaticData = (listType, staticTableName) => {
        scripterService.getData(Constants.scripterServiceBaseUrl + `/staticData?staticTable=${staticTableName}`, 
            this.props.userContext?.active_tenant?.tenant_id)
        .then(response => {
            let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
            this.setState({ [listType]: formattedList });
        },
        (err) => {
            console.log("Error in fetching Email Types:", err)
        })
    }

    getAgencies = (searchString) => {
        this.setState({ loading: true });
        scripterService.getData(Constants.scripterServiceBaseUrl + `/agency?searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item =>
                    ({ value: item.agency_id, text: item.agency_name, agency_type: item.agency_type, agency_type_id: item.agency_type_id }));
                this.setState({ agencies: formattedList, loading: false });
            },
                (err) => {
                    this.setState({ loading: false });
                    console.log("Error in fetching Agencies:", err)
                })
    }
    
    handleListEdit = (field, value, obj, listName) => {
        let weekendReadDetails = JSON.parse(JSON.stringify({ ...this.state.weekendReadDetails }));
        let id = (listName === "submitted_by") ? "weekend_read_submitted_by_id" :"";
        let list = weekendReadDetails[listName]?.map(item => {
            let currentVal = item;
            if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                currentVal[field] = value;
                 if (field === 'submitted_by_id') {
                    currentVal.talent_id = value?.type === 'talent' ? value?.value : null;
                    currentVal.talent_group_id = value?.type === 'talent_group' ? value?.value : null;
                    currentVal.representative_id = value?.type === 'representative' ? value?.value : null;
                    currentVal.submitted_by = value?.text || null;
                    currentVal.loanout_company = value?.company || null;
                    currentVal.agency_id = (value?.type === 'representative' && value?.company_id) ? value?.company_id : null;
                    currentVal['is_company_change'] = 0;
                } else if (field === 'loanout_company') {
                    currentVal.loanout_company = value;
                    currentVal['is_company_change'] = value ? 1 : 0;
                }else if (field === 'agency_id') {
                    currentVal.agency_id = value?.value || null;
                    currentVal.loanout_company = value?.text || null;
                    currentVal['is_company_change'] = value?.value ? 1 : 0;
                } 
                return currentVal;
            }
            return currentVal;
        })
        this.handleChange(listName, list);
    }
    getTalentName = (field, value, type = null) => {
        let prefixes = type === 'rep' ? [ ...this.state.repPrefixes ] : [ ...talentPrefixes ];
        let suffixes = type === 'rep' ? [ ...this.state.repSuffixes ] : [ ...talentSuffixes ];
        let n = value?.split(" ");
        if (field === "first_name" || field === "middle_name" || field === "last_name") {
            let prefixExist = prefixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[0]?.toLowerCase()));
            if (prefixExist) {
                n.splice(0, 1);
            }
            let suffixExist = suffixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[n?.length - 1]?.toLowerCase()));
            if (suffixExist) {
                n.pop();
            }
        }
        if (field === "first_name") {
            if (n?.length > 0) {
                return n[0];
            }
        } else if (field === "middle_name") {
            if (n?.length > 2) {
                return n[1];
            }
        } else if (field === "last_name") {
            if (value.split(" ").length > 1) {
                let lastName = [];
                n?.map((item, index) => {
                    if (n?.length <= 2 && index !== 0) {
                        lastName.push(item)
                    } else if (n?.length > 2 && index !== 0 && index !== 1) {
                        lastName.push(item)
                    }
                })
                return lastName.join(" ");
            }
        } else if (field === "prefix") {
            let prefix = prefixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[0]?.toLowerCase()));
            return prefix;
        } else if (field === "suffix") {
            let suffix = suffixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[n?.length -1]?.toLowerCase()));
            return suffix;
        }
    }

    handleNewRepDetails = (details) => {
        let newValue = {
            value: details?.representative_id,
            text: ((details?.prefix ? details?.prefix + ' ' : '') + details?.first_name + ' ' + (details?.m_i ? details?.m_i + ' ' : '') + (details?.last_name ? details?.last_name : '') + (details?.suffix ? ' ' + details?.suffix : '')),
            type: 'representative',
            company_id: details?.agencies?.[0]?.agency_id || null,
            company: details?.agencies?.[0]?.agency_name      
        }
        this.handleListEdit("submitted_by_id", newValue, this.state.talentObj, 'submitted_by');
    }
    handleNewCompanyDetails = (companyDetails) => {
        let newValue = {
            value: companyDetails?.agency_id,
            text: companyDetails?.agency_name,
        }
        this.handleListEdit('agency_id', newValue, this.state.companyObj, 'submitted_by')
    }
    handleNewTalentDetails = (talentDetails) => {
        let newValue = {
            value: talentDetails?.talent_id || talentDetails?.talent_group_id,
            text: (talentDetails?.talent_id ?
                ((talentDetails?.prefix ? talentDetails?.prefix + ' ' : '') + talentDetails?.first_name + ' ' + (talentDetails?.m_i ? talentDetails?.m_i + ' ' : '') + (talentDetails?.last_name ? talentDetails?.last_name : '') + (talentDetails?.suffix ? ' ' + talentDetails?.suffix : '')) :
                this.getFormatTalentGroupName(talentDetails?.talent_group_members_list)),
            type: talentDetails?.talent_id ? 'talent' : 'talent_group',
            company: talentDetails?.company
        }
        this.handleListEdit('submitted_by_id', newValue, this.state.talentObj, 'submitted_by')
    }

    handleSubmittedByAgencyOnChange = (field, value, obj, listName, index, agencyRepId = null) => {
        this.setState({ companyIndex: index, companyObj: obj }, () => {
            if (value === null) {
                this.handleListEdit(field, value, obj, listName)
            } else {
                if (!value.hasOwnProperty('agency_type')) {
                    // Add Agency on fly over here
                    this.props?.navFuns?.handleShowAgencyPopup(null, "new", this.handleNewCompanyDetails, agencyRepId)
                } else {
                    this.handleListEdit(field, value, obj, listName)
                }
            }
        })
    }

    handleSubmittedByOnChange = (field, value, obj, listName, index) => {
        if (field === 'loanout_company') {
            this.handleListEdit(field, value, obj, listName)
        } else {
            this.setState({ talentIndex: index, talentObj: obj }, () => {
                if (value === null) {
                    this.handleListEdit(field, value, obj, listName)
                } else {
                    if (!value.hasOwnProperty('type') && value?.text?.indexOf('Add Rep') == -1) {
                        let talentDetails = {};
                        let prefixDetails = this.getTalentName("prefix", value?.value);
                        if (prefixDetails) {
                            talentDetails['prefix_id'] = prefixDetails?.value;
                            talentDetails['prefix'] = prefixDetails?.label;
                        }
                        talentDetails['first_name'] = this.getTalentName("first_name", value?.value);
                        talentDetails['middle_name'] = this.getTalentName("middle_name", value?.value) || null;
                        talentDetails['last_name'] = this.getTalentName("last_name", value?.value) || null;
                        let suffixDetails = this.getTalentName("suffix", value?.value);
                        if (suffixDetails) {
                            talentDetails['suffix_id'] = suffixDetails?.value;
                            talentDetails['suffix'] = suffixDetails?.label;
                        }

                        //Add on-fly Talent/Talent Team by opening Talent DM Modal
                        this.props?.navFuns?.handleShowTalentPopup(null, 0, "new", this.handleNewTalentDetails, talentDetails)
                    } else if (value?.text?.indexOf('Add Rep') == 0) {
                        // Add Rep on-fly
                        let talentId= null;
                        let repDetails = {}
                        let prefixDetails = this.getTalentName("prefix", value?.value, 'rep');
                        if (prefixDetails) {
                            repDetails['prefix_id'] = prefixDetails?.value;
                            repDetails['prefix'] = prefixDetails?.label;
                        }
                        repDetails['first_name'] = this.getTalentName("first_name", value?.value, 'rep');
                        repDetails['middle_name'] = this.getTalentName("middle_name", value?.value, 'rep') || null;
                        repDetails['last_name'] = this.getTalentName("last_name", value?.value, 'rep') || null;
                        let suffixDetails = this.getTalentName("suffix", value?.value, 'rep');
                        if (suffixDetails) {
                            repDetails['suffix_id'] = suffixDetails?.value;
                            repDetails['suffix'] = suffixDetails?.label;
                        }
                        this.props?.navFuns?.handleShowRepresentativePopup(null,"new", talentId, this.handleNewRepDetails.bind(this), repDetails)
                    } else {
                        this.handleListEdit(field, value, obj, listName)
                    }
                }
            })
        }
    }

    handleSelectChange = (key, selectedObj, itemId) => {
        // if (key === "submittedByList") {
        //     this.handleChange("submitted_by", selectedObj)
        // }
        // else {
            let formedKey = `${key}_selected`;
            this.setState({ [formedKey]: selectedObj })
            // let selectArray = [...this.state?.weekendReadDetails?.[key]]; 
            // let selectedIdKey = this.selectTypeReturnKey(key,"main")
            // let subKey = this.selectTypeReturnKey(key,"subid") 
            // let name = this.selectTypeReturnKey(key,"name")    
            // let updatedArray = selectArray?.map(item => {
            //     if (item?.[selectedIdKey] === itemId) {
            //         item.selectedData = selectedObj
            //         item[name]=selectedObj?.text
            //         item[subKey] = selectedObj?.value

            //     }
            //     return item;
            // }) 
            // this.handleChange(key, updatedArray);            
        // }
    }
    formatTalentRepName = (str) => {
        var i, frags = str?.split('_');
        for (i = 0; i < frags?.length; i++) {
            frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
        }
        return frags?.join(' ');
    }

    getSearchData(selectType, type, value, id) {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        let url = `/entitySearch?entity=${type}&searchString=${value}${type === 'USER' ? `&permissionName="Assignable to a Weekend Read"` : ''}`;
        scripterService.getDataWithCancel(Constants.scripterServiceBaseUrl + url, cancelToken, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = [];
                if (selectType === "attached_coverage") {
                    formattedList = response?.data?.map(item => ({ value: item.submission_id, text: item.title + (item.received_date ? ` - Rcvd. ${item.received_date}` : ''), submissionRcvdDate: item.received_date }));
                    formattedList.sort(function (a, b) {
                        return new Date(b.submissionRcvdDate) - new Date(a.submissionRcvdDate);
                    });
                    this.setState({ [`${selectType}_options`]: formattedList })
                }else if (selectType === "advocate") {
                    formattedList = response?.data?.map(item => ({ value: item.user_id, text: item.user_name }));
                    this.setState({ advocateSelectOptions: formattedList })
                }else if (selectType === "submittedByList") {
                    formattedList = response?.data?.map(item => ({ value: item.id, text: item.name + ' - ' + this.formatTalentRepName(item?.type), type: item.type, company: item?.company, company_id: item?.company_id }));
                    this.setState({submittedBySelectOptions : formattedList})
                }else {
                    formattedList = response?.data?.map(item => ({ value: item.user_id, text: item.user_name }));
                    this.setState({ [`${selectType}_options`]: formattedList })
                }
                
                
                // else {
                //     // let arrayObj = [...this.state.weekendReadDetails?.[selectType]] 
                //     // let selectedIdKey = this.selectTypeReturnKey(selectType,"main")                 
                //     // let updatedData = arrayObj?.map(item=>{
                //     //     if(item?.[selectedIdKey] === id){
                //     //         item.optionData = formattedList;
                //     //     }
                //     //     return item;
                //     // })

                    
                // }

            }, err => {
                console.log("Error from search value", err)
            })
    }
    checkFieldLength = (field, limit) => {
        return field ? field.length <= limit : true
    }
    validateBeforSubmit(weekendReadDetails) {
        let isSubmit = false;
        isSubmit = weekendReadDetails?.weekend_read_date 
        return isSubmit;
    }
    formatPayloadbeforeSubmit() {
        //submitted by
        let weekendSubmittedByArray = [...this.state?.weekendReadDetails?.submitted_by];
        let mainSubmittedId = this.selectTypeReturnKey('submitted_by',"main");
        let updateSubmittedByArray = weekendSubmittedByArray.map((o)=>{
            if (o?.mode === "new") {
                o[mainSubmittedId] = null
            }if(o?.talent_id == null && o?.representative_id == null && o?.talent_group_id == null){
                o["is_post"] = 0;
            }else{
                o["is_post"] = 1;
            }
            return o;
        })
        this.handleChange("submitted_by", updateSubmittedByArray)
        //advocate
        let weekendAdvocateArray = [...this.state?.weekendReadDetails?.advocate];
        let mainAdvocateId = this.selectTypeReturnKey('advocate',"main");
        let updateAdvocateArray = weekendAdvocateArray.map((o)=>{
            if (o?.mode === "new") {
                o[mainAdvocateId] = null
            }
            return o;
        })
        this.handleChange("advocate", updateAdvocateArray)
        // Assigned to
        let weekendAssignedArray = [...this.state?.weekendReadDetails?.weekend_assigned];
        let mainWeekendId = this.selectTypeReturnKey('weekend_assigned', "main");
        let updatedAssignedArray = weekendAssignedArray.map((o) => {
            if (o?.mode === "new") {
                o[mainWeekendId] = null
            }
            return o;
        })
        this.handleChange("weekend_assigned", updatedAssignedArray)
        // Exec Read
        let execReadArray = [...this.state?.weekendReadDetails?.exec_read];
        let mainExecReadId = this.selectTypeReturnKey('exec_read', "main");
        let updatedExecReadArr = execReadArray.map((o) => {
            if (o?.mode === "new") {
                o[mainExecReadId] = null
            }
            return o;
        })
        this.handleChange("exec_read", updatedExecReadArr)

        // Exec Reading
        let execReadingArray = [...this.state?.weekendReadDetails?.exec_reading];
        let mainExecReadingId = this.selectTypeReturnKey('exec_reading', "main");
        let updatedExecReadingArr = execReadingArray.map((o) => {
            if (o?.mode === "new") {
                o[mainExecReadingId] = null
            }
            return o;
        })
        this.handleChange("exec_reading", updatedExecReadingArr);

        // Attached Coverages
        let attachedCoverageArray = [...this.state?.weekendReadDetails?.attached_coverage];
        let attachedCoverId = this.selectTypeReturnKey('attached_coverage', "main");
        let updatedAttachedArray = attachedCoverageArray.map((o) => {
            if (o?.mode === "new") {
                o[attachedCoverId] = null
            }
            return o;
        })
        this.handleChange("attached_coverage", updatedAttachedArray)
    }
    formatPayloadAfterValidation(){
        //submitted by
        if(this.state?.submitted_by_removed?.length > 0){
            let submittedByeArray = [...this.state?.weekendReadDetails?.submitted_by, ...this.state?.submitted_by_removed];       
            this.handleChange("submitted_by", submittedByeArray)
        }
         // Advocate 
         if(this.state?.advocate_removed?.length > 0){
            let advocateArray = [...this.state?.advocate_removed,...this.state?.weekendReadDetails?.advocate];       
            this.handleChange("advocate", advocateArray)
        }
        // Assigned to
        if(this.state?.weekend_assigned_removed?.length > 0){
            let weekendAssignedArray = [...this.state?.weekend_assigned_removed,...this.state?.weekendReadDetails?.weekend_assigned];       
            this.handleChange("weekend_assigned", weekendAssignedArray)
        }
        // Exec Read
        if(this.state?.exec_read_removed?.length > 0){
            let execReadArray = [...this.state?.exec_read_removed,...this.state?.weekendReadDetails?.exec_read];        
            this.handleChange("exec_read", execReadArray)
        }

        // Exec Reading
        if(this.state?.exec_reading_removed?.length > 0){
            let execReadingArray = [...this.state?.exec_reading_removed,...this.state?.weekendReadDetails?.exec_reading];       
            this.handleChange("exec_reading", execReadingArray);
        }

        // Attached Coverages
        if(this.state?.attached_coverage_removed?.length > 0){
            let attachedCoverageArray = [...this.state?.attached_coverage_removed,...this.state?.weekendReadDetails?.attached_coverage];
            this.handleChange("attached_coverage", attachedCoverageArray) 
        }
    }
    getData() {
        this.setState({isFetchingWeekendReadData:true});
        scripterService.getData(Constants.scripterServiceBaseUrl + `/weekendRead?weekendReadId=${this.props?.weekendReadId}`, 
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({isFetchingWeekendReadData:false});
                if (response?.data?.error) {
                    return;
                }
                let data = {
                    ...response.data?.[0],
                    // weekend_read_date: this.formatDate(response.data?.[0]?.weekend_read_date)
                }
                data.attached_coverage = data?.attached_coverage?.map(item => {
                    if (item?.attached_coverage === 'Undefined') {
                        item.attached_coverage = 'Can not view'
                    } 
                    return item;
                })
                data.submitted_by = data?.submitted_by?.map(item => {
                    let submittedByName = item?.talent_id ? item?.talent_name :
                        item?.talent_group_id ? item?.talent_group_name :
                            item?.representative_id ? item?.representative_name : null;
                    let submittedByType = item?.talent_id ? 'Talent' :
                        item?.talent_group_id ? 'Talent Group' :
                            item?.representative_id ? 'Representative' : null;
                    item.submitted_by = submittedByName + ' - ' + submittedByType;
                    item['is_company_change'] = 0;
                    return item;
                });
                if(data.submitted_by.length === 0){
                    data.submitted_by = JSON.parse(JSON.stringify([{ ...submittedBy }]));
                }
                data.weekend_read_date =  this.formatDate(getFormattedDate(data.weekend_read_date, false));
                this.setState({ weekendReadDetails: data , isFetchingWeekendReadData:false })
            }, err => { });
    }
    async submitData() {
        // weekendRead
        this.formatPayloadbeforeSubmit();
        this.setState({ postInitiated: true, showLoader: true })
        if (!this.validateBeforSubmit(this.state?.weekendReadDetails)) {
            this.setState({ showLoader: false })
            return;
        }
        await this.formatPayloadAfterValidation();          
        scripterService.postDataParams(Constants.scripterServiceBaseUrl + `/weekendRead`, 
            this.state?.weekendReadDetails, 
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response?.data?.error) {
                    this.props.setPostFlag(false);
                    this.props.fieldChanged(true, "fail");
                    return;
                }
                this.setState({ postInitiated: false });
                this.props.setPostFlag(false);
                this.props.fieldChanged(true, "success");
                this.setState({ showLoader: false });
                if (window.location.pathname === "/") {
                    this.props.history.push("/")
                }
                if (this.props?.mode === "Edit") {
                    // setTimeout(() => {
                    //     const {pathname} = this.props.location;                      
                    //     if(pathname.includes("weekendReadDetails")){
                    //         location.reload();
                    //     }
                    // }, 2000);
                    this.props.toggleRefreshPage('weekend');
                } else {
                    let weekendUrl = '/weekendReadDetails/' + response.data[0].weekend_read_id;
                    this.props.history.push(weekendUrl);
                    // window.open(weekendUrl)
                }
            }, err => {
                console.log(err);
                this.setState({ showLoader: false })
            })
    }
    addSelect = (type) => {
        if(type === 'submitted_by'){
            let initialSubmittedByArray = JSON.parse(JSON.stringify({ ...submittedBy }));
                let submitted_by = [...this.state.weekendReadDetails?.submitted_by];
                initialSubmittedByArray.index = this.state.submittedByIndex;
                initialSubmittedByArray.weekend_read_submitted_by_id = Date.now();
                initialSubmittedByArray.mode = 'new';
                submitted_by.push(initialSubmittedByArray);
                this.state.weekendReadDetails.submitted_by = submitted_by;
                this.setState({ submittedByIndex: this.state.submittedByIndex + 1, weekendReadDetails: this.state.weekendReadDetails });
        }
        let key = `${type}_selected`;
        let optionKey = `${type}_options`;
        if (this.state?.hasOwnProperty(key) && this.state?.[key]) {

            let dummyId = Date.now();
            let mainId = this.selectTypeReturnKey(type, "main");
            let subId = this.selectTypeReturnKey(type, "subid")
            let name = this.selectTypeReturnKey(type, "name")
            let initialObj = {
                [mainId]: dummyId,
                [subId]: this.state?.[key]?.value,
                [name]: this.state?.[key]?.text,
                mode: "new",
                selectedData: null,
                optionData: null
            }
            let typeArray = [...this.state?.weekendReadDetails?.[type]];
            typeArray.push(initialObj);
            this.handleChange(type, typeArray);
            this.setState({ [key]: null, })
        }


    }
    formatDate(date) {
        return moment(date).format('YYYY-MM-DD');
    }
    
    handleDeleteConfirmation = (type, id, mode) => {
        if (mode && mode === 'new') {
            this.removeSelect(type, id)
        } else {
            this.setState({ 
                showDeleteConfirmationModal: true, 
                fieldType: type, 
                fieldId: id 
            })
        }
    }
    
    removeSelect(type, id) {
        let mainId = this.selectTypeReturnKey(type, "main");
        let removedType = type + '_removed';
        let typeArray = [...this.state?.weekendReadDetails?.[type]];
        let removedItem= typeArray?.filter(item=>{
            if(item[mainId] == id && item.mode != "new"){
                item.is_delete=1;
                item.is_post =1;
                return item;
            }
        });
        if(removedItem?.length > 0){
            let updatedRemovedArray = [...this.state[removedType],...removedItem];
            this.setState({[removedType]:updatedRemovedArray});
        }
        let updatedArray = typeArray?.filter(item => item[mainId] != id);  
        if(typeArray.length == 1 && type == "submitted_by"){
            updatedArray = JSON.parse(JSON.stringify([{ ...submittedBy }]));
        }
        this.handleChange(type, updatedArray);
    }

    trimHtml = (value) => {
        let html = value;
        let divElement = document.createElement("div");
        divElement.innerHTML = html;
        return (divElement.innerText?.length || 0) 
    }

    render() {
        let { weekendReadDetails } = this.state;
        return (
            <>
            {this.state.isFetchingWeekendReadData ?(<div className="ContentLoader">
                <CircularProgress/>
            </div>) :(
                <MDBContainer fluid className="ModalContainer NewWeekendReadModalContainer">
                    <MessageModal
                        open={this.state.showDeleteConfirmationModal}
                        title={"Delete record"}
                        message={"Would you like to delete saved record?"}
                        primaryButtonText={"Delete"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={() => this.setState({ showDeleteConfirmationModal: false }, () => {
                            this.removeSelect(this.state.fieldType, this.state.fieldId)
                        })}
                        handleClose={(e) => this.setState({ showDeleteConfirmationModal: false })} 
                    />
                    <MDBRow className="rowMargin">
                        <MDBCol md={2}>
                            <DateField
                                id="werDate"
                                label={"WER Date"}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                value={weekendReadDetails?.weekend_read_date || ""}
                                onChange={(e) => this.handleChange('weekend_read_date', e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md={6}>
                            <BasicLabel text="Title" />
                            <BasicTextField
                                id='title'
                                limit={sizeLimits.nameCharacterLimit}
                                limitWarning={messages.exceed50CharacterWarning || ""}
                                value={weekendReadDetails?.title || ""}
                                onChange={(e) => this.handleChange('title', e.target.value)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="rowMargin">
                        {/* <MDBCol size='auto'>
                            <SearchSelect className="searchSelectWeekEndRead"
                                id={"perAdvocate"}
                                label={"Per/Advocate"}
                                value={weekendReadDetails.advocate_id || ""}
                                searchSelect={true}
                                options={this.state?.advocateSelectOptions || []}
                                valueSelected={(e, selected) => { this.handleSelectChange('advocate', selected) }}
                                searchText={(e) => { this.getSearchData("advocate", "USER", e.target?.value) }}
                                detail_selected={weekendReadDetails?.advocate_id && weekendReadDetails?.advocate ? { value: weekendReadDetails?.advocate_id, text: weekendReadDetails?.advocate } : null}
                            />
                        </MDBCol> */}
                        <MDBCol md={4}>
                            <MDBCol md={12}>
                            <BasicLabel text="Per/Advocate" />
                            </MDBCol>
                        {weekendReadDetails?.advocate?.map((obj, index)=>{
                            if(obj?.is_delete !== 1){
                            return(
                             <MDBRow key={index}>
                              <MDBCol md={10}>
                                <BasicLabel type={"text text-body"} text={obj?.advocate} />
                              </MDBCol>
                              <MDBCol md={2}>
                                    <MDBIcon 
                                    icon="trash-alt" 
                                    size="sm" 
                                    onClick={() => this.handleDeleteConfirmation("advocate", obj?.weekend_advocate_id, obj?.mode)}
                                    />
                             </MDBCol>
                            </MDBRow>
                            )}
                        })}
                            <MDBRow>
                            <MDBCol md={10}>
                                <SearchSelect className="searchSelectWeekEndRead"
                                    id={"perAdvocate"}
                                    width={"100%"}
                                    value={this.state?.advocate_selected?.value || ""}
                                    searchSelect={true}
                                    options={this.state?.advocateSelectOptions || []}
                                    valueSelected={(e, selected) => { this.handleSelectChange('advocate', selected) }}
                                    searchText={(e) => { this.getSearchData("advocate", "USER", e.target?.value) }}
                                    detail_selected={this.state?.advocate_selected}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <AddCircleIcon onClick={this.addSelect.bind(this, "advocate")} />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        {/* <MDBCol size='auto'>
                            <SearchSelect className="searchSelectWeekEndRead"
                                id={"producedBy"}
                                label={"Submitted/Produced By"}
                                value={weekendReadDetails.submitted_by || ""}
                                searchSelect={true}
                                options={this.state?.produceBySelectOptions || []}
                                valueSelected={(e, selected) => { this.handleSelectChange('produceBy', selected) }}
                                searchText={(e) => { this.getSearchData("produceBy", "USER", e.target?.value) }}
                                detail_selected={weekendReadDetails?.submitted_by && weekendReadDetails?.submitted_by_name ? { value: weekendReadDetails?.submitted_by, text: weekendReadDetails?.submitted_by_name } : null}
                            />
                        </MDBCol> */}
                        
                        <MDBCol md={5}>
                        {weekendReadDetails?.submitted_by?.map((submittedBy,index)=>{
                            if(submittedBy?.is_delete == 0){
                        return(
                        <MDBRow className='submitted-margin submitted-by p-0'>
                          <MDBCol md={5} className="pr-0">
                             <BasicLabel text={index === 0  ? "Submitted By" : ""}/>
                             <SearchFieldWithAddValue 
                             id={"submittedBy" + index}
                             placeholder={'- Search -'}
                             options={this.state?.submittedBySelectOptions || []}
                             showMultipleAddOption={true}
                             primaryAddText={"Add Talent -"}
                             secondaryAddText={"Add Rep -"}
                             detail_selected={(submittedBy?.talent_id || submittedBy?.talent_group_id || submittedBy?.representative_id) ? {
                                value: submittedBy?.talent_id ? submittedBy?.talent_id :
                                    submittedBy?.talent_group_id ? submittedBy?.talent_group_id :
                                        submittedBy?.representative_id ? submittedBy?.representative_id : null,
                                text: submittedBy?.submitted_by
                            } : null}
                            width={'100%'}
                            valueSelected={(e, selected) => { this.handleSubmittedByOnChange('submitted_by_id', selected, submittedBy, "submitted_by") }}
                            searchText={(e) => this.getSearchData('submittedByList', 'TALENT_REP', e.target.value)}
                            multiple={false}
                            searchSelect={true}
                            />
                          </MDBCol>
                          <MDBCol md={5}className="pl-0">
                            <>
                            {(submittedBy?.talent_id || submittedBy?.talent_group_id) ?
                             <BasicTextField
                                 id="company"
                                 label={index === 0  ? "Company" : ''}
                                 width={'100%'}
                                 value={submittedBy?.loanout_company || ""}
                                 onChange={(e) => this.handleSubmittedByOnChange('loanout_company', e.target.value?.length > 0 ? e.target.value : null, submittedBy, "submitted_by")}
                             /> : null
                             }
                              {(submittedBy?.representative_id) ?
                                <div className="rep-company">
                                   <BasicLabel text={index === 0 ? "Company" : ""} />
                                   <SearchFieldWithAddValue
                                       id={"company" + index}
                                       placeholder={'- Search -'}
                                       options={this.state.agencies || []}
                                       detail_selected={submittedBy?.agency_id ? {
                                           value: submittedBy?.agency_id,
                                           text: this.state.agencies?.find(agency => agency?.value == submittedBy?.agency_id)?.text || submittedBy?.loanout_company
                                       } : null}
                                       width={'100%'}
                                       valueSelected={(e, selected) => this.handleSubmittedByAgencyOnChange("agency_id", selected, submittedBy, "submitted_by", index, submittedBy?.representative_id)}
                                       searchText={(e) => this.getAgencies(e.target.value)}
                                       multiple={false}
                                       searchSelect={true}
                                   />
                                </div> : null
                               }
                               {(!submittedBy?.talent_id && !submittedBy?.talent_group_id && !submittedBy?.representative_id) ?
                                 <BasicTextField
                                    id="company"
                                    label={index === 0  ? "Company" : ''}
                                    width={'100%'}
                                    disabled={true}
                                 /> : null
                               }
                            </>
                          </MDBCol>
                          <MDBCol md={2} className="actionButons2 d-inline-block">
                           <MDBRow className={ `${index === 0 ? 'initialIndexIcons' : ''}`}>
                            <MDBCol md={1}  className="removeActionIcon">
                            {
                              (submittedBy.submitted_by?.length > 0) ?
                                <MDBIcon
                                    icon="trash-alt"
                                    className="float-right"
                                    onClick={() => this.handleDeleteConfirmation("submitted_by", submittedBy?.weekend_read_submitted_by_id , submittedBy?.mode )}
                                />
                                : null
                            }
                            </MDBCol>
                            <MDBCol md={1} className={`${weekendReadDetails?.submitted_by?.length === 1 ? 'addIconMargin' : ''}`}>
                            {
                             (submittedBy.submitted_by?.length > 0 && index === (weekendReadDetails?.submitted_by?.length - 1)) ?
                                <AddCircleIcon onClick={this.addSelect.bind(this, "submitted_by")}/> : null
                            }
                            </MDBCol>
                           </MDBRow>
                          </MDBCol>
                         </MDBRow>
                        
                            )}
                        })}
                    </MDBCol>
                    </MDBRow>
                    <MDBRow className="rowMargin">
                        <MDBCol md={4}>
                            <MDBCol md="12">
                                <BasicLabel text="Assigned To" />
                            </MDBCol>
                            {
                                weekendReadDetails?.weekend_assigned?.map((obj, index) => {
                                    if(obj?.is_delete !== 1){
                                    return (<MDBRow>
                                        <MDBCol md={10}>
                                            <BasicLabel type={"text text-body"} text={obj?.assigned_to} />
                                        </MDBCol>
                                        <MDBCol md={2}>
                                            <MDBIcon 
                                                icon="trash-alt" 
                                                size="sm" 
                                                onClick={() => this.handleDeleteConfirmation("weekend_assigned", obj?.weekend_assigned_to_id, obj?.mode)}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    )}
                                })
                            }
                            <MDBRow>

                                <MDBCol md={10}>
                                    <SearchSelect className="searchSelectWeekEndRead"
                                        id={"assignedTo"}
                                        width={"100%"}
                                        value={this.state?.weekend_assigned_selected?.value || ""}
                                        searchSelect={true}
                                        options={this.state?.weekend_assigned_options || []}
                                        valueSelected={(e, selected) => { this.handleSelectChange('weekend_assigned', selected) }}
                                        searchText={(e) => { this.getSearchData("weekend_assigned", "USER", e.target?.value) }}
                                        detail_selected={this.state?.weekend_assigned_selected}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <AddCircleIcon onClick={this.addSelect.bind(this, "weekend_assigned")} />
                                </MDBCol>
                                {/* <MDBCol md={12}>
                                    {this.state.postInitiated && weekendReadDetails?.weekend_assigned?.length == 0 && <div className="errorText">{this.state.mandatoryWarning}</div>}
                                </MDBCol> */}
                            </MDBRow>


                            {/*                            
                            {weekendReadDetails?.weekend_assigned.map((obj, index) => {
                                        return (
                                            <ListSearchSelectField
                                                id={`assignedTo${index}`}
                                                label={"Assigned To"}
                                                hidePrimary={true}
                                                width={"100%"}
                                                isMandatory={true}
                                                showMandatory={this.state?.postInitiated || false}
                                                detail_selected={obj?.selectedData || null}
                                                renderOption={obj?.optionData || []}
                                                searchSelect={true}
                                                addCallback={this.addSelect.bind(this,"weekend_assigned")}
                                                removeCallback={this.removeSelect.bind(this,"weekend_assigned",obj?.weekend_assigned_to_id)}
                                                searchText={(e)=>{this.getSearchData("weekend_assigned","USER",e.target?.value,obj?.weekend_assigned_to_id)}}
                                                showAdd={weekendReadDetails?.weekend_assigned?.length === 1 ? true : ((weekendReadDetails?.weekend_assigned?.length - 1) === index) ? true : false}
                                                showRemove={((weekendReadDetails?.weekend_assigned?.length - 1) !== index) ? true : false}
                                                valueSelected={(e, selected) => { this.handleSelectChange('weekend_assigned',selected,obj?.weekend_assigned_to_id) }}
                                            />)
                                    })} */}

                        </MDBCol>
                        <MDBCol md={4}>
                            <MDBCol md="12">
                                <BasicLabel text="Execs/Group Also Reading" />
                            </MDBCol>
                            {
                                weekendReadDetails?.exec_reading?.map((obj, index) => {
                                    if(obj?.is_delete !== 1){
                                    return (<MDBRow>
                                        <MDBCol md={10}>
                                            <BasicLabel type={"text text-body"} text={obj?.exec_reading} />
                                        </MDBCol>
                                        <MDBCol md={2}>
                                            <MDBIcon 
                                                icon="trash-alt" 
                                                size="sm" 
                                                onClick={() => this.handleDeleteConfirmation("exec_reading", obj?.weekend_exec_reading_id, obj?.mode)}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    )}
                                })
                            }
                            <MDBRow>

                                <MDBCol md={10}>
                                    <SearchSelect className="searchSelectWeekEndRead"
                                        id={"groupAlsoReading"}
                                        width={"100%"}
                                        value={this.state?.exec_reading_selected?.value || ""}
                                        searchSelect={true}
                                        options={this.state?.exec_reading_options || []}
                                        valueSelected={(e, selected) => { this.handleSelectChange('exec_reading', selected) }}
                                        searchText={(e) => { this.getSearchData("exec_reading", "USER", e.target?.value) }}
                                        detail_selected={this.state?.exec_reading_selected}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <AddCircleIcon onClick={this.addSelect.bind(this, "exec_reading")} />
                                </MDBCol>
                            </MDBRow>
                            {/* {weekendReadDetails?.exec_reading.map((obj, index) => {
                                        return (
                                            <ListSearchSelectField
                                                id={`groupAlsoReading${index}`}
                                                label={"Execs/Group Also Reading"}
                                                hidePrimary={true}
                                                width={"100%"}
                                                isMandatory={false}
                                                detail_selected={obj?.selectedData || null}
                                                renderOption={obj?.optionData || []}
                                                searchSelect={true}
                                                addCallback={this.addSelect.bind(this,"exec_reading")}
                                                removeCallback={this.removeSelect.bind(this,"exec_reading",obj?.weekend_exec_reading_id)}
                                                searchText={(e)=>{this.getSearchData("exec_reading","USER",e.target?.value,obj?.weekend_exec_reading_id)}}
                                                showAdd={weekendReadDetails?.exec_reading?.length === 1 ? true : ((weekendReadDetails?.exec_reading?.length - 1) === index) ? true : false}
                                                showRemove={((weekendReadDetails?.exec_reading?.length - 1) !== index) ? true : false}
                                                valueSelected={(e, selected) => { this.handleSelectChange('exec_reading',selected,obj?.weekend_exec_reading_id) }}
                                            />)
                                    })} */}

                        </MDBCol>
                        <MDBCol md={4}>
                            <MDBCol md="12">
                                <BasicLabel text="Exec's Who've Read" />
                            </MDBCol>
                            {
                                weekendReadDetails?.exec_read?.map((obj, index) => {
                                    if(obj?.is_delete !== 1){
                                    return (<MDBRow>
                                        <MDBCol md={10}>
                                            <BasicLabel type={"text text-body"} text={obj?.exec_read} />
                                        </MDBCol>
                                        <MDBCol md={2}>
                                            <MDBIcon 
                                                icon="trash-alt" 
                                                size="sm" 
                                                onClick={() => this.handleDeleteConfirmation("exec_read", obj?.weekend_exec_read_id, obj?.mode)}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    )}
                                })
                            }
                            <MDBRow>

                                <MDBCol md={10}>
                                    <SearchSelect className="searchSelectWeekEndRead"
                                        id={"whoRead"}
                                        width={"100%"}
                                        value={this.state?.exec_read_selected?.value || ""}
                                        searchSelect={true}
                                        options={this.state?.exec_read_options || []}
                                        valueSelected={(e, selected) => { this.handleSelectChange('exec_read', selected) }}
                                        searchText={(e) => { this.getSearchData("exec_read", "USER", e.target?.value) }}
                                        detail_selected={this.state?.exec_read_selected}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <AddCircleIcon onClick={this.addSelect.bind(this, "exec_read")} />
                                </MDBCol>
                            </MDBRow>
                            {/* {weekendReadDetails?.exec_read.map((obj, index) => {
                                        return (
                                            <ListSearchSelectField
                                                id={`whoRead${index}`}
                                                label={"Exec's Who've Read"}
                                                hidePrimary={true}
                                                width={"100%"}                                                
                                                isMandatory={false}
                                                detail_selected={obj?.selectedData || null}
                                                renderOption={obj?.optionData || []}
                                                searchSelect={true}
                                                addCallback={this.addSelect.bind(this,"exec_read")}
                                                removeCallback={this.removeSelect.bind(this,"exec_read",obj?.weekend_exec_read_id)}
                                                searchText={(e)=>{this.getSearchData("exec_read","USER",e.target?.value,obj?.weekend_exec_read_id)}}
                                                showAdd={weekendReadDetails?.exec_read?.length === 1 ? true : ((weekendReadDetails?.exec_read?.length - 1) === index) ? true : false}
                                                showRemove={((weekendReadDetails?.exec_read?.length - 1) !== index) ? true : false}
                                                valueSelected={(e, selected) => { this.handleSelectChange('exec_read',selected,obj?.weekend_exec_read_id) }}
                                            />)
                                    })} */}

                        </MDBCol>

                    </MDBRow>
                    <MDBRow className="rowMargin">
                        <MDBCol md={4}>
                            <MDBCol md="12">
                                <BasicLabel text="Attached Coverages" isMandatory={false} />
                            </MDBCol>
                            {
                                weekendReadDetails?.attached_coverage?.map((obj, index) => {
                                    if(obj?.is_delete !== 1){
                                    return (<MDBRow>
                                        <MDBCol 
                                            md={10} 
                                            className={`${obj?.attached_coverage === 'Can not view' ? 'disableCoverageText' : ''}`}
                                        >
                                            <BasicLabel type={"text text-body"} text={obj?.attached_coverage} />
                                        </MDBCol>
                                        <MDBCol md={2}>
                                            <MDBIcon 
                                                icon="trash-alt" 
                                                size="sm" 
                                                onClick={() => this.handleDeleteConfirmation("attached_coverage", obj?.weekend_attached_coverage_id, obj?.mode)}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    )}
                                })
                            }
                            <MDBRow>

                                <MDBCol md={10}>
                                    <SearchSelect className="searchSelectWeekEndRead"
                                        id={"attachedCoverages"}
                                        width={"100%"}
                                        isMandatory={false}
                                        value={this.state?.attached_coverage_selected?.value || ""}
                                        searchSelect={true}
                                        options={this.state?.attached_coverage_options || []}
                                        valueSelected={(e, selected) => { this.handleSelectChange('attached_coverage', selected) }}
                                        searchText={(e) => { this.getSearchData("attached_coverage", "SUBMISSION", e.target?.value) }}
                                        detail_selected={this.state?.attached_coverage_selected}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <AddCircleIcon onClick={this.addSelect.bind(this, "attached_coverage")} />
                                </MDBCol>
                            </MDBRow>
                            {/* {weekendReadDetails?.attached_coverage.map((obj, index) => {
                                        return (
                                            <ListSearchSelectField
                                                id={`attachedCoverages${index}`}
                                                label={"Attached Coverages"}
                                                hidePrimary={true}
                                                width={"100%"}
                                                isMandatory={false}
                                                detail_selected={obj?.selectedData || null}
                                                renderOption={obj?.optionData || []}
                                                searchSelect={true}
                                                addCallback={this.addSelect.bind(this,"attached_coverage")}
                                                removeCallback={this.removeSelect.bind(this,"attached_coverage",obj?.weekend_attached_coverage_id)}
                                                searchText={(e)=>{this.getSearchData("attached_coverage","SUBMISSION",e.target?.value,obj?.weekend_attached_coverage_id)}}
                                                showAdd={weekendReadDetails?.attached_coverage?.length === 1 ? true : ((weekendReadDetails?.attached_coverage?.length - 1) === index) ? true : false}
                                                showRemove={((weekendReadDetails?.attached_coverage?.length - 1) !== index) ? true : false}
                                                valueSelected={(e, selected) => { this.handleSelectChange('attached_coverage',selected,obj?.weekend_attached_coverage_id) }}
                                            />)
                                    })} */}

                        </MDBCol>

                        <MDBCol md={8}>
                            <BasicLabel text="Additional Material" />
                            <BasicTextField
                                id='additionalMaterial'
                                isMandatory={false}
                                value={weekendReadDetails?.additional_material || ""}
                                limit={sizeLimits.addressCharacterLimit}
                                limitWarning={messages.exceed100CharacterWarning || ""}
                                onChange={(e) => this.handleChange('additional_material', e.target.value.length > 0 ? e.target.value : null)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="rowMargin">
                        <MDBCol md={3}>
                            <BasicCheckbox
                                id="chb_production"
                                label={"Production"}
                                checked={weekendReadDetails?.is_production || false}
                                onChange={(e) => this.handleChange('is_production', +e.target.checked)}
                            />
                            <BasicCheckbox
                                id="chb_packaging"
                                label={"Packaging"}
                                checked={weekendReadDetails?.is_packaging || false}
                                onChange={(e) => this.handleChange('is_packaging', +e.target.checked)}
                            />
                            <BasicCheckbox
                                id="chb_development"
                                label={"Development"}
                                checked={weekendReadDetails?.is_developement || false}
                                onChange={(e) => this.handleChange('is_developement', +e.target.checked)}
                            />
                            <BasicCheckbox
                                id="chb_priorDevelopment"
                                label={"Prior Development"}
                                checked={weekendReadDetails?.is_prior_developement || false}
                                onChange={(e) => this.handleChange('is_prior_developement', +e.target.checked)}
                            />
                        </MDBCol>
                        <MDBCol md={3}>
                            <BasicCheckbox
                                id="chb_talent"
                                label={"Talent"}
                                checked={weekendReadDetails?.is_talent || false}
                                onChange={(e) => this.handleChange('is_talent', +e.target.checked)}
                            />
                            <BasicCheckbox
                                id="chb_discussion"
                                label={"Discussion"}
                                checked={weekendReadDetails?.is_discussion || false}
                                onChange={(e) => this.handleChange('is_discussion', +e.target.checked)}
                            />
                        </MDBCol>
                        <MDBCol md={3}>
                            <BasicCheckbox
                                id="chb_coverageTbd"
                                label={"Coverage TBD"}
                                checked={weekendReadDetails?.is_coverage_tbd || false}
                                onChange={(e) => this.handleChange('is_coverage_tbd', +e.target.checked)}
                            />
                            <BasicCheckbox
                                id="chb_oldNewCov"
                                label={"Old Cov. Attached/New Cov. TBD"}
                                checked={weekendReadDetails?.is_cov_old_new || false}
                                onChange={(e) => this.handleChange('is_cov_old_new', +e.target.checked)}
                            />
                            <BasicCheckbox
                                id="chb_coverageOnly"
                                label={"Coverage Only"}
                                checked={weekendReadDetails?.is_coverage_only || false}
                                onChange={(e) => this.handleChange('is_coverage_only', +e.target.checked)}
                            />
                            <BasicCheckbox
                                id="chb_noMaterial"
                                label={"No Material"}
                                checked={weekendReadDetails?.is_no_material || false}
                                onChange={(e) => this.handleChange('is_no_material', +e.target.checked)}
                            />
                        </MDBCol>
                        <MDBCol md={3}>
                            <BasicCheckbox
                                id="chb_seperatePage"
                                label={"Separate Page"}
                                checked={weekendReadDetails?.is_seperate_page || false}
                                onChange={(e) => this.handleChange('is_seperate_page', +e.target.checked)}
                            />
                            <BasicCheckbox
                                id="chb_memo"
                                label={"Memo Attached"}
                                checked={weekendReadDetails?.is_memo_attached || false}
                                onChange={(e) => this.handleChange('is_memo_attached', +e.target.checked)}
                            />
                            <BasicCheckbox
                                id="chb_list"
                                label={"List Attached"}
                                checked={weekendReadDetails?.is_list_attached || false}
                                onChange={(e) => this.handleChange('is_list_attached', +e.target.checked)}
                            />
                            <BasicCheckbox
                                id="chb_distributed"
                                label={"Already Distributed"}
                                checked={weekendReadDetails?.is_already_distributed || false}
                                onChange={(e) => this.handleChange('is_already_distributed', +e.target.checked)}
                            />
                            <BasicCheckbox
                                id="chb_synopsis"
                                label={"Synopsis"}
                                checked={weekendReadDetails?.is_synopsis || false}
                                onChange={(e) => this.handleChange('is_synopsis', +e.target.checked)}
                            />
                            <BasicCheckbox
                                id="chb_securityDisclaimer"
                                label={"Security Disclaimer"}
                                checked={weekendReadDetails?.is_security_disclaimer || false}
                                onChange={(e) => this.handleChange('is_security_disclaimer', +e.target.checked)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="rowMargin">
                    <MDBCol md="12">
                                <BasicLabel 
                                    text="Notes"  
                                    // typedCharCount={this.trimHtml(weekendReadDetails?.notes)}
                                    // showCharCount={true}
                                    charLimit={sizeLimits.largeNotes}
                                /> 
                            </MDBCol>
                        <MDBCol md={12}>
                            {/* <BasicTextArea
                                label={"Notes"}
                                value={weekendReadDetails?.notes}
                                isMandatory={false}
                                limit={sizeLimits.notesCharacterLimit}
                                limitWarning={messages.exceed200CharacterWarning || ""}
                                onChange={(e) => this.handleChange('notes', e.target.value)}
                            /> */}
                            <RichTextEditor id="wrNotes" 
                            value={weekendReadDetails?.notes}
                                    onChange={(e) => this.handleChange('notes', e)}                                    
                                    // limit={sizeLimits.largeNotes}
                                    // limit={5}
                                    charCount = {this.state?.noteCharCount}
                                    // getCharCount={(c)=>this.setState({noteCharCount:c})}
                                    // limitWarning={messages.exceed1000CharacterWarning || ""}
                                    >
                            </RichTextEditor>
                        </MDBCol>
                        <br />
                    </MDBRow>
                    <MDBRow className='mt-1'>
                        <MDBCol md={10}></MDBCol>
                        <MDBCol md={2}>
                            <MDBRow className='buttonParent'>
                                <MDBCol className='flex justify-content-end'>
                                    <BasicButton
                                        text={this.state?.showLoader ? <CircularProgress color="inherit" size={20} /> : "Save"}
                                        onClick={this.submitData.bind(this)}
                                    />
                                </MDBCol>
                                <MDBCol className='flex justify-content-end'>
                                    <BasicButton
                                        variant="outlined"
                                        text={"Cancel"}
                                        onClick={this.props?.onClose}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            )}
            </>
        )
    }
}


export default withRouter(withUserContext(NewWeekendRead))
