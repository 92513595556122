import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import DateField from "../../../../SharedComponents/DateField/DateField";
import BasicButton from "../../../../SharedComponents/BasicButton/BasicButton";
import BasicLabel from "../../../../SharedComponents/BasicLabel/BasicLabel";
import SearchSelectField from "../../../../SharedComponents/SearchSelectField";
import CircularProgress from "@material-ui/core/CircularProgress";
import "rc-time-picker/assets/index.css";
import { executiveDetails } from "./config";
import "./Executive.scss";
import { withUserContext } from "../../../../../contexts/UserContext";
import * as Constants from "../../../../../constants/constants";
import scripterService from "../../../../../services/service";
import messages from "../../../../../Common/Messages.json";
import moment from 'moment';

export default withUserContext(
  class AddExecutive extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        executiveDetails: {},
        postInitiated: false,
        executiveType: [],
        selectedValue: {},
        isSubmit: false,
        formEdited: false,
        executiveDetailsArray: [],
        isLoading: false,
        loading: false
      };
    }
    componentDidMount = () => {
      if (this.props.mode === "New") {
        this.setState({
          executiveDetails: JSON.parse(JSON.stringify({ ...executiveDetails })),
        });
      } else {
        this.setState({ isLoading: true });
        this.setState({
          executiveDetails: this.formatExecutiveDetailsResponse(JSON.parse(JSON.stringify({ ...this.props?.executiveDetails }))),
        });
        this.setState({ isLoading: false });
      }
      this.setCurrentStartDate();
    };

    setCurrentStartDate = () => {
      let currentDate = new Date().toISOString().slice(0, 10);
      this.setState(prevState => ({
          executiveDetails: {
              ...prevState.executiveDetails,
              start_date: this.props?.executiveDetails?.start_date || currentDate
          },
          postInitiated: false
      }))
    }

    formatExecutiveDetailsResponse = (response) => {
      response['selected_executive'] = {
        value: response.executive_id, text: response.executive_name
      }
      return response
    }
    handleSubmit = () => {
      let executiveDetailsData = JSON.parse(
        JSON.stringify({ ...this.state.executiveDetails })
      );
      this.setState({ postInitiated: true }, () => {
        if (this.validateExecutiveDetails(executiveDetailsData)) {
          this.submitExecutiveDetails(executiveDetailsData);
        }
      });
    };
    validateExecutiveDetails = (executiveDetailsData) => {
      let canSubmit = executiveDetailsData.executive_id && 
        (executiveDetailsData?.end_date ? this.validateStartDate(executiveDetailsData?.start_date, executiveDetailsData?.end_date) : true);
      return canSubmit;
    };
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    }
    validateStartDate = (startDate, endDate) => {
      let isValid = true;
      if (this.formatDate(startDate) <= this.formatDate(endDate)) {
        isValid = true;
        this.setState({ showStartDateErrorText: false })
      } else {
        isValid = false;
        this.setState({ showStartDateErrorText: true })
      }
      return isValid;
    }
    submitExecutiveDetails = (executiveDetailsData) => {
      this.setState({ isSubmit: true });
      this.props.setPostFlag(true);
      let postJson = {
        "project_executive_id": this.props?.executiveDetails?.project_executive_id || null,
        "project_id": executiveDetailsData?.project_id || null,
        "executive_id": executiveDetailsData?.selected_executive.value || null,
        "start_date": executiveDetailsData?.start_date || null,
        "end_date": executiveDetailsData?.end_date || null
    }
      scripterService
        .postDataParams(
          Constants.scripterServiceBaseUrl + "/projectExecutives",
          postJson,
          this.props.userContext.active_tenant.tenant_id,
          this.props?.divisionId
        )
        .then(
          (response) => {
            if (response.data.error) {
              this.setState({ isSubmit: false });
              this.props.setPostFlag(false);
              this.props.fieldChanged(true, "fail");
            } else {
              this.setState({
                postInitiated: false,
                formEdited: false,
                isSubmit: false,
              });
              this.props.toggleRefreshPage('executive');
              this.props.setPostFlag(false);
              this.props.fieldChanged(true, "success");
              this.props?.updateParentDetails();
            }
          },
          (err) => {
            this.setState({ isSubmit: false });
            this.props.fieldChanged(true, "fail");
            this.props.setPostFlag(false);
            console.log("Post Executive details error: " + err);
          }
        );
    };

    handleExecutiveOptions = (event) => {
      if (event.target.value !== "" || event.target.value !== null) {
        this.setState({ loading: true });
        let canAssignableToAProject = '"' + 'Assignable (and can view) a project' + '"';
        scripterService
          .getData(
            Constants.scripterServiceBaseUrl +
            `/entitySearch?entity=USER&permissionName=${canAssignableToAProject}&searchString=${event.target.value}`,
            this.props.userContext.active_tenant.tenant_id
          )
          .then(
            (response) => {
              let formattedList = response.data?.map((item) => ({
                value: item.user_id,
                text: item.user_name,
              }));
              this.setState({
                executiveType: formattedList,
                executiveDetailsArray: response.data,
                loading: false
              });
            },
            (err) => {
              console.log("error", err);
            }
          );
      }
    };
    handleSelectedValue = (field, value) => {
      if (field === 'start_date') {
        if (this.state.executiveDetails?.end_date) {
          this.validateStartDate(value, this.state.executiveDetails?.end_date);
        }
        this.setState({
          executiveDetails: {
            ...this.state.executiveDetails,
            [field]: value,
          },
          formEdited: true,
        });
      } else {
        this.setState({
          executiveDetails: {
            ...this.state.executiveDetails,
            [field]: value,
          },
          formEdited: true,
        });
      }
      this.props.fieldChanged(this.state.formEdited, "change");
    };
    handleSelectedExecutive = (e, newValue) => {
      this.handleSelectedValue("selected_executive", newValue);
      this.handleSelectedValue("executive_name", newValue.text);
      this.handleSelectedValue("executive_id", newValue.value);
      let executiveDetails = {...this.state.executiveDetails};
      let selectedExecutiveDetails = this.state.executiveDetailsArray.find((item) => item.user_id === newValue.value)
      executiveDetails.selected_executive = newValue;
      executiveDetails.start_date = this.props?.executiveDetails?.start_date || new Date().toISOString().slice(0, 10);
      executiveDetails['group_name'] = selectedExecutiveDetails?.group_name;
      executiveDetails['department'] = selectedExecutiveDetails?.department;
      executiveDetails['project_id'] = this.props?.projectId || null;
      executiveDetails['executive_id'] = newValue.value;
      executiveDetails['project_executive_id'] = this.props?.mode === "Edit" ? selectedExecutiveDetails?.project_executive_id : null;
      this.setState({ executiveType: [], executiveDetails: executiveDetails })
    };
    render() {
      return (
        <>
          {this.state.isLoading ? (
            <div className="ContentLoader">
              <CircularProgress />
            </div>
          ) : (
            <MDBContainer className="ExecutiveContainer">
              <MDBRow>
                <SearchSelectField
                  id={"executive-select"}
                  label={"Executive"}
                  options={this.state.executiveType}
                  width={"80%"}
                  multiple={false}
                  isMandatory={true}
                  showMandatory={this.state.postInitiated}
                  mandatoryWarning={messages.mandatoryWarning}
                  detail_selected={this.state.executiveDetails?.selected_executive}
                  valueSelected={this.handleSelectedExecutive}
                  searchText={this.handleExecutiveOptions}
                  searchSelect={true}
                  loading={this.state.loading}
                />
              </MDBRow>
              {(this.props.mode === "Edit" || this.state.executiveDetails?.executive_id) ? <>
                <MDBRow>
                  <MDBCol md={12}>
                    <BasicLabel text={"Group"} />
                    <span className="basic-text">
                      {this.state.executiveDetails?.group_name || "-"}
                    </span>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md={6}>
                    <BasicLabel text={"Department"} />
                    <span className="basic-text">
                      {this.state.executiveDetails?.department || "-"}
                    </span>
                  </MDBCol>
                  <MDBCol md={3}>
                    <BasicLabel text={"Title"} />
                    <span className="basic-text">
                      {this.state.executiveDetails?.title || "-" }
                    </span>
                  </MDBCol>
                </MDBRow>
              </> : null}
              <MDBRow>
                <MDBCol md={3}>
                  <DateField
                    id="start date"
                    label={"Start Date"}
                    // isMandatory={true}
                    // showMandatory={this.state.postInitiated}
                    mandatoryWarning={messages.mandatoryWarning}
                    value={this.state.executiveDetails?.start_date ? moment(this.state.executiveDetails?.start_date).format("YYYY-MM-DD") : ""}
                    onChange={(e) =>
                      this.handleSelectedValue("start_date", e.target.value)
                    }
                  />
                  {this.state.showStartDateErrorText ?
                    <p className="error">Start Date should not be greater than end date</p>
                    : null
                  }
                </MDBCol>
                <MDBCol md={3}>
                  <DateField
                    id="end date"
                    label={"End Date"}
                      // isMandatory={true}
                      // showMandatory={this.state.postInitiated}
                    mandatoryWarning={messages.mandatoryWarning}
                    value={this.state.executiveDetails?.end_date ? moment(this.state.executiveDetails?.end_date).format("YYYY-MM-DD") : ""}
                    onChange={(e) =>
                      this.handleSelectedValue("end_date", e.target.value)
                    }
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="p-2">
                <MDBCol md={8}></MDBCol>
                <MDBCol md={2}>
                  <BasicButton
                    className="btnwidth"
                    variant="contained"
                    type="inline"
                    text={
                      this.state.isSubmit ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        this.props?.primaryButtonText
                      )
                    }
                    disabled={false}
                    onClick={this.handleSubmit}
                  />
                </MDBCol>
                <MDBCol md={2}>
                  <BasicButton
                    type="inline"
                    variant="outlined"
                    text={this.props?.secondaryButtonText}
                    onClick={() => this.props?.onClose()}
                    disabled={false}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          )}
        </>
      );
    }
  }
);
