import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import DateField from '../../SharedComponents/DateField/DateField';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import CircularProgress from "@material-ui/core/CircularProgress";
import ScripterService from '../../../services/service';
import { intialScriptDeliveryNotice } from './config';
import * as Constants from '../../../constants/constants';
import './GenerateScript.scss';
import messages from '../../../Common/Messages.json';
import sizeLimits from '../../../Common/SizeLimits.json';
import { withUserContext } from '../../../contexts/UserContext';
import { getFormattedDate } from '../../../Common/Helper';
import Loader from '../../../Common/Loader/Loader';
export default withUserContext(class GenerateScript extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isFetchingScriptStatus: false,
            postInitiated: false,
            isPosting: false,
            scriptDetails: intialScriptDeliveryNotice,
        }
    }

    componentDidMount() {
        if (this.props?.scriptDetails?.dealMemoId && this.props?.scriptDetails?.dealMemoStepId) {
            this.populateScriptDetails();
        }
    }

    populateScriptDetails = () => {
        this.setState({ isFetchingScriptNoticeReportDetails: true })
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/scriptDeliveryNoticeData?dealMemoId=${this.props.scriptDetails?.dealMemoId}&dealMemoStepId=${this.props.scriptDetails?.dealMemoStepId}`, 
            this.props.userContext?.active_tenant?.tenant_id, this.props?.divisionId)
            .then(response => {
                if (response?.data?.error) {
                    return;
                }
                let data = response.data?.[0];
                let scriptDetails = { ...intialScriptDeliveryNotice };
                scriptDetails.generate_script_delivery_notice_report_id = data.generate_script_delivery_notice_report_id || null;
                scriptDetails.deal_memo_id = this.props.scriptDetails?.dealMemoId;
                scriptDetails.deal_memo_step_id = data?.deal_memo_step_id || null;
                scriptDetails.to = data?.to_text || null;
                scriptDetails.from = data?.from_text || null;
                scriptDetails.date = getFormattedDate(data?.date, true) || null;
                scriptDetails.subject = data?.subject || null;
                scriptDetails.cc = data?.cc_text || null;
                scriptDetails.title = data?.title || null;
                scriptDetails.writers = data?.writers || null;
                scriptDetails.delivery_date = getFormattedDate(data?.delivery_date, true) || null;
                scriptDetails.script_status = data?.script_status || null;
                scriptDetails.reading_period = this.props?.scriptDetails?.reading_end ?
                    getFormattedDate(this.props?.scriptDetails?.reading_end, true) :
                    getFormattedDate(data?.reading_period_end, true);
                scriptDetails.next_step = data?.next_step || null;
                scriptDetails.additional_comments = data?.additional_comments || null;
                this.setState({ scriptDetails: scriptDetails, isFetchingScriptNoticeReportDetails: false })
            }, err => {
                console.log("Error from get deal memo", err);
                this.setState({ isFetchingScriptNoticeReportDetails: false })
            });
    }

    checkFieldLength = (field, limit) => {
        return field ? field.length <= limit : true
    }

    validateBeforSubmit(generateJSON) {
        let isSubmit = false;
        isSubmit = this.checkFieldLength(generateJSON?.title, sizeLimits?.addressCharacterLimit)
            && this.checkFieldLength(generateJSON?.subject, sizeLimits?.addressCharacterLimit)
            && this.checkFieldLength(generateJSON?.additional_comments, sizeLimits?.notesCharacterLimit)
        return isSubmit;
    }

    handleOnChange = (field, value) => {
        this.setState(prevState => ({
            scriptDetails: {
                ...prevState.scriptDetails,
                [field]: value || null,
            },
        }))
    }

    handleSubmitNoticeData = () => {
        this.setState({ isPostSaving: true })
        let postJson = { ...this.state.scriptDetails };
        if (!this.validateBeforSubmit(postJson)) {
            this.setState({ isPostSaving: false })
            return;
        }
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/generateScriptDeliveryNotice', postJson,
            this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isPostSaving: false })
                    this.props.setPostFlag(false);
                    this.props.fieldChanged(true, "fail");
                } else {
                    this.setState({ isPostSaving: false });
                    this.props.setPostFlag(false);
                    this.props.fieldChanged(true, "success", null, 'notice-report');
                }
            },
                (err) => {
                    console.log("Error in posting script notice report:", err)
                    this.setState({ isPostSaving: false });
                    this.props.setPostFlag(false);
                    this.props.fieldChanged(true, "fail");
                })

    }

    handleGenerate = () => {
        this.setState({ isPosting: true })
        let generateJSON = {
            "to_text": this.state.scriptDetails?.to || null,
            "from_text": this.state.scriptDetails?.from || null,
            "date": this.state.scriptDetails?.date || null,
            "title": this.state.scriptDetails?.title || null,
            "cc_text": this.state.scriptDetails?.cc || null,
            "delivery_status": this.state.scriptDetails?.delivery_date || null,
            "script_status": this.state.scriptDetails?.script_status || null,
            "reading_period_end": this.state.scriptDetails?.reading_period || null,
            "next_step": this.state.scriptDetails?.next_step || null,
            "additional_comments": this.state.scriptDetails?.additional_comments || null,
            "writers": this.state.scriptDetails?.writers || null,
            "subject": this.state.scriptDetails?.subject || null,
        }
        let load = {
            "payload": 'generateReport',
            "reportName": `rptGenerateDeliveryNotice`,
            "searchJson": btoa(JSON.stringify(generateJSON)),
            "showHtml": 0,
            "isStaticReport": 0,
            "encryptionType": 1,
            "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
            "divisionId": this.props?.divisionId
        }
        if (!this.validateBeforSubmit(generateJSON)) {
            this.setState({ isPosting: false })
            return;
        }
        ScripterService.getDataWS(load)
            .then(webSocketConn => {
                webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data)
                    if (response?.statusCode == 200) {
                        this.setState({ isPosting: false })
                        let url = response.body;
                        let obj = {
                            reportName: "GenerateScriptReport"
                        }
                        let finalReportName = obj.reportName
                        this.downloadUrl(url, finalReportName)
                    } else {
                        this.setState({ isPosting: false })
                    }
                }
            })
    }

    downloadUrl = (url, reportName) => {
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', reportName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        }
    }

    render() {
        return (
            <>
                {this.state.isFetchingScriptNoticeReportDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <>
                            <Loader isOpen={this.state.isPosting || false}
                                text={"Loading..."} />
                            <div className="GenerateScriptContainer">
                                <MDBRow>
                                    <MDBCol>
                                        <BasicTextField
                                            id={"to"}
                                            label={"To"}
                                            placeholder={"[Comma-saparated list of Legal Affairs and Bussiness Affairs Executive from Project(from ex:Washinfton, T.adams)]"}
                                            value={this.state.scriptDetails?.to || ""}
                                            onChange={(e) => this.handleOnChange('to', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            id={"from"}
                                            label={"From"}
                                            placeholder={"[Current User's Name (from ex:C.Penneck)]"}
                                            value={this.state.scriptDetails?.from || ""}
                                            onChange={(e) => this.handleOnChange('from', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={2}>
                                        <DateField
                                            id="date"
                                            label={"Date"}
                                            value={this.state.scriptDetails?.date || ""}
                                            onChange={(e) => this.handleOnChange('date', e.target.value)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={5}>
                                        <BasicTextField
                                            id={"subject"}
                                            label={"Subject"}
                                            placeholder={"[Project]"}
                                            value={this.state.scriptDetails?.subject || ""}
                                            onChange={(e) => this.handleOnChange('subject', e.target.value)}
                                            limitWarning={messages.exceed100CharacterWarning || ""}
                                            limit={sizeLimits.addressCharacterLimit}
                                        />
                                    </MDBCol>
                                    <MDBCol md={5}></MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <BasicTextField
                                            id={"cc"}
                                            label={"CC"}
                                            placeholder={"[Comma-saparated list of Legal Project's Executive Names(from ex:Washinfton)]"}
                                            value={this.state.scriptDetails?.cc || ""}
                                            onChange={(e) => this.handleOnChange('cc', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={4}>
                                        <BasicTextField
                                            id={"title"}
                                            label={"Title"}
                                            placeholder={"[Project Title]"}
                                            value={this.state.scriptDetails?.title || ""}
                                            onChange={(e) => this.handleOnChange('title', e.target.value)}
                                            limitWarning={messages.exceed100CharacterWarning || ""}
                                            limit={sizeLimits.addressCharacterLimit}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <BasicTextField
                                            id={"writers"}
                                            label={"Writers"}
                                            placeholder={"[Comma-saparated list of Deal step Writer names]"}
                                            value={this.state.scriptDetails?.writers || ""}
                                            onChange={(e) => this.handleOnChange('writers', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={2}>
                                        <DateField
                                            id="delivery date"
                                            label={"Delivery Date"}
                                            value={this.state.scriptDetails?.delivery_date || ""}
                                            onChange={(e) => this.handleOnChange('delivery_date', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={4}>
                                        <BasicTextField
                                            id={"script status"}
                                            label={"Script Status"}
                                            placeholder={"[Script Status]"}
                                            value={this.state.scriptDetails?.script_status || ""}
                                            onChange={(e) => this.handleOnChange('script_status', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={2}>
                                        <DateField
                                            id="readingperiodend"
                                            label={"Reading Period"}
                                            value={this.state.scriptDetails?.reading_period || ""}
                                            onChange={(e) => this.handleOnChange('reading_period', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={4}>
                                        <BasicTextField
                                            id={"next step"}
                                            label={"Next Step"}
                                            placeholder={"[Deal Step name]"}
                                            value={this.state.scriptDetails?.next_step || ""}
                                            onChange={(e) => this.handleOnChange('next_step', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <div className="textArea">
                                        <BasicTextArea
                                            label={"Additional Comments"}
                                            id={"additional comments"}
                                            rows={2}
                                            value={this.state.scriptDetails?.additional_comments || ""}
                                            onChange={(e) => this.handleOnChange('additional_comments', e.target.value)}
                                            limitWarning={messages.exceed200CharacterWarning || ""}
                                            limit={sizeLimits.notesCharacterLimit}
                                        />
                                    </div>
                                </MDBRow>
                                <br />
                                <MDBRow>
                                    <MDBCol md={9}></MDBCol>
                                    <MDBCol md={3} className="flex justify-content-between">
                                        <BasicButton
                                            text={this.state?.isPostSaving ?
                                                <CircularProgress color="inherit" size={20} /> : "Save"
                                            }
                                            onClick={this.handleSubmitNoticeData}
                                        />
                                        <BasicButton
                                            text={this.state?.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate"}
                                            onClick={this.handleGenerate}
                                        />
                                        <BasicButton
                                            variant="outlined"
                                            onClick={this.props?.onClose}
                                            text={"Cancel"}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        </>
                    )
                }
            </>
        )
    }
}
);