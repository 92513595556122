import React from 'react';
// import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import CircularProgress from "@material-ui/core/CircularProgress";
import exportFromJSON from 'export-from-json';
import { draftsTabList } from '../../../../constants/TabList';
import { projectDraftsConfig } from './Config';
import TabsComponent from '../../../SharedComponents/Tabs/Tabs';
import TableComponent from '../../../SharedComponents/Table';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import Loader from '../../../../Common/Loader/Loader';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';
import { withRouter } from 'react-router';

class DraftsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 1,
            renderList: [],
            config: projectDraftsConfig,
            isFetchingReport: false,
            excelGenerate: false,
            exportExcelSelected: false,
        }
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue
        })
    }

    generateExcel = () => {
        let data = this.props.drafts
        exportFromJSON({ data, fileName: 'ProjectDraftsExcel', exportType: exportFromJSON.types.xls})
    }

    openSubmissionDetailsInNewTab = (id) => {
        if (id) {
            this.props.history.push("/submissionDetails/" + id)
            // window.open("/submissionDetails/" + id);
        }
    }

    handleDraftsExcel = () => {
        // let query = { "projects": 1, "wga": 0, "submissions": 1, "coverages": 1, "weekend_reads": 0, "deals": 0, "talent": 0, "drafts": 1 };
        let query = { "projects": 1,"project_genres": 1,"project_executives": 1,
            "project_source_material": 1,"project_talent": 1,"projects_ba_executive": 1,
            "projects_le_executive": 1,"wga": 1,"authors": 1,"submissions": 1,"submission_author": 1,
            "submission_elements": 1,"submission_format": 1,"submission_source_material": 1,
            "submission_submitted": 1,"coverages": 1,"coverages_analyst": 1,"coverages_workflow": 1,
            "weekend_reads": 0,"weekend_read_assigned_to": 0,"weekend_read_exec_read": 0,"agency": 0,
            "representative": 0,"deals": 0,"deal_ammendments": 0,"deal_steps": 0,"talent": 0, "talent_email": 0,
            "talent_role": 0, "drafts": 1
        }
        let json = {};
        if (this.state.exportExcelSelected) {
            json = {
                "where_query": `project.project_id = ${this.props?.projectId}`,
                "from_query": query,
            }
        } 
        let load = {
            "payload": 'advanceSearch',
            "dataType": `PROJECTS`,
            "input": btoa(JSON.stringify(json)),
            "encryptionType": 1,
            "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
            "divisionId": this.props?.divisionId
        }
        console.log('check the load here', load)
        ScripterService.getDataWS(load)
        .then(webSocketConn => {
            webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data)
                console.log('total response after websocket call', e)
                if(response?.statusCode == 200) {
                    console.log("search result output", response, JSON.parse(response.body))
                    let result = JSON.parse(response.body);
                    let data ; 
                    if(this.state.exportExcelSelected){
                        data = result?.data?.map(item=>{
                            Object.keys(item).forEach(function (index) {
                                if(!item[index]){
                                    item[index] = '';
                                }
                                if(index == 'is_confidential'){
                                    item[index] = item[index] == 1 ? "Confidential" : "Non-Confidential"
                                }
                            });
                            return item
                        });
                    }
                    exportFromJSON({ data, fileName: 'ProjectDraftsExcel', exportType: exportFromJSON.types.xls })
                    this.setState({ exportExcelSelected: false })
                } else {
                    this.setState({ exportExcelSelected: false })
                }
            }
        })
    }

    // handleDraftsExcel = () => {
    //     let query = { "projects": 1, "wga": 0, "submissions": 1, "coverages": 1, "weekend_reads": 0, "deals": 0, "talent": 0, "drafts": 1 };
    //     let json = this.state.exportExcelSelected && { "where_query": `project.project_id = ${this.props?.projectId}`, "from_query": query };
    //     ScripterService.getAdvancedSearch(Constants.scripterServiceBaseUrl + '/advanceSearch', 'PROJECTS', 
    //         json, 
    //         this.props.userContext?.active_tenant?.tenant_id, 
    //         this.props.userContext.active_tenant.division_id, 
    //         false
    //     )
    //         .then(response => {
    //             console.log("check the result", response)
    //             let result = response?.data?.data;
    //             let data ; 
    //             if(this.state.exportExcelSelected){
    //                  data = result.map(item=>{
    //                     Object.keys(item).forEach(function (index) {
    //                         if(!item[index]){
    //                             item[index] = '';
    //                         }
    //                         if(index == 'is_confidential'){
    //                             item[index] = item[index] == 1 ? "Confidential" : "Non-Confidential"
    //                         }
    //                     });
    //                     return item
    //                 });
    //             }
    //             exportFromJSON({ data, fileName: 'ProjectDraftsExcel', exportType: exportFromJSON.types.xls })
    //             this.setState({ exportExcelSelected: false })
    //         },
    //             (err) => {
    //                 console.log("Error in fetching Query Details:", err)
    //             })
    // }

    render() {
        return (
            <div className="project-drafts">
                <Loader
                    isOpen={this.state.isFetchingReport || false}
                    text={"Loading..."}
                />
                <div className="d-flex">
                    <TabsComponent
                        tabList={draftsTabList}
                        value={this.state.tabValue}
                        badgeContent={this.props.drafts?.length}
                        onChange={(e, v) => this.handleTabChange(v)}
                    />
                    <div className="excel-btn">
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            icon="file-excel"
                            text={this.state.exportExcelSelected ? <CircularProgress color="inherit" size={20} /> : "Excel"}
                            onClick={(e) =>
                                this.setState({ exportExcelSelected: true }, () => {
                                    this.handleDraftsExcel()
                                })
                            }
                        />
                    </div>
                </div>
                {this.state.tabValue === 1 && (
                    <TableComponent
                        className="drafts"
                        list={this.props.drafts || []}
                        config={this.state.config}
                        isLoading={this.props.isFetchingDrafts}
                        handleShareCallback={(id) => this.openSubmissionDetailsInNewTab(id)}
                        tableMode={"light"}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(withUserContext(DraftsSection));