import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { MDBIcon } from "mdbreact";
import './shared.scss';
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from '@material-ui/core/Chip'
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import TableFilters from './TableFilters';
import TableInlineEdits from './TableInlineEdits';
import {
	MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
	MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBContainer, MDBNavbarLink, MDBDropdownLink
} from 'mdb-react-ui-kit';
import { selectOptions } from '../ScripterLandingPage/config';
import BasicCheckbox from './BasicCheckbox/BasicCheckbox';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 350,
		border: "1px solid #dadde9",
		marginTop: "-0.1rem",
		fontSize: "0.75rem",
		padding: "0.5rem 0.5rem 0"
	}
}))(Tooltip);
class TableComponent extends React.Component {

	constructor(props) {
		super(props);
		var headings = this.props?.config?.headings;
		var colArr = {};
		var dataTypeArr = {};
		var selectOptionsArr = {};
		headings?.forEach((heading, index) => {
			colArr[heading.headingLabel] = "";
			dataTypeArr[this.props?.config?.dataNodes[index]] = heading.inputType;
			if (heading.inputType == "select")
				selectOptionsArr[this.props?.config?.dataNodes[index]] = heading.selectOptions;
		});
		this.state = {
			inputValues: colArr,
			filterValues: [],
			dropDownValues: [],
			listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
			editInputValues: [],
			dataTypeValues: dataTypeArr,
			selectOptionValues: selectOptionsArr,
			check: null,
			setPopover: null,
			IsLandingPage: true
		}
	}

	componentDidMount() {
		//var headings = this.props?.config?.headings;
		// var colArr = {};
		// var dataTypeArr = {};
		// var selectOptionsArr = {};

		// headings.forEach((heading) => {
		//     colArr[heading.headingLabel] = "";        
		//     dataTypeArr[this.props?.config?.dataNodes[index]] = heading.inputType ;
		//     if(heading.inputType == "select")
		//     selectOptionsArr[this.props?.config?.dataNodes[index]] = heading.selectOptions;
		// });  
		// this.setState({
		//     inputValues : colArr,
		//     filterValues: [],
		//     listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
		//     dataTypeValues: dataTypeArr,
		//     selectOptionValues: selectOptionsArr
		// });
	}


	componentDidUpdate(prevProps, prevState) {
		if (prevProps.list !== this.props.list) {
			this.setState({ listEdits: JSON.parse(JSON.stringify(this.props?.list || [])) });
		}
		if (prevProps.tabChange !== this.props.tabChange) {
			this.clearValues(this.props.tabChange)
		}
	}

	clearValues = (tabChangeFlag) => {
		let filterValues = this.state.filterValues;
		if (tabChangeFlag) {
			filterValues = [];
		}
		this.setState({
			filterValues
		}, () => this.props?.clearTabChange())
	}


	//divide array of columns into chunks of size 6

	divideArrayToChunks = (chunksize, array) => {
		return array.map((e, i) => {
			return i % chunksize === 0 ? array.slice(i, i + chunksize) : null;
		}).filter(e => { return e; });
	}
	//on specific column check and uncheck
	onColumnCheck = (element, index, checked) => {
		this.props.onColumnCheck(element, index, checked)
	}

	onConfidentialCheck = (checked) => {
		if (checked.target.checked) {
			this.props?.getCheckBoxValues(checked.target.id)
		} else {
			this.props?.getCheckBoxValues(null)
		}
	}

	addFilterChangeHandler = (index, name, type, value) => {
		var inputValues = this.state.inputValues;
		var filterValues = this.state.filterValues;
		var dropDownValues = this.state.dropDownValues;
		if (type === "dropdown") {
			const dropDownValue = { value: inputValues[name], label: value }
			dropDownValues.push(dropDownValue)
		} else if (type === "autocomplete") {
			const dropDownValue = { value: inputValues[name].value, label: value }
			dropDownValues.push(dropDownValue)
		}
		if (filterValues.length > 0) {
			let presentInd = filterValues.findIndex((item) => item.split(":")[0] === name);
			if (presentInd != -1) {
				if (type === "daterange") {
					if (inputValues[name] != "") {
						filterValues[presentInd] = (name + ": " + value + " : " + inputValues[name]);
					}
				} else {
					filterValues[presentInd] = name + ": " + value;
				}
			} else {
				if (type === "daterange") {
					if (inputValues[name] != "") {
						filterValues.push(name + ": " + value + " : " + inputValues[name]);
					}
				} else {
					filterValues.push(name + ": " + value);
				}
			}
		} else {
			if (type === "daterange") {
				if (inputValues[name] != "") {
					filterValues.push(name + ": " + value + " : " + inputValues[name]);
				}
			} else {
				filterValues.push(name + ": " + value);
			}
		}
		inputValues[name] = "";
		this.setState({
			inputValues: inputValues,
			filterValues: filterValues,
			dropDownValues: dropDownValues
		});
		var filtersNone = false;
		if (filterValues.length == 0)
			filtersNone = true;
		this.props.applyFilter(filterValues, filtersNone, dropDownValues);
	}

	filterChangeHandler = (index, name, value, filter) => {

		var inputValues = this.state.inputValues;
		inputValues[name] = value;
		this.setState({
			inputValues: inputValues
		});
	}

	handleInlineEditChange = (value, index, nodeName) => {
		var editInputValues = [...this.state.listEdits];
		editInputValues[index][nodeName] = value;
		this.setState({ listEdits: editInputValues });
	}

	handleFilterDelete = (removeNode, index) => {
		var filterValues = this.state.filterValues;
		var dropDownValues = this.state.dropDownValues
		filterValues.splice(index, 1);
		this.setState({ filterValues: filterValues });
		var filtersNone = false;
		if (filterValues.length == 0)
			filtersNone = true;
		this.props.applyFilter(filterValues, filtersNone, dropDownValues);

	}

	handleClick = (event) => {
		this.setState({
			setPopover: event.currentTarget
		})
	};

	handleColumns = (event) => {
		this.setState({
			setColumns: event.currentTarget
		})
	};

	handleClose = () => {
		this.setState({
			setPopover: null
		})
	};

	handleCloseColumns = () => {
		this.setState({
			setColumns: null
		})
	};

	handleCheck = (e, item) => {
		this.setState({
			check: item.value
		});
	}

	render() {
		let open = Boolean(this.state.setPopover);
		let openColumn = Boolean(this.state.setColumns);
		let id = open ? 'simple-popover' : undefined;
		let idColumn = openColumn ? 'simple-popover' : undefined;
		return (
			<>
				<div>
					<div className={this.props?.maxHeight ? "tableContainer " + "max-height-" + this.props?.maxHeight : "tableContainer"}>
						<TableContainer component={Paper} className={this.props?.tableMode == "light" ? "bg-white overflow-x-unset" : "bg-light overflow-x-unset"}>
							<Table id={this.props.className + "-table"} stickyHeader aria-label="collapsible table" size="small" style={{ tableLayout: "fixed" }} >
								<colgroup>
									{this.props?.config?.headings?.map((heading, index) => (
										<col key={"colWidth_" + index}
											width={heading.width ? heading.width : (100 / this.props?.config?.headings?.length) + "%"}
										/>))
									}
								</colgroup>
								{this.props?.config?.isSearchBar ? <>
									<TableHead class="searchHeaders">
										<TableRow key={this.props.className + "-searchHeaderRow"}>
											{
												this.props?.config?.headings?.map((heading, index) => (
													<TableCell key={this.props.className + "-" + index + "-" + heading.headingLabel} className="searchHeaderRows">
														{index === 0 ? 'Search Results for :' + '"' + this.props.searchText + '"' : ''}
													</TableCell>
												))
											}
										</TableRow>
									</TableHead>
									<TableHead class="searchHeadersSecond">
										<TableRow key={this.props.className + "-searchHeaderSecondRow"}>
											{
												this.props?.config?.headings?.map((heading, index) => (
													<TableCell key={this.props.className + "-" + index} className="searchHeadersSecondRows">
														{index === 0 ? this.props.resultsCount + ' search results for ' + '[' + this.props.searchType + ']' : ''}
													</TableCell>
												))
											}
										</TableRow>
									</TableHead></> : null}
								<TableHead id={this.props.className + "-tablehead"} className={this.props?.tableMode == "light" ? "bg-light" : "bg-dark"}>
									{
										this.props?.config?.filters ?
											<TableRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
												{/* <TableCell colSpan={this.props?.config?.headings?.length || 1} key={this.props.className + "-chips-0" } className="FW800" style ={{padding : "4px"}}  > */}
												<div style={{ display: "flex" }}>
													{
														this.state.filterValues && this.state.filterValues?.map((item, index) =>
															<Chip
																label={item}
																onDelete={this.handleFilterDelete.bind(this, item, index)}
															/>
														)
													}
												</div>
												{/* </TableCell> */}
											</TableRow> :
											this.props?.config?.filtersLanding ?
												<TableRow className="columnFilters d-block d-flex justify-content-between">
													{/* <TableCell colSpan={this.props?.config?.headings?.length || 1} key={this.props.className + "-chips-0" } className="FW800" style ={{padding : "4px"}}  > */}
													<div
														className={`${this.props?.tabName === "Deals" ? "dealConstant" :
															((this.props?.tabName === "Weekend Read" && this.props?.config?.headings?.filter(item => item.width === "100%")?.length == 6)  ? "weekendReadConstant" :
															((this.props?.tabName === "Weekend Read" && this.props?.config?.headings?.filter(item => item.width === "100%")?.length == 7)  ? "weekendReadConstantOne" :
															((this.props?.tabName === "Weekend Read" && this.props?.config?.headings?.filter(item => item.width === "100%")?.length == 8)  ? "weekendReadConstantTwo" :
															((this.props?.tabName === "Weekend Read" && this.props?.config?.headings?.filter(item => item.width === "100%")?.length == 9)  ? "weekendReadConstantThree" :
																((this.props?.tabName === "Submissions" && this.props?.config?.headings?.filter(item => item.width === "100%")?.length < 10) ? "submissionConstant" :
																	((this.props?.tabName === "Submissions" && this.props?.config?.headings?.filter(item => item.width === "100%")?.length == 10) ? "submissionConstant submissionConstOne" :
																		((this.props?.tabName === "Projects" && this.props?.config?.headings?.filter(item => item.width === "100%")?.length == 5) ? 'projectDefaultConstant' : 
																			((this.props?.tabName === "Projects" && this.props?.config?.headings?.filter(item => item.width === "100%")?.length == 6) ? 'projectConstantOne' : 
																				((this.props?.tabName === "Projects" && this.props?.config?.headings?.filter(item => item.width === "100%")?.length == 7) ? 'projectConstantTwo' : 
																					((this.props?.tabName === "Projects" && this.props?.config?.headings?.filter(item => item.width === "100%")?.length == 8) ? 'projectConstantThree' : 
																						((this.props?.tabName === "Projects" && this.props?.config?.headings?.filter(item => item.width === "100%")?.length == 9) ? 'projectConstantFour' :
																							((this.props?.tabName === "Projects" && this.props?.config?.headings?.filter(item => item.width === "100%")?.length == 10) ? 'constant projectConstFive' : "constant"))))))))))))}`
														}
														style={{ display: "flex" }}
													>
														{
															this.state.filterValues && this.state.filterValues?.map((item, index) =>
																<Chip
																	label={item}
																	onDelete={this.handleFilterDelete.bind(this, item, index)}
																/>
															)
														}
													</div>
													{
														this.props?.isLandingPage && this.props?.tabName != "Deals" 
															? <div className="constantButtons">
																{this.props?.isLandingPage && this.props?.tabName != "Weekend Read" &&(
																	<BasicButton
																	variant="text"
																	text={this.props?.tabName === "Submissions" ? "All Submissions" : this.props?.tabName === "Projects" ? "All Projects" : this.props?.tabName === "Deals" ? "All Deals" : this.props?.tabName === "Weekend Read" ? "All Weekend Reads" : null}
																	icon={"chevron-right"}
																	iconRight={true}
																	onClick={this.handleClick}
																/>
																)}
																
																<Popover
																	id={id}
																	className="popOverFilter"
																	open={open}
																	anchorEl={this.state.setPopover}
																	onClose={this.handleClose}
																	anchorOrigin={{
																		vertical: 'bottom',
																		horizontal: 'left',
																	}}
																>
																	{this.props?.selectOptions.map((item, index) => {
																		return <span><BasicCheckbox id={index} label={item.label} checked={this.props?.confidentialCheck == index ? true : false} onChange={(e) => this.onConfidentialCheck(e)} /></span>
																	})}
																</Popover>
																<div className="columnsButton">
																	<BasicButton
																		variant="text"
																		text={"Columns"}
																		icon={"chevron-right"}
																		iconRight={true}
																		onClick={this.handleColumns}
																	/>
																	<Popover
																		id={idColumn}
																		className="popOverFilter"
																		open={openColumn}
																		anchorEl={this.state.setColumns}
																		onClose={this.handleCloseColumns}
																		anchorOrigin={{
																			vertical: 'bottom',
																			horizontal: 'left',
																		}}
																	>
																		<div class='row'>
																			{this.divideArrayToChunks(6, this.props?.config?.headings)?.map((element, parentIndex) => {
																				return (<div class='col-3'>
																					{element.map((column, i) => {
																						return (<span><BasicCheckbox
																							id={i + (parentIndex * 6)} key={i + (parentIndex * 6)}
																							label={column.headingLabel} checked={column.isColumn}
																							disabled={column.isDisabled ? column.isDisabled : false}
																							onChange={(e) => this.onColumnCheck(column, (i + (parentIndex * 6)), e.target.checked)} /></span>)
																					})}
																				</div>)
																			})
																			}
																		</div>
																	</Popover>
																</div>
															</div>
															:
															null
													}

												</TableRow>
												:
												<>
												</>
									}
									<TableRow className="filters">
										{
											this.props?.config?.headings?.filter(item => item.isColumn === true).map((heading, index) => (
												<TableCell key={this.props.className + "-" + index} style={{ padding: "4px" }}
													className={this.props?.tableMode == "light" ? "bg-light FW800" : "bg-dark FW800"} >
													{this.props?.config?.filters || this.props?.config?.filtersLanding ?
														<><TableFilters inputType={heading.inputType}
															options={this.props?.options[heading?.selectOptions]}
															name={heading.headingLabel} inputValue={this.state.inputValues[heading.headingLabel]}
															filterChangeHandler={this.filterChangeHandler.bind(this, index, heading.headingLabel)}
															addFilterChangeHandler={this.addFilterChangeHandler.bind(this, index, heading.headingLabel, heading.inputType)}
															autoCompleteCallBack={this.props.autoCompleteCallBack}
															configHeading={heading} /> </> : <> </>}
													<span className="action-icon" onClick={() => this.props?.sortCallback(heading.headingLabel, heading.dataNode)}>
														{heading.icon ? <div>{heading.headingLabel}<MDBIcon icon={heading.icon} /></div>
															: heading.headingLabel}
													</span>
												</TableCell>
											))
										}
										{this.props?.config?.actions &&
											<TableCell key={this.props.className + "-" + this.props?.config?.headings?.length}
												className={this.props?.tableMode == "light" ? "bg-light FW800" : "bg-dark FW800"} style={{ padding: "4px" }}  >
											</TableCell>
										}
									</TableRow>
								</TableHead>
								{
									this.props?.isLoading ? (
										<div className="tableLoader">
											<CircularProgress />
										</div>
									) : (

											<TableBody key={this.props.className + "body"}>
												{this.props?.list?.length > 0 && this.props?.list?.map((dataItem, rowIndex) => (
													<TableRow id={this.props.className + dataItem[this.props?.config?.primaryKey]}
														key={this.props.className + "-" + dataItem[this.props?.config?.primaryKey] + "-" + rowIndex}>
														{this.props?.config?.iscolumnFilterRequired &&
															this.props?.config?.headings.filter(item => item.isColumn == true)?.map((node, index) => (node.dataNode == 'check' ?
																<TableCell className='FW800'>
																	<input type='checkbox' checked={dataItem[node.dataNode]}
																		onClick={(event, newValue) => {
																			dataItem[node.dataNode] = event.target.checked;
																			this.props.arrayCheckUncheck(event.target.checked, dataItem);
																		}}></input>
																</TableCell> :
																<TableCell
																	key={this.props.className + "-" + dataItem[this.props?.config?.primaryKey] + "-" + dataItem[node.dataNode] + "-" + index}
																	className={(this.props?.config?.borders?.includes(node.dataNode) ? "border-left-1-secondary " : "") +
																		(this.props?.config?.isLanding ? (dataItem.is_confidential == "True" ? this.props?.config?.changeColourToRed?.includes(node.dataNode) ? "textColorToRed " : "" : "") :
																			(this.props?.config?.changeColourToRed?.includes(dataItem[node.dataNode]) ? "textColorRed " : "")) +
																		(this.props?.config?.changeColourToRed?.includes(dataItem[node.dataNode]) ? "textColorRed " : "") +
																		(this.props?.config?.changeColourToGreen?.includes(dataItem[node.dataNode]) ? "textColorGreen " : "") +
																		//(this.props?.config?.dataNodes.length == index+1 ? "border-right-0 " : ""  ) +
																		"border-ver-1-secondary padding-5"} >
																	{dataItem["editing"] && this.props?.config?.inlineEdits?.includes(node.dataNode) ?
																		<> </> :
																		node.dataNode == "groups" ?
																			dataItem[node.dataNode]?.map(item => (<div className="groupsStyles">{item}</div>)) :
																			node.dataNode == "label" ?
																				<div className="labelNames" onClick={(e) => this.props.editData(dataItem, node.dataNode)}>
																					{dataItem[node.dataNode]}</div> :
																				this.props?.config?.hyperlinks?.includes(node.dataNode) ?
																				Array.isArray(dataItem[node.dataNode]) ? 
																				dataItem[node.dataNode]?.map((i,index,iArr)=> <a href="javascript:void(0);" className='mr-2' onClick={(e) => this.props.hyperLinkNavigationCallback(i, node.dataNode)}>
																				{i?.label}{index === iArr?.length - 1 ? '': ','}</a>) :
																					<a href="javascript:void(0);" className={dataItem[node?.dataNode+'ShowRed'] && dataItem[node?.dataNode+'ShowBold'] ? "textColorRed textstylebold" : 
																												dataItem[node?.dataNode+'ShowBold'] ? "textstylebold" : dataItem[node?.dataNode+'ShowRed'] ?  "textColorRed" : '	'} onClick={(e) => this.props.hyperLinkNavigationCallback(dataItem, node.dataNode)}>
																						{dataItem[node.dataNode]}</a> :
																					this.props?.config?.shareIcon?.some(configItem => configItem.column === node.dataNode) ?
																						<MDBIcon icon={"share"} onClick /> :
																						this.props?.config?.toggleFields?.includes(node.dataNode) ? (dataItem[node.dataNode] != null ?
																							<MDBIcon icon={dataItem[node.dataNode] == true ? "check-circle" : "times"}
																								className={dataItem[node.dataNode] == true ? "text-success" : "text-dark"} /> : <> </>) :
																							this.props?.config?.fileAttachments?.includes(node.dataNode) ?
																								<BasicButton variant="outlined" icon="paperclip"
																									type="inline" inputType="file" id={this.props?.objectType + "-" + this.props?.name}
																									uploadFile={this.props?.handleFileSelect} />
																								:
																								this.state.selectOptionValues?.[node.dataNode] ?
																									<>{this.state.selectOptionValues?.[node.dataNode].map((item) => {
																										if (item.value == dataItem[node.dataNode])
																											return (<> {item.label} </>);
																									})}</> :
																									<> {dataItem[node.dataNode]}</>

																	}
																	{
																		this.props?.config?.dataNodeGroups?.[node.dataNode] ? (
																			<>
																				{this.props?.config?.dataNodeGroups?.[node.dataNode]?.map((item) => (
																					dataItem["editing"] && this.props?.config?.inlineEdits?.includes(item) ? <> </>
																						: <div>{dataItem[item]}</div>))}
																			</>) : <> </>
																	}
																	{
																		this.props?.config?.columnWithIcon?.some(configItem => configItem.column === node.dataNode) ? (
																			<>
																				{this.props?.config?.columnWithIcon?.map((item) => (
																					item.column == node.dataNode &&
																					(
																						<MDBIcon
																							icon={item.icon}
																							onClick={(e) => this.props.viewCallback(dataItem, node.dataNode)}
																						/>
																					)
																				))}
																			</>
																		) : null
																	}

																	{dataItem["editing"] && this.props?.config?.inlineEdits?.includes(node.dataNode) ?
																		<TableInlineEdits nodeName={node.dataNode} inputType={this.state.dataTypeValues[node.dataNode]}
																			inputValue={this.state.listEdits?.[rowIndex]?.[node.dataNode]} index={rowIndex}
																			handleInlineEditChange={this.handleInlineEditChange.bind(this)}
																			selectOptions={this.state.selectOptionValues?.[node.dataNode]}
																		/> : <> </>}

																	{
																		this.props?.config?.dataNodeGroups?.[node.dataNode] ? (
																			<>
																				{this.props?.config?.dataNodeGroups?.[node.dataNode]?.map((item) =>
																					dataItem["editing"] && this.props?.config?.inlineEdits?.includes(item) ?
																						(<TableInlineEdits nodeName={item} inputType={"text"}
																							inputValue={this.state.listEdits?.[rowIndex]?.[item]} index={rowIndex}
																							handleInlineEditChange={this.handleInlineEditChange.bind(this)}
																						/>) : <> </>)}
																			</>) : <> </>
																	}



																</TableCell>
															))
														}
														{!this.props?.config?.iscolumnFilterRequired &&
															this.props?.config?.dataNodes?.map((node, index) => (node == 'check' ?
																<TableCell className='FW800'>
																	<input type='checkbox' checked={dataItem[node]}
																		onClick={(event, newValue) => {
																			dataItem[node] = event.target.checked;
																			this.props.arrayCheckUncheck(event.target.checked, dataItem);
																		}}></input>
																</TableCell> :
																<TableCell
																	key={this.props.className + "-" + dataItem[this.props?.config?.primaryKey] + "-" + dataItem[node] + "-" + index}
																	className={(this.props?.config?.borders?.includes(node) ? "border-left-1-secondary " : "") +
																		(this.props?.config?.changeColourToRed?.includes(dataItem[node]) ? "textColorRed " : "") +
																		(this.props?.config?.changeColourToGreen?.includes(dataItem[node]) ? "textColorGreen " : "") +
																		(node === "episode_writing_credits" ? "no-break-word " :"") + 
																		"border-ver-1-secondary padding-5"} >
																	{dataItem["editing"] && this.props?.config?.inlineEdits?.includes(node) ?
																		<> </> :
																		node == "groups" ?
																			dataItem[node]?.map(item => (<div className="groupsStyles">{item}</div>)) :
																			node == "label" ?
																				<div className="labelNames" onClick={(e) => this.props.editData(dataItem, node)}>
																					{dataItem[node]}</div> :
																				this.props?.config?.highlightConfidential?.includes(node) ? (dataItem['is_confidential'] ? 
																					<div className="textColorRed">{dataItem[node]}</div> : <div>{dataItem[node]}</div>) :
																				this.props?.config?.hyperlinks?.includes(node) ?
																					Array.isArray(dataItem[node]) ? 
																					dataItem[node]?.map((i,index,iArr)=> <a href="javascript:void(0);" className='mr-2' onClick={(e) => this.props.hyperLinkNavigationCallback(i, node)}>
																					{i?.label}{index === iArr?.length - 1 ? '': ','}</a>)
																					:
																					<a href="javascript:void(0);" onClick={(e) => this.props.hyperLinkNavigationCallback(dataItem, node)}>
																						{dataItem[node]}</a> :
																					this.props?.config?.shareIcon?.some(configItem => configItem.column === node) ?
																						(this.props?.config?.shareIcon?.map(nodeItem => {
																							if (nodeItem?.column === node)
																								return (
																									<>
																										{!nodeItem?.isTooltip ?
																											<MDBIcon
																												aria-controls="simple-menu"
																												aria-haspopup="true"
																												icon={dataItem[nodeItem?.shareId] ? nodeItem.icon : ''}
																												onClick={(e) => (dataItem[nodeItem?.shareId] && nodeItem?.shareId) ?
																													this.props?.hyperLinkNavigationCallback(dataItem, node) :
																													''
																												}
																											/> :
																											<HtmlTooltip
																												title={
																													<div>
																														{dataItem[node] ?
																															<p>{dataItem[node]}</p> :
																															<p>No Data</p>
																														}
																													</div>
																												}
																												interactive={true}
																												placement="bottom-start"
																											>
																												<div className={`${dataItem[node] ? '' : 'icon-light'}`}>
																													<MDBIcon
																														aria-controls="simple-menu"
																														aria-haspopup="true"
																														icon={nodeItem.icon}
																														onClick={(e) => (nodeItem?.shareId) ?
																															this.props?.hyperLinkNavigationCallback(dataItem, node) :
																															''
																														}
																													/>
																												</div>
																											</HtmlTooltip>
																										}
																									</>
																								)
																						}
																						)) :
																						// (this.props?.config?.shareIcon?.some(configItem => configItem?.shareId && dataItem[configItem?.shareId]) ?
																						// <MDBIcon icon={"share"} onClick={(e) => this.props.hyperLinkNavigationCallback(dataItem, node)} /> : <></>) :
																						this.props?.config?.toggleFields?.includes(node) ? (dataItem[node] != null ?
																							<MDBIcon icon={dataItem[node] == true ? "check-circle" : "times"}
																								className={dataItem[node] == true ? "text-success" : "text-dark"} /> : <> </>) :
																							this.props?.config?.fileAttachments?.includes(node) ?
																								<BasicButton variant="outlined" icon="paperclip"
																									type="inline" inputType="file" id={this.props?.objectType + "-" + this.props?.name}
																									uploadFile={this.props?.handleFileSelect} />
																								:
																								this.state.selectOptionValues?.[node] ?
																									<>{this.state.selectOptionValues?.[node].map((item) => {
																										if (item.value == dataItem[node])
																											return (<> {item.label} </>);
																									})}</> :
																									<> {dataItem[node]}</>

																	}
																	{
																		this.props?.config?.dataNodeGroups?.[node] ? (
																			<>
																				{this.props?.config?.dataNodeGroups?.[node]?.map((item) => (
																					dataItem["editing"] && this.props?.config?.inlineEdits?.includes(item) ? <> </>
																						: <div>{dataItem[item]}</div>))}
																			</>) : <> </>
																	}
																	{
																		this.props?.config?.columnWithIcon?.some(configItem => configItem.column === node) ? (
																			<>
																				{this.props?.config?.columnWithIcon?.map((item) => (
																					item.column == node && dataItem[node] &&
																					(
																						<MDBIcon
																							icon={item.icon}
																							onClick={(e) => this.props.viewCallback(dataItem, node)}
																						/>
																					)
																				))}
																			</>
																		) : null
																	}

																	{dataItem["editing"] && this.props?.config?.inlineEdits?.includes(node) ?
																		<TableInlineEdits nodeName={node} inputType={this.state.dataTypeValues[node]}
																			inputValue={this.state.listEdits?.[rowIndex]?.[node]} index={rowIndex}
																			handleInlineEditChange={this.handleInlineEditChange.bind(this)}
																			selectOptions={this.state.selectOptionValues?.[node]}
																		/> : <> </>}

																	{
																		this.props?.config?.dataNodeGroups?.[node] ? (
																			<>
																				{this.props?.config?.dataNodeGroups?.[node]?.map((item) =>
																					dataItem["editing"] && this.props?.config?.inlineEdits?.includes(item) ?
																						(<TableInlineEdits nodeName={item} inputType={"text"}
																							inputValue={this.state.listEdits?.[rowIndex]?.[item]} index={rowIndex}
																							handleInlineEditChange={this.handleInlineEditChange.bind(this)}
																						/>) : <> </>)}
																			</>) : <> </>
																	}



																</TableCell>
															))
														}
														{this.props?.config?.actions &&
															<TableCell key={this.props.className + "action"} className={"border-ver-1-secondary"} > <div className={this.props?.config?.actions?.length > 1 ? "multiIcons row" : "row"}>
																{this.props?.config?.actions?.map((action, indexNo) => (

																	<div key={this.props.className + "-" + indexNo} className={"col-md padding-3"}
																		disabled={this.props?.tabValue === 1 ? (!dataItem?.is_edit) : false}
																		onClick={() => action === "pen" ?
																			(dataItem["editing"] ? this.props?.saveCallback(this.state.listEdits, rowIndex, dataItem[this.props.config.primaryKey], dataItem) :
																				this.props?.editCallback(dataItem[this.props.config.primaryKey], dataItem)
																			) :
																			(action === "plus" ? this.props?.handleAddCallback(dataItem[this.props.config.primaryKey]) :
																				(action === "share" ? this.props?.handleShareCallback(dataItem[this.props.config.primaryKey]) :
																					(action === "copy" ? this.props?.handleCopyCallback(dataItem[this.props.config.primaryKey], dataItem) :
																						(action === "scroll" ? this.props?.handleScrollCallback(dataItem[this.props.config.primaryKey], dataItem) :
																							(action === "trash-alt" ? this.props?.handleDelCallBack(dataItem[this.props.config.primaryKey], dataItem) :
																								(action === "file" ? this.props?.handleFileCallback(dataItem[this.props.config.primaryKey], dataItem) :
																									(action === "file-pdf" ? this.props?.handlePdfCallback(dataItem) : null)))))))}>
																		{action === "view" ? (<a href="#">{action}</a>) :
																			((action === "pen" && dataItem["editing"]
																				? <MDBIcon icon={"save"} color="primary" className="action-icon" />
																				: (action === "trash-alt" ?
																					(!this.props?.canDelete ? null : <MDBIcon icon={action} className="action-icon" />) :
																					<MDBIcon icon={action} className="action-icon" />)))}
																	</div>

																))
																}
															</div></TableCell>
														}
													</TableRow>
												))
												}
												{
													this.props?.list?.length <= 0 &&
													<TableRow >
														<TableCell className="text-align-center" colSpan={this.props?.config?.headings?.length}>
															<b>No results</b>
														</TableCell>
													</TableRow>
												}
											</TableBody>
										)
								}
							</Table>
						</TableContainer>
					</div>
				</div>
			</>
		)
	}
}


export default TableComponent;