import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicTextField from '../BasicTextField/BasicTextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import BasicLabel from '../BasicLabel/BasicLabel';
import BasicCheckbox from '../BasicCheckbox/BasicCheckbox';
import './ListField.scss';

export default class ListField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <MDBRow className="ListFieldsContainer">

                {this.props?.label && 
                    <MDBRow> 
                        <BasicLabel 
                            text={this.props?.label || ""} 
                            type={"text"}
                            isMandatory={this.props?.isMandatory || false} 
                        />
                    </MDBRow>}

                <MDBRow>
                    <MDBCol md={this.props.hidePrimary ? (this.props?.fieldWidth ? this.props?.fieldWidth : 10) : 8} className="mb2">
                        <BasicTextField id={this.props?.id}
                            value={this.props?.value || ""}
                            onChange={this.props.onChange}
                            disabled={this.props?.disabled || false}
                            limitWarning={this.props.limitWarning || ""}
                            limit={this.props.limit}
                            showAsLabel={this.props.showAsLabel || false}
                            placeholder={this.props?.placeholder || ""}
                            width={'100%'}
                        />
                    </MDBCol>
                    {
                        !this.props.hidePrimary &&
                        <MDBCol md={2}>
                            <BasicCheckbox
                                label="primary"
                                checked={this.props?.isPrimary || false}
                                onChange={(e) => this.onChangeCallback("is_primary", e.target.value)}
                                align="start"
                                id={this.props.id}
                            />
                        </MDBCol>
                    }
                    <MDBCol md={this.props?.actionWidth || 2} className={`${this.props?.showAsLabel ? 'labelActions' : ''} actions d-flex`}>
                        {
                            (this.props?.showRemove && !this.props?.disabled) ? 
                                <MDBIcon icon="trash-alt" className="float-right" onClick={this.props?.removeCallback} /> 
                            : null
                        }
                        {
                            (this.props?.showAdd) ? 
                                <MDBIcon icon="plus-circle" className="float-right ml5" onClick={this.props?.addCallback} /> 
                            : null
                        }

                    </MDBCol>
                </MDBRow>
            </MDBRow>
        );
    }
}

