import React from 'react';
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import { projectWgaConfig } from './Config';
import TableComponent from '../../../SharedComponents/Table';
import MessageModal from '../../../SharedComponents/MessageModal';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';
import Loader from '../../../../Common/Loader/Loader';
import { getFormattedDate } from '../../../../Common/Helper';

export default withUserContext(class DealsTabContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: projectWgaConfig,
            wgaList: [],
            isFetchingWgaList: false,
            isDeletingWga: false,
            isCopyingWga: false,
            mode: null,
            wgaRefreshRate: false,
            fetchWgaList: () => this.fetchWgaList(),
            deletewgaId: null
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.refreshPage?.value !== state.wgaRefreshRate && props.refreshPage?.modal === 'wga') {
            if (props.refreshPage?.value) {
                state.fetchWgaList();
                props.toggleRefreshPage(props.refreshPage?.modal)
            }
            return {
                wgaRefreshRate: props.refreshPage?.value || false
            }
        }
        return null;
    }

    componentDidMount() {
        this.fetchWgaList();
    }

    handlePostWga = (wgaDetails, mode) => {
        if (this.state.isCopyingWga && mode==="copy")
        wgaDetails.iscopyWriter=1;

        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + "/wga", wgaDetails,
            this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(
                (response) => {
                    console.log("response delete", response)
                    this.props.deletewgaId(this.state.deletewgaId);
                    this.setState({ isDeletingWga: false, mode: null, isCopyingWga: false })
                    if (response?.data?.error) {
                        this.props.manageMessageModal('Fail', true, mode)
                    } else {
                        this.props.manageMessageModal('Success', true, mode);
                        this.fetchWgaList();
                    }
                },
                (err) => {
                    console.log("error", err);
                    this.setState({ isDeletingWga: false, mode: null, isCopyingWga: false })
                    this.props.manageMessageModal('Fail', true, mode)
                }
            );
    }

    deleteWga = () => {
        this.setState({ isDeletingWga: true, mode: 'delete', showDeleteConfirmationModal: false })
        let wga = JSON.parse(JSON.stringify({ ...this.state.deletedCallbackItem }));
        this.setState({deletewgaId : wga.project_wga_id});
        wga.is_delete = 1;
        this.handlePostWga(wga, 'delete');
    }

    handleWgaCopy = (id, wgaDetails) => {
        this.setState({ isCopyingWga: true, mode: 'copy' })
        this.fetchWgaDetails(id);
    }
    fetchWgaDetails(id){
        this.setState({ isLoading: true });
            ScripterService.getData(
                Constants.scripterServiceBaseUrl + `/wga?projectWgaId=${id}`,
                this.props.userContext.active_tenant.tenant_id,
                this.props?.divisionId
            ).then((response) => { 
                console.log(response);    
                if(response?.data?.error){
                    this.setState({ isDeletingWga: false, mode: null, isCopyingWga: false })
                    this.props.manageMessageModal('Fail', true, 'copy')                           
                }  
                else{
                    let responseData = response?.data?.[0];
                    if(responseData){
                        responseData.project_wga_id = null;
                        this.handlePostWga(responseData, 'copy');
                    }                           
                }
                },
                (err) => {
                    console.log("error", err);
                    this.setState({ isDeletingWga: false, mode: null, isCopyingWga: false })
                    this.props.manageMessageModal('Fail', true, 'copy')
                }
            )                
    }
    fetchWgaList = () => {
        this.setState({ isFetchingWgaList: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/projectEntity?projectId=${this.props.projectId}&entity=WGA`,
            this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(response => {
                console.log("response.data", response.data)
                let formatted = response.data?.map(o=>{
                    let type = o.wga_type_name.toLowerCase();
                    o.title_formatted = (type === "television" || type === "new media") ? o?.episode_title : o?.title;                    
                    return o;
                })
                
                this.setState({ isFetchingWgaList: false, wgaList: formatted });
            },
                (err) => {
                    this.setState({ isFetchingWgaList: false })
                })
    }

    handleWgaPdfDownload = (wga) => {
        this.setState({ isFetchingReport: true, mode: 'pdf' })
        let type = this.getReportName(wga?.wga_type_name);
        if (type) {
            let json = { "project_id": wga?.project_id, "project_wga_id": wga?.project_wga_id };
            let load = {
                "payload": 'generateReport',
                "reportName": type,
                "searchJson": btoa(JSON.stringify(json)),
                "showHtml": 0,
                "isStaticReport": 0,
                "encryptionType": 1,
                "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
                "divisionId": this.props?.divisionId
            }
            ScripterService.getDataWS(load)
            .then(webSocketConn => {
                webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data)
                    if(response?.statusCode == 200) {
                         this.setState({ isFetchingReport: false, mode: null }) 
                         ScripterService.downloadFile(response.body).then(res => { 
                            const url = window.URL.createObjectURL(res.data);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', type);
                            document.body.appendChild(link);
                            link.click();
                        }, (err) => {
                            console.log("File Download Failed:" + err); 
                            this.setState({ isFetchingReport: false, mode: null })                 
                        });
                    } else {
                        this.setState({ isFetchingReport: false, mode: null }) 
                    }
                }
            })
        } else {
            this.setState({ isFetchingReport: false, mode: null })
        }
    }

    // handleWgaPdfDownload = (wga) => {
    //     this.setState({ isFetchingReport: true, mode: 'pdf' })
    //     // console.log(wga);
    //     let type = this.getReportName(wga?.wga_type_name);
    //     if (type) {
    //         let postJson = { project_id: wga?.project_id, project_wga_id: wga?.project_wga_id };
    //         ScripterService.getDataParams(Constants.scripterServiceBaseUrl + `/generateReport?reportName=${type}`, postJson,
    //             this.props.userContext.active_tenant.tenant_id, this.props.userContext.active_tenant.division_id, '&searchJson=')
    //             .then(response => {
    //                 this.setState({ isFetchingReport: false, mode: null })
    //                 if (response?.data?.error) {
    //                     alert(response?.data?.message)
    //                     //Report template not found 404
    //                 } else {
    //                     ScripterService.downloadFile(response.data).then(response => { 
    //                         const url = window.URL.createObjectURL(response.data);
    //                         const link = document.createElement('a');
    //                         link.href = url;
    //                         link.setAttribute('download', type);
    //                         document.body.appendChild(link);
    //                         link.click();
    //                     }, (err) => {
    //                         console.log("File Download Failed:" + err);                 
    //                       });
    //                 }
    //             },
    //                 (err) => {
    //                     this.setState({ isFetchingReport: false, mode: null })
    //                 })
    //     } else {
    //         this.setState({ isFetchingReport: false, mode: null })
    //     }

    // }

    getReportName = (type) => {
        switch (type) {
            case 'Television': return 'rptWgaTelevisionNotice';
            case 'New Media': return 'rptWgaNewMedia';
            case 'Animation': return 'rptWgaAnimationForm';
            case 'HBSVOD': return 'rptWgaHbsvodCreditTemplate';
            case 'Features': return 'rptWgaTheatricalNoticeReport';
            default: return null;
        }
    }

    handleDeleteConfirmation = (callbackItem) => {
        this.setState({ 
            showDeleteConfirmationModal: true,
            deletedCallbackItem: callbackItem
        });
    }

    render() {
        return (
            <div className="TabDetailsContainer">
                <MessageModal
                    open={this.state.showDeleteConfirmationModal}
                    title={"Delete record"}
                    message={"Would you like to delete saved record?"}
                    primaryButtonText={"Delete"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => this.deleteWga()}
                    handleClose={(e) => this.setState({ showDeleteConfirmationModal: false })} 
                />
                <MDBCard>
                    <Loader isOpen={this.state.isDeletingWga || this.state.isCopyingWga || this.state.isFetchingReport || false} text={this.state.mode === 'delete' ? "Deleting..." : this.state.mode === 'pdf' ? "Loading..." : "Copying..."} />
                    <MDBCardBody className={"card-body-height"}>
                        <MDBCardText className="tab-table-content wga-action-icons" id="actionIconWGA">
                            <TableComponent
                                list={this.state?.wgaList || []}
                                className={"coveredTalent"}
                                config={this.state.config}
                                editCallback={this.props.editCallback}
                                removeHeader={false}
                                tableMode={"light"}
                                canDelete={this.props.permissions?.canViewOrModifyProjectWga || false}
                                isLoading={this.state.isFetchingWgaList || false}
                                // handleDelCallBack={(id, item) => this.deleteWga(item)}
                                handleCopyCallback={(id, item) => this.handleWgaCopy(id, item)}
                                handlePdfCallback={(item) => this.handleWgaPdfDownload(item)}
                                handleDelCallBack={(id, item) => this.handleDeleteConfirmation(item)}
                            />
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </div>
        )
    }
})