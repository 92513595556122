import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import DeleteIcon from '@material-ui/icons/Delete';
import DateField from '../../SharedComponents/DateField/DateField';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import InlineSelectField from '../../SharedComponents/InlineSelectField/InlineSelectField';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import { withUserContext } from '../../../contexts/UserContext';
import SearchSelectField from '../../SharedComponents/SearchSelectField'
import ListSearchSelectField from '../../SharedComponents/ListSearchSelectField'
import MessageModal from "../../SharedComponents/MessageModal";
import sizeLimits from '../../../Common/SizeLimits.json'
import messages from '../../../Common/Messages.json'
import './Deals.scss';
import { validateNumbersOnly, validateDecimalNumbers, getFormattedDate } from "../../../Common/Helper";


export default withUserContext(class Step extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            postInitiated: false,
            isFetchingDealsDetais: false,
        }
    } 

    componentDidMount() {
        if (this.props?.projectId) {
            this.props?.getValues('SUBMISSION', '', this.props?.step?.deal_memo_step_id)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.projectId != this.props?.projectId) {
            this.props?.getValues('SUBMISSION', '', this.props?.step?.deal_memo_step_id)
        }
    }

    handleWriterSearchText(stepId, writerId, e) {
        e.preventDefault();
        this.props?.getValues('TALENT', e.target.value, stepId, writerId)
    }

    handleDraftSearchText(stepId, e) {
        this.props?.getValues('SUBMISSION', e.target.value, stepId)
    }
    addWriterCallback(stepId) {
        this.props?.addWorksList(stepId)
    }
    removeWriterCallback(stepId, workId) {        
        this.props?.deleteWriters(stepId, workId)
    }

    handleDeleteConfirmation = (dealMemoStepId, stepMode, dealAmendmentId) => {
        console.log("check the items when added", dealAmendmentId)
        if (dealAmendmentId === undefined) {
            if (this.props?.step?.draft_submission_id?.value) {
                this.setState({ showDraftRemoveWarning: true })
            } else {
                this.setState({ 
                    showDeleteConfirmationModal: true,
                    dealMemoStepId: dealMemoStepId,
                    stepMode: stepMode
                })
            }
        } else {
            this.props?.deleteSteps(dealMemoStepId, stepMode)
        }
    }
    render() {
        let isLoading = this.state.isFetchingTalentDetails && this.state.isFetchingTalentTypes;
        let step = this.props?.step;
        return (
            <>

                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : ( step?.is_delete != 1 && (
                    
                    <div className="DealContainer">
                        <MessageModal
                            open={this.state.showDeleteConfirmationModal}
                            title={"Delete record"}
                            message={"Would you like to delete saved record?"}
                            primaryButtonText={"Delete"}
                            secondaryButtonText={"Cancel"}
                            onConfirm={() => this.setState({ showDeleteConfirmationModal: false }, () => {
                                this.props?.deleteSteps(this.state.dealMemoStepId, this.state.stepMode)
                            })}
                            handleClose={(e) => this.setState({ showDeleteConfirmationModal: false })} 
                        />
                        <MessageModal
                            open={this.state.showDraftRemoveWarning}
                            title={"Remove Draft"}
                            message={"First de-link draft, then step can be deleted."}
                            hideCancel={true}
                            primaryButtonText={"OK"}
                            onConfirm={(e) => this.setState({ showDraftRemoveWarning: false })}
                        />
                        <div className="section-width">
                            <MDBRow>
                                
                                <MDBCol md={6} className="pr-0">
                                 <div className='stepFields'>
                                    <div className='stepno step-row-margin'>
                                     <BasicTextField
                                            id={"stepNo"}
                                            label={"No."}
                                            limit={sizeLimits.usZipCharacterLimit}
                                            limitWarning={messages.exceed5DigitWarning || ""}
                                            value={step?.step_number || null}
                                            onChange={(e) => this.props?.changeHandler('step_number', step?.deal_memo_step_id, e.target.value)}
                                        />
                                  </div>
                                  <div className='stepname step-row-margin'>
                                    <SearchSelectField
                                            id={"stepname"}
                                            label={"Step Name"}
                                            width={"90%"}
                                            placeholder={"- Select -"}
                                            isMandatory={true}
                                            showMandatory={this.props?.postInitiated || false}
                                            value={step?.step_name_id || null}
                                            options={this.props?.stepNameOptions || []}
                                            onChange={(e,value)=> this.props?.changeHandler('step_name_id', step?.deal_memo_step_id, value)}  
                                        />
                                        {/* <BasicTextField
                                            id={"stepname"}
                                            label={"Step Name"}
                                            placeholder={"[Step Name]"}
                                            isMandatory={true}
                                            showMandatory={this.props?.postInitiated || false}
                                            limit={sizeLimits.nameCharacterLimit}
                                            limitWarning={messages.exceed50CharacterWarning || ""}
                                            value={step?.step_name || null}
                                            onChange={(e) => validateCharNumOnlyWithSpace(e.target.value) ? this.props?.changeHandler('step_name', step?.deal_memo_step_id, e.target.value) : null}
                                        /> */}
                                    </div>
                                    </div>
                                    <div id='DraftSelectField' className={`${!this.props?.projectId?.value ? 'disabled-draft' : ''}`}>
                                        <SearchSelectField
                                            id={"draftSelect"}
                                            label={"Draft"}
                                            width={"90%"}
                                            placeholder={"- Select -"}
                                            detail_selected={step?.draft_submission_id || null}
                                            options={step?.draftOptions || []}
                                            searchSelect={true}
                                            disabled={!this.props?.projectId?.value || false}
                                            searchText={this.handleDraftSearchText.bind(this, step?.deal_memo_step_id)}
                                            valueSelected={(e, selected) => this.props?.changeHandler('draft_submission_id', step?.deal_memo_step_id, selected)}
                                        />

                                    </div>
                                    {/* <div className="textArea step-notes">
                                        <BasicTextArea
                                            label={"Notes"}
                                            id={"textarea-performer"}
                                            rows={2}
                                            value={step?.deal_notes || null}
                                            limit={sizeLimits.limitchar500}
                                            limitWarning={messages.exceed500CharacterWarning || ""}
                                            onChange={(e) => this.props?.changeHandler('deal_notes', step?.deal_memo_step_id, e.target.value)}
                                        />
                                    </div> */}
                                    <br />
                                </MDBCol>
                                <MDBCol md={6} className="Second-Section p-0">

                                    {this.props?.step?.writers?.map((obj, index) => (
                                        (!obj?.is_delete ? 
                                            <div className="writer-field">                                       
                                                <ListSearchSelectField
                                                    id={`writer${index}${this.props?.stepIndex}`}
                                                    key={`writer${index}${this.props?.stepIndex}`}
                                                    label={index === 0 ? "Writers" : ""}
                                                    hidePrimary={true}
                                                    width={"100%"}
                                                    isMandatory={true}
                                                    searchFieldColWidth={11}
                                                    actionsColWidth={1}
                                                    placeholder={"- Search -"}
                                                    showMandatory={this.props?.postInitiated || false}
                                                    detail_selected={(obj?.writer_id || obj?.talent_group_id) || null}
                                                    renderOption={obj?.writerOptions || []}
                                                    searchSelect={true}
                                                    stepId={step?.deal_memo_step_id}
                                                    workId={obj?.deal_step_writer_id}
                                                    addCallback={this.addWriterCallback.bind(this, step?.deal_memo_step_id)}
                                                    removeCallback={this.removeWriterCallback.bind(this, step?.deal_memo_step_id, obj?.deal_step_writer_id)}
                                                    searchText={this.handleWriterSearchText.bind(this, step?.deal_memo_step_id, obj?.deal_step_writer_id)}
                                                    showAdd={this.props?.step?.writers?.filter(o=>!o?.is_delete)?.length - 1 === index ? true : ((this.props?.step?.writers?.length - 1) === index) ? true : false}
                                                    // showRemove={this.props?.step?.writers?.length === 1 ? true : ((this.props?.step?.writers?.length - 1) !== index) ? true : false}
                                                    showRemove={this.props?.step?.writers.length > 1}
                                                    valueSelected={(e, selected) => { 
                                                        this.props?.changeWriterHandler(!selected?.writerTeam ? 'writer_id' : 'talent_group_id', step?.deal_memo_step_id, obj?.deal_step_writer_id, selected) 
                                                    }}
                                                    searchSelectAddField={true}
                                                /> 
                                            </div>
                                        :null)
                                    ))}
                                </MDBCol>
                            </MDBRow>
                            <div className="textArea step-notes">
                                <BasicTextArea
                                    label={"Notes"}
                                    id={"textarea-performer"}
                                    rows={3}
                                    value={step?.deal_notes || null}
                                    limit={sizeLimits.limitChar2000}
                                    limitWarning={messages.exceed2000characterWarning || ""}
                                    onChange={(e) => this.props?.changeHandler('deal_notes', step?.deal_memo_step_id, e.target.value)}
                                    typedCharCount={step?.deal_notes?.length || 0}
                                    showCharCount={true}
                                />
                            </div>
                        </div>
                        <div>
                            <MDBRow>
                                <MDBCol md={4} className="datefield">
                                    <div id="date-field step-row-margin">
                                        <DateField
                                            id="writingstart"
                                            label={"Writing Start"}
                                            value={step?.writing_start || null} width={"230px"}
                                            onChange={(e) => this.props?.changeHandler('writing_start', step?.deal_memo_step_id, e.target.value)}
                                        />
                                    </div>
                                    <div id="date-field step-row-margin">
                                        <DateField
                                            id="delivery"
                                            label={"Delivery"}
                                            value={step?.delivery_date || null} width={"200px"}
                                            onChange={(e) => this.props?.changeHandler('delivery_date', step?.deal_memo_step_id, e.target.value)}
                                        />
                                    </div>
                                    <div id="label-height">
                                        <InlineSelectField
                                            className="Select"
                                            id="PaymentSelection"
                                            selectFieldLength={6}
                                            textFieldLength={6}
                                            defaultMenuText={" "}
                                            textFieldposition="end"
                                            label="Payment"
                                            options={this?.props?.currencySelectData}
                                            fieldValue={step?.payment || null}
                                            selectValue={step?.currency || null}
                                            onFieldChange={(e) => validateDecimalNumbers(e.target.value) ? this.props?.changeHandler('payment', step?.deal_memo_step_id, e.target.value) : null}
                                            onSelectChange={(e, value) => this.props?.changeHandler('currency', step?.deal_memo_step_id, e.target.value)}
                                        />
                                    </div>
                                </MDBCol>
                                <MDBCol md={5}>
                                    <div id="label-height step-row-margin" >
                                        <InlineSelectField
                                            className="SelectDuration"
                                            id="DurationLable"
                                            textFieldLength={4}
                                            selectFieldLength={8}
                                            textFieldposition=""
                                            label="Duration"
                                            options={this?.props?.durationSelectData}
                                            selectValue={step?.duration_period || null}
                                            fieldValue={step?.duration == 0 ? step?.duration?.toString() : step?.duration || null}
                                            onFieldChange={(e) => validateNumbersOnly(e.target.value) ? this.props?.changeHandler('duration', step?.deal_memo_step_id, e.target.value) : null}
                                            onSelectChange={(e, value) => this.props?.changeHandler('duration_period', step?.deal_memo_step_id, e.target.value)}
                                        />
                                    </div>
                                    <div  id="label-height">
                                        <InlineSelectField
                                            className="Select"
                                            id="DurationLable"
                                            textFieldLength={4}
                                            selectFieldLength={8}
                                            textFieldposition=""
                                            label="Reading Period"
                                            options={this?.props?.durationSelectData}
                                            selectValue={step?.reading_period || null}
                                            fieldValue={step?.reading == 0 ? step?.reading?.toString() : step?.reading || null}
                                            onFieldChange={(e) => validateNumbersOnly(e.target.value) ? this.props?.changeHandler('reading', step?.deal_memo_step_id, e.target.value) : null}
                                            onSelectChange={(e, value) => this.props?.changeHandler('reading_period', step?.deal_memo_step_id, e.target.value)}
                                        />
                                    </div>
                                </MDBCol>
                                <MDBCol md={2} className="pr-0">
                                    <div className="writing-due step-row-margin">
                                        <BasicLabel text={"Writing Due"} />
                                        <BasicLabel text={step?.writing_due ? getFormattedDate(step?.writing_due) : '-'} />
                                    </div>
                                    <div className="reading-end">
                                        <BasicLabel text={"Reading End"} />
                                        <BasicLabel text={step?.reading_end ? getFormattedDate(step?.reading_end) : '-'} />
                                    </div>
                                </MDBCol>
                                {this.props?.showDelete &&
                                <MDBCol md={1}>
                                    <span className='M2'>
                                        <MDBIcon 
                                            size="sm" 
                                            icon={"trash-alt"} 
                                            // onClick={() => this.props?.deleteSteps(step?.deal_memo_step_id, step?.mode)} 
                                            onClick={() => this.handleDeleteConfirmation(step?.deal_memo_step_id, step?.mode, step?.deal_ammendment_id)}
                                        />
                                    </span>
                                </MDBCol>
                                }
                            </MDBRow>
                        </div>
                    </div>
                ))}

                <hr />
            </>
        )
    }
}
);