import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';

import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-time-picker/assets/index.css';
import './ProjectDetailReport.scss';
import { withUserContext } from '../../contexts/UserContext';
import SearchSelectList from '../../components/Views/Submissions/Dialogs/SearchSelectList';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import ScripterService from '../../services/service';
import * as Constants from '../../constants/constants';

export default withUserContext(class ProjectDetailReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            projectList: [],
            selectedProjectList: []
        }
    }
    componentDidMount() {
        this.getEntity('projectList', 'PROJECT', '');
    }
    getEntity = (entity, entitytype, searchString) => {
        let loading = (entitytype === 'PROJECT') ? 'isProjectListLoading' : 'loading';
        this.setState({ [loading]: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`,
            this.props.userContext.active_tenant.tenant_id, this.props.userContext.active_tenant.division_id)
            .then(response => {
                let formattedList = [];
                console.log("Project Response: ", response);
                if (entitytype === 'PROJECT') {
                    formattedList = response?.data?.map(item => ({ value: item.project_id, text: item.project_title }));
                }
                this.setState({ projectList: formattedList, [loading]: false });
                console.log("Project Data: ", this.state.projectList);
            },
                (err) => {
                    this.setState({ [loading]: false });
                    console.log("Error in fetching entities:", err)
                })
    }


    render() {

        let isLoading = this.state.isFetchingProjectDetailReports;
        return (
            <>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBContainer className="ProjectDetailReportsContainer">
                            <MDBRow>
                                <BasicLabel text={"Project"} />
                            </MDBRow>
                            <MDBRow>
                                {console.log("Projects Data:", this.state.projectList)}
                                <MDBCol md={11} className="searchSelectcontent">
                                    <SearchSelectList
                                        //id={"projects" + project?.project_id}
                                        // value={this.setState({selectedProjectList: { value: project_id, text: project_title }}) || ""}
                                        placeholder="-Search Projects-"
                                        options={this.state.projectList || []}
                                        showAdd={(this.state.selectedProjectList?.length - 1)}
                                        showRemove={this.state.selectedProjectList.length > 1}
                                        // addCallback={() => this.addAdditionalField('projects')}
                                    // removeCallback={() => this.removeCallback('projects', project)}
                                    //onChange={(e, value) => this.handleListEdit("project", value, project, "projects")}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="p-2">
                                <MDBCol md={8} >
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        type="inline"
                                        variant="outlined"
                                        text={"Cancel"}
                                        onClick={this.props?.onClose}
                                        disabled={false}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        className="btnwidth"
                                        variant="contained"
                                        type="inline"
                                        text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate"}
                                        disabled={false}
                                        onClick={this.GenerateReportDetails}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    )}
            </>
        )
    }
}
);