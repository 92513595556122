import React from 'react';
import { MDBContainer, MDBRow, MDBCol} from 'mdbreact';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-time-picker/assets/index.css';
import { withUserContext } from '../../contexts/UserContext';

export default withUserContext(class DetailedSubmissionReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {

        let isLoading = this.state.isFetchingDetailedSubmissionReport;
        return (
            <>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                    <MDBContainer className="ProjectDetailReportsContainer">
                        <MDBRow className="p-2">
                            <MDBCol md={8} >
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    type="inline"
                                    variant="outlined"
                                    text={"Cancel"}
                                    onClick={this.props?.onClose}
                                    disabled={false}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    className="btnwidth"
                                    variant="contained"
                                    type="inline"
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate"}
                                    disabled={false}
                                    onClick={this.GenerateReportDetails}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}
            </>
        )
    }
}
);