
export const selectOptionsFeature = [
    {
        label: "Submissions",
        value: 2,
        searchType : "SUBMISSION",
        id: 'submission_id',
        name: 'title',
        date: 'received_date',
        searchGroup:'Submissions',
        searchParam:'title',
        searchSubGroup:'Submissions',
        coverageType:'coverage_type_name'
    },
    {
        label: "Projects",
        value: 3,
        searchType : "PROJECT",
        id: 'project_id',
        name: 'project_title',
        date: 'date_created',
        searchGroup:'Projects',
        searchParam:'project_title',
        searchSubGroup:'Project',
        projectStatus:"project_status_name"
    },
    {
        label: "Deals",
        value: 4,
        searchType : "DEAL_MEMO",
        id: 'deal_memo_id',
        name: 'document_name',
        date: 'memo_date',
        searchGroup:'Deals',
        searchParam:'document_name',
        searchSubGroup:'Deal',
        projectTitle:'project_title'
    },
    {
        label: "Weekend Reads",
        value: 5,
        searchType : "WEEKEND_READ",
        id: 'weekend_read_id',
        name: 'title',
        date: 'weekend_read_date',
        searchGroup:'Weekend_Reads',
        searchParam:'title',
        searchSubGroup:'WeekendRead'
    },
    {
        label: "Talent",
        value: 6,
        searchType : "TALENT",
        id: 'talent_id',
        name: 'talent_name',
        date: 'date_created',
        searchGroup:'Talent',
        searchParam:'first_name,last_name, talent_group_members_name',
        searchSubGroup:'Talent'
    }
]