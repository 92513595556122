import React from 'react';
import BasicLabel from '../BasicLabel/BasicLabel';;
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './AutocompleteAdd.scss';

const filter = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
});

const AutocompleteAdd = (props) => {
    return (
        <div className="autocomplete-add-container">
            {props?.label && <BasicLabel text={props?.label || ""} type={"text"}
                    isMandatory={props?.isMandatory || false} />}
            <Autocomplete
                id={props?.id || ""}
                options={props?.options || []}
                value={props?.value}
                freeSolo
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option ? option : "";
                    } else
                        if (option?.inputValue) {
                            return option.inputValue;
                        } else {
                            if (option.text?.includes("Add '")) {
                                option.text = option.text?.match(/'([^']+)'/)[1];
                            }
                            return option.text ? option.text : "";
                        }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                        filtered.push({
                            value: params.inputValue,
                            text: `Add '${params.inputValue}'`,
                        });
                    }

                    return filtered;
                }}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        props?.handleEdit(props?.value, newValue, "autocomplete")
                    } else if (newValue && newValue.inputValue) {
                        props?.handleEdit(props?.value, newValue.inputValue, "autocomplete")
                    } else {
                        if (newValue?.text?.includes("Add '")) {
                            newValue.text = newValue.text.match(/'([^']+)'/)[1];
                        }
                        props?.handleEdit(props?.value, newValue, "autocomplete")
                    }
                }}
                renderOption={(option) => option.text}
                renderInput={(params) => <TextField {...params} variant="outlined" onChange={(e) => props?.handleEdit(props?.value, e.target.value, "gift_name")} />}
            />
        </div>
    );
}

export default AutocompleteAdd;
