import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { MDBCard, MDBCardBody, MDBCardText,MDBTooltip } from 'mdb-react-ui-kit';
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import { getFormattedDate } from '../../../../Common/Helper';

export default class DealsDetalisContainer extends React.Component {

    handleProjectRedirection = () => {
        if (this.props?.dealsDetails?.project_id) {
            let url = '/projectDetails/' + this.props?.dealsDetails?.project_id;
            return this.props?.history?.push(url);
        }
    }
 
    render() {
        let dealDetail = this.props?.dealsDetails;
        return (
            <div className="DetailsContainer">
                <MDBCard className="submissionDetailsCard">
                    <MDBCardBody>
                        <MDBCardText>
                            {this.props?.fetchingDealDetails ? (
                                <div className="ContentLoader">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div className="submissionDetailsSection">
                                    <div className="sectionWidth">
                                        <MDBRow>
                                            <MDBCol md={2}>
                                                <BasicLabel type={"text"} text={"Memo Date"} />
                                                <p>{dealDetail?.memo_date ? getFormattedDate(dealDetail?.memo_date, false) : "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={3}>
                                                <BasicLabel type={"text"} text={"Studio Division"} />
                                                <p>{dealDetail?.division_name || "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={3}>
                                                <BasicLabel type={"text"} text={"Amendment"} />
                                                {(this.props?.canModifyDeal && !this.props?.isAssignedToProjectPermission) &&
                                                    <span className="pluse-button">
                                                        <BasicButton
                                                            variant="contained"
                                                            text=" "
                                                            icon={"plus-circle"}
                                                            onClick={() => this.props?.onAmendmentClick('newAmendment')}
                                                            />
                                                    </span>
                                                }
                                                {
                                                    dealDetail?.deal_ammendments?.map(item=>(
                                                        <span className="flex docIcon">
                                                            <p className='dateText'>{item?.ammendment_date ? getFormattedDate(item?.ammendment_date, false) :"-"}</p>
                                                            <MDBTooltip 
                                                                tag="a" 
                                                                title={item?.ammendment_notes}
                                                                placement="right" 
                                                            >
                                                                <MDBIcon icon="file" size='sm' />
                                                            </MDBTooltip>
                                                            {!this.props?.isAssignedToProjectPermission ? 
                                                                <MDBIcon 
                                                                    icon="pen" 
                                                                    size='sm' 
                                                                    onClick={() => this.props?.onAmendmentClick('editAmendment', null, item?.deal_ammendment_id)} 
                                                                /> : null
                                                            }
                                                        </span>
                                                    ))                                            
                                                }
                                            </MDBCol>
                                            <MDBCol md={4} className="pr-0">
                                                <BasicLabel type={"text"} text={"Assigned Material"} />
                                                <p>{dealDetail?.assigned_material || '-'}</p>

                                                <BasicLabel type={"text"} text={"Project"} />
                                                <p className="project-redirection-link" onClick={() => this.handleProjectRedirection()}>{dealDetail?.project_title || '-'}</p>
                                            </MDBCol>
                                        </MDBRow>                                   
                                    </div>
                                    <div className="sectionWidth">
                                        <MDBRow>
                                            <MDBCol md={11} className="ml-2">
                                                <BasicLabel type={"text"} text={"Notes"} />
                                                <p>{dealDetail?.deal_notes || '-'}</p>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </div>
                            )}
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </div>
        )
    }
}