import React from 'react';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBContainer, MDBNavbarLink, MDBDropdownLink
} from 'mdb-react-ui-kit';
import { fade, makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link'
// import logo from '../assets/logo.png';
import logo from '../assets/WB_logo.png';
import { useHistory } from "react-router-dom";
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import AsynchronousSearch from '../../src/components/SharedComponents/AsynchronousSerach/AsynchronousSearch'
import { withUserContext } from '../contexts/UserContext';
import MessageModal from '../components/SharedComponents/MessageModal';
import UserManual from '../../src/assets/Scripter - Application User Manual.pdf'
import OneSheetDoc from '../../src/assets/Scripter One Sheet V5.docx'

export default //withRouter(
    withUserContext(
        withOktaAuth(class HeaderContent extends React.Component {
            constructor(props) {
                super(props);
                this.logout = this.logout.bind(this);
                this.login = this.login.bind(this);
                this.state = {
                    confirmSaveChanges: false,
                    switch_division_id: null,
                    switch_division_name: null,
                    isManageUser: false
                }
            }

            async logout() {
                var len = localStorage.length;
                var keys = Object.keys(localStorage);
                for (var i = 0; i < len; i++) {
                    var key = keys[i];
                    if (key.indexOf("okta-") == -1)
                        localStorage.removeItem(key);
                }

                this.props.oktaAuth.signOut('/');
            }


            async login() {
                // Redirect to '/' after logout
                this.props.authService.login('/');
            }

            handleSideNavActive = (value, routePath) => {
                this.props.history.push(routePath, this.state);
            }

            switchTenant = (division_id, division_name) => {
                this.setState({ switch_division_id: division_id, switch_division_name: division_name, confirmSaveChanges: !this.state.confirmSaveChanges });
            }

            confirmCancelChanges = () => {
                this.props.userContext.switchTenant(this.state.switch_division_id);
                this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
            }
            componentWillReceiveProps(nextProps) {
                this.setState({
                    isManageUser: nextProps.userContext?.active_tenant?.permissions?.isManageUser
                });
            }

            render() {
                const bgColorBlue = { backgroundColor: '#0062AF' }
                const adminTitle = { fontSize: '13px', color: '#ffffff', padding: '2px' }
                const ptli = { paddingTop: '0.1rem' }
                let userRole = this.props?.userContext?.user_profile?.group_name;

                return (
                    <div id="headerContent">
                        {/* <Router fixed="top"> */}
                        <header  >
                            <div className="fixed-top">
                                <MDBNavbar bgColor="primary" dark expand="md" fixed sticky >
                                    <MDBContainer fluid>
                                        <MDBNavbarBrand className='mb-0 h1'>

                                            {/* <MDBNavbarLink tag={Link} active={false} className="nav-link-override" to="/" > */}
                                            <Link component={RouterLink} className="action-icon" to={'/'} >
                                                <img
                                                    src={logo}
                                                    alt='Logo' className="imageWidth"
                                                //onClick={this.handleSideNavActive.bind(this, 'LP', "/")}
                                                /></Link>

                                            {/* </MDBNavbarLink> */}
                                            <h1 className="app-title">
                                                SCRIPTER
                                            </h1>
                                        </MDBNavbarBrand>
                                        <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'>                                            {/* <MDBNavbarItem className="d-none d-lg-block">
                                            <span style={adminTitle}>Signed in as: &nbsp;
                                            <b> {this.props.user_full_name} </b>
                                                <br />
                                                <i> {this.props.active_tenant_role ? this.props.active_tenant_role + ", " + this.props.active_tenant_name : ""} </i>
                                            </span>
                                        </MDBNavbarItem> */}
                                            <MDBNavbarItem className="padding-hor-25">
                                                <AsynchronousSearch props={this.props}></AsynchronousSearch>
                                            </MDBNavbarItem>
                                            <MDBNavbarItem className="padding-hor-25" >
                                                <MDBDropdown group className='shadow-0'
                                                    options={{
                                                        modifiers: [
                                                            {
                                                                name: 'offset',
                                                                options: {
                                                                    offset: [-100, 0],
                                                                },
                                                            },
                                                        ]
                                                    }}>
                                                    <MDBDropdownToggle tag="a" size='lg' className="bgColorBlue shadow-none text-white" >
                                                        <MDBIcon fas size='3x' icon="user-circle" className="mr-2" />
                                                    </MDBDropdownToggle>
                                                    <MDBDropdownMenu className="text-end border border-1-quinary">
                                                        {/* {this.props?.userContext?.active_tenant?.divisions?.map((division) => {
                                                            if (division.division_id != this.props.userContext?.active_tenant?.division_id)
                                                                return <MDBDropdownItem data-active={false} key={division.division_id}
                                                                    onClick={this.switchTenant.bind(this, division.division_id, division.division_name)}>
                                                                    <MDBDropdownLink className="text-tertiary" active={false} to='/'>
                                                                        <small>{division.division_name}</small>
                                                                    </MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                            else
                                                                return <MDBDropdownItem data-active={false} key={division.division_id}>
                                                                    <MDBDropdownLink className="text-dark" active={false} to='/'>
                                                                        <small>{division.division_name}</small>
                                                                    </MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                        }
                                                        )} */}
                                                        {userRole === 'Feature Story Admin' &&
                                                            <>
                                                                <MDBDropdownItem data-active={false} key={"manual"}>
                                                                    <MDBDropdownLink className="text-tertiary logout-link" href={UserManual} download="Scripter Application User Manual.pdf">
                                                                        <small>{'User Manual'}</small>
                                                                    </MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                                <MDBDropdownItem data-active={false} key={"oneSheet"}>
                                                                    <MDBDropdownLink className="text-tertiary logout-link" href={OneSheetDoc} download="Scripter One Sheet V5.docx">
                                                                        <small>{'One Sheet V5'}</small>
                                                                    </MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                            </>
                                                        }
                                                        {userRole !== 'Feature Story Admin' && 
                                                            <MDBDropdownItem data-active={false} key={"oneSheet"}>
                                                                <MDBDropdownLink className="text-tertiary logout-link" href={OneSheetDoc} download="Scripter One Sheet V5.docx">
                                                                    <small>{'One Sheet V5'}</small>
                                                                </MDBDropdownLink>
                                                            </MDBDropdownItem>
                                                        }
                                                        <MDBDropdownItem data-active={false} key={"logout"}  onClick={() => this.logout()}>
                                                            <MDBDropdownLink className="text-tertiary logout-link" active={false} to='/'>
                                                                <small>{'Logout'}</small>
                                                            </MDBDropdownLink>
                                                        </MDBDropdownItem>

                                                    </MDBDropdownMenu>
                                                </MDBDropdown>
                                            </MDBNavbarItem>

                                        </MDBNavbarNav>
                                    </MDBContainer>
                                </MDBNavbar>
                                <MessageModal
                                    open={this.state.confirmSaveChanges}
                                    title={"Unsaved Changes"}
                                    message={"Switching division will lose any unsaved changes. Do you want to proceed?"}
                                    primaryButtonText={"Yes"}
                                    secondaryButtonText={"No"}
                                    onConfirm={() => this.confirmCancelChanges()}
                                    handleClose={(e) => this.setState({ confirmSaveChanges: false })} />
                            </div>
                        </header>
                        {/* </Router> */}
                    </div>
                );
            }
        }
        )
    );