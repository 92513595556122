export const projectDetails = {
    "project_wga_id": null,
    "wga_type_id_selected": null,
    "wga_type_id": null,
    "project_id": null,
    "notice_date": null,
    "episode_title": null,
    "series_title": null,
    "title": null,
    "production": null,
    "production_company": null,
    "executive_director": null,
    "executive_producer": null,
    "producer": null,
    "associate_producer": null,
    "co_producer": null,
    "director": null,
    "other_production_staff": null,
    "original_exhibition_site": null,
    "on_screen": null,
    "on_screen_source_credit": null,
    "on_screen_production_credit": null,
    "source_material": null,
    "continuing_source_material": null,
    "episode_writing_credits": null,
    "revised_final_script": null,
    "final_date": null,
    "company": null,
    "wga_date": null,
    "notes": null,
    "wga_name": null,
    "wga_phone": null,
    "wga_address": null,
    "writers": [],
}

export const participatingWriterList =
{
    "project_wga_writer_id": null,
    "writer_name": null,
    "additional_writer_team_info":null,
    "adjusted_representative_name": null,
    "adjusted_representative_address": null,
    "row_no": 1,
    "showMandatory":false,
    "writer_selected": null,
    "representation_list": [],
    "address_list": [],
    "writer_list": [],
}

export const talentPrefixes = [
    { value: "Dr", label: "Dr" },
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" }
]

export const talentSuffixes = [
    { value: "Esq.", label: "Esq." },
    { value: "I", label: "I" },
    { value: "II", label: "II" },
    { value: "III", label: "III" },
    { value: "IV", label: "IV" },
    { value: "V", label: "V" },
    { value: "Sr.", label: "Sr." },
    { value: "Jr.", label: "Jr." }
]
