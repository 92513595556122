import React, { useState } from 'react';
import { MDBIcon, MDBRow } from 'mdbreact';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import './shared.scss';
import { MDBCol } from 'mdbreact';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import SearchSelectField from './SearchSelectField'
import SearchFieldWithAddValue from './SearchFieldWithAddValue';
const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,

});

export default class ListSearchSelectField extends React.Component {
    constructor(props)
    {
        super(props);
        this.state={}
    }
    render() {
        let error = (this.props.showMandatory && (!this.props.searchSelect ? !this.props?.value.value : this.props?.detail_selected?.length > 0 ? false : true)) || false;
        return (
            <MDBRow className={`ListSearchSelectFieldContainer ${error && 'error'}`}>
                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                        isMandatory={this.props?.isMandatory || false} />}
              
                    <MDBCol 
                        md={this.props?.searchFieldColWidth || 10}
                        className="search-selct-field"
                    >
                        {this.props?.searchSelectAddField ? 
                        <SearchFieldWithAddValue id={this.props?.id}
                        value ={this.props?.value || ""}
                        isMandatory={this?.props?.isMandatory}
                        showMandatory={this.props?.showMandatory || false}
                        detail_selected={this?.props?.detail_selected || null}
                        onChange = {this.props?.onChange}
                        valueSelected = {this?.props?.valueSelected}
                        disabled = {this.props?.disabled || false}
                        placeholder = {this.props?.placeholder || ""}
                        getOptionLabel = {this.props?.getOptionLabel}
                        filterOptions = {this.props?.filterOptions}
                        options = {this.props?.renderOption}
                        searchSelect={this.props?.searchSelect}
                        searchText={this.props?.searchText}
                        width={this.props?.width}
                        renderInput = {this.props?.renderInput}></SearchFieldWithAddValue>                        
                            :
                            <SearchSelectField id={this.props?.id}
                            value ={this.props?.value || ""}
                            isMandatory={this?.props?.isMandatory}
                            showMandatory={this.props?.showMandatory || false}
                            detail_selected={this?.props?.detail_selected || null}
                            onChange = {this.props?.onChange}
                            valueSelected = {this?.props?.valueSelected}
                            disabled = {this.props?.disabled || false}
                            placeholder = {this.props?.placeholder || ""}
                            getOptionLabel = {this.props?.getOptionLabel}
                            filterOptions = {this.props?.filterOptions}
                            options = {this.props?.renderOption}
                            searchSelect={this.props?.searchSelect}
                            searchText={this.props?.searchText}
                            width={this.props?.width}
                            renderInput = {this.props?.renderInput}
                            ></SearchSelectField>
                    }
                       
                    </MDBCol>
                    <MDBCol md={this.props?.actionsColWidth || 2} className = "actions">
                        {
                            (this.props?.showRemove && !this.props?.disabled) ? <RemoveCircleIcon className='fontSizeSmall' onClick = {this.props?.removeCallback} /> : null
                        }
                        {
                            (this.props?.showAdd) ? <AddCircleIcon className='fontSizeSmall' onClick = {this.props?.addCallback} /> : null
                        }
                    </MDBCol>
                
            </MDBRow>
        );
    }
}

