export const queueFilters = [
    {
        label: "Submissions",
        value: "SUBMISSIONS"
    },
    {
        label: "Projects",
        value: "PROJECTS"
    },
    {
        label: "Deals",
        value: "DEALS"
    },
    {
        label: "Weekend Reads",
        value: "WEEKEND_READS"
    },
]
const selectOptionsSubmissions = [
    {
        label: "All Submissions",
        value: 1,
    },
    {
        label: "Confidential",
        value: 2,
    },
    {
        label: "Non - Confidential",
        value: 3,
    },
]
const selectOptionsProjects = [
    {
        label: "All Projects",
        value: 1,
    },
    {
        label: "Confidential",
        value: 2,
    },
    {
        label: "Non - Confidential",
        value: 3,
    },
]


const confidentialFlag = [
    {
        label: "Confidential",
        value: 1
    },
    {
        label: "Non-Confidential",
        value: 0
    }
]

const activeOrInactive = [
    {
        label: "Active",
        value: 1
    },
    {
        label: "Inactive",
        value: 0
    },
]

const compareOptions = [
    {
        label: "<",
        value: 0
    },
    {
        label: ">",
        value: 1
    },
    {
        label: "=",
        value: 2
    },
    {
        label: ">=",
        value: 3
    },
    {
        label: "<=",
        value: 4
    },
]

const configObjectSubmissions = {
    iscolumnFilterRequired: true,
    headings: [{
        headingLabel: "Title / AKAs",
        icon: "",
        width: "180%",
        inputType: "text",
        isColumn: true,
        dataNode: "akaTitle",
        searchJson: "title_aka_search",
        isDisabled:true
    },
    {
        headingLabel: "Authors",
        icon: "",
        width: "100%",
        inputType: "autocomplete",
        selectOptions: "authorsOptions",
        searchJson: "author_search_ids",
        isColumn: true,
        dataNode: "authors",
        entityType: "TALENT"
    },

    {
        headingLabel: "Submitted To",
        icon: "",
        width: "100%",
        inputType: "autocomplete",
        selectOptions: "submittedByOptions",
        isColumn: true,
        dataNode: "submitted_to",
        entityType: "USER",
        searchJson: "submitted_to_ids",
    },
    {
        headingLabel: "Format",
        icon: "",
        width: "100%",
        inputType: "dropdown", isColumn: true,
        dataNode: "format",
        selectOptions: "formatOptions",
        tableName: "lu_format",
        searchJson: "format_search_ids"
    },
    {
        headingLabel: "Received Date",
        icon: "",
        width: "100%",
        inputType: "daterange", 
        isColumn: true,
        dataNode: "received_date",
        searchJson: "received_date",
        operator: "received_date_operator",
        selectOptions: "receivedDateOptions",
        tableName: "none"
    },
    {
        headingLabel: "Analyst",
        icon: "",
        width: "100%",
        inputType: "autocomplete",
        selectOptions: "analystOptions", isColumn: true,
        dataNode: "user_name", // ????????
        entityType: "USER",
        searchJson: "analyst_search_ids"
    },
    {
        headingLabel: "Coverage Due Date",
        icon: "",
        width: "100%",
        inputType: "daterange", isColumn: true,
        dataNode: "due_date", // ??????
        searchJson: "coverage_due_date",
        operator: "coverage_due_date_operator",
        selectOptions: "coverageDueDateOptions",
        tableName: "none"
    },
    {
        headingLabel: "Coverage Type",
        icon: "",
        width: "100%",
        inputType: "dropdown",
        selectOptions: "coverageTypeOptions", isColumn: true,
        dataNode: "coverage_type",
        tableName: "lu_coverage_types",
        searchJson: "coverage_type_ids"
    },
    {
        headingLabel: "Status",
        icon: "",
        width: "100%",
        inputType: "dropdown",
        selectOptions: "statusSubmissionOptions", 
        isColumn: true,
        dataNode: "status",
        searchJson: "status_ids",
        tableName:"lu_submission_status"
    },
    {
        headingLabel: "Project",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "project_title",
        searchJson: "project_search_text"
    },
    {
        headingLabel: "Deal Step",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "deal_step", 
        searchJson: "deal_step_search_text"
    },
    {
        headingLabel: "Term Deal Company",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "term_deal_company",
        searchJson: "term_deal_company_search_text" 
    },
    {
        headingLabel: "Company",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "company",
        searchJson: "company_search_text" 
    },
    {
        headingLabel: "Unofficial/Interim",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false, 
        dataNode: "un_official_interim",
        searchJson: "unofficial_interim_dd",
    },
    {
        headingLabel: "Submission Notes",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "submission_notes",
        searchJson: "submission_notes_search_text"
    },
    {
        headingLabel: "Draft Date",
        icon: "",
        width: "0%",
        inputType: "daterange",
        isColumn: false,
        dataNode: "draft_date",
        searchJson: "draft_date",
        operator: "draft_date_operator",
        selectOptions: "draftDateOptions",
        tableName: "none"
    },
    {
        headingLabel: "Draft Version",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "draft_version",
        searchJson: "draft_version_search_text"
    },
    {
        headingLabel: "Page Count",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "pgs",
        searchJson: "min_pgs"
    },
    {
        headingLabel: "WB Page Count",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "wb_pgs",
        searchJson: "min_wb_pgs"
    },
    {
        headingLabel: "BOX #",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "box",
        searchJson: "box_search_text"
    },
    {
        headingLabel: "SKU #",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "sku",
        searchJson: "sku_search_text"
    },
    {
        headingLabel: "Confidentiality",
        icon: "",
        width: "0%",
        inputType: "dropdown",
        isColumn: false,
        dataNode: "is_confidential",
        searchJson: "confidential_flag", // ????
        selectOptions: "confidentialOptions", //(1 or 0)
        tableName: "none"

    },
    {
        headingLabel: "Elements",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "elements",
        searchJson: "elements_search_text"
    },
    {
        headingLabel: "Studio Division",
        icon: "",
        width: "0%",
        inputType: "dropdown",
        selectOptions: "divisionOptions",
        isColumn: false,
        dataNode: "division_name",
        entityType: "USER_DIVISIONS",
        searchJson: "division_search_ids",
    },

    ],
    dataNodes: ["akaTitle", "authors", "box", "company", "coverage_type", "draft_date", "draft_version", "due_date", "elements", "format", "is_confidential", "pgs", "project_title", "received_date", "sku", "status", "submission_id", "submission_notes", "submitted_to", "term_deal_company", "title", "un_official_interim", "wb_pgs", "user_name", "deal_step", "division_name"],
    primaryKey: "submission_id",
    // actions: ["pen"],
    changeColourToRed: ["akaTitle"],
    changeColourToGreen: ["Active"],
    filtersLanding: true,
    hyperlinks: ["akaTitle"],
    isLanding: true
};

const configObjectProjects = {
    iscolumnFilterRequired: true,
    headings: [{
        headingLabel: "Title / AKAs",
        icon: "",
        width: "100%",
        inputType: "text",
        isColumn: true,
        dataNode: "akaTitle",
        searchJson: "title_aka_search",
        isDisabled:true
    },
    {
        headingLabel: "Genre",
        icon: "",
        width: "100%",
        inputType: "dropdown",
        isColumn: true,
        dataNode: "genre",
        searchJson: "genre_search_ids",
        selectOptions: "genreOptions",
        tableName: "lu_genre"
    },
    {
        headingLabel: "Logline",
        icon: "",
        width: "100%",
        inputType: "text",
        isColumn: true,
        dataNode: "log_line",
        searchJson: "logline_search_text"
    },
    {
        headingLabel: "Creative Executives",
        icon: "",
        width: "100%",
        inputType: "autocomplete",
        isColumn: true,
        dataNode: "creative_executives",
        searchJson: "creative_executive_search_ids",
        selectOptions: "creativeExecutives",
        entityType: "USER"
    },
    {
        headingLabel: "Status",
        icon: "",
        width: "100%",
        inputType: "dropdown",
        isColumn: true,
        dataNode: "status",
        searchJson: "status_ids",
        selectOptions: "statusOptions",
        tableName: "lu_project_status"
    },
    {
        headingLabel: "Studio Division",
        icon: "",
        width: "100%",
        inputType: "dropdown",
        selectOptions: "divisionOptions",
        isColumn: true,
        dataNode: "division_name",
        entityType: "USER_DIVISIONS",
        searchJson: "division_search_ids",
    },
    {
        headingLabel: "Material Location",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "material_location",
        searchJson: "material_location_search_text"
    },
    {
        headingLabel: "Vault Information",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "vault_information",
        searchJson: "vault_information_search_text"
    },
    {
        headingLabel: "Project Type",
        icon: "",
        width: "0%",
        inputType: "dropdown",
        isColumn: false,
        dataNode: "project_type_name",
        searchJson: "project_type_search_ids",
        selectOptions: "projectTypeOptions",
        tableName: "lu_project_type"
    },
    {
        headingLabel: "Authors",
        icon: "",
        width: "0%",
        inputType: "autocomplete",
        isColumn: false,
        dataNode: "authors",
        selectOptions: "authorOptions",
        entityType: "TALENT",
        searchJson: "author_search_ids"
    },
    {
        headingLabel: "Confidentiality",
        icon: "",
        width: "0%",
        inputType: "dropdown",
        isColumn: false,
        dataNode: "is_confidential",
        selectOptions: "confidentialOptions", //(1 or 0)
        tableName: "none",
        searchJson: "confidential_flag"
    },
    {
        headingLabel: "Project Notes",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "project_notes",
        searchJson: "project_notes_search_text"
    },
    {
        headingLabel: "SAP (Dev)",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "SAP_dev",
        searchJson: "sap_dev_search_text"
    },
    {
        headingLabel: "SAP (Prod)",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "SAP_prod",
        searchJson: "sap_prod_search_text"
    },
    {
        headingLabel: "Wrap Date",
        icon: "",
        width: "0%",
        inputType: "daterange",
        isColumn: false,
        dataNode: "wrap_date",
        searchJson: "wrap_date",
        operator: "wrap_date_operator",
        selectOptions: "wrapDateOptions",
        tableName: "none"
    },
    {
        headingLabel: "Production Start Date",
        icon: "",
        width: "0%",
        inputType: "daterange",
        isColumn: false,
        dataNode: "prod_start_date",
        searchJson: "production_start_date",
        operator: "production_start_date_operator",
        selectOptions: "productionStartDateOptions",
        tableName: "none"
    },
    {
        headingLabel: "Release Date",
        icon: "",
        width: "0%",
        inputType: "daterange",
        isColumn: false,
        dataNode: "release_date",
        searchJson: "release_date",
        operator: "release_date_operator",
        selectOptions: "releaseDateOptions",
        tableName: "none"
    },
    {
        headingLabel: "Rights Lapse Date",
        icon: "",
        width: "0%",
        inputType: "daterange",
        isColumn: false,
        dataNode: "rights_lapse_date",
        searchJson: "rights_lapse_date",
        operator: "rights_lapse_date_operator",
        selectOptions: "rightsLapseDateOptions",
        tableName: "none"
    },
    {
        headingLabel: "Distribution Company",
        icon: "",
        width: "0%",
        inputType: "dropdown",
        isColumn: false,
        dataNode: "distribution_company_name",
        selectOptions: "distributionCompanyOptions",
        tableName: "lu_distribution_company",
        searchJson: "distribution_company_search_ids"
    },
    {
        headingLabel: "MPM#",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "mpm_no",
        searchJson: "mpm_no_search_text"
    },
    ],
    dataNodes: ["project_id", "project_title", "aka", "genre", "log_line", "creative_executives", "status", "studiodivision"
        , "material_location", "vault_information", "project_type_name", "authors", "is_confidential", "project_notes", "SAP_dev", "SAP_prod"
        , "wrap_date", "prod_start_date", "release_date", "rights_lapse_date", "distribution_company_name", "akaTitle", "division_name","mpm_no"],
    primaryKey: "project_id",
    // actions: ["pen"],
    changeColourToRed: ["akaTitle"],
    changeColourToGreen: ["Active"],
    filtersLanding: true,
    hyperlinks: ["akaTitle"],
    isLanding: true
};

const configObjectDeals = {
    iscolumnFilterRequired: true,
    headings: [{
        headingLabel: "Project",
        icon: "",
        width: "150%",
        inputType: "text",
        isColumn: true,
        dataNode: "project_title",
        searchJson: "project_search_text"
    },
    {
        headingLabel: "Document Name",
        icon: "",
        width: "150%",
        inputType: "text",
        isColumn: true,
        dataNode: "document_name",
        searchJson: "document_name_search_text",
    },
    {
        headingLabel: "Deal Date",
        icon: "",
        width: "90%",
        inputType: "daterange",
        isColumn: true,
        dataNode: "memo_date",
        searchJson: "deal_date",
        operator: "deal_date_operator",
        selectOptions: "dealDateOptions",
        tableName: "none"
    },
    {
        headingLabel: "Amendments",
        icon: "",
        width: "90%",
        inputType: "daterange",
        isColumn: true,
        dataNode: "ammendments",
        searchJson: "ammendment_date",
        operator: "ammendment_date_operator",
        selectOptions: "ammendmentDateOptions",
        tableName: "none"
    },
    {
        headingLabel: "Deal Notes",
        icon: "",
        width: "100%",
        inputType: "text",
        isColumn: true,
        dataNode: "deal_notes",
        searchJson: "deal_notes_search_text"
    },
    {
        headingLabel: "Business Affair Executive",
        icon: "",
        width: "100%",
        inputType: "autocomplete",
        isColumn: true,
        dataNode: "ba_executive",
        selectOptions: "businessAffairsOptions",
        entityType: "USER", //pass grp name along with user
        searchJson: "ba_executive_search_ids"

    },
    {
        headingLabel: "Feature Legal Executive",
        icon: "",
        width: "100%",
        inputType: "autocomplete",
        isColumn: true,
        dataNode: "legal_executive",
        selectOptions: "featureLegalExecOptions",
        entityType: "USER", //pass grp name 
        searchJson: "legal_executive_search_ids"

    },
    {
        headingLabel: "Studio Division",
        icon: "",
        width: "100%",
        inputType: "dropdown",
        selectOptions: "divisionOptions",
        isColumn: true,
        dataNode: "division_name",
        entityType: "USER_DIVISIONS",
        searchJson: "division_search_ids",
    }],
    dataNodes: ["project_id", "project_title", "memo_date", "legal_executive", "document_name", "deal_notes", "ba_executive", "ammendments", "division_name"],
    primaryKey: "deal_memo_id",
    // actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    filtersLanding: true,
    hyperlinks: ["document_name"]
};

const configObjectWeekendRead = {
    iscolumnFilterRequired: true,
    headings: [{
        headingLabel: "WER Date",
        icon: "",
        width: "100%",
        inputType: "daterange",
        isColumn: true,
        dataNode: "weekend_read_date",
        searchJson: "wer_date",
        operator: "wer_date_operator",
        selectOptions: "werDateOptions",
        tableName: "none"
    },
    {
        headingLabel: "Title",
        icon: "",
        width: "100%",
        inputType: "text",
        isColumn: true,
        dataNode: "title",
        searchJson: "title_search_text"
    },
    {
        headingLabel: "Draft Date",
        icon: "",
        width: "100%",
        inputType: "daterange",
        isColumn: true,
        dataNode: "draft_date",
        searchJson: "draft_date",
        operator: "draft_date_operator",
        selectOptions: "DraftDateOptions",
        tableName: "none"
        
    },
    // {
    //     headingLabel: "Attached Coverages",
    //     icon: "",
    //     width: "100%",
    //     inputType: "autocomplete",
    //     isColumn: false,
    //     dataNode: "attached_coverages",
    //     selectOptions: "authorOptions",
    //     entityType: "SUBMISSION",
    //     searchJson: "attached_coverages_ids"
    // },
    {
        headingLabel: "Per / Advocate",
        icon: "",
        width: "100%",
        inputType: "autocomplete",
        isColumn: true,
        dataNode: "advocate",
        selectOptions: "advocateOptions",
        entityType: "USER",
        searchJson: "per_advocate_search_ids"
    },
    {
        headingLabel: "Assigned To",
        icon: "",
        width: "100%",
        inputType: "autocomplete",
        isColumn: true,
        dataNode: "assigned_to",
        selectOptions: "assignedToOptions",
        entityType: "USER", //???? 
        searchJson: "assigned_to_search_ids"

    },
    {
        headingLabel: "Weekend Read Notes",
        icon: "",
        width: "100%",
        inputType: "text",
        isColumn: true,
        dataNode: "notes", //??? 
        searchJson: "weekend_read_notes_search_text",
        isHtml:true

    },
    {
        headingLabel: "Execs Who've Read",
        icon: "",
        width: "0%",
        inputType: "autocomplete",
        isColumn: false,
        dataNode: "exec_read",
        searchJson: "exec_read_search_ids",
        entityType: "USER" //????? user
    },
    {
        headingLabel: "Additional Material",
        icon: "",
        width: "0%",
        inputType: "text",
        isColumn: false,
        dataNode: "additional_material",
        searchJson: "additional_material_search_text"
    },
    {
        headingLabel: "Submitted/Produced By",
        icon: "",
        width: "0%",
        inputType: "autocomplete",
        isColumn: false,
        dataNode: "submitted_by",
        selectOptions: "submittedByOptions",
        entityType: "TALENT_REP", // for talent 
        // searchJson: "submitted_by_search_ids"
        searchJson:["submitted_by_talent_search_ids","submitted_by_talent_group_search_ids","submitted_by_representative_search_ids"]

    }],
    dataNodes: ["weekend_read_id", "weekend_read_date", "additional_material", "advocate", "assigned_to", "draft_date", "exec_read", "notes", "submitted_by", "title"],
    primaryKey: "weekend_read_id",
    // actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    filtersLanding: true,
    hyperlinks: ["title"]
};

const tabList = [
    { label: "Submissions", index: 0, name: "allusers" },
    { label: "Projects", index: 1, name: "groups" },
    { label: "Deals", index: 2, name: "groups" },
    { label: "Weekend Read", index: 3, name: "groups" },
    { label: "My Queue", index: 4, name: "groups" },
]

export const submissionFiltersSearchJson = {
    "title_aka_search": null,
    "author_search_ids": null,
    "submitted_to_ids": null,
    "format_search_ids": null,
    "received_date": null,
    "received_date_operator": null,
    "analyst_search_ids": null,
    "coverage_due_date": null,
    "coverage_due_date_operator": null,
    "coverage_type_ids": null,
    "status_ids": null,
    "project_search_text": null,
    "deal_step_search_text": null,
    "term_deal_company_search_text": null,
    "company_search_text": null,
    "unofficial_interim_dd": null,
    "submission_notes_search_text": null,
    "draft_date": null,
    "draft_date_operator": null,
    "draft_version_search_text": null,
    "min_pgs": null,
    "max_pgs": null,
    "min_wb_pgs": null,
    "max_wb_pgs": null,
    "box_search_text": null,
    "sku_search_text": null,
    "confidential_flag": null,
    "elements_search_text": null,
    "order_by_clause": null,
    "order_by": null,
    "show_historical_data": 0,
    "page_count": 10,
    "page_no": 1
}

export const projectFiltersSearchJson = {
    "title_aka_search": null,
    "genre_search_ids": null,
    "logline_search_text": null,
    "creative_executive_search_ids": null,
    "status_ids": null,
    "material_location_search_text": null,
    "vault_information_search_text": null,
    "project_type_search_ids": null,
    "author_search_ids": null,
    "confidential_flag": null,
    "project_notes_search_text": null,
    "sap_dev_search_text": null,
    "sap_prod_search_text": null,
    "wrap_date": null,
    "wrap_date_operator": null,
    "production_start_date": null,
    "production_start_date_operator": null,
    "release_date": null,
    "release_date_operator": null,
    "rights_lapse_date": null,
    "rights_lapse_date_operator": null,
    "distribution_company_search_ids": null,
    "order_by_clause": null,
    "order_by": null,
    "page_count": 10,
    "page_no": 1,
    "mpm_no_search_text": null
}

export const dealsFilterSearchJson = {
    "project_search_text": null,
    "document_name_search_text": null,
    "deal_date": null,
    "deal_date_operator": null,
    "ammendment_date": null,
    "ammendment_date_operator": null,
    "deal_notes_search_text": null,
    "ba_executive_search_ids": null,
    "legal_executive_search_ids": null,
    "order_by_clause": null,
    "order_by": null,
    "page_no": 1,
    "page_count": 10,
    "logged_user_id": null
}

export const weekendReadFilterSearchJson = {
    "wer_date": null,
    "wer_date_operator": null,
    "title_search_text": null,
    "author_search_ids": null,
    "min_draft_date": null,
    "max_draft_date": null,
    "per_advocate_search_ids": null,
    "assigned_to_search_ids": null,
    "weekend_read_notes_search_text": null,
    "exec_read_search_ids": null,
    "additional_material_search_text": null,
    "submitted_by_talent_search_ids": null,
    "submitted_by_talent_group_search_ids": null,
    "submitted_by_representative_search_ids": null,
    "attached_coverages_ids":null,
    "order_by_clause": null,
    "order_by": null,
    "page_no": 1,
    "page_count": 10
}

export const projectAndDeal = {
    "user_id": null,
    "is_deal_queue": null,
    "user_queue_deal_id": null,
    "deal_memo_id": null,
    "user_queue_project_id": null,
    "project_id": null,
    "is_project_queue": null,
    "is_delete": null
}


export {
    selectOptionsSubmissions, selectOptionsProjects, configObjectSubmissions, configObjectProjects, configObjectDeals, configObjectWeekendRead, tabList, confidentialFlag, activeOrInactive, compareOptions
}