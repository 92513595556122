import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import SelectField from '../SharedComponents/SelectField/SelectField';
import TableComponent from '../SharedComponents/Table';
import TablePagination from '@material-ui/core/TablePagination';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import {lookupType, lookupConfig} from './Config';
import './ManageLookup.scss';
import InlineButtonField from '../SharedComponents/InlineButtonField/InlineButtonField'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import messages from '../../Common/Messages.json'
import { withUserContext } from '../../contexts/UserContext'
import ScripterService from '../../services/service' 
import * as Constants from '../../constants/constants'
import sizeLimits from '../../Common/SizeLimits.json';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper'

export default  withUserContext( class ManageLookup extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            selectedType : "",
            lookupType: [],
            tableData: [],
            showContent: false,
            inlineButtonField: "",
            setIndex: 0,
            rowsPerPage: 5,
            page: 0,
            sortCount: 0,
            sortBy: "Value",
            isSortByAsc: true,
            config: lookupConfig,
            
        }
    }

    componentDidMount() {
        let data = [];
        if(this.props.userContext?.active_tenant?.tenant_id === 4){
            lookupType.map((item)=>{
                if(item.value != "lu_role_type" && item.value != "lu_contract_status" && item.value != "lu_network"){
                    data.push(item)
                }
            });
            this.setState({lookupType:data})
        } else {
            this.setState({lookupType:lookupType})
        }
    }
    
    fetchDetails = (val) => {
        this.setState({ selectedType: val });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=' + val, val === 'user_role' ? null : this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, check: item.is_active === 1 ? true : false }));
                this.setState({ tableData: formattedList, showContent: true, inlineButtonField: "", page: 0,renderList: handlePaginationList(0, this.state.rowsPerPage, formattedList) }
                );
                },
                (err) => {
                    console.log("Error in fetching Market Types:", err)
                })
    }
    editList = () => {
        if (this.state.setIndex === 0) {
            if (this.state.inlineButtonField && this.state.inlineButtonField.length <= sizeLimits.nameCharacterLimit) {
                let details= JSON.parse(JSON.stringify([...this.state.tableData]))
                details.push({ value: null, label: this.state.inlineButtonField, check: true })
                this.setState({ tableData: details, formEdited: true }, () => {
                    this.setState({ renderList: handlePaginationList(this.state.page, this.state.rowsPerPage, this.state.tableData) });
                    
                })
                this.setState({ inlineButtonField :"" });
            }
        } else {
            if (this.state.inlineButtonField.length <= sizeLimits.nameCharacterLimit) {
                let details= JSON.parse(JSON.stringify([...this.state.tableData]))
                details.map(item => {
                    if (item.value === this.state.setIndex) {
                        item.label = this.state.inlineButtonField;
                    }
                });
                this.setState({ tableData: details, formEdited: true },() => { this.handleSubmit() });
            }
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage, renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.tableData) });
    }
    handleSubmit = () => {
        this.setState({ isSubmit: true });
        let lookupArray = this.state.tableData.map(item =>
            ({ name: item.label, id: item.value, is_active: item.check ? 1 : 0 })
        );
        let lookupJson = {
            "table_name": this.state.selectedType,
            "values": lookupArray
        };
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/staticData', lookupJson, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({ isSubmit: false },
                    () => {
                        this.props.fieldChanged(true, "success");
                       
                    });
                console.log(response.data[0])
            },
                (err) => {
                    console.log("Error in fetching Work Details:", err)
                    this.setState({ isSubmit: false },
                        () => {
                            this.props.fieldChanged(true, "fail");
                            
                        });
                })
    }

    arrayCheckUncheck = (event, dataItem) => {
        let details= JSON.parse(JSON.stringify([...this.state.tableData]))
        details?.map(item => {
            if (item.value === dataItem.value) {
                item.check = dataItem.check;
            }
        });
        this.setState({ tableData: details, formEdited: true });

    }
    
    editData = (dataItem, node) => {
        
        this.setState({ inlineButtonField: dataItem.label, setIndex: dataItem.value });
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Value": return "label";
            case "Active": return "staticMode";
        }
    }
    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                renderList: handlePaginationList(this.state.page, this.state.rowsPerPage,
                    tableSortList("Name", this.getSortNode(colName), this.state.tableData, this.state.isSortByAsc))

            });
        })
    }

    render()
    {
        return(
            <MDBContainer className = "ManageLookupContainer">
                <MDBRow>
                    <MDBCol md={8}>
                        <div className ="pt5">
                            <SelectField 
                                placeHolderText={"-Select Type -"}
                                value={this.state.selectedType}
                                options={this.state.lookupType}
                                onChange={(e) => { this.fetchDetails(e.target.value) }}
                            />
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <div className="pt5">
                        <TableComponent
                            list={this.state.renderList || []}
                            config = {lookupConfig}
                            isLoading={false}
                            arrayCheckUncheck={this.arrayCheckUncheck}
                            editData={this.editData}
                            sortCallback={this.handleTableColumnClick}
                        />
                    </div>
                </MDBRow>
                <MDBRow className="Pagination">
                    <TablePagination
                        component="div"
                        count={this.state.tableData ? this.state.tableData?.length : 0}
                        rowsPerPageOptions={[]}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                    />
                </MDBRow>
                <MDBRow>
                    {!this.state.showContent ?
                        <div className="pt5">
                            <span> Choose a Lookup Type to View its values</span>
                        </div> : null}
                </MDBRow>
                <MDBRow>
                    {this.state.showContent ? 
                    <div>
                        <MDBRow>
                            <MDBCol md={12}>
                                <div className="pt5">
                                    <MDBCol md={8} className="inline-block">
                                        <InlineButtonField id={"key20"}
                                            value = {this.state.inlineButtonField}
                                            placeholder = {this.state.setIndex === 0 ? "Add Value" : "Edit Value"}
                                            onChange = {(e) => this.setState({ inlineButtonField : e.target.value})}
                                            buttonOnClick = {this.state.setIndex==0 ? (e) => this.editList():""}
                                            limit={sizeLimits.nameCharacterLimit}
                                            limitWarning={messages.exceed50CharacterWarning || ""}
                                        />
                                    </MDBCol>
                                    <MDBCol md={4} className="inline-block p11 ps-1">
                                        {this.state.setIndex != 0 ? 
                                            <BasicButton onClick ={() => this.setState({ setIndex: 0, inlineButtonField: "" })}
                                                variant = "contained"
                                                size = "small"
                                                color = "primary"
                                                text = "Cancel"
                                                />: null
                                        }
                                    </MDBCol>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                            <MDBCol md={7}></MDBCol>
                            <MDBCol md={3} className="p-0 primary-btn">
                                <BasicButton
                                    className="btnwidth"
                                    variant = "contained"
                                    type = "inline"
                                    text = {this.state.isSubmit ? <CircularProgress color="inherit" size={20} /> : this.props?.primaryButtonText}
                                    disabled = {false}
                                    onClick = {() => this.state.setIndex === 0 ? this.handleSubmit() : this.editList()}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="p-0">
                                <BasicButton
                                    type = "inline"
                                    variant ="outlined"
                                    text = {this.props?.secondaryButtonText}
                                    disabled = {false}
                                    onClick = {() => this.props?.onClose()}
                                />
                            </MDBCol>
                        </MDBRow>
                    </div>
                    : null}
                </MDBRow>
            </MDBContainer>
        )
    }
} 
)