export const representedTalentListConfig = {
    headings: [
        {
            headingLabel: "Talent Name",
            icon: "",
            isColumn: true
        },
        {
            headingLabel: "Datetime Added",
            icon: "",
            isColumn: true
        }],
    dataNodes: ["talent_name", "created_at"],
    primaryKey: "representative_id",
    hyperlinks: ["talent_name"]
};

export const initialRepresentativePhone = {
    "representative_phone_id": null,
    "phone_type_id": null,
    "phone_type": "",
    "is_international": 0,
    "phone": "",
    "is_primary": 0,
    "index": 1
}

export const phoneConfig = {
    "phoneTypeId": "phone_type_id",
    "phoneType": "phone_type",
    "phone": "phone",
    "isPrimary": "is_primary",
    "isInternational": "is_international"
}

export const initialRepresentativeEmail = {
    "representative_email_id": null,
    "email_type_id": null,
    "email_type": null,
    "email": null,
    "is_primary": 0,
    "index": 1
}

export const emailConfig = {
    "emailTypeId": "email_type_id",
    "emailType": "email_type",
    "email": "email",
    "isPrimary": "is_primary"
}

export const initialRepresentativeAddress = {
    "representative_address_id": null,
    "address_type_id": null,
    "address_type": null,
    "address": null,
    "address_1": null,
    "address_2": null,
    "address_3": null,
    "city": null,
    "state_id": "",
    "state": null,
    "country_id": 0,
    "country": null,
    "zip": null,
    "is_primary": 0,
    "is_international": 0,
    "index": 1
}

export const addressConfig = {
    "addressTypeId": "address_type_id",
    "addressType": "address_type",
    "address": "address",
    "address1": "address_1",
    "address2": "address_2",
    "address3": "address_3",
    "city": "city",
    "zipcode": "zip",
    "isPrimary": "is_primary",
    "isInternational": "is_international",
    "country": "country",
    "countryId": "country_id",
    "state": "state",
    "stateId": "state_id"
}

export const initialRepresentativeAgencies = {
    "agency_id": null,
    "agency_name": null,
    "agency_type_id": null,
    "agency_type_name": null,
    "is_active": null,
    "agency_selected": {}
}

export const newRepresentativeDetails = {
    "representative_id": null,
    "is_active": 1,
    "m_i": null,
    "email_list": [],
    "phone_list": [],
    "address_list": [],
    "agencies": [],
    'prefix': null,
    "first_name": null,
    "last_name": null,
    "suffix": null,
    "type_id": 1,
    "type_name": null

}