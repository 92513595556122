import React from 'react';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import './loader.scss';

export default class Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.isOpen || false}
                    style={{ backgroundColor: 'transparent' }}
                    overlayStyle={{ backgroundColor: 'transparent' }}
                >
                    <div className="Loader">
                        <MDBRow>
                            <MDBCol md={4}><CircularProgress /></MDBCol>
                            <MDBCol md={7} className="text"><span>{this.props.text || ""}</span></MDBCol>
                        </MDBRow>
                    </div>
                </Dialog>
            </div>
        )
    }
}