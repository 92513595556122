import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { subMockData, limit, subElement, talentPrefixes, talentSuffixes } from './Config';
import EditElements from './EditElements';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';
import MessageModal from '../../../SharedComponents/MessageModal';

class SubmissionEditElementsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submissionDetails: this.formatSubmissionList(JSON.parse(JSON.stringify({ ...subMockData }))),
            inActiveElements: [],
            postInitiated: false,
            isFetchingSubmissionDetails: false,
            isFetchingStatuses: false,
            isFetchingRoles: false,
            statuses: [],
            roles: [],
            talentOptions:[],
            elementIndex: 2,
            isPosting: false,
            isOpen: false
        }
    }

    componentDidMount() {
        if (this.props?.submissionId) {
            this.fetchSubmissionDetails()
        }
       
        this.getStatuses();
        this.getRoles();
    }
    getTalents = (event,element) => {
        console.log("value", event.target.value)
             if (event.target.value !== "" || event.target.value !== null) {
                this.setState({ loading: true });
                ScripterService.getData(
                    Constants.scripterServiceBaseUrl +
                    `/entitySearch?entity=TALENT&searchString=${event.target.value}`,
                    this.props.userContext.active_tenant.tenant_id
                )
                    .then((response) => {
                       
                        let formattedList = response?.data?.map((item) => ({
                            value: item?.talent_id || item?.talent_group_id,
                            text: item?.talent_name,
                            writerTeam: item?.writer_team,
                            type:"talent",
                           
                        }));
                        let updateElement = [...this.state?.submissionDetails?.elements?.map(ele=>{
                            if(ele?.index === element?.index){
                                ele.talentOptions = formattedList
                                ele.index=element?.index
                            }
                            return ele;
                        })]
                        this.setState(prevState=>({
                            submissionDetails:{
                                ...prevState.submissionDetails,
                                elements:updateElement     
                            },                          
                            talentOptions: formattedList,
                            loading: false,
                        }));
                    })
                    .catch((err) => {
                        console.log("error", err);
                        this.setState({ loading: false });
                    });
            }
        
        }
    getFormatTalentGroupName = (talentTeamList) => {
        let talentGroupName = [];
        talentTeamList?.map(item => {
            talentGroupName.push(item?.talent_name)
        })
        return talentGroupName?.join(' & ');
    }
    handleNewTalentDetails(talentDetails){
        let value = {
            value: talentDetails?.talent_id || talentDetails?.talent_group_id,
            text: talentDetails?.talent_id ? 
                // `${talentDetails?.first_name + (talentDetails?.last_name ? (' ' + talentDetails?.last_name) : '')}` :
                ((talentDetails?.prefix ? talentDetails?.prefix + ' ' : '') + talentDetails?.first_name + ' ' + (talentDetails?.m_i ? talentDetails?.m_i + ' ' : '') + (talentDetails?.last_name ? talentDetails?.last_name : '') + (talentDetails?.suffix ? ' ' + talentDetails?.suffix : '')) :
                this.getFormatTalentGroupName(talentDetails?.talent_group_members_list) || null,        
            writerTeam: talentDetails?.writer_team,
            type:"talent"
        }
        let obj={
            index:this.state?.currentIndex
        }
        this.handleListEdit('name',value,obj,'elements')
    }
    selectedTalent=(value,obj)=>{
        this.setState({currentIndex:obj?.index})
        if(value && !value?.hasOwnProperty('type')){
            let talentDetails = {}
            let prefixDetails = this.getTalentName("prefix", value?.value);
            if (prefixDetails) {
                talentDetails['prefix_id'] = prefixDetails?.value;
                talentDetails['prefix'] = prefixDetails?.label;
            }
            talentDetails['first_name'] = this.getTalentName("first_name", value?.value);
            talentDetails['middle_name'] = this.getTalentName("middle_name", value?.value) || null;
            talentDetails['last_name'] = this.getTalentName("last_name", value?.value) || null;
            let suffixDetails = this.getTalentName("suffix", value?.value);
            if (suffixDetails) {
                talentDetails['suffix_id'] = suffixDetails?.value;
                talentDetails['suffix'] = suffixDetails?.label;
            }
            this.props?.navFuns?.handleShowTalentPopup(null, 0, "new", this.handleNewTalentDetails.bind(this), talentDetails)
        }
        else{
            this.handleListEdit('name',value,obj,'elements')
        }
    }
    getTalentName = (field, value) => {
        let prefixes = [ ...talentPrefixes ];
        let suffixes =[ ...talentSuffixes ];
        let n = value?.split(" ");
        if (field === "first_name" || field === "middle_name" || field === "last_name") {
            let prefixExist = prefixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[0]?.toLowerCase()));
            if (prefixExist) {
                n.splice(0, 1);
            }
            let suffixExist = suffixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[n?.length - 1]?.toLowerCase()));
            if (suffixExist) {
                n.pop();
            }
        }
        if (field === "first_name") {
            if (n?.length > 0) {
                return n[0];
            }
        } else if (field === "middle_name") {
            if (n?.length > 2) {
                return n[1];
            }
        } else if (field === "last_name") {
            if (value.split(" ").length > 1) {
                let lastName = [];
                n?.map((item, index) => {
                    if (n?.length <= 2 && index !== 0) {
                        lastName.push(item)
                    } else if (n?.length > 2 && index !== 0 && index !== 1) {
                        lastName.push(item)
                    }
                })
                return lastName.join(" ");
            }
        } else if (field === "prefix") {
            let prefix = prefixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[0]?.toLowerCase()));
            return prefix;
        } else if (field === "suffix") {
            let suffix = suffixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[n?.length -1]?.toLowerCase()));
            return suffix;
        }
    }
    fetchSubmissionDetails = () => {
        if (this.props.submissionId) {
            this.setState({ isFetchingSubmissionDetails: true });
            ScripterService.getData(Constants.scripterServiceBaseUrl + `/submission?submissionId=${this.props.submissionId}`,
                this.props.userContext.active_tenant.tenant_id)
                .then(response => {
                    this.setState({ isFetchingSubmissionDetails: false });
                    if (response.data.error) {
                        console.log("response error", response)
                    } else {
                        console.log("response", response?.data[0])
                        let dataList=this.formatSubmissionList(JSON.parse(JSON.stringify({ ...response?.data[0] })))
                        this.setState({ submissionDetails:dataList, isFetchingSubmissionDetails: false,elementIndex:dataList?.elements?.length > 1?dataList?.elements?.length + 1:2})
                    }
                },
                    (err) => {
                        this.setState({ isFetchingSubmissionDetails: false });
                        console.log("Error in fetching submission details:", err)
                    })
        }
    }

    formatSubmissionList = (response) => {
        if (response?.elements?.length === 0) {
            response?.elements.push(JSON.parse(JSON.stringify({ ...subElement })));
        }
        else{
            response?.elements?.map((item,index)=>{
                item.index = index+1
                item.talent_selected = {
                    value:item?.talent_id || item?.talent_group_id,
                    text:item?.element_name,
                    type:"talent",
                    writerTeam:item?.writer_team
                }
                item.talentOptions=[{
                    value:item?.talent_id || item?.talent_group_id,
                    text:item?.element_name,
                    type:"talent",
                    writerTeam:item?.writer_team
                }]
                return item;
            })
        }
        return response;
    }

    getStatuses = () => {
        this.setState({ isFetchingStatuses: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_element_status',
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ statuses: formattedList, isFetchingStatuses: false });
            },
                (err) => {
                    this.setState({ isFetchingStatuses: false });
                    console.log("Error in fetching statuses:", err)
                })
    }

    getRoles = () => {
        this.setState({ isFetchingRoles: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_talent_roles',
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ roles: formattedList, isFetchingRoles: false });
            },
                (err) => {
                    this.setState({ isFetchingRoles: false });
                    console.log("Error in fetching roles:", err)
                })
    }

    addAdditionalField = (listName) => {
        this.setState({ postInitiated: false });
        let submissionDetails = JSON.parse(JSON.stringify({ ...this.state.submissionDetails }));
        let initialElementArray = JSON.parse(JSON.stringify({ ...subElement }));
        let elements = [...submissionDetails.elements];
        initialElementArray.index = this.state.elementIndex;
        initialElementArray.canRemove = true;
        elements.push(initialElementArray)
        submissionDetails.elements = elements;
        this.setState({ elementIndex: this.state.elementIndex + 1, submissionDetails: submissionDetails });
    }

    showMessage = () => {
        this.setState({ isOpen: true });
    }

    deleteRow = (listName, callbackItem, type) => {
        let submissionDetails = this.state.submissionDetails;
        submissionDetails[listName][this.state.index].is_delete = 1;
        if (type === 'edit') {
            let temp = [];
            this.state.inActiveElements?.map((item) => {
                temp.push(item);
            });
            let element_list = submissionDetails[listName].filter(item =>
                (item.is_delete === 1 && item.index === callbackItem.index)
            );
            temp.push(element_list[0]);
            this.setState({ inActiveElements: temp })
        }
        let element_list = submissionDetails[listName].filter(item =>
            (item.submission_element_id !== callbackItem.submission_element_id) || (item.index !== callbackItem.index)
        );
        if (element_list.length === 0) {
            submissionDetails[listName] = [{ ...subElement }];
        } else {
            submissionDetails[listName] = element_list;
        }
        this.setState({
            submissionDetails: submissionDetails, listName: null, callbackItem: null, postInitiated: false, isOpen: false
        });
    }

    removeField = (listName, callbackItem, index) => {
        this.setState({
            formEdited: true,
            listName: listName, callbackItem: callbackItem, postInitiated: false, index: index
        }, () => {
            if (callbackItem.submission_element_id) {
                this.showMessage();
            } else {
                this.deleteRow(listName, callbackItem, 'new');
            }
        })
    }

    handleGeneralEdit = (field, value) => {
        let newValue = value;
        if (typeof value === "boolean") {
            newValue = value ? 1 : 0
        }
        this.setState(prevState => ({
            submissionDetails: {
                ...prevState.submissionDetails,
                [field]: newValue
            },
            postInitiated: false
        }))
    }

    handleListEdit = (field, value, obj, listName) => {
        // let submissionDetails = JSON.parse(JSON.stringify({ ...this.state.submissionDetails }));
        let submissionDetails = { ...this.state.submissionDetails };
        let id = (listName === "elements") ? "submission_element_id" : "";
        let list = submissionDetails[listName]?.map(item => {
            let currentVal = item;
            if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                currentVal[field] = value;
                if(field === "name"){
                    currentVal.element_name = value?.text
                    currentVal.talent_id = !value?.writerTeam ? value?.value : null
                    currentVal.talent_group_id = value?.writerTeam ? value?.value : null
                    currentVal.writerTeam = value?.writerTeam
                    currentVal.talent_selected =value

                }                
                else if (field === 'element_role_id') {
                    currentVal.element_role = this.state.roles?.find(role => role.value === value)?.label;
                } else if (field === 'element_status_id') {
                    currentVal.element_status = this.state.statuses?.find(status => status.value === value)?.label;
                }
                return currentVal;
            } return currentVal;
        })
        this.handleGeneralEdit(listName, list);
    }

    formatWithUniqueEntries = () => {
        let submissionDetails = JSON.parse(JSON.stringify({ ...this.state.submissionDetails }));
        submissionDetails.elements = [...submissionDetails.elements].filter((tag, index, array) =>
            (array.findIndex(t => t.element_name == tag.element_name && (t.talent_id == tag.talent_id || t.talent_group_id == tag.talent_group_id) && t.element_role_id == tag.element_role_id &&
                t.element_status_id == tag.element_status_id) == index));
        return submissionDetails.elements;
    }

    getFilteredJson = (elementDetailsJson) => {
        let elementTemp = [];
        this.state.inActiveElements?.map(item => {
            elementTemp?.push(item);
        });
        elementDetailsJson?.map(item => {
            elementTemp?.push(item);
        });
        elementDetailsJson = elementTemp;
        return elementDetailsJson
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true }, () => {
            let elementDetailsJson = this.formatWithUniqueEntries();
            if (this.validateJson(elementDetailsJson)) {
                let filteredPostElementsJson = this.getFilteredJson(elementDetailsJson);
                this.postElementDetails(filteredPostElementsJson);
            }
        })
    }

    validateJson = (elementDetailsJson) => {
        let canSubmit =
            (!elementDetailsJson?.some(element => (!(element.element_name &&(element?.talent_id || element?.talent_group_id) &&
                (element.element_name ? element.element_name?.length < limit.name : true)) || false)))
        return canSubmit;
    }

    postElementDetails = (postJson) => {
        this.setState({ postInitiated: true, isPosting: true })
        this.props.setPostFlag(true);
        let payload = {
            submission_id: this.props?.submissionId,
            elements: postJson
        }
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/submissionElements', payload,
            this.props.userContext.active_tenant.tenant_id, this.state.submissionDetails?.division_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isPosting: false })
                    this.props.setPostFlag(false);
                    this.props.fieldChanged(true, "fail");
                } else {
                    this.setState({ postInitiated: false, isPosting: false });
                    this.props.toggleRefreshPage('elements');
                    this.props.setPostFlag(false);
                    this.props.fieldChanged(true, "success");
                }
            },
                (err) => {
                    console.log("Error in posting submission:", err)
                    this.setState({ postInitiated: false, isPosting: false });
                    this.props.setPostFlag(false);
                    this.props.fieldChanged(true, "fail");
                })
    }

    render() {
        return (
            <MDBContainer fluid className="submission-edit-elements-container">
                <MessageModal
                    open={this.state.isOpen}
                    title={"Delete record"}
                    message={"Would you like to delete saved record?"}
                    primaryButtonText={"Delete"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => this.deleteRow(this.state.listName, this.state.callbackItem, 'edit')}
                    handleClose={(e) => this.setState({ isOpen: false })}
                />
                <div className="submission-elements">
                    {
                        this.state.submissionDetails?.elements?.map((element, index) => (
                            (element.is_delete === 0 ?
                                <MDBRow>
                                    <MDBCol md={11} className="p-0">
                                        <EditElements
                                            index={index}
                                            getTalents={this.getTalents}
                                            selectedTalent={this.selectedTalent}
                                            statuses={this.state.statuses || []}
                                            roles={this.state.roles || []}
                                            element={element || {}}
                                            talentOptions={this.state?.talentOptions}
                                            onChange={this.handleListEdit}
                                            isMandatory={true}
                                            postInitiated={this.state.postInitiated || false}
                                        />
                                    </MDBCol>
                                    <MDBCol md={1} className="p-0">
                                        <MDBRow className={index === 0 ? "actionButons ml-1" : "ml-1"}>
                                            <MDBCol md={1} className="actions p-0">
                                                {
                                                    !(this.state.submissionDetails?.elements.length === 1) ?
                                                        <MDBIcon
                                                            icon="trash-alt"
                                                            size='sm'
                                                            onClick={(e) => this.removeField('elements', element, index)}
                                                        />
                                                        : null
                                                }
                                            </MDBCol>
                                            <MDBCol md={1} className="actions">
                                                {
                                                    (this.state.submissionDetails?.elements.length - 1 === index && element.element_name?.length > 0) ?
                                                        <AddCircleIcon fontSize="small"
                                                            onClick={(e) =>
                                                                this.addAdditionalField('elements', index)
                                                            } /> : null
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                                : null
                            )
                        ))
                    }
                </div>
                {this.props?.mode === "Edit" &&
                    <MDBRow className="p-2">
                        <MDBCol md={8} >
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicButton
                                type="inline"
                                variant="outlined"
                                text={"Cancel"}
                                onClick={this.props?.handleClose}
                                disabled={false}

                            />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicButton
                                className="btnwidth"
                                variant="contained"
                                type="inline"
                                text={this.state.isPosting ? <CircularProgress color="inherit" size={18} /> : "Save"}
                                disabled={false}
                                onClick={this.handleSubmit}
                            />
                        </MDBCol>
                    </MDBRow>
                }
            </MDBContainer>
        )
    }
}

export default withUserContext(SubmissionEditElementsContainer)