import React from 'react';
import TableComponent from '../../components/SharedComponents/Table';
import { Card, Button } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { MDBBtn } from 'mdb-react-ui-kit';
import { configObjectAllUsers, configObjectAllUsersModal, allUsersList, ActiveOrInActive } from '../UserManagement/config';
import "../UserManagement/usermanagement.scss";
import IconTextField from '../../components/SharedComponents/IconTextField/IconTextField';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { handlePaginationList, applyFilterTable, updateTableSortConfigObject, tableSortList } from '../../../src/Common/TableHelper';
import CreateOrEditUserModal from '../../../src/pages/NewUserPopUp/CreateOrEditUserModal';
import TablePagination from '@material-ui/core/TablePagination';
// import CreateOrEditGroupModal from '../UserManagement/GroupModal/NewGroup';
import * as Constants from '../../constants/constants';
import scripterService from '../../services/service';
import CircularProgress from "@material-ui/core/CircularProgress";
import { withUserContext } from '../../contexts/UserContext';
import exportFromJSON from 'export-from-json'; 

export default withUserContext(class UserManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            tabListData: [],
            searchTerm: null,
            listBackUp: [],
            list: [],
            page: 0,
            rowsPerPage: 10,
            ismodalOpen: false,
            isModal: false,
            titleGroup: "",
            list: [],
            newUserList: {},
            titleUser: "",
            userTitle: "",
            id: "",
            isFetchingGroupDetails: false,
            isFetchingUserDetails: false,
            currentList: [],
            config: JSON.parse(JSON.stringify({ ...configObjectAllUsers })),
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            list: [],
            list1: [],
            mode: null,
            newUserModal: false
        }
    }

    componentDidMount() {
        this.getUserDetails();
        this.getDropDownValues(this.state.config, null);
    }

    getUserDetails = () => {
        this.setState({ isFetchingUserDetails: true });
        scripterService.getData(Constants.scripterServiceBaseUrl + '/usersAll', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({
                    "name": item.user_name !== null ? item.user_name : "" ,
                    "email": item?.account_email ?  item?.account_email?.length > 30 ? item.account_email.substring(0,30)+'...' : item.account_email !== null ? item.account_email : "" : "",
                    "phone": item.phone !== null ? item.phone : "",
                    "department": item.department !== null ? item.department : "",
                    "group": item.group_name !== null ? item.group_name : "",
                    "status": item.is_active === 1 ? "Active" : "Inactive",
                    "user_id": item.user_id,
                    "title": item.title !== null ? item.title : "",
                    "studio": item.division_name !== null ? item.division_name : ""
                }));
                this.setState({ list1: formattedList, list: handlePaginationList(this.state.page, this.state.rowsPerPage, formattedList), currentList: formattedList, listBackUp: formattedList, isFetchingUserDetails: false });
            },
                (err) => {
                    this.setState({ isFetchingUserDetails: false });
                    console.log("Error in fetching Agency Details:", err)
                })
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })

    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                list: handlePaginationList(this.state.page, this.state.rowsPerPage,
                    tableSortList("Name", this.getSortNode(colName), this.state.list1, this.state.isSortByAsc))
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Name": return "name";
            case "Group": return "group";
            case "Studio": return "studio";
            case "Department": return "department";
            case "Title": return "title";
            case "Email": return "email";
            case "Status": return "status";
        }
    }



    a11yProps = (index) => {
        return {
            id: `horizontal-tab-${index}`,
            'aria-controls': `horizontal-tabpanel-${index}`
        };
    }

    handleSearch = (value) => {
        this.setState({
            searchTerm: value
        });
        switch (this.state.tabValue) {
            case 0:
                if (value) {
                    let filteredList = this.state.listBackUp?.filter(item => item.name?.toLowerCase().includes(value.toLowerCase()));
                    this.setState({ list: handlePaginationList(0, this.state.rowsPerPage, filteredList), currentList: filteredList });
                } else {
                    this.setState({ list: handlePaginationList(0, this.state.rowsPerPage, this.state.listBackUp), currentList: this.state.listBackUp });
                }
                break;

            case 1:
                if (value) {
                    let filteredList = this.state.listBackUp?.filter(item => item.groupname?.toLowerCase().includes(value.toLowerCase()));
                    this.setState({ list: handlePaginationList(0, this.state.rowsPerPage, filteredList), currentList: filteredList });
                } else {
                    this.setState({ list: handlePaginationList(0, this.state.rowsPerPage, this.state.listBackUp), currentList: this.state.listBackUp });
                }
                break;
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            list: handlePaginationList(newPage, this.state.rowsPerPage, this.state.currentList)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10), page: 0,
            list: handlePaginationList(0, parseInt(event.target.value, 10), this.state.currentList)
        });
    }

    applyFilterUsersGridList = (filterValues, filtersNone) => {
        var finalResult = applyFilterTable(this.state.config, this.state?.listBackUp, filterValues, filtersNone);
        this.setState({
            currentList: finalResult, page: 0,
            list1: finalResult,
            list: handlePaginationList(0, this.state.rowsPerPage, finalResult)
        });
    }

    getDropDownValues = (config, v) => {
        config?.headings?.map(data => {
            if (data.inputType === "searchselect") {
                if (data.tableName != 'none') {
                    scripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=' + data.tableName, null)
                        .then(response => {
                            let formattedList = response.data?.map(item => ({ value: item.id, text: item.name, is_active: item.is_active }));
                            this.setState({ [data.selectOptions]: formattedList });
                        },
                            (err) => {
                                console.log("Error in fetching drop-down Details:", err)
                            })
                }

            }
            else if (data.inputType === "dropdown") {
                if (data.tableName == "none") {
                    this.setState({ [data.selectOptions]: ActiveOrInActive });
                }
            }
        })
    }

    handleUserSelection = (item, node) => {
        if (node == 'name') {
            this.setState({ titleUser: "Edit User", id: item?.user_id, ismodalOpen: true })
        }
    }

    handleOpenUser = (id) => {
        this.setState({
            ismodalOpen: true,
            id: id === undefined ? "" : id,
            titleUser: id === undefined ? "New User" : "Edit User"
        });
    }

    handleUserRoleAudit = () => {
        let data = this.state.list1
        exportFromJSON({ data, fileName: 'UserRoleAudit', exportType: exportFromJSON.types.xls})
    }

    render() {
        let isLoading = this.state.isFetchingUserDetails || this.state.isFetchingGroupDetails;
        return (
            <>
                <div>
                    <div className="User-Management">
                        <div className="tabs">
                            <div>
                                <Tab
                                    orientation="horizontal"
                                    variant="standard"
                                    indicatorColor="innherit"
                                    label="User Role Management"
                                >
                                </Tab>
                            </div>

                            <div className="d-flex flex-row headerButtons" >
                                <div>
                                    <BasicButton variant="contained"
                                        text="User Role Audit"
                                        type="inline"
                                        onClick={this.handleUserRoleAudit}
                                        icon={"file-excel"}
                                    />
                                </div>
                                <div>
                                    <BasicButton
                                        variant="contained"
                                        icon="user-plus"
                                        text="New User"
                                        onClick={() => this.handleOpenUser()} />
                                </div>
                            </div>
                        </div>
                        <MDBContainer fluid className="filters">

                        </MDBContainer>
                        <div>
                            {this.state.ismodalOpen ?
                                <CreateOrEditUserModal
                                    open={this.state.ismodalOpen}
                                    title={this.state.titleUser}
                                    primaryButtonText={this.state.id ? "Save" : "Add User"}
                                    secondaryButtonText={"Cancel"}
                                    AddButtonText={"Add"}
                                    onConfirm={() => alert("Button Clicked")}
                                    handleClose={(e) => this.setState({ ismodalOpen: false })}
                                    id={this.state.id ? this.state.id : ""}
                                    handleRefresh={this.getUserDetails}
                                    viewFunction={this.handleOpenUser}
                                />
                                : null}
                        </div>
                        <div>
                            {isLoading ? (
                                <div className="ContentLoader">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div id="UserManagementTable">
                                    <TableComponent
                                        list={this.state.list ? this.state.list : ""}
                                        config={this.state.config}
                                        options={this.state}
                                        applyFilter={this.applyFilterUsersGridList.bind(this)}
                                        isLoading={false}
                                        sortCallback={this.handleTableColumnClick}
                                        editCallback={this.handleOpenUser}
                                        tabValue={this.state.tabValue}
                                        hyperLinkNavigationCallback={(item, node) => this.handleUserSelection(item, node)}
                                    />
                                    <TablePagination
                                        component="div"
                                        count={this.state.currentList ? this.state.currentList?.length : 0}
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        page={this.state.page}
                                        onChangePage={this.handleChangePage}
                                        rowsPerPage={this.state.rowsPerPage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* :
                    <div>You do not have permissions to manage users/groups</div>
                } */}
            </>
        )
    }
}
);