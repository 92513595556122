import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import { validateAlpVarCharNumOnlyWithSpace } from '../../../../Common/Helper';
import BasicTextArea from '../../../SharedComponents/BasicTextArea/BasicTextArea';
import messages from '../../../../Common/Messages.json';
import sizeLimit from '../../../../Common/SizeLimits.json';
import { submissionWorkflowMockData } from './Config';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';
import './Submissions.scss';

class ReviseCoverage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reviseCoverageDetails: submissionWorkflowMockData,
            postInitiated: false,
            isPosting: false
        }
    }

    handleGeneralEdit = (field, value) => {
        let newValue = value;
        this.setState(prevState => ({
            reviseCoverageDetails: {
                ...prevState.reviseCoverageDetails,
                [field]: newValue
            },
            postInitiated: false
        }))
    }

    isValidErrorFlags = () => {
        let canSubmit = this.state.reviseCoverageDetails?.comments ? this.state.reviseCoverageDetails?.comments?.length <= sizeLimit.limitchar500 : true;
        return canSubmit;
    }

    handleReviseSubmit = () => {
        this.setState({ postInitiated: true })
        if (this.isValidErrorFlags()) {
            this.postReviseCoverage()
        } else {
            console.log('Submission Failed');
        }
    }

    postReviseCoverage = () => {
        this.setState({ isPosting: true })
        let postJson = this.state.reviseCoverageDetails;
        postJson.submission_id = this.props?.submissionId;
        postJson.submission_coverage_id = this.props?.coverageId;
        postJson.action_label = "REVISE COVERAGE";
        postJson.created_at = new Date().toLocaleString('en-US', { hour12: false});
        console.log('postJson', postJson);
        // POST API call
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/submissionCoverageWorkflow', postJson,
        this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
        .then(response => {
            if (response.data.error) {
                this.setState({ isPosting: false })
                this.props.setPostFlag(false);
                this.props.fieldChanged(true, "fail");
            } else {
                this.setState({ postInitiated: false, isPosting: false });
                this.props.setPostFlag(false);
                this.props.fieldChanged(true, "success", "submission", "revise_coverage");
            }
        },
            (err) => {
                console.log("Error in posting revise submission:", err)
                this.setState({ postInitiated: false, isPosting: false });
                this.props.setPostFlag(false);
                this.props.fieldChanged(true, "fail");
            })
    }

    render() {
        return (
            <MDBContainer fluid className="revise-coverage-container">
                <MDBRow className="revise-text">
                    <BasicLabel text={`This coverage will be sent back to ${this.props?.analyst} for revision.`} />
                </MDBRow>
                <MDBRow>
                    <MDBCol md={12}>
                        <BasicTextArea
                            value={this.state.reviseCoverageDetails?.comments || null}
                            label={"Comments"}
                            className={"textArea"}
                            rows={3}
                            limit={sizeLimit.limitchar500}
                            limitWarning={messages.exceed500CharacterWarning || ""}
                            onChange={(e) => validateAlpVarCharNumOnlyWithSpace(e.target.value) ?
                                this.handleGeneralEdit('comments', e.target.value) : null}
                            typedCharCount={this.state.reviseCoverageDetails?.comments?.length || 0}
                            showCharCount={true}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mt-1">
                    <MDBCol md={7}></MDBCol>
                    <MDBCol md={5}>
                        <MDBRow className='mt5'>
                            <MDBCol md={4} className="coverage-revise-cancel">
                                <BasicButton
                                    variant="outlined"
                                    text={"Cancel"}
                                    onClick={this.props?.handleClose}

                                />
                            </MDBCol>
                            <MDBCol md={8} className="coverage-revise-btn">
                                <BasicButton
                                    icon="share"
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Return for Revision"}
                                    disabled={this.state.reviseCoverageDetails?.comments ? false : true}
                                    onClick={this.handleReviseSubmit}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}

export default withUserContext(ReviseCoverage);