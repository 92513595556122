import React, { Component } from 'react'
import './WeekendReadDetails.scss'
import { MDBCol, MDBRow } from "mdbreact"
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import BasicCheckbox from "../../../SharedComponents/BasicCheckbox/BasicCheckbox";
import dompurify from 'dompurify';
export default class WeekendReadDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    getSubmittedByNames = (submittedByList) => {
        let submittedByValues = [];
        submittedByList?.map(item => {
            let submittedByName = item?.talent_id ? item?.talent_name :
                item?.talent_group_id ? item?.talent_group_name :
                    item?.representative_id ? item?.representative_name : null;
            let submittedByCompanyLabel = submittedByName ?
                (submittedByName + (item?.loanout_company ? (', ' + item?.loanout_company) : '')) : null;
            if (submittedByCompanyLabel) {
                submittedByValues?.push(submittedByCompanyLabel)
            }
        })
        return submittedByValues?.join('\n');
    }

    render() {
        const sanitizer = dompurify.sanitize;
        return (
            // <div className='main-container'>
            <MDBCard>
                <MDBCardBody>
                    <MDBCardText>
                        <MDBRow>
                            <MDBCol md={4}>
                                <BasicLabel text={"WER Date"} />
                                <p className={"infoText"}>{this.props?.weekendReadDetails?.weekend_read_date || "-"}</p>
                            </MDBCol>
                            <MDBCol md={4}>
                                <BasicLabel text={"Assign To"} />
                                <p className={"infoText"}>{this.props?.assignedTo || "-"}</p>
                            </MDBCol>
                            <MDBCol md={4}>
                                <BasicLabel text={`Execs Who've Read`} />
                                <p className={"infoText"}>{this.props?.execRead || "-"}</p> 

                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={4}>
                                <BasicLabel text={"Per/Advocate"} />
                                <p className={"infoText"}>{this.props?.advocate || "-"}</p>
                            </MDBCol>
                            <MDBCol md={4}>
                                <BasicLabel text={"Execs/Group Also Reading"} />
                                <p className={"infoText"}>{this.props?.execReading || "-"}</p>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={4}>
                                <MDBRow>
                                    <MDBCol md={12}>
                                        <BasicLabel text={"Submitted/Produced By"} />
                                        <p className={"infoText white-space-pre-line"}>{this.getSubmittedByNames(this.props?.weekendReadDetails?.submitted_by) || "-"}</p>
                                    </MDBCol>
                                    <MDBCol md={12}>
                                        <BasicLabel text={"Attached Coverages"} />
                                        {this.props?.attachedCoverage?.map(item => (
                                            <p
                                                className={`${item?.attached_coverage === 'Can not view' ? 'disableCoverageText' : ''} infoText`}
                                            >
                                                {item?.attached_coverage || "-"}
                                            </p>
                                        ))}
                                        {/* <p className="infoText white-space-pre-line">{this.props?.attachedCoverage || "-"}</p> */}
                                    </MDBCol>

                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={8}>
                                <MDBRow>
                                    <MDBCol md={3}>
                                        <BasicCheckbox
                                            id="production"
                                            label={"Production"}
                                            checked={this.props?.weekendReadDetails?.is_production === 1 ? true : false}
                                            disabled={true}
                                        />
                                        <BasicCheckbox
                                            id="packaging"
                                            label={"Packaging"}
                                            checked={this.props?.weekendReadDetails?.is_packaging === 1 ? true : false}
                                            disabled={true}
                                        />
                                        <BasicCheckbox
                                            id="development"
                                            label={"Development"}
                                            checked={this.props?.weekendReadDetails?.is_developement === 1 ? true : false}
                                            disabled={true}
                                        />
                                        <BasicCheckbox
                                            id="priorDevelopment"
                                            label={"Prior Development"}
                                            checked={this.props?.weekendReadDetails?.is_prior_developement === 1 ? true : false}
                                            disabled={true}
                                        />
                                    </MDBCol>
                                    <MDBCol md={3}>
                                        <BasicCheckbox
                                            id="talent"
                                            label={"Talent"}
                                            checked={this.props?.weekendReadDetails?.is_talent === 1 ? true : false}
                                            disabled={true}
                                        />
                                        <BasicCheckbox
                                            id="discussion"
                                            label={"Discussion"}
                                            checked={this.props?.weekendReadDetails?.is_discussion === 1 ? true : false}
                                            disabled={true}
                                        />

                                    </MDBCol>
                                    <MDBCol md={3}>
                                        <BasicCheckbox
                                            id="coverageTbd"
                                            label={"Coverage TBD"}
                                            checked={this.props?.weekendReadDetails?.is_coverage_tbd === 1 ? true : false}
                                            disabled={true}
                                        />
                                        <BasicCheckbox
                                            id="oldNewCov"
                                            label={"Old Cov. Attached/New Cov. TBD"}
                                            checked={this.props?.weekendReadDetails?.is_cov_old_new === 1 ? true : false}
                                            disabled={true}
                                        />
                                        <BasicCheckbox
                                            id="coverageOnly"
                                            label={"Coverage Only"}
                                            checked={this.props?.weekendReadDetails?.is_coverage_only === 1 ? true : false}
                                            disabled={true}
                                        />
                                        <BasicCheckbox
                                            id="noMaterial"
                                            label={"No Material"}
                                            checked={this.props?.weekendReadDetails?.is_no_material === 1 ? true : false}
                                            disabled={true}
                                        />
                                    </MDBCol>
                                    <MDBCol md={3}>
                                        <BasicCheckbox
                                            id="seperatePage"
                                            label={"Separate Page"}
                                            checked={this.props?.weekendReadDetails?.is_seperate_page === 1 ? true : false}
                                            disabled={true}
                                        />
                                        <BasicCheckbox
                                            id="memo"
                                            label={"Memo Attached"}
                                            checked={this.props?.weekendReadDetails?.is_memo_attached === 1 ? true : false}
                                            disabled={true}
                                        />
                                        <BasicCheckbox
                                            id="list"
                                            label={"List"}
                                            checked={this.props?.weekendReadDetails?.is_list_attached === 1 ? true : false}
                                            disabled={true}
                                        />
                                        <BasicCheckbox
                                            id="distributed"
                                            label={"Already Distributed"}
                                            checked={this.props?.weekendReadDetails?.is_already_distributed || false}
                                            disabled={true}
                                        />
                                        <BasicCheckbox
                                            id="synopsis"
                                            label={"Synopsis"}
                                            checked={this.props?.weekendReadDetails?.is_synopsis === 1 ? true : false}
                                            disabled={true}
                                        />
                                        <BasicCheckbox
                                            id="securityDisclaimer"
                                            label={"Security Disclaimer"}
                                            checked={this.props?.weekendReadDetails?.is_security_disclaimer === 1 ? true : false}
                                            disabled={true}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>

                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={4}>
                                <BasicLabel text={"Additional Material"} />
                                <p className={"infoText"}>{this.props?.weekendReadDetails?.additional_material || "-"}</p>
                            </MDBCol>
                            <MDBCol md={8} className="rich-text-content">
                                <BasicLabel text={"Notes"} />
                                <p className={"infoText"} dangerouslySetInnerHTML={{ __html: sanitizer(this.props?.weekendReadDetails?.notes ? this.props?.weekendReadDetails?.notes : "-") }}></p>
                            </MDBCol>

                        </MDBRow>
                    </MDBCardText>
                </MDBCardBody>
            </MDBCard>
        )
    }
}


