import React, { Component } from 'react';
import { MDBContainer, MDBRow } from "mdbreact";
import { talentSubmissionsList } from './MockData';
import TableComponent from '../../SharedComponents/Table';
import MessageModal from '../../SharedComponents/MessageModal';
import TablePagination from '@material-ui/core/TablePagination';
import { talentSubmissionsConfig } from './Config';
import { getFormattedDate } from "../../../Common/Helper";
import { withUserContext } from "../../../contexts/UserContext"
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';
import ScripterService from '../../../services/service';
import * as Constants from '../../../constants/constants';

class TalentSubmissions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 10,
            page: 0,
            renderList: [],
            submissionList: [],
            isLoading: false,
            mode: "new",
            rowsPerPage: 10,
            page: 0,
            config: talentSubmissionsConfig,
            isFetchingSubmissionList: false,
            isSortByAsc: true,
            sortCount: 0,
            sortBy: "Submission",
            getSortNode: this.getSortNode.bind(this),
            showMessageModal: false,
            showError: false,
            unsavedMessage: "Are you sure you want to discard your changes?"
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.mode === "edit" &&
            (this.props.talentId != nextProps.talentId || this.props?.talentGroupId != nextProps.talentGroupId)
        ) {
            this.getTalentSubmissionsList();
        }
    }

    componentDidMount() {
        if (this.props.mode === "new") {
            this.setState({ submissions: [] });
        } else if (this.props.mode === "edit") {
            this.getTalentSubmissionsList()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.talentId != this.props?.talentId || prevProps?.talentGroupId != this.props?.talentGroupId) {
            this.getTalentSubmissionsList()
        }
    }

    getTalentSubmissionsList() {
        this.setState({ isFetchingSubmissionList: true });
        let url;
        if (this.props?.talentId) {
            url = `/talent?searchKey=id&searchString=${this.props.talentId}`
        } else {
            url = `/talent?searchKey=id&searchString=${this.props.talentGroupId}&writerTeam=1`
        }
        ScripterService.getData(Constants.scripterServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, null)
            .then(response => {
                console.log("subresponse", response.data)
                if (response.data.length > 0) {
                    let formattedList = response.data[0]?.submission_list.map(obj => {
                        return {
                            "submission": obj?.submission_title,
                            "submission_author": obj?.submission_author,
                            "submission_format": obj?.submission_format,
                            "submission_status": obj?.submission_status,
                            "submission_id": obj?.submission_id
                        }

                    });
                    let list = tableSortList("Name", this.getSortNode(this.state.sortBy), formattedList, this.state.isSortByAsc)
                    this.setState({ submissionList: list, isFetchingSubmissionList: false, submissions: list });
                } else {
                    this.setState({ isFetchingSubmissionList: false })
                }
            },
                (err) => {
                    this.setState({ isFetchingSubmissionList: false });
                    console.log("Error in fetching Project List:", err)
                })
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage, submissionList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.submissions) });
    }

    navigationCallback = (obj, node) => {
        console.log("projectobjnode", obj, node)
        if (node.includes("submission")) {
            if (this.props.talentUpdated == true) {
                this.setState({ showMessageModal: true, showError: true, submissionId: obj.submission_id });
            } else {
                this.onHandleCloseTalentModal(obj);
            }
        }
    }

    onHandleCloseTalentModal = (obj) => {
        let submissionId = obj?.submission_id || this.state.submissionId;
        this.props.navFuns.handleHideTalentPopup();
        window.open(`/submissionDetails/${submissionId}`)
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Submission": return "submission";
            case "Author(s)": return "submission_author";
            case "Format": return "submission_format";
            case "Status": return "submission_status";
        }
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                renderList: tableSortList("Name", this.getSortNode(colName), this.state.submissionList, this.state.isSortByAsc)
            });
        })
    }

    render() {
        return (
            <MDBContainer fluid className="TalentSubmissions">
                <MDBRow className='g-0'>
                    <TableComponent
                        list={this.state.submissionList || []}
                        config={this.state.config}
                        isLoading={this.state.isFetchingSubmissionList || false}
                        hyperLinkNavigationCallback={this.navigationCallback}
                        sortCallback={this.handleTableColumnClick}
                    />
                </MDBRow>
                <MDBRow className="Pagination">
                    <TablePagination
                        component="div"
                        count={this.state.submissions ? this.state.submissions?.length : 0}
                        rowsPerPageOptions={[]}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                    />
                </MDBRow>
                {
                    this.state.showMessageModal &&
                    <MessageModal
                        open={this.state.showError || false}
                        title={"Unsaved Changes"}
                        message={this.state.unsavedMessage}
                        hideCancel={false}
                        primaryButtonText={"OK"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={this.onHandleCloseTalentModal}
                        handleClose={(e) => this.setState({ showError: false })} />
                }
            </MDBContainer>
        );
    }
}

export default withUserContext(TalentSubmissions);