import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicCheckbox from '../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import SearchSelectField from '../../SharedComponents/SearchSelectField';
import MessageModal from '../../SharedComponents/MessageModal';
import { withUserContext } from '../../../contexts/UserContext';
import ScripterService from '../../../services/service';
import * as Constants from '../../../constants/constants';

class RepresentationDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            repsOfAgency: [],
            unsavedMessage: "Are you sure you want to discard your changes?"
        }
    }

    onSelectChange = (field, obj) => {
        if (field === "agency_id") {
            this.props.handleRepListChange(obj, this.props.rep, "agency");
            this.getRepsOfAgency(obj?.value || "");
        } else if (field === "representative_id") {
            this.props.handleRepListChange(obj, this.props.rep, "representative");
        } else if (field === "is_primary") {
            this.props.handleListEdit(field, obj || "", this.props.rep, 'representation_list');
        } else {
            this.props.handleListEdit(field, obj?.value || "", this.props.rep, 'representation_list');
        }
    }

    getRepsOfAgency = (agencyId) => {
        if (agencyId) {
            ScripterService.getData(Constants.scripterServiceBaseUrl + `/agencyRepresentatives?agencyId=${agencyId}`, this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    let newList = response.data?.map(item => {
                        item.text = item.representative_name;
                        item.value = item.representative_id;
                        return item;
                    });
                    this.setState({ repsOfAgency: newList })
                },
                    (err) => {
                        this.setState({ repsOfAgency: [] });
                        console.log("Error in fetching repsOfAgency:", err)
                    })
        } else {
            this.setState({ repsOfAgency: [] });
        }
    }

    navToAgency = () => {
        if (this.props?.talentUpdated) {
            this.setState({ showMessageModalOnAgencyNav: true, showError: true });
        } else {
           this.props.navFuns.handleHideTalentPopup();
            this.props.navFuns.handleShowAgencyPopup(this.props.rep.agency_id);
        }
        
    }

    navToRep = () => {
        if (this.props?.talentUpdated) {
            this.setState({ showMessageModalOnRepNav: true, showError: true });
        } else {
            this.props.navFuns.handleHideTalentPopup();
            this.props.navFuns.handleShowRepresentativePopup(this.props.rep.representative_id);
        }
    }

    render() {
        const { rep } = this.props;
        return (
            <div>
                <MDBContainer className="TalentRepresentationDetails RepContainer">
                    <MDBRow className="mt-2">
                        <MDBCol md={10} className="repHeading">
                            <BasicLabel className="repText"
                                text={"Representative"}
                            />
                            <div className="SelectedFields">
                                <SearchSelectField
                                    id={"agency-select"}
                                    className="TalentAgencySelect"
                                    width={"320px"}
                                    disabled={rep.talent_representative_agency_mtrx_id ? true : false}
                                    value={{ "value": rep.agency_id, "text": rep.agency_name } || {}}
                                    options={this.props.agencies}
                                    placeholder={"- Search or Select Company -"}
                                    onChange={(e, value) => this.onSelectChange('agency_id', value)}
                                />
                                <SearchSelectField
                                    id={"rep-select"}
                                    className="TalentRepSelect"
                                    width={"320px"}
                                    disabled={rep.talent_representative_agency_mtrx_id ? true : false}
                                    value={{ "value": rep.representative_id, "text": rep.representative_name } || {}}
                                    options={this.state.repsOfAgency || []}
                                    placeholder={"- Search or Select Representative -"}
                                    onChange={(e, value) => this.onSelectChange('representative_id', value)}
                                />
                                <BasicLabel />
                                <BasicCheckbox
                                    label="primary"
                                    checked={rep.is_primary}
                                    onChange={(e) => this.onSelectChange('is_primary', e.target.checked)}
                                    align="start"
                                    id={"email" + this.props.keyName}
                                />
                                {
                                    !rep.talent_representative_agency_mtrx_id ? (
                                        <MDBCol md={1} className="textRemove"
                                            onClick={(e) => this.props.removeCallback('representation_list', rep)}>
                                            <BasicLabel />
                                            <BasicLabel
                                                text={"Remove"} />
                                        </MDBCol>
                                    ) : null
                                }
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-2">
                        <MDBCol md={2}>
                            <BasicButton
                                type="inline"
                                variant="outlined"
                                text="View Company"
                                onClick={() => this.navToAgency()}

                            />
                            <BasicButton
                                type="inline"
                                variant="outlined"
                                text="View Rep"
                                onClick={() => this.navToRep()}
                            />
                        </MDBCol>
                        <MDBCol md={3}>
                            <BasicLabel
                                text={"Company Contact"} />
                            <p className="talentRep">
                                {rep.phone || ""} <br />
                                {rep.agency_email || ""}
                            </p>
                        </MDBCol>
                        <MDBCol md={1}>
                            <BasicLabel
                                text={"Rep.Type"} />
                            <p className="talentRep">{rep.representative_type || ""}</p>
                        </MDBCol>
                        <MDBCol md={3}>
                            <BasicLabel
                                text={"Rep Contact"} />
                            <p className="talentRep">
                                {rep.representative_phone || ""}<br />
                                {rep.representative_email || ""}
                            </p>
                        </MDBCol>
                        <MDBCol md={3}>
                            <BasicLabel
                                text={"Rep Address"} />
                            <p className="talentRep">
                                {((rep.address_1 || "") + (rep.address_2 ? " , " : "") + (rep.address_2 || "") + (rep.address_3 ? " , " : "") + (rep.address_3 || "")) || ""} <br />
                                {(rep.city || "") + (rep.state ? " , " : "") + (rep.state || "")} <br />
                                {(rep.country || "") + (rep.zip ? " , " : "") + (rep.zip || "")} <br />
                            </p>
                        </MDBCol>

                    </MDBRow>
                    <hr className="hrLine" />
                    {
                        this.props.checkedMultiPrimary ? (
                            <MDBRow>
                                <div className="error">{"Only one representation can be a primary. Multiple selection is not allowed"}</div>
                            </MDBRow>
                        ) : null
                    }
                    {
                        (this.props.postInitiated && (!rep.agency_id || !rep.representative_id)) ? (
                            <MDBRow>
                                <div className="error">{"Agency and Representative must be selected!"}</div>
                            </MDBRow>
                        ) : null
                    }
                </MDBContainer>
                {
                    this.state.showMessageModalOnAgencyNav &&
                    <MessageModal
                        open={this.state.showError || false}
                        title={"Unsaved Changes"}
                        message={this.state.unsavedMessage}
                        hideCancel={this.state.showFail || this.state.showReportFail || this.state.showSuccess || this.state.showReportNotFound || false}
                        primaryButtonText={"OK"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={() => {
                            this.props?.navFuns?.handleHideTalentPopup();
                            this.props.navFuns.handleShowAgencyPopup(this.props.rep.agency_id);
                        }}
                        handleClose={(e) => this.setState({ showError: false })} 
                    />
                }
                {
                    this.state.showMessageModalOnRepNav &&
                    <MessageModal
                        open={this.state.showError || false}
                        title={"Unsaved Changes"}
                        message={this.state.unsavedMessage}
                        hideCancel={this.state.showFail || this.state.showReportFail || this.state.showSuccess || this.state.showReportNotFound || false}
                        primaryButtonText={"OK"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={() => {
                            this.props?.navFuns?.handleHideTalentPopup();
                            this.props.navFuns.handleShowRepresentativePopup(this.props.rep.representative_id);
                        }}
                        handleClose={(e) => this.setState({ showError: false })} 
                    />
                }
            </div>
        );
    }
}

export default withUserContext(RepresentationDetails);