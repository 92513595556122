import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../SharedComponents/SelectField/SelectField';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import PhoneComponent from '../../../Common/Phone/PhoneComponent';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import AddressComponent from '../../../Common/AddressComponent/AddressComponent';
import EmailComponent from '../../../Common/Email/EmailComponent';
import CircularProgress from "@material-ui/core/CircularProgress";
import { emailConfig, phoneConfig, addressConfig } from './Config';
import { validateCharNumOnlyWithSpace, validatePlusCharacter } from '../../../Common/Helper';
import messages from '../../../Common/Messages.json'
import sizeLimits from '../../../Common/SizeLimits.json';

class AgentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agencyDetails: [],
            navObj: {},
            mode: "new",
            isFetchingAgencyTypes: false,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.agencyDetails !== state.agencyDetails || props.mode !== state.mode || (JSON.stringify(props.navObj) !== JSON.stringify(state.navObj)) && props.navObj) {
            return {
                mode: props.mode,
                navObj: props.navObj || {},
                agencyDetails: props.agencyDetails
            }
        }
        return null
    }

    render() {
        let isLoading = this.props.isFetchingAgencyDetails && this.state.isFetchingAgencyTypes;
        return (
            <div>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBContainer className="AgentDetailsContainer">
                            <MDBRow className='g-0 detailsHeading'>
                                <BasicLabel
                                    type={"subHeading"}
                                    text={"Details"}
                                />
                            </MDBRow>
                            <MDBRow className='g-0'>
                                <MDBCol md={5} className="col-content">
                                    <BasicTextField id="agencyName"
                                        label={"Company Name"}
                                        limit={this.props.limit.agencyNameCharacterLimit}
                                        showMandatory={this.props.postInitiated}
                                        limitWarning={messages.exceed50CharacterWarning || ""}
                                        isMandatory={true}
                                        onChange={(e) => this.props.handleGeneralEdit('agency_name', e.target.value)}
                                        value={this.state.agencyDetails?.agency_name || ""} />
                                    <br />
                                    <SelectField id={"agencyType"} className='MTN1'
                                        label={"Company Type"}
                                        showMandatory={this.props.postInitiated}
                                        options={this.props.agencyTypes || []}
                                        value={this.state.agencyDetails?.agency_type_id || ""}
                                        isMandatory={true}
                                        onChange={(e) => this.props.handleGeneralEdit('agency_type_id', e.target.value)} />
                                </MDBCol>
                                <MDBCol md={7} className="col-content">
                                    <BasicTextArea id={"key4"}
                                        value={this.state.agencyDetails?.agency_notes || ""}
                                        limit={this.props.limit.agencyNotesCharacterLimit}
                                        limitWarning={messages.exceed100CharacterWarning || ""}
                                        label={"Company Notes"}
                                        onChange={(e) => this.props.handleGeneralEdit('agency_notes', e.target.value.length > 0 ? e.target.value : null)} 
                                        typedCharCount={this.state.agencyDetails?.agency_notes?.length || 0}
                                        showCharCount={true}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='g-0 ContactHeading'>
                                <BasicLabel
                                    type={"subHeading"}
                                    text={"Contact"}
                                />
                            </MDBRow>
                            <MDBRow className='g-0'>
                                <MDBCol md={6} className="col-content email-tab">
                                    {
                                        this.state.agencyDetails?.email_list?.map((email, index) => (
                                            <MDBRow>
                                                <EmailComponent
                                                    editCallback={this.props.handleListEdit}
                                                    keyName={index + "-agentEmail"}
                                                    list={email}
                                                    disabled={email.agency_email_id || false}
                                                    limit={this.props.limit.agencyEmailCharacterLimit}
                                                    limitWarning={messages.exceed50CharacterWarning || ""}
                                                    checkedMultiPrimary={(this.state.agencyDetails?.email_list?.length - 1) === index ? this.props.checkedMultiPrimary.email_list : false}
                                                    emailConfig={emailConfig}
                                                    inValidInput={messages.invalidInput || ""}
                                                    typeEndpoint={'lu_company_email_type'}
                                                    addCallback={() => this.props.addAdditionalField('email_list')}
                                                    removeCallback={() => this.props.removeField('email_list', email)}
                                                    showRemove={email.agency_email_id || index === 0 ? false : true}
                                                    showAdd={(this.state.agencyDetails?.email_list?.length - 1) === index
                                                        && this.state.agencyDetails?.email_list[index]?.email_type
                                                        && this.state.agencyDetails?.email_list[index]?.email ? true : false}
                                                    label={index === 0 ? "Email List" : ""} />
                                            </MDBRow>
                                        ))
                                    }
                                </MDBCol>
                                <MDBCol md={6} className="col-content phone-tab">
                                    {
                                        this.state.agencyDetails?.phone_list?.map((phone, index) => (
                                            <MDBRow>
                                                <PhoneComponent label={index === 0 ? "Phone List" : ""}
                                                    editCallback={this.props.handleListEdit}
                                                    keyName={index + "-agentPhone"}
                                                    disabled={phone.agency_phone_id || false}
                                                    typeEndpoint={'lu_company_phone_type'}
                                                    checkedMultiPrimary={(this.state.agencyDetails?.phone_list?.length - 1) === index ? this.props.checkedMultiPrimary.phone_list : false}
                                                    list={phone}
                                                    inValidInput={messages.invalidInput || ""}
                                                    limit={validatePlusCharacter((phone.phone.trim()).charAt(0)) ? sizeLimits.phoneLimitIntl : sizeLimits.phoneLimitUS}
                                                    limitWarning={validatePlusCharacter((phone.phone.trim()).charAt(0)) ? messages.phoneLimitIntl : messages.phoneLimitUs}
                                                    phoneConfig={phoneConfig}
                                                    addCallback={() => this.props.addAdditionalField('phone_list')}
                                                    removeCallback={() => this.props.removeField('phone_list', phone)}
                                                    showAdd={((this.state.agencyDetails?.phone_list?.length - 1) === index) && this.state.agencyDetails?.phone_list[index]?.phone_type
                                                        && this.state.agencyDetails?.phone_list[index]?.phone ? true : false}
                                                    showRemove={phone.agency_phone_id || index === 0 ? false : true}
                                                />
                                            </MDBRow>
                                        ))
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='g-0 Address'>
                                {
                                    this.state.agencyDetails?.address_list?.map((address, index) => (
                                        <MDBRow>
                                            <AddressComponent
                                                editCallback={this.props.handleListEdit}
                                                disabled={address.agency_address_id || false}
                                                typeEndpoint={'lu_company_address_type'}
                                                keyName={index + "-agentAddress"}
                                                list={address}
                                                limit = {this.props.limit.agencyAddressCharacterLimit}
                                                limitWarning = {messages.exceed100CharacterWarning || ""}
                                                addressConfig={addressConfig}
                                                postInitiated={this.props.postInitiated}
                                                checkMandatory={(address?.address_1 !== null || address?.city !== null || address?.state !== null || address?.zip !== null) &&
                                                    !address?.agency_address_id}
                                                checkedMultiPrimary={(this.state.agencyDetails?.address_list?.length - 1) === index ? this.props.checkedMultiPrimary.address_list : false}
                                                showAdd={(this.state.agencyDetails?.address_list.length - 1) === index && this.state.agencyDetails.address_list[index]?.address_type
                                                    && this.state.agencyDetails.address_list[index]?.zip && this.state.agencyDetails?.address_list[index]?.city
                                                    && this.state.agencyDetails.address_list[index]?.address_1
                                                    && (this.state.agencyDetails.address_list[index]?.state_id || this.state.agencyDetails?.address_list[index]?.state) ? true : false}
                                                showRemove={address.agency_address_id || index === 0 ? false : true}
                                                addCallback={() => this.props.addAdditionalField('address_list')}
                                                removeCallback={() => this.props.removeField('address_list', address)} />
                                        </MDBRow>
                                    ))
                                }
                            </MDBRow>
                        </MDBContainer>
                    )}
            </div>
        );
    }
}

export default AgentDetails;