import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import DateField from '../../../../SharedComponents/DateField/DateField';
import BasicButton from '../../../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../../../SharedComponents/BasicLabel/BasicLabel';
import BasicTextArea from '../../../../SharedComponents/BasicTextArea/BasicTextArea';
import SelectField from '../../../../SharedComponents/SelectField/SelectField'
import SearchSelectField from '../../../../SharedComponents/SearchSelectField';
import CircularProgress from "@material-ui/core/CircularProgress";
import messages from '../../../../../Common/Messages.json';
import sizeLimit from '../../../../../Common/SizeLimits.json';
import 'rc-time-picker/assets/index.css';
import { intialAssignCoverage, submissionWorkflowMockData, compareTo } from "./config";
import ScripterService from '../../../../../services/service';
import * as Constants from '../../../../../constants/constants';
import './AssignCoverage.scss';
import { withUserContext } from '../../../../../contexts/UserContext';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from 'axios';
import moment from 'moment';

export default withUserContext(class AssignCoverage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            assignCoverageDetails: this.formatCoverageList(JSON.parse(JSON.stringify({ ...intialAssignCoverage }))),
            selectedCoverageValues: [],
            userGroupName: [],
            userList: [],
            coverageTypes: [],
            postInitiated: false,
            isPosting: false,
            analyst: "",
            isUserListLoading: false,
            isFetchingCoverageTypes: false,
            compareTo: [],
            searchCancelToken: null,
            compareToIndex: 2,
            showCoverageWarningGreaterThanFive: false,
            submissionCompareToNames: this.props?.compareToName
        }
    }

    componentDidMount() {
        this.getCoverageTypes();
        // this.setDefaultData();
        // this.getGroups();
        // this.getEntity('submissionList', 'SUBMISSION', " ")
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState?.assignCoverageDetails?.coverage_type_id != this.state.assignCoverageDetails?.coverage_type_id) {
            this.setDefaultCompareTo();
        }
    }

    setDefaultData = () => {
        this.setState(prevState => ({
            assignCoverageDetails: {
                ...prevState.assignCoverageDetails,
                coverage_type_id: this.props?.coverageTypeId || null,
                due_date: this.props?.coverageDueDate || null,
                coverage_type_name: prevState.coverageTypes?.filter(item => item?.value === this.props?.coverageTypeId)?.[0]?.label || null
            }
        }))
    }

    setDefaultCompareTo = () => {
        if (this.props?.coverageTypeId === this.state.assignCoverageDetails?.coverage_type_id) {
            let submissionCompareTo = this.props?.compareToName;
            let formattedCompareTo = [];
            submissionCompareTo?.map(item => {
                let compareTo = {
                    'compare_to': item.compare_to,
                    'compare_to_id': item.compare_to_id,
                    'is_delete': item.is_delete,
                    'submission_coverage_compare_to_id': null
                }
                formattedCompareTo?.push(compareTo);
            })
            this.setState(prevState => ({
                assignCoverageDetails: {
                    ...prevState.assignCoverageDetails,
                    submission_coverage_compare_to: formattedCompareTo
                }
            }))
        } else {
            this.setState(prevState => ({
                assignCoverageDetails: {
                    ...prevState.assignCoverageDetails,
                    submission_coverage_compare_to: []
                }
            }), () => {
                this.setState({ assignCoverageDetails: this.formatCoverageList(JSON.parse(JSON.stringify({ ...this.state.assignCoverageDetails }))) })
            })
        }
    }

    formatCoverageList = (response) => {
        if (response?.submission_coverage_compare_to?.length === 0) {
            response?.submission_coverage_compare_to.push(JSON.parse(JSON.stringify({ ...compareTo })));
        }
        return response;
    }

    getEntity = (entity, entitytype, searchString) => {
        let loading = 'isUserListLoading';
        this.setState({ [loading]: true });
        if (searchString) {
            ScripterService.getData(Constants.scripterServiceBaseUrl +
                `/entitySearch?entity=${entitytype}&permissionName="Assignable (and view submission)/Submit Coverages"&searchString=${searchString}`,
                this.props.userContext.active_tenant.tenant_id)
                .then(response => {
                    let formattedList = [];
                    if (entitytype === 'USER') {
                        formattedList = response?.data?.map(item => ({ value: item.user_id, text: item.user_name }));
                    }
                    if (entitytype === 'SUBMISSION') {
                        formattedList = response?.data?.map(item => ({ value: item.submission_id, text: item.title }));
                    }
                    this.setState({ [entity]: formattedList, [loading]: false });
                },
                    (err) => {
                        this.setState({ [loading]: false });
                        console.log("Error in fetching entities:", err)
                    })
        }

    }

    getCompareToValues = (entity, entitytype, searchString) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken, isSubmissionLoading: true });
        ScripterService.getDataWithCancel(Constants.scripterServiceBaseUrl + `/entitySearch?entity=${entitytype}&searchString=${searchString}`,
            cancelToken,
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let compareToDetails = response?.data?.map(item => ({ value: item.submission_id, text: item.title + (item.received_date ? ` - Rcvd. ${item.received_date}` : ''), submissionRcvdDate: item.received_date }));
                compareToDetails.sort(function (a, b) {
                    return new Date(b.submissionRcvdDate) - new Date(a.submissionRcvdDate);
                });
                this.setState({ [entity]: compareToDetails, isSubmissionLoading: false });
            },
                (err) => {
                    this.setState({ isSubmissionLoading: false });
                    console.log("Error in fetching entities:", err)
                })
    }

    getGroups = () => {
        let response = ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=group', null)
            .then(response => {
                let filteredAnalystGroups = response.data?.map(item => {
                    if (item?.name === "Story Analyst") {
                        return item?.name
                    }
                }).filter(item => item !== undefined);

                this.setState({
                    userGroupName: '"' + filteredAnalystGroups.join('","') + '"'
                })
            },
                (err) => {
                    console.log("Error in fetching Group Details:", err)
                })
        return response
    }

    getCoverageTypes = () => {
        this.setState({ isFetchingCoverageTypes: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_coverage_types',
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ coverageTypes: formattedList, isFetchingCoverageTypes: false }, () => {
                    this.setDefaultData();
                });
            },
                (err) => {
                    this.setState({ isFetchingCoverageTypes: false });
                    console.log("Error in fetching coverage types:", err)
                })
    }

    validateDate = (selectedDate) => {
        // let dateValid = new Date(selectedDate) >= new Date().setHours(0, 0, 0, 0);
        let dateValid = selectedDate >= this.formatDate(new Date());
        if (dateValid) {
            this.setState({ showDateError: false })
        } else {
            this.setState({ showDateError: true })
        }
        return dateValid;
    }

    formatDate(date) {
        return moment(date).format('YYYY-MM-DD');
    }

    onChangeCallBack = (field, selectedValue, index) => {
        if (field === "analyst") {
            this.setState(prevState => ({
                assignCoverageDetails: {
                    ...prevState.assignCoverageDetails,
                    [field + '_id']: selectedValue?.value || null,
                    [field]: selectedValue?.text || null,
                },
                postInitiated: false
            }))
        } else if (field === "coverage_type") {
            let coverageTypeName = this.state.coverageTypes?.find(type => type.value === selectedValue)?.label;
            this.setState(prevState => ({
                assignCoverageDetails: {
                    ...prevState.assignCoverageDetails,
                    [field + '_id']: selectedValue || null,
                    [field + '_name']: coverageTypeName || null,
                },
                postInitiated: false,
                submissionCompareToNames: (this.props?.submissionCoverageType != coverageTypeName) ? [] :
                    this.props?.compareToName
            }))
        } else if (field === "due_date") {
            this.validateDate(selectedValue)
            this.setState(prevState => ({
                assignCoverageDetails: {
                    ...prevState.assignCoverageDetails,
                    [field]: selectedValue,
                },
                postInitiated: false
            }));
        } else if (field === "submission_coverage_compare_to") {
            let assignCoverageDetails = { ...this.state.assignCoverageDetails };
            assignCoverageDetails["submission_coverage_compare_to"][index].compare_to_id = selectedValue?.value || null,
                assignCoverageDetails["submission_coverage_compare_to"][index].compare_to = selectedValue?.text || null
            this.setState(prevState => ({
                assignCoverageDetails: {
                    ...prevState.assignCoverageDetails,
                    assignCoverageDetails: assignCoverageDetails,
                },
                postInitiated: false
            }))
        } else {
            this.setState(prevState => ({
                assignCoverageDetails: {
                    ...prevState.assignCoverageDetails,
                    [field]: selectedValue,
                },
                postInitiated: false
            }));
        }
    }

    isValidFields = () => {
        let { coverage_type_id, analyst, analyst_id, notes, due_date, submission_coverage_compare_to, coverage_type_name } = this.state.assignCoverageDetails;
        let canSubmit = (analyst_id && coverage_type_id) && (notes ? notes.length <= sizeLimit.limitchar500 : true) &&
            (due_date ? this.validateDate(due_date) : true) && (submission_coverage_compare_to &&
                (coverage_type_name === "Amended Writing Credit Determination" ||
                    coverage_type_name === "Full Project Comparison" || coverage_type_name === "Legal Addendum" ||
                    coverage_type_name === "Legal Amendment" || coverage_type_name === "Second Opinion" ||
                    coverage_type_name === "Second Opinion Project" || coverage_type_name === "Third Opinion" ||
                    coverage_type_name === "Top Sheet Comparison" || coverage_type_name === "Extended Comparison" ||
                    coverage_type_name === "Legal") ? this.validateCompareTo(submission_coverage_compare_to, coverage_type_name) : true);
        return canSubmit;
    }

    validateCompareTo = (submission_coverage_compare_to, coverage_type_name) => {
        let isValid = false;
        switch (coverage_type_name) {
            case "Amended Writing Credit Determination":
            case "Full Project Comparison":
            case "Legal Addendum":
            case "Legal Amendment":
            case "Second Opinion":
            case "Second Opinion Project":
            case "Third Opinion":
            case "Top Sheet Comparison":
                isValid = (submission_coverage_compare_to?.length >= 1 && submission_coverage_compare_to?.some(item => item.compare_to_id != null));
                break;
            case "Extended Comparison":
                // isValid = this.formatCompareToNames(this.state.submissionCompareToNames).length > 1 || (submission_coverage_compare_to?.length >= 1 && submission_coverage_compare_to?.length <= 5 && submission_coverage_compare_to?.some(item => item.compare_to_id != null))
                // if (submission_coverage_compare_to?.length > 5) {
                //     this.setState({
                //         showCoverageWarningGreaterThanFive: isValid === false ? true : false
                //     })
                // }
                // isValid = false;
                // let totalCount;
                // if (this.state.submissionCompareToNames?.length >= 1) {
                //     let submissionCoverageCompareTo = submission_coverage_compare_to?.filter(item => item.compare_to_id != null);
                //     totalCount = this.state.submissionCompareToNames?.length + submissionCoverageCompareTo?.length;
                //     isValid = (totalCount >= 1 && totalCount <= 5)
                // } 
                // else {
                isValid = (submission_coverage_compare_to?.length >= 1 && submission_coverage_compare_to?.length <= 5 && submission_coverage_compare_to?.some(item => item.compare_to_id != null))
                // }
                // if (submission_coverage_compare_to?.length > 5 || totalCount > 5) {
                if (submission_coverage_compare_to?.length > 5) {
                    this.setState({
                        showCoverageWarningGreaterThanFive: isValid === false ? true : false
                    })
                }
                break;
            case "Legal":
                // isValid = false;
                // let totalLegalCount;
                // let submissionCoverageCompareTo = submission_coverage_compare_to?.filter(item => item.compare_to_id != null);
                // if (this.state.submissionCompareToNames?.length > 0) {
                //     totalLegalCount = this.state.submissionCompareToNames?.length + submissionCoverageCompareTo?.length;
                //     isValid = totalLegalCount <= 5
                // } else if (this.state.submissionCompareToNames?.length == 0 && submissionCoverageCompareTo?.length > 0) {
                //     isValid = submission_coverage_compare_to?.length <= 5;
                // } else if (this.state.submissionCompareToNames?.length == 0 && submissionCoverageCompareTo?.length == 0) {
                //     isValid = true;
                // }
                isValid = (submission_coverage_compare_to?.length > 1 ? submission_coverage_compare_to?.length <= 5 && submission_coverage_compare_to?.some(item => item.compare_to_id != null) : true);
                // if (submission_coverage_compare_to?.length > 5 || totalLegalCount > 5) {
                if (submission_coverage_compare_to?.length > 5) {
                    this.setState({
                        showCoverageWarningGreaterThanFive: isValid === false ? true : false
                    })
                }
                break;
            default:
                isValid = true;
        }
        return isValid;
    }



    handleAssignCoverageSubmit = () => {
        this.setState({ postInitiated: true })
        if (this.isValidFields()) {
            this.props.setPostFlag(true);
            this.setState({ isPosting: true })
            let postJson = this.state.assignCoverageDetails;
            postJson.submission_id = this.props?.submissionId;
            ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/submissionCoverages', postJson,
                this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
                .then(response => {
                    if (response.data.error) {
                        this.setState({ isPosting: false })
                        this.props.setPostFlag(false);
                        this.props.fieldChanged(true, "fail");
                    } else {
                        this.setState({ postInitiated: false, isPosting: false });
                        this.props.setPostFlag(false);
                        this.props.fieldChanged(true, "success", 'submission');
                        let coverageId = response.data[0]?.submission_coverage_id;
                        this.postSubmissionWorkflow(coverageId)
                    }
                },
                    (err) => {
                        console.log("Error in posting submission:", err)
                        this.setState({ postInitiated: false, isPosting: false });
                        this.props.setPostFlag(false);
                        this.props.fieldChanged(true, "fail");
                    })
        } else {
            console.log('Submission Failed');
        }
    }

    postSubmissionWorkflow = (coverageId) => {
        let postJson = { ...submissionWorkflowMockData };
        postJson.submission_id = this.props?.submissionId;
        postJson.submission_coverage_id = coverageId;
        postJson.action_label = "ASSIGN COVERAGE";
        postJson.created_at = new Date().toLocaleString('en-US', { hour12: false});

        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/submissionCoverageWorkflow', postJson,
            this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(response => {
                console.log("response", response)
            },
                (err) => {
                    console.log("Error in confirming submission:", err)
                })
    }

    formatCompareToNames = (compareTo) => {
        let formattedCompareToList = [];
        compareTo?.map(item => {
            if (item?.compare_to) {
                formattedCompareToList?.push(item?.compare_to)
            }
        })
        return formattedCompareToList?.join(', ');
    }

    addAdditionalField = () => {
        let assignCoverageDetails = JSON.parse(JSON.stringify({ ...this.state.assignCoverageDetails }));
        let initialCompareToArray = JSON.parse(JSON.stringify({ ...compareTo }));
        let compare_to = [...assignCoverageDetails.submission_coverage_compare_to];
        initialCompareToArray.index = this.state.compareToIndex;
        initialCompareToArray.canRemove = true;
        compare_to.push(initialCompareToArray)
        assignCoverageDetails.submission_coverage_compare_to = compare_to;
        this.setState({ compareToIndex: this.state.compareToIndex + 1, assignCoverageDetails: assignCoverageDetails });
    }

    removeField = (listName, callbackItem, index) => {
        let assignCoverageDetails = this.state.assignCoverageDetails;
        this.setState({
            index: index,
            showCoverageWarningGreaterThanFive: false
        }, () => {
            assignCoverageDetails[listName][this.state.index].is_delete = 1;
            let compare_to_list = assignCoverageDetails[listName].filter(item =>
                (item.submission_coverage_compare_to_id !== callbackItem.submission_coverage_compare_to_id) || (item.index !== callbackItem.index)
            );
            if (compare_to_list.length === 0) {
                assignCoverageDetails[listName] = [{ ...compareTo }];
            } else {
                assignCoverageDetails[listName] = compare_to_list;
            }
            this.setState({
                assignCoverageDetails: assignCoverageDetails,
                callbackItem: null,
            });
        })
    }

    render() {
        let isLoading = this.state.isFetchingCoverageTypes;
        return (
            <>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBContainer fluid className="AssignCoverageContainer">
                            <MDBRow>
                                <SearchSelectField
                                    id={"searchAnalyst"}
                                    label={"Analyst"}
                                    isMandatory={true}
                                    placeholder={'- Search -'}
                                    options={this.state.userList || []}
                                    detail_selected={this.state.assignCoverageDetails?.analyst_id && this.state.assignCoverageDetails?.analyst ? {
                                        value: this.state.assignCoverageDetails?.analyst_id,
                                        text: this.state.assignCoverageDetails?.analyst
                                    } : null}
                                    showMandatory={this.state.postInitiated || false}
                                    valueSelected={(e, selected) => { this.onChangeCallBack('analyst', selected) }}
                                    searchText={(e) => this.getEntity('userList', 'USER', e.target.value)}
                                    multiple={false}
                                    searchSelect={true}
                                />
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={9} className="coverageType">
                                    <SelectField
                                        id={"coverageType"}
                                        label={"Coverage Type"}
                                        defaultMenuText={"-Select-"}
                                        isMandatory={true}
                                        options={this.state.coverageTypes || []}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        showMandatory={this.state.postInitiated || false}
                                        value={this.state.assignCoverageDetails?.coverage_type_id || ""}
                                        onChange={(e, value) => this.onChangeCallBack('coverage_type', e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={3}>
                                    <DateField
                                        id="due date"
                                        label={"Due Date"}
                                        value={this.state.assignCoverageDetails?.due_date || ""}
                                        onChange={(e) => this.onChangeCallBack('due_date', e.target.value)}
                                    />
                                    {this.state.showDateError ?
                                        <p className="error">Due Date can not be before the current day</p>
                                        : null
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol className="compare-to">
                                    {this.state.assignCoverageDetails?.coverage_type_name === "Amended Writing Credit Determination" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Full Project Comparison" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Legal Addendum" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Legal Amendment" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Legal" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Second Opinion" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Second Opinion Project" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Third Opinion" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Top Sheet Comparison" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Extended Comparison" ?
                                        <BasicLabel
                                            text={'Compare To'}
                                            isMandatory={this.state.assignCoverageDetails?.coverage_type_name === "Amended Writing Credit Determination" || this.state.assignCoverageDetails?.coverage_type_name === "Full Project Comparison" || this.state.assignCoverageDetails?.coverage_type_name === "Legal Addendum" || this.state.assignCoverageDetails?.coverage_type_name === "Legal Amendment" || this.state.assignCoverageDetails?.coverage_type_name === "Second Opinion" || this.state.assignCoverageDetails?.coverage_type_name === "Second Opinion Project" || this.state.assignCoverageDetails?.coverage_type_name === "Third Opinion" || this.state.assignCoverageDetails?.coverage_type_name === "Top Sheet Comparison" || this.state.assignCoverageDetails?.coverage_type_name === "Extended Comparison" ? true : false}
                                        /> : null}
                                    {/* <p className={this.props?.headerText === "Confirm Coverage" ? "assignTextcolor" : "basic-text"}>
                                        {this.formatCompareToNames(this.state.submissionCompareToNames) || "-"}
                                    </p> */}
                                    {this.state.assignCoverageDetails?.coverage_type_name === "Amended Writing Credit Determination" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Full Project Comparison" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Legal Addendum" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Legal Amendment" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Legal" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Second Opinion" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Second Opinion Project" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Third Opinion" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Top Sheet Comparison" ||
                                        this.state.assignCoverageDetails?.coverage_type_name === "Extended Comparison" ?
                                        this.state.assignCoverageDetails?.submission_coverage_compare_to?.map((compareTo, index) => (
                                            (compareTo.is_delete === 0 ?
                                                <MDBRow className="compare-to-margin select-field">
                                                    <MDBCol md={10}>
                                                        <SearchSelectField
                                                            mandatoryWarning={this.formatCompareToNames(this.state.submissionCompareToNames)?.length > 0 ? null : messages.mandatoryWarning}
                                                            showMandatory={this.formatCompareToNames(this.state.submissionCompareToNames)?.length > 0 ? false : ((this.state.postInitiated && this.state.assignCoverageDetails?.coverage_type_name !== "Legal") || false)}
                                                            id={"compare-to" + compareTo?.compare_to_id}
                                                            placeholder={'- Search -'}
                                                            options={this.state.compareTo}
                                                            detail_selected={compareTo?.compare_to_id && compareTo?.compare_to ? {
                                                                value: compareTo?.compare_to_id,
                                                                text: compareTo?.compare_to
                                                            } : null}
                                                            width={'100%'}
                                                            valueSelected={(e, value) => this.onChangeCallBack("submission_coverage_compare_to", value, index)}
                                                            searchText={(e) => this.getCompareToValues('compareTo', 'SUBMISSION', e.target.value)}
                                                            multiple={false}
                                                            searchSelect={true}
                                                            loading={this.state.isSubmissionLoading}
                                                        />
                                                    </MDBCol>
                                                    <MDBCol md={2} className="actionButons2 d-inline-block">
                                                        <MDBRow className={`${index === 0 ? 'initialIndexIconsCoverage' : ''}`}>
                                                            <MDBCol md={1} className="removeActionIcon">
                                                                {
                                                                    (compareTo?.compare_to?.length > 0) ?
                                                                        <MDBIcon
                                                                            icon="trash-alt"
                                                                            className="float-right"
                                                                            onClick={() => this.removeField("submission_coverage_compare_to", compareTo, index)}
                                                                        />
                                                                        : null
                                                                }
                                                            </MDBCol>
                                                            <MDBCol
                                                                md={1}
                                                                className={`${this.state?.assignCoverageDetails?.submission_coverage_compare_to?.length === 1 ? 'addIconMargin' : ''}`}
                                                            >
                                                                {
                                                                    (compareTo.compare_to?.length > 0 && index === (this.state?.assignCoverageDetails?.submission_coverage_compare_to.length - 1)) ?
                                                                        <AddCircleIcon
                                                                            onClick={(e) =>
                                                                                this.addAdditionalField()
                                                                            }
                                                                        />
                                                                        : null
                                                                }
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                                : null
                                            )
                                        ))
                                        : null}
                                    {this.state.showCoverageWarningGreaterThanFive ? <span className="errorText">Cannot enter more than 5 compare-to</span> : null}
                                </MDBCol>
                            </MDBRow>
                            {/* <MDBRow>
                                <MDBCol md={4}>
                                    <DateField
                                        id="return-date"
                                        label={"Return Date"}
                                        value={this.state.assignCoverageDetails?.return_date || ""}
                                        onChange={(e) => this.onChangeCallBack('return_date', e.target.value)}
                                    />
                                </MDBCol>
                            </MDBRow> */}
                            <MDBRow className="textArea">
                                <BasicTextArea
                                    label={"Assigned Message"}
                                    id={"textarea-performer"}
                                    rows={1}
                                    limit={sizeLimit.limitchar500}
                                    limitWarning={messages.exceed500CharacterWarning || ""}
                                    value={this.state.assignCoverageDetails?.notes || ""}
                                    onChange={(e) => this.onChangeCallBack('notes', e.target.value)
                                    }
                                    typedCharCount={this.state.assignCoverageDetails?.notes?.length || 0}
                                    showCharCount={true}
                                />
                            </MDBRow>
                            <MDBRow className="p-2">
                                <MDBCol md={8} >
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        type="inline"
                                        variant="outlined"
                                        text={"Cancel"}
                                        onClick={this.props?.handleClose}
                                        disabled={false}

                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        className="btnwidth"
                                        variant="contained"
                                        type="inline"
                                        text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Assign"}
                                        disabled={false}
                                        onClick={this.handleAssignCoverageSubmit}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    )}
            </>
        )
    }
}
);