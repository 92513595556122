import React from 'react';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import { withRouter } from 'react-router';
import { MDBRow, MDBCol, MDBContainer } from 'mdb-react-ui-kit';
import DateField from '../../../SharedComponents/DateField/DateField';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import BasicTextArea from '../../../SharedComponents/BasicTextArea/BasicTextArea';
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import SelectField from '../../../SharedComponents/SelectField/SelectField';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import { CircularProgress } from '@material-ui/core';
import Step from '../Step';
import './NewDeal.scss';
import scripterService from "../../../../services/service";
import * as Constants from '../../../../constants/constants';
import { intialDeals, writerConfig, intialDealConfig, initialSteps, talentPrefixes, talentSuffixes } from '../config';
import { withUserContext } from '../../../../contexts/UserContext';
import sizeLimits from '../../../../Common/SizeLimits.json'
import messages from '../../../../Common/Messages.json'
import moment from "moment";
import MessageModal from '../../../SharedComponents/MessageModal';
import { validateCharctersSpaceOnly, validateCharNumOnly, validateCharNumOnlyWithSpace, getFormattedDate } from '../../../../Common/Helper';
export default withRouter(withUserContext(class NewDeal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            draftOptions: [],
            stepNameOptions: [],
            writerOptions: [],
            projectOptions: [],
            postInitiated: false,
            showLoader: false,
            dealsDetails: intialDealConfig,
            durationSelectData: [],
            currencySelectData: [],
            dealIndex: 2,
            isAmendment: false,
            isManageDealSteps: false,
            headerText: "",
            setProjectDropdown: false,
            isStepDelete: false,
            showRecordDeleteMsg: false,
            projectFlag:false,
            isFetchingDealDetails: false
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.headerText !== nextProps.headerText) {
            return {
                headerText: nextProps.headerText,
                isAmendment: nextProps?.headerText === "Amendment" ? true : (nextProps?.headerText === "Deal Steps" && nextProps?.isDeal === false) ? true : false,
                isManageDealSteps: nextProps?.headerText === "Deal Steps" ? true : false
            };
        }
        return null

    }
    componentDidMount() {
        this.fetchStaticData("lu_duration");
        this.fetchStaticData("lu_currency");
        this.fetchStaticData("lu_step_names");
        //  this.fetchStaticData("lu_division");
        this.getSearchValue('USER_DIVISIONS', '');
        if (this?.props?.mode === 'New') {
            if (this.state?.isAmendment) {
                this.handleChange('deal_memo_id', this.props?.dealId);
            }
            // this.addDealsList();
        }
        if (this.props?.mode === 'Edit') {
            //hit get deal API
            this.getDealEditDetails();
        }
    }
    handleProjectChange = async (field, value) => {
        if (value) {
            this.handleChange(field, value);
            this.handleChange('division_id', null);
        } else {
            await this.handleChange(field, value);
            this.setDefaultDivision();
        }
    }
    handleChange = (field, value) => {
        this.setState(prevState => ({
            dealsDetails: {
                ...prevState.dealsDetails,
                [field]: value || null
            }
        }))
    }
    calculateEndDate(startDate, duration, durationType) {
        try {
            if (startDate && durationType && duration !== null && duration !== "") {

                let endDate = moment(startDate, 'YYYY-MM-DD').add(parseInt(duration), durationType).format("YYYY-MM-DD");
                return endDate;
            }
            else {
                return null
            }
        } catch (err) {
            return null
        }

    }
    handleStepChange = (key, id, value) => {
        if (value == "" || value == null) {
            value = null
        }
        let stepArray = [...this.state?.dealsDetails?.steps]
        let stepUpdated = stepArray.map(obj => {
            if (obj.deal_memo_step_id === id) {
                if (key === "draft_submission_id" && value) {
                    obj.delivery_date = moment(value?.submissionRcvdDate, "MM-DD-YYYY").format("YYYY-MM-DD")
                } else if (key === "draft_submission_id" && !value) {
                    obj.delivery_date = null;
                    obj.reading_end = null;
                }
                obj[key] = value
            }
            if ((key === "delivery_date" || key === "reading_period" || key === "reading" || key === "draft_submission_id") && (obj.delivery_date && obj.reading_period && obj.reading)) {
                let durationStr = [...this?.state?.durationSelectData]?.filter(item => item.value === obj.reading_period)?.[0]
                durationStr = durationStr?.label?.replace("(", "")?.replace(")", "")?.toLowerCase();
                obj.reading_end = this.calculateEndDate(obj.delivery_date, obj.reading, durationStr);
            }
            if ((key === "writing_start" || key === "duration_period" || key === "duration") && (obj.writing_start && obj.duration_period && obj.duration)) {
                let durationStr = [...this?.state?.durationSelectData]?.filter(item => item.value === obj.duration_period)?.[0]
                durationStr = durationStr?.label?.replaceAll("(", "")?.replaceAll(")", "")?.toLowerCase();
                obj.writing_due = this.calculateEndDate(obj.writing_start, obj.duration, durationStr);
            }
            return obj;
        });
        this.handleChange('steps', stepUpdated);
    }
    getFormatTalentGroupName = (talentTeamList) => {
        let talentGroupName = [];
        talentTeamList?.map(item => {
            talentGroupName.push(item?.talent_name)
        })
        return talentGroupName?.join(' & ');
    }
    handleNewTalentDetails = (talentDetails) => {

        let newValue = {
            value: talentDetails?.talent_id || talentDetails?.talent_group_id,
            text: talentDetails?.talent_id ?
                // `${talentDetails?.first_name} ${talentDetails?.last_name|| ""}` : 
                ((talentDetails?.prefix ? talentDetails?.prefix + ' ' : '') + talentDetails?.first_name + ' ' + (talentDetails?.m_i ? talentDetails?.m_i + ' ' : '') + (talentDetails?.last_name ? talentDetails?.last_name : '') + (talentDetails?.suffix ? ' ' + talentDetails?.suffix : '')) :
                this.getFormatTalentGroupName(talentDetails?.talent_group_members_list),
            wwriterTeam: talentDetails?.writer_team,
            roles: talentDetails?.role_list,
            type: "writer"
        }
        console.log(newValue);
        let stepArray = [...this.state?.dealsDetails?.steps];

        let stepUpdated = stepArray.map(obj => {
            if (obj.deal_memo_step_id === this.state?.stepId) {
                let writers = [...obj?.writers]
                obj.writers = writers?.map(item => {
                    if (item?.deal_step_writer_id === this.state?.writerId) {
                        if (!newValue?.writerTeam) {
                            item.writer_id = newValue
                        } else {
                            item.talent_group_id = newValue
                        }
                        item.writerOptions = [...item?.writerOptions, newValue]
                    }
                    return item;
                })
            }
            return obj;
        });
        this.handleChange('steps', stepUpdated);
    }
    handleWriterChange = (key, stepId, writerId, value) => {
        // console.log("value",value);
        if (value && !value?.hasOwnProperty('type')) {
            this.setState({
                key,
                stepId,
                writerId
            })
            let talentDetails = {}
            let prefixDetails = this.getTalentName("prefix", value?.value);
            if (prefixDetails) {
                talentDetails['prefix_id'] = prefixDetails?.value;
                talentDetails['prefix'] = prefixDetails?.label;
            }
            talentDetails['first_name'] = this.getTalentName("first_name", value?.value);
            talentDetails['middle_name'] = this.getTalentName("middle_name", value?.value) || null;
            talentDetails['last_name'] = this.getTalentName("last_name", value?.value) || null;
            let suffixDetails = this.getTalentName("suffix", value?.value);
            if (suffixDetails) {
                talentDetails['suffix_id'] = suffixDetails?.value;
                talentDetails['suffix'] = suffixDetails?.label;
            }
            this.props?.navFuns?.handleShowTalentPopup(null, 0, "new", this.handleNewTalentDetails, talentDetails)
        }
        else {
            let stepArray = [...this.state?.dealsDetails?.steps];
            let stepUpdated = stepArray.map(obj => {
                if (obj.deal_memo_step_id === stepId) {
                    let writers = [...obj?.writers]
                    obj.writers = writers?.map(item => {
                        if (item?.deal_step_writer_id === writerId) {
                            if (!value?.writerTeam) {
                                item.writer_id = value
                            } else {
                                item.talent_group_id = value
                            }
                            //Upon editing ,when a talent_group is cleared off from the placeholder and when a talent is added to it,we are just removing of the value of talent_group_id
                            if(!value){
                                item.talent_group_id = value
                            }
                        }
                        return item;
                    })
                }
                return obj;
            });
            this.handleChange('steps', stepUpdated);
        }
    }

    getTalentName = (field, value) => {
        let prefixes = [...talentPrefixes];
        let suffixes = [...talentSuffixes];
        let n = value?.split(" ");
        if (field === "first_name" || field === "middle_name" || field === "last_name") {
            let prefixExist = prefixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[0]?.toLowerCase()));
            if (prefixExist) {
                n.splice(0, 1);
            }
            let suffixExist = suffixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[n?.length - 1]?.toLowerCase()));
            if (suffixExist) {
                n.pop();
            }
        }
        if (field === "first_name") {
            if (n?.length > 0) {
                return n[0];
            }
        } else if (field === "middle_name") {
            if (n?.length > 2) {
                return n[1];
            }
        } else if (field === "last_name") {
            if (value.split(" ").length > 1) {
                let lastName = [];
                n?.map((item, index) => {
                    if (n?.length <= 2 && index !== 0) {
                        lastName.push(item)
                    } else if (n?.length > 2 && index !== 0 && index !== 1) {
                        lastName.push(item)
                    }
                })
                return lastName.join(" ");
            }
        } else if (field === "prefix") {
            let prefix = prefixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[0]?.toLowerCase()));
            return prefix;
        } else if (field === "suffix") {
            let suffix = suffixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[n?.length - 1]?.toLowerCase()));
            return suffix;
        }
    }

    addDealsList = () => {
        this.setState({ postInitiated: false })
        let dummyId = Date.now();
        let newProps = { deal_memo_step_id: dummyId, draftOptions: [], writers: [], mode: 'new', canRemove: true }
        let initialStep = { ...initialSteps, ...newProps };
        initialStep.currency = 1;
        initialStep.reading = '4';
        initialStep.reading_period = 2;
        let steps = [...this.state?.dealsDetails?.steps];
        if (this?.props?.mode === "New" && this.state?.isAmendment) {
            initialStep.deal_memo_id = this.props?.dealId;
        }
        steps.push(initialStep);
        // this.handleChange('steps', steps);
        this.setState(prevState => ({
            dealsDetails: {
                ...prevState.dealsDetails,
                'steps': steps
            }
        }), () => {
            this.addWorksList(dummyId);
        })
    }
    fetchStaticData(tableName) {
        let type = tableName.split('_')?.[1];
        scripterService.getData(Constants.scripterServiceBaseUrl + `/staticData?staticTable=${tableName}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log(response);
                if (response?.data?.error) {
                    return;
                }
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }))
                if (type === "currency") {
                    this.setState({ currencySelectData: formattedList })
                } else if (type === "duration") {
                    this.setState({ durationSelectData: formattedList })
                } else {
                    let formatData = response.data?.map(item => ({ value: item.id, text: item.name, is_active: item.is_active }))
                    this.setState({ stepNameOptions: formatData })
                }
            }, err => {
                console.log("Error from static data", err);
            });
    }
    addWorksList = (stepId) => {
        let dummyId = Date.now();
        let initialWriter = { ...writerConfig, ...{ deal_memo_step_id: stepId } }
        let newProps = { deal_step_writer_id: dummyId, mode: 'new', writerOptions: [], canRemove: true }
        let stepObj = this.state?.dealsDetails?.steps?.filter(obj => obj.deal_memo_step_id === stepId)?.[0]
        let witersArr = [...stepObj.writers];
        witersArr.push({ ...initialWriter, ...newProps });
        let allSteps = [...this.state?.dealsDetails?.steps];
        let updatedSteps = allSteps?.map(obj => {
            if (obj.deal_memo_step_id === stepId) {
                obj.writers = [...witersArr];
            }
            return obj;
        })
        this.handleChange('steps', updatedSteps);

    }
    deleteWriters(stepId, writerId) {
        let stepArr = [...this.state?.dealsDetails?.steps];
        let stepArrUpdated = stepArr?.map(obj => {
            if (obj.deal_memo_step_id === stepId) {
                let writersArr = [...obj?.writers];
                if (writersArr?.filter(o => !o?.is_delete)?.length !== 1) {
                    obj.writers = writersArr?.map(item => {
                        if (item?.deal_step_writer_id == writerId) {
                            item = { ...item, is_delete: 1 }
                        }
                        return item
                    })
                }
            }
            return obj;
        });
        this.handleChange('steps', stepArrUpdated);
    }
    deleteSteps1 = (id, mode) => {
        if (id) {
            if (mode === 'new' && this.state?.dealsDetails?.steps?.length === 1) {
                return;
            }

            let steps = this.state?.dealsDetails?.steps.filter(obj => obj.deal_memo_step_id != id);
            this.handleChange('steps', steps);
        }
    }
    deleteSteps = (id, mode) => {
        if (id) {
            if (mode === 'new' && this.state?.dealsDetails?.steps?.length === 1) {
                return;
            }
            else {
                let isAlreadyAddedStepDeleted = false;
                let steps = this.state?.dealsDetails?.steps.map(obj => {
                    if (obj.deal_memo_step_id == id) {
                        obj.is_delete = 1;
                        if (mode !== "new") {
                            isAlreadyAddedStepDeleted = true;
                        }
                    }
                    return obj;
                });
                if (isAlreadyAddedStepDeleted) {
                    this.setState({ isStepDelete: true })
                }
                this.handleChange('steps', steps);
            }

        }
    }
    getSearchValue(type, value, stepId, writerId) {
        let url = null;
        if (type === "SUBMISSION") {
            if (this.state.isAmendment) {
                url = `/entitySearch?entity=${type}&projectId=${this.props?.projectId.value ? this.props?.projectId.value : null}&searchString=${value}`
            }
            else {
                url = `/entitySearch?entity=${type}&projectId=${this.state?.dealsDetails?.project_id?.value ? this.state?.dealsDetails?.project_id?.value : null}&searchString=${value}`
            }
        } else {
            url = `/entitySearch?entity=${type}&searchString=${value}`
        }
        scripterService.getData(Constants.scripterServiceBaseUrl + url, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {

                if (type === "PROJECT") {
                    let formattedList = response?.data?.map(item => ({ value: item.project_id, text: item.aka != null ? item.project_title + " + " + item.aka : item.project_title }));
                    this.setState({ projectOptions: formattedList, loading: false });
                    if (this.state?.setProjectDropdown) {
                        this.setState({ setProjectDropdown: false })
                        this.handleChange('project_id', { value: this.state?.dealsDetails?.project_id, text: this.state?.dealsDetails?.project_title })
                    }
                }
                else if (type === "TALENT") {
                    let formattedList = response?.data?.map(item => ({
                        value: item.talent_id,
                        text: item.talent_name,
                        roles: item.roles,
                        writerTeam: item.writer_team,
                        type: "writer"
                    }));
                    formattedList = formattedList?.filter(item => item?.roles?.includes("Writer"));
                    let stepData = [...this.state?.dealsDetails?.steps]
                    let updatedStepData = stepData?.map(obj => {
                        if (obj?.deal_memo_step_id === stepId) {
                            let writersData = obj?.writers?.map(item => {
                                if (item?.deal_step_writer_id === writerId) {
                                    item.writerOptions = [...formattedList];
                                }
                                return item;
                            });
                            obj.writers = [...writersData];
                        }
                        return obj;
                    })
                    this.handleChange('steps', updatedStepData);
                }
                else if (type === "SUBMISSION") {
                    let formattedList = response?.data?.map(item => ({ value: item.submission_id, text: item.title + (item.received_date ? ` - Rcvd. ${item.received_date}` : ''), submissionRcvdDate: item.received_date }));
                    formattedList.sort(function (a, b) {
                        return new Date(b.submissionRcvdDate) - new Date(a.submissionRcvdDate);
                    });
                    let stepData = [...this.state?.dealsDetails?.steps]
                    let updatedStepData = stepData?.map(obj => {
                        if (obj?.deal_memo_step_id === stepId) {
                            obj.draftOptions = [...formattedList];
                        }
                        return obj;
                    })
                    this.handleChange('steps', updatedStepData);
                } else if (type === "USER_DIVISIONS") {
                    let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                    this.setState({ divisions: formattedList }, () => {
                        this.setDefaultDivision();
                    });
                }
            }, err => {
                console.log("Error from entitysearch dealmemo", err);
            })
    }
    setDefaultDivision = () => {
        if (!this.state.dealsDetails?.division_id || !this.state.dealsDetails?.project_id) {
            let findWbPictureDivision = this.state.divisions?.find(item => item.label === 'WB Pictures');
            if (findWbPictureDivision) {
                this.setState(prevState => ({
                    dealsDetails: {
                        ...prevState.dealsDetails,
                        division_id: findWbPictureDivision?.value || null
                    },
                    postInitiated: false
                }))
            } else {
                this.setState(prevState => ({
                    dealsDetails: {
                        ...prevState.dealsDetails,
                        division_id: this.state.divisions?.[0]?.value || null
                    },
                    postInitiated: false
                }))
            }
        }
    }
    handleProjectSearch(e) {
        this.getSearchValue('PROJECT', e.target?.value)
    }
    checkFieldLength = (field, limit) => {
        return field ? field.length <= limit : true
    }
    validateBeforeSubmit(dealsDetails) {
        let isSubmit = false
        if (!this.state?.isAmendment) {
            isSubmit = dealsDetails?.memo_date && dealsDetails?.document_name
                && this.checkFieldLength(dealsDetails?.document_name, sizeLimits?.docNameCharacterLimit)
                && this.checkFieldLength(dealsDetails?.assigned_material, sizeLimits?.largeNameCharLimit)
                && this.checkFieldLength(dealsDetails?.deal_notes, sizeLimits?.largeNotes)
                // && (dealsDetails?.steps?.length > 0 ? dealsDetails?.steps?.filter(o => !o?.is_delete)?.length > 0 : true)
        }
        else {
            isSubmit = dealsDetails?.ammendment_date
                && this.checkFieldLength(dealsDetails?.ammendment_notes, sizeLimits?.largeNotes)
                // && (dealsDetails?.steps?.length > 0 ? dealsDetails?.steps?.filter(o => !o?.is_delete)?.length > 0 : true)
        }
        return isSubmit;
    }
    checkStepValidation(dealsDetails) {
        dealsDetails?.steps?.map(step => {
            step.writers = [...step?.writers]?.filter((tag, index, array) =>
                tag.writer_id ? (array.findIndex(t => t.writer_id == tag.writer_id) == index) :
                    (array.findIndex(t => t.talent_group_id == tag.talent_group_id) == index) && (tag.writer_id || tag.talent_group_id));
            step.isValid = this.validateSteps(step);
        });
        let allValid = dealsDetails?.steps?.filter(item => !item?.isValid)?.length == 0;
        return allValid;
    }
    validateSteps(step) {
        let isWriterValid = step?.writers?.some(writer => ((!writer.writer_id || !writer.talent_group_id) || false))
        let isValid = this.checkFieldLength(step?.step_number, sizeLimits?.usZipCharacterLimit) &&
            (step?.deal_notes ? this.checkFieldLength(step?.deal_notes, sizeLimits.limitChar2000) : true) &&
            step?.step_name_id && isWriterValid;
        return isValid;
    }
    closPopup = () => {
        if (this.state.projectFlag) {
                this.handleSubmit();  
           }
        this.setState({projectFlag : false});
    }
    validateProject(){
        let postData = JSON.parse(JSON.stringify({ ...this.state?.dealsDetails }));
        if(postData.project_id === null && !this.state.isAmendment && postData.deal_memo_id != null ){
            this.setState({projectFlag:true});
        }
        else{
            this.handleSubmit();
        }
    }
    handleSubmit() {
        this.setState({ postInitiated: true, showLoader: true })
        this.props.setPostFlag(true);
        let postData = JSON.parse(JSON.stringify({ ...this.state?.dealsDetails }));
        postData.project_title = postData?.project_id?.text;
        postData.project_id = postData?.project_id?.value;
        if (this.state?.isAmendment) {
            postData.is_deal_ammendment = 1
            postData.is_deal_memo = 0
            postData['division_id'] = null;
        }
        else {
            postData.is_deal_ammendment = 0
            postData.is_deal_memo = 1
        }
        let stepData = [...postData?.steps];
        let updatedStep = stepData?.map(item => {
            item.draft_submission_id = item?.draft_submission_id?.hasOwnProperty('value') ? item?.draft_submission_id?.value : item?.draft_submission_id
            if (this.state?.isManageDealSteps || item?.mode === "new") {
                item.step_name_id = item?.step_name_id?.value;
            }
            if (item?.mode === "new") {
                item.deal_memo_step_id = null;
            }
            let writerUpdated = [...item?.writers];
            writerUpdated?.filter(item => (item?.mode === "new" && !item?.is_delete) || item?.mode != 'new').map(obj => {
                if (obj?.mode === "new" || this.props?.headerText === "Deal Steps") {
                    if (!obj.talent_group_id) {
                        obj.writer_id = obj?.writer_id?.value;
                    } else {
                        obj.talent_group_id = obj?.talent_group_id?.value;
                    }
                }
                if (obj?.mode === "new") {
                    obj.deal_step_writer_id = null;
                    obj.deal_memo_step_id = null;
                }
                delete obj?.writerOptions;
                return obj;
            });
            item.writers = writerUpdated;
            delete item?.draftOptions;
            return item;
        })
        updatedStep = updatedStep?.filter(i => (i.mode === "new" && !i?.is_delete) || i.mode != "new")
        postData.steps = [...updatedStep];
        let dealValid = this.validateBeforeSubmit(postData);
        if (this.state.dealsDetails?.steps?.length > 0) {
            let stepValid = this.checkStepValidation(postData);
            if (!stepValid) {
                this.setState({ showLoader: false })
                return;
            }
        }
        if (!dealValid) {
            this.setState({ showLoader: false })
            return;
        }
        scripterService.postDataParams(Constants.scripterServiceBaseUrl + `/dealMemo`, postData, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log(response);
                if (response.data.error) {
                    if (this.state?.isStepDelete) {
                        this.setState({ showRecordDeleteMsg: true })
                    }
                    this.props.setPostFlag(false);
                    this.props.fieldChanged(true, "fail");
                    this.setState({ showLoader: false })

                } else {
                    this.setState({ postInitiated: false, showLoader: false });
                    this.props.setPostFlag(false);
                    this.props.fieldChanged(true, "success");
                    this.props.toggleRefreshPage('Deal');
                    if (window.location.pathname === "/") {
                        this.props.history.push("/")
                    }
                    // setTimeout(()=>{
                    //     // const {pathname} = this.props.location;                      
                    //     if(!(this.state?.headerText === "Deal" && this.props?.mode === "New")){                            
                    //         this.props.toggleRefreshPage('Deal');
                    //     }
                    // },2000)
                    if (this.props?.mode === "New" && this.props?.headerText === "Deal" && !this.props.showNewDealProject) {
                        let dealUrl = '/dealDetails/' + response.data[0].deal_memo_id;
                        this.props.history.push(dealUrl);
                        // window.open(dealUrl);
                    }
                }
            }, err => {
                console.log("Error from submit deal memo", err);
                this.setState({ postInitiated: false });
                this.props.setPostFlag(false);
                this.props.fieldChanged(true, "fail");
            });
    }
    formatDate(date) {
        return moment(date).format('YYYY-MM-DD');
    }
    getDealEditDetails = () => {
        this.setState({ isFetchingDealDetails: true })
        let id = this.state?.isManageDealSteps ? this.props?.id : this.props?.dealId;
        let queryUrl = '';
        if (this.state?.isAmendment) {
            queryUrl = `dealMemoId=null&dealAmmendmentId=${this.props?.dealMemoId || id}`
        }
        else {
            queryUrl = `dealMemoId=${id}&dealAmmendmentId=null`
        }
        scripterService.getData(Constants.scripterServiceBaseUrl + `/dealMemo?${queryUrl}`, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let data = response.data[0];
                console.log(data);
                if (!this.state?.isManageDealSteps && data.project_title) {
                    this.getSearchValue('PROJECT', data.project_title)
                }
                data.memo_date = this.formatDate(getFormattedDate(data.memo_date, false));
                if (this.state?.isManageDealSteps) {
                    data.project_id = { value: data.project_id, text: data?.project_title }
                    let stepData = [...data.steps]
                    let stepsUpdated = stepData.map(obj => {

                        obj.writing_start = obj.writing_start ? this.formatDate(getFormattedDate(obj.writing_start, false)) : null;
                        obj.delivery_date = obj?.delivery_date ? 
                            this.formatDate(getFormattedDate(obj.delivery_date, false)) : 
                            obj?.draft_submission_id ? moment(obj.draft_submission_received_date, "MM-DD-YYYY").format("YYYY-MM-DD") : null;
                        obj.draft_submission_id = { value: obj?.draft_submission_id, text: obj.submission_title ? (obj.submission_title + ' - Rcvd. ' + obj.draft_submission_received_date) : null }
                        obj.step_name_id = { value: obj?.step_name_id, text: obj?.step_name }
                        if (obj?.delivery_date && obj?.reading_period && obj?.reading_period_name) {
                            // let durationStr = [...this?.state?.durationSelectData]?.filter(a => a.value === obj.reading_period)?.[0]
                            let durationStr = obj?.reading_period_name?.replace("(", "")?.replace(")", "")?.toLowerCase();
                            obj.reading_end = obj.delivery_date ?  this.calculateEndDate(obj.delivery_date, obj.reading, durationStr):null;
                        }
                        if (obj?.writing_start && obj?.duration_period && obj?.duration_period_name) {
                            // let durationStr = [...this?.state?.durationSelectData]?.filter(a => a.value === obj.duration_period)?.[0]
                            let durationStr = obj?.duration_period_name?.replace("(", "")?.replace(")", "")?.toLowerCase();
                            obj.writing_due = this.calculateEndDate(obj.writing_start, obj.duration, durationStr);
                        }
                        let writers=[{deal_memo_step_id: obj.deal_memo_step_id,
                            deal_step_writer_id: null,
                            talent_group_id: null,
                            writer_id:null}]
                        obj.writers = obj.writers.length==0 ? writers:
                         obj.writers?.map(e => {
                            if (!e.talent_group_id) {
                                e.writer_id = { value: e.writer_id, text: e.writer_name }
                            } else {
                                e.talent_group_id = { value: e.talent_group_id, text: e.writer_name }
                            }
                            return e;
                        })
                        return obj;
                    })
                    data.steps = [...stepsUpdated];
                }
                let postData = JSON.parse(JSON.stringify({ ...data }));
                postData.memo_date = moment(postData.memo_date).format("YYYY-MM-DD");
                postData?.steps?.sort(function (a, b) {
                    return parseInt(a.step_number) - parseInt(b.step_number);
                });
                postData.project_title = postData?.project_aka != null ? postData?.project_title + " + " + postData?.project_aka : postData?.project_title
                if (this.state.isAmendment && this.props?.mode === 'Edit') {
                    postData.ammendment_date = this.formatDate(getFormattedDate(postData.ammendment_date, false));
                    // postData.ammendment_date = moment(postData.ammendment_date).format("YYYY-MM-DD");
                }

                this.setState({ dealsDetails: { ...postData }, setProjectDropdown: true, isFetchingDealDetails: false }, () => {
                    if (postData?.steps?.length == 0 && this.state.isManageDealSteps) {
                        this.addDealsList();
                    }
                });

            }, err => {
                this.setState({ isFetchingDealDetails: false })
                console.log("Error from get deal memo", err);
            });
    }

    render() {
        let dealDetails = this.state?.dealsDetails;
        return (
            <>
               {this.state.projectFlag && 
                <MessageModal
                open={this.state.projectFlag || false}
                title={'Confirmation'}
                message={"Are you sure you want to save the deal without project. All the project's drafts will be de-linked from the deal steps?"}
                primaryButtonText={"OK"}
                secondaryButtonText={"Cancel"}
                onConfirm={() => this.closPopup()}
                handleClose={(e) => this.setState({projectFlag:false})} />
               
               }
                {this.state.isFetchingDealDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <div className="EditDealContainer">

                            {(!this.state?.isManageDealSteps && !this.state?.isAmendment) &&
                                <>
                                    <MDBRow className="deal-row-margin">
                                        <MDBCol md={2}>
                                            <DateField
                                                id="memo date"
                                                label={"Memo Date"}
                                                isMandatory={true}
                                                showMandatory={this.state?.postInitiated || false}
                                                value={dealDetails?.memo_date || null}
                                                onChange={(e) => this.handleChange('memo_date', e.target.value)}
                                            />
                                        </MDBCol>
                                        <MDBCol md={5}>
                                            <BasicTextField
                                                label="Document Name"
                                                className={"documentName"}
                                                isMandatory={true}
                                                limit={sizeLimits.docNameCharacterLimit}
                                                showMandatory={this.state?.postInitiated || false}
                                                mandatoryWarning={messages.mandatoryWarning}
                                                limitWarning={messages.exceed1000characterWarning || ""}
                                                // placeholder="[Document Name]"
                                                value={dealDetails?.document_name || null}
                                                onChange={(e) => this.handleChange('document_name', e.target.value)}
                                            >
                                            </BasicTextField>
                                        </MDBCol>
                                        <MDBCol md={5} className="studio-division">
                                            {(!dealDetails?.project_id && this.props?.mode === 'New') ?
                                                <SelectField
                                                    id={"studioDivision"}
                                                    label={"Studio Division"}
                                                    defaultMenuText={"-Select-"}
                                                    isMandatory={false}
                                                    // showAsLabel={(this.props?.mode === "Edit" && dealDetails?.project_id)
                                                    //     ? true : false
                                                    // }
                                                    options={this.state.divisions || []}
                                                    value={dealDetails?.division_id || ""}
                                                    onChange={(e) => this.handleChange("division_id", e.target.value)}
                                                /> : null
                                            }
                                            {this.props?.mode === 'Edit' ?
                                                <SelectField
                                                    id={"studioDivision"}
                                                    label={"Studio Division"}
                                                    defaultMenuText={"-Select-"}
                                                    isMandatory={false}
                                                    showAsLabel={(this.props?.mode === "Edit" && dealDetails?.project_id)
                                                        ? true : false
                                                    }
                                                    options={this.state.divisions || []}
                                                    value={dealDetails?.division_id || ""}
                                                    onChange={(e) => this.handleChange("division_id", e.target.value)}
                                                /> : null
                                            }
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="deal-row-margin">
                                        <MDBCol md={3}>
                                            <BasicLabel text={"Project"} />
                                            <SearchSelectField
                                                className="projectSelect"
                                                id={"project-select"}
                                                placeholder="- Select -"
                                                isMandatory={false}
                                                // value={dealDetails?.project_id}
                                                detail_selected={dealDetails?.project_id || null}
                                                options={this.state?.projectOptions}
                                                width={'100%'}
                                                // valueSelected={(e, value) => this.handleChange('project_id', value)}
                                                valueSelected={(e, value) => this.handleProjectChange('project_id', value)}
                                                searchSelect={true}
                                                searchText={this.handleProjectSearch.bind(this)}
                                            />
                                        </MDBCol>
                                        <MDBCol md={9}>
                                            <BasicTextField
                                                label="Assigned Material"
                                                className={"assignedMaterial"}
                                                isMandatory={false}
                                                limit={sizeLimits.largeNameCharLimit}
                                                limitWarning={messages.exceed300CharacterWarning || ""}
                                                // placeholder="[Assigned Material]"
                                                value={dealDetails?.assigned_material || null}
                                                onChange={(e) => this.handleChange('assigned_material', e.target.value)}
                                            >
                                            </BasicTextField>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <BasicTextArea
                                            label="Deal Notes"
                                            className={"dealNotes"}
                                            isMandatory={false}
                                            limit={sizeLimits.largeNotes}
                                            limitWarning={messages.exceed1000CharacterWarning || ""}
                                            // placeholder="[Deal Notes]"
                                            value={dealDetails?.deal_notes || null}
                                            onChange={(e) => this.handleChange('deal_notes', e.target.value)}
                                            typedCharCount={dealDetails?.deal_notes?.length || 0}
                                            showCharCount={true}>
                                        </BasicTextArea>

                                    </MDBRow>
                                </>
                            }
                            {(!this.state?.isManageDealSteps && this.state?.isAmendment) &&
                                <MDBRow>
                                    <MDBCol md={3}>
                                        <DateField
                                            id="Amendment date"
                                            label={"Amendment Date"}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated || false}
                                            value={dealDetails?.ammendment_date || null}
                                            onChange={(e) => this.handleChange('ammendment_date', e.target.value)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={9}>
                                        <BasicTextArea
                                            label={"Amendment Notes"}
                                            id={"textarea-performer"}
                                            rows={4}
                                            limit={sizeLimits.largeNotes}
                                            limitWarning={messages.exceed1000CharacterWarning || ""}
                                            value={dealDetails?.ammendment_notes || null}
                                            onChange={(e) => this.handleChange('ammendment_notes', e.target.value)}
                                            typedCharCount={dealDetails?.ammendment_notes?.length || 0}
                                            showCharCount={true}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            }

                            <br />
                            {(this.props?.mode === 'New' || this.state?.isManageDealSteps) && <h6>Deal Steps</h6>}
                            {(this.props?.mode === 'New' || this.state?.isManageDealSteps) && dealDetails?.steps?.map((item, index) => (
                                <MDBRow>
                                    <Step
                                        deleteSteps={this.deleteSteps}
                                        step={item}
                                        projectId={this.props?.projectId || dealDetails?.project_id}
                                        stepNameOptions={this.state?.stepNameOptions || []}
                                        stepIndex={index}
                                        mode={this.state?.isManageDealSteps ? 'Edit' : 'New'}
                                        postInitiated={this.state?.postInitiated || false}
                                        currencySelectData={this.state?.currencySelectData || []}
                                        durationSelectData={this.state?.durationSelectData || []}
                                        changeHandler={this.handleStepChange.bind(this)}
                                        changeWriterHandler={this.handleWriterChange.bind(this)}
                                        getValues={this.getSearchValue.bind(this)}
                                        addWorksList={this.addWorksList.bind(this)}
                                        deleteWriters={this.deleteWriters.bind(this)}
                                        showDelete={this.props?.mode === "New" && index === 0 ? false : true}
                                    />
                                </MDBRow>
                            ))
                            }
                            {(this.props?.mode === 'New' || this.state?.isManageDealSteps) && <MDBRow>
                                {/* {this.state?.dealsDetails?.steps?.filter(o=>!o.is_delete)?.length == 0 && 
                        <MDBCol md={12}>
                            <div className='errorText'>Step is required!</div>
                        </MDBCol>
                    } */}
                                {(this.state?.postInitiated && this.state?.showRecordDeleteMsg) &&
                                    <MDBCol md={12}>
                                        <div className='errorText'>Record cannot be delete!!</div>
                                    </MDBCol>
                                }
                                <MDBCol className="">
                                    <BasicButton
                                        variant="contained"
                                        text="Add Step"
                                        type="inline"
                                        onClick={this.addDealsList}
                                        icon={"plus-circle"}
                                    />
                                </MDBCol>
                            </MDBRow>}

                            <MDBRow>
                                <MDBCol md={`${(this.props?.mode === 'Edit' && this.props?.headerText === 'Deal') ? 9 : 10}`}></MDBCol>
                                <MDBCol md={1} className={`${(this.props?.mode === 'Edit' && this.props?.headerText === 'Deal') ? 'ml-1' : ''}`}>
                                    <BasicButton
                                        text={this.state?.showLoader ? <CircularProgress color="inherit" size={20} /> : "Save"}
                                        onClick={()=>this.validateProject()}
                                    />
                                </MDBCol>
                                <MDBCol md={1} className={`${(this.props?.mode === 'Edit' && this.props?.headerText === 'Deal') ? 'ml-1' : ''}`}>
                                    <BasicButton
                                        variant="outlined"
                                        text={"Cancel"}
                                        onClick={this.props?.onClose}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </div>
                    )}
            </>
        )
    }

}
));