export const projectExecutivesConfig = {
    iscolumnFilterRequired: false,
    headings: [
        {
            headingLabel: "Executive Name",
            width: "15.5%",
            isColumn: true
        },
        {
            headingLabel: "User Group",
            width: "14.5%",
            isColumn: true
        },
        {
            headingLabel: "Department Title",
            width: "14.5%",
            isColumn: true
        },
        {
            headingLabel: "Phone",
            width: "12%",
            isColumn: true
        },
        {
            headingLabel: "Email",
            width: "18.5%",
            isColumn: true
        },
        {
            headingLabel: "Date",
            width: "17%",
            isColumn: true
        },
        {
            headingLabel: "Actions",
            width: "5%",
            isColumn: true
        },
    ],
    dataNodes: ["executive_name", "group_name", "deptTitle", "phone", "email", "date"],
    primaryKey: "project_executive_id",
    actions: ["pen","trash-alt"],
}

export const projectTalentConfig = {
    iscolumnFilterRequired: false,
    headings: [
        {
            headingLabel: "Talent Full Name",
            width: "17%",
            isColumn: true
        },
        {
            headingLabel: "Talent Type",
            width: "12%",
            isColumn: true
        },
        {
            headingLabel: "Talent Email",
            width: "17%",
            isColumn: true
        },
        {
            headingLabel: "Company Name",
            width: "21%",
            isColumn: true
        },
        {
            headingLabel: "Primary Representative Name",
            width: "25%",
            isColumn: true
        },
        {
            headingLabel: "Actions",
            width: "6%",
            isColumn: true
        },
    ],
    dataNodes: ["talent", "talent_role", "email", "agency_name", "representative"],
    primaryKey: "project_talent_id",
    hyperlinks: ["talent"],
    columnWithIcon: [
        {
            column: "agency_name",
            icon: "eye"
        },
        {
            column: "representative",
            icon: "eye"
        },
    ],
    actions: ["pen","trash-alt"],
}

export const projectMenuOptions = [
    { value: 1, label: "Add to My Queue" },
    { value: 2, label: "Remove from My Queue" },
    { value: 3, label: "Export to PDF" },
    { value: 4, label: "Delete Project" },
]

export const projectQueueInitialJson = {
    "is_deal_queue": null,
    "is_project_queue": 1,
    "user_id": null,
    "user_queue_deal_id": null,
    "deal_memo_id": 1,
    "user_queue_project_id": null,
    "project_id": null,
    "is_delete": 0

}

export const projectDealsConfig = {
    iscolumnFilterRequired: false,
    headings: [
        {
            headingLabel: "Memo Date",
            width: "10%",
            isColumn: true
        },
        {
            headingLabel: "Document Name",
            width: "20%",
            isColumn: true
        },
        {
            headingLabel: "Amendment Dates",
            width: "26%",
            isColumn: true
        },
        {
            headingLabel: "Notes",
            width: "30%",
            isColumn: true
        },
        {
            headingLabel: "Actions",
            width: "6%",
            isColumn: true
        },
    ],
    dataNodes: ["memo_date", "document_name", "ammendment_dates", "deal_notes"],
    primaryKey: "deal_memo_id",
    actions: ["file-pdf","share"],
}

export const projectWgaConfig = {
    iscolumnFilterRequired: false,
    headings: [
        {
            headingLabel: "Notice Date",
            width: "9%",
            isColumn: true
        },
        {
            headingLabel: "Title",
            width: "16%",
            isColumn: true
        },
        {
            headingLabel: "WGA Type",
            width: "10%",
            isColumn: true
        },
        {
            headingLabel: "Tentative/Proposed Writing Credit",
            width: "20%",
            isColumn: true
        },
        {
            headingLabel: "Final Credit Date",
            width: "15%",
            isColumn: true
        },
        {
            headingLabel: "Notes",
            width: "22%",
            isColumn: true
        },
        {
            headingLabel: "Actions",
            width: "10%",
            isColumn: true
        },
    ],
    dataNodes: ["notice_date", "title_formatted", "wga_type_name", "episode_writing_credits", "final_date", "notes"],
    primaryKey: "project_wga_id",
    actions: ["file-pdf", "copy", "pen", "trash-alt"],
}

export const projectDraftsConfig = {
    iscolumnFilterRequired: false,
    headings: [
        {
            headingLabel: "Name",
            width: "15%",
            isColumn: true
        },
        {
            headingLabel: "Writers",
            width: "14%",
            isColumn: true
        },
        {
            headingLabel: "Title Page Date",
            width: "11%",
            isColumn: true
        },
        {
            headingLabel: "Received Date",
            width: "11%",
            isColumn: true
        },
        {
            headingLabel: "Format",
            width: "12%",
            isColumn: true
        },
        {
            headingLabel: "Coverage",
            width: "12.5%",
            isColumn: true
        },
        {
            headingLabel: "Analyst",
            width: "10%",
            isColumn: true
        },
        {
            headingLabel: "Deal Step",
            width: "10%",
            isColumn: true
        },
        {
            headingLabel: "",
            width: "4.5%",
            isColumn: true
        },
    ],
    dataNodes: ["title", "writers", "draft_date", "received_date", "format_name", "coverage_type_name", "analyst", "step_name"],
    highlightConfidential: ["title"],
    primaryKey: "submission_id",
    actions: ["share"],
}