import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-time-picker/assets/index.css';
import './WritingDueReport.scss';
import { withUserContext } from '../../contexts/UserContext';
import DateField from '../../components/SharedComponents/DateField/DateField';
import BasicCheckbox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';

export default withUserContext(class WritingDueReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {

        let isLoading = this.state.isFetchingWritingDueReportDetails && this.state.isFetchingWritingDueReportTypes;
        return (
            <>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                    <MDBContainer className="WritingDueReportContainer">
                        <MDBRow>
                            <BasicLabel type={"fieldHeading"} text={"Due Date Range"} />
                        </MDBRow>
                        <MDBRow className='g-0'>
                            <MDBCol md={3} className="DateField1">
                                <DateField
                                // value={this.props?.dateValue}
                                // label={"Due Date Range"}
                                // onChange={this.props?.onDateChange || ""}
                                />
                            </MDBCol>
                            <MDBCol md={3} className="DateField2">
                                <DateField
                                    // value={this.props?.dateValue}
                                    label={""}
                                // onChange={this.props?.onDateChange || ""}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <BasicCheckbox
                                id="Stunt_Only"
                                label="Only Outstanding Drafts"
                            // checked={this.state.is_ssn === 1}
                            // align="start"
                            // onChange={e => this.handleCheck(e)}
                            />


                        </MDBRow>
                        <MDBRow>
                            <BasicCheckbox
                                id="Stunt_Only"
                                label="Only Delivered Drafts"
                            // checked={this.state.is_ssn === 1}
                            // align="start"
                            // onChange={e => this.handleCheck(e)}
                            />
                        </MDBRow>
                        <MDBRow className="p-2">
                            <MDBCol md={8} >
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    type="inline"
                                    variant="outlined"
                                    text={"Cancel"}
                                    onClick={this.props?.onClose}
                                    disabled={false}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    className="btnwidth"
                                    variant="contained"
                                    type="inline"
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate"}
                                    disabled={false}
                                    onClick={this.GenerateReportDetails}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}
            </>
        )
    }
}
);