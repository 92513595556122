import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import './SideNav.scss';
import { MDBIcon } from 'mdb-react-ui-kit';


export default withRouter(withOktaAuth(class SideNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            permissions: null
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.permissions !== state.permissions) {
            return {
                permissions: props.userContext?.active_tenant?.permissions
            }
        }
        return null
    }


    render() {
        return (
            <>
                <div id="sidenav" className="bg-warning">
                    {this.state?.permissions?.canCreateSubmissions && <a href="javascript:void(0);" id="Reports" className="nav-icons" onClick={() => this.props.handleShowSubmissionPopup(null)}>
                        <MDBIcon far fixed icon="file-alt" className="mr-2" /> New Submission
                        </a>}
                    {this.state?.permissions?.canModifyProject && <a href="javascript:void(0);" id="lookups" className="nav-icons" onClick={() => this.props.showNewProjectModal(null)}>
                        <MDBIcon fas fixed icon="film" className="mr-2" /> New Project
                        </a>}

                    {this.state?.permissions?.canModifyDeal && <a href="javascript:void(0);" id="NewDeal" className="nav-icons" onClick={this.props.showNewDealModal}>
                        <MDBIcon far fixed icon="file-alt" className="mr-2" /> New Deal
                        </a>
                    }
                    {this.state?.permissions?.canModifyWeekendRead &&
                        <a href="javascript:void(0);" id="newWeekendRead" className="nav-icons" onClick={this.props.handleShowNewWeekendReadModal}>
                            <MDBIcon fas fixed icon="book-reader" className="mr-2" /> New Weekend Read
                        </a>
                    }
                    {(this.state?.permissions?.canViewTalentRepAgencies || this.state?.permissions?.canCreateTalentRepsAgency || this.state?.permissions?.canEditTalentRepAgencies) &&
                        <a href="javascript:void(0);" id="talentPopup" className="nav-icons" onClick={this.props.handleShowTalentPopup}>
                            <MDBIcon fas fixed icon="leaf" className="mr-2" /> Manage Talent
                        </a>
                    }
                    {(this.state?.permissions?.canViewTalentRepAgencies || this.state?.permissions?.canCreateTalentRepsAgency || this.state?.permissions?.canEditTalentRepAgencies) &&
                        <a href="javascript:void(0);" id="representatives" className="nav-icons" onClick={this.props.handleShowRepresentativePopup}>
                            <MDBIcon fas fixed icon="user-tie" className="mr-2" /> Manage Representatives
                        </a>
                    }
                    {(this.state?.permissions?.canViewTalentRepAgencies || this.state?.permissions?.canCreateTalentRepsAgency || this.state?.permissions?.canEditTalentRepAgencies) &&
                        <a href="javascript:void(0);" id="agencies" className="nav-icons" onClick={() => this.props.handleShowAgencyPopup(null)}>
                            <MDBIcon fas fixed icon="building" className="mr-2" /> Manage Companies
                            </a>
                    }
                    {this.state?.permissions?.canManageLookups &&
                        < a href="javascript:void(0);" id="lookups" className="nav-icons" onClick={this.props.showLookupPopup}>
                            <MDBIcon fas fixed icon="list-ul" className="mr-2" /> Manage Lookups
                        </a>
                    }

                </div>
            </>
        );

    }

}
));