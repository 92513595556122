import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';

import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-time-picker/assets/index.css';
import './ProjectReport.scss';
import { withUserContext } from '../../contexts/UserContext';
import ScripterService from '../../services/service';
import * as Constants from '../../constants/constants';

export default withUserContext(class ProjectReports extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            project: false,
            projectList: [],
            selectedProject: null
        }
    }
    componentDidMount () {
        this.getEntity('projectList', 'PROJECT', '');
    }

    getEntity = (entity, entitytype, searchString) => {
        let loading = (entitytype === 'PROJECT') ? 'isProjectListLoading' : 'loading';
        this.setState({ [loading]: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`,
            this.props.userContext.active_tenant.tenant_id, this.props.userContext.active_tenant.division_id)
            .then(response => {
                let formattedList = [];
                console.log("Project Response: ",response);
                if (entitytype === 'PROJECT') {
                    formattedList = response?.data?.map(item => ({ value: item.project_id, label: item.project_title }));
                } 
                this.setState({ projectList: formattedList, [loading]: false });
                console.log("Project Data: ", this.state.projectList);
            },
                (err) => {
                    this.setState({ [loading]: false });
                    console.log("Error in fetching entities:", err)
                })
    }

    render() {

        let isLoading = this.state.isFetchingEpisodeDetails && this.state.isFetchingEpisodeTypes;
        return (
            <>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                    <MDBContainer className="ProjectReportContainer">
                        <MDBRow>
                            <MDBCol md={12} className="searchSelectcontent">
                            <SelectField
                                        id={"project"}
                                        className={"Project"}
                                        placeHolderText={"-Select Project Title-"}
                                        options={this.state.projectList}
                                        value={this.state.selectedProject || ""}
                                        onChange={(e) => this.setState({ selectedProject: e.target.value }) || ""}
                                    />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                            <MDBCol md={8} >
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    type="inline"
                                    variant="outlined"
                                    text={"Cancel"}
                                    onClick={this.props?.onClose}
                                    disabled={false}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    className="btnwidth"
                                    variant="contained"
                                    type="inline"
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate"}
                                    disabled={false}
                                    onClick={this.GenerateReportDetails}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}
            </>
        )
    }
}
);