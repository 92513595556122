import React from 'react';
import {
    MDBContainer, MDBRow, MDBCol, MDBIcon, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem
} from "mdbreact";
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import TabsComponent from '../../../SharedComponents/Tabs/Tabs';
import { weekendReadTabList, weekendReadTabListSub } from '../../../../constants/TabList';
import WeekendReadDetails from '../WeekendReadDetailView/WeekendReadDetails';
import WeekendReadFiles from '../WeekendReadDetailView/WeekendReadFiles';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import './WeekendReadDetailView.scss';
import { withUserContext } from '../../../../contexts/UserContext';
import { getFormattedDate } from '../../../../Common/Helper';
import RichTextEditor from '../../../SharedComponents/RichTextEditor/RichTextEditor';
import BasicTextArea from '../../../SharedComponents/BasicTextArea/BasicTextArea';
import dompurify from 'dompurify';
import { weekendRead, WeekendReadQueueInitialJson } from '../Dialogs/config';
import MessageModal from '../../../../components/SharedComponents/MessageModal'
import Loader from '../../../../Common/Loader/Loader';
import MiscFiles from '../../../MiscFiles/MiscFiles';
import { filePayload } from '../../../MiscFiles/config';

class WeekendReadDetailsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 1,
            subTabValue: 1,
            weekendReadId: null,
            isFetchingWeekendReadDetails: false,
            weekendReadDetails: weekendRead,
            execRead: null,
            execReading: null,
            attachedCoverage: null,
            assignedTo: null,
            advocate:null,
            editorNotes: null,
            notes: null,
            readMode: true,
            isPosting: false,
            isStatusPending: false,
            fieldChanged: false,
            showError: false,
            showFail: false,
            showSuccess: false,
            newTabValue: 1,
            fileCount: 0,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            deleteSuccessMessage: "Record deleted successfully!",
            deleteFailureMesaage: "Deletion Failed!",
            redirectMessage: "Access Denied",
            saveAddQueue: "Weekend Read has been added to your My Queue",
            saveRemoveQueue: "Weekend Read has been removed from your Queue",
            failAddQueue: "Failed to Add in a Queue",
            failRemoveQueue: "Failed to Remove from Queue",
            isDelete: false,
            isRedirectionPopup: false,
            showPageLoader: false,
            canModifyWeekendRead: true,
            canViewWeekendRead: true,
            tenant_id: null,
            weekendReadRefreshPage: false,
            fetchWeekendDetails: () => this.fetchWeekendDetails(),
        }
        this.fileRef = React.createRef();
    }
    static getDerivedStateFromProps(props, state) {
        if ((props.refreshPage?.value !== state.weekendReadRefreshPage) && props.refreshPage?.modal === 'weekend') {
            if (props.refreshPage?.value) {
                state.fetchWeekendDetails();
                props.toggleRefreshPage(props.refreshPage?.modal)
            }
            return {
                weekendReadRefreshPage: props.refreshPage?.value || false
            }
        }
        return null;
    }
    componentDidMount() {
        if (isNaN(this.props.match?.params?.id)) {
            this.props?.history?.push('/')
        }
        else {
            this.checkPermission();

        }


    }
    checkPermission() {
        let permissions = this.props?.userContext?.active_tenant?.permissions;
        this.setState({ canViewWeekendRead: permissions?.canViewWeekendRead, canModifyWeekendRead: permissions?.canModifyWeekendRead })
        if (!permissions?.canViewWeekendRead) {
            this.setState({ isRedirectionPopup: true })
            this.fieldChanged(true, "success");
        }
        else {
            this.setState({ weekendReadId: this.props.match?.params?.id }, () => {
                this.fetchWeekendDetails();
            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match?.params?.id !== this.props.match?.params?.id) {
            this.setState({ weekendReadId: this.props.match?.params?.id }, () => {
                this.fetchWeekendDetails();
            })
        }
    }
    handleEditOrChange = (value) => {
        let key = this.state.newTabValue === 1 ? "editorNotes" : "notes"
        this.setState({
            [key]: value
        })
    }
    checkDateGreater(date) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let date1 = new Date(date);
        return today < date1;
    }
    getFiles() {
        let payload = {
            ...filePayload,
            weekendReadId: this.state?.weekendReadId,
            type: "weekend",
            module: 'weekend',
            subModule: 'files',
            tenantName: this.props?.userContext?.active_tenant?.tenant_name
        }
        ScripterService.getDataParams(Constants.scripterServiceBaseUrl + `/getFiles`, payload, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data?.error) {
                    console.log("Error from fetch file", response.data?.error)
                    return;
                }
                let count = response.data?.Contents?.length;
                this.setState({ fileCount: count })
            }, err => {
                console.log('from misc files', err)
            })
    }
    fetchWeekendDetails = () => {
        if (this.state.weekendReadId) {
            this.setState({ isFetchingWeekendReadDetails: true })
            ScripterService.getData(Constants.scripterServiceBaseUrl + `/weekendRead?weekendReadId=${this.state.weekendReadId}`,
                this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    this.setState({ isFetchingWeekendReadDetails: false })
                    if (response.data.error) {
                        console.log("response error", response)
                    } else {
                        console.log("response", response?.data[0])
                        let formattedList = response?.data[0];
                        let isStatusPending = this.checkDateGreater(formattedList?.weekend_read_date);
                        if (formattedList) {
                            formattedList.weekend_read_date = getFormattedDate(formattedList?.weekend_read_date);
                        }
                        let temp_assigned = formattedList?.weekend_assigned?.map(({ assigned_to }) =>
                            `${assigned_to}`
                        ).join(', ')
                        let temp_advocate = formattedList?.advocate?.map(({ advocate }) =>
                        `${advocate}`
                    ).join(', ')
                        let temp_exec_read = formattedList?.exec_read.map(({ exec_read }) =>
                            `${exec_read}`
                        ).join(', ')
                        let temp_exec_reading = formattedList?.exec_reading.map(({ exec_reading }) =>
                            `${exec_reading}`
                        ).join(', ')
                        let temp_coverage = formattedList?.attached_coverage?.map(item => {
                            if (item?.attached_coverage === 'Undefined') {
                                item.attached_coverage = 'Can not view'
                            }
                            return item;
                        })
                        // let temp_coverage = formattedCoverages?.map(({ attached_coverage }) =>
                        //     `${attached_coverage}`
                        // ).join('\n')

                        this.setState({
                            weekendReadDetails: formattedList,
                            execRead: temp_exec_read,
                            isStatusPending: isStatusPending,
                            execReading: temp_exec_reading,
                            attachedCoverage: temp_coverage,
                            assignedTo: temp_assigned,
                            advocate: temp_advocate,
                            editorNotes: formattedList?.meeting_remarks,
                            notes: formattedList?.notes
                        })
                        this.getFiles();
                    }
                },
                    (err) => {
                        this.setState({ isFetchingWeekendReadDetails: false })
                        console.log("Error in fetching submission details:", err)
                    })
        }
    }

    handleTabChange = (newTabValue, type) => {
        this.setState({
            [type]: newTabValue,
            newTabValue: newTabValue
        })
    }

    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 1: return <WeekendReadDetails weekendReadDetails={this.state.weekendReadDetails}
                execRead={this.state.execRead} execReading={this.state.execReading}
                attachedCoverage={this.state.attachedCoverage} assignedTo={this.state.assignedTo} advocate={this.state.advocate} />
            case 2: return <MiscFiles
                fileRef={this.fileRef}
                toggleRefreshPage={this.props.toggleRefreshPage}
                canModifyFiles={this.state?.canModifyWeekendRead}
                filePayload={{
                    ...filePayload,
                    weekendReadId: this.state?.weekendReadId,
                    type: "weekend",
                    module: 'weekend',
                    subModule: 'files',
                }}
            />

        }
    }

    handleDeleteConfirmation = () => {
        this.setState({ showDeleteConfirmationModal: true });
    }

    saveRemarks = (isDelete) => {
        if (this.state.readMode && !isDelete) {
            this.setState({
                readMode: !this.state.readMode
            })
            return;
        }
        else {
            if (isDelete) {
                this.setState({ isDelete: true })
            }
            this.setState({ showPageLoader: true });
            let weekendReadPostJson = {
                ...this.state?.weekendReadDetails,
                weekend_read_date: getFormattedDate(this.state?.weekendReadDetails?.weekend_read_date, true),
                "notes": this.state?.notes,
                "meeting_remarks": this.state?.editorNotes,
                ...(isDelete) && { "is_delete": 1 }
            }
            // console.log("Post JSOn:", weekendReadPostJson);
            ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/weekendRead', weekendReadPostJson, this.props.userContext.active_tenant.tenant_id)
                .then(response => {
                    this.setState({ showPageLoader: false });
                    if (response.data.error) {
                        this.fieldChanged(true, "fail");
                    } else {
                        this.fieldChanged(true, "success");
                        if (isDelete) {
                            this.setState({ isDelete: false });
                            setTimeout(() => {
                                this.props.history.push("/")
                            }, 2000)
                        } else {
                            this.setState({
                                readMode: !this.state.readMode
                            })
                            this.fetchWeekendDetails();
                        }

                    }
                },
                    (err) => {
                        this.setState({ isPosting: false, showPageLoader: false })
                        this.fieldChanged(true, "fail");
                    });
        }
    }

    fieldChanged = (value, mode = "error") => {
        switch (mode) {
            case "error": return this.setState({ fieldChanged: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }
    handleText = () => {
        switch (this.state.tabValue) {
            case 1: return "Edit"
            case 2: return "Add File"
        }
    }

    handleIcon = () => {
        switch (this.state.tabValue) {
            case 1: return "pen"
            case 2: return "plus-circle"
        }
    }

    handleButtonTextChange = () => {
        this.setState({ readMode: !this.state.readMode })
    }

    showModals(file) {
        switch (this.state.tabValue) {
            case 1: return this.props?.handleShowEditWeekendReadModal(this.state?.weekendReadId); break;
            case 2: return this.fileRef.current.onFileUpload(file); break;
        }
    }
    closePopup() {
        if (this.state?.isRedirectionPopup) {
            this.props?.history?.push("/")
        }
        this.setState({ showError: false, showFail: false, showSuccess: false })

    }

    handleMyQueue = (actionType) => {
        this.setState({ mode: actionType === "Add To My Queue" ? 'ADDQUEUE' : "REMOVEQUEUE", showPageLoader: true })
        let payload = {
            ...WeekendReadQueueInitialJson,
            user_id: this?.props?.userContext?.user_profile?.user_id,
            weekend_read_id: this.state.weekendReadDetails?.weekend_read_id,
            is_delete: actionType === "Remove from My Queue" ? 1 : 0
        }
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + `/userQueue`, payload, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({ showPageLoader: false })
                if (response.data?.error) {
                    this.fieldChanged(true, "fail");
                    return;
                }
                this.fieldChanged(true, "success");
                this.fetchWeekendDetails();

            }, err => {
                this.setState({ showPageLoader: false })
                this.fieldChanged(true, "fail");
                console.log("Error from Weekend Read Queue", err)
            })
    }

    render() {
        const sanitizer = dompurify.sanitize;
        let message = this.state.showError ? this.state.unsavedMessage :
            this.state.showFail ? this.state?.isDelete ? this.state?.deleteFailureMesaage :
                this.state.mode === "ADDQUEUE" ? this.state.failAddQueue :
                    this.state.mode === "REMOVEQUEUE" ? this.state.failRemoveQueue : this.state.failMessage :
                this.state.showSuccess ? this.state?.isRedirectionPopup ? this.state?.redirectMessage :
                    this.state?.isDelete ? this.state?.deleteSuccessMessage :
                        this.state.mode === "ADDQUEUE" ? this.state.saveAddQueue :
                            this.state.mode === "REMOVEQUEUE" ? this.state.saveRemoveQueue : this.state.saveEditMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failTitle :
            this.state.showSuccess ? this.state.saveTitle : "";
        return (
            <>
                {this.state.isFetchingWeekendReadDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBContainer fluid className="weekend-detail-container">
                            <MessageModal
                                open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                                title={comfirmModalTitle}
                                message={message}
                                hideCancel={this.state.showFail || this.state.showSuccess || false}
                                primaryButtonText={"OK"}
                                secondaryButtonText={"Cancel"}
                                onConfirm={() => this.closePopup()}
                                handleClose={(e) => this.closePopup()}
                            />
                            <MessageModal
                                open={this.state.showDeleteConfirmationModal}
                                title={"Delete Weekend Read"}
                                message={"Are you sure you want to Delete?"}
                                primaryButtonText={"Delete"}
                                secondaryButtonText={"Cancel"}
                                onConfirm={() => this.setState({ showDeleteConfirmationModal: false }, () => {
                                    this.saveRemarks(true)
                                })}
                                handleClose={(e) => this.setState({ showDeleteConfirmationModal: false })}
                            />
                            <Loader
                                isOpen={this.state.showPageLoader || false}
                                text={this.state.mode === 'ADDQUEUE' ? "Adding..." : (this.state.mode === 'REMOVEQUEUE' || this.state.isDelete) ? "Removing..." : "Loading..."}
                            />
                            <div className='weekend'>
                                <div className='nameWrapper'>
                                    <span className='weekendTitle'>Weekend Read</span>
                                    <span className='onTheJob'>&nbsp;- {this.state?.weekendReadDetails?.title}</span>
                                    {this.state?.canModifyWeekendRead &&
                                        <span className='dropdownAction'>
                                            <MDBDropdown >
                                                <MDBDropdownToggle tag="a">
                                                    <MDBIcon
                                                        className='chevron'
                                                        icon={"ellipsis-h"}
                                                    />
                                                </MDBDropdownToggle>
                                                <MDBDropdownMenu className="text-end border border-1-quinary">
                                                    {this.state.weekendReadDetails?.is_add_to_queue == 0 ?
                                                        <MDBDropdownItem active={false} onClick={this.handleMyQueue.bind(this, 'Add To My Queue')} >
                                                            Add To My Queue
                                            </MDBDropdownItem> : ''
                                                    }
                                                    <MDBDropdownItem
                                                        active={false}
                                                        onClick={this.handleMyQueue.bind(this, 'Remove from My Queue')}
                                                        disabled={this.state.weekendReadDetails?.is_add_to_queue == 0 || false}
                                                    >
                                                        Remove from My Queue
                                        </MDBDropdownItem>
                                                    <MDBDropdownItem
                                                        active={false}
                                                        // onClick={this.saveRemarks.bind(this,true)} 
                                                        onClick={() => this.handleDeleteConfirmation(true)}
                                                    >
                                                        Delete Weekend Read
                                        </MDBDropdownItem>
                                                </MDBDropdownMenu>
                                            </MDBDropdown>

                                        </span>
                                    }
                                </div>
                                <div>
                                    {this.state?.isStatusPending ?
                                        <span className='Pending'>Pending</span> :
                                        <span className='Completed'>Completed</span>
                                    }
                                </div>
                            </div>
                            <>
                                <MDBRow id="tab-content">
                                    <MDBCol md={10}>
                                        <TabsComponent
                                            tabList={weekendReadTabList}
                                            value={this.state.tabValue}
                                            badgeContent={this.state.fileCount || 0}
                                            onChange={(e, v) => this.handleTabChange(v, "tabValue")}
                                        />
                                    </MDBCol>
                                    {this.state?.canModifyWeekendRead &&
                                        <MDBCol md={2} className="edit-btn">
                                            <BasicButton
                                                variant="contained"
                                                type="inline"
                                                color="primary"
                                                text={this.handleText()}
                                                icon={this.handleIcon()}
                                                {...(this.state.tabValue === 2 && { uploadFile: (file) => this.showModals(file) })}
                                                {...(this.state.tabValue === 2 && { inputType: 'file' })}
                                                onClick={this.showModals.bind(this)}
                                            />
                                        </MDBCol>
                                    }
                                </MDBRow>

                            </>
                            {this.getTabComponent()}

                            <MDBRow>
                                <MDBCol md={11}>
                                    <TabsComponent
                                        tabList={weekendReadTabListSub}
                                        value={this.state.subTabValue}
                                        onChange={(e, v) => this.handleTabChange(v, "subTabValue")}
                                    />
                                </MDBCol>
                                {this.state?.canModifyWeekendRead &&
                                    <MDBCol md={1} className="editOrSave">
                                        <BasicButton
                                            text={this.state.readMode ? "Edit" : "Save"}
                                            icon={this.state.readMode ? "pen" : "save"}
                                            //onClick={this.handleButtonTextChange} 
                                            onClick={this.saveRemarks.bind(this, false)} />
                                    </MDBCol>
                                }
                            </MDBRow>
                            {this.state.readMode === true ? (
                                <MDBCard className="remarksOrNotes">
                                    <MDBCardBody>
                                        <MDBCardText>
                                            <MDBRow>
                                                <MDBCol md={12} className="rich-text-content">
                                                    {this.state.subTabValue === 1 ? <span className='infoText' dangerouslySetInnerHTML={{ __html: sanitizer(this.state.editorNotes) }} />
                                                        : <span className='infoText' dangerouslySetInnerHTML={{ __html: sanitizer(this.state.notes) }} />}
                                                </MDBCol>

                                                {/* <BasicTextArea value={<span dangerouslySetInnerHTML={{ __html: sanitizer(this.state.editorNotes) }} />} /> */}
                                                {/* <span dangerouslySetInnerHTML={{ __html: sanitizer(this.state.editorNotes) }} /> */}
                                            </MDBRow>
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            ) : (
                                    <MDBCard className="remarksOrNotesEditable">
                                        <MDBCardBody>
                                            <MDBCardText>
                                                <MDBRow>
                                                    <MDBCol md={12}>
                                                        <RichTextEditor id="notesMeetingRemarks" value={this.state.subTabValue === 1 ? this.state.editorNotes : this.state.notes}
                                                            onChange={this.handleEditOrChange}>
                                                        </RichTextEditor>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                )}
                        </MDBContainer>)
                }
            </>
        );
    }
}

export default withUserContext(WeekendReadDetailsView);