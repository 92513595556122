import React, { Component } from 'react';
import AgencyDetails from './AgencyDetails';
import AgentList from './AgentList';
import CoveredTalent from './CoveredTalent';
import { getFormattedDate, getAddress, getDateAndTime } from '../../../Common/Helper';
import {
    initialAgencyPhone, initialAgencyEmail, initialAgencyAddress, newAgencyDetails, addressConfig
} from './Config';
import { validateEmail, validateUSNumber, validateIntlNumber, validatePlusCharacter, validatePhoneNumber } from '../../../Common/Helper';
import { withUserContext } from '../../../contexts/UserContext';
import sizeLimits from '../../../Common/SizeLimits.json';
import './Agency.scss';
import ScripterService from '../../../services/service';
import * as Constants from '../../../constants/constants';

class AgencyContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            mode: "new",
            navObj: {},
            agentList: [],
            coveredTalentList: [],
            agencyTypes: [],
            phoneIndex: 2,
            emailIndex: 2,
            addressIndex: 2,
            agencyDetails: [],
            isFetchingAgentList: false,
            isFetchingAgencyDetails: false,
            postInitiated: false,
            checkedMultiPrimary: {
                email_list: false,
                phone_list: false,
                address_list: false
            },
            limit: {
                agencyNameCharacterLimit: 50,
                agencyNotesCharacterLimit: 100,
                agencyEmailCharacterLimit: 50,
                agencyUSPhoneLimit: 10,
                agencyIntlPhoneLimit: 13,
                agencyAddressCharacterLimit: 100
            },
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.mode !== state.mode || props.tabValue !== state.tabValue || (JSON.stringify(props.navObj) !== JSON.stringify(state.navObj)) && props.navObj) {
            return {
                mode: props.mode,
                tabValue: props.tabValue,
                navObj: props.navObj
            }
        }
        return null
    }

    componentDidMount() {
        this.getAgentTypes()
        if (this.props.mode === "new") {
            if (Object.entries(this.state.agencyDetails).length === 0) {
                this.setState({ agencyDetails: this.formatAgencyDetailsResponse(JSON.parse(JSON.stringify({ ...newAgencyDetails }))), agentList: [], coveredTalentList: [] }, () => {
                    this.props.setModalList(this.state.agencyDetails);
                })
            }
        } else if (this.props.mode === "edit") {
            this.getAgencyDetails(this.state.navObj.agency_id);
            this.getAgentList();
        }
    }

    getAgentTypes = () => {
        // API to get the agent types
        this.setState({ isFetchingAgencyTypes: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_agency_types', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                this.setState({ agencyTypes: formattedList, isFetchingAgencyTypes: false });
            },
                (err) => {
                    this.setState({ isFetchingAgencyTypes: false });
                    console.log("Error in fetching Agency Types:", err)
                })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.mode !== prevState.mode) {
            if (this.state.mode === "new" && this.state.agencyDetails?.agency_id) {
                this.setState({ agencyDetails: this.formatAgencyDetailsResponse(JSON.parse(JSON.stringify({ ...newAgencyDetails }))), agentList: [], coveredTalentList: [] }, () => {
                    this.props.setModalList(this.state.agencyDetails);
                })
            } else if (this.state.mode === "edit") {
                this.getAgencyDetails(this.state.navObj.agency_id);
                this.getAgentList();
            }
        }
        if (this.state.mode === "edit" && this.state.navObj !== prevState.navObj && this.state.navObj) {
            this.getAgencyDetails(this.state.navObj?.agency_id);
            this.getAgentList();
        }
    }

    validateErrorFlags = (agencyDetails) => {
        let canSubmit = ((agencyDetails?.agency_name && agencyDetails?.agency_type_id) &&
            (agencyDetails?.agency_name?.length <= this.state.limit.agencyNameCharacterLimit) &&
            //(agencyDetails?.agency_notes ? agencyDetails?.agency_notes?.length <= this.state.limit.agencyNotesCharacterLimit : true) &&
            (agencyDetails.email_list?.length !== 0 ?
                !agencyDetails.email_list?.every(email => !(email.email.length <= this.state.limit.agencyEmailCharacterLimit)
                    || !validateEmail(email.email)) : true) &&
            (agencyDetails.phone_list?.every(phone =>
                !(phone.phone) ? true :
                    !phone.agency_phone_id ? (validatePlusCharacter((phone.phone.trim()).charAt(0)) ?
                        validateIntlNumber(phone.phone) && phone.phone.length <= sizeLimits.phoneLimitIntl :
                        (validateUSNumber(phone.phone) || validatePhoneNumber(phone.phone)) && phone.phone.length <= sizeLimits.phoneLimitUS) : true)) &&
            (agencyDetails.address_list?.every(address =>
            (address?.agency_address_id ? true : (address?.address_1 && address?.city && address?.state && address?.zip &&
                (address.is_international === 1 ? address?.zip.length <= sizeLimits?.intlZipCharacterLimit  : address?.zip.length <= sizeLimits?.usZipCharacterLimit)))
            )) &&
            (!this.state.checkedMultiPrimary.email_list) && (!this.state.checkedMultiPrimary.phone_list) &&
            (!this.state.checkedMultiPrimary.address_list)) ? true : false;
        return canSubmit;

    }

    handleGeneralEdit = (field, value) => {
        this.setState(prevState => ({
            agencyDetails: {
                ...prevState.agencyDetails,
                [field]: value
            },
            postInitiated: false
        }), () => {
            this.props.fieldChanged(true, "change");
            this.setState({ agencyEdited: true });
            this.props.setModalList(this.state.agencyDetails);
        })
        if (field === "agency_type_id") {
            this.setState(prevState => ({
                agencyDetails: {
                    ...prevState.agencyDetails,
                    agency_type: this.state.agencyTypes?.find(type => type.value === value)?.label
                },
                postInitiated: false
            }))
        }
    }

    initializeCountry = (field, value) => {
        this.setState(prevState => ({
            agencyDetails: {
                ...prevState.agencyDetails,
                [field]: value
            },
            postInitiated: false
        }), () => {
            this.props.setModalList(this.state.agencyDetails);
        })
    }

    handleListEdit = (field, value, obj, listName, check = '') => {
        let agencyDetails = { ...this.state.agencyDetails };
        let id = (listName === "email_list") ? "agency_email_id" :
            (listName === "phone_list") ? "agency_phone_id" :
                (listName === "address_list") ? "agency_address_id" : "";
        let list = agencyDetails[listName]?.map(item => {
            let currentVal = item;
            if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                if (field === "is_primary" || field === "is_international") {
                    currentVal[field] = value ? 1 : 0;
                } else if (field === "address") {
                    currentVal = getAddress(field, currentVal, value, addressConfig);
                } else {
                    currentVal[field] = value;
                }
                return currentVal;
            } return currentVal;
        })
        this.setState(prevState => ({
            checkedMultiPrimary: {
                ...prevState.checkedMultiPrimary,
                [listName]: this.checkMultiplePrimary(listName, list)
            }
        }))
        if (check === 'Check') {
            this.initializeCountry(listName, list);
        } else {
            this.handleGeneralEdit(listName, list);
        }
    }

    setAgencyDetailsUniqueEntries = () => {
        let agencyDetails = { ...this.state.agencyDetails };
        agencyDetails.email_list = [...agencyDetails.email_list].filter((tag, index, array) =>
            (array.findIndex(t => t.email == tag.email && t.email_type == tag.email_type) == index) &&
            tag.email && tag.email_type);
        agencyDetails.phone_list = [...agencyDetails.phone_list].filter((tag, index, array) =>
            (array.findIndex(t => t.phone == tag.phone && t.phone_type == tag.phone_type) == index) &&
            tag.phone && tag.phone_type);
        agencyDetails.address_list = [...agencyDetails.address_list].filter((tag, index, array) =>
            (array.findIndex(t => t.address_1 == tag.address_1 && t.address_2 == tag.address_2 &&
                t.address_3 == tag.address_3 && t.address_type == tag.address_type) == index) && (tag.address_1 ||
                    tag.address_2 || tag.address_3 || tag.city || tag.state || tag.zip));
        return agencyDetails;
    }

    checkIfPrimaryIsChecked = (agencyDetails) => {
        if (agencyDetails.email_list.length !== 0 && !agencyDetails.email_list?.some(item => item.is_primary == 1)) {
            agencyDetails.email_list[0].is_primary = 1;
        }
        if (agencyDetails.phone_list.length !== 0 && !agencyDetails.phone_list?.some(item => item.is_primary == 1)) {
            agencyDetails.phone_list[0].is_primary = 1;
        }
        if (agencyDetails.address_list.length !== 0 && !agencyDetails.address_list?.some(item => item.is_primary == 1)) {
            agencyDetails.address_list[0].is_primary = 1;
        }
        return agencyDetails;
    }

    addAdditionalField = (listName) => {
        let agencyDetails = { ...this.state.agencyDetails };
        switch (listName) {
            case 'email_list':
                let initialEmailArray = { ...initialAgencyEmail };
                let email_list = [...agencyDetails.email_list];
                initialEmailArray.index = this.state.emailIndex;
                initialEmailArray.canRemove = true;
                email_list.push(initialEmailArray)
                agencyDetails.email_list = email_list;
                this.setState({ emailIndex: this.state.emailIndex + 1, agencyDetails: agencyDetails });
                break;
            case 'phone_list':
                let initialPhoneArray = { ...initialAgencyPhone };
                let phone_list = [...agencyDetails.phone_list];
                initialPhoneArray.index = this.state.phoneIndex;
                initialPhoneArray.canRemove = true;
                phone_list.push(initialPhoneArray)
                agencyDetails.phone_list = phone_list;
                this.setState({ phoneIndex: this.state.phoneIndex + 1, agencyDetails: agencyDetails });
                break;
            case 'address_list':
                let initialAddressArray = { ...initialAgencyAddress };
                let address_list = [...agencyDetails.address_list];
                initialAddressArray.index = this.state.addressIndex;
                initialAddressArray.canRemove = true;
                address_list.push(initialAddressArray)
                agencyDetails.address_list = address_list;
                this.setState({ addressIndex: this.state.addressIndex + 1, agencyDetails: agencyDetails });
                break;
        }
    }

    removeField = (listName, callbackItem) => {
        let agencyDetails = { ...this.state.agencyDetails };
        if (!callbackItem.canRemove) {
            let index = agencyDetails[listName].indexOf(callbackItem);
            switch (listName) {
                case 'email_list':
                    agencyDetails[listName][index] = { ...initialAgencyEmail };
                    break;
                case 'phone_list':
                    agencyDetails[listName][index] = { ...initialAgencyPhone };
                    break;
                case 'address_list':
                    agencyDetails[listName][index] = { ...initialAgencyAddress };
                    break;
            }
        } else {
            let list = agencyDetails[listName]?.filter(item => item.index !== callbackItem.index);
            agencyDetails[listName] = list;
        }
        this.setState({ agencyDetails: agencyDetails });
    }

    postAgencyDetails = (agencyDetailsPostJson) => {
        this.props.setPostFlag(true);
        // API call to post the Agency modal Data
        if(this.props?.agencyRepId) {
            agencyDetailsPostJson.representative_id = this.props?.agencyRepId
        }
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/agency', agencyDetailsPostJson, this.props.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                if (response.data.error) {
                    this.props.fieldChanged(true, "fail");
                    this.props.setPostFlag(false);
                } else {
                    this.props.fieldChanged(false, "error");
                    this.props.fieldChanged(true, "success");
                    this.props.setPostFlag(false);
                    console.log("post response", response);
                    agencyDetailsPostJson.agency_id = response?.data[0]?.agency_id || null;
                    this.setState({ 
                        postInitiated: false, 
                        agencyDetails: this.formatAgencyDetailsResponse(JSON.parse(JSON.stringify({ ...agencyDetailsPostJson }))) 
                    }, () => {
                        if (this.props?.updateDetailsToParent) {
                            this.props?.updateDetailsToParent(this.state.agencyDetails)
                        }
                    })
                }
            },
                (err) => {
                    this.props.fieldChanged(true, "fail");
                    this.props.setPostFlag(false);
                    console.log("Post agency details error: " + err);
                });
    }

    handleSubmit = () => {
        let agencyDetailsPostJson = this.checkIfPrimaryIsChecked(this.setAgencyDetailsUniqueEntries());
        this.setState({ postInitiated: true }, () => {
            if (this.validateErrorFlags(agencyDetailsPostJson)) {
                this.editPhoneNumbers(agencyDetailsPostJson);
                this.postAgencyDetails(agencyDetailsPostJson);
            } else {
                console.log("Post JSON", agencyDetailsPostJson)
            }
        })
    }

    editPhoneNumbers = (agencyDetailsPostJson) => {
        agencyDetailsPostJson.phone_list.map(phone => {
            if (phone.phone.length === 10) {
                phone.phone = '(' + phone.phone.substr(0, 3) + ')' + ' ' + phone.phone.substr(3, 3) + '-' + phone.phone.substr(6, 4);
            }
        }
        )
    }

    checkMultiplePrimary = (listName, list) => {
        let multiplePrimary = list?.filter(item => item.is_primary === 1);
        return multiplePrimary.length > 1
    }


    getAgencyDetails = (agency_id) => {
        this.setState({ isFetchingAgencyDetails: true });
        // API call to get the agency details
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/agency?searchString=${agency_id}&searchKey=id`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({ agencyDetails: this.formatAgencyDetailsResponse(response.data[0]), isFetchingAgencyDetails: false });
                this.props.setModalList(response.data[0]);
                this.state.agencyDetails?.address_list?.map((item) => {
                    item['address'] = ((!item.address_1) ? '' : item.address_1) + ((!item.address_2) ? '' : ' ' + item.address_2)
                        + ((!item.address_3) ? '' : ' ' + item.address_3)
                });
            },
                (err) => {
                    this.setState({ isFetchingAgencyDetails: false, agencyDetails: this.formatAgencyDetailsResponse(JSON.parse(JSON.stringify({ ...newAgencyDetails }))) });
                    console.log("Error in fetching Agency Details:", err)
                })
    }

    formatAgencyDetailsResponse = (response) => {
        response?.email_list.push(JSON.parse(JSON.stringify({ ...initialAgencyEmail })));
        response?.phone_list.push(JSON.parse(JSON.stringify({ ...initialAgencyPhone })));
        response?.address_list.push(JSON.parse(JSON.stringify({ ...initialAgencyAddress })));

        return response;
    }

    getAgentList = () => {
        this.setState({ isFetchingAgentList: true });
        if (this.props.navObj.agency_id) {
            // API call to get the agent list
            ScripterService.getData(Constants.scripterServiceBaseUrl + '/agencyRepresentativesTalent?agencyId=' + this.props.navObj.agency_id, this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    let formattedListAgent = response.data?.map(item => {
                        item.representative_created_at = getDateAndTime(item.representative_created_at);
                        item.talent_created_at = getDateAndTime(item.talent_created_at);
                        return item;
                    });
                    let formattedListTalent = [];
                    response.data?.map(item => {
                        if (item.talent_name) {
                            formattedListTalent.push(item);
                        }

                    });
                    this.setState({ agentList: formattedListAgent, coveredTalentList: formattedListTalent, isFetchingAgentList: false });
                },
                    (err) => {
                        this.setState({ isFetchingAgentList: false });
                        console.log("Error in fetching Agent List:", err)
                    })
        }
    }

    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 0: return <AgencyDetails
                setModalList={this.props.setModalList}
                isFetchingAgencyDetails={this.state.isFetchingAgencyDetails}
                postInitiated={this.state.postInitiated}
                agencyDetails={this.state.agencyDetails}
                fieldChanged={this.props.fieldChanged}
                handleGeneralEdit={this.handleGeneralEdit}
                handleListEdit={this.handleListEdit}
                addAdditionalField={this.addAdditionalField}
                checkedMultiPrimary={this.state.checkedMultiPrimary}
                removeField={this.removeField}
                agencyTypes={this.state.agencyTypes}
                setPostFlag={this.props.setPostFlag}
                limit={this.state.limit}
                navObj={this.props.navObj || {}}
                mode={this.state.mode || "new"} />;
            case 1: return <AgentList
                agentList={this.state.agentList}
                isLoading={this.state.isFetchingAgentList}
                navFuns={this.props.navFuns}
                agencyEdited={this.state.agencyEdited} />;
            case 2: return <CoveredTalent
                agentList={this.state.coveredTalentList}
                isLoading={this.state.isFetchingAgentList}
                navFuns={this.props.navFuns}
                agencyEdited={this.state.agencyEdited} />;
        }
    }

    render() {
        return (
            <div className="agentContainer">
                <input className="hiddenButton" type="button" ref={this.props.submitButtonRef} onClick={() => this.handleSubmit()}></input>
                {this.getTabComponent()}
            </div>
        );
    }
}

export default withUserContext(AgencyContainer);