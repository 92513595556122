import React from 'react';
import './BasicLabel.scss';

export default class BasicLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let isMandatory = this.props?.isMandatory || false;
        return (
            <div className={this.props?.text ? "BasicLabelContainer" : "hidden"}>
                {this.props?.showCharCount ?
                    <div className={`${this.props?.labelClassName} label-with-count`}>
                        <label className={this.props?.type || "text"}>{this.props?.text}<span>{isMandatory ? "*" : ""}</span></label>
                        <p className="charCount">
                            {this.props?.typedCharCount + '/' + this.props?.charLimit}
                        </p>
                    </div> :
                    <label className={this.props?.type || "text"}>{this.props?.text}<span>{isMandatory ? "*" : ""}</span></label>
                }
            </div>
        );
    }
}

