export const filePayload ={
    "tenantName": null,
    "submissionId": null,
    "projectId":null,
    "dealId": null,
    "weekendReadId": null,
    "module": null,
    "subModule":null,
    "fileName": null
}

//Primary keys : "Pre-Production": 1,"Post-Production":6,"Production":13,"Active:32","Pending":37
export const projectStatusesMakePrivate = [
    1, 6, 13,32, 37
]

/*
_______________________________
modules     | submodule       |
----------------------------------
submission  | misc            |
project     | misc/poster     |
deal        | files           |
weekend     | files           |
talent      | profile         |
-------------------------------
*/