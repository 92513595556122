import React from 'react';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import DateField from '../../../SharedComponents/DateField/DateField';
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import messages from '../../../../Common/Messages.json';
import { limit } from './Config';

export default class SubSourceMaterials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submissionDetails: {},

        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.submissionDetails !== state.submissionDetails) {
            return {
                submissionDetails: props.submissionDetails
            }
        }
        return null
    }

    render() {
        return (
            <MDBContainer fluid className="sub-source-materials-container">
                <MDBRow className="submission-row-margin">
                    <MDBCol md={3}>
                        <SearchSelectField
                            id={"type"}
                            width={'100%'}
                            options={this.props.types || []}
                            value={{ value: this.props.source?.material_type_id, text: this.props.source?.material_type }}
                            onChange={(e, value) => this.props.onChange("material_type_id", value || null, this.props.source, "source_material")}
                            label={"Type"} />
                    </MDBCol>
                    <MDBCol md={4} id="h5" className="p-0">
                        <BasicTextField
                            id="title"
                            label={"Title"}
                            limit={limit.title}
                            limitWarning={messages.exceed300CharacterWarning || ""}
                            value={this.props.source.title || ""}
                            onChange={(e) => this.props.onChange("title", e.target.value.length > 0 ? e.target.value : null, this.props.source, "source_material")}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        {/* <SearchSelectField
                            id={"source-author"}
                            label={"Author"}
                            placeholder={'- Search -'}
                            options={this.props?.userList || []}
                            detail_selected={(this.props.source?.source_material_author_id || this.props.source?.source_material_talent_group_id) && this.props.source?.source_material_author ? {
                                value: (this.props.source?.source_material_author_id || this.props.source?.source_material_talent_group_id),
                                text: this.props.source?.source_material_author
                            } : null}
                            width={'100%'}
                            valueSelected={(e, value) => this.props.onChange("source_material_author_id", value || null, this.props.source, "source_material")}
                            searchText={(e) => this.props?.getEntity('talentList', 'TALENT', e.target.value)}
                            multiple={false}
                            searchSelect={true}
                        /> */}
                        <BasicTextField
                            id={"author"}
                            label={"Author"}
                            limit={limit.author}
                            limitWarning={messages.exceed300CharacterWarning || ""}
                            value={this.props.source.source_material_author || ""}
                            onChange={(e) =>  this.props.onChange("source_material_author", e.target.value.length > 0 ? e.target.value : null, this.props.source, "source_material")}
                        />
                    </MDBCol>
                    <MDBCol md={1} className="icon">
                        {
                            (this.props?.showRemove) ? <MDBIcon icon="trash-alt" className="float-right" onClick={this.props.removeCallback} /> : null
                        }
                        {
                            (this.props?.showAdd && !this.props?.disabled) ? <MDBIcon icon="plus-circle" className="float-right ml5" onClick={this.props.addCallback} /> : null
                        }
                    </MDBCol>
                </MDBRow>
                <MDBRow className="submission-row-margin">
                    <MDBCol md={4} className="p-0"></MDBCol>
                    <MDBCol md={4} id="h5" className="p-0">
                        <BasicTextField
                            id={"publisher"}
                            label={"Publisher"}
                            limit={limit.publisher}
                            limitWarning={messages.exceed300CharacterWarning || ""}
                            value={this.props.source.publisher || ""}
                            onChange={(e) =>  this.props.onChange("publisher", e.target.value.length > 0 ? e.target.value : null, this.props.source, "source_material")}
                        />
                    </MDBCol>
                    <MDBCol md={2} id="h6" className="pr-0 date">
                        <DateField
                            label={"Date"}
                            value={this.props.source.source_material_date || ""}
                            onChange={(e) => this.props.onChange("source_material_date", e.target.value.length > 0 ? e.target.value : null, this.props.source, "source_material")}
                        />
                    </MDBCol>
                    <MDBCol md={2} id="h5" className="pr-0 copyright-date">
                        {/* <DateField
                            label={"Copyright Date"}
                            value={this.props.source.copy_right_date || ""}
                            onChange={(e) => this.props.onChange("copy_right_date", e.target.value.length > 0 ? e.target.value : null, this.props.source, "source_material")}
                        /> */}
                        <BasicTextField
                            id={"copyright-date"}
                            label={"Copyright Date"}
                            // limit={limit.author}
                            // limitWarning={messages.exceed300CharacterWarning || ""}
                            value={this.props.source.copy_right_date || ""}
                            onChange={(e) =>  this.props.onChange("copy_right_date", e.target.value.length > 0 ? e.target.value : null, this.props.source, "source_material")}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}