import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicButton from '../SharedComponents/BasicButton/BasicButton'
import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-time-picker/assets/index.css';
import Step from '../Views/Deals/Step';
import {intialDeals} from '../../components/Views/Deals/config';
import './ManageDeal.scss';
import { withUserContext } from '../../contexts/UserContext';

export default withUserContext(class ManageDeal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isFetchingDealsStatus: false,
            postInitiated: false,
            isPosting: false,
            dealsDetails: intialDeals,
            dealIndex:2,
        }
    }
    addDealsList = () => {
        let dealsDetails = { ...this.state.dealsDetails };
        let initialDealList = { ...intialDeals };
        let deal_list = [...dealsDetails.deal_list];
        initialDealList.index = this.state.dealIndex;
        initialDealList.canRemove = true;
        deal_list.push(initialDealList)
        dealsDetails.deal_list = deal_list;
        this.setState({ repIndex: this.state.dealIndex + 1, dealsDetails: dealsDetails });
    }

    render() {
        let isLoading = this.state.isFetchingDealsDetails && this.state.isFetchingDealsTypes;
        return (
            <>

                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <div className="ManageDealContainer">
                            <MDBRow>
                                <Step />
                            </MDBRow>
                            {
                                 this.state.dealsDetails?.deal_list.map((item, index) => (
                                 <MDBRow>
                                        <Step/>
                                   </MDBRow>
                                ))
                            }
                            
                            <MDBRow>
                                <MDBCol className="mt-2">
                                    <BasicButton
                                        variant="contained"
                                        text="Add Step"
                                        type="inline"
                                        onClick={this.addDealsList}
                                        icon={"plus-circle"}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={9}></MDBCol>
                                <MDBCol md={3}>
                                    <MDBRow>
                                        <MDBCol>
                                            <BasicButton
                                                text={"Save"} />
                                        </MDBCol>
                                        <MDBCol>
                                            <BasicButton
                                                variant="outlined"
                                                text={"Cancel"} />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                           
                            
                        </div>
                    )}
            </>
        )
    }
}
);