import React, { Component } from 'react';
import RepresentationDetail from './RepresentationDetails';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import { withUserContext } from '../../../contexts/UserContext';
import ScripterService from '../../../services/service';
import * as Constants from '../../../constants/constants';

class TalentRepresentation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agencies: []
        }
    }

    componentDidMount() {
        this.getAgencyDetails();
    }

    getAgencyDetails = () => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + "/talentAgencies", this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({
                    text: item.agency_name,
                    value: item.agency_id,
                    phone: item.agency_phone,
                    email: item.agency_email,
                    emailType: item.agency_email_id,
                    phoneType: item.agency_phone_id
                }));
                this.setState({ agencies: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Agencies:", err)
                })
    }


    render() {
        return (
            <div>
                <MDBContainer className="TalentRepresentation">
                    <MDBRow>
                        <MDBCol md={10}></MDBCol>
                        <MDBCol md={2} className="mt-2 create-rep-btn">
                            <BasicButton
                                variant="contained"
                                text="Create New Rep"
                                type="inline"
                                onClick={() => this.props.addRepresentationList()}
                                icon={"plus-circle"}
                            />
                        </MDBCol>
                        <MDBCol md={7}></MDBCol>
                    </MDBRow>
                    {this.props.representativeDetails?.map((rep, index) => <RepresentationDetail
                        rep={rep}
                        keyName={"rep" + index}
                        navFuns={this.props.navFuns}
                        handleListEdit={this.props.handleListEdit}
                        removeCallback={this.props.removeCallback}
                        handleRepListChange={this.props.handleRepListChange}
                        postInitiated={this.props.postInitiated}
                        agencies={this.state.agencies || []}
                        checkedMultiPrimary={this.props.checkMultiplePrimary(this.props.representativeDetails)
                            && (this.props.representativeDetails.length - 1) === index}
                        talentUpdated={this.props?.talentUpdated}
                    />)}
                </MDBContainer>
            </div>
        );
    }
}

export default withUserContext(TalentRepresentation);