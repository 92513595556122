import React from 'react';
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import SearchSelectField from '../../../SharedComponents/SearchSelectField';

export default class SearchSelectList extends React.Component {

    render() {
        return (
            <MDBRow className="searchSelectListContainer">
                <MDBRow>
                    <MDBCol md={11} id="pb2">
                        {!this.props?.searchSelect ?
                            <SearchSelectField
                                id={this.props?.id}
                                showMandatory={this.props.showMandatory}
                                value={this.props?.value || ""}
                                onChange={this.props.onChange}
                                isMandatory={this.props.isMandatory || false}
                                options={this.props.options || []}
                                width={'100%'} 
                            /> :
                            <SearchSelectField
                                id={this.props?.id}
                                showMandatory={this.props.showMandatory}
                                detail_selected={this.props?.detail_selected || null}
                                valueSelected={this.props?.valueSelected}
                                searchText={this.props?.searchText}
                                isMandatory={this.props.isMandatory || false}
                                options={this.props.options || []}
                                width={'100%'} 
                                multiple={this.props?.multiple}
                                searchSelect={this.props?.searchSelect}
                            />
                        }
                    </MDBCol>
                    <MDBCol md={1} className="d-flex actions">
                        {
                            (this.props?.showRemove) ? <MDBIcon icon="trash-alt" className="float-right" onClick={this.props.removeCallback} /> : null
                        }
                        {
                            (this.props?.showAdd && !this.props?.disabled) ? <MDBIcon icon="plus-circle" className="float-right ml5" onClick={this.props.addCallback} /> : null
                        }
                    </MDBCol>
                </MDBRow>
            </MDBRow>
        );
    }
}