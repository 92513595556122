import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from '../BasicTextField/BasicTextField';
import SelectField from '../SelectField/SelectField';
import BasicLabel from '../BasicLabel/BasicLabel';
import './InlineSelectField.scss';

export default class InlineSelectField extends React.Component {
    render() {
        let error = this.props?.fieldValue?.length > this.props.limit || (this.props.showMandatory && (this.props?.fieldValue === "" ||this.props?.fieldValue === null || !this?.props?.selectValue)) ||
            this.props.fieldValid === "" || this.props.fieldValid === null || false;
        let mandatoryWarning = "Please complete this mandatory field";
        let showAsLabel = this.props.showAsLabel || false;
        return (
            <MDBContainer className={this.props?.textFieldposition === "end" ? "InlineSelectFieldContainer endPosition" : "InlineSelectFieldContainer"}>
                <MDBRow>
                    {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                        isMandatory={this.props?.isMandatory || false} />}
                </MDBRow>
                <MDBRow className="g-0">
                    <MDBCol md={this.props?.textFieldLength || 8} className="TextField">
                        <BasicTextField id={this.props?.id}
                            className="TextField1"
                            limit={this.props.limit}
                            fieldValid={this.props.fieldValid === false ? true : false}
                            limitWarning={this.props.limitWarning}
                            inValidInput={this.props.inValidInput}
                            value={this.props?.fieldValue}
                            onChange={this.props?.onFieldChange}
                            disabled={this.props?.disabled || false}
                            showAsLabel={this.props.showAsLabel || false}
                            placeholder={this.props?.placeholder || ""} />
                    </MDBCol>
                    <MDBCol md={this.props?.selectFieldLength || 4} className="SelectField">
                        <SelectField id={this.props?.id + this.props?.id}
                            value={this.props?.selectValue || ""}
                            options={this.props?.options || []}
                            showAsLabel={this.props.showAsLabel || false}
                            disabled={this.props?.disabled || false}
                            onChange={this.props?.onSelectChange} />
                    </MDBCol>
                    {error && !showAsLabel && <MDBCol md={12}><div className="errorText">{((this.props.showMandatory && (!this.props?.fieldValue || !this.props?.selectValue)) ? mandatoryWarning :
                    this.props.fieldValid ? "" : this.props.limitWarning) || ""}</div></MDBCol> }
                </MDBRow>

            </MDBContainer>
        );
    }
}

