import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import BasicCheckbox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-time-picker/assets/index.css';
import ScripterService from '../../services/service';
import * as Constants from '../../constants/constants';

import './VaultStorageReport.scss';
import { withUserContext } from '../../contexts/UserContext';

export default withUserContext(class VaultStorageReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            statusOptions: [],
            isFetchingAuditionStatus: false,
            postInitiated: false,
            isPosting: false,
            boxNumber: false,
            project: false,
            projectList: [],
            selectedProject: null
        }
    }
    handleCheck = (e) => {
        let isChecked = e.target.checked;
        if (isChecked) {
            if (e.target.id == "BoxNumber") {
                this.setState({ boxNumber: true })
            }
            else {
                this.setState({ project: true })
            }

        }
        else {
            if (e.target.id == "BoxNumber") {
                this.setState({ boxNumber: false })
            }
            else {
                this.setState({ project: false })
            }
        }
    }
    componentDidMount () {
        this.getEntity('projectList', 'PROJECT', '');
    }

    getEntity = (entity, entitytype, searchString) => {
        let loading = (entitytype === 'PROJECT') ? 'isProjectListLoading' : 'loading';
        this.setState({ [loading]: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`,
            this.props.userContext.active_tenant.tenant_id, this.props.userContext.active_tenant.division_id)
            .then(response => {
                let formattedList = [];
                console.log("Project Response: ",response);
                if (entitytype === 'PROJECT') {
                    formattedList = response?.data?.map(item => ({ value: item.project_id, label: item.project_title }));
                } 
                this.setState({ projectList: formattedList, [loading]: false });
                console.log("Project Data: ", this.state.projectList);
            },
                (err) => {
                    this.setState({ [loading]: false });
                    console.log("Error in fetching entities:", err)
                })
    }


    render() {

        let isLoading = this.state.isFetchingVaultStorageReportDetails && this.state.isFetchingVaultStorageReportTypes;
        return (
            <>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBContainer className="VaultStorageReportContainer">
                            <MDBRow>
                                <BasicLabel type={"fieldHeading"} text={"Search By"} />
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={1} className="basicCheck">
                                    <BasicCheckbox
                                        id={"BoxNumber"}
                                        //label="Custom"
                                        checked={this.state.boxNumber}
                                        onChange={(e) => this.handleCheck(e)}
                                    />

                                </MDBCol>
                                <MDBCol md={4} className="searchSelectcontent">
                                    <BasicTextField
                                        placeholder={"Box Number"}
                                        value={this.state.auditionDetails?.talent_name || ""}
                                        disabled={!this.state.boxNumber}
                                        onChange={(e) => this.handleChange('Report_name', e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={4}>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={1} className="basicCheck">
                                    <BasicCheckbox
                                        id={"Project"}
                                        //label="Custom"
                                        checked={this.state.project}
                                        onChange={(e) => this.handleCheck(e)}
                                    />
                                </MDBCol>
                                <MDBCol md={5}>
                                    <SelectField
                                        id={"project"}
                                        className={"Project"}
                                        placeHolderText={"-Select Project Title-"}
                                        disabled={!this.state.project}
                                        options={this.state.projectList}
                                        value={this.state.selectedProject || ""}
                                        onChange={(e) => this.setState({ selectedProject: e.target.value }) || ""}
                                    />
                                </MDBCol>
                                <MDBCol md={6}>

                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="p-2">
                                <MDBCol md={8} >
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        type="inline"
                                        variant="outlined"
                                        text={"Cancel"}
                                        onClick={this.props?.onClose}
                                        disabled={false}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        className="btnwidth"
                                        variant="contained"
                                        type="inline"
                                        text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate"}
                                        disabled={false}
                                        onClick={this.GenerateReportDetails}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    )}
            </>
        )
    }
}
);