import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import BasicButton from './BasicButton/BasicButton';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid grey',
        borderRadius: '8px',
        boxShadow: theme.shadows[5]
    },
    label: {
        fontSize: '0.75rem'
    }
}));

export default function FileModal(props) {
    const classes = useStyles();
    const [file, setFileNAme] = useState(null);
    const uploadFile = file => {
       setFileNAme(file)
    }
    const handleSubmit = () => {
        props?.handleFileUpload(file)
        props?.handleClose()
    }
    useEffect(() => {
        setFileNAme(props?.file)
      }, []);
    return (
        <div className="BasicFileModalContainer">
            <Modal
                open={props?.open ? props?.open : false}
                onClose={props?.handleClose}
                className={classes.modal} 
            >
                <div className={`${classes.paper} ${props?.className || ""}`}>
                    <div className="MessageContent">
                        <div className="file-div">
                            <BasicButton
                                id={props?.id}
                                className="file-button"
                                variant="outlined"
                                text={"Browse"}
                                uploadFile={(file) => uploadFile(file)}
                                inputType="file"
                                disabled={false}
                                accept={"image/*"}
                            />
                            <span className="file-name">{file?.name || ''}</span>
                        </div>
                        <div className="ButtonDiv">
                            <div className="FloatRight">
                                <BasicButton
                                    variant="outlined"
                                    type="inline"
                                    text={'Cancel'}
                                    onClick={props?.handleClose}
                                    disabled={false} />
                            </div>
                            <div className="FloatRight">
                                <BasicButton
                                    variant="contained"
                                    type="inline"
                                    text={'Ok'}
                                    onClick={() => handleSubmit()}
                                    disabled={false} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
