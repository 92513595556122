export const projectDetails = {
    "project_id": null,
    "project_title": null,
    "poster_image": null,
    "sap_dev": null,
    "sap_prod": null,
    "is_confidential": 0,
    "akas": [],
    "genres":[],
    "log_line":null,
    "project_type_id": null,
    "project_type_name":null,
    "project_status_id": null,
    "project_status_name":null,
    "project_genre_id":null,
    "production_start_date": null,
    "production_start_date_tbd": 0,
    "wrap_date": null,
    "wrap_date_tbd": 0,
    "release_date": null,
    "release_date_tbd": 0,
    "rights_lapse_date": null,
    "rights_lapse_date_tbd": 0,
    "distribution_company_id":null,
    "distribution_company_name":null,
    "material_location":null,
    "vault_information":null,
    "project_notes":null,
    "source_material":[],
    "division_id": null,
    "is_division_change": 0,
    "executives":[],
    "talents":[],
    "files":[]
}

export const sourceMaterialList = {
    "project_source_material_id": null,
    "type_id": null,
    "type_name": null,
    "title": null,
    "author_id": null,
    "author": null,
    "talent_group_id": null,
    "publisher": null,
    "date": null,
    "copyright_date": null,
    "is_delete": 0
}

export const akaList =
{
    "project_aka_id": null,
    "aka": null,
    "index": 1,
    "is_delete": 0
}

export const genreList =
{
    "genre_id": null,
    "project_genre_id": null,
    "index": 1,
    "is_delete": 0
}

export const executiveList =
{
    "project_executive_id": null,
    "executive_id": null,
    "executive_name": null,
    "start_date": null,
    "end_date": null,
    "index": 1,
    "is_delete": 0
}

export const talentList =
{
    "project_talent_id": null,
    "talent_id": null,
    "talent_name": null,
    "talent_group_id": null,
    "index": 1,
    "is_delete": 0
}

export const filesList =
{
    "project_file_id": null,
    "file_name": null,
    "index": 1
}

export const filePayload = {
    "tenantName": null,
    "submissionId": null,
    "projectId": null,
    "dealId": null,
    "weekendReadId": null,
    "module": null,
    "subModule": null,
    "fileName": null,
    isPrivate: 0
}

export const talentPrefixes = [
    { value: "Dr", label: "Dr" },
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" }
]

export const talentSuffixes = [
    { value: "Esq.", label: "Esq." },
    { value: "I", label: "I" },
    { value: "II", label: "II" },
    { value: "III", label: "III" },
    { value: "IV", label: "IV" },
    { value: "V", label: "V" },
    { value: "Sr.", label: "Sr." },
    { value: "Jr.", label: "Jr." }
]