
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { MDBModal, MDBModalBody, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';
import CancelIcon from '@material-ui/icons/Cancel';
import ProjectReport from '../../../Reports/ProjectReport/ProjectReport';
import VaultStorageReport from '../../../Reports/VaultStorageReport/VaultStorageReport';
import WritingDueReport from '../../../Reports/WritingDueReport/WritingDueReport';
import DealInformationReport from '../../../Reports/DealInformationReport/DealInformationReport';
import ProjectDetailReport from '../../../Reports/ProjectDetailReport/ProjectDetailReport';
import LoglineReport from '../../../Reports/LoglineReport/LoglineReport';
import DetailedSubmissionReport from '../../../Reports/DetailedSubmissionReport/DetailedSubmissionReport';
import { useStyles } from './Config';
import GenerateReport from '../../../components/Reports/GenerateReport/GenerateReport';
import ProjectOfficerReport from '../../../components/Reports/GenerateReport/projectofficer/ProjectOfficerReport';
import MessageModal from '../../../components/SharedComponents/MessageModal';
import ProjectContainer from '../../../components/Views/Project/Dialog/Project/ProjectContainer'
import Submission from '../../../components/Views/Submissions/Dialogs/Submission';
import ReviseCoverage from '../../../components/Views/Submissions/Dialogs/ReviseCoverage';
import ReviseSubmission from '../../../components/Views/Submissions/Dialogs/ReviseSubmission';
import './Modal.scss';
import Executive from '../../../components/Views/Project/Dialog/Executive/Executive'
import WGAType from '../../../components/Views/Project/Dialog/WGAType/WGAType';
import AssignCoverage from '../../../components/Views/Submissions/Dialogs/AssignCoverage/AssignCoverage';
import ExportPdf from '../../../components/Navigation/AdvancedSearch/ExportPdf';
import ExportExcel from '../../../components/Navigation/AdvancedSearch/ExportExcel';
import WeekendReadDialog from '../../../components/Views/WeekendRead/Dialogs/WeekendRead';
import ManageDeal from '../../../components/ManageDeals/ManageDeal'
import Editdealmemo from '../../../components/Views/Deals/Dialogs/Editdealmemo';
import NewAmendment from '../../../components/Views/Deals/Dialogs/NewAmendment'
import WGAContainer from '../../../components/Views/Project/Dialog/WGA/WGAContainer';
import ManageLookup from '../../../components/Manage Lookup/ManageLookup';
import NewDeal from '../../../components/Views/Deals/Dialogs/NewDeal';
import SubmissionEditElementsContainer from '../../../components/Views/Submissions/Dialogs/SubmissionEditElementsContainer';
import GenerateScript from '../../../components/Views/GenerateDelivaryNotice/GenerateScript';
import Talent from '../../../components/Views/Project/Dialog/Talent/Talent'

class MainViewModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            fieldChanged: false,
            showError: false,
            showSuccess: false,
            showFail: false,
            isPosting: false,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            reviseSubmissionMessage: "The submission has been returned for revision. The creating user has been notified.",
            reviseCoverageMessage: "The submission has been returned for revision. The assigned Story Analyst has been notified.",
            saveFlag: true,
            wgaTypeName: "",
        }
        this.submitButtonRef = React.createRef();
    }
    setPostFlag = (value) => {
        this.setState({ isPosting: value });
    }

    onClose = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        } else {
            // this.closPopup()
            this.props?.handleClose();
        }
    }
    closPopup = () => {
        if (this.state.showSuccess || this.state.showError) {
            this.props?.handleClose();
        }
        if (this.state.refreshPageValue) {
            this.props?.handlePageRefresh(this.state.refreshPageValue);
        }
        this.setState({ showError: false, showFail: false, showSuccess: false });
    }
    deletePost = (save_flag) => {
        this.setState({ saveFlag: save_flag });
    }
    handleSubmit = () => {
        //this.submitButtonRef.current.click();
        this.props?.handleSubmit();
    }
    fieldChanged = (value, mode = "error", refreshValue = null, operation = null) => {
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value });
            case "error": return this.setState({ showError: value });
            case "success": return this.setState({ showSuccess: value, refreshPageValue: refreshValue, mode: operation });
            case "fail": return this.setState({ showFail: value });
        }
    }
    wgaNameChanged = (value) => {
        this.setState({ wgaTypeName: value })
    }
    getModalContent = () => {
        switch (this.props?.modalName) {
            case 'Project': return <ProjectContainer
                mode={this.props?.mode}
                primaryButtonText={"Create"}
                secondaryButtonText={"Cancel"}
                onClose={this.onClose}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                projectId={this.props?.projectId}
                toggleRefreshPage={this.props.toggleRefreshPage}
                navFuns={this.props?.navFuns}
            />
            case 'WGA': return <WGAContainer
                mode={this.props?.mode}
                primaryButtonText={this.props?.mode === "New" ? "Create" : "Save"}
                secondaryButtonText={"Cancel"}
                onClose={this.onClose}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                wgaNameChanged={this.wgaNameChanged}
                wgaId={this.props.wgaId || null}
                projectId={this.props?.projectId}
                toggleRefreshPage={this.props.toggleRefreshPage}
                navFuns={this.props?.navFuns}
                divisionId={this.props?.divisionId}
            />
            case 'Submission': return <Submission 
                handleClose={this.props?.handleClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                onClose={this.onClose}
                setPostFlag={this.setPostFlag}
                submissionId={this.props.submissionId}
                toggleRefreshPage={this.props.toggleRefreshPage}
                navFuns={this.props?.navFuns}
            />
            case 'Revise Coverage': return <ReviseCoverage
                handleClose={this.props?.handleClose}
                analyst={this.props?.analyst}
                submissionId={this.props?.submissionId}
                coverageId={this.props?.coverageId}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                divisionId={this.props?.divisionId}
            />
            case 'Revise Submission': return <ReviseSubmission
                submissionId={this.props?.submissionId}
                executive={this.props?.executive}
                handleClose={this.props?.handleClose}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                divisionId={this.props?.divisionId}
            />
            case 'Talent': return <Talent
                mode={this.props.mode}
                primaryButtonText={this.props?.mode === "Add" ? "Add" : "Save"}
                secondaryButtonText={"Cancel"}
                onClose={this.onClose}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                projectId={this.props?.projectId}
                toggleRefreshPage={this.props.toggleRefreshPage}
                talentDetails={this.props.talentDetails}
                handleShowTalentPopup={this.props?.handleShowTalentPopup}
                navFuns={this.props.navFuns}
                divisionId={this.props?.divisionId}
            />
            case 'Executive': return <Executive
                mode={this.props.mode}
                primaryButtonText={this.props?.mode === "Add" ? "Add" : "Save"}
                secondaryButtonText={"Cancel"}
                executiveId={this.props?.executiveId || null}
                onClose={this.onClose}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                projectId={this.props?.projectId}
                executiveDetails={this.props?.executiveDetails || {}}
                toggleRefreshPage={this.props.toggleRefreshPage}
                divisionId={this.props?.divisionId}
                updateParentDetails={this.props?.updateParentDetails}
            />
            case 'Manage Deal': return <NewDeal
                {...this.props}
                onClose={this.onClose}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                toggleRefreshPage={this.props.toggleRefreshPage}
            />
            case 'New Deal': return <NewDeal
                {...this.props}
                showNewDealProject={this.props.showNewDealProject}
                onClose={this.onClose}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                toggleRefreshPage={this.props.toggleRefreshPage}
            />
            case 'Select WGA': return <WGAType />
            case 'Assign Coverage': return <AssignCoverage
                submissionId={this.props?.submissionId}
                coverageDueDate={this.props?.coverageDueDate}
                coverageTypeId={this.props?.coverageTypeId}
                compareToName={this.props?.compareToName}
                submissionCoverageType={this.props?.submissionCoverageType}
                handleClose={this.props?.handleClose}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                divisionId={this.props?.divisionId}
            />
            case 'New Amendment': 
            case 'Edit Amendment':
                return <NewDeal
                    {...this.props}
                    onClose={this.onClose}
                    setPostFlag={this.setPostFlag}
                    fieldChanged={this.fieldChanged}
                />
            case 'generate report name': return <GenerateReport onClose={this.onClose}/>
            case 'New Weekend Read': return <WeekendReadDialog
                {...this.props}
                onClose={this.onClose}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                navFuns={this.props.navFuns}
            />
            case 'Project Report': return <ProjectReport />
            case 'Writing Due Report': return <WritingDueReport />
            case 'Deal Information Report': return <DealInformationReport />
            // case 'Script Delivery Notice Report': return <GenerateScript  onClose={this.onClose}/>
            case 'Project Detail Report': return <ProjectDetailReport />
            case 'Logline Report': return <LoglineReport />
            case 'Detailed Submission Report': return <DetailedSubmissionReport />
            case 'Vault Storage Report': return <VaultStorageReport />
            case 'Project Officer Report': return <ProjectOfficerReport />
            case 'Edit deal memo': return <Editdealmemo />
            case 'Manage Lookups': return <ManageLookup
                onClose={this.onClose}
                isFeature={false}
                fieldChanged={this.fieldChanged}
                primaryButtonText={'Save & Close'}
                secondaryButtonText={'Cancel'}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                id={this.props?.id}
                episode_id={this.props?.episode_id}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'Edit Elements': return <SubmissionEditElementsContainer
                mode={this.props?.mode}
                handleClose={this.props?.handleClose}
                submissionId={this.props.submissionId}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                navFuns={this.props.navFuns}
            />

            case 'Generate Script': return <GenerateScript 
                onClose={this.onClose}
                scriptDetails={this.props?.scriptDetails}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                divisionId={this.props?.divisionId}
            />

            case 'Export Report to PDF': return <ExportPdf 
                handleClose={this.props?.handleClose}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                handleGeneratePdf={this.props?.handleGeneratePdf}
                reportName={this.props?.reportName}
                isGeneratingPdf={this.props?.isGeneratingPdf}
            />

            case 'Export Report to Excel': return <ExportExcel
                handleClose={this.props?.handleClose}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                handleGenerateExcel={this.props?.handleGenerateExcel}
                reportName={this.props?.reportName}
                isGeneratingExcel={this.props?.isGeneratingExcel}
            />
        }
    }


    getClasses = () => {
        const { classes } = this.props;
        switch (this.props?.modalName) {
            case 'New Set': return classes.AddSetContainer;
            case 'New Project': return classes.AddProjectContainer;
            case 'WGA': return classes.WGAFormContainer;
            case 'Project': return classes.AddProjectContainer;
            case 'New Deal': return this.props?.mode === "New" ? null : classes.AddProjectContainer;
            default: return '';
        }
    }
    getClassesForModal = () => {
        const { classes } = this.props;
        switch (this.props?.modalName) {
            case 'New Set': return 'sm';
            case 'Project': return 'lg'
            case 'Submission': return 'lg';
            case 'generate report name': return 'sm';
            case 'Talent': return 'sm';
            case 'Executive': return 'sm';
            case 'Select WGA': return 'sm';
            case 'Assign Coverage': return 'sm';
            case 'Export Report to PDF': return 'sm';
            case 'Export Report to Excel': return 'sm';
            case 'New Weekend Read': return 'lg'
            case 'Manage Deal': return 'lg';
            case 'New Amendment': 
            case 'Edit Amendment':
                return 'lg';
            case 'New Deal': return 'lg';
            case 'WGA': return 'lg';
            case 'Edit Elements': return 'sm';
            case 'Generate Script': return 'lg';
            case 'Script Delivery Notice Report': return 'lg';
            case 'Revise Submission': return 'sm';
            case 'Manage Lookups': return 'sm';
            default: return 'md';
        }
    }

    render() {
        const { classes } = this.props;
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            (this.state.showSuccess && (this.props.mode === "Edit")) ? this.state.saveEditMessage :
                (this.state.showSuccess && this.state.mode === 'notice-report') ? this.state.saveEditMessage :
                    (this.state.showSuccess && ((this.props.mode === "New") || (this.props.mode === "Add"))) ? `New ${this.props?.headerText} has been created!` :
                        (this.state.showSuccess && (this.props.mode === "Both")) ? this.state.saveEditMessage :
                            (this.state.showSuccess && (this.props.messageMode === "revise_submission")) ? this.state.reviseSubmissionMessage :
                                (this.state.showSuccess && (this.props.messageMode === "revise_coverage")) ? this.state.reviseCoverageMessage :
                                    (this.state.showSuccess && (this.props?.modalName === "Assign Coverage")) ? this.props?.coverageSuccessMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveTitle : "";
        return (
            <div className="MainViewModalContainer">
                {/* <Modal
                    open={this.props?.open ? this.props?.open : false}
                    onClose={this.onClose}
                    className={classes.modal}

                > */}
                <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1'
                    show={this.props?.open ? this.props?.open : false}>
                    <MDBModalDialog size={this.getClassesForModal()} className={this.getClasses()}>
                        <MDBModalBody>
                            <MDBModalContent>
                                <div>
                                    <MDBContainer fluid className="padding-hor-12">
                                        <MessageModal
                                            open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                                            title={comfirmModalTitle}
                                            message={message}
                                            hideCancel={this.state.showFail || this.state.showSuccess || false}
                                            primaryButtonText={"OK"}
                                            secondaryButtonText={"Cancel"}
                                            onConfirm={() => this.closPopup()}
                                            handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false })} />
                                        <MDBRow className="ModalContent">
                                            <MDBCol>
                                                <MDBRow className="ModalHeader" id={"modal_header"}>
                                                    <MDBCol md={5} className="HeaderText" >{(this.props.mode === 'Both' ? "" : this.props.mode) + " " + ((this.state.wgaTypeName && this.state.wgaTypeName.length > 0) ? `${this.props?.headerText} - ${this.state.wgaTypeName}` : this.props?.headerText)}</MDBCol>
                                                    <MDBCol md={6} className="HeaderText" ></MDBCol>
                                                    <MDBCol md={1} className="closeIcon" onClick={this.onClose} ><CancelIcon style={{ fill: "white" }} /></MDBCol>
                                                </MDBRow>
                                                <MDBRow className="ModalBody">
                                                    <MDBCol md={12} className="Content">
                                                        {this.getModalContent()}
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="footer">

                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </div>
                                {/* </Modal> */}
                            </MDBModalContent>
                        </MDBModalBody>
                    </MDBModalDialog>
                </MDBModal>
            </div>
        );
    }
}

export default withStyles(useStyles)(MainViewModalComponent);
