import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-time-picker/assets/index.css';
import './GenerateReport.scss';
import { withUserContext } from '../../../contexts/UserContext';



export default withUserContext(class GeneralReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           
            statusOptions: [],
            isFetchingAuditionStatus: false,
            postInitiated: false,
            isPosting: false,

        }
    }
   
    
    

    GenerateReportDetails = () => {
        this.setState({ postInitiated: true });

        if (this.isValidFields()) {
            let time = this.state.auditionDetails.audition_time;
            let audition_time = undefined;
            if (time) {
                audition_time = this.handleTime(time)
            }
            console.log(this.state.auditionDetails + "  " + audition_time);
            this.setState({ isPosting: true });
            scripterService.postDataParams(`${Constants.scripterServiceBaseUrl}/audition`, { ...this.state.auditionDetails, audition_time }
                , this.props.userContext?.active_tenant?.tenant_id)
                .then((response) => {
                    this.setState({ isPosting: false });
                    this.props?.handleSubmit("success", response?.data?.[0]?.pse_audition_id);
                    console.log(response);
                    this.props.fieldChanged(false, "error");
                    this.props.fieldChanged(true, "success");
                }, (err) => {
                    this.props?.handleSubmit("fail");
                    this.props.fieldChanged(true, "fail");
                    this.setState({ isPosting: false });
                })
        } else {
            alert("Post fail")
        }

    }
   
    render() {

        let isLoading = this.state.isFetchingEpisodeDetails && this.state.isFetchingEpisodeTypes;
        return (
            <>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                    <MDBContainer className="NewGenerateReportContainer">
                        <MDBRow className="searchSelect">
                            <MDBCol md={12} className="searchSelectcontent">
                                <BasicTextField
                                    label={"File Export Name"}
                                    placeholder={" [Default Export Name]"}
                                    value={this.state.auditionDetails?.talent_name || ""}                                  
                                />
                                {/* <BasicLabel text={"File Export Name"} />
                        <SearchSelectField
                            id={"roles-select"}
                            multiple={true}
                            value={this.state.selectedPerformerValues}
                            options={this.state.performerOptions} width={300}
                            // onChange={(e, value) => this.onChangeCallBack('selectedPerformerValues', value)}
                        /> */}
                                {/* <BasicLabel
                            // type={"fieldHeading"}
                            text={"Performer"}
                            isMandatory={"true"}
                        />
                        <SearchSelectField
                            className="SearchSelectWidth"
                            id={"audition-select"}
                        /> */}
                            </MDBCol>
                            
                        </MDBRow>
                        
                        
                   
                        
                        <MDBRow className="p-2">
                            <MDBCol md={8} >
                            </MDBCol>
                           
                                
                            <MDBCol md={2}>
                                <BasicButton
                                    type="inline"
                                    variant="outlined"
                                    text={"Cancel"}
                                    onClick={this.props?.onClose}
                                    disabled={false}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                            <BasicButton
                                    className="btnwidth"
                                    variant="contained"
                                    type="inline"
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate"}
                                    disabled={false}
                                    onClick={this.GenerateReportDetails}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}
            </>
        )
    }
}
);