import React from 'react';
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import { projectExecutivesConfig } from './Config';
import TableComponent from '../../../SharedComponents/Table';
import MessageModal from '../../../SharedComponents/MessageModal';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';
import Loader from '../../../../Common/Loader/Loader';
import { formatStartAndEndDate } from '../../../../Common/Helper';

export default withUserContext(class ExecutivesTabContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: projectExecutivesConfig,
            executiveList: [],
            isFetchingExecutiveList: false,
            isDeletingExecutive: false,
            executiveRefreshPage: false,
            fetchExecutiveList: () => this.fetchExecutiveList()
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.refreshPage?.value !== state.executiveRefreshPage && props.refreshPage?.modal === 'executive') {  
            if(props.refreshPage?.value) {
                state.fetchExecutiveList();
                props.toggleRefreshPage(props.refreshPage?.modal) 
            }       
            return {
                executiveRefreshPage: props.refreshPage?.value || false
            }
        }
        return null;
    }

    deleteExecutive = () => {
        this.setState({ isDeletingExecutive: true, showDeleteConfirmationModal: false })
        this.state.deletedCallbackItem.is_delete = 1;
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + "/projectExecutives", this.state.deletedCallbackItem,
            this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(
                (response) => {
                    console.log("response delete", response)
                    this.setState({ isDeletingExecutive: false })
                    if(response?.data?.error) {
                        this.props.manageMessageModal('Fail', true, 'delete')
                    } else {
                        this.props.manageMessageModal('Success', true, 'delete');
                        this.fetchExecutiveList();
                    }
                },
                (err) => {
                    console.log("error", err);
                    this.setState({ isDeletingExecutive: false })
                    this.props.manageMessageModal('Fail', true, 'delete')
                }
            );
    }

    componentDidMount() {
        this.fetchExecutiveList();
    }

    fetchExecutiveList = () => {
        this.setState({ isFetchingExecutiveList: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/projectEntity?projectId=${this.props.projectId}&entity=EXECUTIVES`,
            this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(response => {
                let formattedList = response.data?.map(item => {
                    item.deptTitle = `${item.department || ''}${item.title ? "," : ""} ${item.title || ''}`
                    item.date = formatStartAndEndDate(item?.start_date || null, item?.end_date || null)
                    return item;
                })
                this.setState({ isFetchingExecutiveList: false, executiveList: formattedList });
            },
                (err) => {
                    this.setState({ isFetchingExecutiveList: false })
                })
    }

    handleDeleteConfirmation = (callbackItem) => {
        this.setState({ 
            showDeleteConfirmationModal: true,
            deletedCallbackItem: callbackItem
        });
    }

    render() {
        let permissions = this.props?.userContext?.active_tenant?.permissions;
        return (
            <div className="TabDetailsContainer">
                <MDBCard>
                <Loader isOpen={this.state.isDeletingExecutive || false} text={"Deleting..."} />
                <MessageModal
                    open={this.state.showDeleteConfirmationModal}
                    title={"Delete record"}
                    message={"Would you like to delete saved record?"}
                    primaryButtonText={"Delete"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => this.deleteExecutive()}
                    handleClose={(e) => this.setState({ showDeleteConfirmationModal: false })} 
                />
                    <MDBCardBody className={"card-body-height"}>
                        <MDBCardText className="tab-table-content executive-text-highlight" id="actionIconExcecutive">
                            <div className={`${!permissions?.canEditTalentRepAgencies ? 'hideExecutiveActions' : ''}`}>
                                <TableComponent
                                    list={this.state?.executiveList || []}
                                    className={"coveredTalent"}
                                    isLoading={this.state.isFetchingExecutiveList || false}
                                    config={this.state.config}
                                    canDelete={true}
                                    editCallback={this.props.editCallback}
                                    removeHeader={false}
                                    tableMode={"light"}
                                    // handleDelCallBack={(id, item) => this.deleteExecutive(item)}
                                    handleDelCallBack={(id, item) => this.handleDeleteConfirmation(item)}
                                />
                                </div>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </div>
        )
    }
})