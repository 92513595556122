import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import './TabComponent.scss';

class TabComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0
        }
    }

    a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tabValue !== state.tabValue) {
            return {
                tabValue: props.tabValue
            }
        }
        return null
    }

    render() {
        return (
            <MDBContainer fluid className="TabComponentContainer">
                <MDBRow>
                    <MDBCol md={1}></MDBCol>
                    <MDBCol md={11} className="TabContainer">
                        <Tabs
                            orientation="vertical"
                            variant="standard"
                            value={this.state.tabValue ? this.state.tabValue : 0}
                            TabIndicatorProps={{
                                style: {
                                    display: "none",
                                },
                            }}
                            onChange={this.props.handleTabChange}
                        >
                            {
                                this.props?.tabList?.map((item, index) => <Tab key={index} value={this.props?.headerText === "Reports" ? item.index : index} disabled={item.index === null ? true : false} label={item.label} {...this.a11yProps(item.index)} />)
                            }
                        </Tabs>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}

export default TabComponent;