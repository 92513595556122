import React from 'react';
import { MDBIcon } from 'mdbreact';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import './shared.scss';
import './SearchFieldWithAddValue.scss'

const filter = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
});

const SearchFieldWithAddValue = (props) => {
    let error = (props.showMandatory && (!props.searchSelect ? !props?.value?.value : props?.detail_selected?.length > 0 ? false : true)) || false;
    return (
        <>
            {!props?.searchSelect ?
                <div className="SearchSelectFieldAddValueContainer">
                    <Autocomplete
                        id={props?.id}
                        disabled={props?.disabled ? props?.disabled : false}
                        options={props?.options ? props?.options : []}
                        value={props?.value ? props?.options.find(item => item.value === props?.value) !== undefined ? props?.options.find(item => item.value === props?.value).text : null : null}
                        //value={props?.value !== null && props?.value.length > 0 ? props?.options.find(item => item.value === props?.value).text : null}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option ? option : "";
                            } else
                                if (option?.inputValue) {
                                    return option.inputValue;
                                } else {
                                    return option.text ? option.text : "";
                                }
                        }}
                        getOptionSelected={(option, value) => option.value === value.value}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            let checkOption = options.find(item => item[props?.type] === params?.inputValue)
                            if (params?.inputValue !== '' && checkOption === undefined) {
                                filtered.push({
                                    value: params.inputValue,
                                    text: `Add '${params.inputValue}'`,
                                });
                            }

                            return filtered;
                        }}
                        style={{ width: props?.width }}
                        popupIcon={<MDBIcon icon="chevron-right" size='sm' />}
                        onChange={props?.onChange}
                        renderInput={(params) => <TextField
                            //className={"textFieldContainer"}
                            placeholder="Add Or Select"
                            {...params} variant="outlined"
                            style={{ border: '1px solid', }}
                        />}
                    />
                </div> :
                <div className="SearchSelectFieldAddValueContainer">
                    <Autocomplete
                        className={`${error ? "error" : ""}`}
                        id={props?.id}
                        disabled={props?.disabled ? props?.disabled : false}
                        options={props?.options ? props?.options : []}
                        getOptionDisabled={(option) => option.is_active === 0 ? true : false}
                        value={(props?.detail_selected ? props?.detail_selected : null)}
                        getOptionLabel={(option) => option.text ? option.text : ''}
                        getOptionSelected={(option, value) => option.value === value}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            let checkOption = options.find(item => item[props?.type] === params?.inputValue)
                            if (params?.inputValue !== '' && checkOption === undefined) {
                                filtered.push({
                                    value: params.inputValue,
                                    text: `${props?.primaryAddText || 'Add'} '${params.inputValue}'`,
                                });
                                if (props?.showMultipleAddOption) {
                                    filtered.push({
                                        value: params.inputValue,
                                        text: `${props?.secondaryAddText} '${params.inputValue}'`,
                                    });
                                }
                            }

                            return filtered;
                        }}
                        style={{ width: props.width ? props.width : '200px' }}
                        popupIcon={(props.icon === 'hide') ? '' : <MDBIcon icon="chevron-right" size='sm' />}
                        onChange={props?.valueSelected}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="outlined"
                                required={props?.isMandatory || false}
                                placeholder={props?.placeholder}
                                textFieldId={props?.textFieldId}
                                onChange={props?.searchText}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    endAdornment: (
                                        <React.Fragment>
                                            {(props?.detail_selected === undefined && props?.loading) ? <CircularProgress color="inherit" size={15} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        }
                    />
                </div>
            }
        </>
    );
}

export default SearchFieldWithAddValue;