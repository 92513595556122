import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../../SharedComponents/SelectField/SelectField';
import SearchFieldWithAddValue from "../../../SharedComponents/SearchFieldWithAddValue"
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import messages from '../../../../Common/Messages.json';
import { limit } from './Config';

export default class EditElements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <MDBContainer fluid className="editElementContainer p-0">
                <MDBRow className="g-0 rowDivider">
                    <MDBCol md={4} id="hh7">
                        {this.props?.index === 0 &&
                            <BasicLabel
                                type={"subHeading"}
                                text={"Name"}
                                isMandatory={this.props?.isMandatory || false}
                            />
                        }

                        <SearchFieldWithAddValue
                            id={"elemTal" +this.props?.element?.index}                            
                            options={this.props?.element?.talentOptions || []}
                            width={"100%"}
                            isMandatory={true}
                            showMandatory={this.props.postInitiated || false}
                            detail_selected={this.props.element?.talent_selected}
                            valueSelected={(e,value)=>this.props?.selectedTalent(value,this.props?.element)}
                            searchText={(event)=>this.props?.getTalents(event,this?.props?.element)}
                            searchSelect={true}
                        />
                    </MDBCol>
                    <MDBCol md={4} id="editSelect">
                        {this.props?.index === 0 &&
                            <BasicLabel
                                type={"subHeading"}
                                text={"Role"}
                               // isMandatory={this.props?.isMandatory || false}
                            />
                        }
                        <SelectField
                            className="editselectField"
                            id={"role"}
                           // showMandatory={this.props.postInitiated || false}
                            onChange={(e) => this.props.onChange("element_role_id", e.target.value || null, this.props.element, "elements")}
                            value={this.props.element?.element_role_id}
                            options={this.props.roles || []}
                        />
                    </MDBCol>
                    <MDBCol md={4} id="editSelect">
                        {this.props?.index === 0 &&
                            <BasicLabel
                                type={"subHeading"}
                                text={"Status"}
                                //isMandatory={this.props?.isMandatory || false}
                            />
                        }
                        <SelectField
                            className="editselectField2"
                            id={"status"}
                            //showMandatory={this.props.postInitiated || false}
                            onChange={(e) => this.props.onChange("element_status_id", e.target.value || null, this.props.element, "elements")}
                            value={this.props.element?.element_status_id}
                            options={this.props.statuses || []}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}