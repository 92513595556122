import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import InlineSelectField from '../../components/SharedComponents/InlineSelectField/InlineSelectField';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicCheckbox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import ActionButtons from '../../components/SharedComponents/ActionButtons/BasicActionButtons';
import scripterService from '../../services/service';
import * as Constants from '../../constants/constants';
import { validateNumbersOnly, validatePlusCharacter, validateIntlNumber, validateUSNumber, validatePhoneNumber } from '../Helper';
import './PhoneComponent.scss';
import { withUserContext } from '../../contexts/UserContext';

class PhoneComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phoneTypes: [],
            mandatoryWarning: "Please complete this mandatory field"
        }
    }

    componentDidMount() {
        this.getPhoneTypes();
    }

    getPhoneTypes = () => {
        scripterService.getData(Constants.scripterServiceBaseUrl  + `/staticData?staticTable=${this.props.typeEndpoint}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                this.setState({ phoneTypes: formattedList },
                    () => {
                        if (this.props.list[this.props.phoneConfig?.phoneTypeId] === null) {
                            this.props.editCallback('phone_type_id', this.state.phoneTypes[0].value, this.props.list, 'phone_list', 'Check');
                            this.props.editCallback('phone_type', this.state.phoneTypes[0].label, this.props.list, 'phone_list', 'Check');
                        }
                    });
            },
                (err) => {
                    console.log("Error in fetching Phone Types:", err)
                })
    }

    onChangeCallback = (field, value) => {
        if (field === this.props.phoneConfig?.phone) {
            this.setState({
                fieldValid:
                    value.length > 0 ?
                        (validatePlusCharacter((value.trim()).charAt(0)) ?
                            validateIntlNumber(value) : validateUSNumber(value) || validatePhoneNumber(value)) : true
            });

        }

        if (field === this.props.phoneConfig?.phoneTypeId) {
            this.props.editCallback(this.props.phoneConfig?.phoneType,
                this.state.phoneTypes?.find(type => type.value === value)?.label,
                this.props.list, 'phone_list');
        }

        this.props.editCallback(field, value, this.props.list, 'phone_list');
    }

    render() {
        const { phoneConfig } = this.props;
        let error = (this.props.showMandatory && this.props?.list.phone == "" ) || this.props.fieldValid || false;
        return (
            <MDBContainer className="PhoneContainer">
                <MDBRow>
                    {this.props?.label && <BasicLabel text={this.props?.label || ""}
                        isMandatory={this.props?.isMandatory || false} />}
                </MDBRow>
                <MDBRow className='g-0'>
                    <MDBCol md={6} className="PhoneField">
                        <InlineSelectField id={"key17"}
                            textFieldLength={7}
                            selectFieldLength={5}
                            fieldValid={this.state.fieldValid}
                            textFieldposition="end"
                            showAsLabel={this.props.disabled || false}
                            disabled={this.props.disabled || false}
                            placeholder="(___) ___-____"
                            limit={this.props.disabled ? this.props.limit + 2 : this.props.limit}
                            limitWarning={`Exceeded ${this.props.limit} Numbers limit. Please re-enter`}
                            inValidInput={this.props.inValidInput}
                            fieldValue={(this.props.list && phoneConfig?.phone) ? this.props.list[phoneConfig?.phone] : ""}
                            onSelectChange={(e) => this.onChangeCallback(phoneConfig?.phoneTypeId, e.target.value)}
                            onFieldChange={(e) => this.onChangeCallback(phoneConfig?.phone, e.target.value)}
                            selectValue={(this.props.list && phoneConfig?.phoneTypeId) ? this.props.list[phoneConfig?.phoneTypeId] : ""}
                            options={this.state.phoneTypes || []} />
                    </MDBCol>
                    <MDBCol md={4}>
                        <ActionButtons
                            keyName={this.props.keyName}
                            showAdd={this.props.showAdd || false}
                            showRemove={this.props.showRemove || false}
                            isPrimary={(this.props.list && phoneConfig?.isPrimary) ? this.props.list[phoneConfig?.isPrimary] : false}
                            primaryOnChange={(e) => this.onChangeCallback(phoneConfig?.isPrimary, e.target.checked)}
                            removeCallback={this.props.removeCallback}
                            addCallback={this.props.addCallback} />
                    </MDBCol>
                </MDBRow>
                {
                    error && <MDBRow className="error">{(this.props.showMandatory && !this.props?.value) ? this.state.mandatoryWarning :
                        (this.props.fieldValid ? this.props.inValidInput : (this.props.disableSignatory ? this.state.selectUnion : ""))}</MDBRow>
                }
                {
                    this.props.checkedMultiPrimary ? (
                        <MDBRow>
                            <div className="error">{"Only one phone number can be a primary. Multiple selection is not allowed"}</div>
                        </MDBRow>
                    ) : null
                }
            </MDBContainer>
        );
    }
}

export default withUserContext(PhoneComponent);