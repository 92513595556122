export const intialAssignCoverage = {
    "submission_coverage_id": null,
    "submission_id": null,
    "analyst_id": null,
    "analyst": null,
    "coverage_type_id": null,
    "coverage_type_name": null,
    "due_date": null,
    "return_date": null,
    // "compare_to_submission_id": null,
    // "compare_to_submission": null,
    "notes": null,
    "genre_id": null,
    "genre_name": null,
    "locale": null,
    "logline": null,
    "time": null,
    "recommendation_summary": null,
    "writer_summary": null,
    "recommendation_id": null,
    "recommendation_name": null,
    "writer_recommendation_id": null,
    "writer_recommendation_name": null,
    "synopsis": null,
    "comparison": null,
    "comments": null,
    "submission_coverage_compare_to": [],
}

export const submissionWorkflowMockData = {
	"submission_id": null, 
	"submission_coverage_id": null,
	"action_label": null,
	"comments": null
}

export const compareTo = {
	"submission_coverage_compare_to_id": null,
	"compare_to_id": null,
	"index": 1,
	"is_delete": 0
}

export const assignAnalysts = [
    {
        id: 1,
        text: 'test',
        value: 'test',
    },
    {
        id: 2,
        text: 'test2',
        value: 'test2',
    },
    {
        id: 3,
        text: 'test3',
        value: 'test3',
    }
]

export const assignCoverageType = [
    {
        id: 1,
        label: 'coverage1',
        value: 'coverage1',
    },
    {
        id: 2,
        label: 'coverage2',
        value: 'coverage2',
    },
    {
        id: 3,
        label: 'coverage3',
        value: 'coverage2',
    }
]