import React from 'react';
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import { projectDealsConfig } from './Config';
import TableComponent from '../../../SharedComponents/Table';
import scripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';
import Loader from '../../../../Common/Loader/Loader';
import { withRouter } from 'react-router';

class DealsTabContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: projectDealsConfig,
            dealList: [],
            isFetchingDealList: false,
            dealRefreshPage: false,
            fetchDealList: () => this.fetchDealList()
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.refreshPage?.value !== state.dealRefreshPage && props.refreshPage?.modal === 'Deal') {  
            if(props.refreshPage?.value) {
                state.fetchDealList();
                props.toggleRefreshPage(props.refreshPage?.modal) 
            }       
            return {
                dealRefreshPage: props.refreshPage?.value || false
            }
        }
        return null;
    }


    componentDidMount() {
        this.fetchDealList();
    }

    openDealDetailsInNewTab = (id) => {
       if(id) {
        this.props.history.push("/dealDetails/"+id);
        //    window.open("/dealDetails/"+id);
       }
    }
    downloadUrl = (url, reportName) => {
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', reportName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        }
    }

    fetchDealList = () => {
        this.setState({ isFetchingDealList: true });
        scripterService.getData(Constants.scripterServiceBaseUrl + `/projectEntity?projectId=${this.props.projectId}&entity=DEALS`,
         this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(response => {
                 console.log("response.data", response.data)
                 this.setState({ isFetchingDealList: false, dealList: response.data });
            },
                (err) => {
                    this.setState({ isFetchingDealList: false })
                })
    }

    handlePdfCallback = (deal) => {
        this.setState({ isFetchingReport: true, mode: 'pdf' })
        if (deal) {
            let json = { "deal_memo_id": deal?.deal_memo_id };
            let load = {
                "payload": 'generateReport',
                "reportName": 'rptDealDetails',
                "searchJson": btoa(JSON.stringify(json)),
                "showHtml": 0,
                "isStaticReport": 0,
                "encryptionType": 1,
                "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
                "divisionId": this.props?.divisionId
            }
            scripterService.getDataWS(load)
            .then(webSocketConn => {
                webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data)
                    if(response?.statusCode == 200) {
                        this.setState({ isFetchingReport: false, mode: null }) 
                        scripterService.downloadFile(response.body).then(res => { 
                            const url = window.URL.createObjectURL(res.data);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', "DealDetails");
                            document.body.appendChild(link);
                            link.click();
                        }, (err) => {
                            console.log("File Download Failed:" + err);  
                            this.setState({ isFetchingReport: false, mode: null })                
                        });
                    } else {
                        this.setState({ isFetchingReport: false, mode: null }) 
                    }
                }
            })
        }
    }

    // handlePdfCallback = (deal) => {
    //     this.setState({ isFetchingReport: true, mode: 'pdf' })
    //     if (deal) {
    //         let postJson = { deal_memo_id: deal?.deal_memo_id };
    //         scripterService.getDataParams(Constants.scripterServiceBaseUrl + `/generateReport?reportName=rptDealDetails`, postJson,
    //             this.props.userContext.active_tenant.tenant_id, this.props.userContext.active_tenant.division_id, '&searchJson=')
    //             .then(response => {
    //                 this.setState({ isFetchingReport: false, mode: null })
    //                 if (response?.data?.error) {
    //                     alert(response?.data?.message)
    //                     //Report template not found 404
    //                 } else {
    //                     scripterService.downloadFile(response.data).then(response => { 
    //                         const url = window.URL.createObjectURL(response.data);
    //                         const link = document.createElement('a');
    //                         link.href = url;
    //                         link.setAttribute('download', "DealDetails");
    //                         document.body.appendChild(link);
    //                         link.click();
    //                     }, (err) => {
    //                         console.log("File Download Failed:" + err);                 
    //                       });
    //                 }
    //             },
    //                 (err) => {
    //                     this.setState({ isFetchingReport: false, mode: null })
    //                 })
    //     } else {
    //         this.setState({ isFetchingReport: false, mode: null })
    //     }

    // }


    render() {
       
        let permissionNotToView = this.state.dealList[0]?.is_View_Deal;
        permissionNotToView==0 ? this.state.config.actions=[]:'';
        
        return (
            <div className="TabDetailsContainer">
                 <Loader isOpen={this.state.isFetchingReport || false} text={"Loading..."} />
                <MDBCard>
                    <MDBCardBody className={"card-body-height"}>
                        <MDBCardText className="tab-table-content deals-action-icons" id="actionIconDeal">
                            <TableComponent
                                list={this.state?.dealList || []}
                                className={"coveredTalent"}
                                config={this.state.config}
                                removeHeader={false}
                                tableMode={"light"}
                                isLoading={this.state.isFetchingDealList || false}
                                // handlePdfCallback={(id) => this.handlePdfCallback(id)}
                                handleShareCallback={(id) => this.openDealDetailsInNewTab(id)}
                                handlePdfCallback={(id) => this.handlePdfCallback(id)}
                            />
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </div>
        )
    }
}

export default withRouter(withUserContext(DealsTabContent));