
import React from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

class NotificationComponent extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false,
            message: ""
        }
    }
    
    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps?.message !== state.message)
            return { open: true, message: nextProps.message }

        return null;
    }

    componentDidMount() {
        //this.props.productionContext.loadStatusDatesData();
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false, message: "" });
        this.props?.handleResetNotify();
    };

    render() {
        return (
            <Snackbar open={this.state.open} autoHideDuration={this.props?.duration || 6000}
                onClose={this.handleClose.bind(this)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert elevation={6} variant="filled" onClose={this.handleClose.bind(this)} severity={this.props?.severity}
                    sx={{ width: '100%' }}>
                    {this.props?.message || ""}
                </Alert>
            </Snackbar>
        );
    }
}

export default NotificationComponent;