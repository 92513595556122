import React from "react";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import CancelIcon from "@material-ui/icons/Cancel";
import { projectDetails, participatingWriterList} from "./config";
import BasicTextField from "../../../../SharedComponents/BasicTextField/BasicTextField";
import DateField from "../../../../SharedComponents/DateField/DateField";
import BasicTextArea from "../../../../SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../../SharedComponents/SelectField/SelectField";
import BasicCheckbox from "../../../../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicButton from "../../../../SharedComponents/BasicButton/BasicButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { InputAdornment } from "@material-ui/core";
import ScripterService from "../../../../../services/service";
import * as Constants from "../../../../../constants/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import BasicLabel from "../../../../SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    validateCharNumOnlyWithSpace,
    validateCharNumWithHyphenSlash,
    validatePlusCharacter,
    validateUSNumber,
    validatePhoneNumber,
    validateIntlNumber,
    getFormattedDate,
    formatPhoneNumbers
} from "../../../../../Common/Helper";
import messages from "../../../../../Common/Messages.json";
import sizeLimits from "../../../../../Common/SizeLimits.json";
import MessageModal from "../../../../SharedComponents/MessageModal";
import { withUserContext } from "../../../../../contexts/UserContext";
import SearchSelectField from "../../../../SharedComponents/SearchSelectField";
import SearchFieldWithAddValue from "../../../../SharedComponents/SearchFieldWithAddValue";
import SubAttachedFiles from "../../../Submissions/Dialogs/SubAttachedFiles";
import "./WGAContainer.scss";
import moment from "moment";
import { FormatColorReset, IndeterminateCheckBox } from "@material-ui/icons";
import { EmergencyRecordingOutlined } from "@mui/icons-material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

class Writer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            projectDetails: this.props.projectDetails,
            isSubmit: false,
            formEdited: false,
            postInitiated: false,
            wgaFormTypeOptions: [],
            loading: false,
            arrowClickedIndex: -1,
            existingWriters: this.props.existingWriters,
            isExistData: false,
            isOpen: false,
            selectedWGAType: {},
            submit: false,
            isnotify: false,
            isnotifyFail: false,
            index:null,
        };
    }
    componentDidMount=()=>{
        if(this.props.mode === "Edit"){
            if(this.state.existingWriters.writers.length>0 && this.state.existingWriters.writers[0].writer_name==null){
                let writers=this.state.existingWriters;
                writers.writers=[];
                this.setState({existingWriters:writers});
            }
        }
    }
    
    /**This method is used to setting value to the fields in writers object fields */
    handleListEdit = (fieldName, field, value, index, type) => {
        this.setState({ submit: true });
        let projectDetails = { ...this.state.projectDetails };
        let writerObj = projectDetails[fieldName][index];
        // if (field === 'talent_id' || field === 'talent_group_id') {
        //     writerObj['original_talent_name'] = writerObj?.adjusted_talent_name
        // }
        let writerObj_item = {
            ...writerObj,
            [field]: value,
        };
        projectDetails.writers[index] = writerObj_item;
        this.setState({
            projectDetails: projectDetails,
            formEdited: true,
            isExistData: true,
        });
        this.props.fieldChanged(this.state.formEdited, "change");
    };

    handlechange = (fieldName, field, value, index, type) => {
        this.setState({ submit: true });
        let ExistingprojectDetails = { ...this.state.existingWriters };
        let writerObj = ExistingprojectDetails[fieldName][index];
        let writerObj_item = {
            ...writerObj,
            [field]: value,
        };
        ExistingprojectDetails.writers[index] = writerObj_item;

        this.setState({
            existingWriters: ExistingprojectDetails,
            formEdited: true,
            isExistData: true,
        });
        this.props.fieldChanged(this.state.formEdited, "change");

    };
    showMessage = () => {
        this.setState({ isOpen: true });
    }

    /**adding new row to the writers array */
    addAdditionalField = (writerItem, index) => {
        this.setState({ submit: true });
        if ((writerItem?.writer_name)
            // && writerItem?.representative_id && writerItem?.representative_address_id
        ) {
            this.setState({ formEdited: true, postInitiated: false });
            this.props.fieldChanged(this.state.formEdited, "change");
            let existWriterDetails = JSON.parse(
                JSON.stringify({ ...this.state.existingWriters })
            );
            if (JSON.stringify(existWriterDetails) === '{}') {
                existWriterDetails = JSON.parse(
                    JSON.stringify({ ...this.state.projectDetails })
                );
                existWriterDetails.writers = [];
            }
            let writerArray = [...existWriterDetails.writers]
            // let writerIndex = existWriterDetails?.writers.length > 0 ?
            //     existWriterDetails?.writers[existWriterDetails?.writers.length - 1].row_no : 1;
            let writerIndex = existWriterDetails?.writers[existWriterDetails?.writers.length - 1]?.row_no || 0;
            let newWriterObj = { ...writerItem, row_no: writerIndex + 1 }
            writerArray.push(newWriterObj)
            existWriterDetails.writers = writerArray;
            this.setState({
                participatingWritersIndex: this.state.participatingWritersIndex + 1,
                existingWriters: existWriterDetails,
            }, () => {
                let projectDetails = JSON.parse(
                    JSON.stringify({ ...this.state.projectDetails })
                );
                let newWriterList = [];
                newWriterList.push({ ...participatingWriterList })
                projectDetails.writers = newWriterList;
                this.setState({ projectDetails: projectDetails })
            });
        } else {
            this.setState({ postInitiated: true, submit: true })
        }
    };
    /** removing row from the writers array */
    removeField = (callbackItem, type) => {
        this.setState({
            formEdited: true,
        });
        if (callbackItem.project_wga_writer_id) {
            this.setState({
                showDeleteConfirmationModal: true,
                deletedCallbackItem: callbackItem,
                type: type
            })
        } else {
            this.deleteRow(callbackItem, type);
        }
    };
    deleteRow = (callbackItem, type) => {
        let existingWriterDetails = JSON.parse(
            JSON.stringify({ ...this.state.existingWriters })
        );
        if (type === "exist") {
            let exist_Writers_list = existingWriterDetails.writers.filter(
                (item) =>
                    !item?.writer_selected?.writerTeam ?
                        (item.writer_name !== callbackItem.writer_name ||
                            item.adjusted_representative_name !== callbackItem.adjusted_representative_name ||
                            item.row_no !== callbackItem.row_no) : ""

            );
            existingWriterDetails.writers = exist_Writers_list;
            existingWriterDetails?.writers?.map((item, index) => {
                item.row_no = index + 1;
                return item;
            })
        }
        this.setState({
            existingWriters: existingWriterDetails
        });
    };

    handleSubmit = () => {
        let wgaProjectDetails = JSON.parse(
            JSON.stringify({ ...this.state.projectDetails })
        );
        let writersArray = []
        let writers ={}
        if (this.state.existingWriters?.writers?.length > 0) {
            this.state.existingWriters?.writers?.map((existWriter) => {
                // if (existWriter?.writer_name) {
                    writersArray.push(existWriter);
                //}
            });
            writers.project_wga_id=this.props.project_wga_id || wgaProjectDetails.project_wga_id;
            writers.project_id=wgaProjectDetails.project_id;
            writers.writers=[...writersArray]
        }
        this.setState({ postInitiated: true }, () => {
            if (this.validateWGAFormJson(writers)) {
                this.postWGAWriterDetails(writers);
            }
        });
    };
    postWGAWriterDetails = (wgaFormDetails) => {
        this.setState({ isSubmit: true,isLoading:true });
        this.props.setPostFlag(true);
        ScripterService.postDataParams(
            Constants.scripterServiceBaseUrl + "/WgaWriter",
            wgaFormDetails,
            this.props.userContext.active_tenant.tenant_id,
            this.props?.divisionId                                 
        ).then((response) => {
            if (response.data.error) {
                this.setState({ isSubmit: false, submit: false });
                this.props.setPostFlag(false);
                this.props.fieldChanged(true, "fail");
            } else {
                this.setState({
                    isnotify: true,
                    postInitiated: false,
                    formEdited: false,
                    isSubmit: false,
                    submit: false,
                    isLoading:false
                });
                this.props.setPostFlag(false);
                this.props.fieldChanged(true, "success");
            }
        }, (err) => {
            this.setState({ isSubmit: false, submit: false, isnotifyFail: true });
            this.props.fieldChanged(true, "fail");
            this.props.setPostFlag(false);
            console.log("Post WGA project details error: " + err);
        }
        );
    };
    validateWriters = (writers) => {
        let canSubmit = true;
        let i = 0;
        for (i; i < writers?.length; i++) {
            let obj = writers[i];

            if (obj.writer_name.length==0  || obj.adjusted_representative_name.length==0 || obj.adjusted_representative_address.length==0 ) {
                canSubmit = false;
                break;
            }
            
            // delete obj?.representation_list;
            // delete obj?.address_list
            // delete obj?.writer_list
        }
        return canSubmit;
    }
    validateWGAFormJson = (projectDetails) => {
        let canSubmit = false;
        canSubmit = projectDetails?.writers?.length > 0 && this.validateWriters(projectDetails?.writers)
        return canSubmit;
        
    };
    reverseAddress = (index, type) => {
        this.setState({ submit: true });
        let wgaDetails = type === "new" ? { ...this.state?.projectDetails } : { ...this.state.existingWriters };
        let nextIndex = index + 1;
        let previousIndex = index - 1;

        var wgaItem = wgaDetails.writers[index];
        wgaDetails.writers.splice(index, 1);
        if (index === 0) {
            wgaDetails.writers.splice(nextIndex, 0, wgaItem);
        } else {
            wgaDetails.writers.splice(previousIndex, 0, wgaItem);
        }
        wgaDetails?.writers?.map((item, index) => {
            item.row_no = index + 1;
            return item;
        })
        if (type === "new") {
            this.setState({ projectDetails: wgaDetails });
        } else {

            this.setState({ existingWriters: wgaDetails });
        }
    };

    moveWriterDown = (index, type) => {
        this.setState({ submit: true });
        let wgaDetails = type === "new" ? { ...this.state?.projectDetails } : { ...this.state.existingWriters };
        let nextIndex = index + 1;
        var wgaItem = wgaDetails.writers[index];
        wgaDetails.writers.splice(index, 1);
        wgaDetails.writers.splice(nextIndex, 0, wgaItem);
        wgaDetails?.writers?.map((item, index) => {
            item.row_no = index + 1;
            return item;
        })
        if (type === "new") {
            this.setState({ projectDetails: wgaDetails });
        } else {
            this.setState({ existingWriters: wgaDetails });
        }
    }



    render() {

        return (
            <div>
                {this.state.isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <div fluid className="ModalContainer WGAContainer">
                            <MessageModal
                                open={this.state.showDeleteConfirmationModal}
                                title={"Delete record"}
                                message={"Would you like to delete saved record?"}
                                primaryButtonText={"Delete"}
                                secondaryButtonText={"Cancel"}
                                onConfirm={() => this.setState({ showDeleteConfirmationModal: false, submit: true }, () => {
                                    this.deleteRow(this.state.deletedCallbackItem, this.state.type)
                                })}
                                handleClose={(e) => this.setState({ showDeleteConfirmationModal: false })}
                            />
                            
                            <div className="ModalContent" id="ProductionDetailsContainer">
                                {/* <MDBCol> */}
                                {/* <MDBRow className="ModalBody p-2"> */}
                                <MDBCol md={12} className="Content p-2">
                                            <MDBRow className="d-flex">
                                                <div className="WGAWidth">
                                                    <MDBRow>
                                                        {/* <MDBCol md={1}>
                                                                          <BasicLabel
                                                                              text={"Order"}
                                                                              isMandatory={true}
                                                                          />
                                                                      </MDBCol> */}
                                                        <MDBCol md={3} className="headingWriter">
                                                            <BasicLabel
                                                                text={"Writer Name"}
                                                                isMandatory={true}
                                                            />
                                                        </MDBCol>
                                                        <MDBCol md={3} className="headingAdditionalwriter">
                                                            <BasicLabel
                                                                text={"Additional Writer info"}
                                                            />
                                                        </MDBCol>
                                                        <MDBCol md={3} className="headingRep">
                                                            <BasicLabel
                                                                text={"Representation"}
                                                                isMandatory={true}
                                                            />
                                                        </MDBCol>
                                                        <MDBCol md={3} >
                                                            <BasicLabel
                                                                text={"Address"}
                                                                isMandatory={true}
                                                            />
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <div className="row-space"></div>
                                                    <hr className="hrTopLine"></hr>
                                                   <div className="row-space"></div>
                                                </div>
                                            </MDBRow>

                                    {(this.state.existingWriters?.writers?.length > 0) ? (
                                        <>
                                            {/* <MDBRow> */}
                                            {this.state.existingWriters?.writers?.map(
                                                (existWriterItem, index) =>
                                                    <div className="d-flex">
                                                        <div className="WGAWidth">
                                                            <MDBRow>
                                                                {/* <MDBCol md={1} className="writer-content">         
                                                                               <BasicTextField
                                                                             
                                                                             id={"order"}
                                                                            value={existWriterItem?.row_no||""}
                                                                            //onChange={(e) => this.handlechange("writers", "adjusted_talent_name", e.target.value, index, this.props.mode === "Edit" ? "edit" : "new")}
                                                                         /> 
                                                                        </MDBCol>  */}
                                                                <MDBCol md={3} className="RowStyle" >
                                                                    <BasicTextField
                                                                        id={"writer-select"}
                                                                        isMandatory={true}
                                                                        showMandatory={
                                                                            this.state.postInitiated ?
                                                                                this.props.mode === "Edit" ?
                                                                                    this.state?.existingWriters?.writers[index].writer_name.length==0 ? true : false :
                                                                                    true :
                                                                                false}

                                                                        mandatoryWarning={messages.exceed20CharacterWarning}
                                                                        value={existWriterItem?.writer_name || ""}
                                                                        onChange={(e) => this.handlechange("writers", "writer_name", e.target.value, index, this.props.mode === "Edit" ? "edit" : "new")}
                                                                    />
                                                                </MDBCol>
                                                                <MDBCol md={3} className="RowStyle">
                                                                    <BasicTextField
                                                                        id={"writer-select"}
                                                                        value={existWriterItem?.additional_writer_team_info || ""}
                                                                        onChange={(e) => this.handlechange("writers", "additional_writer_team_info", e.target.value, index, this.props.mode === "Edit" ? "edit" : "new")}
                                                                    />
                                                                </MDBCol>
                                                                <MDBCol md={3} className="RowStyle">

                                                                    <BasicTextField
                                                                        id={"representation"}
                                                                        isMandatory={true}
                                                                        showMandatory={
                                                                            this.state.postInitiated ?
                                                                                this.props.mode === "Edit" ?
                                                                                    this.state?.existingWriters?.writers[index].adjusted_representative_name.length==0 ? true : false :
                                                                                    true :
                                                                                false}

                                                                        mandatoryWarning={messages.exceed20CharacterWarning}
                                                                        value={existWriterItem?.adjusted_representative_name || ""}
                                                                        onChange={(e) => this.handlechange("writers", "adjusted_representative_name", e.target.value, index, this.props.mode === "Edit" ? "edit" : "new")}
                                                                    />
                                                                </MDBCol>
                                                                <MDBCol md={3} className="RowStyle">
                                                                    <BasicTextArea
                                                                        id={"address"}
                                                                        rows={4}
                                                                        isMandatory={true}
                                                                        showMandatory={
                                                                            this.state.postInitiated ?
                                                                                this.props.mode === "Edit" ?
                                                                                    this.state?.existingWriters?.writers[index].adjusted_representative_address.length==0 ? true : false :
                                                                                    true :
                                                                                false}
                                                                        mandatoryWarning={messages.exceed20CharacterWarning}

                                                                        value={existWriterItem?.adjusted_representative_address || ""}
                                                                        onChange={(e) => this.handlechange("writers", "adjusted_representative_address", e.target.value, index, this.props.mode === "Edit" ? "edit" : "new")}
                                                                    />
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </div>
                                                        <div className="actions d-inline-block">
                                                            <MDBRow className={"actionButons actionIconMargin"}>
                                                                <MDBCol className="p-0">
                                                                    {this.state.existingWriters?.writers ?.length > 0 ? (
                                                                        // ?.length > 0 && this.state.existingWriters?.writers.writer_name!=null ? (
                                                                            <MDBIcon
                                                                                icon="trash-alt"
                                                                                size="sm"
                                                                                onClick={(e) => this.removeField(existWriterItem, "exist")}
                                                                            />
                                                                        ) : null}
                                                                </MDBCol>
                                                                <MDBCol className="p-0">
                                                                    {this.state.existingWriters?.writers ?.length > 0 ? (
                                                                        //?.length > 0 && this.state.existingWriters?.writers.writer_name!=null ? (
                                                                            <MDBIcon
                                                                                className="arrow-icon"
                                                                                icon={
                                                                                    index === 0
                                                                                        ? "arrow-down"
                                                                                        : "arrow-up"
                                                                                }
                                                                                size="sm"
                                                                                onClick={() =>
                                                                                    this.reverseAddress(index, "existing")
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                </MDBCol>
                                                                <MDBCol className="p-0">
                                                                    <MDBIcon
                                                                        className="arrow-icon"
                                                                        icon={(index != 0 && index < (this.state.existingWriters?.writers?.length - 1)) ?
                                                                            "arrow-down" : ""
                                                                        }
                                                                        size="sm"
                                                                        onClick={() =>
                                                                            this.moveWriterDown(index, "existing")
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                                <div className="row-space"></div>
                                                            </MDBRow>
                                                        </div>
                                                        <div className="row-space"></div>
                                                    </div>
                                                // ) : null

                                            )}
                                            {/* </MDBRow> */}
                                        </>
                                    ) : null}

                                    {this.state.existingWriters?.writers?.some(item => (item.writer_name !== null)) ?
                                        <hr className="hrLine"></hr> : null
                                    }
                                    <MDBRow className="mt-2">
                                        {this.state.projectDetails?.writers?.map((writerItem, index) => (
                                            <div className="d-flex">
                                                <div className="WGAWidth">
                                                    <MDBRow>
                                                        {/* <MDBCol md={1}>         
                                                                            <BasicTextField
                                                                             
                                                                             id={"order"}
                                                                            value={writerItem?.row_no||""}
                                                                            //onChange={(e) => this.handlechange("writers", "adjusted_talent_name", e.target.value, index, this.props.mode === "Edit" ? "edit" : "new")}
                                                                         /> 
                                                                        </MDBCol> */}
                                                        <MDBCol md={3} className="RowStyle">
                                                            <BasicTextField
                                                                id={"writers-select"}
                                                                // label={
                                                                //     JSON.stringify(this.state.existingWriters) === '{}' ||
                                                                //         this.state.existingWriters?.writers?.length === 0
                                                                //         ? "Writer Name"
                                                                //         : ""
                                                                // }
                                                                
                                                                value={writerItem.writer_name || null}

                                                                onChange={(e) => this.handleListEdit(
                                                                    'writers', 'writer_name', e.target.value, index
                                                                )}

                                                                loading={this.state.loading}

                                                            />
                                                        </MDBCol>
                                                        <MDBCol md={3} className="RowStyle">
                                                            <BasicTextField

                                                                id={"writers-select"}
                                                                // label={
                                                                //     JSON.stringify(this.state.existingWriters) === '{}' ||
                                                                //         this.state.existingWriters?.writers?.length === 0
                                                                //         ? "Additional Writer Info"
                                                                //         : ""
                                                                // }
                                                                value={writerItem?.additional_writer_team_info || ""}
                                                                onChange={(e) => this.handleListEdit("writers", "additional_writer_team_info", e.target.value, index, this.props.mode === "Edit" ? "edit" : "new")}
                                                            />
                                                        </MDBCol>
                                                        <MDBCol md={3} className="RowStyle">
                                                            <BasicTextField
                                                                id={"representation"}
                                                                width={"100%"}
                                                                // label={
                                                                //     JSON.stringify(this.state.existingWriters) === '{}' ||
                                                                //         this.state.existingWriters?.writers?.length === 0
                                                                //         ? "Representation"
                                                                //         : ""
                                                                // }
                                                                value={writerItem?.adjusted_representative_name || ""}

                                                                onChange={(e) => this.handleListEdit(
                                                                    'writers', 'adjusted_representative_name', e.target.value, index
                                                                )}


                                                            />
                                                            <div className="row-space"></div>

                                                        </MDBCol>

                                                        <MDBCol md={3} className="RowStyle">
                                                            <BasicTextArea
                                                                id={"address"}
                                                                // label={
                                                                //     JSON.stringify(this.state.existingWriters) === '{}' ||
                                                                //         this.state.existingWriters?.writers?.length === 0 ?
                                                                //         "Address" : ""
                                                                // }
                                                                rows={4}
                                                                value={writerItem?.adjusted_representative_address || ""}
                                                                onChange={(e) => this.handleListEdit(
                                                                    'writers', 'adjusted_representative_address', e.target.value, index
                                                                )}

                                                            />
                                                            <div className="row-space"></div>

                                                        </MDBCol>
                                                    </MDBRow>

                                                    <div className="row-extra-space"></div>
                                                </div>
                                                <div className="actions d-inline-block" style={{ "width": "80px" }}>
                                                    <MDBRow
                                                        className={index === 0 ? "actionButons" : ""}
                                                    >
                                                        <MDBCol md={1} className="p-0">
                                                            {this.state.projectDetails?.writers.length !== 1 ? (
                                                                <RemoveCircleIcon
                                                                    fontSize="small"
                                                                    onClick={(e) => this.removeField(writerItem, this.props.mode === "Edit" ? "edit" : "new")}
                                                                />
                                                            ) : null}
                                                        </MDBCol>
                                                        <MDBCol md={1} className="p-0">
                                                            {(this.state.projectDetails?.writers?.length - 1 === index && writerItem?.writer_name && writerItem?.adjusted_representative_name && writerItem?.adjusted_representative_address) ? (
                                                                <AddCircleIcon
                                                                    fontSize="small"
                                                                    onClick={(e) => this.addAdditionalField(writerItem, index)}
                                                                />
                                                            ) : null}
                                                        </MDBCol>
                                                    </MDBRow>
                                                </div>
                                            </div>
                                        ))}
                                    </MDBRow>


                                    <MDBRow className="">
                                        <MDBCol md={9}></MDBCol>
                                        <MDBCol md={3}>
                                            <MDBRow className="mt-1">
                                                <MDBCol>
                                                    <BasicButton
                                                        type="inline"
                                                        variant="outlined"
                                                        text={"Cancel"}
                                                        onClick={() => this.props.onClose()}
                                                        disabled={false}
                                                    />
                                                </MDBCol>
                                                <MDBCol>
                                                    <BasicButton
                                                        className="btnwidth"
                                                        variant="contained"
                                                        type="inline"
                                                        text={
                                                            this.state.isSubmit ? (
                                                                <CircularProgress color="inherit" size={20} />
                                                            ) : (
                                                                    "Save"
                                                                )
                                                        }
                                                        onClick={this.handleSubmit}
                                                        disabled={!this.state.submit ? true : false}
                                                    />

                                                    <Snackbar
                                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                        open={this.state.isnotify || this.state.isnotifyFail}
                                                        onClose={() => this.setState({ isnotify: false })}
                                                        autoHideDuration={3000}
                                                    >
                                                        <Alert onClose={() => this.setState({ isnotify: false, isnotifyFail: false })} severity={!this.state.isnotifyFail ? "success" : "error"} sx={{ width: '100%' }}>{!this.state.isnotifyFail ? 'Saved Successfully!' : 'saved Failed !'}</Alert>
                                                    </Snackbar>

                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                {/* </MDBRow> */}
                                {/* </MDBCol> */}
                            </div>



                        </div>
                    )}
            </div>
        );
    }
}

export default withUserContext(Writer);