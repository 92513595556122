export const coverageInitialDetails = {
    "coverage_list": []
}

export const coverage = {
    "sent": null,
    "due": null,
    "assigned_notes": null,
    "genre": null,
    "locale": null,
    "time": null,
    "logline": null,
    "recommendation_summary": null,
    "writer_summary": null,
    "recommendatin": null,
    "writer_recommendation": null,
    "synopsis": null,
    "comparision": null,
    "comments": null
}

export const submissionWorkflowMockData = {
    "submission_id": null,
    "submission_coverage_id": null,
    "action_label": null,
    "comments": null,
    "created_at": null
}

// export const submissionMenuOptions = [
//     { value: 1, label: "Add To My Queue" },
//     { value: 2, label: "Remove from My Queue" },
//     { value: 3, label: "Delete Submission" },
// ]

export const submissionMenuOptions = [
    { value: 1, label: "Delete Submission" },
]

export const SubmissionQueueInitialJson = {
    "is_deal_queue": null,
    "is_project_queue": null,
    "is_submission_queue": 1,
    "is_weekend_read_queue": null,
    "user_id": null,
    "user_queue_deal_id": null,
    "deal_memo_id": null,
    "user_queue_project_id": null,
    "project_id": null,
    "user_queue_submission_id": null,
    "submission_id": null,
    "user_queue_weekend_read_id": null,
    "weekend_read_id": null,
    "is_delete": 0
}

export const coverageMenuOptions = [
    { value: 1, label: "Cancel Coverage" },
]

export const elementsConfig = {
    iscolumnFilterRequired: false,
    headings: [
        {
            headingLabel: "Full Name",
            width: "28%",
            isColumn: true
        },
        {
            headingLabel: "Role",
            width: "33.75%",
            isColumn: true
        },
        {
            headingLabel: "Status",
            width: "33.75%",
            isColumn: true
        },
        {
            headingLabel: "Actions",
            width: "4.5%",
            isColumn: true
        },
    ],
    dataNodes: ["element_name", "element_role", "element_status"],
    primaryKey: "submission_element_id",
    actions: ["trash-alt"],
}

export const filePayload ={
    "tenantName": null,
    "submissionId": null,
    "projectId":null,
    "dealId": null,
    "weekendReadId": null,
    "module": null,
    "subModule":"misc",
    "fileName": null,
    "submissionCoverageId": null
}