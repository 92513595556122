export const talentProjectsConfig = { 
    headings: [
        {
            headingLabel: "Project",
            icon: "",
            isColumn: true
        },
        {
            headingLabel: "Logline",
            icon: "",
            isColumn: true
        },
        {
            headingLabel: "Studio Division",
            icon: "",
            isColumn: true
        },

    ],
    dataNodes: ["project", "log_line", "studio_division"],
    primaryKey: "project_id",
    hyperlinks: ["project"]
}

export const talentSubmissionsConfig = {
    headings: [
        {
            headingLabel: "Submission",
            icon: "",
            isColumn: true
        },
        {
            headingLabel: "Author(s)",
            icon: "",
            isColumn: true
        },
        {
            headingLabel: "Format",
            icon: "",
            isColumn: true
        },
        {
            headingLabel: "Status",
            icon: "",
            isColumn: true
        },

    ],
    dataNodes: ["submission", "submission_author", "submission_format", "submission_status"],
    primaryKey: "talent_id",
    hyperlinks: ["submission"]
}

export const talentEmail = {
    "talent_email_id": null,
    "email_type_id": null,
    "email_type": "",
    "email": null,
    "email_is_primary": 0,
    "is_primary": 0,
    "index": 1
}


export const talentPhone = {
    "talent_phone_id": null,
    "phone_type_id": null,
    "phone_type": "",
    "is_international": 0,
    "phone": "",
    "is_primary": 0,
    "index": 1
}

export const talentAddress = {
    "talent_address_id": null,
    "address_type_id": null,
    "address_type": "",
    "address_1": null,
    "address_2": null,
    "address_3": null,
    "address": null,
    "city": null,
    "is_primary": 0,
    "state_id": null,
    "state": null,
    "country_id": 0,
    "country": null,
    "zip": null,
    "is_international": 0,
    "index": 1
}

export const talentAka = {
    "aka_id": null,
    "aka": null,
    "index": 1
}


export const akaConfig = {
    "id": "aka_id",
    "name": "aka"
}

export const emailConfig = {
    "emailTypeId": "email_type_id",
    "emailType": "email_type",
    "email": "email",
    "isPrimary": "is_primary"
}

export const talentInitialDetails = {
    "talent_id": null,
    "talent_group_id": null,
    "first_name": null,
    "last_name": null,
    "m_i": null,
    "prefix": null,
    "suffix": null,
    "performer_notes": null,
    "company": null,
    "akas": [],
    "email_list": [],
    "phone_list": [],
    "address_list": [],
    "role_list": [],
    "representation_list": [],
    "writer_team": 0,
}

export const talentRepresentation = {
    "talent_representative_agency_mtrx_id": null,
    "is_primary": 0,
    "send_check": null,
    "agency_id": null,
    "agency_name": null,
    "agency_phone_id": null,
    "agency_is_international": 0,
    "phone": null,
    "agency_email_id": null,
    "agency_email": null,
    "representative_id": null,
    "representative_name": null,
    "representative_type_id": null,
    "representative_type": null,
    "representative_phone_id": null,
    "representative_is_international": 0,
    "representative_phone": null,
    "representative_email_id": null,
    "representative_email": null,
    "representative_address_id": null,
    "address_1": null,
    "address_2": null,
    "address_3": null,
    "country_id": null,
    "country": null,
    "city": null,
    "state_id": null,
    "state": null,
    "zip": null,
    "index": 1
}

export const prefixes = [
    { value: null, label: "-" },
    { value: "Dr", label: "Dr" },
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" },
]

export const suffixes = [
    { value: null, label: "-" },
    { value: "Esq.", label: "Esq." },
    { value: "I", label: "I" },
    { value: "II", label: "II" },
    { value: "III", label: "III" },
    { value: "IV", label: "IV" },
    { value: "V", label: "V" },
    { value: "Sr.", label: "Sr." },
    { value: "Jr.", label: "Jr." },
]

export const talentPersonalLimit = {
    "firstName": 50,
    "lastName": 50,
    "mi": 50,
    "prefix": 10,
    "suffix": 10,
    "aka": 50,
    "email": 50,
    "performerNotes": 100,
}