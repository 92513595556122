import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import {
    MDBSideNavCat, MDBSideNavMenu, MDBSideNav, MDBSideNavLink, MDBSideNavItem, MDBModal,
    MDBContainer, MDBIcon, MDBBtn
} from "mdb-react-ui-kit";
import {
    BrowserRouter as Router
} from "react-router-dom";
import Routes from '../route/Routes';
import ErrorBoundary from "../error-boundary/ErrorBoundary";
import { useHistory } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import HeaderContent from '../header/HeaderContent';
import SideNav from '../components/SideNav/SideNav';
import './MainContent.scss';
import ModalComponent from '../Common/DefaultModalLayout/Modal/Modal';
import MainViewModalComponent from '../Common/MainViewModalLayout/Modal/Modal';
import * as Constants from '../constants/constants';
import ScripterService from '../services/service';
import { withUserContext } from '../contexts/UserContext';
import { talentNavList, agentNavList, productionCompanyList, crewList, representativeNavList } from '../constants/NavList';

export default withUserContext(class MainContent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: true,
            sideNavLeft: true,
            isSlim: false,
            user_name: localStorage.getItem("user_name"),
            activeTab: "",
            active_route: "",
            showTalentPopup: false,
            showAgencyPopup: false,
            showSubmissionModal: false,
            showElementsModal: false,
            showGenerateReportModal: false,
            showAssignCoverageModal: false,
            showManageDealModal: false,
            showNewAmendmentModal: false,
            showEditAmendmentModal: false,
            showNewDealModal: false,
            showEditNewDealModal: false,
            dealId: null,
            manageDealId: null,
            isDeal: false,
            showNewProjectModal: false,
            showWGAformModal: false,
            showGenerateScriptModal: false,
            showDealInformationReport: false,
            showProjectOfficerReport: false,
            showWritingDueReport: false,
            showProjectDetailReport: false,
            showLoglineReport: false,
            showVaultStorageReport: false,
            showDetailedSubmissionReport: false,
            showProjectReport: false,
            agencyId: null,
            repId: null,
            epId: null,
            workId: null,
            seasonId: null,
            statusDateId: null,
            productionId: null,
            submissionId: null,
            projectId: null,
            executiveId: null,
            wgaId: null,
            agencyRepId:null,
            showRepresentativePopup: false,
            showMainProductionPopup: false,
            showNewWeekendReadPopup: false,
            showEditWeekendReadPopup: false,
            weekendReadId: null,
            episodeMode: "New",
            showLookup: false,
            isManageUser: false,
            defaultTabValue: 0,
            talentId: null,
            disableSideNav: false,
            showAddExecutiveModal: false,
            showAddTalentModal: false,
            executiveDetails: {},
            refreshPage: { "modal": '', "value": false },
            showNewDealProject: false,
            repTalentId:null,
            talentMode:null
        }
        this.mainContent = React.createRef();
    }

    toggleRefreshPage = (modalName) => {
        let refreshPage = {
            "modal": modalName,
            "value": !this.state.refreshPage?.value
        }
        this.setState({ refreshPage: refreshPage })
    }

    toggleSideNav = () => {
        this.setState({
            isExpanded: !this.state.isExpanded
        });

    }
    handleSideNavLeft = () => {
        this.setState(
            {
                sideNavLeft: !this.state.sideNavLeft,
                slim: !this.state.isSlim,
                isExpanded: !this.state.isExpanded
            });
    }

    setActiveTab = (value) => {
        this.setState({ activeTab: value });
    }

    handleSideNavActive = (value, routePath) => {
        this.setActiveTab(value);
        this.props.history.push(routePath, this.state);
    }

    switchTenant = (tenant_id) => {
        this.props.switchTenant(tenant_id);
    }

    functions = {
        handleShowSubmissionPopup: (subId = null) => {
            this.setState({ showSubmissionModal: true, submissionId: subId })
        },
        handleHideSubmissionPopup: () => {
            this.setState({ showSubmissionModal: false })
        },
        handleShowEditElementModal: (submissionId = null) => {
            this.setState({ showElementsModal: true, submissionId: submissionId })
        },
        handleHideEditElementModal: () => {
            this.setState({ showElementsModal: false })
        },
        handleShowTalentPopup: (id = null, writerTeam = 0, talentMode = null, updateDetailsToParent = null, talentDetails = null) => {
            console.log("handleShowMaincontant")
            if (writerTeam) {
                this.setState({ talentGroupId: id })
            } else {
                this.setState({ talentId: id });
            }
            this.setState({ 
                showTalentPopup: true, 
                disableSideNav: true, 
                talentWriterTeam: writerTeam, 
                talentMode: talentMode,
                updateDetailsToParent: updateDetailsToParent,
                talentDetails: talentDetails
            });
        },
        handleHideTalentPopup: () => {
            this.setState({ showTalentPopup: false, disableSideNav: false });
        },

        handleShowAgencyPopup: (agencyId = null, agencyMode = null, updateDetailsToParent = null, repId = null) => {
            this.setState({ 
                showAgencyPopup: true, 
                agencyId: agencyId,
                agencyRepId : repId, 
                disableSideNav: true,
                agencyMode: agencyMode,
                updateDetailsToParent: updateDetailsToParent
            });
        },
        handleHideAgencyPopup: () => {
            this.setState({ showAgencyPopup: false, disableSideNav: false,agencyRepId:null });
        },

        handleShowRepresentativePopup: (repId = null,repMode=null,talentId=null,updateDetailsToParent = null, repDetails = {}) => {
            this.setState({ showRepresentativePopup: true,
                repMode:repMode,
                repId: repId, 
                repTalentId:talentId,
                disableSideNav: true, 
                updateDetailsToParent: updateDetailsToParent,
                repDetails: repDetails 
            });
        },
        handleHideRepresentativePopup: () => {
            this.setState({ showRepresentativePopup: false, disableSideNav: false });
        },
        handleShowMainProductionPopup: (mode, id) => {
            this.setState({ showMainProductionPopup: true, episodeMode: mode, productionId: id, disableSideNav: true });
        },
        handleHideMainProductionPopup: () => {
            this.setState({ showMainProductionPopup: false, disableSideNav: false });
        },
        showLookupPopup: () => {
            this.setState({ showLookup: true, disableSideNav: true })
        },
        hideLookupPopup: () => {
            this.setState({ showLookup: false, disableSideNav: false })
        },
        showGenerateReportModal: () => {
            this.setState({ showGenerateReportModal: true })
        },
        hideShowGenerateReportModal: () => {
            this.setState({ showGenerateReportModal: false })
        },
        handleShowNewWeekendReadModal: () => {
            this.setState({ showNewWeekendReadPopup: true })
        },
        handleHideNewWeekendReadModal: () => {
            this.setState({ showNewWeekendReadPopup: false })
        },
        handleShowEditWeekendReadModal: (weekendReadId) => {
            this.setState({ showEditWeekendReadPopup: true, weekendReadId: weekendReadId })
        },
        handleHideEditWeekendReadModal: () => {
            this.setState({ showEditWeekendReadPopup: false })
        },
        showManageDealModal: (isDeal = false, manageDealId, projectId = null) => {
            this.setState({ showManageDealModal: true, manageDealId: manageDealId, isDeal: isDeal, projectId: projectId })
        },
        hideShowManageDealModal: () => {
            this.setState({ showManageDealModal: false })
        },
        showNewDealModal: () => {
            this.setState({ showNewDealModal: true })
        },
        hideShowNewDealModal: () => {
            this.setState({ showNewDealModal: false })
        },
        showEditNewDealModal: (dealId = null) => {
            this.setState({ showEditNewDealModal: true, dealId: dealId })
        },
        hideEditNewDealModal: () => {
            this.setState({ showEditNewDealModal: false })
        },
        showNewAmendmentModal: (dealId = null, projectId = null) => {
            this.setState({ showNewAmendmentModal: true, dealId: dealId, projectId: projectId })
        },
        hideShowNewAmendmentModal: () => {
            this.setState({ showNewAmendmentModal: false, dealId: null })
        },
        showEditAmendmentModal: (dealId = null, projectId = null, dealMemoId = null) => {
            this.setState({ 
                showEditAmendmentModal: true, 
                dealId: dealId, 
                projectId: projectId ,
                dealMemoId: dealMemoId
            })
        },
        hideShowEditAmendmentModal: () => {
            this.setState({ showEditAmendmentModal: false, dealId: null })
        },
        showNewProjectModal: (projectId = null) => {
            this.setState({ showNewProjectModal: true, projectId: projectId })
        },
        hideNewProjectModal: () => {
            this.setState({ showNewProjectModal: false })
        },
        showWGAformModal: (wgaId = null, projectId, divisionId) => {
            this.setState({ showWGAformModal: true, wgaId: wgaId, projectId: projectId, divisionId: divisionId })
        },
        hideWGAformtModal: () => {
            this.setState({ showWGAformModal: false })
        },
        showAddExecutiveModal: (executiveId, projectId, executiveDetails, divisionId, updateParentDetails = null) => {
            this.setState({ 
                showAddExecutiveModal: true, 
                executiveDetails: executiveDetails, 
                executiveId: executiveId, 
                projectId: projectId,
                divisionId: divisionId,
                updateParentDetails: updateParentDetails
            })
        },
        hideAddExecutiveModal: () => {
            this.setState({ showAddExecutiveModal: false })
        },
        showAddTalentModal: (talentModalId, projectId, talentDetails, divisionId) => {
            this.setState({ 
                showAddTalentModal: true, 
                talentModalId: talentModalId, 
                projectId: projectId, 
                talentDetails: talentDetails, 
                divisionId: divisionId
            })
        },
        hideAddTalentModal: () => {
            this.setState({ showAddTalentModal: false })
        },
        showGenerateScriptModal: (scriptDetails, divisionId) => {
            this.setState({ showGenerateScriptModal: true, scriptDetails: scriptDetails, divisionId: divisionId })
        },
        hideShowGenerateScriptModal: () => {
            this.setState({ showGenerateScriptModal: false })
        },
        showDealInformationReport: () => {
            this.setState({ showDealInformationReport: true })
        },
        hideDealInformationReport: () => {
            this.setState({ showDealInformationReport: false })
        },
        showProjectOfficerReport: () => {
            this.setState({ showProjectOfficerReport: true })
        },
        hideProjectOfficerReport: () => {
            this.setState({ showProjectOfficerReport: false })
        },
        showWritingDueReport: () => {
            this.setState({ showWritingDueReport: true })
        },
        hideWritingDueReport: () => {
            this.setState({ showWritingDueReport: false })
        },
        showProjectDetailReport: () => {
            this.setState({ showProjectDetailReport: true })
        },
        hideProjectDetailReport: () => {
            this.setState({ showProjectDetailReport: false })
        },
        showLoglineReport: () => {
            this.setState({ showLoglineReport: true })
        },
        hideLoglineReport: () => {
            this.setState({ showLoglineReport: false })
        },        
        showVaultStorageReport: () => {
            this.setState({ showVaultStorageReport: true })
        },
        hideVaultStorageReport: () => {
            this.setState({ showVaultStorageReport: false })
        },
        showDetailedSubmissionReport: () => {
            this.setState({ showDetailedSubmissionReport: true })
        },
        hideDetailedSubmissionReport: () => {
            this.setState({ showDetailedSubmissionReport: false })
        },
        showProjectReport: () => {
            this.setState({ showProjectReport: true })
        },
        hideProjectReport: () => {
            this.setState({ showProjectReport: false })
        },
        showNewDealProject: () => {
            this.setState({ showNewDealProject: true })
        },

    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.active_route !== this.props.active_route) {
            this.props.history.push(this.props.active_route, this.state);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentWillReceiveProps(nextProps) {
        if (this._isMounted) {
            this.setState({
                active_route: nextProps.active_route,
            });

        }
    }



    render() {
        let permissions = this.props?.userContext?.active_tenant?.permissions;
        return (

            <>
                <div>
                    {/* <HeaderContent {...this.props} {...this.state} 
                  authState={this.props.authState} 
                  switchTenant = {this.switchTenant.bind(this)}
                  setActiveTab = {this.setActiveTab.bind(this)} /> */}

                    <MDBContainer fluid >
                        <MDBRow className="mainContent">
                            <MDBCol className="col-half" disabled={this.state.disableSideNav}>
                                <SideNav {...this.props} {...this.functions} />
                            </MDBCol>
                            <MDBCol className="col-qtr" >

                            </MDBCol>
                            <MDBCol className="w-auto main-content" id="main-content">
                                <div ref={this.mainContent} >
                                    <ErrorBoundary>
                                        <Routes authState={this.props.authState} {...this.props} {...this.state}
                                            refreshPage={this.state.refreshPage}
                                            toggleRefreshPage={this.toggleRefreshPage}
                                            functions={this.functions}
                                            handleSideNavActive={this.handleSideNavActive.bind(this)}
                                            setActiveTab={this.setActiveTab.bind(this)} />
                                    </ErrorBoundary>
                                </div>
                            </MDBCol >
                            <MDBCol className="col-qtr" >

                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <div className="modals">
                        {this.state.showNewWeekendReadPopup &&
                            <MainViewModalComponent
                                open={this.state.showNewWeekendReadPopup}
                                handleClose={() => this.setState({ showNewWeekendReadPopup: false })}
                                headerText={"Weekend Read"}
                                mode={"New"}
                                modalName={"New Weekend Read"}
                                navFuns={this.functions}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {this.state.showEditWeekendReadPopup &&
                            <MainViewModalComponent
                                open={this.state.showEditWeekendReadPopup}
                                handleClose={() => this.setState({ showEditWeekendReadPopup: false })}
                                headerText={"Weekend Read"}
                                mode={"Edit"}
                                weekendReadId={this.state?.weekendReadId}
                                refreshPage={this.state.refreshPage}
                                toggleRefreshPage={this.toggleRefreshPage}
                                modalName={"New Weekend Read"}
                                navFuns={this.functions}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showGenerateReportModal &&
                            <MainViewModalComponent
                                open={this.state.showGenerateReportModal}
                                handleClose={() => this.setState({ showGenerateReportModal: false })}
                                headerText={"[Report Name]"}
                                mode={"Generate"}
                                modalName={"generate report name"}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showAssignCoverageModal &&
                            <MainViewModalComponent
                                open={this.state.showAssignCoverageModal}
                                handleClose={this.functions.handleHideAssignCoverageModal}
                                headerText={"Assign Coverage"}
                                mode={"Assign"}
                                modalName={"Assign Coverage"}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showManageDealModal &&
                            <MainViewModalComponent
                                open={this.state.showManageDealModal}
                                handleClose={() => this.setState({ showManageDealModal: false })}
                                headerText={"Deal Steps"}
                                mode={"Edit"}
                                modalName={"Manage Deal"}
                                id={this.state?.manageDealId}
                                isDeal={this.state?.isDeal}
                                toggleRefreshPage={this.toggleRefreshPage}
                                projectId={this.state.projectId}
                                navFuns={this.functions}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            (this.state.showNewDealModal || this.state.showNewDealProject) &&
                            <MainViewModalComponent
                                open={this.state.showNewDealModal || this.state.showNewDealProject}
                                handleClose={this.state.showNewDealModal ? () => this.setState({ showNewDealModal: false }) : () => this.setState({ showNewDealProject: false })}
                                headerText={"Deal"}
                                mode={"New"}
                                modalName={"New Deal"}
                                toggleRefreshPage={this.toggleRefreshPage}
                                showNewDealProject={this.state.showNewDealProject}
                                navFuns={this.functions}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showEditNewDealModal &&
                            <MainViewModalComponent
                                open={this.state.showEditNewDealModal}
                                handleClose={() => this.setState({ showEditNewDealModal: false })}
                                headerText={"Deal"}
                                mode={"Edit"}
                                dealId={this.state?.dealId}
                                modalName={"New Deal"}
                                toggleRefreshPage={this.toggleRefreshPage}
                                navFuns={this.functions}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showNewAmendmentModal &&
                            <MainViewModalComponent
                                open={this.state.showNewAmendmentModal}
                                handleClose={() => this.setState({ showNewAmendmentModal: false })}
                                headerText={"Amendment"}
                                mode={"New"}
                                modalName={"New Amendment"}
                                dealId={this.state?.dealId}
                                projectId={this.state.projectId}
                                toggleRefreshPage={this.toggleRefreshPage}
                                navFuns={this.functions}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showEditAmendmentModal &&
                            <MainViewModalComponent
                                open={this.state.showEditAmendmentModal}
                                handleClose={() => this.setState({ showEditAmendmentModal: false })}
                                headerText={"Amendment"}
                                mode={"Edit"}
                                modalName={"Edit Amendment"}
                                dealId={this.state?.dealId}
                                projectId={this.state.projectId}
                                dealMemoId={this.state.dealMemoId}
                                toggleRefreshPage={this.toggleRefreshPage}
                                navFuns={this.functions}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showWGAformModal &&
                            <MainViewModalComponent
                                open={this.state.showWGAformModal}
                                handleClose={() => this.setState({ showWGAformModal: false, wgaId: null })}
                                headerText={"WGA"}
                                mode={this.state.wgaId ? "Edit" : "New"}
                                modalName={"WGA"}
                                wgaId={this.state.wgaId || null}
                                projectId={this.state.projectId || null}
                                toggleRefreshPage={this.toggleRefreshPage}
                                navFuns={this.functions}
                                divisionId={this.state.divisionId}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showNewProjectModal && permissions?.canModifyProject &&
                            <MainViewModalComponent
                                open={this.state.showNewProjectModal}
                                handleClose={() => this.setState({ showNewProjectModal: false, projectId: null })}
                                headerText={"Project"}
                                mode={this.state.projectId ? "Edit" : "New"}
                                modalName={"Project"}
                                projectId={this.state.projectId || null}
                                toggleRefreshPage={this.toggleRefreshPage}
                                navFuns={this.functions}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showSubmissionModal &&
                            <MainViewModalComponent
                                open={this.state.showSubmissionModal}
                                handleClose={() => this.setState({ showSubmissionModal: false, submissionId: null })}
                                headerText={"Submission"}
                                mode={this.state.submissionId ? "Edit" : "New"}
                                modalName={"Submission"}
                                submissionId={this.state.submissionId}
                                toggleRefreshPage={this.toggleRefreshPage}
                                navFuns={this.functions}
                            />
                        }
                        {
                            this.state.showElementsModal &&
                            <MainViewModalComponent
                                open={this.state.showElementsModal}
                                handleClose={() => this.setState({ showElementsModal: false })}
                                headerText={"Elements"}
                                mode={"Edit"}
                                modalName={"Edit Elements"}
                                submissionId={this.state.submissionId}
                                toggleRefreshPage={this.toggleRefreshPage}
                                navFuns={this.functions}
                            />
                        }
                         {
                            this.state.showAddTalentModal &&
                            <MainViewModalComponent
                                navFuns={this.functions}
                                open={this.state.showAddTalentModal}
                                handleClose={this.functions.hideAddTalentModal}
                                headerText={"Talent"}
                                modalName={"Talent"}
                                talentDetails={this.state.talentDetails}
                                mode={this.state.talentModalId ? "Edit" : "Add"}
                                projectId={this.state.projectId || null}
                                talentModalId={this.state.talentModalId || null}
                                toggleRefreshPage={this.toggleRefreshPage}
                                handleShowTalentPopup={this.functions.handleShowTalentPopup}
                                divisionId={this.state.divisionId}
                            />
                        }
                        {
                            this.state.showAgencyPopup ?
                                <ModalComponent
                                    navFuns={this.functions}
                                    open={this.state.showAgencyPopup}
                                    handleClose={this.functions.handleHideAgencyPopup}
                                    tabList={agentNavList}
                                    bodyText={"Search for a Company to view its information , or create a new Company"}
                                    searchPlaceholder={"Search by Company name"}
                                    addButtonText={"New Company"}
                                    agencyId={this.state.agencyId}
                                    agencyRepId={this.state.agencyRepId}
                                    agencyMode={this.state.agencyMode}
                                    updateDetailsToParent={this.state.updateDetailsToParent}
                                    objectType={'AGENCY'}
                                    headerText={"Companies"}
                                    dataUrl='agency' /> : null

                        }
                        {
                            this.state.showTalentPopup ?
                                <ModalComponent
                                    navFuns={this.functions}
                                    talentId={this.state.talentId}
                                    talentGroupId={this.state.talentGroupId}
                                    talentWriterTeam={this.state.talentWriterTeam}
                                    talentMode={this.state.talentMode}
                                    talentDetails={this.state.talentDetails}
                                    updateDetailsToParent={this.state.updateDetailsToParent}
                                    open={this.state.showTalentPopup}
                                    handleClose={this.functions.handleHideTalentPopup}
                                    tabList={talentNavList}
                                    bodyText={"Search for Talent to view their Data, or create a new Talent"}
                                    addButtonText={"New Talent"}
                                    searchPlaceholder={"Search Talent by name"}
                                    objectType={'TALENT'}
                                    headerText={"Talent"}
                                    dataUrl='talent' /> : null

                        }

                        {
                            this.state.showRepresentativePopup ?
                                <ModalComponent
                                    navFuns={this.functions}
                                    open={this.state.showRepresentativePopup}
                                    repMode={this.state?.repMode}
                                    handleClose={this.functions.handleHideRepresentativePopup}
                                    tabList={representativeNavList}
                                    bodyText={"Search for a Representative to view their information, or create a new Representative"}
                                    searchPlaceholder={"Search by Rep name"}
                                    addButtonText={"New Representative"}
                                    repId={this.state.repId}
                                    updateDetailsToParent={this.state.updateDetailsToParent}
                                    repDetails={this.state.repDetails}
                                    objectType={'REPRESENTATIVE'}
                                    headerText={"Representatives"}
                                    talentId={this.state.repTalentId}
                                    dataUrl='representative' /> : null
                                    
                        }
                        {
                            this.state.showMainProductionPopup &&
                            <MainViewModalComponent
                                open={this.state.showMainProductionPopup}
                                handleClose={this.functions.handleHideMainProductionPopup}
                                headerText={this.state.episodeMode === "New" ? "Production Details" : "Production Details"}
                                mode={this.state.episodeMode}
                                id={this.state.productionId}
                                modalName={"Main Production"} />
                        }
                        {
                            this.state.showLookup &&
                            <div className="manage-lookup">
                                <MainViewModalComponent
                                    open={this.state.showLookup}
                                    handleClose={this.functions.hideLookupPopup}
                                    headerText={"Manage Lookups"}
                                    modalName={"Manage Lookups"}
                                    mode={'Both'}
                                />
                            </div>
                        }
                        {
                            this.state.showAddExecutiveModal &&
                            <MainViewModalComponent
                                open={this.state.showAddExecutiveModal}
                                handleClose={this.functions.hideAddExecutiveModal}
                                headerText={"Executive"}
                                modalName={"Executive"}
                                mode={this.state.executiveId ? "Edit" : "Add"}
                                executiveId={this.state.executiveId || null}
                                executiveDetails={this.state.executiveDetails || {}}
                                projectId={this.state.projectId || null}
                                toggleRefreshPage={this.toggleRefreshPage}
                                divisionId={this.state.divisionId}
                                updateParentDetails={this.state.updateParentDetails}
                            />
                        }
                       
                        {
                            this.state.showGenerateScriptModal &&
                            <MainViewModalComponent
                                open={this.state.showGenerateScriptModal}
                                handleClose={() => this.setState({ showGenerateScriptModal: false })}
                                headerText={"Delivery Notice Report"}
                                mode={"Generate Script"}
                                modalName={"Generate Script"}
                                scriptDetails={this.state.scriptDetails}
                                divisionId={this.state.divisionId}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showDealInformationReport &&
                            <MainViewModalComponent
                                open={this.state.showDealInformationReport}
                                handleClose={() => this.setState({ showDealInformationReport: false })}
                                headerText={"Deal Information Report"}
                                mode={""}
                                modalName={"Deal Information Report"}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showProjectOfficerReport &&
                            <MainViewModalComponent
                                open={this.state.showProjectOfficerReport}
                                handleClose={() => this.setState({ showProjectOfficerReport: false })}
                                headerText={"Project Officer Report"}
                                mode={""}
                                modalName={"Project Officer Report"}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showWritingDueReport &&
                            <MainViewModalComponent
                                open={this.state.showWritingDueReport}
                                handleClose={() => this.setState({ showWritingDueReport: false })}
                                headerText={"Writing Due Report"}
                                mode={""}
                                modalName={"Writing Due Report"}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showProjectDetailReport &&
                            <MainViewModalComponent
                                open={this.state.showProjectDetailReport}
                                handleClose={() => this.setState({ showProjectDetailReport: false })}
                                headerText={"Project Detail Report"}
                                mode={""}
                                modalName={"Project Detail Report"}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showLoglineReport &&
                            <MainViewModalComponent
                                open={this.state.showLoglineReport}
                                handleClose={() => this.setState({ showLoglineReport: false })}
                                headerText={"Logline Report"}
                                mode={""}
                                modalName={"Logline Report"}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }                        
                        {
                            this.state.showVaultStorageReport &&
                            <MainViewModalComponent
                                open={this.state.showVaultStorageReport}
                                handleClose={() => this.setState({ showVaultStorageReport: false })}
                                headerText={"Vault Storage Report"}
                                mode={""}
                                modalName={"Vault Storage Report"}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showDetailedSubmissionReport &&
                            <MainViewModalComponent
                                open={this.state.showDetailedSubmissionReport}
                                handleClose={() => this.setState({ showDetailedSubmissionReport: false })}
                                headerText={"Detailed Submission Report"}
                                mode={""}
                                modalName={"Detailed Submission Report"}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                        {
                            this.state.showProjectReport &&
                            <MainViewModalComponent
                                open={this.state.showProjectReport}
                                handleClose={() => this.setState({ showProjectReport: false })}
                                headerText={"Project Report"}
                                mode={""}
                                modalName={"Project Report"}
                            // handleSubmit={this.handleAddEpisode.bind(this)}
                            />
                        }
                    </div>
                </div>
            </>

        );
    }
});

// export default MainContent;