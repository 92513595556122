import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
class SearchResultsLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResult: props.searchResult ? props.searchResult : null
        }
    }

    // capitalizeFirstLetter = (str) => {
    //     if (str) {
    //         var i, frags = str?.split('_');
    //         for (i = 0; i < frags?.length; i++) {
    //             frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
    //         }
    //         return frags?.join(' ');
    //     }
    //     return '';
    // }

    render() {
        return (
            <>
                <div className='between'>
                    <div>
                        {this.state.searchResult.name}
                    </div>
                    <div className="color">
                        {this.state.searchResult.resultlabel}
                    </div>
                </div>
                <div className="color">
                    {this.state.searchResult.date_created}
                </div>
                <div className='hrL'></div>
            </>
        )
    }
}
export default SearchResultsLayout;