import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import TableComponent from '../../SharedComponents/Table';
import { coveredTalentConfig } from './Config';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';
import TablePagination from '@material-ui/core/TablePagination';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import MessageModal from '../../SharedComponents/MessageModal';

class CoveredTalent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 10,
            page: 0,
            renderList: [],
            agentList: [],
            config: coveredTalentConfig,
            isSortByAsc: true,
            sortCount: 0,
            sortBy: "Representative Name",
            getSortNode: this.getSortNode.bind(this),
            showMessageModal: false,
            showError: false,
            unsavedMessage: "Are you sure you want to discard your changes?"
        }
    }

    componentDidMount() {
        let list = tableSortList("Name", this.getSortNode(this.state.sortBy), this.state.agentList, this.state.isSortByAsc)
        this.setState({ renderList: handlePaginationList(this.state.page, this.state.rowsPerPage, list), agentList: list });
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage, renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.agentList) });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.agentList !== state.agentList) {
            let list = tableSortList("Name", state.getSortNode(state.sortBy), props.agentList, state.isSortByAsc)
            return {
                agentList: props.agentList,
                renderList: handlePaginationList(state.page, state.rowsPerPage, list)
            }
        }
        return null
    }

    getSortNode = (col) => {
        switch (col) {
            case "Talent Name": return "talent_name";
            case "Rep Type": return "representative_type";
            case "Representative Name": return "representative_name";
            case "Datetime Added": return "created_at";
        }
    }

    handleTableColumnClick = (column) => {
        if (column !== "AKA(s)") {
            if (column === this.state.sortBy) {
                this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                    this.handleTableSort(column);
                })
            }
            else this.setState({ sortCount: 0 }, () => {
                this.handleTableSort(column);
            })
        }
    }

    navigationCallback = (obj, node) => {
        // handle hyperlink navigation
        if(node.includes("talent_name")) {
            this.props.navFuns.handleHideAgencyPopup();
            if (obj.talent_id) {
                this.props.navFuns.handleShowTalentPopup(obj.talent_id);
            } else {
                this.props.navFuns.handleShowTalentPopup(obj.talent_group_id, 1);
            }
        } else if(node.includes("representative_name")) {
            this.props.navFuns.handleHideAgencyPopup();
            this.props.navFuns.handleShowRepresentativePopup(obj.representative_id);
        }
    }

    handleButtonClick = () => {
        if (this.props.agencyEdited == true) {
            this.setState({ showMessageModal: true, showError: true });
        }
        else{
            this.onAgencyClose();
        }
    }

    onAgencyClose = () => {
        this.props.navFuns.handleHideAgencyPopup();
        this.props.navFuns.handleShowTalentPopup();
    }

    render() {
        return (
            <MDBContainer fluid className="ModalTableContainer">
                <MDBRow className='g-0'>
                    <MDBCol md={10}></MDBCol>
                    <MDBCol md={2} className="mt-2">
                            <BasicButton
                                variant="contained"
                                text="New Talent"
                                type="inline"
                                onClick={this.handleButtonClick}
                                icon={"plus-circle"}
                            />
                    </MDBCol>
                </MDBRow>
                <MDBRow className='g-0'>
                    <TableComponent
                        list={this.state.renderList || []}
                        className={"coveredTalent"}
                        config={this.state.config}
                        hyperLinkNavigationCallback={this.navigationCallback}
                        isLoading={this.props.isLoading || false}
                        sortCallback={this.handleTableColumnClick}
                    />
                </MDBRow>
                <MDBRow className="Pagination">
                    <TablePagination
                        component="div"
                        count={this.state.agentList ? this.state.agentList?.length : 0}
                        rowsPerPageOptions={[]}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                    />
                </MDBRow>
                {
                    this.state.showMessageModal &&
                    <MessageModal
                        open={this.state.showError || false}
                        title={"Unsaved Changes"}
                        message={this.state.unsavedMessage}
                        hideCancel={this.state.showFail || this.state.showReportFail || this.state.showSuccess || this.state.showReportNotFound || false}
                        primaryButtonText={"OK"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={this.onAgencyClose}
                        handleClose={(e) => this.setState({ showError: false })} />
                }
            </MDBContainer>
        );
    }
}

export default CoveredTalent;