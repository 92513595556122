import React from 'react';
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import { projectTalentConfig } from './Config';
import TableComponent from '../../../SharedComponents/Table';
import MessageModal from '../../../SharedComponents/MessageModal';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';
import Loader from '../../../../Common/Loader/Loader';

export default withUserContext(class TalentTabContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: projectTalentConfig,
            talentList: null,
            isFetchingTalentList: false,
            isDeletingTalent: false,
            talentRefreshPage: false,
            fetchTalentList: () => this.fetchTalentList()
        }
    }


    static getDerivedStateFromProps(props, state) {
        if (props.refreshPage?.value !== state.talentRefreshPage && props.refreshPage?.modal === 'talent') {
            if (props.refreshPage?.value) {
                state.fetchTalentList();
                props.toggleRefreshPage(props.refreshPage?.modal)
            }
            return {
                talentRefreshPage: props.refreshPage?.value || false
            }
        }
        return null;
    }
    componentDidMount() {
        this.fetchTalentList();
    }

    deleteTalent = () => {
        this.setState({ isDeletingTalent: true, showDeleteConfirmationModal: false })
        this.state.deletedCallbackItem.is_delete = 1;
        delete this.state.deletedCallbackItem.talent_role;
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + "/projectTalents", this.state.deletedCallbackItem,
            this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(
                (response) => {
                    console.log("response delete", response)
                    this.setState({ isDeletingTalent: false })
                    if (response?.data?.error) {
                        this.props.manageMessageModal('Fail', true, 'delete')
                    } else {
                        this.props.manageMessageModal('Success', true, 'delete');
                        this.fetchTalentList();
                    }
                },
                (err) => {
                    console.log("error", err);
                    this.setState({ isDeletingTalent: false })
                    this.props.manageMessageModal('Fail', true, 'delete')
                }
            );
    }

    fetchTalentList = () => {
        this.setState({ isFetchingTalentList: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/projectEntity?projectId=${this.props.projectId}&entity=TALENT`,
            this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(response => {
                let talentList = response.data;
                talentList = talentList?.filter(item => item.talent_id || item.talent_group_id);
                talentList?.map((item, index) => {
                    item.talent_role = item?.roles?.map(roleItem => roleItem.talent_role_name)?.join(', ');
                    talentList[index].talent = talentList[index].talent_name;
                    talentList[index].representative = talentList[index].representative_name;
                    delete talentList[index].talent_name;
                    delete talentList[index].representative_name;
                })
                this.setState({ isFetchingTalentList: false, talentList: talentList });
            },
                (err) => {
                    this.setState({ isFetchingTalentList: false })
                })
    }

    navigationCallback = (obj, node) => {
        if (node.includes("talent")) {
            if (obj.talent_id) {
                this.props.handleShowTalentPopup(obj.talent_id)
            } else {
                this.props?.handleShowTalentPopup(obj.talent_group_id, 1)
            }
        }
    }

    viewCallback = (obj, node) => {
        if (node.includes("representative")) {
            this.props.handleShowRepresentativePopup(obj.representative_id)
        } else {
            this.props.handleShowAgencyPopup(obj.agency_id)
        }
    }

    handleDeleteConfirmation = (callbackItem) => {
        this.setState({ 
            showDeleteConfirmationModal: true,
            deletedCallbackItem: callbackItem
        });
    }

    render() {
        return (
            <div className="TabDetailsContainer">
                <MessageModal
                    open={this.state.showDeleteConfirmationModal}
                    title={"Delete record"}
                    message={"Would you like to delete saved record?"}
                    primaryButtonText={"Delete"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => this.deleteTalent()}
                    handleClose={(e) => this.setState({ showDeleteConfirmationModal: false })} 
                />
                <MDBCard>
                    <Loader isOpen={this.state.isDeletingTalent || false} text={"Deleting..."} />
                    <MDBCardBody className={"card-body-height"}>
                        <MDBCardText className="tab-table-content talent-action-icons" id="actionIcon">
                            <TableComponent
                                list={this.state?.talentList || []}
                                config={this.state.config}
                                removeHeader={false}
                                tableMode={"light"}
                                canDelete={this.props.permissions?.canDeleteTalentRepAgencies || false}
                                hyperLinkNavigationCallback={this.navigationCallback}
                                editCallback={this.props.editCallback}
                                viewCallback={this.viewCallback}
                                isLoading={this.state.isFetchingTalentList || false}
                                // handleDelCallBack={(id, item) => this.deleteTalent(item)}
                                handleDelCallBack={(id, item) => this.handleDeleteConfirmation(item)}
                            />
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </div>
        )
    }
})
