import React from 'react';
import { MDBModal, MDBModalBody, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import '../NewUserPopUp/CreateOrEditUserModal.scss';
import { 
    selectOptions, 
    selectHomePage, 
    userLimit, 
    subProjects, 
    subSubmissions, 
    projectAccessConfig, 
    userInitialDetails, 
    subDivisions,
    initialAssistantConfig
} from './config';
import BasicCheckbox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import ScripterService from '../../services/service';
import * as Constants from '../../constants/constants';
import MessageModal from '../../components/SharedComponents/MessageModal';
import messages from '../../Common/Messages.json';
import sizeLimits from '../../Common/SizeLimits.json';
import CircularProgress from "@material-ui/core/CircularProgress";
import CancelIcon from '@material-ui/icons/Cancel';
import { withUserContext } from '../../contexts/UserContext';
import { getFormattedDate, getDateAndTime, validateEmail, validatePlusCharacter, validateUSNumber, validatePhoneNumber, validateIntlNumber, validateNumbersOnly } from "../../Common/Helper";
import BasicTextArea from '../../components/SharedComponents/BasicTextArea/BasicTextArea';
import SearchSelectList from '../../components/Views/Submissions/Dialogs/SearchSelectList';
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';

class CreateOrEditUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetails: this.formatSubmissionList(JSON.parse(JSON.stringify({ ...userInitialDetails }))),
            fullName: "",
            statusValue: 1,
            department: "",
            title: "",
            accountEmail: "",
            phone: "",
            defaultHomePage: "",
            defaultTenantId: 1,
            divisions: [],
            selectedDivisions: [],
            group_id: null,
            group_name: "",
            projects: [],
            submissions: {},
            permissions: {},
            projectIndex: 2,
            submissionIndex: 2,
            assistantIndex: 2,
            isUserRoleManager: null,
            value: 0,
            divisionDetails: [],
            checked: [],
            allPermissions: [],
            userProvisioner: false,
            receivedPermissions: false,
            is_notification_send: 0,
            userRoleDetails: [],
            groupDetails: [],
            groupPermissions: [],
            copyOfGroupPermissions: [],
            fieldValid: true,
            phoneValid: true,
            postInitiated: false,
            fieldChanged: false,
            showSuccess: false,
            showFail: false,
            loading: false,
            permissionsLoading: false,
            enablePermissions: true,
            searchCancelToken: null,
            failCustomize: false,
            project: [],
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            permissionsCustomizeMessage: "Please customize permissions",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            showFailGroupMessage: "Atleast one group has to be entered",
            showFailGroupTitle: "Groups missing",
            showFailTenantMessage: "Tenant already exists",
            showFailTenantTitle: "Duplicate Tenant",
            isPosting: false,
            last_logged_at: "",
            allGroupDetails: [],
            projectList: [],
            submissionList: [],
            custom: 0,
            projectAccess: null,
            userGetDetails: null,
            isAssistant: true
        }
    }

    componentDidMount() {
        this.getPermissions();
        this.getDivisions();
        this.getGroups();
        this.getEntity('divisionList', 'DIVISION', '');
        this.getEntity('projectList', 'PROJECT', '');
        // this.getEntity('submissionList', 'SUBMISSION', '');
        if (this.props?.id) {
            this.getEditUser(this.props?.id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState?.group_id !== this.state.group_id) {
            this.setState(prevState => ({
                userDetails: {
                    ...prevState.userDetails,
                    user_assistants: [{...initialAssistantConfig}]
                },
            }))
        }
    }

    getEditUser = (id) => {
        this.setState({ loading: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/user?userId=' + id, 
            this.props?.userContext?.active_tenant?.tenant_id, null)
            .then(response => {
                let formattedList = response.data?.find(item => item.user_id === id)
                this.setState({ userGetDetails: formattedList });
                console.log("Formatted List: ", this.state.userGetDetails);
                let userStatus = formattedList?.is_active;
                let default_Home_Page = formattedList?.default_home_page;
                let defaultTenantId = formattedList?.default_tenant_id;
                let usDivisions = []
                let usProjects = []
                let usSubmissions = []
                let usPermissions = []
                formattedList?.divisions?.map((item, index) => {
                    let selectedDivisions = {
                        division_name: item.division_name
                    }
                    this.setState({ userRoleDetails: selectedDivisions })
                })
                formattedList?.projects?.map((item, index) => {
                    let selectedProjects = {
                        user_project_id: item.user_project_id,
                        project_id: item.project_id
                    }
                    usProjects.push(selectedProjects);
                });
                formattedList?.submissions?.map((item, index) => {
                    let selectedSubmissions = { user_submission_id: item.user_submission_id, submission_id: item.submission_id }
                    usSubmissions.push(selectedSubmissions);
                });
                formattedList?.permissions?.map((item, index) => {
                    let selectedPermissions = { permission_id: item.permission_id, permission_name: item.permission_name, entity_type: item.type }
                    usPermissions.push(selectedPermissions);
                });
                this.setState({
                    fullName: formattedList?.user_name,
                    statusValue: userStatus,
                    department: formattedList?.department,
                    title: formattedList?.title,
                    accountEmail: formattedList?.account_email,
                    phone: formattedList?.phone,
                    defaultHomePage: default_Home_Page,
                    defaultTenantId: defaultTenantId,
                    custom: formattedList?.is_customized,
                    enablePermissions: formattedList?.is_customized ? false : true,
                    customAccessJustification: formattedList?.custom_access_justification,
                    divisions: usDivisions,
                    group_id: formattedList?.group_id,
                    group_name: formattedList?.group_name,
                    // isAssistant: formattedList?.group_name?.indexOf('Assistant') !== -1,
                    isAssistant: this.state.groupsDetails?.find(item => item.value == formattedList?.group_id)?.is_assistant == 1 ? true : false,
                    projects: usProjects,
                    submissions: usSubmissions,
                    groupPermissions: usPermissions,
                    is_notification_send: formattedList?.is_notification_send,
                    loading: false
                });
                response?.data?.[0]?.submissions?.map(item => {
                    item.title = item.title ? (item.title + ' - Rcvd. ' + item.submission_received_date) : null
                    return item
                })
                this.setState({ userDetails: this.formatSubmissionList(JSON.parse(JSON.stringify({ ...response?.data[0] }))) }, () => {
                    this.setState({ enablePermissions: this.state.userDetails?.user_assistants?.filter(item => item?.users_count > 0 ? true : false) })
                })
                console.log("User Details:", this.state.userDetails);

            },
                (err) => {
                    console.log("Error in fetching UserRole Details:", err)
                    this.setState({ loading: false });
                })
    }

    getEntity = (entity, entitytype, searchString) => {
        let loading = (entitytype === 'PROJECT') ? 'isProjectListLoading' : 
            (entitytype === 'SUBMISSION') ? 'isSubmissionListLoading' : 
                (entitytype === 'DIVISION') ? 'isDivisionListLoading' : 
                    (entitytype === 'USER_ASSISTANT') ? 'isAssistantLoading' : 'loading';
        this.setState({ [loading]: true });
        let groupName = this.state.group_name;
        let url = entitytype === 'USER_ASSISTANT' ? 
            `/entitySearch?entity=${entitytype}&groupName=${groupName}&searchString=${searchString}` : 
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`;
        ScripterService.getData(Constants.scripterServiceBaseUrl +
            url,
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = [];
                if (entitytype === 'PROJECT') {
                    formattedList = response?.data?.map(item => ({ value: item.project_id, text: item.project_title }));
                } else if (entitytype === 'SUBMISSION') {
                    formattedList = response?.data?.map(item => ({ value: item.submission_id, text: item.title + (item.received_date ? ` - Rcvd. ${item.received_date}` : '' ), submissionRcvdDate: item.received_date }));
                    formattedList.sort(function(a,b){
                        return new Date(b.submissionRcvdDate) - new Date(a.submissionRcvdDate);
                    });
                } else if (entitytype === 'DIVISION') {
                    formattedList = response?.data?.map(item => ({ value: item.division_id, text: item.division_name }));
                } else if (entitytype === 'USER_ASSISTANT') {
                    formattedList = response?.data?.map(item => ({ value: item.user_id, text: item.user_name, users_count: item?.users_count }));
                }
                this.setState({ [entity]: formattedList, [loading]: false });
            },
                (err) => {
                    this.setState({ [loading]: false });
                    console.log("Error in fetching entities:", err)
                })
    }

    getDivisions = () => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_division', null)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, text: item.name }));
                this.setState({ divisionDetails: formattedList });
            },

                (err) => {
                    console.log("Error in fetching Division Details:", err)
                })
    }

    getPermissions = () => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=permission', null)
            .then(response => {
                console.log("Permissions Response: ", response);
                this.setState({ allPermissions: response.data });
                console.log("All Permissions: ", this.state.allPermissions);
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                //this.setState({ divisionDetails: formattedList });
            },

                (err) => {
                    console.log("Error in fetching Permission Details:", err)
                })
    }
    getGroupPermissions = (groupId, id) => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/groupPermissions?groupId=${groupId}`,
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ permission_id: item.permission_id, permission_name: item.permission_name, entity_type: item.type }));
                if (id === 1) {
                    this.setState({ copyOfGroupPermissions: formattedList });
                }
                else {
                    this.setState({ groupPermissions: formattedList, copyOfGroupPermissions: formattedList, permissionsLoading: false });
                }
                console.log("Assigned Group permissions:", this.state.groupPermissions);
            },
                (err) => {
                    this.setState({ permissionsLoading: false })
                    console.log("Error in fetching Permission Details:", err)
                })
    }

    getGroups = () => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=group', null)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_assistant: item.is_assistant }));
                this.setState({ groupsDetails: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Group Details:", err)
                })
    }

    fieldChanged = (value, mode = "error") => {
        switch (mode) {
            case "error": return this.setState({ fieldChanged: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }

    closeState = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        }
        else {
            this.props?.handleClose();
        }
    }

    postUserDetails = (postJson) => {
        console.log("Post JSON", postJson);
        let extensionUrl = '/user'
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + extensionUrl, postJson, this.props.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                if (response.data.error) {
                    this.setState({ isPosting: false })
                    this.fieldChanged(true, "fail");
                }
                else {
                    this.setState({ isPosting: false })
                    this.fieldChanged(false, "error");
                    this.fieldChanged(true, "success");
                    // this.props?.handleClose();
                    console.log("post response", response);
                }
            },
                (err) => {
                    this.setState({ isPosting: false })
                    this.fieldChanged(true, "fail");
                    console.log("Post agency details error: " + err);
                });
    }

    getDivisionArrayDiff = (array1, array2) => {
        return array1.filter(object1 => {
            return !array2.some(object2 => {
                return object1.division_id === object2.division_id;
            });
        });
    }

    onDivisionsChangeCallback = (field, val, eventValue) => {
        let newValue = val.filter((value, index, arr) =>
                index === arr.findIndex((item) => (item.value === value.value))
            )
        let divisionValue = newValue?.map(item => ({ division_id: item.value, division_name: item.text, user_division_id: null }))
        if (this.props?.title === 'Edit User' && val?.length < this.state.userDetails?.divisions?.length) {
            const difference = [
                ...this.getDivisionArrayDiff(divisionValue, this.state.userDetails?.divisions),
                ...this.getDivisionArrayDiff(this.state.userDetails?.divisions, divisionValue),
              ];
            this.checkDivisionRemoveStatus(difference[0]?.division_id, divisionValue);
        } else {
            this.handleGeneralEdit('divisions', divisionValue);
        }
    }

    checkDivisionRemoveStatus = (divisionId, divisionVal) => {
        ScripterService.getData(Constants.scripterServiceBaseUrl +
            `/checkDivisionRemoveStatus?userId=${this.props?.id}&divisionId=${divisionId}`,
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
               if (!response?.data?.error) {
                    let result = response?.data[0];
                    this.setState({ 
                        isCoverageExists: result?.is_coverage_exists,
                        isSubmittedToUser: result?.is_submitted_to_user,
                        isWeekendReadAssignedToExecReadUser: result?.is_wr_assigned_exec_reading_user
                    }, () => {
                        if (this.state.isCoverageExists || this.state.isSubmittedToUser || this.state.isWeekendReadAssignedToExecReadUser == 1) {
                            this.handleGeneralEdit('divisions', this.state.userDetails?.divisions);
                        } else {
                            this.handleGeneralEdit('divisions', divisionVal);
                        }
                        this.setState({  
                            showCoverageExistsAlert: this.state.isCoverageExists == 1 ? true : false,
                            showIsSubmittedToUserAlert: this.state.isSubmittedToUser == 1 ? true : false,
                            showIsWeekendReadAssignedToExecReadUserAlert: this.state.isWeekendReadAssignedToExecReadUser == 1 ? true : false
                        })
                    })
               }
            },
                (err) => {
                    console.log("Error in fetching status:", err)
                })
    }

    onChangeCallback = (value) => {
        if (value) {
            this.setState({ fieldValid: validateEmail(value) });
        } else {
            this.setState({ fieldValid: true });
        }
        this.setState({
            accountEmail: value,
            postInitiated: false
        }, () => { this.fieldChanged(true, "error") })
    }

    onConfirm = () => {
        // if (this.state.failCustomize) {
        //     this.setState({failCustomize: false})
        // }
        this.props?.handleClose();
        this.props?.handleRefresh();
    }

    handleCheck = (e, elementName, elementType) => {
        let isChecked = e.target.checked
        console.log("State of Group Permissions:", this.state.groupPermissions);
        let formattedList = [...this.state.groupPermissions]
        let filteredPermission = {
            //user_group_permission_id: null,
            permission_id: +e.target.id,
            permission_name: elementName,
            entity_type: elementType
        }
        if (isChecked) {
            if (e.target.id == "custom") {
                this.setState({ custom: 1, enablePermissions: false })
            }
            else if (e.target.id == "notificationEmail") {
                this.setState({ is_notification_send: 1 })
            }
            else {
                if (elementName.includes("Edit") || elementName.includes("Delete")) {
                    let checkedPermissions = formattedList.filter(per => per.entity_type === elementType) //filtering permission based on the elementType
                    let permissionNames = []
                    permissionNames = checkedPermissions.length === 0 ? [] : checkedPermissions.filter(option => option.permission_name.includes("View")); //checking if View permissions is checked
                    if (permissionNames.length !== 0 && !(permissionNames === [])) {
                        this.setState(previousState => ({ groupPermissions: [...previousState.groupPermissions, filteredPermission] }));
                    }
                    else {
                        alert("Edit/Delete permissions cannot be provisioned without View");
                    }
                }
                else {
                    this.setState(previousState => ({ groupPermissions: [...previousState.groupPermissions, filteredPermission] }));
                    console.log("Post Push:", this.state.groupPermissions);
                }
            }
        }
        else {
            if (e.target.id == "custom") {
                this.setState({ custom: 0, enablePermissions: true, customAccessJustification: "", permissionsLoading: true })
                this.getGroupPermissions(this.state.group_id)
            }
            else if (e.target.id == "notificationEmail") {
                this.setState({ is_notification_send: 0 })
            }
            else {
                if (elementName.includes("View")) {
                    let checkedPermissions = this.state.groupPermissions.filter(option => option.permission_id != filteredPermission.permission_id) //removing unchecked permission from list
                    let uncheckedEntityPermissions = checkedPermissions.filter(per => per.entity_type === elementType) //Filtering permissions based on entity
                    let permissionNames = []
                    permissionNames = uncheckedEntityPermissions.length === 0 ? [] : uncheckedEntityPermissions.find(option => option.permission_name.includes("View")); //checking for View permission in the list
                    if (permissionNames) {
                        this.setState({ groupPermissions: checkedPermissions })
                    }
                    else { //unchecking Edit and Delete permissions as View is not checked
                        uncheckedEntityPermissions = uncheckedEntityPermissions.filter(per => per.permission_name.includes("Edit") || per.permission_name.includes("Delete"))
                        uncheckedEntityPermissions.map(item => (
                            checkedPermissions = checkedPermissions.filter(per => per.permission_id != item.permission_id)
                        ))
                        this.setState({ groupPermissions: checkedPermissions })
                    }
                }
                else if (elementName.includes("Edit") || elementName.includes("Delete")) {
                    let uncheckedEntityPermissions = this.state.groupPermissions.filter(per => per.entity_type === elementType) //checking for View permissions from the list of user Permissions
                    let viewPermissions = uncheckedEntityPermissions.filter(per => per.permission_name.includes("View"))
                    if (viewPermissions) {
                        alert("Please uncheck View Permissions of " + elementType);
                    }
                    else {
                        this.setState({ groupPermissions: this.state.groupPermissions.filter(item => item.permission_id != filteredPermission.permission_id && item.permission_id !== null) })
                    }
                }
                else {
                    this.setState({ groupPermissions: this.state.groupPermissions.filter(item => item.permission_id != filteredPermission.permission_id && item.permission_id !== null) })
                    console.log("Post Splice:", this.state.groupPermissions);
                }
            }
        }
        this.fieldChanged(true, "error")
    }

    setUserDetailsUniqueEntries = () => {
        let userGetDetails = { ...this.state.userDetails };
        userGetDetails.projects = [...userGetDetails.projects].filter((tag, index, array) =>
            (array.findIndex(t => t.project_id == tag.project_id && t.project_title == tag.project_title) == index) &&
            tag.project_id && tag.project_title);
        userGetDetails.submissions = [...userGetDetails.submissions].filter((tag, index, array) =>
            (array.findIndex(t => t.submission_id == tag.submission_id) == index) &&
            tag.submission_id);
        return userGetDetails;
    }

    postJsonGeneration = (userPermissions) => {
        this.setState({ isPosting: true })
        let groupName = this.state.groupsDetails.find(item => item.value == this.state.group_id)
        let projects = this.state.userDetails?.projects.filter(item => item.project_id !== null && item.project_title !== null)
        let submissions = this.state.userDetails?.submissions.filter(item => item.submission_id !== null && item.submission_title !== null)
        let assistants = this.state.userDetails?.user_assistants?.filter(item => item?.assistant_id !== null && item?.assistant_name !== null)
        if ((this.state.custom === null && JSON.stringify(this.state.copyOfGroupPermissions) === JSON.stringify(userPermissions)) ||
            (this.state.custom === 0 && JSON.stringify(this.state.copyOfGroupPermissions) === JSON.stringify(userPermissions)) ||
            (this.state.custom === 1 && JSON.stringify(this.state.copyOfGroupPermissions) != JSON.stringify(userPermissions))) {
            let finalArray = {
                "user_id": this.props?.id ? this.props.id : null,
                "user_name": this.state.fullName ? this.state.fullName : "",
                "department": this.state.department ? this.state.department : "",
                "title": this.state.title ? this.state.title : null,
                "account_email": this.state.accountEmail ? this.state.accountEmail : "",
                "phone": this.state.phone ? this.state.phone : null,
                "is_active": this.state.statusValue ? parseInt(this.state.statusValue) : null,
                "default_home_page": this.state.defaultHomePage ? this.state.defaultHomePage : null,
                "default_tenant_id": 1,
                "divisions": this.state.userDetails?.divisions ? this.state.userDetails?.divisions : null,
                "group_id": this.state.group_id ? this.state.group_id : null,
                "group_name": groupName.label ? groupName.label : null,
                "is_customized": this.state.custom ? this.state.custom : 0,
                "custom_access_justification": this.state.customAccessJustification ? this.state.customAccessJustification : null,
                //"permissions": this.state.groupPermissions ? this.state.groupPermissions : null,
                "permissions": userPermissions ? userPermissions : null,
                "projects": projects,
                "submissions": submissions,
                "user_assistants": assistants,
                "is_notification_send": this.state.is_notification_send ? this.state.is_notification_send : null
            }
            console.log("Final Array", finalArray);
            if (this.validateErrorFlags(finalArray)) {
                console.log("Post JSON", finalArray)
                this.postUserDetails(finalArray);
            }
            else {
                console.log("Post JSON", finalArray)
                this.setState({ isPosting: false })
                console.log("Failed");
                //alert("Submit fail")
            }
        }
        else {
            this.setState({ isPosting: false })
            alert("Please customize permissions");
            //this.setState({ failCustomize: true})
        }
    }
    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    handleSubmitUser = () => {
        this.setState({
            postInitiated: true, isPosting: true
        }, () => {
            let userPermissions = this.state.groupPermissions;
            console.log("user Permissions: ", userPermissions);
            if (this.state.copyOfGroupPermissions.length === 0) {
                this.getGroupPermissions(this.state.group_id, 1)
                this.sleep(4000).then(r => {
                    this.postJsonGeneration(userPermissions);
                })
            }
            else {
                this.postJsonGeneration(userPermissions);
            }
        })
    }

    formatSubmissionList = (response) => {
        // if (response?.divisions?.length === 0) {
        //     response?.divisions.push(JSON.parse(JSON.stringify({ ...subDivisions })));
        // }
        if (response?.projects?.length === 0) {
            response?.projects.push(JSON.parse(JSON.stringify({ ...subProjects })));
        }
        if (response?.submissions?.length === 0) {
            response.submissions.push(JSON.parse(JSON.stringify({ ...subSubmissions })))
        }
        if (response?.user_assistants?.length === 0) {
            response?.user_assistants?.push(JSON.parse(JSON.stringify({ ...initialAssistantConfig })))
        }
        return response;
    }

    validateErrorFlags = (postUserJson) => {
        let emailValid = (postUserJson?.account_email) && (validateEmail(postUserJson?.account_email))
        let phoneValid = postUserJson?.phone ? 
            (validatePlusCharacter((postUserJson?.phone.trim()).charAt(0)) ?
                validateIntlNumber(postUserJson?.phone) && postUserJson?.phone.length <= sizeLimits.phoneLimitIntl :
                (validateUSNumber(postUserJson?.phone) || validatePhoneNumber(postUserJson?.phone)) && postUserJson?.phone.length <= sizeLimits.phoneLimitUS) : false;
        let canSubmit = emailValid && phoneValid && (postUserJson?.user_name?.length <= userLimit.fullName) && (postUserJson?.default_home_page)
            && (postUserJson?.department !== "" && postUserJson?.department?.length <= userLimit.department) && (postUserJson?.divisions?.some(div => ((div.division_id && div.division_name) || false)))
            && (postUserJson?.group_name) && (postUserJson?.is_customized === 1 ? postUserJson?.custom_access_justification?.length <= userLimit.justification : true)
        return canSubmit;
    }

    removeCallback = (listName, callbackItem) => {
        let userDetails = { ...this.state.userDetails };
        if (!callbackItem.canRemove) {
            let index = userDetails[listName].indexOf(callbackItem);
            if (index > -1) {
                userDetails[listName].splice(index, 1);
            }
        } else {
            let list = userDetails[listName]?.filter(item => item.index !== callbackItem.index);
            userDetails[listName] = list;
        }
        this.setState({ userDetails: userDetails });
        this.fieldChanged(true, "error")
    }

    addAdditionalField = (listName) => {
        let userDetail = JSON.parse(JSON.stringify({ ...this.state.userDetails }));
        switch (listName) {
            case 'projects':
                let initialProjectArray = JSON.parse(JSON.stringify({ ...subProjects }));
                let project_list = [...userDetail.projects];
                initialProjectArray.index = this.state.projectIndex;
                initialProjectArray.canRemove = true;
                project_list.push(initialProjectArray)
                userDetail.projects = project_list;
                this.setState({ projectIndex: this.state.projectIndex + 1, userDetails: userDetail });
                break;
            case 'submissions':
                let initialSubmissionsArray = JSON.parse(JSON.stringify({ ...subSubmissions }));
                let submissions_list = [...userDetail.submissions];
                initialSubmissionsArray.index = this.state.submissionIndex;
                initialSubmissionsArray.canRemove = true;
                submissions_list.push(initialSubmissionsArray)
                userDetail.submissions = submissions_list;
                this.setState({ submissionIndex: this.state.submissionIndex + 1, userDetails: userDetail });
                break;
            case 'user_assistants':
                let initialUserAssistantsArray = JSON.parse(JSON.stringify({ ...initialAssistantConfig }));
                let user_assistant_list = [...userDetail.user_assistants];
                initialUserAssistantsArray.index = this.state.assistantIndex;
                initialUserAssistantsArray.canRemove = true;
                user_assistant_list.push(initialUserAssistantsArray)
                userDetail.user_assistants = user_assistant_list;
                this.setState({ assistantIndex: this.state.assistantIndex + 1, userDetails: userDetail });
                break;
        }
    }
    validateValues = (value) => {
        this.setState({
            phoneValid:
                value.length > 0 ?
                    (validatePlusCharacter((value.trim()).charAt(0)) ?
                        validateIntlNumber(value) : validateUSNumber(value) || validatePhoneNumber(value)) : false,
            phone: value
        });
        this.fieldChanged(true, "error")
    }

    handleListEdit = (field, value, obj, listName) => {
        console.log("Field & Value", field, value);
        let userDetails = this.state.userDetails;
        let id = (listName === "projects") ? "project_id" : 
            (listName === "submissions") ? "submission_id" : "assistant_id";
        let list = userDetails[listName]?.map(item => {
            let currentVal = item;
            if ((!obj[id] && item.id && obj.id && (item.id === obj.id)) || (item.index && obj.index && (item.index === obj.index)) || (item.project === null || item.submission === null || item.assistant === null)
                || (item[id] && obj[id] && (item[id] === obj[id]))) {
                //if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                currentVal[field] = value;
                if (field === 'project') {
                    currentVal.project_id = value ? value?.value : null;
                    currentVal.project_title = value ? value?.text : null;
                } else if (field === 'submission') {
                    currentVal.submission_id = value ? value?.value : null;
                    currentVal.title = value ? value?.text : null;
                } else if (field === 'assistant') {
                    currentVal.assistant_id = value ? value?.value : null;
                    currentVal.assistant_name = value ? value?.text : null;
                    currentVal['users_count'] = value ? value?.users_count : null;
                    if (!this.state.custom || currentVal?.users_count > 0) {
                        this.setState({ enablePermissions: true })
                    } else {
                        this.setState({ enablePermissions: false })
                    }
                }
                return currentVal;
            } return currentVal;
        })
        this.handleGeneralEdit(field, value);
    }

    handleGeneralEdit = (field, value) => {
        console.log("Value", value);
        let newValue = value;
        if (field === 'project') {
            this.setState(prevState => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field + '_id']: newValue?.value || null
                },
                postInitiated: false
            }))
            console.log("user list:", this.state.userDetails);
        }
        if (field === 'submission') {
            this.setState(prevState => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field + '_id']: newValue?.value || null
                },
                postInitiated: false
            }))
        }
        if (field === 'divisions') {
            this.setState(prevState => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field]: newValue
                },
                postInitiated: false
            }))
            console.log("User Details:", this.state.userDetails);
        }
        this.fieldChanged(true, "error")
    }

    handleGroupChange = (groupValue) => {
        if (this.state.userDetails?.assistants_count > 0 && 
            this.state.userDetails?.user_assistants?.filter(item => item?.assistant_id !== null)?.length > 0 
        ) {
            this.setState({ showAssistantExsistAlert: true })
        } else if (this.state.userDetails?.users_count > 0) {
            this.setState({ showBossUserExsistAlert: true })
        } else {
            this.handleGroupChangeUpdate(groupValue);
        }
    }

    handleGroupChangeUpdate = (value) => {
        this.setState({ 
            group_id: value, 
            group_name: this.state.groupsDetails.find(item => item.value == value)?.label,
            isAssistant: this.state.groupsDetails?.find(item => item.value == value)?.is_assistant == 1 ? true : false,
            custom: 0, 
            customAccessJustification: "", 
            is_notification_send: 1, 
            permissionsLoading: true 
        }, this.getGroupPermissions(value), this.fieldChanged(true, "error")) 
    }

    handleCustomCheckDisable = () => {
        let assistantsWithBoss = this.state.userDetails?.user_assistants?.filter(item => item.users_count > 0)
        if (assistantsWithBoss?.length > 0) {
            return true;
        } else {
            return false;
        } 
    }
    
    render() {
        const { classes } = this.props;
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            (this.state.showSuccess && (this.props?.id)) ? this.state.saveEditMessage : this.state.failCustomize ? this.state.permissionsCustomizeMessage :
                (this.state.showSuccess && (this.props?.id === "")) ? `New User has been created!` : "";
        let divisionRemoveAccessAlert = this.state.showCoverageExistsAlert ? 'ERROR: Cannot change division. User has an assigned coverage awaiting action.' :
            this.state.showIsSubmittedToUserAlert ? 'ERROR: Cannot change division. User has one or more submissions Submitted To them' : 
                this.state.showIsWeekendReadAssignedToExecReadUserAlert ? 'ERROR: Cannot change division. User is assigned to an active/future Weekend Read' : '';
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failTitle : this.state.failCustomize ? this.state.failTitle :
            this.state.showSuccess ? this.state.saveTitle : "";
        let changeGroupAccessAlert = this.state.showAssistantExsistAlert ? 'This user have one or more assistants. Remove assistants in order to change the group.' :
            this.state.showBossUserExsistAlert ? 'This action cannot be done as the user is linked to one or more executives.' : '';
        return (
            <div className="MainViewModalContainer" >
                <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1'
                    show={this.props?.open ? this.props?.open : false}
                    onClose={this.props?.handleClose}
                    hideBackdrop={true}
                    id="modal">
                    <MDBModalDialog size='md'>
                        <MDBModalBody>
                            <MDBModalContent>
                                <MDBContainer className='CreateOrEditUserModalContainer'>
                                    <MDBRow className="ModalHeader" id="urmHeader">
                                        <MDBCol md={5} className="HeaderText" >{this.props?.title}</MDBCol>
                                        <MDBCol md={6} className="HeaderText" ></MDBCol>
                                        <MDBCol md={1} className="closeIcon"
                                            onClick={this.closeState}
                                        ><CancelIcon style={{ fill: "white" }} /></MDBCol>
                                    </MDBRow>
                                    {!this.state.loading ?
                                        <MDBRow className="ModalContent">
                                            <MDBCol>
                                                <MDBRow>
                                                    <BasicLabel className="modalSubheadings"
                                                        type={"subHeading"}
                                                        text={"Details"}
                                                    />
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol sm="6">
                                                        <BasicTextField
                                                            label="Full Name"
                                                            isMandatory={true}
                                                            value={this.state.fullName}
                                                            limit={userLimit.fullName}
                                                            limitWarning={messages.exceed50CharacterWarning || ""}
                                                            showMandatory={this.state.postInitiated}
                                                            onChange={(e) => { this.setState({ fullName: e.target.value, postInitiated: false }, () => { this.fieldChanged(true, "error") }) }}
                                                        />
                                                    </MDBCol>
                                                    <MDBCol sm="4" id="StatusSelectField">
                                                        <SelectField
                                                            id={"key15"}
                                                            value={this.state.statusValue}
                                                            label={"Status"}
                                                            options={selectOptions}
                                                            onChange={(e) => this.setState({ statusValue: e.target.value }, () => { this.fieldChanged(true, "error") })}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol sm="6">
                                                        <BasicTextField
                                                            label="Department"
                                                            isMandatory={true}
                                                            value={this.state.department}
                                                            limit={userLimit.department}
                                                            showMandatory={this.state.postInitiated}
                                                            limitWarning={messages.exceed50CharacterWarning || ""}
                                                            onChange={(e) => this.setState({ department: e.target.value }, () => { this.fieldChanged(true, "error") })}
                                                        />
                                                    </MDBCol>
                                                    <MDBCol sm="6">
                                                        <BasicTextField
                                                            label="Title"
                                                            isMandatory={false}
                                                            value={this.state.title}
                                                            limit={userLimit.title}
                                                            limitWarning={messages.exceed50CharacterWarning || ""}
                                                            onChange={(e) => this.setState({ title: e.target.value }, () => { this.fieldChanged(true, "error") })}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol sm="6">
                                                        <BasicTextField
                                                            label="Account Email"
                                                            isMandatory={true}
                                                            value={this.state.accountEmail}
                                                            limit={userLimit.email}
                                                            limitWarning={messages.exceed50CharacterWarning || ""}
                                                            showMandatory={this.state.postInitiated}
                                                            onChange={(e) => this.onChangeCallback(e.target.value)}
                                                            fieldValid={this.state.fieldValid === false ? true : false}
                                                            inValidInput={messages.invalidInput}
                                                        />
                                                    </MDBCol>
                                                    <MDBCol sm="3">
                                                        <BasicTextField
                                                            label="Phone"
                                                            placeholder="(___)-___-____"
                                                            isMandatory={true}
                                                            showMandatory={this.state.postInitiated}
                                                            value={this.state.phone || ""}
                                                            fieldValid={this.state.phoneValid === false ? true : false}
                                                            inValidInput={messages.invalidInput}
                                                            limit={this.state.phone ? (validatePlusCharacter((this.state.phone.trim()).charAt(0)) ? sizeLimits.phoneLimitIntl : sizeLimits.phoneLimitUS) : null}
                                                            limitWarning={this.state.phone ? (validatePlusCharacter((this.state.phone.trim()).charAt(0)) ? messages.phoneLimitIntl : messages.phoneLimitUs) : null}
                                                            onChange={(e) => this.validateValues(e.target.value)}
                                                        />
                                                    </MDBCol>
                                                    <MDBCol md="3" id="StatusSelectField">
                                                        <SelectField
                                                            label="Default Home Page"
                                                            isMandatory={true}
                                                            showMandatory={this.state.postInitiated}
                                                            options={selectHomePage}
                                                            value={this.state.defaultHomePage}
                                                            onChange={(e) => this.setState({ defaultHomePage: e.target.value }, () => { this.fieldChanged(true, "error") })}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <BasicLabel
                                                        type={"subHeading"}
                                                        text={"Permissions"}
                                                    />
                                                </MDBRow>
                                                <MDBRow>
                                                    {/* <MDBCol md="5">
                                                    <SelectField
                                                        label="Studio Division"
                                                        options={this.state.divisionDetails}
                                                        value={this.state.userRoleDetails.division_name || ""}
                                                        onChange={(e) => this.handleGeneralEdit("division", e.target.value)}
                                                    />
                                                </MDBCol> */}
                                                    <MDBCol md={6} id='divisionDropDown'
                                                        className={`${this.state.userDetails?.divisions?.length === 0 ? "searchSelectHeight studioDivisionSearchSelect" : "studioDivisionSearchSelect"}`}>
                                                        <BasicLabel text={"Studio Division"} isMandatory={true} />
                                                        <SearchSelectField
                                                            width={'300'}
                                                            isMandatory={true}
                                                            showMandatory={this.state.postInitiated}
                                                            multiple={true}
                                                            value={this.state?.userDetails?.divisions.map(item => ({ value: item.division_id, text: item.division_name })) || []}
                                                            options={this.state.divisionDetails}
                                                            onChange={(e, value) => this.onDivisionsChangeCallback('divisions', value, e.target.value)} />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md={4} sm="3" id="StatusSelectField">
                                                        <SelectField
                                                            label="Group"
                                                            isMandatory={true}
                                                            showMandatory={this.state.postInitiated}
                                                            options={this.state.groupsDetails}
                                                            value={this.state.group_id || ""}
                                                            onChange={(e) => this.handleGroupChange(e.target.value)}
                                                        />
                                                    </MDBCol>
                                                    <MDBCol md={2} className="checkBox" sm="1">
                                                        <BasicCheckbox
                                                            className="UserCheckBox"
                                                            id={"custom"}
                                                            label="Custom"
                                                            checked={this.state.custom === 1}
                                                            onChange={(e) => { this.handleCheck(e), () => { this.fieldChanged(true, "error") } }}
                                                            disabled={this.handleCustomCheckDisable() || false}
                                                        />
                                                    </MDBCol>
                                                    {(!this.state.isAssistant  && this.state.group_name !== 'Feature Story Admin' &&
                                                        this.state.group_name !== 'Story Analyst' && this.state.group_name !== 'Feature Casting' &&
                                                        this.state.group_name !== 'Production Technology' && this.state.group_name !== 'User Provisioner'
                                                    )?
                                                        <MDBCol sm="6" id="searchSelectListContainer">
                                                            <BasicLabel text={"Assistants"} type={"text"} />
                                                            {
                                                                this.state.userDetails?.user_assistants?.map((assistant, index) => (
                                                                    <SearchSelectList
                                                                        id={"assistant" + assistant?.assistant_id}
                                                                        detail_selected={assistant?.assistant_id && assistant?.assistant_name ? {
                                                                            value: assistant?.assistant_id,
                                                                            text: assistant?.assistant_name
                                                                        } : null}
                                                                        placeholder="-Search Assistants-"
                                                                        options={this.state.assistantList || []}
                                                                        showAdd={((this.state.userDetails?.user_assistants?.length - 1) === index)}
                                                                        showRemove={this.state.userDetails?.user_assistants.length > 1}
                                                                        addCallback={() => this.addAdditionalField('user_assistants')}
                                                                        removeCallback={() => this.removeCallback('user_assistants', assistant)}
                                                                        valueSelected={(e, value) => this.handleListEdit("assistant", value, assistant, "user_assistants")}
                                                                        searchText={(e) => this.getEntity('assistantList', 'USER_ASSISTANT', e.target.value)}
                                                                        multiple={false}
                                                                        searchSelect={true}
                                                                    />
                                                                ))
                                                            }

                                                        </MDBCol> : null
                                                    }
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol sm="6" id="searchSelectListContainer">
                                                        <BasicLabel text={"Other Project Access"} type={"text"} />
                                                        {
                                                            this.state.userDetails?.projects.map((project, index) => (
                                                                <SearchSelectList
                                                                    //isMandatory={true}
                                                                    //showMandatory={this.state.postInitiated}
                                                                    id={"projects" + project?.project_id}
                                                                    value={{ value: project?.project_id, text: project?.project_title } || {}}
                                                                    placeholder="-Search Projects-"
                                                                    options={this.state.projectList || []}
                                                                    showAdd={((this.state.userDetails?.projects?.length - 1) === index)}
                                                                    //&& project?.project_id}
                                                                    //&& (Object.values(project).some(x => x && x !== project.index && x !== true))) ? true : false}
                                                                    showRemove={this.state.userDetails?.projects.length > 1}
                                                                    addCallback={() => this.addAdditionalField('projects')}
                                                                    removeCallback={() => this.removeCallback('projects', project)}
                                                                    onChange={(e, value) => this.handleListEdit("project", value, project, "projects")}
                                                                />
                                                            ))}
                                                    </MDBCol>
                                                    <MDBCol sm="6" id="searchSelectListContainer">
                                                        <BasicLabel text={"Other Submission Access"} type={"text"} />
                                                        {
                                                            this.state.userDetails?.submissions.map((submission, index) => (
                                                                <SearchSelectList
                                                                    //isMandatory={true}
                                                                    //showMandatory={this.state.postInitiated}
                                                                    id={"submissions" + submission?.submission_id}
                                                                    detail_selected={submission?.submission_id && submission?.title ? {
                                                                        value: submission?.submission_id,
                                                                        text: submission?.title
                                                                    } : null}
                                                                    placeholder="-Search Submissions-"
                                                                    options={this.state.submissionList || []}
                                                                    showAdd={((this.state.userDetails?.submissions?.length - 1) === index)}
                                                                    showRemove={this.state.userDetails?.submissions.length > 1}
                                                                    addCallback={() => this.addAdditionalField('submissions')}
                                                                    removeCallback={() => this.removeCallback('submissions', submission)}
                                                                    valueSelected={(e, value) => this.handleListEdit("submission", value, submission, "submissions")}
                                                                    searchText={(e) => this.getEntity('submissionList', 'SUBMISSION', e.target.value)}
                                                                    multiple={false}
                                                                    searchSelect={true}
                                                                />
                                                            ))
                                                        }

                                                    </MDBCol>
                                                </MDBRow>
                                                <>
                                                    {this.state.custom === 1 ? (<>
                                                        <MDBRow className="customAccess">
                                                            <BasicTextArea
                                                                isMandatory={true}
                                                                label={"Custom Access Justification"}
                                                                id={"textarea-customAccess"}
                                                                showMandatory={this.state.postInitiated}
                                                                rows={1}
                                                                limit={userLimit.justification}
                                                                limitWarning={messages.exceed100CharacterWarning}
                                                                value={this.state.customAccessJustification}
                                                                onChange={(e) => this.setState({ customAccessJustification: e.target.value }, () => { this.fieldChanged(true, "error") })}
                                                            />
                                                        </MDBRow>
                                                    </>)
                                                        : (<> </>)}
                                                </>
                                                {!this.state.permissionsLoading ?
                                                    <div>
                                                        <MDBRow sm={4}>
                                                            <BasicLabel text={"URM"} />
                                                            {Object.values(this.state.allPermissions).filter(item => item.type === "URM").map((element) =>
                                                                <BasicCheckbox
                                                                    id={element.id}
                                                                    label={element.name}
                                                                    value={element.id}
                                                                    disabled={true}
                                                                    //checked={this.state.userGetDetails?.permissions.find(permission => permission.permission_id == element.id) ? true : false}
                                                                    //checked={this.state.isUserRoleManager ? true : false}
                                                                    checked={this.state.groupPermissions.find(permission => permission.permission_id == element.id) ? true : false}
                                                                    onChange={(e) => this.handleCheck(e, element.name, "URM")}
                                                                />
                                                            )}
                                                        </MDBRow>
                                                        {console.log("URM Permissions check:", this.state.groupPermissions)}
                                                        <>
                                                            {this.state.groupPermissions.find(item => item.permission_id === 1) ? (<> </>) :
                                                                (<>
                                                                    <MDBRow sm={12}>
                                                                        <MDBCol sm={4}>
                                                                            {console.log("checkbox status: ", this.state.enablePermissions)}
                                                                            {console.log("edited Permissions:", this.state.groupPermissions)}
                                                                            <BasicLabel text={"Submissions"} />
                                                                            {Object.values(this.state.allPermissions).filter(item => item.type === "SUBMISSIONS").map((element) =>
                                                                                <BasicCheckbox
                                                                                    id={element.id}
                                                                                    label={element.name}
                                                                                    value={element.id}
                                                                                    disabled={this.state.enablePermissions}
                                                                                    //checked={this.state.userGetDetails?.permissions.find(permission => permission.permission_id == element.id) ? true : false}
                                                                                    checked={this.state.groupPermissions.find(permission => permission.permission_id == element.id) ? true : false}
                                                                                    onChange={(e) => this.handleCheck(e, element.name, "SUBMISSIONS")}
                                                                                />
                                                                            )}
                                                                            <BasicLabel text={"Deals"} />
                                                                            {Object.values(this.state.allPermissions).filter(item => item.type === "DEALS").map((element) =>
                                                                                <BasicCheckbox
                                                                                    id={element.id}
                                                                                    label={element.name}
                                                                                    value={element.id}
                                                                                    disabled={this.state.enablePermissions}
                                                                                    //checked={this.state.userGetDetails?.permissions.find(permission => permission.permission_id == element.id) ? true : false}
                                                                                    checked={this.state.groupPermissions.find(permission => permission.permission_id == element.id) ? true : false}
                                                                                    onChange={(e) => this.handleCheck(e, element.name, "DEALS")}
                                                                                />
                                                                            )}
                                                                        </MDBCol>
                                                                        <MDBCol sm={4}>
                                                                            <BasicLabel text={"Projects"} />
                                                                            {Object.values(this.state.allPermissions).filter(item => item.type === "PROJECTS").map((element) =>
                                                                                <BasicCheckbox
                                                                                    id={element.id}
                                                                                    label={element.name}
                                                                                    value={element.id}
                                                                                    disabled={this.state.enablePermissions}
                                                                                    //checked={this.state.userGetDetails?.permissions.find(permission => permission.permission_id == element.id) ? true : false}
                                                                                    checked={this.state.groupPermissions.find(permission => permission.permission_id == element.id) ? true : false}
                                                                                    onChange={(e) => this.handleCheck(e, element.name, "PROJECTS")}
                                                                                />
                                                                            )}
                                                                        </MDBCol>
                                                                        <MDBCol sm={4}>
                                                                            <BasicLabel text={"Weekend Reads"} />
                                                                            {Object.values(this.state.allPermissions).filter(item => item.type === "WEEKEND_READS").map((element) =>
                                                                                <BasicCheckbox
                                                                                    id={element.id}
                                                                                    label={element.name}
                                                                                    value={element.id}
                                                                                    disabled={this.state.enablePermissions}
                                                                                    //checked={this.state.userGetDetails?.permissions.find(permission => permission.permission_id == element.id) ? true : false}
                                                                                    checked={this.state.groupPermissions.find(permission => permission.permission_id == element.id) ? true : false}
                                                                                    onChange={(e) => this.handleCheck(e, element.name, "WEEKEND_READS")}
                                                                                />
                                                                            )}
                                                                            <BasicLabel text={"Data Maintenance"} />
                                                                            {Object.values(this.state.allPermissions).filter(item => item.type === "DATA_MAINTENANCE").map((element) =>
                                                                                <BasicCheckbox
                                                                                    id={element.id}
                                                                                    label={element.name}
                                                                                    value={element.id}
                                                                                    disabled={this.state.enablePermissions}
                                                                                    //checked={this.state.userGetDetails?.permissions.find(permission => permission.permission_id == element.id) ? true : false}
                                                                                    checked={this.state.groupPermissions.find(permission => permission.permission_id == element.id) ? true : false}
                                                                                    onChange={(e) => this.handleCheck(e, element.name, "DATA_MAINTENANCE")}
                                                                                />
                                                                            )}
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                </>)
                                                            }
                                                        </>
                                                        <MDBRow>
                                                            <MDBCol>
                                                                <BasicLabel text={"Notifications"} />
                                                                <BasicCheckbox
                                                                    id={"notificationEmail"}
                                                                    label={"Will receive notification emails"}
                                                                    value={this.state.is_notification_send}
                                                                    disabled={this.state.group_id == 2 ? true : false}
                                                                    //disabled={true}
                                                                    //checked={ (this.state.group_id !== null && this.state.is_notification_send) ? 1 : 0 }
                                                                    checked={this.state.is_notification_send || this.state.group_id === 2 ? 1 : 0}
                                                                    onChange={(e) => this.handleCheck(e)}
                                                                />
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </div> :
                                                    <div className="ContentLoader">
                                                        <CircularProgress />
                                                    </div>}
                                                <MDBRow className="p-3">
                                                    <MDBCol md={8}>
                                                    </MDBCol>
                                                    <MDBCol md={2} className="usermodalButton2">
                                                        <BasicButton
                                                            className="basic-btn"
                                                            type="inline"
                                                            variant="contained"
                                                            text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : this.props?.primaryButtonText}
                                                            onClick={this.handleSubmitUser}
                                                            disabled={!this.state.fieldChanged}
                                                        />
                                                    </MDBCol>
                                                    <MDBCol md={2} className="usermodalButton">
                                                        <BasicButton
                                                            variant="outlined"
                                                            type="inline"
                                                            text={this.props?.secondaryButtonText}
                                                            onClick={this.closeState}
                                                            disabled={false}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                        :
                                        <div className="ContentLoader">
                                            <CircularProgress />
                                        </div>
                                    }
                                </MDBContainer>
                            </MDBModalContent>
                        </MDBModalBody>
                    </MDBModalDialog>
                </MDBModal>
                <MessageModal
                    open={this.state.showError || this.state.showFail || this.state.showSuccess || this.state.failCustomize || false}
                    title={comfirmModalTitle}
                    message={message}
                    hideCancel={this.state.showFail || this.state.showSuccess || false}
                    primaryButtonText={"OK"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={this.onConfirm}
                    handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false, failCustomize: false })} 
                />
                <MessageModal
                    open={this.state.showCoverageExistsAlert || 
                        this.state.showIsSubmittedToUserAlert || 
                        this.state.showIsWeekendReadAssignedToExecReadUserAlert
                    }
                    title={"Remove Division Access"}
                    message={divisionRemoveAccessAlert}
                    hideCancel={true}
                    primaryButtonText={"OK"}
                    onConfirm={(e) => this.setState({ 
                        showCoverageExistsAlert: false, 
                        showIsSubmittedToUserAlert: false, 
                        showIsWeekendReadAssignedToExecReadUserAlert: false 
                    })}
                />
                <MessageModal
                    open={this.state.showAssistantExsistAlert || 
                        this.state.showBossUserExsistAlert 
                    }
                    title={"Access Denied!"}
                    message={changeGroupAccessAlert}
                    hideCancel={true}
                    primaryButtonText={"OK"}
                    onConfirm={(e) => this.setState({ 
                        showAssistantExsistAlert: false, 
                        showBossUserExsistAlert: false, 
                    })}
                />
            </div>
        );
    }
}
export default withUserContext(CreateOrEditUserModal);