import React from 'react';
import {
    MDBContainer, MDBRow, MDBCol, MDBIcon, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem
} from "mdbreact";
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import SelectField from '../../../SharedComponents/SelectField/SelectField';
import DealsDetailsContainer from './DealsDetailsContainer'
import DealSteps from './DealSteps'
import './DealsDetails.scss';
import scripterService from "../../../../services/service";
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';
import moment from "moment";
import { DealQueueInitialJson } from '../config'
import MessageModal from '../../../SharedComponents/MessageModal';
import Loader from '../../../../Common/Loader/Loader';
import { getFormattedDate } from '../../../../Common/Helper';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 350,
		border: "1px solid #dadde9",
		marginTop: "-0.1rem",
		fontSize: "0.75rem",
		padding: "0.5rem 0.5rem 0"
	}
}))(Tooltip);
class DealsDetails extends React.Component {
    constructor(props) {
        super(props); {
            this.state = {
                isAmendment: false,
                dealMemoId: null,
                amendmentId: null,
                dealsDetails: null,
                amendmentDetails: null,
                dealAmendmentSelectData: [],
                selectedDate: {},
                isSelectChange: false,
                listData: [],
                showError: false,
                showSuccess: false,
                showFail: false,
                showReportFail: false,
                saveTitle: "Success",
                saveEditMessage: "The changes are saved successfully!",
                saveAddQueue: "Deal has been added to your My Queue",
                saveRemoveQueue: "Deal has been removed from your Queue",
                failAddQueue: "Failed to Add in a Queue",
                failRemoveQueue: "Failed to Remove from Queue",
                deleteSuccess: "Deleted Successfully",
                deleteFailure: "Fail to Delete Deal",
                failTitle: "Failure",
                failMessage: "Saving changes failed!",
                unsavedTitle: "Unsaved Changes",
                unsavedMessage: "Are you sure you want to Delete?",
                redirectMsg: "Access Denied",
                mode: null,
                isDeleteClick: false,
                refreshPage: false,
                isRedirectionPopup: false,
                showPageLoader: false,
                tenant_id: null,
                dealRefreshPage: false,
                getDealDetails: () => this.getDealDetails(),
                canViewDeal: false,
                canModifyDeal: false,
                canDeleteDeal: false,
                canViewDealDetails: true,
                fetchingDealDetails: false,
                list: []
            }
        }
    }
    static getDerivedStateFromProps(props, state) {
        if ((props.refreshPage?.value !== state.dealRefreshPage) && props.refreshPage?.modal === 'Deal') {
            if (props.refreshPage?.value) {
                state.getDealDetails();
                props.toggleRefreshPage(props.refreshPage?.modal)
            }
            return {
                dealRefreshPage: props.refreshPage?.value || false
            }
        }
        // if (props?.userContext?.active_tenant?.tenant_id !== state.tenant_id) {
        //     state.getDealDetails();
        //     return {
        //         tenant_id: props?.userContext?.active_tenant?.tenant_id
        //     }
        // }
        return null;
    }
    checkPermission() {
        let permissions = this.props?.userContext?.active_tenant?.permissions;
        this.setState({
            canViewDeal: permissions?.canViewDeal,
            canModifyDeal: permissions?.canModifyDeal,
            canDeleteDeal: permissions?.canDeleteDeal
        })
        // if (!permissions?.canViewDeal) {
        //     this.setState({ mode: "DENIED", isRedirectionPopup: true })
        //     this.manageMessageModal("Success", true);
        // }
    }
    componentDidMount() {
        console.log("Deal details")
        this.checkPermission();
        if (isNaN(this.props.match?.params?.id)) {
            this.props?.history?.push('/')
        }
        else {
            this.setState({ dealMemoId: this.props.match?.params?.id, dealRefreshPage: this.props.refreshPage?.value }, () => {
                this.getDealDetails();
            })
        }

    }
    formatDate(date) {
        if (date) {
            return moment(date).format("MM/DD/YYYY");
        }
        return "";
    }

    formatDateFormat(date) {
        if (date) {
            return moment(date).format("YYYY-MM-DD");
        }
        return "";
    }

    getDealDetails() {
        this.setState({ fetchingDealDetails: true, canViewDealDetails: true })
        let id = this.state?.dealMemoId;
        if (id) {
            let queryUrl = '';
            queryUrl = `dealMemoId=${id}&dealAmmendmentId=null`
            scripterService.getData(Constants.scripterServiceBaseUrl + `/dealMemo?${queryUrl}`, this.props?.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    if (response?.data?.error) {
                        this.setState({ fetchingDealDetails: false, canViewDealDetails: true })
                        return;
                    }
                    let data = response.data[0];
                    console.log(data);
                    let postData = JSON.parse(JSON.stringify({ ...data }));
                    this.setState({ 
                        dealsDetails: { ...postData }, 
                        canViewDealDetails: data?.is_view_deal_memo == 1 ? true : false,
                        isAssignedToProjectPermission: (data && data?.is_assigned_to_project_permission == 1) ? true : false
                    })
                    let list = [];
                    let memo_date = getFormattedDate(postData?.memo_date)
                    let stepList = this.generateTabelList(postData?.steps, 'stepData');
                    stepList.sort(function (a, b) {
                        return parseInt(a.no) - parseInt(b.no);
                    });
                    postData?.deal_ammendments?.map((item, index) => {
                        this.sortStepNumber(item.ammendment_steps);
                        list.push(this.generateTabelList(item?.ammendment_steps, 'ammendmentData', item?.ammendment_date, item?.deal_ammendment_id))
                    })
                    this.setState({
                        list: list,
                        stepList: stepList,
                        memo_date: memo_date,
                        fetchingDealDetails: false,
                    })

                }, err => {
                    console.log("Error from get deal memo", err);
                });
        }
    }

    sortStepNumber = (dealSteps) => {
        dealSteps.sort(function (a, b) {
            return parseInt(a.step_number) - parseInt(b.step_number);
        });
    }

    handleShowModals = (stepType = null, amendmentSteps = null, type, index = null, id = null) => {
        let scriptDetails = {};
        if (type === 'generateScript' && stepType === 'dealStep') {
            scriptDetails.next_step = this.state.stepList?.[index + 1]?.name || null;
            scriptDetails.reading_end = this.state.stepList?.[index]?.readingend || null
            scriptDetails.dealMemoId = this.state.dealsDetails?.deal_memo_id || null;
            scriptDetails.dealMemoStepId = this.state.stepList?.[index]?.id || null;
            } else if(type === 'generateScript' && stepType === 'amendmentStep') {
                console.log(amendmentSteps?.[index+1]?.step_name);
            scriptDetails.next_step = amendmentSteps?.[index+1]?.step_name || null;
            scriptDetails.reading_end = amendmentSteps?.[index]?.reading_end || null
            scriptDetails.dealMemoId = this.state.dealsDetails?.deal_memo_id || null;
            scriptDetails.dealMemoStepId = amendmentSteps?.[index]?.deal_memo_step_id || null;
            }

            return this.props?.showGenerateScriptModal(scriptDetails, this.state.dealsDetails?.division_id)
        
    }

    showModals(type, index = null, id = null) {
        switch (type) {
            case 'newDeal': return this.props?.hideShowNewDealModal();
            case 'newAmendment': return this.props?.showNewAmendmentModal(this.state?.dealMemoId, { value: this.state.dealsDetails?.project_id });
            case 'editAmendment': return this.props?.showEditAmendmentModal(this.state?.dealMemoId, { value: this.state.dealsDetails?.project_id }, id)
            case 'editDeal': return this.props?.showEditNewDealModal(this.state?.dealMemoId)
            case 'amendmentStep': return this.props?.showManageDealModal(false, id, { value: this.state.dealsDetails?.project_id })
            case 'dealStep': return this.props?.showManageDealModal(true, this.state?.dealMemoId)
            case 'talent': {
                return this.props?.handleShowTalentPopup(id);
            }
        }
    }

    formatWriters = (writers) => {
        let dealWriters = [];
        writers?.map(item => {
            dealWriters?.push(item.writer_name)
        })
        return dealWriters?.join(', ');
    }

    calculateEndDate(startDate, duration, durationType) {
        try {
            if (startDate && durationType && duration !== "" && duration !== null) {

                let endDate = moment(startDate, 'YYYY-MM-DD').add(parseInt(duration), durationType).format("YYYY-MM-DD");
                return endDate;
            }
            else {
                return ''
            }
        } catch (err) {
            return ''
        }

    }
    generateTabelList(stepData, entity, ammendment_date, deal_ammendment_id) {
        let formattedList = stepData?.map(item => {

            let writerNames = item?.writers?.map((o, index) => {
                return { label: o.writer_name, id: o?.writer_id || o?.talent_group_id };
            });
            // writerNames = writerNames?.length > 0 ? writerNames.join(', ') : '-';
            let durationStr = item?.duration && item?.duration_period_name ? item?.duration + ' ' + item?.duration_period_name : '-';
            let durationLabel = item?.duration_period_name?.replace("(", "")?.replace(")", "")?.toLowerCase();
            let writingDue = this.calculateEndDate(item?.writing_start, item?.duration, durationLabel);
            let readingPeriod = item?.reading_period_name ? item?.reading + ' ' + item?.reading_period_name : '-';
            let readingLabel = item?.reading_period_name ? item?.reading_period_name?.replace("(", "")?.replace(")", "")?.toLowerCase() : '-'
            let readingend = item?.delivery_date?  this.calculateEndDate(item?.delivery_date, item?.reading, readingLabel):null;
            let payment = item?.payment ? item?.payment?.toLocaleString('en-US') : '';
            let obj = {
                id: item?.deal_memo_step_id,
                no: item?.step_number,
                name: item?.step_name,
                writers: writerNames,
                duration: durationStr,
                writingstart: getFormattedDate(item?.writing_start) || null,
                writingdue: getFormattedDate(writingDue) || null,
                delivery: getFormattedDate(item?.delivery_date) || null,
                readingperiod: readingPeriod,
                readingend: getFormattedDate(readingend) || null,
                // payment: item?.currency_code && item?.payment ? `${item?.currency_code} ${item?.payment}` : ' ',
                payment: `${item?.payment ? item?.currency_code : ''} ${payment || ''}`,
                shareIcon: "pen",
                genaralnotes: item?.deal_notes,
                submission_id: item?.draft_submission_id || null,
                ammendment_date: this.formatDate(ammendment_date) || null,
                ammendment_id: deal_ammendment_id
            }
            return obj;
        });
        // this.setState({ [entity]: formattedList })
        return formattedList;
    }
    addRemoveQue(type) {
        this.setState({ mode: type === "REMOVE" ? 'REMOVEQUEUE' : "ADDQUEUE", showPageLoader: true })
        let payload = {
            ...DealQueueInitialJson,
            user_id: this?.props?.userContext?.user_profile?.user_id,
            deal_memo_id: this.state?.dealMemoId,
            is_delete: type === "REMOVE" ? 1 : 0
        }
        scripterService.postDataParams(Constants.scripterServiceBaseUrl + `/userQueue`, payload, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({ showPageLoader: false })
                if (response.data?.error) {
                    this.manageMessageModal("Fail", true);
                    return;
                }
                this.manageMessageModal("Success", true, true);

            }, err => {
                this.setState({ showPageLoader: false })
                this.manageMessageModal("Fail", true);
                console.log("Error from Deal Queue", err)
            })
    }

    deleteDeal() {
        if (this.state.dealsDetails?.steps?.length > 0 || 
                (this.state.dealsDetails?.deal_ammendments?.length > 0 && this.state.dealsDetails?.deal_ammendments?.filter(item => 
                    item?.ammendment_steps?.length > 0)?.length > 0
                ) 
            ) {
            this.setState({ showDeleteWarningPopup: true })
        } else {
            if (!this.state?.isDeleteClick) {
                this.manageMessageModal("Error", true);
                return
            }
            this.setState({ mode: "DELETE", showPageLoader: true })
            let postData = {
                ...this.state?.dealsDetails,
                is_deal_memo: 1,
                is_deal_ammendment: 0,
                is_delete: 1
            }
            scripterService.postDataParams(Constants.scripterServiceBaseUrl + `/dealMemo`, postData,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    this.setState({ showPageLoader: false })
                    if (response.data.error) {
                        this.manageMessageModal("Fail", true)
                        return
                    }
                    this.manageMessageModal("Success", true, true)
                }, err => {
                    this.setState({ showPageLoader: false })
                    console.log("Error from delete deal memo", err);
                    this.manageMessageModal("Fail", true)
                });
        }
    }
    onManageSteps(id) {
        id ? this.showModals('amendmentStep', null, id) : this.showModals("dealStep")
    }
    closePopup = () => {

        if (this.state?.showError) {
            this.setState({ isDeleteClick: true, showError: false }, () => {
                this.deleteDeal();
            })
        }
        if ((this.state.showSuccess && this.state.refreshPage && this.state?.mode === "DELETE") || this.state?.isRedirectionPopup) {
            this.props.history.push("/")
        }
        if (this.state.showSuccess && this.state.refreshPage) {
            // this.props?.toggleRefreshPage('deal')
            this.getDealDetails();
        }
        this.setState({ showError: false, showFail: false, showSuccess: false });
    }

    manageMessageModal = (mode, value, refreshValue) => {
        switch (mode) {
            case 'Success':
                this.setState({ showSuccess: value, showFail: false, refreshPage: refreshValue })
                break;
            case 'Fail': this.setState({ showSuccess: false, showFail: value })
                break;
            case 'Error': this.setState({ showSuccess: false, showFail: false, showError: value })
                break;
        }
    }
    goToAllDeals() {
        this.props?.history.push("/Deals")
    }
    downloadUrl = (url, reportName) => {
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', reportName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        }
    }

    generateReport = () => {
        let json = { "deal_memo_id": this.state.dealMemoId };
        let load = {
            "payload": 'generateReport',
            "reportName": 'rptDealDetails',
            "searchJson": btoa(JSON.stringify(json)),
            "showHtml": 0,
            "isStaticReport": 0,
            "encryptionType": 1,
            "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
            "divisionId": this.state.dealsDetails?.division_id
        }
        this.setState({ showPageLoader: true, mode: "LOADING" })
        scripterService.getDataWS(load)
            .then(webSocketConn => {
                webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data)
                    if (response?.statusCode == 200) {
                        this.setState({ showPageLoader: false })
                        if (response?.body?.includes('Template Data not found')) {
                            this.setState({ showReportFail: true })
                        } else {
                            this.downloadUrl(response?.body, "Deal_Details");
                        }
                    } else {
                        this.setState({ showPageLoader: false })
                    }
                }
            })
    }

    closeAccessMessageModal = () => {
        this.props.history.push("/");
    }

    render() {
        let failCustomMsg = this.state?.mode === "ADDQUEUE" ? this.state?.failAddQueue : this.state?.mode === "REMOVEQUEUE" ? this.state?.failRemoveQueue : this.state?.mode === "DELETE" ? this.state?.deleteFailure : '';
        let successCustomMsg = this.state?.mode === "ADDQUEUE" ? this.state?.saveAddQueue : this.state?.mode === "REMOVEQUEUE" ? this.state?.saveRemoveQueue : this.state?.mode === "DELETE" ? this.state?.deleteSuccess : this.state?.mode === 'DENIED' ? this.state?.redirectMsg : '';
        let dealDetail = this.state.dealsDetails;
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state?.mode ? failCustomMsg : this.state.failMessage :
            this.state.showSuccess ? this.state?.mode ? successCustomMsg : this.state.saveEditMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage : this.state.showSuccess ? this.state.saveTitle : "";
        
        return (
            <>
                {!this.state.canViewDealDetails ?
                    <MessageModal
                        open={!this.state?.canViewDealDetails || false}
                        title={"Access Denied"}
                        message={"You don't have access to view this deal!"}
                        primaryButtonText={"Ok"}
                        hideCancel={true}
                        onConfirm={this.closeAccessMessageModal}
                    /> :
                    <MDBContainer fluid className="Deails-detail-container">
                        <MessageModal
                            open={this.state.showDeleteWarningPopup}
                            title={"Delete Deal"}
                            message={"First delete individual deal steps, then deal can be deleted."}
                            hideCancel={true}
                            primaryButtonText={"OK"}
                            onConfirm={(e) => this.setState({ showDeleteWarningPopup: false })}
                        />
                        <Loader isOpen={this.state.showPageLoader || false}
                            text={this.state.mode === 'ADDQUEUE' ? "Adding..." : this.state.mode === 'LOADING' ? "Loading..." : "Removing..."} />
                        <div className='deals'>
                            <div className='dealNameWraper'>
                                <span className='deal'>Deal- </span>
                                <span className={dealDetail?.document_name.length > 60 ?'justiceMoreCharacters':'justice' }>{dealDetail?.document_name}</span>
                                {dealDetail?.document_name.length > 180 &&
                                <span className='expand-icon'> 
                                <HtmlTooltip  title={dealDetail?.document_name}>
                                <span>{dealDetail?.document_name.length > 180 && <OpenInFullIcon style={{ fontSize: 15}} />}</span>
                                </HtmlTooltip>
                                </span>
                                }
                                {!this.state.isAssignedToProjectPermission ?
                                    <span className='dealsActions'>
                                        <MDBDropdown >
                                            <MDBDropdownToggle tag="a">
                                                <MDBIcon
                                                    className='chevron'
                                                    icon={"ellipsis-h"}
                                                />
                                            </MDBDropdownToggle>
                                            <MDBDropdownMenu className="text-end border border-1-quinary">
                                                {dealDetail?.is_add_to_queue == 1 ?
                                                    <MDBDropdownItem active={false} onClick={this.addRemoveQue.bind(this, 'ADD')} >
                                                        Add To My Queue
                                                </MDBDropdownItem> : null
                                                }
                                                {/* {dealDetail?.is_remove_from_queue == 1 ?  */}
                                                <MDBDropdownItem
                                                    active={false}
                                                    onClick={this.addRemoveQue.bind(this, 'REMOVE')}
                                                    // disabled={`${dealDetail?.is_remove_from_queue == 1 ? false : true}`}
                                                    disabled={!dealDetail?.is_remove_from_queue || false}
                                                >
                                                    Remove from My Queue
                                            </MDBDropdownItem>
                                                {/* } */}
                                                {this.state?.canDeleteDeal &&
                                                    <MDBDropdownItem active={false} onClick={this.deleteDeal.bind(this)} >
                                                        Delete Deal
                                                </MDBDropdownItem>
                                                }
                                            </MDBDropdownMenu>
                                        </MDBDropdown>
                                    </span>
                                : null}
                            </div>
                            <div>
                                <span className='backDeal'>
                                    <div>
                                        <MDBIcon
                                            className='chevron'
                                            icon={"chevron-left"}
                                            onClick={this.goToAllDeals.bind(this)}
                                        />

                                    </div><span className='allDeals' onClick={this.goToAllDeals.bind(this)}>back to all deals</span></span></div>
                        </div>
                        <div className="tab-content m-t">
                            <div className="file-heading">
                                <BasicLabel type={"fieldHeading"} text={"Details"} />
                            </div>
                            <div className='flex dealButton'>
                                <BasicButton
                                    variant="outlined"
                                    text={"Deal Details"}
                                    icon="file-alt"
                                    className='dealBtn'
                                    onClick={this.generateReport.bind(this)}
                                />
                                {(this.state?.canModifyDeal && !this.state.isAssignedToProjectPermission) &&
                                    <BasicButton
                                        variant="contained"
                                        type="inline"
                                        color="primary"
                                        text={"Edit"}
                                        icon="pen"
                                        onClick={this.showModals.bind(this, 'editDeal')}
                                    />
                                }
                            </div>
                        </div>
                        <div>
                            <DealsDetailsContainer
                                formatDate={this.formatDate}
                                dealsDetails={dealDetail}
                                canModifyDeal={this.state?.canModifyDeal}
                                // onAmendmentClick={this.showModals.bind(this, 'newAmendment')} 
                                onAmendmentClick={this.showModals.bind(this)}
                                fetchingDealDetails={this.state.fetchingDealDetails}
                                isAssignedToProjectPermission={this.state.isAssignedToProjectPermission}
                                {...this.props}
                            />
                            <br />
                        </div>
                        <div className="tab-content m-t mb-2">
                            <div className="file-heading">
                                <BasicLabel type={"fieldHeading"} text={`Deal Steps - ${this.state.memo_date}`} />
                            </div>
                            <div className='flex dealButtonSecond'>
                                {(this.state?.canModifyDeal && !this.state.isAssignedToProjectPermission) &&
                                    <BasicButton
                                        variant="contained"
                                        type="inline"
                                        color="primary"
                                        text={"Manage Steps"}
                                        width={"100%"}
                                        onClick={() => this.onManageSteps(null)}
                                    />
                                }
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <DealSteps
                                listData={this.state.stepList}
                                // showModals={this.showModals.bind(this, 'dealStep', null)}
                                showModals={this.handleShowModals.bind(this, 'dealStep', null)}
                            />
                            <br />
                            {this.state.dealsDetails?.deal_ammendments?.map((item, index) =>
                                <div>
                                    <div className="tab-content m-t mb-2">
                                        <div className="file-heading">
                                            <BasicLabel type={"fieldHeading"} text={`Deal Amendment Steps - ${getFormattedDate(item?.ammendment_date, false)}`} />
                                        </div>
                                        <div className='flex dealButtonSecond'>
                                            {(this.state?.canModifyDeal && !this.state.isAssignedToProjectPermission) &&
                                                <BasicButton
                                                    variant="contained"
                                                    type="inline"
                                                    color="primary"
                                                    text={"Manage Steps"}
                                                    width={"100%"}
                                                    onClick={() => this.onManageSteps(item?.deal_ammendment_id)}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <DealSteps
                                        listData={this.generateTabelList(item?.ammendment_steps, 'ammendmentData', item?.ammendment_date, item?.deal_ammendment_id)}
                                        // showModals={this.showModals.bind(this, 'amendmentStep', item.ammendment_steps)}
                                        showModals={this.handleShowModals.bind(this, 'amendmentStep', item.ammendment_steps)}
                                    />
                                    <br />
                                </div>
                            )}
                        </div>
                        <MessageModal
                            open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                            title={comfirmModalTitle}
                            message={message}
                            hideCancel={this.state.showFail || this.state.showSuccess || false}
                            primaryButtonText={"OK"}
                            secondaryButtonText={"Cancel"}
                            onConfirm={() => this.closePopup()}
                            handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false, mode: "default" })}
                        />
                        <MessageModal
                            open={this.state.showReportFail || false}
                            title={'Data not Found'}
                            message={"No data available to generate report"}
                            hideCancel={true}
                            primaryButtonText={"OK"}
                            onConfirm={(e) => this.setState({ showReportFail: false })}
                            handleClose={(e) => this.setState({ showReportFail: false })}
                        />
                    </MDBContainer>
                }
            </>
        );
    }
}

export default withUserContext(DealsDetails);