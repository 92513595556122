import React from 'react';
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import { ElementList } from './Mock';
import { elementsConfig } from './Config';
import TableComponent from '../../../SharedComponents/Table';
import MessageModal from '../../../SharedComponents/MessageModal';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';
import Loader from '../../../../Common/Loader/Loader';

class SubmissionElements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: elementsConfig,
            submissionElements: null,
            isDeletingElement: false,
            isFetchingElementList: false,
            elementsRefreshPage: false,
            fetchElementsList: () => this.fetchElementsList()
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.refreshPage?.value !== state.elementsRefreshPage && props.refreshPage?.modal === 'elements') {
            if (props.refreshPage?.value) {
                state.fetchElementsList();
                props.toggleRefreshPage(props.refreshPage?.modal)
            }
            return {
                elementsRefreshPage: props.refreshPage?.value || false
            }
        }
        return null;
    }

    componentDidMount() {
        this.fetchElementsList();
    }

    fetchElementsList = () => {
        this.setState({ isFetchingElementList: true })
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/submission?submissionId=${this.props?.submissionId}`,
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                this.setState({ isFetchingElementList: false, submissionElements: response?.data[0]?.elements });
            },
                (err) => {
                    this.setState({ isFetchingElementList: false })
                })
    }

    handleDeleteConfirmation = (callbackItem) => {
        this.setState({ 
            showDeleteConfirmationModal: true,
            deletedCallbackItem: callbackItem
        });
    }

    deleteElement = () => {
        this.setState({ isDeletingElement: true, showDeleteConfirmationModal: false })
        this.state.deletedCallbackItem.is_delete = 1;
        let filteredElements = this.state.submissionElements?.filter(item => item.submission_element_id !== this.state.deletedCallbackItem?.submission_element_id);
        let elementList = {
            submission_id: this.props?.submissionId,
            elements: [...filteredElements, this.state.deletedCallbackItem]
        }
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + "/submissionElements", elementList,
            this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(
                (response) => {
                    console.log("response delete", response)
                    this.setState({ isDeletingElement: false })
                    if (response?.data?.error) {
                        this.props?.manageMessageModal('Fail', true)
                    } else {
                        // this.props?.manageMessageModal('Success', true);
                        this.fetchElementsList();
                    }
                },
                (err) => {
                    console.log("error", err);
                    this.setState({ isDeletingElement: false })
                    this.props?.manageMessageModal('Fail', true)
                }
            );
    }


    render() {
        return (
            <div className="ElementsContainer">
                <MessageModal
                    open={this.state.showDeleteConfirmationModal}
                    title={"Delete record"}
                    message={"Would you like to delete saved record?"}
                    primaryButtonText={"Delete"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => this.deleteElement()}
                    handleClose={(e) => this.setState({ showDeleteConfirmationModal: false })} 
                />
                <Loader isOpen={this.state.isDeletingElement || false} text={"Deleting..."} />
                <MDBCard>
                    <MDBCardBody className={`${this.state.submissionElements?.length === 0 ?
                        'no-result-card' : `${this.state.submissionElements?.length > 9 && "card-body-height"}`}`}
                    >
                        <MDBCardText className="tab-table-content elements-text-highlight">
                            <TableComponent
                                list={this.state?.submissionElements || []}
                                className={"submissionElementsTable"}
                                config={this.state.config}
                                removeHeader={false}
                                tableMode={"light"}
                                isLoading={this.state.isFetchingElementList || false}
                                // handleDelCallBack={(id, item) => this.deleteElement(item)}
                                handleDelCallBack={(id, item) => this.handleDeleteConfirmation(item)}
                                canDelete={this.props?.canModifySubmission}
                            />
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </div>
        )
    }
}

export default withUserContext(SubmissionElements)