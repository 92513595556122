import React, { Component } from 'react'
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';
import MessageModal from '../SharedComponents/MessageModal';
export default class FilelistItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDeleteConfirmationModal: false
        }
    }

    handleDeleteConfirmation = (name) => {
        this.setState({
            showDeleteConfirmationModal: true,
            fileName: name
        })
    }

    handleConfirm = () => {
        this.setState({ showDeleteConfirmationModal: false }, () => {
            this.props?.deleteFile(this.state.fileName)
        })
    }

    render() {
        let { name, prefix } = this.props?.file;
        let fileObj = this.props?.fileObj;
        return (
            <>
                <MessageModal
                    open={this.state.showDeleteConfirmationModal}
                    title={"Delete file"}
                    message={"Would you like to delete saved file?"}
                    primaryButtonText={"Delete"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={this.handleConfirm}
                    handleClose={(e) => this.setState({ showDeleteConfirmationModal: false })}
                />
                <MDBRow>
                    <MDBCol md={5} className="flex">
                        <BasicLabel type={"text"} text={name} />
                    </MDBCol>
                    <MDBCol md={1}>
                    {this.props?.viewPrivateFiles && this.props?.file?.isPrivate === "1"?
                        <MDBIcon icon="file" size='sm' onClick={this.props?.downloadFile.bind(this, name, prefix, fileObj)} />:
                        (this.props?.file?.isPrivate === "0")?
                        <MDBIcon icon="file" size='sm' onClick={this.props?.downloadFile.bind(this, name, prefix, fileObj)} />:null }
                        
                    </MDBCol>
                    <MDBCol md={3}>
                    {(this.props?.showPrivateCheck && this.props?.canModifyFiles)?
                          <BasicCheckbox
                          label="Private"
                          id={`private-${this.props?.index}`}
                          checked={this.props?.file?.isPrivate ? parseInt(this.props?.file?.isPrivate) : 0}
                          onChange={(e) => this.props?.handleFilePrivate(this.props?.file, e.target.checked ? 1 : 0)} /> :
                          (this.props?.showPrivateCheck && ! this.props?.canModifyFiles && this.props?.file?.isPrivate === "0") ?
                          <BasicCheckbox
                            label="Private"
                            id={`private-${this.props?.index}`}
                            checked={this.props?.file?.isPrivate ? parseInt(this.props?.file?.isPrivate) : 0}
                            onChange={(e) => this.props?.handleFilePrivate(this.props?.file, 1)} /> :
                          (!this.props?.showPrivateCheck && this.props?.canModifyFiles && this.props?.file?.isPrivate === "1")  ?
                          <BasicCheckbox
                            label="Private"
                            id={`private-${this.props?.index}`}
                            checked={this.props?.file?.isPrivate ? parseInt(this.props?.file?.isPrivate) : 0}
                            onChange={(e) => this.props?.handleFilePrivate(this.props?.file, 0)} /> : null
                    }
                    {(this.props?.viewPrivateFiles && !this.props?.showPrivateCheck && !this.props?.canModifyFiles)?
                    <BasicCheckbox
                    label="Private"
                    id={`private-${this.props?.index}`}
                    checked={this.props?.file?.isPrivate ? parseInt(this.props?.file?.isPrivate) : 0} /> : null
                    }
                    </MDBCol>   
                    <MDBCol md={2}>
                    {(this.props?.canModifyFiles && (this.props?.file?.isPrivate === "1"|| this.props?.file?.isPrivate === "0")) ?
                            <MDBIcon
                            icon="trash-alt"
                            size='sm'
                            onClick={()=>{this.handleDeleteConfirmation(name, prefix, fileObj)}}/>: 
                    (this.props?.file?.isPrivate === "0" && !this.props?.canModifyFiles)?
                    <MDBIcon
                    icon="trash-alt"
                    size='sm'
                    onClick={()=> this.handleDeleteConfirmation(name, prefix, fileObj) }/>: ""
                    }
                    </MDBCol>
                </MDBRow>
            </>
        )
    }
}
