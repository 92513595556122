import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import DateField from '../../../SharedComponents/DateField/DateField';
import BasicTextArea from '../../../SharedComponents/BasicTextArea/BasicTextArea';
import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-time-picker/assets/index.css';
import Deals from '../Step';
import { intialAmendments } from './Config'
import { intialDeals } from '../../../../components/Views/Deals/config';
import './NewAmendment.scss';
import { withUserContext } from '../../../../contexts/UserContext';

export default withUserContext(class NewAmendment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

            isFetchingAmendmentStatus: false,
            postInitiated: false,
            isPosting: false,
            dealsDetails: intialDeals,
            dealIndex: 2,
        }
    }
    addDealsList = () => {
        let dealsDetails = { ...this.state.dealsDetails };
        let initialDealList = { ...intialDeals };
        let deal_list = [...dealsDetails.deal_list];
        initialDealList.index = this.state.dealIndex;
        initialDealList.canRemove = true;
        deal_list.push(initialDealList)
        dealsDetails.deal_list = deal_list;
        this.setState({ repIndex: this.state.dealIndex + 1, dealsDetails: dealsDetails });
    }

    render() {
        let isLoading = this.state.isFetchingAmendmentDetails && this.state.isFetchingAmendmentTypes;
        return (
            <>

                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="ManageDealContainer">
                        <MDBRow>
                            <MDBCol md={3}>
                                <DateField
                                    id="Amendment date"
                                    label={"Amendment Date"}
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated || false}
                                    value={this.state.dateValue || ""}
                                />
                            </MDBCol>
                            <MDBCol md={9}>
                                <BasicTextArea
                                    label={"Amedment Notes"}
                                    id={"textarea-performer"}
                                    rows={1}
                                    value={this.state.coverageDetails?.notes || ""}
                                />
                            </MDBCol>
                        </MDBRow>
                        <br />
                        <MDBRow>
                            <span className="basic-text">Deals Step</span>
                            <Deals />
                        </MDBRow>
                        {
                            this.state.dealsDetails?.deal_list.map((item, index) => (
                                <MDBRow>
                                    <Deals />
                                </MDBRow>
                            ))
                        }

                        <MDBRow>
                            <MDBCol className="mt-2">
                                <BasicButton
                                    variant="contained"
                                    text="Add Step"
                                    type="inline"
                                    onClick={this.addDealsList}
                                    icon={"plus-circle"}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={9}></MDBCol>
                            <MDBCol md={3}>
                                <MDBRow>
                                    <MDBCol>
                                        <BasicButton
                                            text={"Save"} />
                                    </MDBCol>
                                    <MDBCol>
                                        <BasicButton
                                            variant="outlined"
                                            text={"Cancel"} />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>


                    </div>
                )}
            </>
        )
    }
}
);