import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import DateField from '../../../SharedComponents/DateField/DateField';
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import SearchFieldWithAddValue from '../../../SharedComponents/SearchFieldWithAddValue';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import BasicCheckBox from '../../../SharedComponents/BasicCheckbox/BasicCheckbox';
import { validateNumbersOnly, validateCharNumOnly } from '../../../../Common/Helper';
import messages from '../../../../Common/Messages.json';
import { limit, talentInitialDetails } from './Config';
export default class SubMaterials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    formatList = (list) => {
        let formats = list?.map(item => ({ submission_format_id: null, format_id: item.value, format: item.text }));
        formats = formats.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.format_id === value.format_id && t.format === value.format
            ))
        )
        this.props.handleGeneralEdit('formats', formats);
    }
    getFormatTalentGroupName = (talentTeamList) => {
        let talentGroupName = [];
        talentTeamList?.map(item => {
            talentGroupName.push(item?.talent_name)
        })
        return talentGroupName?.join(' & ');
    }
    handleNewAuthorDetails = (talentDetails) => {
        let newValue = {
            value: talentDetails?.talent_id || talentDetails?.talent_group_id,
            text: (talentDetails?.talent_id ?
                // (talentDetails?.first_name + ' ' + (talentDetails?.last_name ? talentDetails?.last_name : '')) :
                ((talentDetails?.prefix ? talentDetails?.prefix + ' ' : '') + talentDetails?.first_name + ' ' + (talentDetails?.m_i ? talentDetails?.m_i + ' ' : '') + (talentDetails?.last_name ? talentDetails?.last_name : '') + (talentDetails?.suffix ? ' ' + talentDetails?.suffix : '')) :
                this.getFormatTalentGroupName(talentDetails?.talent_group_members_list) || null),
            role: "Writer",
            writerTeam: talentDetails?.writer_team,
        }
        this.props?.handleListEdit('author_id', newValue, this.state.authorObj, 'authors')
    }

    handleAuthorsOnChange = (field, value, obj, listName, index) => {
        this.setState({ authorIndex: index, authorObj: obj }, () => {
            if (value === null) {
                this.props?.handleListEdit(field, value, obj, listName)
            } else {
                if (!value.hasOwnProperty('roles')) {
                    let talentDetails = {}
                    let prefixDetails = this.props?.getTalentName("prefix", value?.value);
                    if (prefixDetails) {
                        talentDetails['prefix_id'] = prefixDetails?.value;
                        talentDetails['prefix'] = prefixDetails?.label;
                    }
                    talentDetails['first_name'] = this.props?.getTalentName("first_name", value?.value);
                    talentDetails['middle_name'] = this.props?.getTalentName("middle_name", value?.value) || null;
                    talentDetails['last_name'] = this.props?.getTalentName("last_name", value?.value) || null;
                    let suffixDetails = this.props?.getTalentName("suffix", value?.value);
                    if (suffixDetails) {
                        talentDetails['suffix_id'] = suffixDetails?.value;
                        talentDetails['suffix'] = suffixDetails?.label;
                    }
                    this.props?.navFuns?.handleShowTalentPopup(null, 0, "new", this.handleNewAuthorDetails, talentDetails)
                } else {
                    this.props?.handleListEdit(field, value, obj, listName)
                }
            }
        })
    }

    render() {
        let formatListValue = this.props.submissionDetails.formats?.map(item => ({ value: item.format_id, text: item.format }));
        return (
            <MDBContainer fluid className="sub-materials-container">
                {/* <BasicLabel type={"darkFieldHeading"} text={"Materials"} /> */}
                <MDBRow className="submission-row-margin">
                    <MDBCol md={12} className="sub-authors-width">
                        {
                            this.props.submissionDetails?.authors?.map((author, index) => (
                                (author.is_delete === 0 ?
                                    <MDBRow className="authors-margin">
                                        <MDBCol md={10}>
                                            {/* <SearchSelectField
                                                id={"author" + (author?.author_id || author?.talent_group_id)}
                                                label={index === 0 ? "Authors" : ""}
                                                placeholder={'- Search -'}
                                                options={this.props.userList || []}
                                                detail_selected={(author?.author_id || author?.talent_group_id) && author?.author ? {
                                                    value: (author?.author_id || author?.talent_group_id),
                                                    text: author?.author
                                                } : null}
                                                width={'100%'}
                                                valueSelected={(e, value) => this.props?.handleListEdit("author_id", value, author, "authors")}
                                                searchText={(e) => this.props?.getEntity('talentList', 'TALENT', e.target.value)}
                                                multiple={false}
                                                searchSelect={true}
                                            /> */}
                                            <BasicLabel text={index === 0 ? "Authors" : ""} />
                                            <SearchFieldWithAddValue
                                                id={"author" + (author?.author_id || author?.talent_group_id)}
                                                placeholder={'- Search -'}
                                                options={this.props?.userList || []}
                                                detail_selected={(author?.author_id || author?.talent_group_id) && author?.author ? {
                                                    value: (author?.author_id || author?.talent_group_id),
                                                    text: author?.author
                                                } : null}
                                                width={'100%'}
                                                valueSelected={(e, value) => this.handleAuthorsOnChange("author_id", value, author, "authors", index)}
                                                searchText={(e) => this.props?.getEntity('talentList', 'TALENT', e.target.value)}
                                                multiple={false}
                                                searchSelect={true}
                                            />
                                        </MDBCol>
                                        <MDBCol md={2} className="actionButons2 d-inline-block">
                                            <MDBRow className={`${index === 0 ? 'initialIndexIcons' : ''}`}>
                                                <MDBCol md={1} className="removeActionIcon">
                                                    {
                                                        (author.author?.length > 0) ?
                                                            <MDBIcon
                                                                icon="trash-alt"
                                                                className="float-right"
                                                                onClick={() => this.props?.removeCallback("authors", author, index)}
                                                            />
                                                            : null
                                                    }
                                                </MDBCol>
                                                <MDBCol
                                                    md={1}
                                                    className={`${this.props?.submissionDetails?.authors?.length === 1 ? 'addIconMargin' : ''}`}
                                                >
                                                    {
                                                        (author.author?.length > 0 && index === (this.props?.submissionDetails?.authors.length - 1)) ?
                                                            <AddCircleIcon
                                                                onClick={(e) =>
                                                                    this.props?.addCallback("authors")
                                                                }
                                                            /> : null
                                                    }
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                    : null
                                )
                            ))
                        }
                    </MDBCol>
                </MDBRow>
                <MDBRow className='submission-formats submission-row-margin'>
                    <MDBCol md={8}>
                        <MDBRow
                            className={`${formatListValue?.length === 0 ? "searchSelectHeight searchSelect" : "searchSelect"}`}
                            id='formatDropDown'
                        >
                            <SearchSelectField
                                id={"format"}
                                label={"Format"}
                                value={formatListValue || []}
                                options={this.props?.formats || []}
                                // placeholder={' - Select Multiple -'}
                                isMandatory={true}
                                showMandatory={this.props?.postInitiated || false}
                                limitTags={4}
                                onChange={(e, value) => this.formatList(value)}
                                multiple={true}
                                width={'100%'}
                            />
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="submission-row-margin">
                    <MDBCol md={4} id="h2">
                        <DateField
                            label={"Draft Date"}
                            value={this.props.submissionDetails?.draft_date || ''}
                            onChange={(e) => this.props.handleGeneralEdit('draft_date', e.target.value)}
                        />
                    </MDBCol>
                    <MDBCol md={4} id="h3">
                        <BasicTextField
                            id="draft-version"
                            label={"Draft Version"}
                            limit={limit.draftversion}
                            limitWarning={messages.exceed100CharacterWarning || ""}
                            value={this.props.submissionDetails?.draft_version || ""}
                            onChange={(e) => this.props?.handleGeneralEdit('draft_version', e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="submission-row-margin">
                    <MDBCol md={2} id="h3" className="pr-0">
                        <div className="d-flex pgs-section">
                            <RadioGroup
                                aria-label="is-pgs"
                                name="pgs"
                                value={this.props?.submissionDetails?.is_pgs}
                                onChange={(e) => {
                                    this.props?.handleGeneralEdit("is_pgs", parseInt(e.target.value))
                                    this.props?.handleGeneralEdit("is_wb_pgs", 0)
                                }}
                            >
                                <FormControlLabel value={1} control={<Radio size="small" />} />
                            </RadioGroup>
                            <BasicTextField
                                id="pgs"
                                label={"Pgs."}
                                limit={limit.pgs}
                                limitWarning={messages.exceed5DigitWarning || ""}
                                value={this.props?.submissionDetails?.pgs || ""}
                                onChange={(e) => validateNumbersOnly(e.target.value) ?
                                    this.props?.handleGeneralEdit('pgs', e.target.value) : null}
                            />
                        </div>
                    </MDBCol>
                    <MDBCol md={2} id="h3" className="pr-0 wb-pgs">
                        <div className="d-flex pgs-section">
                            <RadioGroup
                                aria-label="is-wb-pgs"
                                name="wb_pgs"
                                value={this.props?.submissionDetails?.is_wb_pgs}
                                onChange={(e) => {
                                    this.props?.handleGeneralEdit("is_pgs", 0)
                                    this.props?.handleGeneralEdit("is_wb_pgs", parseInt(e.target.value))
                                }}
                            >
                                <FormControlLabel value={1} control={<Radio size="small" />} />
                            </RadioGroup>
                            <BasicTextField
                                id="wbpgs"
                                label={"WB Pgs."}
                                limit={limit.wBPgs}
                                limitWarning={messages.exceed5DigitWarning || ""}
                                value={this.props.submissionDetails?.wb_pgs || ""}
                                onChange={(e) => validateNumbersOnly(e.target.value) ?
                                    this.props?.handleGeneralEdit('wb_pgs', e.target.value) : null}
                            />
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}