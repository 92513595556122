export const subMockData = {
	"division_id":null,
	"delink_project_id": null,
	"is_division_change": 0,
	"submission_id": null,
	"submission_title": null,
	"submitted_to": [],
	"submitted_by": [],
	"company": null,
	"is_confidential": 0,
	"is_un_official": 0,
	"is_interim": 0,
	"is_term_deal_producer": 0,
	"is_hide_author": 0,
	"is_hide_title": 0,
	"term_deal_company_id": null,
	"term_deal_company": null,
	"project_id": null,
	"project": null,
	"deal_memo_steps": [],
	"coverage_type_id": null,
	"coverage_type": null,
	"compare_to": [],
	"draft_date": null,
	"received_date": null,
	"due_date": null,
	"draft_version": null,
	"is_pgs": 1,
	"pgs": null,
	"is_wb_pgs": 0,
	"wb_pgs": null,
	"sku": null,
	"box": null,
	"submission_notes": null,
	"akas": [],
	"authors": [],
	"formats": [],
	"source_material": [],
	"elements": []
};

export const submittedTo = {
	"submission_submitted_to_id": null,
	"submitted_to": null,
	"submitted_to_id": null,
	"index": 1,
	"is_delete": 0
}

export const submittedBy = {
	"submission_submitted_by_id": null,
	"talent_id": null,
	"talent_group_id": null,
	"representative_id": null,
	"loanout_company": null,
	"agency_id": null,
	"is_company_change": 0,
	"index": 1,
	"is_delete": 0
}

export const compareTo = {
	"submission_compare_to_id": null,
	"compare_to_id": null,
	"index": 1,
	"is_delete": 0
}

export const dealMemoSteps = {
	"submission_deal_memo_steps_id": null,
	"deal_step_id": null,
	"deal_step": null,
	"index": 1,
	"is_delete": 0
}

export const subAka = { 
    "submission_aka_id": null, 
    "aka": null, 
	"index": 1,
	"is_delete": 0
}

export const subAuthor = {
    "submission_author_id": null,
	"author_id": null,
	"author": null,
	"talent_group_id": null,
	"index": 1,
	"is_delete": 0
}

export const subElement = {
    "submission_element_id": null,
    "element_name": null,
	"talent_id":null,
	"talent_group_id":null,
    "element_role_id": null,
    "element_role": null,
    "element_status_id": null,
    "element_status": null,
	"index": 1,
	"is_delete": 0,
	"talentOptions":[]
}

export const subSourceMaterial = {
	"material_type_id": null,
	"material_type": null,
	"submission_material_id": null,
	"source_material_author": null,
	"title": null,
	// "source_material_author_id": null,
	"publisher": null,
	"source_material_date": null,
	"copy_right_date": null,
	"source_material_talent_group_id": null,
	"index": 1,
	"is_delete": 0
}

export const submissionWorkflowMockData = {
	"submission_id": null, 
	"submission_coverage_id": null,
	"action_label": null,
	"comments": null,
	"created_at": null
}

export const limit = {
    "submissionTitle": 300,
    "aka": 300,
    "company": 50,
	"draftversion": 100,
    "pgs": 5,
    "sku": 20,
    "box": 20,
    "wBPgs": 5,
	"title": 300,
	"author": 300,
    "publisher": 300, 
	"name": 50
}

export const filePayload ={
    "tenantName": null,
    "submissionId": null,
    "projectId":null,
    "dealId": null,
    "weekendReadId": null,
    "module": null,
    "subModule":null,
	"fileName": null,
	"isPrivate":0
}

export const talentInitialDetails = {
    "talent_id": null,
    "talent_group_id": null,
    "first_name": null,
    "last_name": null,
    "m_i": null,
    "prefix": null,
    "suffix": null,
    "performer_notes": null,
    "company": null,
    "akas": [],
    "email_list": [],
    "phone_list": [],
    "address_list": [],
    "role_list": [],
    "representation_list": [],
    "writer_team": 0,
}

export const talentPrefixes = [
	{ value: "Dr", label: "Dr" },
	{ value: "Mr", label: "Mr" },
	{ value: "Miss", label: "Miss" },
	{ value: "Mrs", label: "Mrs" }
]

export const talentSuffixes = [
	{ value: "Esq.", label: "Esq." },
	{ value: "I", label: "I" },
	{ value: "II", label: "II" },
	{ value: "III", label: "III" },
	{ value: "IV", label: "IV" },
	{ value: "V", label: "V" },
	{ value: "Sr.", label: "Sr." },
	{ value: "Jr.", label: "Jr." }
]