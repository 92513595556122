import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { withUserContext } from '../contexts/UserContext';
import UserRoleManagement from '../pages/UserManagement/UserManagement';
import ScripterLandingPage from '../components/ScripterLandingPage/ScripterLandingPage';
import SubmissionDetailContainer from '../components/Views/Submissions/SubDetails/SubmissionDetailContainer';
import DealsDetails from '../components/Views/Deals/DealDetails/DealDetails';
import WeekendReadDetailsView from '../components/Views/WeekendRead/WeekendReadDetailView/WeekendReadDetailView';
import ProjectDetails from '../components/Views/Project/ProjectDetails/ProjectDetails';
import AdvancedSearch from '../components/Navigation/AdvancedSearch/AdvancedSearch';
import AccessDenied from '../components/AccessDenied/AccessDenied';

export default withOktaAuth(withUserContext(class Routes extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let permissions = this.props.userContext?.active_tenant?.permissions;
    return (
      <Switch>
        {
          // permissions?.canAccessURM ? (
          //   <Route exact={true} path='/' render={(props) => <UserRoleManagement {...props} />} />
          // ) : (
          <>            
            <Route exact={true} path={["/", "/Submissions", "/Projects", "/Deals", "/WeekendRead", "/MyQueue"]} render={(props) => <ScripterLandingPage {...props} />} />
            <Route exact={true} path='/submissionDetails/:id' render={(props) => <SubmissionDetailContainer {...props} {...this.props.functions}
              refreshPage={this.props?.refreshPage}
              toggleRefreshPage={this.props?.toggleRefreshPage} />} />
            <Route exact={true} path='/dealDetails/:id' render={(props) =><DealsDetails {...props} {...this.props.functions} refreshPage={this.props.refreshPage}
              toggleRefreshPage={this.props.toggleRefreshPage} />} />
            <Route exact={true} path='/weekendReadDetails/:id' render={(props) => permissions.canViewWeekendRead ? <WeekendReadDetailsView {...props} {...this.props.functions} refreshPage={this.props.refreshPage} toggleRefreshPage={this.props.toggleRefreshPage} /> : <AccessDenied />} />

            <Route exact={true} path='/projectDetails/:id' render={(props) => <ProjectDetails {...props} {...this.props.functions}
              refreshPage={this.props.refreshPage}
              toggleRefreshPage={this.props.toggleRefreshPage} />} />
            <Route exact={true} path='/advancedSearch' render={(props) => <AdvancedSearch {...props} {...this.props.functions} />} />
            <Route exact={true} path='/userRoleManagement' render={(props) => <UserRoleManagement {...props} />} />
          </>
          // )
        }
      </Switch>
    );
  }
}
));
