import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import BasicCheckbox from '../BasicCheckbox/BasicCheckbox';
import './ActionButtons.scss';

export default class ActionButtons extends React.Component {
    render() {
        return (
            <MDBContainer className="ActionButtonContainer">
                <MDBRow className="g-0">
                    <MDBCol md={8}>
                        {this.props?.disabledPrimary ? null :
                        <BasicCheckbox
                            id={this.props.keyName}
                            label="primary"
                            checked={this.props.isPrimary || false}
                            onChange={this.props.primaryOnChange}
                            align="end"
                          //  disabled={this.props.disabled} 
                          />
                        }
                    </MDBCol>
                    {
                        (this.props?.showRemove && !this.props?.disabled) ?
                            <MDBCol md={2} className="actions">
                                <RemoveCircleIcon onClick={this.props?.removeCallback} className={this.props?.removeClassName || ''} />
                            </MDBCol> : null
                    }
                    {
                        (this.props?.showAdd && !this.props?.disabled) ?
                            <MDBCol md={2} className="actions">
                                <AddCircleIcon onClick={this.props?.addCallback} className={this.props?.addClassName || ''} />
                            </MDBCol> : null
                    }
                </MDBRow>
            </MDBContainer>
        );
    }
}

